import React from "react";
import {
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
  Image,
  message,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { DownOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import config from "../config";
import withRouter from "../withRouter";
import SpeedViolationReportStore from "../store/SpeedViolationReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";

import L from "leaflet";

import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import MapForVehiclePosition from "./MapForVehiclePosition";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
const { RangePicker } = DatePicker;
const { Option } = Select;

class SpeedViolationReportList extends React.Component {
  formRef = React.createRef();
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.speedReportStore = new SpeedViolationReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showMap: false,
      showMonthPopup: false,
      showAlertPopup: false,
      modalInfoObj: "",
      Notcompany: false,
      Notasset: false,
      Notspeed: false,
      vehicleObj: false,
      selectAllVehicles: false,
      showEmailModal: false,
      selectedColumns: [
        {
          // title: "Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          //   fixed: 'left',
          //   width:'15%'
        },
        {
          // title: "  Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "name",
          key: "name",

          // width:'15%'
        },
        {
          // title: "Speed ",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_SPEED_"),
          dataIndex: "speed",
          key: "speed",

          //fixed: 'left',
          //   width:'15%'
        },

        {
          // title: " Location ",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
          key: "location",

          // width:'15%'
        },
        {
          // title: " Time  ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_TIME_"),
          dataIndex: "timestamp",
          key: "timestamp",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },

          // width:'15%'
        },
        {
          // title: " View On Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "view",
          key: "view",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: { ...obj, icon: "map-car-icon-green.png" },
                      heading: this.context.translate(
                        "_SPEED_VIOLATION_REPORT_"
                      ),
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />
                {/* <Link
                  onClick={(e) =>
                    this.setState({
                      modalInfoObj: "",
                      showMap: true,
                      modalInfoObj: obj,
                    })
                  }
                >
                  {" "}
                  <img
                    src={require("../assets/images/mapIcon/gps_icon.png")}
                    width={25}
                    height={25}
                  />
                </Link> */}
              </>
            );
          },
          // width:'15%'
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            // intervel: this.state.intervel,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            filter: this.state.filter,
            speedlimit: this.state.speedlimit,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.speedReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.speedReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.speedReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.speedReportStore.exportxml(getData);
          }
        }
      },
    };

    this.showMenu = {
      items: [
        {
          // label: "Excel",
          label: "Email",
          key: "email",
        },
      ],
      onClick: (val) => {
        if (val.key == "email") {
          this.setState({ showEmailModal: true });
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.speedReportStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }
  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({
      companyId: comp?.value,
      currentPage: 1,
      pageSize: 50,
    });
    await this.setState({ selectedVehicles: [] });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.setState({ loadData: 1 });
    // await this.handleSearch();
    //  console.log(data,"sdcd")
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    // console.log(this.vehicleStore.getVehiclesUsingCompanyId)
    // {filteredOptions.map((option, index) => (
    //       <option key={index} value={option}>
    //         {option}
    //       </option>
    //     ))}
    // await this.speedReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val, Notasset: false });
    console.log(this.state.selectedVehicles);
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (!this.state.speedlimit) {
      this.setState({ Notspeed: true });
    } else {
      this.setState({ Notspeed: false });
    }
    console.log(this.state.companyId);
    console.log(this.state.selectedVehicles.map(Number));
    console.log(
      this.state.companyId &&
        this.state.selectedVehicles.length > 0 &&
        this.state.speedlimit
    );
    if (
      this.state.companyId &&
      this.state.selectedVehicles.length > 0 &&
      this.state.speedlimit
    ) {
      this.setState({ Notcompany: false, Notasset: false, Notspeed: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.speedReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles.map(Number),
          speedlimit: this.state.speedlimit,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          currentPage: this.state.currentPage,
        });
        await this.setState({ loadData: 1 });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  onEmailSend = async (values) => {
    console.log(values);

    // const result = this.state.selectedColumns
    // .filter((obj) => obj.dataIndex !== "view") // Exclude columns with dataIndex "view"
    // .map((obj) => obj.dataIndex);

    // console.log(result);
    const data = {
      ...values,
      companyId: this.state.companyId,
      vehicleId: this.state.selectedVehicles,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      speedlimit: this.state.speedlimit,
      // colList: result
    };
    console.log(data);
    const response = await this.speedReportStore.sendExcelByEmail(data);
    console.log(response);
    if (response?.success === "S") {
      message.success(
        "Report will be generated at backend and sent by email in sometime"
      );
      this.setState({ showEmailModal: false });
      this.formRef.current?.resetFields();
    }
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const SpeedInputStyle = this.state.Notspeed
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Speed Violation Report</div> */}
              <div className="heading">
                {this.context.translate("_SPEED_VIOLATION_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
               
                {/* {this.state.selectedVehicles?.length > 1 ? (
                  <Dropdown
                    menu={this.showMenu}
                    // disabled={
                    //   this.speedReportStore?.speedViolationStore?.length > 0
                    //     ? false
                    //     : true
                    // }
                  >
                    <Button style={{ backgroundColor: config.activeColor }}>
                      <Space>
                       
                        {this.context.translate("_EXPORT_")}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                ) : (
                  <Dropdown
                    menu={this.menuProps}
                    disabled={
                      this.speedReportStore?.speedViolationStore?.length > 0
                        ? false
                        : true
                    }
                  >
                    <Button style={{ backgroundColor: config.activeColor }}>
                      <Space>
                    
                        {this.context.translate("_EXPORT_")}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
                )} */}

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.speedReportStore?.speedViolationStore?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                      showAlertPopup: e.target.checked,
                    });
                    // console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {this.context.translate("_ALL_")}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    console.log(val.length);
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                      val.length 
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    // if (val?.length > 1) {
                    //   console.log(val?.length > 1);
                    //   this.setState({ showAlertPopup: true });
                    // }
                  }}
                  // onSelect={(val)=>{
                  //   console.log(val?.length)

                  // }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>

                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Input
                  // style={SpeedInputStyle}
                  style={{ width: 150 }}
                  status={this.state.Notspeed ? "error" : false}
                  // placeholder="Speed(Km)"
                  placeholder={this.context.translate("_SPEED_KM_")}
                  value={this.state.speedlimit}
                  onChange={(e) =>
                    this.setState({
                      speedlimit: e.target.value,
                      Notspeed: false,
                    })
                  }
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                    this.setState({ currentPage: 1, pageSize: 50 });
                  }}
                  // disabled={
                  //   this.state.showAlertPopup ||
                  //   this.state.selectAllVehicles ||
                  //   this.state.selectedVehicles?.length > 1
                  //     ? true
                  //     : false
                  // }
                >
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.speedReportStore?.total}
            columns={this.state.column}
            dataSource={this.speedReportStore?.speedViolationStore}
            loading={this.speedReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.speedReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          {this.state.vehicleObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.vehicleObj}
              heading={this.state.heading}
              closeMapModal={(resp) => {
                this.setState({ vehicleObj: resp });
              }}
            />
          )}

          {/* {this.state.showMap &&  */}

          {/* <Modal
className="multiTracking-popup"
  //  title="Speed Exception Report"
  title= <div>{this.context.translate("_SPEED_VIOLATION_REPORT_")}<CloseOutlined onClick={() => this.setState({ showMap: false })}/></div>
   open={this.state.showMap}
   centered
   // bodyStyle={{ height: 1000 }}
   width={850}
   height={400}
   onOk={() => this.setState({ showMap: false })}
   onCancel={() => this.setState({ showMap: false })}
   footer={false}
 >
   <MapContainer
     ref={this.mapRef}
     zoom={16}
     center={[
       this.state.modalInfoObj?.latitude,
       this.state.modalInfoObj?.longitude,
     ]}
     style={{ height: "60vh", }}

     // bounds={this.state.modalInfoObj ? [[this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude], [this.state.modalInfoObj.latitude, this.state.modalInfoObj.longitude]] : []}
   >
     <ReactLeafletGoogleLayer />
    

     <Marker
       icon={dotIcon}
       position={[
         this.state.modalInfoObj?.latitude,
         this.state.modalInfoObj?.longitude,
       ]}
     >
       
       <Tooltip permanent={true} direction="top">
         <div
           style={{
             backgroundColor: "#00ff00",
             border: "solid 1px #000",
             textAlign: "center",
             padding: 0,
           }}
         >
           <b>{this.state.modalInfoObj?.vehicleNo}</b>
           <br />
         </div>
       </Tooltip>
     </Marker>
   </MapContainer>
 </Modal>
} */}
          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {/* Alert */}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For Reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
          {console.log(this.state.selectedVehicles?.length)}
          {/* {(this.state.selectAllVehicles ||
            this.state.selectedVehicles?.length > 1) && (
            <Modal
              width={350}
              className="alert-model"
              title={
                <div>
                  {" "}
                  <i
                    style={{
                      color: "red",
                      fontSize: "18px",
                      marginRight: "5px",
                    }}
                    class="bi bi-exclamation-triangle"
                  ></i>{" "}
               
                  {this.context.translate("_FLEET_ALERT_")}
                </div>
              }
              maskClosable={false}
              open={this.state.showAlertPopup}
              onOk={() => this.setState({ showAlertPopup: false })}
              onCancel={() => this.setState({ showAlertPopup: false })}
              footer={false}
            >
              For all vehicles please select email option from export & report
              will be sent by email.
            </Modal>
          )} */}

          <Modal
            open={this.state.showEmailModal}
            // title="Send Email"
            title={this.context.translate("_SEND_EMAIL_")}
            onOk={() => this.setState({ showEmailModal: false })}
            onCancel={() => this.setState({ showEmailModal: false })}
            footer={false}
            width={400}
          >
            <Form
              ref={this.formRef}
              name="Email"
              layout="vertical"
              onFinish={this.onEmailSend}
              style={{ maxWidth: "100%" }}
            >
              <Form.Item
                name="email"
                // label="Email"
                label={this.context.translate("_FLEET_LOGIN_EMAILLBL_")}
                rules={[{ required: true, type: "email" }]}
              >
                <Input />
              </Form.Item>
              <div className="formBtnGroup">
                {/* css class */}

                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {/* css class */}
                  Save
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}
SpeedViolationReportList.contextType = StoreContext;
export default observer(withRouter(SpeedViolationReportList));
