import React from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  DatePicker,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Modal,
  Checkbox,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";
import config from "../config";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import { format } from "date-fns";
import AssetRegisteredReportStore from "../store/AssetRegisteredReportStore";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
const { Search } = Input;

const dateFormat = "YYYY-MM-DD";
const timeFormat = "HH:MM";
const currentDate = moment().format(dateFormat);
const currentTime = "12:00";
const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);
const { RangePicker } = DatePicker;

class AssetRegisteredReportList extends React.Component {
  constructor(props,context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.assetRegisterReportStore = new AssetRegisteredReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      date: "",
      time: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany:false,
      showMonthPopup: false,
      selectedColumns: [
        {
          // title: "Device Id",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_DEVICE_"),
          dataIndex: "deviceName",
          key: "deviceName",

          width: "15%",
        },
        {
          // title: "Plates",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_PLATE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          width: "15%",
        },
        {
          // title: "Serial Number",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_SERIALNO_"),
          dataIndex: "simNo",
          key: "simNo",

          width: "15%",
        },
        {
          // title: "Brand",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_BRAND_"),
          dataIndex: "Brand",
          key: "Brand",

          width: "15%",
        },
        {
          // title: "Model",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_MODEL_"),
          dataIndex: "Model",
          key: "Model",

          width: "15%",
        },
        {
          // title: "Year ",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_YEAR_"),
          dataIndex: "Year",

          key: "Year",

          width: "15%",
        },
        {
          // title: "Color",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_COLOR_"),
          dataIndex: "colorCode",
          key: "colorCode",

          width: "15%",
          render: (value) => {
          //  value='FF0000'
              return <div style={{backgroundColor:'#'+ value , display: 'inline-block', width: 80 ,height: 15}}></div>;
          }
        },
        {
          // title: "Asset Type",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_VEHICLETYPE_"),
          dataIndex: "type",
          key: "type",

          width: "15%",
        },
        {
          // title: "Asset Status",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_VEHICLESTATUS_"),
          dataIndex: "status",
          key: "status",

          width: "15%",
          render: function (value) {
            return value == 1 ? 'Active' : 'Inactive';
        }
      
        },

        {
          // title: "Register Date",
          title:context.translate("_FLEET_REPORTS_VEHICLEREPORT_REGISTER_"),
          dataIndex: "createdate",
          key: "createdate",

          width: "15%",
          render: (value) => {
            if (value) {
              const formattedDateTime = (dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A")
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>
            }
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label:context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "CSV",
        //   label:context.translate("_CSV_"), 
        //   key: "csv",
        //   icon: <PlusOutlined />,
        // },
        // {
        //   // label: "XML",
        //   label:context.translate("_XML_"), 
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label:context.translate("_PDF_"), 
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        console.log(val.key);
        const result = [];
        if (this.assetRegisterReportStore.rec) {
          {
            this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));
          }
          console.log(result.toString());

          var getDta = {
            companyId: this.state.companyId,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            this.assetRegisterReportStore.exportExcel(getDta);
          }
          if (val.key == "pdf") {
            this.assetRegisterReportStore.exportpdf(getDta);
          }
          if (val.key == "csv") {
            this.assetRegisterReportStore.exportcsv(getDta);
          }
          if (val.key == "xml") {
            this.assetRegisterReportStore.exportxml(getDta);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)===1){
      this.props.navigate("/login");
    }
    this.setState({ pageSize: 50 });

    await this.setState({ column: this.state.selectedColumns });
    if(Number(this.props.getUserData().isadmin)!==-1){
    
      let companyId = this.props.getUserData().userCompanyId;
   
        this.setState({companyId:companyId})
        this.handleChange({value:companyId});
    }

  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  async handleChange(comp) {
    await this.setState({Notcompany:false});
    await this.setState({ currentPage: 1, companyId: comp?.value });
    // this.handleSearch();
  }
  onDateTimeChange = (dates) => {
    if (dates) {
    const format = "YYYY-MM-DD HH:mm:ss,";
    this.setState({
      fromdateStdTime: dates[0],
      fromDate: dates[0]?.format(format),
      toDate: dates[1]?.format(format),
    });
  }
  };
  async onSearch(value) {
    console.log(value);
    await this.setState({ emailId: value });
    this.handleSearch();
  }

  async handleSearch() {
    await this.assetRegisterReportStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.pageNo,
    });
    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  onTimeChange = (time, timeString) => {
    console.log(time, timeString);
  };
  showData = async () => {
    if(!this.state.companyId ){
      this.setState({Notcompany:true})
    }
    else{
      this.setState({Notcompany:false})
    }
    if(this.state.companyId){
      // this.setState({mandatory:false})
    this.setState({Notcompany: false});
      if(this.checkFromDate(this.state.fromdateStdTime)){
        await this.assetRegisterReportStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.pageNo,
        });
        await this.setState({ listUpdated: 1 }); //need to reload data
      }
    } 
    // else {
    //   console.log("Please select required fields");
    // }

    // console.log(this.state.fromDate,this.state.toDate,)
  };
  addMonths=(date, months) =>{
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + (-6)
    // return date.format("YYYY-MM-DD HH:mm:ss"); 
    return dayjs().month(indx) 
 }
 
  checkFromDate(dates){
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
     var pdate = this.addMonths();
      if(pdate > fromDate){
        //  Ext.Msg.show({
        //      title: fleet.Language.get('_FLEET_ALERT_'),
        //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
        //      buttons: Ext.Msg.OK,
        //  });
        this.setState({showMonthPopup: true})
         return false;
      }else{
         return true;
      }
 }
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany ? { border: '1px solid red',width: '150px', borderRadius: '8px'  } : {width: '150px'};
   
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Asset Registered Report</div> */}
              <div className="heading">{this.context.translate("_FLEET_REPORTS_VEHICLEREPORT_TITLE_")}</div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
         

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown menu={this.menuProps}
                
                disabled={
                  this.assetRegisterReportStore?.assetReport?.length > 0 ? false : true
                }
                >
                  <Button className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? 'error' : false}
                />
              </Col>

              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col>
                {" "}
                <Button type="primary" onClick={() => {this.showData();this.setState({currentPage:1})}}>
                  {" "}
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>

              <Col> </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.assetRegisterReportStore?.total}
            columns={this.state.column}
            dataSource={this.assetRegisterReportStore?.assetReport}
            loading={this.assetRegisterReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.assetRegisterReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
          <Modal
            open={this.state.showMonthPopup}
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
           {/* For Reports prior to 6 months please contact administrator */}
           {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}

          </Modal>
        </div>
      </>
    );
  }
}
AssetRegisteredReportList.contextType = StoreContext;
export default observer(withRouter(AssetRegisteredReportList));
