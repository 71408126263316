import { decode as base64_decode, encode as base64_encode } from "base-64";
import RootStore from "./RootStore";
import { postApi } from "../components/Utility";
import {
  action,
  computed,
  makeObservable,
  observable,
  autorun,
  runInAction,
} from "mobx";

class AssignVehicleToUserStore {
  total = 0;
  loading = false;

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
    
      addData: action,
    });
    this.rootStore = rootStore;
  }

  async addData(data) {
    console.log("data", data);
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=usergroup", "postData=" + base64Input);
    console.log(response);
    return response;
  }

  async getAllUserVehicleFunc({companyId,userId}){
    var postData ={"ruleId":"","addedByUserId":"","companyId":companyId,"userId":userId,"isadmin":"-1"}
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getAllUserVehicles", "postData=" + base64Input);
    console.log(response);
    return response.data?.data;
  }
}

export default AssignVehicleToUserStore;
