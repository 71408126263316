import React from "react";
import {
  Select,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Typography,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import config from "../config";
import withRouter from "../withRouter";

import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

import DriverLoginReportStore from "../store/DriverLoginReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option } = Select;
class DriverLoginReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.driverLoginReportStore = new DriverLoginReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedDriver: [],
      selectAllDrivers: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showMonthPopup: false,
      Notcompany: false,
      loadData: 0,
      showTable: true,
      showtotaltable: false,
      selectedColumns: [
        {
          // title: "Driver Name",
          title: context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
          ),
          dataIndex: "driver",
          key: "driver",

          // width: '15%'
        },
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          // width: '15%'
        },
        {
          // title: "  Login Time",
          title: context.translate("_LOGIN_TIME_"),
          dataIndex: "tripStart",
          key: "tripStart",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },

          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: "  Logout Time",
          title: context.translate("_LOGOUT_TIME_"),
          dataIndex: "tripEnd",
          key: "tripEnd",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: " Start Odometer  ",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STARTOD_"),
          dataIndex: "startOdometer",
          key: "startOdometer",

          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: "End Odometer"
          title: context.translate("_END_ODOMETER_"),
          dataIndex: "stopOdometer",
          key: "stopOdometer",

          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: "Distance Travelled",
          title: context.translate("_FLEET_RULESELECTORDETAIL_RULE13_TITLE_"),
          dataIndex: "distanceTravelled",
          key: "distanceTravelled",

          //fixed: 'left',
          // width: '15%'
        },
        {
          // title: "Max Speed ",
          title: context.translate("_FLEET_REPORTS_FLEETREPORT_MAXSPEED_"),
          dataIndex: "speed",
          key: "speed",

          //fixed: 'left',
          // width: '15%'
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            DriverId: this.state.selectedDriver,
            // intervel: this.state.intervel,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            // filter: this.state.filter,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.driverLoginReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.driverLoginReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.driverLoginReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.driverLoginReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.driverLoginReportStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    await this.setState({ column: this.state.selectedColumns });

    this.totalDriverReportCol = [
      {
        // title: "  Driver Name ",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
        ),
        dataIndex: "driver",
        // width: '15%'
      },
      {
        // title: "  Vehicle No ",
        title: this.context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
        dataIndex: "vehicleNo",
        // width: '15%'
        // summaryType: (records) => {
        //   return "<b>Total</b>";
        // },
      },
      {
        // title: "  Login Time",
        title: this.context.translate("_LOGIN_TIME_"),
        dataIndex: "tripStart",
        //fixed: 'left',
        // width: '15%'
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: "Logout Time",
        title: this.context.translate("_LOGOUT_TIME_"),
        dataIndex: "tripEnd",
        //fixed: 'left',
        // width: '15%'
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: " Distance Travel ",
        title: this.context.translate("_DISTANCE_TRAVEL_"),
        dataIndex: "totalDist",
        //fixed: 'left',
        // width: '15%'
        // summaryType: (records) => {
        //   var suma = 0;

        //   records.map((record) => {
        //     if (record.totalDist) {
        //       suma = parseInt(suma) + parseInt(record.totalDist);
        //     }
        //   });

        //   return "<b>" + suma + "</b>";
        // },
      },
      {
        // title: " Travel Time   ",
        title: this.context.translate("_TRAVEL_TIME_"),
        dataIndex: "totaltraveltime",
        //fixed: 'left',
        // width: '15%'
        // summaryType: (records) => {
        //   //console.log(records);
        //   var suma = 0;
        //   records.map((record) => {
        //     if (record.totaltraveltime) {
        //       var dt = record.totaltraveltime.split(":");

        //       var ts =
        //         parseInt(dt[0] * 60 * 60) +
        //         parseInt(dt[1] * 60) +
        //         parseInt(dt[2]);
        //       suma = parseInt(parseInt(suma) + parseInt(ts));
        //     }
        // });

        // var d, h, m, s;

        // s = suma;
        // m = Math.floor(s / 60);
        // s = s % 60;
        // h = Math.floor(m / 60);
        // m = m % 60;
        // d = Math.floor(h / 24);
        // h = h % 24;

        // var result = (d < 10 ? "0" + d : d) + " days,";
        // result += h < 10 ? "0" + h : h;
        // result += ":" + (m < 10 ? "0" + m : m);
        // result += ":" + (s < 10 ? "0" + s : s);

        // return "<b>" + result + "</b>";
      },
      // },
    ];
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ loadData: 1 });
  }

  async handleChange(comp) {
    if (comp) {
      await this.setState({ Notcompany: false });
      await this.setState({ companyId: comp?.value });
      await this.setState({ selectedDriver: [], selectAllDrivers: false });

      if (this.state.companyId) {
        await this.driverLoginReportStore.getDriversByCompanyId({
          companyId: comp?.value,
        });
      }

      await this.handleSearch();
    } else {
      this.driverLoginReportStore.getDrivers = [];
      await this.setState({ selectedDriver: [], selectAllDrivers: false });
      await this.setState({ loadData: 1 });
    }

    //  console.log(data,"sdcd")
  }

  async handleSearch() {
    // await this.driverLoginReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   pageNo: this.state.currentPage,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  // driverSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedDriver: val });
  // };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedDriver.length === 0) {
      this.setState({ Notdriver: true });
    } else {
      this.setState({ Notdriver: false });
    }
    if (this.state.companyId && this.state.selectedDriver?.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.driverLoginReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,

          driverId: this.state.selectedDriver,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.currentPage,
        });
        await this.setState({ loadData: 1 });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleSelectAllDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.driverLoginReportStore?.getDrivers.map((val) => {
        return val.driverId;
      });
      await this.setState({ selectedDriver: allDrivers });
    } else {
      await this.setState({ selectedDriver: [] });
    }
  };

  showTotal = async () => {
    await this.driverLoginReportStore?.totalDriverReport({
      companyId: this.state.companyId,
      driverId: this.state.selectedDriver,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
    // console.log(this.state.fromDate,this.state.toDate,)
    await this.setState({ loadData: 1 });
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Driver Login</div> */}
              <div className="heading">
                {this.context.translate("_DRIVER_LOGIN_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async () => {
                    await this.showTotal();
                    await this.setState({
                      showTable: false,
                      showtotaltable: true,
                    });
                  }}
                >
                  {this.context.translate("_TOTAL_")}
                </Button>
            

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.driverLoginReportStore?.driverLoginStore?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button 
                  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                <Checkbox
                  checked={this.state.selectAllDrivers}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllDrivers: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleSelectAllDrivers();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  value={this.state.selectedDriver}
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.setState({ selectedDriver: val });
                    if (
                      this.driverLoginReportStore?.getDrivers.length >
                      val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  onSearch={this.handleSearch}
                  mode="multiple"
                  // onChange={(val) => this.driverSelect(val)}
                  fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  optionFilterProp="children"
                  options={this.driverLoginReportStore?.getDrivers}
                  style={{ width: 160 }}
                /> */}

                <Select
                  mode="multiple"
                  value={this.state.selectedDriver}
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.setState({
                      selectedDriver: val,
                      Notdriver: false,
                    });
                    if (
                      this.driverLoginReportStore?.getDrivers.length >
                      val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  showSearch
                  style={{ width: 150 }}
                  status={this.state.Notdriver ? "error" : false}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.driverLoginReportStore?.getDrivers?.map((val) => {
                    return <Option value={val.driverId}>{val.name}</Option>;
                  })}
                </Select>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({
                      currentPage: 1,
                      showTable: true,
                      showtotaltable: false,
                    });
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={250}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <div
            className="accordion-list"
            onClick={() =>
              this.setState({
                showTable: !this.state.showTable,
                showtotaltable: !this.state.showtotaltable,
              })
            }
          >
            {this.context.translate("_DRIVER_LOGIN_")}

            <Button>
              {this.state.showTable ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </Button>
          </div>
          {this.state.showTable && (
            <ListTable
              currentPage={this.state.currentPage}
              pageSize={this.state.pageSize}
              total={this.driverLoginReportStore?.total}
              columns={this.state.column}
              dataSource={this.driverLoginReportStore?.driverLoginStore}
              // pagination={{
              //   pageSize: this.state.pageSize,
              //   //  current:this.state.pageNo
              //   total: this.driverLoginReportStore?.total || 0,
              // }}
              loading={this.driverLoginReportStore?.loading}
              onChange={this.handleTableChange}
              // scroll={{
              //   // y: this.state.clientHeight-240,
              //   x: 100,
              // }}
            />
          )}
          {/* <div
            style={{
              backgroundColor: "Transparent ",
              color: "#0090e7",
              fontSize: "16px",
              padding: "8px 8px",
              fontWeight: "600",
              marginTop: "8px",
              marginBottom: "5px",
            }}
          >
            {this.context.translate("_DRIVER_TOTAL_DETAILS_")}
          </div> */}
          <div
            className="accordion-list"
            onClick={() =>
              this.setState({
                showtotaltable: !this.state.showtotaltable,
                showTable: !this.state.showTable,
              })
            }
          >
            {" "}
            {this.context.translate("_DRIVER_TOTAL_DETAILS_")}
            <Button>
              {this.state.showtotaltable ? (
                <CaretDownOutlined />
              ) : (
                <CaretUpOutlined />
              )}
            </Button>{" "}
          </div>
          {this.state.showtotaltable && (
            <ListTable
              // currentPage={this.state.currentPage}
              // total={this.driverLoginReportStore?.total}
              pagination={false}
              columns={this.totalDriverReportCol}
              dataSource={this.driverLoginReportStore?.totalDriverArray}
              // loading={this.driverLoginReportStore?.loading}
              // onChange={this.handleTableChange}

              summary={(records) => {
                var sum = 0;
                var suma = 0;

                records.map((record) => {
                  if (record.totalDist) {
                    sum = parseInt(sum) + parseInt(record.totalDist);
                  }

                  if (record.totaltraveltime) {
                    var dt = record.totaltraveltime.split(":");
                    var ts =
                      parseInt(dt[0] * 60 * 60) +
                      parseInt(dt[1] * 60) +
                      parseInt(dt[2]);
                    suma = parseInt(parseInt(suma) + parseInt(ts));
                  }
                });

                var d, h, m, s;

                s = suma;
                m = Math.floor(s / 60);
                s = s % 60;
                h = Math.floor(m / 60);
                m = m % 60;
                d = Math.floor(h / 24);
                h = h % 24;

                var result = (d < 10 ? "0" + d : d) + " days,";
                result += h < 10 ? "0" + h : h;
                result += ":" + (m < 10 ? "0" + m : m);
                result += ":" + (s < 10 ? "0" + s : s);

                return (
                  <>
                    {records.length != 0 && (
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          {/* <Text style={{ fontWeight: "bold" }}>Total</Text> */}
                          <Text style={{ fontWeight: "bold" }}>
                            {this.context.translate("_TOTAL_")}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell />
                        <Table.Summary.Cell />
                        <Table.Summary.Cell />

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>{sum}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>{result}</Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </>
                );
              }}
            />
          )}
        </div>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              Alert
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
DriverLoginReportList.contextType = StoreContext;
export default observer(withRouter(DriverLoginReportList));
