import React, { useState } from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import withRouter from "../withRouter";

import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  Modal,
  message,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import config from "../config";

import Password from "antd/es/input/Password";
import ChangePasswordStore from "../store/ChangePasswordStore";
// import Cookies from "universal-cookie";

const layout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 20 },
};

/* eslint-disable no-template-curly-in-string */
const validateMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
    number: "${label} is not a valid number!",
  },
  number: {
    range: "${label} must be between ${min} and ${max}",
  },
};
class CompanyChangePassword extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.chagePassStore = new ChangePasswordStore();
    // console.log(companyStore);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      showalert: false,
      error: "",
    };
    this.fontColorCss = { color: "white" };
  }
  async componentDidMount() {
    // await this.chagePassStore.loadData({ offset: 0, limit: 50 });
    console.log("working");
    this.setState({ pageSize: 50 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleSearch() {
    await this.chagePassStore.loadData({
      offset: this.state.currentPage - 1,
      limit: this.state.pageSize,
    });
  }

  validateReenterPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("newpass") === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error("New password and Confirm password not matched!")
        // new Error(this.context.translate("_NEW_PASSWORD_AND_CONFIRM_PASSWORD_MESSAGE_"))
      );
    },
  });


  
  onChangePassSubmit = async (values) => {
    const userId = JSON.parse(base64_decode(Cookies.get("data"))).userId; // getting user id in cookies
    console.log(userId);
    const data = { ...values, userId: userId };
    const response = await this.chagePassStore.setChangePassword(data);
    console.log(response);
    if (response?.success === "S") {
      message.success(response.message);
      this.props.navigate("/login");
    } else {
      if (response.errors[0]?.passworderror) {
        message.error(
          this.context.translate(response.errors[0]?.passworderror)
        );
      }

      this.setState({ loading: false });
    }
  };
  reset = () => {
    this.formRef.current.resetFields();
  };

  render() {
    return (
      <>
        {/* <div className="changePassHeadline">Change Password</div> */}
        <div className="changePassHeadline">{this.context.translate("_FLEET_MANAGEUSER_CHANGEPASSWORD_TAB_")}</div>
        <div className="changePassLayout">
          <Form
            // {...layout}
            className="changePassForm"
            name="nest-messages"
            onFinish={this.onChangePassSubmit}
            // validateMessages={validateMessages}
            layout="vertical"
            ref={this.formRef}
          >
            <Form.Item
              name="oldpass"
              // label="Old Password"
              label={this.context.translate("_FLEET_CHANGEPASSWORD_OLDPASSWD_")}
              rules={[{ required: true, message: this.context.translate("PLEASE_ENTER_OLD_PASSWORD_") }]}
            >
              <Input.Password style={this.fontColorCss} type="password" />
            </Form.Item>

            <Form.Item
              name="newpass"
              // label="New Password"
              label={this.context.translate("_FLEET_CHANGEPASSWORD_NEWPASSWD_")}
              style={this.fontColorCss}
              rules={[{ required: true, message: this.context.translate("PLEASE_ENTER_NEW_PASSWORD_")}]}
            >
              <Input.Password type="password" />
            </Form.Item>

            <Form.Item
              name="conformpassword"
              label={this.context.translate("_FLEET_CHANGEPASSWORD_CONFIRMPASSWD_")}
              // label="Confirm Password"
              dependencies={["password"]}
              rules={[
                { required: true, message: this.context.translate("PLEASE_ENTER_CONFIRM_PASSWORD_") },
                this.validateReenterPassword,
              ]}
            >
              <Input.Password style={this.fontColorCss} />
              {/* onChange={(e)=>this.comparePasswords(e.target.value,"newpassword")} */}
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "space-between", margin:"24px 0 0 0" }}>
              <Button
                className="changePassBtn"
                type="primary"
                onClick={this.reset}
                // style={{ background: "#181722" }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
              </Button>
              <Button
                className="changePassBtn"
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
            {/* <p
              style={{
                display: "flex",
                justifyContent: "center",
                color: "white",
              }}
            >
              Forget Password?
            </p> */}
          </Form>
        </div>
      </>
    );
  }
}
CompanyChangePassword.contextType = StoreContext;
export default observer(withRouter(CompanyChangePassword));
