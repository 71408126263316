import { encode as base64_encode, decode as base64_decode } from "base-64";

import config from "../config";
import { postApi } from "../components/Utility";
import utf8 from "utf8";
import Cookies from "js-cookie";

import { action, makeObservable, observable } from "mobx";

class VehicleStore {
  total = 0;
  loading = false;
  logoFromDb = [];
  vehicles = [];
  assetVehicleList = [];
  getSCategories = [];
  getVehiclesUsingCompanyId = [];
  getTypeofCategory = [];
  getpollimie = [];
  getWeightUsingtId = [];
  searchdata = [];
  deviceGroups = [];
  getDeviceGroupsVehicles = [];
  vehicleRules = [];
  fuelDetails = [];
  groupList = [];
  globalSearchData=[]
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      vehicles: observable,
      logoFromDb: observable,
      total: observable,
      searchdata: observable,
      deviceGroups: observable,
      getDeviceGroupsVehicles: observable,
      vehicleRules: observable,
      fuelDetails: observable,
      groupList: observable,
      globalSearchData:observable,
      loadData: action,

      loading: observable,
      // loadStatusData: action,
      getVehicleData: action,
      getVehicles: action,
      addData: action,
      deleteRecord: action,
      getTrailerDetails: action,
      getSFDACategories: action,
      getTypeByCategory: action,
      renewVehicle: action,
      setLogoFromDb: action,
      getWeightBytId: action,
      getVehicleGroups: action,
      getDeviceGroups: action,
      sampleExcel: action,
      addVehicleByExcel: action,
      getVehicleRules: action,
      getFuelCaliDetails: action,

      getVehicleGroup: action,
      getVehicleSubGroup: action,
      downloadCertificate: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    offset,
    limit,
    searchField,
    status,
    isadmin,
    userId,
    usercompanyId,
  }) {
    console.log(offset, limit, usercompanyId);
    this.loading = true;
    console.log("vehicle list");
    var postData = {
      // usercompanyId: "1",
      companyId: companyId,
      isadmin: isadmin,
      userId: userId,
      offset: offset,
      limit: limit,
      searchField: searchField,
      status: status,
      usercompanyId,
    };
    //const base64Input = await base64_encode(JSON.stringify(postData));
    const base64Input = await utf8.encode(JSON.stringify(postData));
    const base2 = JSON.stringify(base64_encode(base64Input));

    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=vehicledetails", "postData=" + base2);
    console.log(response?.data?.data?.vehicledetails, "vehi");
    this.vehicles = response?.data?.data?.vehicledetails;
    this.total = parseInt(response?.data?.data?.TotalCount);
    console.log(this.total);
    this.loading = false;
  }

  async addData(vehicle) {
    console.log("data", vehicle);
    var postData = vehicle;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=update", "postData=" + base64Input);
    console.log(response);
    return response;
  }
  //For Groups in live tracking
  async getVehicleGroups(companyId, userId, isadmin) {
    var postData = { companyId, userId, isadmin };
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=user&a=getusergroupvehiclesnew",
        "postData=" + base64Input
      );
    console.log(response);
    return response;
  }

  async getVehicleData(vehicleId) {
    console.log(vehicleId, "id");
    const postData = { vehicleId: vehicleId, limit: 1, offset: 0 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=vehicledetails", "postData=" + base64Input);
    console.log(response);

    var singleRow = response?.data?.data?.vehicledetails[0];
    console.log(singleRow);
    //this.logoFromDb.disabled = true;

    if (singleRow.icon) {
      console.log(config.fleetURL + "/images/vehicles/" + singleRow.icon);

      const fileList = [
        {
          name: singleRow.icon,
          status: "success",
          url: config.fleetURL + "/images/vehicles/" + singleRow.icon,
        },
      ];
      this.setLogoFromDb(fileList);
      console.log(fileList, "filelist");
      //  }
    }

    console.log("editRecord", response);

    // return response?.data?.data?.vehicledetails[0];
    return singleRow;
  }

  // use For DropDown Asset

  async getVehicles(companyId) {
    // console.log("companyId", companyId)
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      usercompanyId: 1,
      companyId: companyId,
      isadmin: -1,
      offset: 0,
      limit: 100,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=vehicledetails", "postData=" + base64Input);
    console.log(response, "post");
    let allVehicles = [{ vehicleId: 0, vehicleNo: "ALL" }];
    allVehicles = [...allVehicles, ...response?.data?.data?.vehicledetails];
    this.assetVehicleList = allVehicles;
    return response?.data?.data?.vehicledetails;
  }

  async getBusNo(companyId) {
    // console.log("companyId", companyId)
    var postData = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      usercompanyId: 1,
      companyId: companyId,
      isadmin: -1,
      offset: 0,
      limit: 100,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=vehicledetails", "postData=" + base64Input);
    console.log(response, "post");
    // let allVehicles = [{ "vehicleId": 0, "vehicleNo": "ALL" }];

    this.assetVehicleList = response?.data?.data?.vehicledetails;
    return response?.data?.data?.vehicledetails;
  }

  async deleteRecord({ vehicleId, userId, full_imei }) {
    var postData = {
      tokenId: "",
      vehicle: { vehicle_id: vehicleId, userId: 1, full_imei: full_imei },
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=delete", "postData=" + base64Input);
    return response;
  }

  async getCurrentGps(vehicleId) {
    // console.log("vehicle list")
    var postData = { vehicleId: vehicleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=odometer&a=getGpsOdometer", "postData=" + base64Input);
    console.log(response, "post");
    return response?.data?.data;
  }

  async getOdometerByTime(vehicleId, datetime) {
    // console.log("vehicle list")
    var postData = { datetime: datetime, vehicleId: vehicleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=odometer&a=findOdometerByTime",
        "postData=" + base64Input
      );
    console.log(response, "post");
    return response?.data?.data;
  }
  async getPollingImeiData() {
    // console.log("vehicle list")
    // var postData = null;
    // const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getpollimie");
    // console.log(response?.data?.data, "post");

    this.getpollimie = response?.data?.data;
    return response?.data?.data;
  }

  // getVehicle Service use in reports
  // async getVehiclesCompanyId({ companyId }) {
  async getVehiclesCompanyId(companyId) {
    console.log("companyId" + companyId);
    // var postData = { companyId: "96" };
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, "post");
    this.getVehiclesUsingCompanyId = response?.data?.data;

    return response?.data?.data;
  }

  async getDeliveryVehicles(companyId) {
    console.log("companyId" + companyId);
    // var postData = { companyId: "96" };
    var postData = { companyId: companyId,isDelivery:1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    console.log(response, "post");
    this.getVehiclesUsingCompanyId = response?.data?.data;

    return response?.data?.data;
  }

  // To get Attach Trailer Dropdown
  async getTrailerDetails(companyId, vehicleId) {
    // console.log("vehicle list")
    var postData = { companyId: companyId, vehicleId: vehicleId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=trailer&a=getTrailers", "postData=" + base64Input);
    // console.log(response?.data?.data, "post")
    this.getTrailerUsingCompanyId = response?.data?.data;
    return response?.data?.data;
  }

  async getWeightBytId(tId) {
    // console.log("vehicle list")
    var postData = { tId: tId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=trailer&a=getWeightBytId", "postData=" + base64Input);
    console.log(response, "post");
    this.getWeightUsingtId = response?.data?.data;
    return response?.data?.data;
  }

  //To get Storing Category
  async getSFDACategories() {
    // console.log("vehicle list")
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=inventory&a=getSCategories",
        "postData=" + base64Input
      );
    // console.log(response?.data?.data, "post")
    this.getSCategories = response?.data?.data;
    return response?.data?.data;
  }

  async getTypeByCategory(categoryId) {
    // console.log("vehicle list")
    var postData = { categoryId: categoryId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=inventory&a=getTypeByCategory",
        "postData=" + base64Input
      );
    console.log(response, "post");
    this.getTypeofCategory = response?.data?.data;
    return response?.data?.data;
  }

  async renewVehicle(renewData) {
    // console.log("vehicle list")
    var postData = renewData;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=renewVehicle", "postData=" + base64Input);
    return response;
  }

  async setLogoFromDb(logo) {
    this.logoFromDb = { fileList: logo };
  }

  async exportExcel(getData) {
    window.location.href =
      config.baseURL +
      "index.php?c=vehicle&a=vehicleExportexcel&cid=" +
      getData.cid +
      "&isadmin=" +
      getData.isadmin +
      "&UcompanyId=" +
      getData.UcompanyId +
      "&status=" +
      getData.status +
      "&searchField=" +
      getData.searchField +
      "&colList=" +
      getData.colList;
  }

  async updateStatus(data) {
    console.log(data); //update active in-active
    var postData = data;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=updateActive", "postData=" + base64Input);
    return response.data;
  }

  async getDeviceGroups(companyId) {
    console.log(companyId);
    var postData = { companyId: companyId, offset: 0, limit: 50, page: 1 };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getDeviceGroups", "postData=" + base64Input);
    this.deviceGroups = response.data.data.devicegroups;
    return response.data.data.devicegroups;
  }

  async getDeviceGroupsofVehicles({ deviceGroupId, companyId, userId }) {
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: "1",
      groupNameId: deviceGroupId,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=company&a=getGrpvehicles", "postData=" + base64Input);
    this.getDeviceGroupsVehicles =
      response?.data?.data?.getDeviceGroupsVehicles;

    this.getVehiclesUsingCompanyId =
      response?.data?.data?.getDeviceGroupsVehicles;
    return response.data.data.getDeviceGroupsVehicles;
  }

  // async sampleExcel() {
  //   window.location.href = config.fleetURL + "/appRes/sampleVehicle.xlsx";
  // }

  async sampleExcel() {
    let isadmin = JSON.parse(base64_decode(Cookies.get("data"))).isadmin;
    if (isadmin == 1) {
      window.location.href = config.fleetURL + "/appRes/sampleVehicle.xlsx";
    } else {
      window.location.href = config.fleetURL + "/appRes/vehicle.xlsx";
    }
  }

  async addVehicleByExcel(excelFile) {
    console.log(excelFile);
    this.excelLoadig = true;
    var postData = excelFile;

    const base64Input = await base64_encode(JSON.stringify(postData));
    let isadmin = JSON.parse(base64_decode(Cookies.get("data"))).isadmin;
    console.log(isadmin);
    if (isadmin == 1) {
      var response = await postApi
        .getClient()
        .post(
          "index.php?c=vehicle&a=importVehicleInsert",
          "postData=" + base64Input
        );
    } else {
      var response = await postApi
        .getClient()
        .post(
          "index.php?c=vehicle&a=importVehicleNew",
          "postData=" + base64Input
        );
    }

    this.excelLoadig = false;
    return response.data;
  }

  async getVehicleRules(companyId) {
    console.log(companyId);
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getRules", "postData=" + base64Input);
    this.vehicleRules = response.data.data;
    return response.data.data;
  }

  async getFuelCaliDetails() {
    var postData = null;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=fuelcalibration&a=getFuelCalibrationDetails",
        "postData=" + base64Input
      );
    this.fuelDetails = response.data.data;
    return response.data.data;
  }

  async getVehicleGroup({ companyId, userId, isadmin }) {
    console.log("getdata");
    var postData = { companyId: companyId, userId: userId, isadmin: isadmin };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=devicegroup&a=getVehicleGropups",
        "postData=" + base64Input
      );

    this.groupList = response.data.data;
    return response;
  }

  async getVehicleSubGroup({
    deviceGroupId,
    deviceSubGroupId,
    companyId,
    userId,
    isadmin,
    subgroup,
  }) {
    var postData = {
      deviceGroupId: deviceGroupId,
      deviceSubGroupId: deviceSubGroupId,
      companyId: companyId,
      userId: userId,
      isadmin: isadmin,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=devicegroup&a=getGroups", "postData=" + base64Input);

    if (subgroup) {
      this.subGroupList = response.data.data?.subgroup;
      this.getVehiclesUsingCompanyId = response.data.data?.vehicles;
    } else {
      this.vehicles = response.data.data?.vehicles;
      this.getVehiclesUsingCompanyId = response.data.data?.vehicles;
    }

    return response.data.data?.vehicles;
  }

  async downloadCertificate(vid) {
    // console.log(vid)
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=downloadCertificatePdf&vid=" + vid);
  }

  async globalSearchList({input,offset,limit}) {
    var postData = {
      companyId: "1",
      isadmin: "-1",
      issuperadmin: "1",
      userId: 1,
      input: input,
      offset: offset,
      limit: limit,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=globalSearch", "postData=" + base64Input);
    console.log(response?.data?.data?.data);

    this.globalSearchData = response?.data?.data?.data
  }
}

export default VehicleStore;
