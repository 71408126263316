import { Button } from "antd";

import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
/*"displayDomains" : ["awtltrack"],*/

const UserListFields = (props) => {
  // console.log('props', props.UsersStore?.users?.length);
  const shouldShow = shouldShowComponent(config.project, "UserListFields");
  const storeContext = useContext(StoreContext);

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <Button
        disabled={props.UsersStore?.length > 0 ? false : true}
        style={{ backgroundColor: config.activeColor }}
        onClick={(val) => props.handleExportClick(val)}
      >
        {/* Export */}
        {storeContext.translate("_EXPORT_")}
      </Button>
    </>
  );
};

UserListFields.contextType = StoreContext;
export default observer(withRouter(UserListFields));
