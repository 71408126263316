import {
  Box,
  CarFront,
  ExclamationTriangle,
  Envelope,
  Gear,
  Clipboard2Data,
} from "react-bootstrap-icons";
import LounchingDashboard from "../components/LounchingDashboard";
import React from "react";
import { Image, Layout, Menu, Space } from "antd";
import { Content } from "antd/es/layout/layout";
import { Outlet } from "react-router-dom";
import Sider from "antd/es/layout/Sider";
import logoIcon from "../assets/images/userPhoto.jpg";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { decode as base64_decode, encode as base64_encode } from "base-64";
// import Cookies from "js-cookie";
import Cookies from "universal-cookie";
import {
  AppstoreOutlined,
  LineChartOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import config from "../config";
import HelpModal from "./HelpModal";
import AccountMenus from "./Plugins/AddMenu/AccountMenus";
import MenuSettingStore from "../store/MenuSettingStore";

let rolePermission = [];

class Menu1 extends React.Component {
  constructor(prop, context) {
    super(prop);
    this.cookies = new Cookies();
    this.state = {
      collapsed: false,
      marginLeft: 80,
      settingColor: "white",
      userName: prop.getUserData().userName,
      photo: prop.getUserData().photo,
      settingMenuShow: false,
      showHelpModal: false,
      isLaunchingDashboard: false,
      project: config.project,
      menusData: [],
      menuItem: [],
    };
    this.settingMenuIconsStyle = {
      padding: "3px",
      borderRadius: "50%",
      border: "1px solid",
      marginInlineEnd: "10px",
    };

    this.menuSettingStore = new MenuSettingStore(this);

    this.settingMenu = (
      <div className="menu-popover-content">
        <Menu
          mode="vertical"
          className="settingMenu"
          theme="dark"
          onClick={this.selectMenuItems}
          style={{ padding: "0px !important" }}
        >
          <Menu.Item key="menu-item-1">
            <div
              onClick={async () => {
                await this.setState({ settingMenuShow: false });
              }}
            >
              <i class="bi bi-question" style={this.settingMenuIconsStyle}></i>
              {/* Help */}
              {context.translate("_HELP_")}
            </div>
          </Menu.Item>
          <Menu.Item key="menu-item-2">
            <div
              onClick={async () => {
                await this.setState({ settingMenuShow: false });
                await this.navigateHandler("companychangepassword");
              }}
            >
              {" "}
              <i class="bi bi-key" style={this.settingMenuIconsStyle}></i>
              {/* Change Password */}
              {context.translate("_FLEET_MANAGEUSER_CHANGEPASSWORD_TAB_")}
            </div>
          </Menu.Item>
          <Menu.Item key="menu-item-3">
            <div onClick={() => this.navigateHandler("logout")}>
              {" "}
              <i class="bi bi-power" style={this.settingMenuIconsStyle}></i>
              {/* Logout */}
              {context.translate("_FLEET_LOGOUT_")}
            </div>
          </Menu.Item>

          <Menu.Item key="menu-item-4">
            <div onClick={() => this.navigateHandler("companymenusetting")}>
              {" "}
              <i
                class="bi bi-menu-button-wide"
                style={this.settingMenuIconsStyle}
              ></i>
              {/* Menu Setting */}
              {context.translate("_MENU_SETTING_")}
            </div>
          </Menu.Item>

          <Menu.Item key="menu-item-5">
            <div
              onClick={() => this.navigateHandler("companydevicetestinglist")}
            >
              {" "}
              <i
                class="bi bi-device-ssd"
                style={this.settingMenuIconsStyle}
              ></i>
              {/* Device Testing */}
              {context.translate("_DEVICE_TESTING_")}
            </div>
          </Menu.Item>
          <Menu.Item key="menu-item-6">
            <div
              onClick={() =>
                this.navigateHandler("companydriverbehaviorsetting")
              }
            >
              {" "}
              <i
                class="bi bi-people-fill"
                style={this.settingMenuIconsStyle}
              ></i>
              {/* Driver Behaviour Setting */}
              {context.translate("_DRIVER_BEHAVIOR_SETTINGS_")}
            </div>
          </Menu.Item>
        </Menu>
      </div>
    );
    rolePermission = prop.props?.rolePermission;
  }
  async componentDidMount() {
    document.body.style.direction =
      this.context.locale.language === "english" ? "ltr" : "rtl";

    // document.body.style.background === drive7-wrapper
    if (config.project === "drive7") {
      document.body.classList.add("drive7-wrapper");
    }

    const rolePermission = JSON.parse(
      base64_decode(this.cookies.get("data"))
    ).rolePermission;
    const isadmin = JSON.parse(base64_decode(this.cookies.get("data"))).isadmin;

    var innerLogo = require("../assets/images/" + config.innerLogo);

    console.log("innerLogo1**", innerLogo);
    if (JSON.parse(base64_decode(this.cookies.get("data"))).logo) {
      innerLogo =
        config.fleetURL +
        "/images/companylogo/" +
        JSON.parse(base64_decode(this.cookies.get("data"))).logo;
      console.log("ifinnerLogo**", innerLogo);
    }

    const menusData = await this.menuSettingStore.getModules({
      roleId: "",
    });
    // console.log(menusData.modules, "menusData");
    this.mapMenuItems(menusData.modules);
    this.setState({ menusData: menusData.modules });
    this.state.innerLogo = innerLogo;

    let hideDrive7Menus = config.project;
    // console.log(hideDrive7Menus)

    let arr = [];
    function getItem(label, key, icon, children, parent) {
      arr.push(key);
      if (key == "companyList" && isadmin == 0) {
        return;
      }

      if (
        (isadmin === 1 || isadmin === 0) &&
        (key === "notificationlist" ||
          key === "simlist" ||
          key === "devicelist" ||
          key === "deviceloglist" ||
          key === "companydevicetestinglist" ||
          key === "inspectionlist" ||
          key === "gprscommand" ||
          key === "useractivityreportlist" ||
          key === "assetregisterreportlist" ||
          key === "paymentlist" ||
          key === "parentpayment" ||
          key === "parentschoolmanagement")
      ) {
        return;
      }
      if (isadmin == 3 && key === "parentpayment") {
        return;
      }
      // if (
      //   config.project === "drive7" &&
      //   (key === "taxiCustomers" ||
      //     key === "discountList" ||
      //     key === "advertisementList" ||
      //     key === "audiomessage" ||
      //     key === "poiList" ||
      //     key === "newsList" ||
      //     key === "eventList" ||
      //     key === "queueList" ||
      //     key === "specialOfferList" ||
      //     key === "taxiTypeList" ||
      //     key === "vehicleAudit" ||
      //     key === "newMaintenanceList" ||
      //     key === "vehiclesubgrouplist" ||
      //     key === "serviceTemplate" ||
      //     key === "VehicleFuelCalibrationList" ||
      //     key === "parentjobmanagement" ||
      //     key === "parenttaximanagement" ||
      //     key === "predefinedMesssage" ||
      //     key === "detailedjobreport" ||
      //     key === "deliverymanagementreport")
      // ) {
      //   return;
      // }

      if (config.project === "drive7" && key === "parenttaximanagement") {
        return;
      }

      if (
        (key === "certificateReport" ||
          key === "assignGeofenceToCompany" ||
          key === "addSocialMedia" ||
          key === "rtaOfficials") &&
        config.project !== "drive7"
      ) {
        return;
      }
      if (
        rolePermission.includes(key) ||
        rolePermission === "All" ||
        (key === "passengerList2/1" &&
          rolePermission.includes("passengerList")) ||
        rolePermission.includes("logout")
      ) {
        return {
          key,
          icon,
          children,
          label,
        };
      }
    }

    this.menuItems = [
      {
        label: this.context.translate("_TAXI_CUSTOMERS_"),
        key: "taxiCustomers",
      },
      { label: this.context.translate("_DISCOUNT_LIST_"), key: "discountList" },
      {
        label: this.context.translate("_ADVERTISEMENT_"),
        key: "advertisementList",
      },
      { label: this.context.translate("_AUDIO_MESSAGE_"), key: "audiomessage" },
      { label: this.context.translate("_POINT_OF_INTEREST_"), key: "poiList" },
      { label: this.context.translate("_EVENT_"), key: "eventList" },
      { label: this.context.translate("_NEWS_"), key: "newsList" },
      {
        label: this.context.translate("_SPECIAL_OFFER_"),
        key: "specialOfferList",
      },
    ];

    // }

    this.items = [
      // getItem('Masters', 'master', <Box size={20} />,
      getItem(
        this.context.translate("_MASTERS_"),
        "master",
        <Box size={20} />,
        [
          getItem(
            this.context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
            "parentaccount",
            null,
            [
              getItem(this.context.translate("_COMPANY_LIST_"), "companyList"),
              getItem(
                this.context.translate("_FLEET_USER_USERLIST_TITLE_"),
                "companyuserlist"
              ),
              getItem(
                this.context.translate("_FLEET_DRIVER_DRIVERLIST_TITLE_"),
                "companydriverslist"
              ),
              getItem(this.context.translate("_EMPLOYEE_"), "companystafflist"),
              getItem(
                this.context.translate("_PASSENGER_LIST_"),
                "passengerList"
              ),
              getItem(
                this.context.translate("_RTA_OFFICIALS_"),
                "rtaOfficials"
              ),
              // <AccountMenus getItem={getItem()} menuItems={this.menuItems}    onMenusGenerated={this.handleMenusGenerated} />

              getItem(
                this.context.translate("_TAXI_CUSTOMERS_"),
                "taxiCustomers"
              ),
              getItem(
                this.context.translate("_DISCOUNT_LIST_"),
                "discountList"
              ),
              getItem(
                this.context.translate("_ADVERTISEMENT_"),
                "advertisementList"
              ),
              getItem(
                this.context.translate("_AUDIO_MESSAGE_"),
                "audiomessage"
              ),
              getItem(this.context.translate("_POINT_OF_INTEREST_"), "poiList"),
              getItem(this.context.translate("_EVENT_"), "eventList"),
              getItem(this.context.translate("_NEWS_"), "newsList"),
              getItem(
                this.context.translate("_SPECIAL_OFFER_"),
                "specialOfferList"
              ),
            ]
          ),
          // [getItem('Account','parentaccount',null,
          //   [getItem('Company List', 'companyList',),
          //   getItem('User List', 'companyuserlist',),
          //   getItem('Driver List', 'companydriverslist',),
          //   getItem('Staff List', 'companystafflist',),
          //   getItem('Passenger List', 'passengerList',),

          // ]),
          //  getItem('Vehicle Management','vehiclemanagement',null,[
          //       getItem('Vehicle List', 'vehiclelist',),
          //       getItem(' Vehicle Allocate List', 'VehicleAllocateList',),
          //       getItem(' Vehicle Return Asset List', 'vehiclereturnassetlist',),
          //       getItem('Vehicle Maintenance List', 'vehiclemaintenancelist',),
          //       getItem('Vehicle Temp Sensor List ', 'VehicleTempSensorList',),
          //       getItem(' Vehicle Group List', 'VehicleGroupList',),
          //       getItem(' Vehicle Working Hours List', 'VehicleWorkingHoursList',),
          //       getItem(' Vehicle Distance List', 'vehicledistancelist',),
          //       getItem(' Vehicle Live Location List', 'VehicleLiveLocationList',),
          //       getItem(' Vehicle Odometer List', 'VehicleOdometerList',),
          //       getItem(' Vehicle Trailer List', 'VehicleTrailerList',),
          //       getItem(' Vehicle Fuel Calibration List', 'VehicleFuelCalibrationList',),

          //     ]),
          getItem(
            this.context.translate("_VEHICLE_MANAGEMENT_"),
            "vehiclemanagement",
            null,
            [
              // getItem('Vehicle List', 'vehiclelist',),
              getItem(this.context.translate("_VEHICLE_LIST_"), "vehiclelist"),
              getItem(
                this.context.translate("_ALLOCATE_VEHICLE_DETAILS_LIST_"),
                "VehicleAllocateList"
              ),
              getItem(
                this.context.translate("_RETURN_ASSET_LIST_"),
                "vehiclereturnassetlist"
              ),
              // getItem('Vehicle Maintenance List', 'vehiclemaintenancelist',),
              getItem(
                this.context.translate("_VEHICLE_MAINTENANCE_LIST_"),
                "vehiclemaintenancelist"
              ),

              getItem(
                this.context.translate("_NEW_MAINTENANCE_"),
                "newMaintenanceList"
              ),

              getItem(
                this.context.translate("_VEHICLE_TEMP_SENSOR_LIST_"),
                "VehicleTempSensorList"
              ),
              // getItem(' Vehicle Group List', 'VehicleGroupList',),
              getItem(
                this.context.translate("_VEHICLE_GRP_LIST_"),
                "VehicleGroupList"
              ),
              getItem(
                this.context.translate("_SUB_GROUP_"),
                "vehiclesubgrouplist"
              ),
              getItem(
                this.context.translate("_VEHICLE_WORKING_HOURS_LIST_"),
                "VehicleWorkingHoursList"
              ),
              // getItem(' Vehicle Distance List', 'vehicledistancelist',),
              getItem(
                this.context.translate("_VEHICLE_DISTANCE_LIST_"),
                "vehicledistancelist"
              ),
              // getItem(' Vehicle Live Location List', 'VehicleLiveLocationList',),
              getItem(
                this.context.translate("_VEHICLE_LIVE_LOCATION_LIST_"),
                "VehicleLiveLocationList"
              ),

              getItem(
                this.context.translate("_VEHICLE_FUEL_CALIBERATION_LIST_"),
                "VehicleFuelCalibrationList"
              ),
              // getItem(' Vehicle Odometer List', 'VehicleOdometerList',),
              getItem(
                this.context.translate("_VEHICLE_ODOMETER_LIST_"),
                "VehicleOdometerList"
              ),
              getItem(
                this.context.translate("_SERVICE_TEMPLATE_"),
                "serviceTemplate"
              ),
              getItem(
                this.context.translate("_VEHICLE_AUDIT_"),
                "vehicleAudit"
              ),
              getItem(
                this.context.translate("_TAXI_TYPE_LIST_"),
                "taxiTypeList"
              ),
              getItem(this.context.translate("_QUEUE_LIST_"), "queueList"),
              // getItem(
              //   this.context.translate("_VEHICLE_TRAILER_LIST_"),
              //   "VehicleTrailerList"
              // ),
              // getItem(' Vehicle Fuel Calibration List', 'VehicleFuelCalibrationList',),
            ]
          ),
          //  getItem('Fuel Management','fuelmanagement',null,[
          //       getItem('Fuel Details', 'fueldetailslist',),
          //       getItem('Fuel Filled', 'fuelfilledlist',),
          // ]),
          getItem(
            this.context.translate("_FUEL_MANAGEMENT_"),
            "parentfuelmanagement",
            null,
            [
              getItem(
                this.context.translate("_FUEL_DETAIL_"),
                "fueldetailslist"
              ),
              getItem(
                this.context.translate("_FUEL_FILLED_"),
                "fuelfilledlist"
              ),
            ]
          ),

          getItem(
            this.context.translate("_ASSIGN_GEOFENCE_"),
            "assignGeofenceToCompany",
            null,
            [
              getItem(
                this.context.translate("_ASSIGN_GEOFENCE_TO_COMPANY_"),
                "assignGeofenceToCompany"
              ),
            ]
          ),
          // getItem('School Management', 'schoolmanagement', null, [
          getItem(
            this.context.translate("_SCHOOL_MANAGEMENT_"),
            "parentschoolmanagement",
            null,
            [
              // getItem('Passenger List', 'passengerList2/1',),
              getItem(
                this.context.translate("_PASSENGER_LIST_"),
                "passengerList2/1"
              ),
              // getItem('Grade Bus Timing List', 'gradetiminglist',),
              getItem(
                this.context.translate("_GRADE_BUS_TIMING_LIST_"),
                "gradetiminglist"
              ),
              // getItem('School Calendar List', 'schoolcalenderlist',),
              getItem(
                this.context.translate("_SCHOOL_CALENDER_LIST_"),
                "schoolcalenderlist"
              ),

              // getItem('Create/Assign Trip List', 'assigntriplist',),

              // getItem('Parent List', 'parentlist',),
              getItem(this.context.translate("_PARENT_LIST_"), "parentlist"),
            ]
          ),
          // getItem('Job Management', 'Jobmanagement', null, [
          //   getItem('Job Management List', 'jobmanagementlist',),
          //   getItem('Job Request List', 'jobrequestlist',),
          // ]),
          getItem(
            this.context.translate("_JOB_MANAGEMENT_"),
            "parentjobmanagement",
            null,
            [
              getItem(
                this.context.translate("_JOB_MANAGEMENT_LIST_"),
                "jobmanagementlist"
              ),
              getItem(
                this.context.translate("_JOB_REQUEST_LIST_"),
                "jobrequestlist"
              ),
              getItem(
                this.context.translate("_ROUTE_MANAGEMENT_"),
                "routemanagementlist"
              ),
            ]
          ),

          getItem(
            this.context.translate("_TAXI_MANAGEMENT_"),
            "parenttaximanagement",
            null,
            [
              getItem(this.context.translate("_FARE_DETAILS_"), "fareDetails"),
              getItem(
                this.context.translate("_TAXI_DEFAULT_PARAMETERS_"),
                "taxiDefaultParameters"
              ),
            ]
          ),

          // getItem('Trip Management', 'tripmanagement', null, [
          //   getItem('Create/Assign Trip Details', 'assigntriplist',),
          //   getItem('Trip Cost', 'tripCostList',),
          //   // getItem('Job Request List', 'jobrequestlist',),
          // ]),
          getItem(
            this.context.translate("_TRIP_MANAGEMENT_"),
            "parenttripmanagement",
            null,
            [
              getItem(
                this.context.translate("_TRIP_DETAILS_ASSIGN_"),
                "assigntriplist"
              ),
              // getItem(this.context.translate("_TRIP_COST_"), "tripCostList"),
              // getItem('Job Request List', 'jobrequestlist',),
            ]
          ),
          // getItem('Payment', 'payment', null, [
          //   getItem('Payment', 'paymentlist',),

          // ]),
          // getItem(this.context.translate("_PAYMENT_"), "parentpayment", null, [
          //   getItem(this.context.translate("_PAYMENT_"), "paymentlist"),
          // ]),
          // getItem('Warehouse', 'warehouse', null, [
          //   getItem('Warehouse List', 'warehouselist',),

          // ]),
          // getItem(
          //   this.context.translate("_FLEET_DASHBORD_NEWPOI_TYPEDDL_WAREHOUSE_"),
          //   "parentwarehouse",
          //   null,
          //   [
          //     getItem(
          //       this.context.translate("_WAREHOUSE_LIST_"),
          //       "warehouselist"
          //     ),
          //   ]
          // ),
          // getItem('Inventory', 'inventory', null, [
          //   getItem('Inventory List', 'inventorylist',),
          // ]),
          // getItem(
          //   this.context.translate("_INVENTORY_"),
          //   "parentinventory",
          //   null,
          //   [
          //     getItem(
          //       this.context.translate("_INVENTORY_LIST_"),
          //       "inventorylist"
          //     ),
          //   ]
          // ),
        ]
      ),
      // getItem('Tracking', 'parenttracking', <CarFront size={20} />,
      getItem(
        this.context.translate("_TRACKING_"),
        "parenttracking",
        <CarFront size={20} />,
        // [getItem('Live Tracking', 'livetracking'),
        [
          getItem(this.context.translate("_LIVE_TRACING_"), "livetracking"),
          // getItem('Detail Tracking', 'detailtracking'),

          getItem(
            this.context.translate("_DETAIL_TRACKING_"),
            "detailtracking"
          ),
          getItem(this.context.translate("_TRACK_BY_GROUP_"), "trackbygroup"),
          // getItem('Dashboard', 'dashboard'),
          getItem(this.context.translate("_DASHBOARD_"), "dashboard"),
          // getItem('Maintenance Dashboard', 'maintenancedashboard'),
          getItem(
            this.context.translate("_MAINTENANCE_DASHBOARD_"),
            "maintenancedashboard"
          ),
          // getItem('Multi Tracking', 'multitracking'),
          getItem(this.context.translate("_MULTI_TRACKING_"), "multitracking"),
          // getItem('Live Route Path', 'liveroutepath'),
          getItem(this.context.translate("_LIVE_ROUTE_PATH_"), "liveroutepath"),
          // getItem('History Tracking', 'historytracking'),
          getItem(
            this.context.translate("_HISTORY_TRACKING_"),
            "historytracking"
          ),
          // getItem('Advance Dashboard', 'advancedashboard')
          getItem(
            this.context.translate("_ADVANCE_DASHBOARD_"),
            "AdvanceDashboard"
          ),
          getItem(
            this.context.translate("_SENSOR_REPORT_"),
            "sensorreportlist"
          ),
          getItem(
            this.context.translate("_WAREHOUSE_MONITORING_"),
            "warehousemonitoring"
          ),

          getItem(
            this.context.translate("_TEMPERATURE_DASHBOARD_"),
            "temperaturedashboard"
          ),
          getItem(this.context.translate("_HEAT_MAP_"), "heatmap"),
          getItem(
            this.context.translate("_VIDEO_MONITORING_"),
            "videomonitoring"
          ),
          getItem(
            this.context.translate("_MULTI_VIDEO_MONITORING_"),
            "multivideomonitoring"
          ),
        ]
      ),

      // getItem('Reports', 'parentreport', <Clipboard2Data size={20} />,
      getItem(
        this.context.translate("_FLEET_REPORTS_TAB_"),
        "parentreport",
        <Clipboard2Data size={20} />,
        // [getItem('Tracking Report', 'parenttrackingreport', null,
        [
          getItem(
            this.context.translate("_TRACKING_REPORT_"),
            "parenttrackingreport",
            null,
            // [getItem('Track Data Report', 'trackdatalist',),
            [
              getItem(
                this.context.translate("_TRACK_DATA_REPORT_"),
                "trackdatalist"
              ),
              // getItem('Travel Report', 'travelreportlist',),
              getItem(
                this.context.translate("_FLEET_REPORTS_STARTSTOPREPORT_TITLE_"),
                "travelreportlist"
              ),
              // getItem('Stoppage Report', 'stoppagereportlist',),
              getItem(
                this.context.translate("_FLEET_REPORTS_STOPREPORT_TITLE_"),
                "stoppagereportlist"
              ),
              // getItem('Trip  Report', 'tripreportlist',),
              getItem(
                this.context.translate("_TRIP_REPORT_"),
                "tripreportlist"
              ),
              // getItem('IDLE Report', 'idlereportlist',),
              getItem(
                this.context.translate("_IDLE_REPORT_"),
                "idlereportlist"
              ),
              // getItem('IDLE Report', 'idlereportlist',),
              // getItem('EX-IDLE Report', 'exidlereportlist',),
              getItem(
                this.context.translate("_EXIDLE_REPORT_"),
                "exidlereportlist"
              ),

              // getItem('Distance Report', 'distancereportlist',),
              getItem(
                this.context.translate("_DISTANCE_REPORT_"),
                "distancereportlist"
              ),
              // getItem('Command Sent Report', 'commandsentreportlist',),
              // getItem(
              //   this.context.translate("_COMMAND_SENT_REPORT_"),
              //   "commandsendreportlist"
              // ),
              // getItem('Can Bus Report', 'canbusreportstorelist',),
              getItem(
                this.context.translate("_CANBUS_REPORT_"),
                "canbusreportstorelist"
              ),
              // getItem('Machinery Report', 'machineryseportlist',),
              getItem(
                this.context.translate("_GENSET_WORKING_HOUR_REPORT_"),
                "machineryseportlist"
              ),
              // getItem('Stationary Report', 'stationaryreportlist',),
              getItem(
                this.context.translate("_STATIONARY_REPORT_"),
                "stationaryreportlist"
              ),
              // getItem('Weight Report', 'weightreportlist',),
              getItem(
                this.context.translate("_WEIGHT_REPORT_"),
                "weightreportlist"
              ),
              getItem(
                this.context.translate("_VEHICLE_PERFORMANCE_REPORT_"),
                "vehicleperformancelist"
              ),
              getItem(
                this.context.translate("_WORKING_HOURS_REPORT_"),
                "workinghoursreportlist"
              ),
            ]
          ),
          // getItem('Miscellaneous Report', 'parentmiscellaneousreport', null,
          getItem(
            this.context.translate("_MISCELLANEOUS_REPORTS_"),
            "parentmiscellaneousreport",
            null,
            // [getItem('User Login Report', 'userloginreportlist'),
            [
              // getItem('Asset Register Report', 'assetregisterreportlist'),

              // getItem('Event Report', 'eventreportlist'),
              getItem(
                this.context.translate("_EVENT_REPORT_"),
                "eventreportlist"
              ),
              // getItem('Raw Data Report', 'rawdatareportlist'),

              // getItem('Passenger Log Report', 'passengerlogreportlist'),
              getItem(
                this.context.translate("_PASSENGER_LOG_REPORT_"),
                "passengerlogreportlist"
              ),
              // getItem('Attendence Report', 'attendencereportlist'),
              getItem(
                this.context.translate("_ATTENDANCE_REPORT_"),
                "attendencereportlist"
              ),
              // getItem('Vehicle Performance Report', 'vehicleperformancelist'),

              // getItem('Working Hours Report', 'workinghoursreportlist'),

              // getItem('MDVR Alarm Report', 'mdvralarmreportlist'),

              // getItem("Black List Person Report", "blacklistpersonreportlist"),
              getItem(
                this.context.translate("_BLACKLIST_PERSON_REPORT_"),
                "blacklistpersonreportlist"
              ),

              getItem(
                this.context.translate("_DELIVERY_MANAGEMENT_REPORT_"),
                "deliverymanagementreport"
              ),

              getItem(
                this.context.translate("_DETAILED_JOB_REPORT_"),
                "detailedjobreport"
              ),

              getItem(
                this.context.translate("Daily Job Report"),
                "dailyjobreport"
              ),
              // getItem('WASL Report', 'waslreportlist'),

              // getItem('Sensor Report', 'sensorreportlist'),

              // getItem('Inventory Status Report', 'inventorystatusrepostlist'),

              // getItem('User Activity Report', 'useractivityreportlist'),
            ]
          ),
          getItem(
            this.context.translate("_DRIVER_RELATED_"),
            "parentdriverreport",
            null,
            // [getItem('Driver Score Report', 'driverscorereportlist',),
            [
              getItem(
                this.context.translate("_DRIVER_SCORE_REPORT_"),
                "driverscorereportlist"
              ),
              // getItem('Over Time Report', 'overtimereportlist',),
              getItem(
                this.context.translate("_OVER_TIME_REPORT_"),
                "overtimereportlist"
              ),
              // getItem('Driver Login Report List', 'DriverLoginReportList',),
              // getItem('Driver Performance Report List', 'DriverPerformanceReportList',),
              // getItem('Driver Complain Report List', 'DriverComplainReportList',),
              getItem(
                this.context.translate("_DRIVER_LOGIN_REPORT_"),
                "DriverLoginReportList"
              ),
              getItem(
                this.context.translate("_DRIVER_PERFORMANCE_REPORT_"),
                "DriverPerformanceReportList"
              ),
              getItem(
                this.context.translate("_DRIVER_COMPLAIN_REPORT_"),
                "DriverComplainReportList"
              ),
            ]
          ),
          // getItem('Fleet Report', 'fleetReport', null,
          getItem(
            this.context.translate("_FLEET_REPORT_"),
            "parentfleetreport",
            null,
            [
              // getItem('Fleet Summary Report ', 'FleetSummaryReportList',),
              // getItem('Fuel Filled Report ', 'FuelFilledReportList',),
              // getItem('Mileage Report', 'MileageReportList',),
              // getItem('Maintenance Fleet Report ', 'MaintenanceFleetReportList',),
              getItem(
                this.context.translate(
                  "_FLEET_DAY_SUMMRY_REPORTS_FLEETREPORT_TITLE_"
                ),
                "fleetdaysummaryreport"
              ),
              getItem(
                this.context.translate("_FLEET_REPORTS_FLEETREPORT_TITLE_"),
                "FleetSummaryReportList"
              ),
              getItem(
                this.context.translate("_FUEL_FILLED_REPORT_"),
                "FuelFilledReportList"
              ),
              getItem(
                this.context.translate("_MILEAGE_REPORT_"),
                "MileageReportList"
              ),
              getItem(
                this.context.translate(
                  "_FLEET_REPORTS_MAINTENANCEREPORT_TITLE_"
                ),
                "MaintenanceFleetReportList"
              ),
            ]
          ),

          // getItem('Alert Report', 'parentalertreport', null,
          getItem(
            this.context.translate("_ALERT_REPORT_"),
            "parentalertreport",
            null,
            [
              // getItem('Speed Violation Report ', 'SpeedViolationReportList',),
              getItem(
                this.context.translate("_SPEED_VIOLATION_REPORT_"),
                "SpeedViolationReportList"
              ),
              // getItem('Geofence Or POI In Out Report', 'geofenceinoroutreportlist',),
              getItem(
                this.context.translate("_FLEET_REPORTS_GEOFENCEREPORT_TITLE_"),
                "geofenceinoroutreportlist"
              ),
              // getItem('Temp Report ', 'TempReportList',),
              getItem(
                this.context.translate("_TEMP_REPORT_"),
                "TempReportList"
              ),
              // getItem('Geofence Summary Report', 'GeofenceSummaryList',),
              getItem(
                this.context.translate("_GEOFENCE_SUMMARY_REPORT_"),
                "GeofenceSummaryList"
              ),
              // getItem('Vehicle Not Poll Report', 'VehicleNotPollReportList',),
              getItem(
                this.context.translate("_VEHICLE_NOT_POLL_REPORT_"),
                "VehicleNotPollReportList"
              ),
              // getItem('Rule Trigger Report', 'RuleTriggerList',),
              getItem(
                this.context.translate("_RULE_TRIGGER_REPORT_"),
                "RuleTriggerList"
              ),
              // getItem('Geofence Visit Report ', 'GeofenceVisitReportList',),
              getItem(
                this.context.translate("_GEOFENCE_VISIT_REPORT_"),
                "GeofenceVisitReportList"
              ),
              getItem(
                this.context.translate("_MDVR_ALARM_REPORT_"),
                "mdvralarmreportlist"
              ),
            ]
          ),
          getItem(
            this.context.translate("_ADMIN_REPORT_"),
            "parentadminreport",
            null,
            [
              getItem(
                this.context.translate("_USER_LOGIN_REPORT_"),
                "userloginreportlist"
              ),
              getItem(
                this.context.translate("_ASSET_REGISTER_REPORT_"),
                "assetregisterreportlist"
              ),
              getItem(
                this.context.translate("_COMMAND_SENT_REPORT_"),
                "commandsendreportlist"
              ),
              getItem(
                this.context.translate("_RAW_DATA_REPORT_"),
                "rawdatareportlist"
              ),
              getItem(
                this.context.translate("_USER_ACTIVITY_REPORT_"),
                "useractivityreportlist"
              ),
            ]
          ),
          // getItem(
          //   this.context.translate("_WASL_REPORT_"),
          //   "parentwaslreport",
          //   null,
          //   [
          //     getItem(
          //       this.context.translate("_WASL_REPORT_"),
          //       "waslreportlist"
          //     ),
          //     getItem(
          //       this.context.translate("_WASL_BUS_TRIP_REPORT_"),
          //       "waslbustripresponselist"
          //     ),
          //     getItem(
          //       this.context.translate("_INVENTORY_STATUS_REPORT_"),
          //       "inventorystatusrepostlist"
          //     ),
          //   ]
          // ),
        ]
      ),

      // getItem('Email Report', 'parentemailreport', <Envelope size={20} />, [getItem('Email Report', 'EmailReportList',),
      getItem(
        this.context.translate("_EMAIL_REPORT_"),
        "parentemailreport",
        <Envelope size={20} />,
        [getItem(this.context.translate("_EMAIL_REPORT_"), "EmailReportList")]
      ),

      // getItem('Settings', 'parentsettings', <Gear size={20} />, [
      getItem(
        this.context.translate("_SETTINGS_"),
        "parentsettings",
        <Gear size={20} />,
        [
          // getItem('Notification List', 'notificationlist',),
          getItem(
            this.context.translate("_NOTIFICATION_LIST_"),
            "notificationlist"
          ),
          // getItem('SIM List', 'simlist',),
          getItem(this.context.translate("_SIM_LIST_"), "simlist"),
          // getItem('Device Log List', 'deviceloglist',),
          getItem(this.context.translate("_DEVICE_LOG_LIST_"), "deviceloglist"),

          getItem(
            this.context.translate("_FLEET_RULEADMIN_RULEDETAIL_EMAILMESSAGE_"),
            "emailmessage"
          ),
          // getItem('Inspection', 'inspectionlist'),
          getItem(this.context.translate("_INSPECTION_"), "inspectionlist"),
          // getItem('GPRS Command', 'gprscommand',),
          getItem(this.context.translate("_GPRS_COMMAND_"), "gprscommand"),
          // getItem('Email Message', 'emailmessage',),
          getItem(this.context.translate("_ADD_LOCATION_"), "addLocation"),

          getItem(this.context.translate("FAQ List"), "faqList"),

          getItem(
            this.context.translate("_PREDEFINED_MESSAGES_LIST_"),
            "predefinedMesssage"
          ),

          getItem(
            this.context.translate("_CERTIFICATE_REPORT_"),
            "certificateReport"
          ),
          getItem(this.context.translate("_SOCIAL_MEDIA_"), "addSocialMedia"),
          // getItem('Device List', 'devicelist',),
          getItem(
            this.context.translate("_FLEET_GROUP_GROUPLIST_TITLE_"),
            "devicelist"
          ),

          // getItem('Change Password', 'companychangepassword',),
          getItem(
            this.context.translate("_FLEET_MANAGEUSER_CHANGEPASSWORD_TAB_"),
            "companychangepassword"
          ),
          // getItem('Menu Setting', 'companymenusetting',),
          // getItem(
          //   this.context.translate("_MENU_SETTING_"),
          //   "companymenusetting"
          // ),
          // getItem('Device Testing', 'companydevicetestinglist',),
          // getItem(
          //   this.context.translate("_DEVICE_TESTING_"),
          //   "companydevicetestinglist"
          // ),
          // getItem('Driver Behaviour Setting', 'companydriverbehaviorsetting',),
          // getItem(
          //   this.context.translate("_DRIVER_BEHAVIOR_SETTINGS_"),
          //   "companydriverbehaviorsetting"
          // ),

          // getItem('GeoFence Management', 'geofencereportlist'),
          getItem(
            this.context.translate("_GEOFENCE_MANAGEMENT_"),
            "geofencereportlist"
          ),
          // getItem('Logout', 'logout',),
          // getItem(this.context.translate("_FLEET_LOGOUT_"), "logout"),
        ]
      ),

      // getItem('Alert', 'parentalertreport', <ExclamationTriangle size={20} />, [getItem('Alert List', 'AlertList',),]),
      getItem(
        this.context.translate("_FLEET_ALERT_"),
        "parentalertreportlist",
        <ExclamationTriangle size={20} />,
        [getItem(this.context.translate("_ALERT_LIST_"), "alertlist")]
      ),

      // getItem('Alert', 'parentalertreport', <ExclamationTriangle size={20} />, [getItem('Alert List', 'AlertList',),]),

      // getItem('Graph', 'parentgraph', <LineChartOutlined size={20} />, [
      getItem(
        this.context.translate("_GRAPH_"),
        "parentgraph",
        <LineChartOutlined size={20} />,
        [
          // getItem('Speed Graph', 'SpeedGraph',),
          getItem(this.context.translate("_SPEED_GRAPH_"), "SpeedGraph"),
          // getItem('Distance Graph', 'DistanceGraph',),
          getItem(this.context.translate("_DISTANCE_GRAPH_"), "DistanceGraph"),
          // getItem('Idle Graph', 'IdleGraph',),
          getItem(this.context.translate("_IDLE_GRAPH_"), "IdleGraph"),
          // getItem("Ex-Idle Graph", "ExIdleGraph"),
          getItem(this.context.translate("_EXIDLE_GRAPH_"), "ExIdleGraph"),
          // getItem('Usage Graph', 'UsageGraph',),
          getItem(this.context.translate("_USAGE_GRAPH_"), "UsageGraph"),
          // getItem('Temprature Graph', 'TempGraph',),
          getItem(this.context.translate("_TEMPERATURE_GRAPH_"), "TempGraph"),
          // getItem('Dashboard Graph', 'DashboardGraph',),
          getItem(
            this.context.translate("_DASHBOARD_GRAPH_"),
            "DashboardGraph"
          ),
          // getItem('Fuel Graph', 'FuelGraph',),
          getItem(this.context.translate("_FUEL_GRAPH_"), "FuelGraph"),
          // getItem('Driver Score Graph', 'DriverScoreGraph',),
          getItem(
            this.context.translate("_DRIVER_SCORE_GRAPH_"),
            "DriverScoreGraph"
          ),
          // getItem('Max Speed Graph', 'MaxSpeedGraph',),
          getItem(this.context.translate("_MAX_SPEED_GRAPH_"), "MaxSpeedGraph"),
        ]
      ),
      // getItem("Back", 'lounchingDashboard', <BackwardOutlined size={20}  />)
    ];

    // console.log(this.items);
  }

  mapMenuItems = (menusData) => {
    // Ensure menusData is an array
    if (!Array.isArray(menusData)) {
      console.error("menusData is not an array", menusData);
      return [];
    }

    // Map over menusData to create the menu items
    const mappedItems = menusData.map((item) => {
      let icon = undefined;

      if (item.title === "Master") {
        icon = <Box size={20} />;
      } else if (item.title === "Tracking") {
        icon = <CarFront size={20} />;
      } else if (item.title === "Graphs") {
        icon = <LineChartOutlined size={20} />;
      } else if (item.title === "Email Report") {
        icon = <Envelope size={20} />;
      } else if (item.title === "Settings") {
        icon = <Gear size={20} />;
      } else if (item.title === "Report") {
        icon = <Clipboard2Data size={20} />;
      } else if (item.title === "Alerts") {
        icon = <ExclamationTriangle size={20} />;
      }
      return {
        label: item.title,
        key: item.key,
        children:
          item.children && item.children.length > 0
            ? this.mapMenuItems(item.children)
            : undefined,
        // Optionally add an icon here based on some condition
        icon: icon, // Set the icon based on the condition above
      };
    });

    // console.log("Mapped menu items:", mappedItems);

    // Update the state with the newly mapped items
    this.setState({ menuItem: mappedItems });

    return mappedItems;
  };

  // mapMenuItems = (menusData) => {
  //   console.log(menusData);
  //   return menusData.map((item) => {
  //     const menuItem = {
  //       label: item.title, // Assuming translation is needed
  //       key: item.key, // Convert key to string for consistency
  //       children: item.children.length > 0 ? this.mapMenuItems(item.children) : undefined

  //     };
  //     console.log(menuItem);
  //     // If you need to add an icon based on specific conditions

  //     // if (item.title === "Account") {
  //     //   menuItem.icon = <Box size={20} />;
  //     // }

  //     this.setState({ menuItem: menuItem });
  //     return menuItem;
  //   });
  // };

  async navigateHandler(link) {
    if (link === "logout") {
      this.cookies.remove("isAdmin");
      this.props.removeCookie("data");
      this.props.navigate("/login");
    } else {
      this.props.navigate("/" + link);
    }

    //console.log(link);

    /*if (link === "logout" && this.state.project === "awtltrack") {

    if (link === "logout") {
      //removeCookie('userToken');
      // this.props.removeCookie("isAdmin")

      this.cookies.remove("isAdmin");
      this.props.removeCookie("data");
      this.props.navigate("/login");
    } else if (link === "logout" && this.state.project === "starlingeye") {
      this.cookies.remove("isAdmin");
      this.props.removeCookie("data");
      this.props.navigate("/login");
    } else {
      this.props.navigate("/" + link);
    }*/
  }

  selectMenuItems(menuItems) {
    let menuKey = menuItems.key;
    if (menuKey === "menu-item-1") {
    }
  }
  updateDashboard = (value) => {
    // Step 1: Retrieve encoded data from the cookie
    const encodedCookieData = this.cookies.get("data");

    // Step 2: Decode the data (if needed)
    const decodedCookieData = JSON.parse(base64_decode(encodedCookieData));

    // Step 3: Update the decoded data
    decodedCookieData.showLaunchingDashboard = value;

    console.log(decodedCookieData, "decoded cookidata");
    // Step 4: Encode the updated data
    const updatedEncodedCookieData = base64_encode(
      JSON.stringify(decodedCookieData)
    );

    // Step 5: Set the updated encoded data back into the cookie
    this.cookies.set("data", updatedEncodedCookieData, { path: "/" });
  };

  render() {
    // const mapShow = Number(JSON.parse(JSON.stringify(Cookies.get())).mapShow);
    const encodedCookieData = this.cookies.get("data");

    // Step 2: Decode the data (if needed)
    const isIaunchingDashboard = JSON.parse(
      base64_decode(encodedCookieData)
    ).showLaunchingDashboard;
    // console.log(isIaunchingDashboard);

    // const items = [
    //   {
    //     key: "master",
    //     icon: <AppstoreOutlined />,
    //     label: "Master",
    //     children: [
    //       {
    //         key: "5",
    //         label: "Option 5",
    //       },
    //       {
    //         key: "6",
    //         label: "Option 6",
    //       },
    //       {
    //         key: "parentaccount",
    //         label: "Account",
    //         children: [
    //           {
    //             key: "companylist",
    //             label: "Company List",
    //           },
    //           {
    //             key: "companyuserlist",
    //             label: "User List",
    //           },
    //         ],
    //       },
    //       {
    //         key: "vehiclemanagement",
    //         label: "Vehicle Management",
    //         children: [
    //           {
    //             key: "vehiclereturnassetlist",
    //             label: "Vehicle Return Asset",
    //           },
    //           {
    //             key: "vehicledistancelist",
    //             label: "Vehicle Distance List",
    //           },
    //         ],
    //       },
    //     ],
    //   },
    //   {
    //     key: "parentgraph",
    //     label: "Grpahs",
    //     icon: <SettingOutlined />,
    //     children: [
    //       {
    //         key: "DistanceGraph",
    //         label: "Speed Graph",
    //       },
    //       {
    //         key: "MaxSpeedGraph",
    //         label: "Max Speed Graph",
    //       },
    //       {
    //         key: "DashboardGraph",
    //         label: "Dashboard Graph",
    //       },
    //       {
    //         key: "FuelGraph",
    //         label: "Fuel Graph",
    //       },
    //     ],
    //   },
    // ];
    return (
      <div>
        {/* {this.context.menuShow  ? <LounchingDashboard/>:   <Layout */}
        {isIaunchingDashboard ? (
          <LounchingDashboard />
        ) : (
          <Layout
            className="site-layout"
            // style={{
            //   minHeight: "100vh",  background: '#181722',
            // boxShadow: '5px 0px 4px 0px rgba(0, 0, 0, 0.60)',

            // }}
          >
            <Sider
              className="leftSlider"
              style={{
                // overflow: "auto",
                height: "100vh",
                position: "fixed",
                minHeight: "100vh",
                // background: "#181722",
                // boxShadow: "5px 0px 4px 0px rgba(0, 0, 0, 0.60)",
                zIndex: "1",

                // left: 0,
                insetInlineStart: 0,
                top: 0,
                bottom: 0,
              }}
              // collapsible
              collapsed={this.state.marginLeft === 80}
              // //breakpoint="lg"
              // defaultCollapsed={true}
              // onCollapse={(collapsed, type) => {
              //   console.log(collapsed)
              //   if (!collapsed) {
              //     this.setState({ marginLeft: 200 });
              //   } else {
              //     this.setState({ marginLeft: 80 });
              //   }
              // }}
            >
              {/* <div className="logo" /> */}

              {this.state.marginLeft === 80 ? (
                <>
                  <div
                    className="logoBlock"
                    style={{
                      // backgroundColor: "#1f212d",
                      height: "55px",
                    }}
                  >
                    <Image
                      onClick={() => {
                        this.setState({ marginLeft: 200 });
                      }}
                      src={require("../assets/images/" + config.smallLogo)}
                      style={{
                        padding: "9px 12px 8px",
                        width: "50px",
                        marginInlineStart: "12px",
                        // backgroundColor: "rgb(31, 33, 45)",
                        // paddingRight: "16px"
                      }}
                    />
                  </div>

                  <div
                    className="personIcon"
                    style={{
                      marginInlineStart: "27px",
                      marginTop: "20px",
                      marginBottom: "10px",
                      minHeight: "50px",
                    }}
                  >
                    <i
                      onClick={() => this.setState({ marginLeft: 200 })}
                      class="bi bi-person"
                      // style={{ color: "#e0e0e0", fontSize: "28px" }}
                    ></i>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className="innerBigLogo"
                    style={{
                      // backgroundColor: "#1f212d",
                      paddingTop: "6px",
                      paddingBottom: "6px",
                    }}
                  >
                    <Image
                      onClick={() => {
                        this.setState({
                          marginLeft: 80,
                          settingMenuShow: false,
                        });
                      }}
                      src={
                        this.state.innerLogo
                          ? this.state.innerLogo
                          : require("../assets/images/" + config.innerLogo)
                      }
                      onError={(e) => {
                        //  console.log(e)
                        e.target.onerror = null; // Prevents infinite loop if the fallback image also fails to load
                        e.target.src = require("../assets/images/" +
                          config.innerLogo);
                      }}
                      style={{ marginLeft: "35px", width: "120px" }}
                    />
                    {/* <h1 style={{color:"white",margin:"0px"}}>Trackeazy</h1> */}
                  </div>
                  <Space
                    style={{
                      marginTop: "20px",
                      marginBottom: "10px",
                      maxHeight: "50px",
                      // marginBottom: "14px",
                      marginLeft: "10px",
                    }}
                  >
                    <Image
                      style={{
                        borderRadius: "50px",
                        borderColor: "white",
                        border: "2px solid",
                      }}
                      width={50}
                      // src={logoIcon}
                      src={
                        this.state.photo
                          ? config.fleetURL +
                            "/images/users/" +
                            this.state.photo
                          : logoIcon
                      }
                      alt="logo"
                    />
                    <div>
                      {" "}
                      <p
                        style={{
                          color: "#e0e0e0",
                          margin: "0px",
                          fontSize: "15px",
                        }}
                      >
                        {this.state.userName}
                      </p>{" "}
                      <Space style={{ gap: "5px", marginTop: "4px" }}>
                        <div
                          style={{
                            height: "7px",
                            width: "7px",
                            borderRadius: "50px",
                            backgroundColor: "#27ae60",
                          }}
                        ></div>
                        <p
                          style={{
                            color: "#bdbdbd",
                            margin: "0px",
                            padding: "0px",
                            fontSize: "13px",
                          }}
                        >
                          Online
                        </p>
                        <i
                          onClick={(e) => {
                            this.setState({
                              settingColor:
                                this.state.settingColor === "white"
                                  ? "#2f80ed"
                                  : "white",
                              settingMenuShow: !this.state.settingMenuShow,
                            });
                          }}
                          className="bi bi-gear"
                          style={{
                            color: this.state.settingColor,
                            marginLeft: "32px",
                            borderRadius: "50%",
                            border: "1px solid",
                            padding: "3px",
                            position: "relative", // Add this style
                            zIndex: 10000, // Increase the z-index of the icon
                            fontSize: "11px",
                          }}
                        ></i>
                      </Space>
                    </div>
                  </Space>
                </>
              )}
              {/* {console.log(this.state.menuItem)} */}
              <Menu
                className="main-menu"
                theme="dark"
                mode="vertical"
                defaultSelectedKeys={["1"]}
                // items={this.state.menuItem}
                // items={items}
                items={this.items}
                onClick={(item) => {
                  this.navigateHandler(item.key);
                  console.log(item.key);
                }}
              />
              {config.project !== "drive7" && (
                <i
                  class="bi bi-arrow-left-circle"
                  style={{
                    color: "white",
                    fontSize: "25px",
                    marginInlineStart: "25px",
                  }}
                  onClick={async () => {
                    await this.updateDashboard(1);
                    await this.setState({ update: 1 });
                    await this.props.navigate("/lounchingDashboard");
                  }}
                ></i>
              )}
              {/* <div style={{color:"white"}} onClick={()=>{this.props.navigate("/lounchingDashboard"); this.context.menuShow= true}}>Back</div> */}
            </Sider>

            <Layout
              className="main-menu"
              style={{
                marginInlineStart: this.state.marginLeft,
                transition: "all 1s ease 0s",
                // direction:this.context.locale.language==='english' ? 'ltr' : 'rtl'
              }}
            >
              <Content style={{ margin: "0", overflow: "initial" }}>
                <div style={{ height: "100%" }}>
                  <Outlet />
                </div>
              </Content>
            </Layout>
          </Layout>
        )}

        {this.state.settingMenuShow && this.state.marginLeft === 200 && (
          <div>
            <Menu
              mode="vertical"
              className={
                this.state.marginLeft === 200
                  ? "settingMenu userMenu"
                  : "settingMenu userMenu menuSml"
              }
              theme="dark"
              onClick={this.selectMenuItems}
              //style={{ marginLeft: "300px" }}

              // style={{ borderRadius: "0px 10px 10px 0px" }}
            >
              <Menu.Item key="menu-item-1">
                <div
                  onClick={() =>
                    this.setState({
                      showHelpModal: true,
                      settingMenuShow: false,
                    })
                  }
                >
                  <i
                    class="bi bi-question"
                    style={this.settingMenuIconsStyle}
                  ></i>

                  {/* Help */}
                  {this.context.translate("_HELP_")}
                </div>
              </Menu.Item>
              <Menu.Item key="menu-item-2">
                <div
                  onClick={async () => {
                    await this.setState({ settingMenuShow: false });
                    await this.navigateHandler("companychangepassword");
                  }}
                >
                  {" "}
                  <i class="bi bi-key" style={this.settingMenuIconsStyle}></i>
                  {/* Change Password */}
                  {this.context.translate(
                    "_FLEET_MANAGEUSER_CHANGEPASSWORD_TAB_"
                  )}
                </div>
              </Menu.Item>
              <Menu.Item key="menu-item-3">
                <div
                  onClick={async () => {
                    await this.setState({ settingMenuShow: false });
                    await this.navigateHandler("logout");
                  }}
                >
                  {" "}
                  <i class="bi bi-power" style={this.settingMenuIconsStyle}></i>
                  {/* Logout */}
                  {this.context.translate("_FLEET_LOGOUT_")}
                </div>
              </Menu.Item>
              {Number(this.props.getUserData().isadmin) != 0 && (
                <Menu.Item key="menu-item-4">
                  <div
                    onClick={async () => {
                      await this.setState({ settingMenuShow: false });
                      await this.navigateHandler("companymenusetting");
                    }}
                  >
                    {" "}
                    <i
                      class="bi bi-menu-button-wide"
                      style={this.settingMenuIconsStyle}
                    ></i>
                    {/* Menu Setting */}
                    {this.context.translate("_MENU_SETTING_")}
                  </div>
                </Menu.Item>
              )}
              {/* {(Number(this.props.getUserData().isadmin) === -1 ||
                Number(this.props.getUserData().isadmin) === 3) && (
                <>
                  <Menu.Item key="menu-item-5">
                    <div
                      onClick={async () => {
                        await this.setState({ settingMenuShow: false });
                        await this.navigateHandler("companydevicetestinglist");
                      }}
                    >
                      {" "}
                      <i
                        class="bi bi-device-ssd"
                        style={this.settingMenuIconsStyle}
                      ></i>
                    
                      {this.context.translate("_DEVICE_TESTING_")}
                    </div>
                  </Menu.Item>
                </>
              )} */}
              {/* {(Number(this.props.getUserData().isadmin) === -1 ||
                Number(this.props.getUserData().isadmin) === 3) && (
                <> */}
              {config.project !== "drive7" && (
                <Menu.Item key="menu-item-6">
                  <div
                    onClick={async () => {
                      await this.setState({ settingMenuShow: false });
                      await this.navigateHandler(
                        "companydriverbehaviorsetting"
                      );
                    }}
                  >
                    {" "}
                    <i
                      class="bi bi-people-fill"
                      style={this.settingMenuIconsStyle}
                    ></i>
                    {/* Driver Behaviour Setting */}
                    {this.context.translate("_DRIVER_BEHAVIOR_SETTINGS_")}
                  </div>
                </Menu.Item>
              )}
            </Menu>
          </div>
        )}

        <HelpModal
          show={this.state.showHelpModal}
          closeModal={() => this.setState({ showHelpModal: false })}
        />
      </div>
    );
  }
}
// export default Menu1
Menu1.contextType = StoreContext;
export default observer(withRouter(Menu1));
