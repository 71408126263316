import { encode as base64_encode } from "base-64";

import { postApi, baseURL } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class DeliveryManagementStore {
  loading = false;
  total = 0;
  deliveryTableData = [];
  deliverylogTblData = [];
  invoiceData = [];
  usersData = [];
  usersVehiclesData = [];
  getVehiclesUsingCompanyId = [];
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      deliveryTableData: observable,
      deliverylogTblData: observable,
      invoiceData: observable,
      usersData: observable,
      usersVehiclesData: observable,
      loadData: action,
      loadDeliveryLogData: action,
      loadInvoiceData: action,
      exportExcel: action,
      exportpdf: action,
      exportxml: action,
      exportcsv: action,
      loadUsersData: action,
      loadUsersVehiclesData: action,
      getVehiclesCompanyId: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({
    companyId,
    vehicleId,
    driverId,
    filterBy,
    fromDate,
    toDate,
    offset,
    limit,
    pageNo,
    jobType,
    hasInvoice,
  }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      driverId: driverId,
      filterBy: filterBy,
      fromDate: fromDate,
      toDate: toDate,
      offset: offset,
      limit: limit,
      pageNo: pageNo,
      jobType: jobType,
      hasInvoice: hasInvoice,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=deliveryreport", "postData=" + base64Input);

    this.deliveryTableData = response?.data?.data?.deliverydetail;

    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async loadDeliveryLogData({ jobNo }) {
    this.loading = true;
    var postData = { jobNo: jobNo };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=delivery&a=deliveryLogReport",
        "postData=" + base64Input
      );

    this.deliverylogTblData = [response?.data?.data];

    this.loading = false;
  }

  async loadInvoiceData(jobId) {
    this.loading = true;
    var postData = { jobId: jobId };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=delivery&a=getInvoice", "postData=" + base64Input);

    this.invoiceData = response?.data?.data;
    //   console.log(this.invoiceData )

    this.loading = false;
  }

  async loadUsersData(companyId) {
    this.loading = true;
    var postData = { companyId: companyId };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getCompanyAllUsers",
        "postData=" + base64Input
      );

    // this.usersData = response?.data?.data
    //   console.log(this.invoiceData )

    const allUsers = [{ userId: 0, username: "All" }];
    this.usersData = [...allUsers, ...response?.data?.data];
    this.loading = false;
  }

  async getVehiclesCompanyId(companyId) {
    var postData = { companyId: companyId };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=vehicle&a=getVehicles", "postData=" + base64Input);
    this.getVehiclesUsingCompanyId = response?.data?.data;

    return response?.data?.data;
  }

  async loadUsersVehiclesData({ companyId, userId, searchbyUserId }) {
    this.loading = true;
    var postData = {
      companyId: companyId,
      userId: userId,
      isadmin: "1",
      searchbyUserId: searchbyUserId,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicletracking&a=getUserVehicleForReport",
        "postData=" + base64Input
      );

    this.usersVehiclesData = response?.data?.data;

    this.getVehiclesUsingCompanyId = response?.data?.data;

    this.loading = false;
  }

  async exportExcel(getData) {
    console.log("getData", getData);
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdeliveryexcel&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&filterBy=" +
      getData.filterBy +
      "&did=" +
      getData.driverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&jobType=" +
      getData.jobType +
      "&colList=" +
      getData.colList;
  }

  async exportpdf(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdeliverypdf&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&filterBy=" +
      getData.filterBy +
      "&did=" +
      getData.driverId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&jobType=" +
      getData.jobType +
      "&colList=" +
      getData.colList;
  }

  async exportxml(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdeliveryxml&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&filterBy=" +
      getData.filterBy +
      "&did=" +
      getData.driverId +
      "&jobType=" +
      getData.jobType +
      "&colList=" +
      getData.colList;
  }

  async exportcsv(getData) {
    window.location.href =
      baseURL +
      "index.php?c=vehicle&a=exportdeliverycsv&cid=" +
      getData.companyId +
      "&vid=" +
      getData.vehicleId +
      "&fD=" +
      getData.fromDate +
      "&tD=" +
      getData.toDate +
      "&filterBy=" +
      getData.filterBy +
      "&did=" +
      getData.driverId +
      "&jobType=" +
      getData.jobType +
      "&colList=" +
      getData.colList;
  }
}

export default DeliveryManagementStore;
