import React from "react";

import {
  Form,
  Input,
  Table,
  Col,
  Space,
  Button,
  message,
  Row,
  Tooltip,
  Modal,
} from "antd";
import { Link } from "react-router-dom";
import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import AddButton from "./AddButton";

import {
  ExportOutlined,
  EditFilled,
  DeleteFilled,
  DownloadOutlined,
  EditOutlined,
  CloseOutlined,
  SearchOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import VehicleReturnAssetsStore from "../store/VehicleReturnAssetsStore";
import withRouter from "../withRouter";
import CompanyStore from "../store/CompanyStore";
import VehicleReturnAssetsAdd from "./VehicleReturnAssetsAdd";

class VehicleReturnAssetList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.returnAssetStore = new VehicleReturnAssetsStore();
    this.companyStore = new CompanyStore(this);
    this.state = {
      offset: "",
      limit: "",
      status: "",
      loading: false,
      pageSize: 50,
      currentPage: 1,
      distributor: "",
      distributorType: "",

      company_name: "",
      emailId: "",
      companyId: "",
      companyVal_Lab: "",
      openReturnAssetForm: false,
      returnAssetId: "",
      listDataUpdate: false,
      companyFieldHighlight: false,
      searchField:""
    };
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      console.log(this.context.viewStateStore.value, "vale");
      const setReturnAssetStates =
        this.context.viewStateStore.returnAssetObject;
      console.log(setReturnAssetStates);
      if (setReturnAssetStates.companyId !== "") {
        await this.setState({ companyId: setReturnAssetStates.companyId });
        await this.returnAssetStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          searchField: setReturnAssetStates.searchField,
          companyId: setReturnAssetStates.companyId,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          setReturnAssetStates.companyId
        );

        await this.setState({ loadList: 1 });
        console.log("singleData", singleData);
        await this.setState({ companyVal_Lab: singleData });
        console.log("singleData", singleData);
      }
      this.setState({ listDataUpdate: false });
    }
  }

  async componentDidMount() {
    if(Number(this.props.getUserData().isadmin)!==-1 ){
    
       console.log(this.props.getUserData().userCompanyId)
       await this.setState({companyId:this.props.getUserData().userCompanyId})
      //  console.log(this.state.companyId)
       await this.setState({userId:this.props.getUserData().userId})
       await this.handleSearch();
  }
    // await this.returnAssetStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });
    this.columns = [
      {
        // title: "Driver Name",
        title:this.context.translate("_DRIVER_NAME_"),
        dataIndex: "drivername",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
        // width:""
        // fixed: 'left',
      },
      {
        // title: "Vehicle No",
        title:this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        // fixed: 'left',
      },
      {
        // title: "  Allocate Date",
        title:this.context.translate("_ALLOCATE_DATE_"),
        dataIndex: "allocateDate",
      },
      {
        // title: "Returned Date",
        title:this.context.translate("_RETURNED_DATE_"),
        dataIndex: "returnDate",
      },

      {
        // title: " Days Used",
        title:this.context.translate("_DAYS_USED_"),
        dataIndex: "totalUsedDays",
      },
      {
        // title: "Returned From",
        title:this.context.translate("_RETURNED_FROM_"),
        dataIndex: "allocateTo",
        render: (value) => {
          // console.log("value",value)
          if (value === "1") {
            return <>Driver</>;
          } else   if (value === "2")  {
            return <>Project</>;
          }
        },
      },
      {
        // title: "Total Amount",
        title:this.context.translate("_TOTAL_AMOUNT_"),
        dataIndex: "totalAmountCharge",
      },
      {
        // title: "Status",
        title:this.context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "returnStatus",
        render: (value) => {
          // console.log(typeof(value))
          if (value === "1") {
            return <>Vehicle Returned</>;
          }
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        width: "5%",
        // fixed: 'right',

        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Return Asset"
                    title={this.context.translate("_EDIT_RETURN_ASSET_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete Asset"
                      title={this.context.translate("_FLEET_VEHICLE_VEHICLELIST_DELETE_")}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      pageSize: 50,
      currentPage: 1,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyVal_Lab: singleData });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.returnAssetStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });

    await this.setState({ loadData: 1 });
  }

  async searchInputButton() {
    if (!this.state.companyId) {
      console.log(
        this.state.companyId,
        "sfsdgdfgdfhfg",
        this.state.companyFieldHighlight
      );
      this.setState({ companyFieldHighlight: true });
    } else {
      this.setState({ companyFieldHighlight: false });
    }
    if (this.state.companyId) {
      this.setState({ companyFieldHighlight: false, loading: true });
      await this.returnAssetStore.loadData({
        offset: 0,
        limit: 50,
        searchField: this.state.searchField,
        companyId: this.state.companyId,
      });
      await this.setState({ loadData: 1 });
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  onEdit = (record) => {
    console.log(record);
    const vehicleId = record.Id;
    if (vehicleId) {
      this.context.viewStateStore.setReturnAssetStates({
        companyId: record.companyId,
      });
      this.setState({ openReturnAssetForm: true });
      this.setState({ returnAssetId: vehicleId });
      // this.props.navigate("/vehiclereturnassetadd/" + vehicleId);
    }
  };

  // onEdit = (record) => {
  //   console.log("Id", record.Id)
  //   this.props.navigate("/vehiclereturnassetadd/" + record.Id)
  // }

  async onDelete(record) {
    console.log(record.Id, "record");
    const response = await this.returnAssetStore.deleteRecord({
      Id: record.Id,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      // message.success(response.data.message);
      message.success("Returned Vehicle Removed")
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  searchInput = async (e) => {
    const value = e.target.value;
    // await this.setState({searchField:value})
    if (value.length >= 3 || value.length === 0) {
      await this.returnAssetStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1 });
    }
  };
  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      // this.props.navigate("/vehiclereturnassetlist");
      this.setState({ openReturnAssetForm: false });
      this.setState({ listDataUpdate: true });
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["username"]);
      });
    }
  };

  closeModal=()=>{
    this.setState({ openReturnAssetForm: false, returnAssetId: "" });
  }
  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_RETURN_ASSET_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/vehiclereturnassetadd"
                /> */}
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({
                        returnAssetId: "",
                        openReturnAssetForm: true,
                      })
                    }
                  ></Button>
                </Tooltip>
                <Button
                  disabled={
                    this.returnAssetStore?.returnAsset?.length > 0
                      ? false
                      : true
                  }
                   className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    console.log(val);
                    const result = [];
                    this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      cid: this.state.companyId,
                      searchField:this.state.searchField,
                      colList: result,
                    };
                    console.log(getData);
                    if (val) {
                      this.returnAssetStore.exportExcel(getData);
                    }
                  }}
                >
                  <DownloadOutlined />
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  onChange={(value) => this.handleChange(value)}
                  // style={{ width: 200 }}
                  style={companyInputBox}
                />

                <Input
                  // placeholder="Search by Vehicle No,Driver Name"
                  placeholder={this.context.translate('_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_')}
                  style={{ width: 400 }}
                  onChange={(e) => {
                    this.setState({ searchField: e.target.value });
                    this.searchInput(e);
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={() => {
                    this.searchInputButton();
                  }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.returnAssetStore?.total}
            columns={this.columns}
            dataSource={this.returnAssetStore?.returnAsset}
            loading={this.returnAssetStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openReturnAssetForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Return Assets"
            title={this.context.translate("_RETURN_ASSET_")}
            centered
            visible={this.state.openReturnAssetForm}
            footer={null}
            onCancel={() => {
              this.setState({ openReturnAssetForm: false, returnAssetId: "" });
            }}
            width={665}
          >
            <div>
              <VehicleReturnAssetsAdd
                returnAssetId={this.state.returnAssetId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}

VehicleReturnAssetList.contextType = StoreContext;
export default observer(withRouter(VehicleReturnAssetList));
