import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";

import config from "../config";
import { action, makeObservable, observable } from "mobx";

class CertificateReportStore {
  certificateData = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      certificateData: observable,
      total: observable,
      loading: observable,
      loadData: action,
      exportExcel: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, companyId, vehicleId }) {
    this.loading = true;

    var postData = {
      companyId: companyId,
      vehicleId: vehicleId,
      type: "admin",
      userCompanyId: "1",
      offset: offset,
      limit: limit,
      page: 1,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=vehicle&a=certificateReport",
        "postData=" + base64Input
      );

    this.certificateData = response?.data?.data?.certificate;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async exportExcel(getData) {
    window.location.href =
      config.baseURL +
      "index.php?c=vehicle&a=certificateExportexcel&cid=" +
      getData.cid +
      "&vid=" +
      getData.vid +
      "&type=" +
      "admin" +
      "&ucid=" +
      1 +
      "&colList=" +
      getData.colList;
  }
}

export default CertificateReportStore;
