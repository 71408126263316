import React from "react";
import {
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import config from "../config";
import withRouter from "../withRouter";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import MapForVehiclePosition from "./MapForVehiclePosition";

import GeofenceReportStore from "../store/GeofenceReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import {
  MapContainer,
  Tooltip,
  Marker,
  Popup,
  Circle,
  Polygon,
} from "react-leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import AdditionalContentInMap from "./AdditionalContentInMap";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
// import conf from "../config";
import { Icon } from "leaflet";

const dotIcon = new Icon({
  iconUrl: require("../assets/images/location-pin.png"),
  iconSize: [38, 38], // size of the icon
});

// const dotIcon2 = L.divIcon({
//   className: "dot-icon",
//   iconSize: [8, 8],
//   html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
// });
const { RangePicker } = DatePicker;
const { Option } = Select;
class GeofenceReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.geofencePOIReportStore = new GeofenceReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      companyId: "",
      selectedVehicles: [],
      selectedgeofenceData: [],
      selectAllGeofenceData: false,
      markers: [],
      modalVisible: false,
      column: "",
      filter: "",
      selectAllVehicles: false,
      allGeo: 0,
      showMap: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showMonthPopup: false,
      mandatory: false,
      company: false,
      asset: false,
      vehicle: false,
      // array:[0,0,0],
      vehicleObj: false,

      addressInfo: this.props.addressInfo,
      update: 0,
      type: "",
      key: new Date(),
      traffic: false,
      flag: true,
      mapBtn: false,
      satelliteBtn: false,
      modalInfoObj:[],
      selectedColumns: [
        {
          // title: " Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          // fixed: 'left',
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "  Driver",
          title: context.translate("_FLEET_MANAGEVEHICLE_DRIVER_TAB_"),
          dataIndex: "name",
          width: "15%",
          key: "name",
        },
        {
          // title: " In Time ",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_INTIME_"),
          dataIndex: "inTime",
          //fixed: 'left',
          width: "15%",
          key: "inTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          title: context.translate(
            "_FLEET_REPORTS_GEOFENCEREPORT_LOCATION_IN_"
          ),
          dataIndex: "locationIn",
          ellipsis: true,
          width: "15%",
          key: "locationIn",
          render: (value, obj) => {
            // console.log(obj)
            return (
              <Link
                onClick={async (e) => {
                  await this.setState({  modalInfoObj: obj });
                  await this.setState({
                    vehicleObj: {
                      ...obj,
                      icon: "logo_icon.png",
                      latitude: obj.latitudeIn,
                      longitude: obj.longitudeIn,
                    },
                    heading: this.context.translate("_LOCATION_ON_MAP_"),
                  });
                  console.log(this.state.vehicleObj);
                  await this.setState({ loadData: 1 });
                }}
              >
                {value}
              </Link>
            );
          },
        },

        {
          // title: " Out Time ",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_OUTTIME_"),
          dataIndex: "outTime",
          //fixed: 'left',
          width: "15%",
          key: "outTime",
          render: (value) => {
            if (value === null) {
              return "";
            } else {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            }
          },
        },
        {
          title: context.translate(
            "_FLEET_REPORTS_GEOFENCEREPORT_LOCATION_OUT_"
          ),
          dataIndex: "locationOut",
          width: "15%",
          key: "name",
          render: (value, obj) => {
            return (
              <Link
                onClick={async (e) => {
                  // await this.setState({ showMap: true, modalInfoObj: obj });
                  await this.setState({
                    vehicleObj: {
                      ...obj,
                      icon: "logo_icon.png",
                      latitude: obj.latitudeOut,
                      longitude: obj.longitudeOut,
                    },
                    heading: this.context.translate("_LOCATION_ON_MAP_"),
                  });
                  console.log(this.state.vehicleObj);
                  await this.setState({ loadData: 1 });
                }}
              >
                {value}
              </Link>
            );
          },
        },
        {
          // title: "Type",
          title: context.translate("_FLEET_DASHBORD_NEWPOI_TYPE_"),
          dataIndex: "typeName",
          width: "15%",
          key: "typeName",
        },
        {
          // title: "  Geofence or POI ",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_GEOPOI_"),
          dataIndex: "geoname",
          width: "15%",
          key: "geoname",
        },
        {
          // title: " Elapsed Time Inside The Geofence ",
          title: context.translate("_FLEET_REPORTS_GEOFENCEREPORT_ELAPSETIME_"),
          dataIndex: "elapsedTime",
          width: "15%",

          key: "elapsedTime",
        },

        {
          // title: "View On Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "",
          // fixed: 'right',
          width: "15%",
          key: "View",
          render: (value, obj) => {
            return (
              <>
                <Link onClick={(e) => this.handleGeofenceFunc(obj)}>
                  {" "}
                  <img
                    src={require("../assets/images/mapIcon/gps_icon.png")}
                    width={25}
                    height={25}
                  />
                </Link>
              </>
            );
          },
        },
      ],
    };
    this.mapRef = React.createRef();
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles.map(Number),
            intervel: this.state.intervel,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            userId: 1,
            filter: this.state.filter,
            geoZoneId: this.state.selectedgeofenceData.map(Number),
            colList: result.toString(),
            allGeo: this.state.allGeo,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.geofencePOIReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.geofencePOIReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.geofencePOIReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.geofencePOIReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  handleGeofenceFunc = async (obj) => {
    console.log(obj);
    await this.setState({ showMap: true, modalInfoObj: obj });
    console.log(parseFloat(this.state.modalInfoObj?.points?.split(",")[0]));
    let latlongs = [
      parseFloat(this.state.modalInfoObj?.points?.split(",")[0]),
      parseFloat(this.state.modalInfoObj?.points?.split(",")[1]),
    ];

    // const markers2 = [
    //   { lat: 25.178024421461,  lng: 55.390588312238, name: 'San Francisco' },
    //   { lat: 25.17899421461,  lng: 55.390588312238, name: 'Los Angeles' },
    //  ];
    // console.log("marker1",markers2)
    var markers = [];
    if (
      this.state.modalInfoObj?.latitudeIn &&
      this.state.modalInfoObj?.latitudeOut
    ) {
      markers = [
        {
          lat: parseFloat(this.state.modalInfoObj?.latitudeIn),
          lng: parseFloat(this.state.modalInfoObj?.longitudeIn),
          name: "In",
        },
        {
          lat: parseFloat(this.state.modalInfoObj?.latitudeOut),
          lng: parseFloat(this.state.modalInfoObj?.longitudeOut),
          name: "Out",
        },
      ];
    } else {
      markers = [
        {
          lat: parseFloat(this.state.modalInfoObj?.latitudeIn),
          lng: parseFloat(this.state.modalInfoObj?.longitudeIn),
          name: "In",
        },
      ];
    }

    console.log("markers", markers);
    await this.setState({ markers: markers });
    if (Number(obj.radius) > 1000) {
      await this.mapRef.current.fitBounds([latlongs], { maxZoom: 12 });
    } else if (Number(obj.radius) < 400) {
      await this.mapRef.current.fitBounds([latlongs], { maxZoom: 17 });
    } else {
      await this.mapRef.current.fitBounds([latlongs], { maxZoom: 15 });
    }
    console.log(latlongs);
    // await this.mapRef.current.setView(latlongs,13)
  };

  async componentDidMount() {
    // await this.geofencePOIReportStore.loadData({ offset: 0, limit: 50 });
    await this.setState({ column: this.state.selectedColumns });
    console.log("hello");
    this.setState({ pageSize: 20 });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 1);
    if (this.state.showMap) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }

  async handleChange(comp) {
    this.setState({ company: false });
    this.setState({ selectedVehicles: [], selectAllVehicles: false });
    this.setState({ selectedgeofenceData: [], selectAllGeofenceData: false });
    await this.setState({ companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    await this.geofencePOIReportStore.getGeofenceDataByCompany({
      companyId: comp?.value,
    });

    await this.handleSearch();
    // console.log(data, "sfdgdsfghfh");

    // console.log("data", data);
  }

  async handleSearch() {
    // this.setState({ loading: true });
    // await this.geofencePOIReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val });
  };

  showData = async () => {
    console.log("click");
    console.log(this.state.selectedgeofenceData.length, this.state.companyId);
    if (!this.state.companyId) {
      this.setState({ company: true });
    } else {
      this.setState({ company: false });
    }
    if (this.state.selectedgeofenceData.length === 0) {
      this.setState({ vehicle: true });
    } else {
      this.setState({ vehicle: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ asset: true });
    } else {
      this.setState({ asset: false });
    }

    if (
      this.state.companyId &&
      this.state.selectedgeofenceData.length > 0 &&
      this.state.selectedVehicles.length > 0
    ) {
      // this.setState({mandatory:false})
      this.setState({ company: false, asset: false, vehicle: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.geofencePOIReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          geoZoneId: this.state.selectedgeofenceData,
        });
        this.setState({ loadData: 1 });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleSelectAllGeofenceData = async (e) => {
    if (this.state.selectAllGeofenceData) {
      const allGeofenceData = this.geofencePOIReportStore?.geofenceData.map(
        (val) => {
          return val.zoneId;
        }
      );
      await this.setState({ selectedgeofenceData: allGeofenceData });
    } else {
      await this.setState({ selectedgeofenceData: [] });
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleBtnFunc = (state) => {
    this.setState({ [state]: true });
    setTimeout(() => {
      this.setState({ [state]: false });
    }, 1000);
  };

  render() {
    const companyInputStyle = this.state.company
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.asset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const vehicleInputStyle = this.state.vehicle
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

      const mapData = this.state.modalInfoObj.points?.split(",0,").map((val) => {
        return val.split(",").map((val) => Number(val));
      });
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Geofence Or POI In Out Report</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_REPORTS_GEOFENCEREPORT_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
              

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.geofencePOIReportStore?.geofenceReportStore?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={22}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={inputStyle}
                  // style={companyInputStyle}
                  status={this.state.company ? "error" : false}
                  // style={this.state.array[0] ? { border: '1px solid red',width: 160,borderRadius: 8 } : { width: 160}}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  // onChange={(val) =>this.setState({ selectedVehicles: val })console.log(this.vehicleStore?.getVehiclesUsingCompanyId.length)}
                  // onChange={this.assetchange}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val, asset: false });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                    // this.setState({ selectedVehicles: val });
                    console.log(
                      this.vehicleStore?.getVehiclesUsingCompanyId.length
                    );
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={inputStyle}
                  onSelect={(val) => console.log(val)}
                  // style={assetInputStyle }
                  status={this.state.asset ? "error" : false}
                  // style={this.state.array[1] ? { border: '1px solid red',width: 160,borderRadius: 8 } : { width: 160}}
                /> */}
                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.asset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <Checkbox
                  checked={this.state.selectAllGeofenceData}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllGeofenceData: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleSelectAllGeofenceData();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  maxTagCount="responsive"
                  value={this.state.selectedgeofenceData}
                  onChange={(val) => {
                    this.setState({
                      selectedgeofenceData: val,
                      vehicle: false,
                    });
                    if (
                      this.geofencePOIReportStore?.geofenceData.length >
                      val.length
                    ) {
                      this.setState({
                        selectAllGeofenceData: false,
                        allGeo: 1,
                      });
                    } else {
                      this.setState({ selectAllGeofenceData: true, allGeo: 0 });
                    }
                  }}
                  fieldNames={{ label: "name", value: "zoneId" }}
                  // placeholder=" Geofence"
                  placeholder={this.context.translate("_GEOFENCE_")}
                  optionFilterProp="children"
                  options={this.geofencePOIReportStore?.geofenceData}
                  style={{ width: 150 }}
                  status={this.state.vehicle ? "error" : false}
                  // style={inputStyle}
                  // style={vehicleInputStyle}
                  // style={this.state.array[2] ? { border: '1px solid red',width: 160,borderRadius: 8 } : { width: 160}}
                  onSelect={(val) => console.log(val)}
                />
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />

                <Button type="primary" onClick={() => this.showData()}>
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={2}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.geofencePOIReportStore?.total}
            columns={this.state.selectedColumns}
            dataSource={this.geofencePOIReportStore?.geofenceReportStore}
            loading={this.geofencePOIReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.geofencePOIReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          {this.state.showMap && (
            <Modal
              className="multiTracking-popup"
              style={{ padding: "0px !important" }}
              title={
                <div>
                  Geofence or POI In Out Report on Map
                  <CloseOutlined
                    onClick={() => this.setState({ showMap: false })}
                  />
                </div>
              }
              maskClosable={false}
              open={this.state.showMap}
              centered
              // bodyStyle={{ height: 1000 }}
              width={1000}
              bodyStyle={{ height: "60vh", padding: "0px" }}
              onOk={() => this.setState({ showMap: false })}
              onCancel={() => this.setState({ showMap: false })}
              footer={false}
            >
              <div
                dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
              >
                <MapContainer
                  ref={this.mapRef}
                  zoom={12}
                  center={[
                    parseFloat(this.state.modalInfoObj?.points?.split(",")[0]),
                    parseFloat(this.state.modalInfoObj?.points?.split(",")[1]),
                  ]}
                  // center={config.center}
                  style={{ height: "60vh", position: "relative" }}
                >
                  <ReactLeafletGoogleLayer />

                  {this.state.modalInfoObj?.type == 1 && (
                    <Polygon
                    positions={mapData}
                      // positions={this.state.modalInfoObj?.points}
                      pathOptions={{ color: "red" }}
                    >
                      <Popup>{this.state.modalInfoObj?.name}</Popup>
                    </Polygon>
                  )}
                  {this.state.modalInfoObj?.type == 2 && (
                    <Circle
                      center={{
                        lat: this.state.modalInfoObj?.points?.split(",")[0],
                        lng: this.state.modalInfoObj?.points?.split(",")[1],
                      }}
                      radius={parseFloat(this.state.modalInfoObj?.radius)}
                      pathOptions={{ color: "red" }}
                    >
                      <Popup>{this.state.modalInfoObj?.name}</Popup>
                    </Circle>
                  )}

                  {this.state.type || this.state.traffic ? (
                    <ReactLeafletGoogleLayer
                      key={this.state.type ? this.state.type : ""}
                      type={this.state.type ? this.state.type : "roadmap"}
                      googleMapsAddLayers={
                        this.state.traffic ? [{ name: "TrafficLayer" }] : null
                      }
                    />
                  ) : (
                    <ReactLeafletGoogleLayer
                      googleMapsAddLayers={
                        this.state.traffic ? [{ name: "TrafficLayer" }] : null
                      }
                    />
                  )}

                  {this.state.markers?.map((marker, idx) => (
                    // console.log("position",marker.lat,marker.lng)
                    <Marker position={[marker.lat, marker.lng]} icon={dotIcon}>
                      <Tooltip permanent direction="top" offset={[1, -10]}>
                        <b>{marker.name}</b>
                      </Tooltip>
                    </Marker>
                  ))}

                  <AdditionalContentInMap size="small" This={this} />
                </MapContainer>
              </div>
            </Modal>
          )}

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {/* Alert */}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For Reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}
      </>
    );
  }
}
GeofenceReportList.contextType = StoreContext;
export default observer(withRouter(GeofenceReportList));
