import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  message,
  Form,
  Input,
  Row,
  Select,
  Upload,
  TimePicker,
  Modal,
  Spin,
} from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";

import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import PassengersStore from "../store/PassengerStore";
import withRouter1 from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";

import CompanyStore from "../store/CompanyStore";

import { times } from "lodash";
import Autocomplete from "react-google-autocomplete";
import config from "../config";
import conf from "../config";
import ListTable from "./ListTable";

import "leaflet/dist/leaflet.css";
import DisplayLocationMap from "./DisplayLocationMap";
import dayjs from "dayjs";

const { Option } = Select;
class PassengerAdd extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = {
      loading: false,
      passengerId: -1,
      stId: -1,
      getVehicles: [],
      companyId: "",
      employeeType: false,
      studentType: true,
      type: "Student",

      busRootShow: false,

      currentPage: "",
      pageSize: "",
      addressMap: false,
      addressInfo: [],
      update: 0,
      photo: "",
      fileList: "",
      loadingSpin: false,
    };
    this.passengerStore = new PassengersStore(this);
    this.vehicleStore = new VehicleStore(this);
    this.companyStore = new CompanyStore(this);
    // this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.query = new URLSearchParams(this.props.location.search);
    this.queryId = this.query.get("schollPassenger");
    this.mapRef = React.createRef();
    // console.log(query.get("schollPassenger"))
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setPassengerStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      // await this.setState({userId:this.props.getUserData().userId})
      // await this.setState({isadmin:this.props.getUserData().isadmin})
    }
    const passengerId = this.props.passengerIdEdit;
    console.log(this.props);
    if (passengerId) {
      this.filldata(passengerId);
    }
    // this.passengerStore.getAddress()

    this.columnsRouteTripList = [
      {
        // title: "Bus No",
        title: this.context.translate("_BUSNO_"),
        dataIndex: "busno",
      },
      {
        //  title: "Trip Name",
        title: this.context.translate("_TRIP_NAME_"),
        dataIndex: "tripName",
      },
      {
        // title: "Pickup Point",
        title: this.context.translate("_PICKUP_POINT_"),
        dataIndex: "pickupPoint",
      },
      {
        // title: "Pickup Time",
        title: this.context.translate("_PICKUP_TIME_"),
        dataIndex: "pickupTime",
      },
      {
        //  title: "Seater",
        title: this.context.translate("_SERATER_"),
        dataIndex: "seater",
      },
      {
        // title: "Available Seats",
        title: this.context.translate("_AVAILABLE_SEATS_"),
        dataIndex: "availableSeats",
      },
    ];

    console.log(passengerId, this.props, "pass");

    const data = await this.passengerStore.getAllClass();
    console.log(data);

    await this.setState({ loadList: 1 });
  }
  filldata = async (passengerId) => {
    await this.setState({ loadingSpin: true });
    const getPassengerData = await this.passengerStore.getPassenger(
      passengerId
    );
    console.log("getpassData", getPassengerData);
    await this.passengerStore.getBusNo(getPassengerData.companyId);
    await this.setState({
      companyId: getPassengerData.companyId,
      passengerId: getPassengerData.passengerId,
      stId: getPassengerData.stId,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getPassengerData.companyId
    );
    await this.passengerStore.getAllClass();
    await this.formRef.current.setFieldsValue({
      studentName: getPassengerData.studentName,

      passengerId: getPassengerData.passengerId,
      companyId: singleData,
      vehicleNo: getPassengerData.vehicleId,
      idNo: getPassengerData.idNo,
      mobileNo: getPassengerData.mobileNo,
      tagId: getPassengerData.tagId,
      employeeDepartment: getPassengerData.employeeDepartment,
      employeeDesignation: getPassengerData.employeeDesignation,
      employeeName: getPassengerData.employeeName,
      // classId: getPassengerData.className,
      className: getPassengerData.classId,
      schoolName: getPassengerData.schoolName,
      type: getPassengerData.type,

      term: getPassengerData.term,
      transportation: getPassengerData.transportation,
      vehicleType: getPassengerData.vehicleType,
      dropOffTime: getPassengerData.dropOffTime
        ? dayjs(getPassengerData.dropOffTime, "HH:mm")
        : "",
      pickUpTime: getPassengerData.pickUpTime
        ? dayjs(getPassengerData.pickUpTime, "HH:mm")
        : "",
      dropPoint: getPassengerData.dropPoint,
      pickUpPoint: getPassengerData.pickUpPoint,
      zone: getPassengerData.zone,
      // createDate:getPassengerData.createDate,
      bloodGroup: getPassengerData.bloodGroup,

      classDiv: getPassengerData.classDiv,
      guardianContactNo: getPassengerData.guardianContactNo,
      guardianName: getPassengerData.guardianName,
      motherContactNo: getPassengerData.motherContactNo,
      motherName: getPassengerData.motherName,
      fatherContactNo: getPassengerData.fatherContactNo,
      fatherName: getPassengerData.fatherName,
      transportationType: getPassengerData.transportationType,
      fee: getPassengerData.fee,

      siblingsClass1: getPassengerData.siblingsClass1,
      siblingsId1: getPassengerData.siblingsId1,
      siblingsName1: getPassengerData.siblingsName1,
      // photo: getPassengerData.photo,
      status: getPassengerData.status,
      longitude: getPassengerData.longitude,
      latitude: getPassengerData.latitude,
      mobileapp: getPassengerData.mobileapp,

      siblingsName2: getPassengerData.siblingsName2,
      siblingsId2: getPassengerData.siblingsId2,
      siblingsClass2: getPassengerData.siblingsClass2,

      stId: getPassengerData.stId,
      countryCode: getPassengerData.countryCode,
      fatherContactNoCountryCode: getPassengerData.fatherContactNoCountryCode,
      motherContactNoCountryCode: getPassengerData.motherContactNoCountryCode,
      guardianContactNoCountryCode:
        getPassengerData.guardianContactNoCountryCode,
    });
    this.setState({ photo: getPassengerData.photo });
    if (getPassengerData.type === "Student") {
      this.setState({ studentType: true });
      this.setState({ employeeType: false });
    } else if (getPassengerData.type === "Employee") {
      this.setState({ employeeType: true });
      this.setState({ studentType: false });
    }

    await this.setState({ loadingSpin: false });
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addressMap &&
      prevState.addressMap !== this.state.addressMap
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }

  async handleChange(comp) {
    const getVehicles = await this.passengerStore.getBusNo(comp.value);
    console.log(getVehicles);

    await this.setState({ companyId: comp.value });

    console.log(this.state.companyId);
    this.formRef.current.setFieldsValue({ companyId: comp.value });
    if (!this.props.passengerIdEdit) {
      console.log("inn");
      this.context.viewStateStore.setPassengerStates({ companyId: comp.value });
    }
    await this.setState({ loadList: 1 });

    // await this.handleSearch();
  }

  async handleSearch() {
    await this.passengerStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });

    await this.setState({ loadList: 1 });
  }

  onPassengerDetailSubmit = async (fieldsValue) => {
    // if (!this.props.params.passengerId) {
    //   this.context.viewStateStore.setBeforeEditState(this.state.companyId);
    // }
    console.log(this.state.fileList);
    if (this.state.fileList?.size > conf.photoUploadSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return;
    }
    let filterType = ["image/jpeg", "image/png", "image/jpg"];
    console.log(!filterType.includes(this.state.fileList?.type));
    if (
      this.state.photo?.length > 0 &&
      this.state.fileList?.type !== undefined &&
      !filterType.includes(this.state.fileList?.type)
    ) {
      message.error("Only JPG,JPEG,PNG Files are allowed");
      return;
    }
    this.setState({ loading: true });
    console.log(fieldsValue);
    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      passengerId: this.state.passengerId,
      photo: this.state.photo,
      stid: this.state.stId,
      pickUpTime: fieldsValue?.pickUpTime
        ? fieldsValue?.pickUpTime?.format("HH:mm")
        : "",
      dropOffTime: fieldsValue?.dropOffTime
        ? fieldsValue?.dropOffTime?.format("HH:mm")
        : "",
    };

    const response = await this.passengerStore.addData(data);
    await this.props.response(response);
    console.log(response, "res");
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   await this.props.navigate(this.queryId?"/passengerList2/1":"/passengerList");
    // } else {
    //   message.error(response.data.errors[0].tagId);

    // }
    this.setState({ loading: false });
  };

  fillClassDiv = () => {
    var div = [];

    for (let i = 65; i <= 77; i++) {
      div.push(String.fromCharCode(i));
    }
    if (div) {
      return div.map((value) => {
        return (
          <Select.Option key={value} value={value}>
            {value}
          </Select.Option>
        );
      });
    }
  };

  onTypeChange = (val) => {
    if (val === "Student") {
      this.setState({ studentType: true });
      this.setState({ employeeType: false });
    } else {
      this.setState({ studentType: false });
      this.setState({ employeeType: true });
    }
  };
  validateNumber = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };
  validateFee = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9.]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }
  getNewLatlong = (val) => {
    console.log(val);
  };
  getUploadData = async (fileUp) => {
    console.log(fileUp.fileList);
    // if (fileUp.fileList[0]?.type == "image/png") {
    let fileinfo = fileUp.file.response;
    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)
      console.log("innnnnnnn");
      await this.setState({ photo: fileinfo.data });
    }
    fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
    this.passengerStore.setPhotoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
    // }
  };

  validateFile = (file) => {
    this.setState({ fileList: file });
    console.log(file);
    const allowedExtensions = [".jpeg", ".jpg", ".png"];
    const fileName = file.name;
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
      message.error("Only JPEG and PNG files are allowed");
      return false;
    }
    const maxSize = conf.photoUploadSize;
    if (file.size > maxSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return false;
    }
    return true;
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              ref={this.formRef}
              layout="vertical"
              name="nest-messages"
              colon={true}
              onFinish={this.onPassengerDetailSubmit}
              style={{ maxWidth: "100%", padding: 0 }}
              className="formClass" //change
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      // label="Account"
                      label={this.context.translate(
                        "_FLEET_DASHBORD_NEWPOI_CUSTOMER_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        allowClear={true}
                        onChange={this.handleChange}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="type"
                    initialValue={this.state.type}
                    // label="Type"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_NEWPOI_TYPE_"
                    )}
                  >
                    <Select
                      // placeholder="Select Type "
                      placeholder={this.context.translate("_SELECT_TYPE_")}
                      onSelect={(val) => {
                        this.onTypeChange(val);
                        this.setState({ type: val });
                      }}
                    >
                      <Select.Option value="Student">
                        {/* Student */}
                        {this.context.translate("_STUDENT_")}
                      </Select.Option>
                      <Select.Option value="Employee">
                        {/* Employee */}
                        {this.context.translate("_EMPLOYEE_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  {this.state.employeeType && (
                    <>
                      <Form.Item
                        name="employeeName"
                        // label="Employee Name"
                        label={this.context.translate("_EMPLOYEE_NAME_")}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="employeeDesignation"
                        // label="Designation "
                        label={this.context.translate("_DESIGNATION_")}
                        rules={[{}]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="employeeDepartment"
                        // label="Department"
                        label={this.context.translate("_DEPARTMENT_")}
                        rules={[{}]}
                      >
                        <Input />
                      </Form.Item>
                    </>
                  )}

                  {this.state.studentType && (
                    <>
                      <Form.Item
                        name="studentName"
                        // label="Student Name"
                        label={this.context.translate("_STUDENT_NAME_")}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        name="schoolName"
                        // label="School Name"
                        label={this.context.translate("_SCHOOL_NAME_")}
                        rules={[{}]}
                      >
                        <Input />
                      </Form.Item>

                      <Row>
                        <Col span={11}>
                          <Form.Item
                            name="className"
                            // label="Class Name"
                            label={this.context.translate("_CLASS_NAME_")}
                          >
                            <Select
                              fieldNames={{
                                value: "classId",
                                label: "className",
                              }}
                              optionFilterProp="children"
                              options={this.passengerStore?.getClass}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={11}>
                          <Form.Item
                            name="classDiv"
                            // label="Division"
                            label={this.context.translate("_CLASS_DIV_")}
                          >
                            <Select
                              // placeholder="Select Type "
                              placeholder={this.context.translate(
                                "_SELECT_TYPE_"
                              )}
                            >
                              {this.fillClassDiv()}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={11}>
                          <Form.Item
                            name="term"
                            // label="Term"
                            label={this.context.translate("_TERM_")}
                          >
                            <Select
                              // placeholder="Select Type "
                              placeholder={this.context.translate(
                                "_SELECT_TYPE_"
                              )}
                            >
                              <Select.Option value="firstTerm">
                                {/* First Term */}
                                {this.context.translate("_FIRST_TERM_")}
                              </Select.Option>
                              <Select.Option value="SecondTerm">
                                {/* Second Term */}
                                {this.context.translate("_SECOND_TERM_")}
                              </Select.Option>
                              <Select.Option value="ThirdTerm">
                                {/* Third Term */}
                                {this.context.translate("_THIRD_TERM_")}
                              </Select.Option>
                              <Select.Option value="FullTerm">
                                {/* Full Term */}
                                {this.context.translate("_FULL_TERM_")}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={2}></Col>
                        <Col span={11}>
                          <Form.Item
                            name="fee"
                            // label="Fee"
                            label={this.context.translate("_FEE_")}
                            rules={[{ type: Number }]}
                          >
                            <Input
                              type="tel"
                              onChange={(e) => {
                                this.validateFee(e.target.value, "fee");
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  )}

                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="pickUpTime"
                        // label="Pick Up Time"
                        label={this.context.translate("_PICK_UP_TIME_")}
                        rules={[{ type: times }]}
                      >
                        <TimePicker
                          format={"HH:mm"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="dropOffTime"
                        // label="Drop Off Time"
                        label={this.context.translate("_DROP_OFF_TIME_")}
                      >
                        <TimePicker
                          format={"HH:mm"}
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="pickUpPoint"
                    // label="Location"
                    label={this.context.translate("_LOCATION_")}
                    rules={[{}]}
                  >
                    <Autocomplete
                      style={{
                        width: "100%",
                        height: "46px",
                        boxShadow: "none",
                        padding: "4px 11px",
                        borderWidth: 1,
                        borderStyle: "solid",
                        borderColor: "#d9d9d9",
                        borderRadius: "5px",
                      }}
                      componentRestrictions={{ country: "us" }}
                      options={{
                        types: ["geocode", "establishment"],
                      }}
                      placeholder={this.context.translate("_ENTER_LOCATION_")}
                      apiKey={config.googleMapKey}
                      onPlaceSelected={async (place) => {
                        console.log(place);
                        await this.setState({ addressMap: true });
                        await this.formRef.current.setFieldsValue({
                          pickUpPoint: place.formatted_address,
                          latitude: place.geometry.location.lat(),
                          longitude: place.geometry.location.lng(),
                        });

                        console.log(
                          place.geometry.location.lat(),
                          place.geometry.location.lng()
                        );
                        await this.setState({
                          addressInfo: [
                            place.geometry.location.lat(),
                            place.geometry.location.lng(),
                          ],
                        });

                        await this.setState({ update: 1 });
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="vehicleNo"
                    // label="Bus No"
                    label={this.context.translate("_BUSNO_")}
                  >
                    <Select
                      // placeholder="Select Asset"
                      placeholder={this.context.translate(
                        "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                      )}
                      showSearch
                      // fieldNames={{
                      //   value: "vehicleId",
                      //   label: "vehicleNo",
                      // }}
                      optionFilterProp="children"
                      // options={this.passengerStore?.assetVehicleList}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.passengerStore?.assetVehicleList?.map((val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Row>
                    <Col span={10}>
                      <Form.Item label=" ">
                        <Button
                          type="primary"
                          onClick={() => this.setState({ busRootShow: true })}
                        >
                          {/* Check Bus Route */}
                          {this.context.translate("_CHECK_BUS_ROUT_")}
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col span={4} />
                    <Col span={10}>
                      <div
                        style={{
                          display: this.state.studentType ? "" : "none",
                        }}
                      >
                        <Form.Item
                          name="mobileapp"
                          label="  "
                          valuePropName="checked"
                        >
                          {/* <Checkbox>Mobile App</Checkbox> */}
                          <Checkbox>
                            {this.context.translate("_MOOBILE_APP_")}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>

                  {/* <Form.Item
                    name="mobileNo"
                    // label="Mobile No"
                    label={this.context.translate("_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_")}
                    rules={[
                      {
                        pattern: /^(\d{7}|\d{10})$/,
                        // message: "Enter 7 or 10 Digit Number",
                        message:this.context.translate("_Enter_7_or_10_Digit_Number_")
                      },
                    ]}
                  >
                    <Input
                      type="tel"
                      maxLength={13}
                      onChange={(e) => {
                        this.validateNumber(e.target.value, "mobileNo");
                      }}
                    />
                  </Form.Item> */}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Form.Item
                      style={{ width: "30%" }}
                      name="countryCode"
                      // label="Contact 1"
                      label={" "}
                      // rules={[{ required: true }]}
                      rules={[{ required: true }]}
                      initialValue={config.countryCode}
                    >
                      <Select>
                        <Select.Option value={91}>+91</Select.Option>
                        <Select.Option value={966}>+966</Select.Option>
                        {/* 966 = saudi arabia */}
                        <Select.Option value={971}>+971</Select.Option>
                        {/* 971 = uae */}
                      </Select>
                    </Form.Item>

                    <Form.Item
                      style={{ width: "65%" }}
                      name="mobileNo"
                      // label="Phone Number 1"
                      label={this.context.translate("_MOBILE_NUMBER_")}
                      rules={[
                        {
                          pattern: /^(\d{8}|\d{9}|\d{10})$/,
                          // message: "Enter a 7 or 10 digit number",
                          message: this.context.translate(
                            "_Enter_7_or_10_Digit_Number_"
                          ),
                        },
                      ]}
                      // rules={[{ required: true}]}
                    >
                      <Input
                        onChange={(e) =>
                          this.validateNumber(e.target.value, "mobileNo")
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="bloodGroup"
                    // label="Blood Group"
                    label={this.context.translate(
                      "_FLEET_DRIVER_DRIVERDETAIL_BLOODGROUP_"
                    )}
                    rules={[{}]}
                  >
                    <Input />
                  </Form.Item>
                  <Row>
                    <Col span={11}>
                      <Form.Item
                        name="idNo"
                        // label="Id No"
                        label={this.context.translate("_IDNO_")}
                        rules={[{ type: Number }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={2} />
                    <Col span={11}>
                      <Form.Item
                        name="tagId"
                        // label="Tag Id"
                        label={this.context.translate("_TAG_ID_")}
                        rules={[{ type: Number }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <div
                    style={{ display: this.state.employeeType ? "" : "none" }}
                  >
                    <Form.Item
                      name="photo"
                      label=" "
                      className="formCheckboxCss"
                    >
                      <Upload
                        name="photo"
                        action={
                          config.baseURL + "/index.php?c=company&a=fileupload"
                        }
                        listType="picture"
                        data={{ fileType: "studentphoto", fieldName: "photo" }}
                        onChange={this.getUploadData}
                        // onRemove={() => {
                        //   this.setState({ photo: "", fileList: [] });
                        // }}
                        onRemove={async () => {
                          await this.passengerStore.setPhotoFromDb([]);
                          await this.setState({ photo: "", fileList: [] });
                        }}
                        maxCount={1}
                        accept=".jpg,.jpeg,.png"
                        // Add the beforeUpload prop to perform file extension validation
                        beforeUpload={(file) => this.validateFile(file)}
                        {...this.passengerStore.photoFromDb}
                      >
                        <Button className="imgupload" icon={<UploadOutlined />}>
                          {/* Upload Photo */}
                          {this.context.translate(
                            "_FLEET_DASHBORD_NEWPOI_PHOTO_"
                          )}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>

                  <div
                    style={{ display: this.state.studentType ? "" : "none" }}
                  >
                    <Form.Item
                      name="siblingsName1"
                      // label="Sibling 1 Name"
                      label={this.context.translate("_SIBLING1_NAME_")}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="siblingsId1"
                      // label="Sibling 1 Id"
                      label={this.context.translate("_SIBLING1_ID_")}
                      rules={[{ type: Number }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="siblingsClass1"
                      // label="Sibling 1 Class "
                      label={this.context.translate("_SIBLING1_CLASS_")}
                    >
                      <Input />
                    </Form.Item>
                  </div>

                  <div
                    style={{ display: this.state.studentType ? "" : "none" }}
                  >
                    <Form.Item
                      name="siblingsName2"
                      // label="Sibling 2 Name "
                      label={this.context.translate("_SIBLING2_NAME_")}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="siblingsId2"
                      // label="Sibling 2 Id"
                      label={this.context.translate("_SIBLING2_ID_")}
                      rules={[{ type: Number }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="siblingsClass2"
                      // label="Sibling 2 Class "
                      label={this.context.translate("_SIBLING2_CLASS_")}
                      rules={[{ type: Number }]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      name="fatherName"
                      // label="Father Name "
                      label={this.context.translate("_FATHER_NAME_")}
                      rules={[{}]}
                    >
                      <Input />
                    </Form.Item>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        style={{ width: "30%" }}
                        name="fatherContactNoCountryCode"
                        // label="Contact 1"
                        label={" "}
                        // rules={[{ required: true }]}
                        initialValue={config.countryCode}
                      >
                        <Select>
                          <Select.Option value={91}>+91</Select.Option>
                          <Select.Option value={966}>+966</Select.Option>

                          <Select.Option value={971}>+971</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        style={{ width: "65%" }}
                        name="fatherContactNo"
                        // label="Phone Number 1"
                        label={this.context.translate("_FATHER_CONTACT_NO_")}
                        rules={[
                          {
                            // required: true,
                            pattern: /^(\d{7}|\d{10})$/,
                            message: this.context.translate(
                              "_Enter_7_or_10_Digit_Number_"
                            ),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validateNumber(
                              e.target.value,
                              "fatherContactNo"
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name="motherName"
                      // label="Mother Name "
                      label={this.context.translate("_MOTHER_NAME_")}
                    >
                      <Input />
                    </Form.Item>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        style={{ width: "30%" }}
                        name="motherContactNoCountryCode"
                        // label="Contact 1"
                        label={" "}
                        // rules={[{ required: true }]}
                        initialValue={config.countryCode}
                      >
                        <Select>
                          <Select.Option value={91}>+91</Select.Option>
                          <Select.Option value={966}>+966</Select.Option>

                          <Select.Option value={971}>+971</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        style={{ width: "65%" }}
                        name="motherContactNo"
                        // label="Phone Number 1"
                        label={this.context.translate("_MOTHER_CONTACT_NO_")}
                        rules={[
                          {
                            // required: true,
                            pattern: /^(\d{7}|\d{10})$/,
                            message: this.context.translate(
                              "_Enter_7_or_10_Digit_Number_"
                            ),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validateNumber(
                              e.target.value,
                              "motherContactNo"
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name="guardianName"
                      // label="Guardian Name "
                      label={this.context.translate("_GUARDIAN_NAME_")}
                      rules={[{}]}
                    >
                      <Input />
                    </Form.Item>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Form.Item
                        style={{ width: "30%" }}
                        name="guardianContactNoCountryCode"
                        // label="Contact 1"
                        label={" "}
                        // rules={[{ required: true }]}
                        initialValue={config.countryCode}
                      >
                        <Select>
                          <Select.Option value={91}>+91</Select.Option>
                          <Select.Option value={966}>+966</Select.Option>

                          <Select.Option value={971}>+971</Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        style={{ width: "65%" }}
                        name="guardianContactNo"
                        // label="Phone Number 1"
                        label={this.context.translate("_GUARDIAN_CONTACT_NO_")}
                        rules={[
                          {
                            // required: true,
                            pattern: /^(\d{7}|\d{10})$/,
                            message: this.context.translate(
                              "_Enter_7_or_10_Digit_Number_"
                            ),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) =>
                            this.validateNumber(
                              e.target.value,
                              "guardianContactNo"
                            )
                          }
                        />
                      </Form.Item>
                    </div>

                    <Form.Item
                      name="photo"
                      label=" "
                      className="formCheckboxCss"
                    >
                      <Upload
                        name="photo"
                        action={
                          config.baseURL + "/index.php?c=company&a=fileupload"
                        }
                        listType="picture"
                        onChange={this.getUploadData}
                        // onRemove={async () => { this.setState({ photo: null })}}
                        data={{ fileType: "studentphoto", fieldName: "photo" }}
                        // onRemove={() =>
                        //   this.setState({ photo: "", fileList: [] })
                        // }
                        // onRemove={async() =>{await this.setState({ photo: "", fileList: [] });await this.passengerStore.setPhotoFromDb([])}}
                        // onRemove={async () => { await this.setState({ photo: "", fileList: [] }); await this.userStore.setPhotoFromDb([]) }}
                        onRemove={async () => {
                          await this.passengerStore.setPhotoFromDb([]);
                          await this.setState({ photo: "", fileList: [] });
                        }}
                        maxCount={1}
                        accept=".jpg,.jpeg,.png"
                        // Add the beforeUpload prop to perform file extension validation
                        beforeUpload={(file) => this.validateFile(file)}
                        {...this.passengerStore.photoFromDb}
                      >
                        <Button className="imgupload" icon={<UploadOutlined />}>
                          {/* Upload Photo */}
                          {this.context.translate(
                            "_FLEET_DASHBORD_NEWPOI_PHOTO_"
                          )}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                  }}
                >
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
        <Modal
          // title="Route Trip List"
          title={this.context.translate("_ROUT_TRIP_LIST_")}
          open={this.state.busRootShow}
          width={800}
          footer={null}
          onOk={() => this.setState({ busRootShow: false })}
          onCancel={() => this.setState({ busRootShow: false })}
        >
          <div
            style={{
              paddingTop: "10px",
              // paddingRight: "24px",
              // paddingLeft: "24px",
              paddingBottom: "10px",
            }}
          >
            <Button type="primary">
              {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
            </Button>
            <br />
            <br />
            <ListTable
              current={this.state.currentPage}
              total={this.passengerStore?.total}
              columns={this.columnsRouteTripList}
              dataSource={this.passengerStore?.rootTripListArray}
              loading={this.passengerStore?.loading}
              onChange={this.handleTableChange}
            />
          </div>
        </Modal>

        <Modal
          className="multiTracking-popup"
          title=<div>
            Location On Map
            <CloseOutlined
              onClick={() => this.setState({ addressMap: false })}
            />
          </div>
          maskClosable={false}
          style={{ padding: "0px !important" }}
          centered
          bodyStyle={{ height: "60vh", padding: "0px" }} // Adjust the dimensions as needed
          width={1000}
          visible={this.state.addressMap}
          onCancel={() => this.setState({ addressMap: false })}
          footer={null}
        >
          <DisplayLocationMap
            addressInfo={
              this.state.addressInfo.length > 0 && this.state.addressInfo
            }
            getNewLatLong={this.getNewLatlong}
            formRef={this.formRef}
          />
        </Modal>
      </div>
    );
  }
}
PassengerAdd.contextType = StoreContext;
export default observer(withRouter1(PassengerAdd));
