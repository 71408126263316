import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class AddSocialMediaStore {
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      total: observable,
      loading: observable,

      loadSocialMediaData: action,
      addData: action,
    });
    this.rootStore = rootStore;
  }

  async loadSocialMediaData({ companyId }) {
    console.log(companyId);
    var postData = {
      facebookLink: "",
      instagramLink: "",
      linkedinLink: "",
      emailLink: "",
      tiktokLink: "",
      whatsappNo: "",
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=addSocialMedia", "postData=" + base64Input);
      console.log(response?.data?.data)

    return response?.data?.data;
  }

  async addData(values) {
    console.log(values);
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));

    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=addSocialMedia", "postData=" + base64Input);

    return response;
  }
}

export default AddSocialMediaStore;
