import React, { useContext, useState } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Checkbox, Col, Form, Input, Radio, Row, Select } from "antd";

const GprsFormFields = (props) => {
  const shouldShow = shouldShowComponent(config.project, "GprsFormFields");

  // console.log(shouldShow,config.project)
  const storeContext = useContext(StoreContext);

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      {config.project === "starlingeye" && (
        <>
          {" "}
          <Row
            style={{
              padding: "0px 20px",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              className="search-field"
              name="vehicleId"
              // label="Asset Name"
              label={storeContext.translate(
                "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
              )}
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select
                // style={{ width: "130px" }}
                // placeholder="Select Asset"
                placeholder={storeContext.translate(
                  "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                )}
                value={props.vehicleId}
                onChange={(val) => {
                  // setVehicleId(val);
                  props.selectVehicles(val);
                  props.formRef.current.setFieldsValue({
                    smsInput: undefined,
                    cmdText: undefined,
                  });
                }}
                showSearch
                fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                optionFilterProp="children"
                options={props.gprsStore}
              />
            </Form.Item>
          </Row>
          <Row style={{ padding: "0px 20px" }}>
            <Form.Item
              name="commandType"
              label={storeContext.translate("_COMMAND_TYPE_")}
              style={{ width: "100%" }}
            >
              <Select
                onSelect={(val) => {
                  // this.setState({ commandType: val });
                  props.changeCommandFields(val);
                }}
                options={[
                  {
                    label: "APN",
                    value: 1,
                  },
                  {
                    label: "IP & Port",
                    value: 2,
                  },
                  {
                    label: "ACC ON Interval",
                    value: 3,
                  },
                  {
                    label: "ACC OFF Interval",
                    value: 4,
                  },
                  {
                    label: "Other",
                    value: 5,
                  },
                ]}
              ></Select>
            </Form.Item>
            {props.This.otherCommand && (
              <Form.Item
                name="smsInput"
                // label="SMS Command"
                label={storeContext.translate("_SMS_COMMAND_")}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input
                  // onChange={(e) =>
                  //   this.setState({
                  //     smsInput: e.target.value,
                  //   })
                  // }
                  onChange={async (e) =>
                    props.handleInputChange(e.target.value, "smsInput")
                  }
                />
              </Form.Item>
            )}

            {props.This.APNCommand && (
              <Form.Item
                name="apncmd"
                // label="SMS Command"
                label={storeContext.translate("_ENTER_APN_")}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input
                  onChange={async (e) =>
                    props.handleInputChange(e.target.value, "apncmd")
                  }
                />
              </Form.Item>
            )}

            {props.This.ipportcmd && (
              <Row>
                <Col span={11}>
                  <Form.Item
                    name="ipcmd"
                    // label="SMS Command"
                    label={storeContext.translate("_ENTER_IP_")}
                    rules={[{ required: true }]}
                    // style={{width:"100%"}}
                  >
                    <Input
                      onChange={async (e) =>
                        props.handleInputChange(e.target.value, "ip")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Form.Item
                    name="port"
                    // label="SMS Command"
                    label={storeContext.translate("_ENTER_PORT_")}
                    rules={[{ required: true }]}
                    // style={{width:"100%"}}
                  >
                    <Input
                      pattern="\d*"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={async (e) =>
                        props.handleInputChange(e.target.value, "port")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {props.This.intervalcmd && (
              <Form.Item
                name="interval"
                // label="SMS Command"
                label={storeContext.translate("_ENTER_INTERVAL_")}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input
                  onChange={async (e) =>
                    props.handleInputChange(e.target.value, "interval")
                  }
                />
              </Form.Item>
            )}
          </Row>
          <Row style={{ padding: "0px 20px" }}>
            <Form.Item
              name="cmdText"
              // label="Command"
              style={{ width: "100%" }}
              // style={{}}
              label={storeContext.translate("_COMMAND_")}
              rules={[{ required: true }]}
            >
              <Input
                readOnly={true}
                onFocus={props.generateCmd}
                // onClick={() => {
                //   this.commandchange();
                // }}
              />
            </Form.Item>
          </Row>
        </>
      )}

      {config.project === "trackeazy" && (
        <>
          {" "}
          <Row
            style={{
              padding: "0px 20px",
              justifyContent: "space-between",
            }}
          >
            <Form.Item
              className="search-field"
              name="vehicleId"
              // label="Asset Name"
              label={storeContext.translate(
                "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
              )}
              rules={[{ required: true }]}
              style={{ width: "100%" }}
            >
              <Select
                // style={{ width: "130px" }}
                // placeholder="Select Asset"
                placeholder={storeContext.translate(
                  "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                )}
                value={props.vehicleId}
                onChange={(val) => {
                  // setVehicleId(val);
                  props.selectVehicles(val);
                  props.formRef.current.setFieldsValue({
                    smsInput: undefined,
                    cmdText: undefined,
                  });
                }}
                showSearch
                fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                optionFilterProp="children"
                options={props.gprsStore}
              />
            </Form.Item>
          </Row>
          <Row style={{ padding: "0px 20px" }}>
            <Form.Item
              name="commandType"
              label={storeContext.translate("_COMMAND_TYPE_")}
              style={{ width: "100%" }}
            >
              <Select
                onSelect={(val) => {
                  // this.setState({ commandType: val });
                  props.changeCommandFields(val);
                }}
                options={[
                  {
                    label: "APN",
                    value: 1,
                  },
                  {
                    label: "IP & Port",
                    value: 2,
                  },
                  {
                    label: "ACC ON Interval",
                    value: 3,
                  },
                  {
                    label: "ACC OFF Interval",
                    value: 4,
                  },
                  {
                    label: "Other",
                    value: 5,
                  },
                ]}
              ></Select>
            </Form.Item>
            {props.This.otherCommand && (
              <Form.Item
                name="smsInput"
                // label="SMS Command"
                label={storeContext.translate("_SMS_COMMAND_")}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input
                  // onChange={(e) =>
                  //   this.setState({
                  //     smsInput: e.target.value,
                  //   })
                  // }
                  onChange={async (e) =>
                    props.handleInputChange(e.target.value, "smsInput")
                  }
                />
              </Form.Item>
            )}

            {props.This.APNCommand && (
              <Form.Item
                name="apncmd"
                // label="SMS Command"
                label={storeContext.translate("_ENTER_APN_")}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input
                  onChange={async (e) =>
                    props.handleInputChange(e.target.value, "apncmd")
                  }
                />
              </Form.Item>
            )}

            {props.This.ipportcmd && (
              <Row>
                <Col span={11}>
                  <Form.Item
                    name="ipcmd"
                    // label="SMS Command"
                    label={storeContext.translate("_ENTER_IP_")}
                    rules={[{ required: true }]}
                    // style={{width:"100%"}}
                  >
                    <Input
                      onChange={async (e) =>
                        props.handleInputChange(e.target.value, "ip")
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Form.Item
                    name="port"
                    // label="SMS Command"
                    label={storeContext.translate("_ENTER_PORT_")}
                    rules={[{ required: true }]}
                    // style={{width:"100%"}}
                  >
                    <Input
                      pattern="\d*"
                      onKeyPress={(e) => {
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={async (e) =>
                        props.handleInputChange(e.target.value, "port")
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}

            {props.This.intervalcmd && (
              <Form.Item
                name="interval"
                // label="SMS Command"
                label={storeContext.translate("_ENTER_INTERVAL_")}
                rules={[{ required: true }]}
                style={{ width: "100%" }}
              >
                <Input
                  onChange={async (e) =>
                    props.handleInputChange(e.target.value, "interval")
                  }
                />
              </Form.Item>
            )}
          </Row>
          <Row style={{ padding: "0px 20px" }}>
            <Form.Item
              name="cmdText"
              // label="Command"
              style={{ width: "100%" }}
              // style={{}}
              label={storeContext.translate("_COMMAND_")}
              rules={[{ required: true }]}
            >
              <Input
                readOnly={true}
                onFocus={props.generateCmd}
                // onClick={() => {
                //   this.commandchange();
                // }}
              />
            </Form.Item>
          </Row>
        </>
      )}

      {config.project === "awtltrack" && (
        <>
          <Row style={{ padding: "0px 20px" }}>
            <Form.Item
              style={{ width: "100%" }}
              name="smsInput"
              // label="SMS Command"
              label={storeContext.translate("_SMS_COMMAND_")}
              rules={[{ required: true }]}
            >
              <Input
                onChange={async (e) =>
                  props.handleInputChange(e.target.value, "smsInput")
                }
                // onChange={()=>props.}
                // onChange={(e) => this.setState({smsInput:e.target.value,disable:false})}
                // placeholder="SMS Command"
                // onChange={(val) => {
                //   console.log(val);
                //   this.getCommand(
                //     this.gprsStore?.getVehiclesUsingCompanyId
                //   );
                // }}
              />
            </Form.Item>
          </Row>

          <Row
            style={{
              padding: "0px 20px",

              justifyContent: "space-between",
            }}
          >
            <Form.Item
              className="search-field"
              name="vehicleId"
              label={storeContext.translate(
                "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
              )}
              rules={[{ required: true }]}
              style={{ width: "80%" }}
            >
              <Select
                placeholder={storeContext.translate(
                  "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                )}
                mode="multiple"
                maxTagCount={"responsive"}
                value={props.This.vehicleId}
                onChange={(val) => {
                  props.selectVehicles(val);
                  // this.setState({ vehicleId: val });
                  props.formRef.current.setFieldsValue({
                    smsInput: undefined,
                    cmdText: undefined,
                  });
                }}
                onSelect={(val) => {
                  // console.log(val);
                  props.getDeviceName(val);
                }}
                showSearch
                fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                optionFilterProp="children"
                options={props.gprsStore}
              />
            </Form.Item>
            <Form.Item label=" ">
              <Checkbox
                checked={props.This.selectAllVehicles}
                onChange={async (e) => {
                  // await this.setState({
                  //   selectAllVehicles: e.target.checked,
                  // });
                  // console.log(e.target.checked);
                  await props.handleCheckBoxChange(e.target.checked);
                  await props.selectAllVehicles();
                }}
              >
                {storeContext.translate("_ALL_")}
              </Checkbox>{" "}
            </Form.Item>
          </Row>
          <Row style={{ padding: "0px 20px" }}>
            <Form.Item
              name="commandType"
              label=""
              //  className="formCheckboxCss"
              className="label-seprator"
              style={{ margin: "8px 0px 0px 0px" }}
            >
              <span style={{ color: "white" }}>
                {storeContext.translate("_COMMAND_TYPE_")}
              </span>{" "}
              <Radio.Group
                defaultValue={1}
                onChange={(e) => props.handleCommandType(e.target.value)}
                style={{ display: "flex" }}
              >
                <Radio value={1}>{storeContext.translate("_NORMAL_")}</Radio>
                <Radio value={0}>
                  {storeContext.translate("_ModBus_")}
                </Radio>{" "}
              </Radio.Group>
            </Form.Item>
          </Row>
          <Row style={{ padding: "0px 20px" }}>
            <Form.Item
              name="cmdText"
              // label="Command"
              style={{ width: "100%" }}
              // style={{}}
              label={storeContext.translate("_COMMAND_")}
              rules={[{ required: true }]}
            >
              <Input
                disabled={props.This.disable}
                onClick={() => {
                  props.commandchange();
                }}
              />
            </Form.Item>
          </Row>
        </>
      )}
    </>
  );
};

GprsFormFields.contextType = StoreContext;
export default observer(withRouter(GprsFormFields));
