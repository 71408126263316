import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class AssignGeofenceToComapnyStore {
  geofencesList = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      total: observable,
      loading: observable,
      geofencesList: observable,
      getGeofenceData: action,
      onAssignGeofenceToCompany: action,
    });
    this.rootStore = rootStore;
  }

  async onAssignGeofenceToCompany(values) {
    var postData = values;
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=geofence&a=sendGeofence", "postData=" + base64Input);

    return response;
  }

  async getGeofenceData() {
    var response = await postApi
      .getClient()
      .post("index.php?c=geofence&a=getGeofence");

    this.geofencesList = response?.data?.data;

    return response?.data?.data;
  }
}

export default AssignGeofenceToComapnyStore;
