import {Form,Select,Input,Row,Col,DatePicker,Switch,Checkbox,Button} from 'antd';
import React, { useContext } from 'react';
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";

  const UserDetailsFields = (props) => {
        console.log('props',props);
        const shouldShow = shouldShowComponent(config.project, "UserDetailsFields");

        const storeContext = useContext(StoreContext); // Use the context here
       console.log("shouldShow**",shouldShow);
    if (!shouldShow) {

      return (
        <Form.Item
          name="name"
          // label="User Name"
          label={storeContext.translate(
            "_FLEET_DASHBORD_NEWPOI_NAME_"
          )}
          rules={[{ required: true }]}
        >
          <Input />
        </Form.Item>

      )                 
    }


    return (
    <>
       <Form.Item
                        name="dobFormat"
                        // label="DOB Format"
                        label={storeContext.translate("_DOB_FORMATE_")}
                      >
                        <Select
                          // placeholder="Select DOB Format"
                          placeholder={storeContext.translate(
                            "_SELECT_DOB_FORMAT_"
                          )}
                        >
                          <Select.Option value="Hijri">
                            Hijri
                            {/* {this.context.translate("_HIJRI_")} */}
                          </Select.Option>
                          <Select.Option value="Gregorian">
                            Gregorian
                            {/* {this.context.translate("_GREGORIAN_")} */}
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="dob"
                        // label="Date Of Birth"
                        label={storeContext.translate("_DOB_LABEL_")}
                        rules={[{ type: Date }]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                        />
                      </Form.Item>

        </>
      
    );
  };


  UserDetailsFields.contextType = StoreContext;
export default observer(withRouter(UserDetailsFields));
