import React from "react";
import { Row, Col, Space, Input, Button, Modal, message } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import ListTable from "./ListTable";

import withRouter from "../withRouter";

import VehicleStore from "../store/VehicleStore";
import { Link } from "react-router-dom";
import VehicleAdd from "./VehicleAdd";

class GlobalSearchList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      input: "",
      openVehicleAddForm:false,
    };
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      //   await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_COMPANY_"),
        dataIndex: "company",
        width: "15%",
        render: (val, record) => {
            return (
              <Link
                onClick={(e) => {
                  e.preventDefault();
                  this.onEdit(record);
                }}
              >
                {val}
              </Link>
            );
          },
      },
      {
        title: this.context.translate("_FLEET_VEHICLE_VEHICLELIST_VEHICLENO_"),
        dataIndex: "vehicleNo",
        width: "15%",
      },
      {
        title: this.context.translate("_VEHICLE_MODEL_"),
        dataIndex: "model",
        width: "15%",
      },
      {
        title: this.context.translate("_CHASSIS_NUMBER_"),
        dataIndex: "chassisNo",
        width: "15%",
      },
      {
        title: this.context.translate("_IMEI_"),
        dataIndex: "full_imei",
        width: "15%",
      },
      {
        title: this.context.translate("_SIM_NO_"),
        dataIndex: "simNo",
        width: "15%",
      },
      {
        title: this.context.translate("_TRACKER_ID_"),
        dataIndex: "trackerId",
        width: "15%",
      },
    ];
  }

 
  onEdit = async (record) => {
    const vehicleId = record.vehicleId;
    if (vehicleId) {
      this.context.viewStateStore.setVehicleStates({
        companyId: record.companyId,
      });
      // this.props.navigate("/vehicleadd/" + vehicleId);
      await this.setState({ vehicleIdForEdit: vehicleId });
      await this.setState({ openVehicleAddForm: true });
    }
  };

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      page: this.state.currentPage,
      input: this.state.input,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async searchInput(e) {
    const value = e.target.value;
    if (value.length >= 2) {
      this.setState({ loading: true });
      await this.vehicleStore.globalSearchList({
        companyId: this.state.companyId,
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        page: this.state.currentPage,
        input: value,
      });
    } else {
      await this.vehicleStore.globalSearchList({
        companyId: this.state.companyId,
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        page: this.state.currentPage,
        input: value,
      });
    }
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  closeModal = () => {
    this.setState({ openVehicleAddForm: false });
  };

  responseMsg = async (resp) => {
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      console.log(resp.data.message);
      // this.props.navigate("/vehiclelist");
      this.setState({ openVehicleAddForm: false });
      await this.setState({ listDataUpdate: true });
    } else if (resp.data.success == "F0") {
      if (resp?.data?.errors[0]?.full_imei) {
        message.error(this.context.translate(resp.data?.errors[0]?.full_imei));
      }
    }
  };


  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_GLOBAL_SEARCH_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={18}>
              <Space>
                <Input
                  style={{ width: 200 }}
                  placeholder={this.context.translate("_SEARCH_")}
                  onChange={async (e) => {
                    await this.setState({ input: e.target.value });
                    await this.searchInput(e);
                  }}
                />
              </Space>
            </Col>
            <Col span={6}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Link link to={"/vehiclelist"}>
                  <Button type="primary">Back</Button>
                </Link>
              </Space>
            </Col>
          </Row>
          <ListTable
            currentPage={this.state.currentPage}
            total={this.vehicleStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.vehicleStore?.globalSearchData}
            loading={this.vehicleStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openVehicleAddForm && (
          <Modal
            bodyStyle={{ height: "100%" }}
            className="formShowModal" //  {/* css class */}
            // title="Add New Vehicle"
            maskClosable={false}
            title={this.context.translate("_ADD_NEW_VEHICLE_")}
            centered
            visible={this.state.openVehicleAddForm}
            footer={null}
            onCancel={() => {
              this.setState({ openVehicleAddForm: false });
            }}
            width={665}
          >
            <div>
              <VehicleAdd
                vehicleIdForEdit={this.state.vehicleIdForEdit}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
              {/* companyId={this.state.companyId} ID={this.state.assignId} response={this.assignresponseMsg} */}
            </div>
          </Modal>
        )}
      </>
    );
  }
}
GlobalSearchList.contextType = StoreContext;
export default observer(withRouter(GlobalSearchList));
