import {Space, Dropdown,Form,Input, Select,Row,Col,DatePicker,Switch,Checkbox,Button} from 'antd';
import { Link } from "react-router-dom";
import React, { useContext } from 'react';
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";


  const DriverListFields = (props) => {
        console.log('props',props);
       
    const shouldShow = shouldShowComponent(config.project, "DriverListFields");

     const storeContext = useContext(StoreContext); // Use the context here

    if (!shouldShow) {
      return null; // Do not render if the condition is not met
    }

    return (
    <>
        <Link to={"/wasldriverlist"}>
          <Button
            onClick={async () =>
              await storeContext.viewStateStore.setDriverState({
                companyId: props.companyId,
                searchText: props.searchField,
                showWaslList: true,
              })
            }
          >
            {/* WASL */}
            {storeContext.translate("_WASL_")}
          </Button>
        </Link>
        </>
      
    );
  };


  DriverListFields.contextType = StoreContext;
export default observer(withRouter(DriverListFields));
