import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Space,
  message,
  Upload,
  Form,
  Modal,
  Tooltip,
  Switch,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  DeleteFilled,
  SearchOutlined,
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import DriversStore from "../store/DriversStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";

import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import conf from "../config";

import withRouter from "../withRouter";

import CompanyStore from "../store/CompanyStore";
import DriverAdd from "./DriverAdd";
import DriverListFields  from "./Plugins/AddDriver/DriverListFields";

class CompanyDriversList extends React.Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.companySearch=this.companySearch.bind(this);
    this.onDelete = this.onDelete.bind(this);

    this.driversStore = new DriversStore(this);
    this.companyStore = new CompanyStore(this);

    // console.log(this.context);

    this.state = {
      loading: false,
      companyLoading: false,
      pageSize: 50,
      currentPage: 1,
      offset: 0,
      limit: 50,
      openConfirm: false,
      confirmLoading: false,
      loadList: 0,
      companyId: "",
      companyId_value_label: "",
      showUpload: false,
      openDriverDetailsForm: false,
      driverId: "",
      listDataUpdate: false,
      searchField: "",
      showStatusConfirm: false,
      showStatusLogout: false,
      logOutDriverId: "",
    };
    // this.menuProps = {
    //   items: [
    //     {
    //       label: "Sample Excel",
    //       key: "1",
    //       icon: <DownloadOutlined />,
    //     },
    //     // {
    //     //   label: "Upload",
    //     //   key: "2",
    //     //   icon: <CloudUploadOutlined />,
    //     // },
    //     // {
    //     //   label: <Link to={"/wasldriverlist"}>WASL</Link>,
    //     //   key: "3",
    //     //   icon: <PlusOutlined />,
    //     // },
    //     {
    //       label: "Export To",
    //       key: "excel",
    //       icon: <DownloadOutlined />,
    //     },
    //   ],
    //   onClick: (val) => {
    //     let result = [];
    //     console.log(val);
    //     if (true) {
    //       this.columns.map((obj) => result.push(obj.dataIndex));

    //       //  console.log(result.toString())

    //       var getData = {
    //         companyId: this.state.companyId,
    //         colList: result,
    //       };
    //       if (val.key === "excel") {
    //         // console.log("afd")
    //         this.driversStore.exportExcel(getData);
    //       }
    //       if (val.key === "1") {
    //         this.driversStore.sampleExcel();
    //       }
    //     }
    //     // console.log("getData",getData)
    //   },
    // };
  }

  async componentDidUpdate(prvProp, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const driverStates = this.context.viewStateStore.driverStateObj;
      if (driverStates.companyId !== "") {
        const singleData = await this.companyStore.getCompanyDataById(
          driverStates.companyId
        );
        await this.setState({
          companyId_value_label: singleData,
          searchField: driverStates.searchText,
          companyId: driverStates.companyId,
        });
        await this.driversStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          searchField: driverStates.searchText,
          companyId: driverStates.companyId,
          isadmin: this.props.getUserData().isadmin,
        });
        await this.setState({ loadList: 1, companyLoading: false });
        await this.setState({ loadList: 1 });
      }

      await this.setState({ listDataUpdate: false });
      console.log(driverStates.companyId);
    }
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      //  console.log(this.state.companyId)
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    this.setState({ searchField: "" });
    console.log(
      this.context.viewStateStore.driverStateObj.showWaslList,
      "sammek"
    );
    if (this.context.viewStateStore.driverStateObj.showWaslList) {
      console.log(
        this.context.viewStateStore.driverStateObj.showWaslList,
        "sammek"
      );
      const driverStates = this.context.viewStateStore.driverStateObj;
      if (driverStates.companyId !== "") {
        const singleData = await this.companyStore.getCompanyDataById(
          driverStates.companyId
        );
        await this.setState({
          companyId_value_label: singleData,
          searchField: driverStates.searchText,
          companyId: driverStates.companyId,
        });
        await this.driversStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          searchField: driverStates.searchText,
          companyId: driverStates.companyId,
          isadmin: this.props.getUserData().isadmin,
        });
      }
    }
    // await this.passengerStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });
    //this.handleSearch();
    // this.setState({loadList:1})

    this.columns = [
      {
        title: this.context.translate("_DRIVER_ID_"),

        dataIndex: "driverId",
        width: "5%",
      },
      {
        title: this.context.translate("_DRIVER_NAME_"),

        dataIndex: "name",
        width: "6%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_DRIVER_NAME_ARABIC_"),

        dataIndex: "driverNameArabic",
        width: "8%",
      },
      // {
      //   title: this.context.translate("_ASSIGN_ASSET_"),
      //   dataIndex: "vehicleNo",
      //   width: "15%",
      // },
      {
        title: this.context.translate("_LICENSE_NUMBER_"),
        dataIndex: "licensenumber",
        width: "6%",
      },
      {
        title: this.context.translate("_LICENSE_NUMBER_ARABIC_"),
        dataIndex: "licenseNoArabic",
        width: "8%",
      },
      {
        title: this.context.translate("_FLEET_DRIVER_DRIVERLIST_MOBILE_"),
        dataIndex: "mobileno",
        width: "8%",
      },
      {
        title: this.context.translate("_TAG_ID_"),
        dataIndex: "DriverTagid",
        width: "4%",
      },
      {
        title: this.context.translate(
          "_FLEET_DRIVER_DRIVERDETAIL_LICENSEEXPIRY_"
        ),

        dataIndex: "expireDate",
        width: "7%",
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        fixed: "right",
        width: "4%",
        render: (_, record) => {
          // console.log(record)
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit Driver Details"
                    title={this.context.translate(
                      "_FLEET_DRIVER_DRIVERLIST_EDIT_"
                    )}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}

                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      // title="Delete Driver"
                      title={this.context.translate(
                        "_FLEET_DRIVER_DRIVERLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>

                <Tooltip
                  title={this.context.translate("_FLEET_LOGOUT_")}
                  color={config.tooltipColorEdit}
                >
                  {/* <LogoutOutlined
                    onClick={() => {
                      this.setState({
                        showStatusLogout: true,
                        logOutDriverId: record.driverId,
                      });
                    }}
                  /> */}
                  <span>
                    <i
                      class="bi bi-power"
                      onClick={() => {
                        this.setState({
                          showStatusLogout: true,
                          logOutDriverId: record.driverId,
                        });
                      }}
                    ></i>
                  </span>
                </Tooltip>

                <Tooltip
                  title={
                    record.status == "1"
                      ? this.context.translate("_ACTIVE_")
                      : this.context.translate(
                          "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                        )
                  }
                  placement="top"
                  color={
                    record.status == "1"
                      ? config.activeColor
                      : config.inActiveColor
                  }
                >
                  <Switch
                    size="small"
                    className="form-offlineAlerts"
                    checked={Number(record.status)}
                    onChange={async (val) => {
                      console.log(val);
                      this.setState({ driverId: record.driverId });
                      this.setState({ isActive: val });
                      this.setState({ showStatusConfirm: true }); // open modal
                    }}
                    // defaultChecked
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }
  onEdit = async (record) => {
    console.log(record.driverId);
    const driverId = record.driverId;
    if (driverId) {
      await this.context.viewStateStore.setDriverState({
        companyId: record.companyId,
        searchText: this.state.searchField,
      });
      await this.setState({ driverId: driverId });
      await this.setState({ openDriverDetailsForm: true });
      // await this.props.navigate("/driveradd/" + driverId);
    }
  };
  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  confirmStatus = async (status) => {
    const data = {};

    data.driverId = this.state.driverId;
    data.activeStatus = this.state.isActive === true ? 1 : 0;

    await this.driversStore.updateStatus(data);

    console.log(data);

    this.setState({ showStatusConfirm: false });
    await this.handleSearch();
    //  await this.setState({ loading: true });
    //   await this.driversStore.loadData({ offset: 0, limit: 50 });
    // await this.setState({ loading: false });
  };

  confirmLogoutStatus = async () => {
    const data = {
      driverId: this.state.logOutDriverId,
    };
    console.log(data);
    const response = await this.driversStore.logoutDriver(data);
    if (response?.success === "S") {
      message.success(response.message);
      this.setState({ showStatusLogout: false });
      // this.props.navigate("/companydriverslist");
    } else {
      message.error(response.message);
    }
  };

  async handleChange(comp) {
    console.log(comp);
    await this.setState({
      companyId: comp.value,
      companyLoading: true,
      pageSize: 50,
      currentPage: 1,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyId_value_label: singleData });

    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.driversStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
      isadmin: this.props.getUserData().isadmin,
    });
    await this.setState({ loadList: 1, companyLoading: false });
  }

  async inputSearchButton() {
    if (this.state.companyId && this.state.searchField.length > 0) {
      await this.driversStore.loadData({
        offset: 0,
        limit: 50,
        searchField: this.state.searchField,
        companyId: this.state.companyId,
      });
    }
    await this.setState({ loadList: 1 });
  }
  async onDelete(record) {
    console.log(record, "reco");
    const response = await this.driversStore.deleteRecord({
      driverId: record.driverId,
      userId: 1,
    });
    // console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  searchInput = async (e) => {
    const value = e.target.value;
    console.log(value);
    await this.setState({ searchField: value });
    if (value.length > 3) {
      await this.driversStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ loadList: 1, companyLoading: false });
    } else if (value.length === 0) {
      await this.driversStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        // searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({
        loadList: 1,
        companyLoading: false,
        currentPage: 1,
      });
    }
  };

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  onExcelSubmit = async (fieldsValue) => {
    console.log(fieldsValue);
    const data = {
      ...fieldsValue,
      companyId: this.state.companyId,
      excelFile: this.state.excelFile,
    };
    const resp = await this.driversStore.addDriverByExcel(data);

    console.log(resp);
    if (resp?.success == "S") {
      console.log(resp.data.length > 0);
      message.success(resp.message);

      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
      this.handleSearch();
      // this.formRef.current?.resetFields();
    } else {
      message.error(resp.errors[0].error);
    }
    if (resp.message) {
      this.setState({ showUpload: false });
      this.formRef.current?.resetFields();
    }
  };

  responseMsg = async (resp) => {
    console.log(resp, resp.message);
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openDriverDetailsForm: false });
      await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else if (resp.success === "F5000") {
      resp.errors.map((value) => {
        message.error(this.context.translate(value["username"]));
        
        // console.log(this.context.translate(value["driver"]),value["driver"])
      });
    }
  };

  validateFile = (file) => {
    this.setState({ fileList: file });
    console.log(file);

    const maxSize = conf.UploadExcelSize;
    if (file.size > maxSize) {
      message.error("File size must be less than or equal to 1MB");
      return false;
    }
    return true;
  };

  closeModal = () => {
    this.setState({ openDriverDetailsForm: false, driverId: "" });
  };

  // validateFile = (file) => {
  //   this.setState({ fileList: file });
  //   console.log(file);
  //   const allowedExtensions = [".xlsx", ".xls", ".csv"];
  //   const fileName = file.name;
  //   const fileExtension = fileName.slice(
  //     ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
  //   );
  //   if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
  //     message.error(
  //       "Allowed File Extension are xls,xlsx,csv and size upto 1mb"
  //     );
  //     return false;
  //   }
  //   const maxSize = conf.UploadExcelSize;
  //   if (file.size > maxSize) {
  //     message.error("File size must be less than or equal to 1MB");
  //     return false;
  //   }

  //   return true;
  // };
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Driver List</div> */}
              <div className="heading">
                {this.context.translate("_FLEET_DRIVER_DRIVERLIST_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/driveradd"
                /> */}
                <Tooltip
                  placement="left"
                  // title="Add"
                  title={this.context.translate(
                    "_FLEET_DRIVER_DRIVERLIST_ADDBTN_"
                  )}
                  color={config.primaryColor}
                >
                  <Button
                    type="primary"
                    className="addButton"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      this.setState({
                        openDriverDetailsForm: true,
                        driverId: "",
                      })
                    }
                  ></Button>
                </Tooltip>

                {(Number(this.props.getUserData().isadmin) === -1 ||
                  Number(this.props.getUserData().isadmin) === 3) && (

                   <DriverListFields companyId={this.state.companyId} searchField={this.state.searchField}/>
                  
                )}



                <Button
                  disabled={
                    this.driversStore?.drivers?.length > 0 ? false : true
                  }
                  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    let result = [];
                    if (true) {
                      this.columns.map((obj) => result.push(obj.dataIndex));

                      //  console.log(result.toString())

                      var getData = {
                        companyId: this.state.companyId,

                        searchField: "",
                        colList: result,
                      };
                      console.log(getData);
                      if (val) {
                        // console.log("afd")
                        this.driversStore.exportExcel(getData);
                      }
                    }
                  }}
                >
                  <DownloadOutlined />
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>

                <Tooltip
                  // title="Upload"
                  title={this.context.translate("_UPLOAD_")}
                  color={config.tooltipColorEdit}
                >
                  <Button
                  className="uploadBtn"
                    type={config.uploadBoxType}
                    onClick={() => this.setState({ showUpload: true })}
                  >
                    <UploadOutlined />
                  </Button>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  value={
                    this.state.companyId_value_label
                      ? this.state.companyId_value_label
                      : null
                  }
                  onChange={(value) => {
                    this.handleChange(value);
                  }}
                  // allowClear={true}
                  style={{ width: 200 }}
                  disabled={this.state.companyLoading}
                />
                <Input
                  // placeholder="Search by Driver Name,Mobile No"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_DRIVER_NAME_MOBILE_"
                  )}
                  value={
                    this.state.searchField !== ""
                      ? this.state.searchField
                      : null
                  }
                  style={{ width: 300 }}
                  onChange={async (e) => {
                    await this.searchInput(e);
                  }}
                />
                <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={() => {
                    this.inputSearchButton();
                    console.log("asdf");
                  }}
                />

                {/* <Button>Sample Excel</Button>
                    <Button>Upload</Button>
                    <Button>WASL</Button> */}
              </Space>
            </Col>

            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.driversStore?.total}
            columns={this.columns}
            dataSource={this.driversStore?.drivers}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.driversStore.total || 0,
            // }}
            loading={this.driversStore?.loading}
            onChange={this.handleTableChange}
          />

          {this.state.showUpload && (
            <>
              {" "}
              <Modal
                open={this.state.showUpload}
                // title="File Upload"
                title={this.context.translate("_FILE_UPLOAD_")}
                maskClosable={false}
                onOk={() => this.setState({ showUpload: false })}
                onCancel={() =>
                  this.setState({
                    showUpload: false,
                    excelFile: "",
                    companyId: "",
                  })
                }
                footer={false}
                width={400}
                // style={{width:100}}
              >
                <Form
                  name="excelimport"
                  layout="vertical"
                  onFinish={this.onExcelSubmit}
                  style={{ padding: "0px 10px" }}
                >
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(companyId) => {
                          console.log(companyId.value);
                          this.setState({ companyId: companyId.value });
                          // this.formRef.current.setFieldsValues({excelFile:undefined})
                        }}
                        style={{ width: 300 }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="excelFile"
                    // className="label-seprator"

                    className="uploadFile"
                    // label="Excel File"
                    label={this.context.translate("_EXCEL_FILE_")}
                    rules={[{ required: true }]}
                  >
                    <Upload
                      // beforeUpload={(file) => this.validateFile(file)}
                      onRemove={() =>
                        this.setState({ excelFile: "", fileList: [] })
                      }
                      name="excelFile"
                      action={
                        config.baseURL + "/index.php?c=company&a=fileupload"
                      }
                      listType="text"
                      data={{
                        fileType: "accountimport",
                        fieldName: "excelFile",
                      }}
                      onChange={this.getUploadData}
                      beforeUpload={(file) => this.validateFile(file)}
                      maxCount={1}
                    >
                      <Button icon={<UploadOutlined />}>
                        {/* Click To Upload Excel File */}
                        {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                      </Button>
                    </Upload>
                  </Form.Item>

                  <div className="formBtnGroup">
                    {/* css class */}
                    <Button
                      className="formCancelButton"
                      // style={{ backgroundColor: config.activeColor }}
                      onClick={() => this.driversStore.sampleExcel()}
                    >
                      {this.context.translate("_SAMPLE_")}
                      {/* <DownloadOutlined /> */}
                      {/* SAMPLE EXCEL */}
                      {/* {this.context.translate("_SAMPLE_EXCEL_")} */}
                    </Button>
                    <Button
                      type="primary"
                      className="formSaveButton"
                      htmlType="submit"
                      // loading={this.state.loading}
                    >
                      {/* css class */}
                      {/* Upload */}
                      {this.context.translate("_UPLOAD_")}
                    </Button>
                  </div>
                  {/* <Row justify={"end"}>
                <Space style={{ padding: "10px" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    loading={this.driversStore.excelLoadig}
                  >
                    Upload
                  </Button>
                </Space>
              </Row> */}
                </Form>
              </Modal>{" "}
            </>
          )}
        </div>

        {this.state.showStatusConfirm && (
          <Modal
            maskClosable={false}
            title={this.context.translate("_CONFIRM_")}
            description="Are you sure to change this status?"
            centered
            visible={this.state.showStatusConfirm}
            //footer={null}
            onOk={this.confirmStatus}
            onCancel={() => {
              this.setState({ showStatusConfirm: false });
            }}
            width={400}
          >
            {this.state.isActive === true ? (
              <p>{this.context.translate("_STATUS_ACTIVE_")}</p>
            ) : (
              <p>{this.context.translate("_STATUS_INACTIVE_")}</p>
            )}
          </Modal>
        )}

        {this.state.openDriverDetailsForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Driver Details"
            title={this.context.translate("_FLEET_DRIVER_DRIVERDETAIL_TITLE_")}
            centered
            visible={this.state.openDriverDetailsForm}
            footer={null}
            onCancel={() => {
              this.setState({ openDriverDetailsForm: false, driverId: "" });
            }}
            width={665}
          >
            <div>
              <DriverAdd
                driverId={this.state.driverId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}

        {this.state.showStatusLogout && (
          <Modal
            maskClosable={false}
            title={this.context.translate("_CONFIRM_")}
            description="
            Are you sure you want to logout?"
            centered
            open={this.state.showStatusLogout}
            //footer={null}
            onOk={this.confirmLogoutStatus}
            onCancel={() => {
              this.setState({ showStatusLogout: false });
            }}
            width={400}
          >
            {this.context.translate("_LOGOUT_STATUS_")}
          </Modal>
        )}
      </>
    );
  }
}
CompanyDriversList.contextType = StoreContext;
export default observer(withRouter(CompanyDriversList));
