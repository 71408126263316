import React from "react";
import {
  Select,
 
  Button,
  DatePicker,
  Space,
  Row,
  Col,
  Checkbox,
  Dropdown,
  Modal,
  Form,
  Tooltip,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { DownOutlined } from "@ant-design/icons";
import DriverScoreReportStore from "../store/DriverScoreReportStore";
import DriverLoginReportStore from "../store/DriverLoginReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import config from "../config";
import withRouter from "../withRouter";

import dayjs from "dayjs";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
import VehicleStore from "../store/VehicleStore";
const { RangePicker } = DatePicker;
const { Option } = Select;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class DriverScoreReport extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.driverScoreReportStore = new DriverScoreReportStore();
    this.driverLoginReportStore = new DriverLoginReportStore();
    this.vehicleStore = new VehicleStore();
    // this.myRef = React.createRef(),
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedDriver: [],
      selectedVehicle: [],
      selectAllDrivers: false,
      selectAllVehicles: false,
      companyId: "",
      pageNo: 1,
      offset: 0,
      limit: 50,
      modalVisible: false,
      column: "",
      filter: "",
      dateWiseReport: false,
      // fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      // toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      fromDate: dayjs().format("YYYY-MM-DD "),
      toDate: dayjs().format("YYYY-MM-DD "),
      showEmail: false,
      Notcompany: false,
      Notdriver: false,
      Nottype: false,
      filterby: "",
      notvehicle: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "driver",
          key: "driver",

          width: "15%",
        },
        {
          // title: "Asset",
          title: context.translate("_FLEET_DASHBORD_SEARCHVEHICLE_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },

        {
          // title: "Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "date",
          key: "date",
          width: "15%",
        },

        {
          // title: <Tooltip title="_ACC_EVENT_">Acce.</Tooltip>,
          title: (
            <Tooltip title={context.translate("_ACC_EVENT_")}>
              {context.translate("_ACCEL_N_")}
            </Tooltip>
          ),
          // title: "Acce",
          dataIndex: "acccount",
          key: "acccount",
          width: "15%",
          // tooltip: '_ACC_EVENT_'
        },
        {
          // title: <Tooltip title="_BRAKE_EVENT_">Brake</Tooltip>,
          title: (
            <Tooltip title={context.translate("_BRAKE_EVENT_")}>
              {context.translate("_DBRAKE_")}
            </Tooltip>
          ),
          // title: "Brake",
          dataIndex: "brakecount",
          key: "brakecount",

          width: "15%",
          // tooltip :'_BRAKE_EVENT_'
        },
        {
          // title: <Tooltip title="_EXIDLE_EVENT_">Exidle</Tooltip>,
          title: (
            <Tooltip title={context.translate("_EXIDLE_EVENT_")}>
              {context.translate("_EXIDLE_")}
            </Tooltip>
          ),
          // title: "Exidle",
          dataIndex: "exidlecount",
          key: "exidlecount",

          width: "15%",
          // tooltip :'_EXIDLE_EVENT_'
        },
        {
          // title: <Tooltip title="_SPEED_EVENT_">Speed</Tooltip>,
          title: (
            <Tooltip title={context.translate("_SPEED_EVENT_")}>
              {context.translate("_FLEET_DASHBORD_SETTING_SPEED_")}
            </Tooltip>
          ),
          // title: "Speed",
          dataIndex: "speedcount",
          key: "speedcount",

          width: "15%",
          // tooltip :'_SPEED_EVENT_'
        },
        {
          // title: "SeatBelt",
          // title: context.translate("_FLEET_DASHBORD_SETTING_SEATBELT_"),
          title:  (
            <Tooltip title={context.translate("_SEATBELT_EVENT_")}>
              {context.translate("_FLEET_DASHBORD_SETTING_SEATBELT_")}
            </Tooltip>
          ),
          dataIndex: "seatbeltrate",
          key: "seatbelt",
          width: "15%",
        },
        {
          // title: "Distance",
          title: context.translate(
            "_FLEET_RULEDETAIL_SELECTPARAMETER_DISTANCE_"
          ),
          dataIndex: "distanceTravel",
          key: "distanceTravel",

          width: "15%",
        },
        {
          // title: <Tooltip title="_ACC_RATE_">AR(10%)</Tooltip>,
          title: <Tooltip title={context.translate("_ACC_RATE_")}>AR</Tooltip>,
          // title: "AR",
          dataIndex: "accrate",
          key: "accrate",

          width: "15%",
          render: (value, c, r) => {
            if (c.accpercent > 0) {
              return value;
            } else {
              return "-";
            }
          },
          // tooltip:'_ACC_RATE_'
        },
        // {
        //   title: "AR",
        //   dataIndex: "ar",
        //   key: "ar",

        //   width: "15%",
        // },
        {
          title: (
            <Tooltip title={context.translate("_BRAKE_RATE_")}>BR</Tooltip>
          ),
          // title: "BR",
          dataIndex: "brakerate",
          key: "brakerate",

          width: "15%",
          render: (value, c, r) => {
            if (c.brakepercent > 0) {
              return value;
            } else {
              return "-";
            }
          },
          // tooltip:'_BRAKE_RATE_'
        },
        {
          title: (
            <Tooltip title={context.translate("_EXIDLE_RATE_")}>ExIR</Tooltip>
          ),
          // title: "EXIR",
          dataIndex: "exidlerate",
          key: "exidlerate",

          width: "15%",
          render: (value, c, r) => {
            if (c.exidlepercent > 0) {
              return value;
            } else {
              return "-";
            }
          },
          // tooltip:'_EXIDLE_RATE_'
        },
        {
          title: (
            <Tooltip title={context.translate("_SPEED_RATE_")}>SR</Tooltip>
          ),
          // title: "SR",
          dataIndex: "speedrate",
          key: "speedrate",

          width: "15%",
          render: (value, c, r) => {
            if (c.speedpercent > 0) {
              return value;
            } else {
              return "-";
            }
          },
          // tooltip:"_SPEED_RATE_"
        },
        // {
        //   title: "RR",
        //   dataIndex: "rr",
        //   width: "15%",
        //   key: "rr",

        // },
        {
          title: (
            <Tooltip title={context.translate("_SEATBELT_RATE_")}>
              {context.translate("_FLEET_DASHBORD_SETTING_SEATBELT_")}
            </Tooltip>
          ),
          // title: "SeatBelt",
          dataIndex: "seatbeltrate",
          key: "seatbeltrate",
          // tooltip:'_SEATBELT_EVENT_',
          width: "15%",
          render: (value, c, r) => {
            if (c.seatbeltpercent > 0) {
              return value;
            } else {
              return "-";
            }
          },
        },
        {
          // title: "Score",
          title: context.translate("_SCORE_"),
          dataIndex: "score",
          key: "score",

          width: "15%",
          render: (value, c, r) => {
            var percent25 = (c.topScore * 25) / 100;
            var percent80 = (c.topScore * 80) / 100;
            if (c.score < percent25) {
              return (
                <div
                  style={{
                    backgroundColor: "red",
                    width: 50,
                    height: 25,
                    color: "black",
                    padding: 10,
                  }}
                >
                  {c.score}
                </div>
              );
            } else if (c.score > percent80) {
              return (
                <div
                  style={{
                    backgroundColor: "green",
                    width: 50,
                    height: 25,
                    color: "black",
                    padding: 10,
                  }}
                >
                  {c.score}
                </div>
              );
            } else if (c.score >= percent25 && c.score <= percent80) {
              return (
                <div
                  style={{
                    backgroundColor: "yellow",
                    width: 50,
                    height: 25,
                    color: "black",
                    padding: 10,
                  }}
                >
                  {c.score}
                </div>
              );
            } else {
              return value;
            }
          },
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            Driver: this.state.selectedDriver,
            reportType: this.state.reportType,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            // userId:JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            // filter: this.state.filter,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.driverScoreReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.driverScoreReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.driverScoreReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.driverScoreReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    await this.driverScoreReportStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      await this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    window.scrollTop = 0;
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedDriver: [], selectAllDrivers: false });
    await this.setState({ selectedVehicle: [], selectAllVehicles: false });

    await this.driverLoginReportStore.getDriversByCompanyId({
      companyId: comp?.value,
    });

    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    // console.log(this.driverLoginReportStore.getDriversByCompanyId)
    await this.handleSearch();
    //  console.log(data,"sdcd")
  }

  async handleSearch() {
    this.setState({ Notdriver: false });
    await this.driverScoreReportStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.pageNo,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  // driverSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedDriver: val });
  // };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    // if (this.state.filterby == "Driver") {
    //   if (this.state.selectedDriver.length === 0) {
    //     this.setState({ Notdriver: true });
    //   } else {
    //     this.setState({ Notdriver: false });
    //   }
    // } else {
    //   if (this.state.selectedVehicle.length === 0) {
    //     this.setState({ notvehicle: true });
    //   } else {
    //     this.setState({ notvehicle: false });
    //   }
    // }
    if (!this.state.reportType) {
      this.setState({ Nottype: true });
    } else {
      this.setState({ Nottype: false });
    }
    if (
      (this.state.companyId &&
        this.state.selectedDriver.length > 0 &&
        this.state.reportType) ||
      (this.state.companyId &&
        this.state.selectedVehicle.length > 0 &&
        this.state.reportType)
    ) {
      this.setState({ mandatory: false });

      this.setState({
        Notcompany: false,
        Notdriver: false,
        notvehicle: false,
        Nottype: false,
      });

      await this.driverScoreReportStore.loadData({
        offset: (this.state.pageNo - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        driverId: this.state.selectedDriver,
        vehicleId: this.state.selectedVehicle,
        filterBy: this.state.filterby,
        reportType: this.state.reportType,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        pageNo: this.state.pageNo,
      });
      console.log(this.driverScoreReportStore?.total);
      if (this.driverScoreReportStore?.total > 0) {
        this.updateColumnTitle();
      }
      console.log(this.state.column[9].title);
      await this.setState({ loadData: 1 });
    }
  };
  updateColumnTitle = () => {
    let data = this.driverScoreReportStore?.driverScoreReport[0];
    console.log("accpercent : ", data.accpercent);
    const updatedColumns = [...this.state.column];
    updatedColumns[9].title = "AR (" + data.accpercent + "%)";
    updatedColumns[10].title = "BR (" + data.brakepercent + "%)";
    updatedColumns[11].title = "ExIR (" + data.exidlepercent + "%)";
    updatedColumns[12].title = "SR (" + data.speedpercent + "%)";
    updatedColumns[13].title = "SeatBelt (" + data.seatbeltpercent + "%)";

    this.setState({ columns: updatedColumns });
  };
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  handleSelectAllDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.driverLoginReportStore?.getDrivers.map((val) => {
        return val.driverId;
      });
      await this.setState({ selectedDriver: allDrivers });
    } else {
      await this.setState({ selectedDriver: [] });
    }
  };

  handleSelectAllVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicle: allVehicles });
    } else {
      await this.setState({ selectedVehicle: [] });
    }
  };

  onDateTimeChange = (dates) => {
    if (dates) {
      // const format = "YYYY-MM-DD HH:mm:ss,";
      const format = "YYYY-MM-DD";

      this.setState({
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  selectTypeofDate = (value) => {
    console.log(value);
    if (value == "3" || value == "2") {
      this.setState({ dateWiseReport: true });
      this.setState({ monthWiseReport: false });
    } else if (value == "1") {
      this.setState({ dateWiseReport: false });
      this.setState({ monthWiseReport: true });
    }
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const DriverInputStyle = this.state.Notdriver
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const TypeInputStyle = this.state.Nottype
      ? { border: "1px solid red", width: "170px", borderRadius: "8px" }
      : { width: "170px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Driver Score Report</div> */}
              <div className="heading">
                {this.context.translate("_DRIVER_SCORE_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={() => this.setState({ showEmail: true })}
                  // disabled={
                  //   this.driverScoreReportStore?.driverScoreReport?.length > 0
                  //     ? false
                  //     : true
                  // }
                >
                  {" "}
                  {/* Email */}
                  {this.context.translate("_FLEET_LOGIN_EMAILLBL_")}
                </Button>

              

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.driverScoreReportStore?.driverScoreReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button 
                   className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={22}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {/* <Checkbox
                  checked={this.state.selectAllDrivers}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllDrivers: e.target.checked,
                    });
                    if (this.state.selectAllDrivers) {
                      await this.setState({
                        Notdriver: false,
                      });
                    }
                    console.log(e.target.checked);
                    await this.handleSelectAllDrivers();
                  }}
                /> */}
                {/* All */}
                <Select
                  style={{ width: 150 }}
                  // placeholder='Filter by'
                  placeholder={this.context.translate("_FILTER_BY_")}
                  onChange={(val) => {
                    this.setState({ filterby: val });
                  }}
                >
                  {/* <Select.Option value="Driver">Driver</Select.Option> */}
                  <Select.Option value="Driver">
                    {this.context.translate(
                      "_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"
                    )}
                  </Select.Option>
                  {/* <Select.Option value="Vehicle">Vehicle</Select.Option> */}
                  <Select.Option value="Vehicle">
                    {this.context.translate("_VEHICLES_")}
                  </Select.Option>
                </Select>

                {this.state.filterby == "Driver" && (
                  <>
                    <Checkbox
                      checked={this.state.selectAllDrivers}
                      onChange={async (e) => {
                        await this.setState({
                          selectAllDrivers: e.target.checked,
                        });
                        if (this.state.selectAllDrivers) {
                          await this.setState({
                            Notdriver: false,
                          });
                        }
                        console.log(e.target.checked);
                        await this.handleSelectAllDrivers();
                      }}
                    />
                    {this.context.translate("_ALL_")}
                    <Select
                      // onSearch={this.handleSearch}
                      mode="multiple"
                      value={this.state.selectedDriver}
                      maxTagCount="responsive"
                      onChange={(val) => {
                        this.setState({
                          selectedDriver: val,
                          Notdriver: false,
                        });
                        if (
                          this.driverLoginReportStore?.getDrivers.length >
                          val.length
                        ) {
                          this.setState({ selectAllDrivers: false });
                        } else {
                          this.setState({ selectAllDrivers: true });
                        }
                      }}
                      // fieldNames={{ label: "name", value: "driverId" }}
                      // placeholder="Driver name"
                      placeholder={this.context.translate(
                        "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                      )}
                      optionFilterProp="children"
                      // options={this.driverLoginReportStore?.getDrivers}
                      // options={this.state.filterby=='Driver' ? this.driverLoginReportStore?.getDrivers :this.driverScoreReportStore?.getVehicles }
                      style={{ width: 150 }}
                      // style={DriverInputStyle}
                      status={this.state.Notdriver ? "error" : false}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.driverLoginReportStore?.getDrivers?.map((val) => {
                        return <Option value={val.driverId}>{val.name}</Option>;
                      })}
                    </Select>
                  </>
                )}

                {this.state.filterby === "Vehicle" && (
                  <>
                    {this.props.getUserData().isadmin === 1 && (
                      <DeviceGroupsForVehicles
                        vehicleStore={this.vehicleStore}
                      
                        setLoadDta={() => this.setState({ loadData: 1 })}
                        resetVehicles={() =>
                          this.setState({
                            selectedVehicle: [],
                            selectAllVehicles:false
                          })
                        }
                      />
                    )}
                    <Checkbox
                      checked={this.state.selectAllVehicles}
                      onChange={async (e) => {
                        await this.setState({
                          selectAllVehicles: e.target.checked,
                        });
                        if (this.state.selectAllVehicles) {
                          await this.setState({
                            notvehicle: false,
                          });
                        }
                        console.log(e.target.checked);
                        await this.handleSelectAllVehicles();
                      }}
                    />
                    {this.context.translate("_ALL_")}
                    {/* <Select
                      onSearch={this.handleSearch}
                      mode="multiple"
                      value={this.state.selectedVehicle}
                      maxTagCount="responsive"
                      onChange={(val) => {
                        this.setState({ selectedVehicle: val, notvehicle: false });
                        if (this.driverScoreReportStore?.getVehicles.length > val.length) {
                          this.setState({ selectAllVehicles: false })
                        }
                        else {
                          this.setState({ selectAllVehicles: true })
                        }
                      }
                      }
                      fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                      placeholder="select asset"

                      optionFilterProp="children"
                      options={this.driverScoreReportStore?.getVehicles}
                      // options={this.state.filterby=='Driver' ? this.driverLoginReportStore?.getDrivers :this.driverScoreReportStore?.getVehicles }
                      style={{ width: 150 }}
                      // style={DriverInputStyle}
                      status={this.state.notvehicle ? 'error' : false}
                    /> */}

                    <Select
                      maxTagCount="responsive"
                      value={this.state.selectedVehicle}
                      onChange={(val) => {
                        this.setState({
                          selectedVehicle: val,
                          notvehicle: false,
                        });
                        if (
                          this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                          val.length
                        ) {
                          this.setState({ selectAllVehicles: false });
                        } else {
                          this.setState({ selectAllVehicles: true });
                        }
                      }}
                      style={{ width: 200 }}
                      showSearch
                      status={this.state.notvehicle ? "error" : false}
                      mode="multiple"
                      placeholder={this.context.translate(
                        "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                      )}
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                    >
                      {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                        (val) => {
                          return (
                            <Option value={val.vehicleId}>
                              {val.vehicleNo}
                            </Option>
                          );
                        }
                      )}
                    </Select>
                  </>
                )}

                <Select
                  style={{ width: 150 }}
                  // style={TypeInputStyle}
                  status={this.state.Nottype ? "error" : false}
                  onSelect={async (value) => {
                    console.log(value);
                    await this.setState({ reportType: value, Nottype: false });
                    // await this.handleSearch()
                  }}
                  onChange={(value) => this.selectTypeofDate(value)}
                  // placeholder="Type"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_NEWPOI_TYPE_"
                  )}
                >
                  {/* <Select.Option value="3">Datewise Report</Select.Option>
                  <Select.Option value="2">Weekly Report</Select.Option>
                  <Select.Option value="1">Monthly Report</Select.Option> */}
                  <Select.Option value="3">Datewise Report</Select.Option>
                  <Select.Option value="2">Weekly Report</Select.Option>
                  <Select.Option value="1">Monthly Report</Select.Option>
                </Select>
                {this.state.dateWiseReport && (
                  <>
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                      defaultValue={dayjs()}
                      onChange={(date) => {
                        // console.log(date)
                        this.setState({fromDate:date.format("YYYY-MM-DD")})
                      }}
                      // style={{ width: "100%" }}
                    />
                    <DatePicker
                      format={"YYYY-MM-DD"}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                      onChange={(date) => {
                        // console.log(date)
                        this.setState({toDate:date.format("YYYY-MM-DD")})
                      }}
                      defaultValue={dayjs()}
                      // style={{ width: "100%" }}
                    />
                    {/* <RangePicker
                      placeholder={["From Datetime", "To Datetime"]}
                      format="DD-MM-YYYY"
                      style={{ width: 300 }}
                      onChange={(val) => {
                        this.onDateTimeChange(val);
                      }}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                      // showTime={{
                      //   format: "h:mmA",
                      //   defaultValue: [dayjs().startOf("day"), dayjs()],
                      // }}
                      // defaultValue={[dayjs().startOf("day"), dayjs()]}
                    /> */}
                  </>
                )}
                {this.state.monthWiseReport && (
                  <>
                    <RangePicker
                      // placeholder={["From Month", "To Month"]}
                      format="MM-YYYY"
                      picker="month"
                      style={{ width: 300 }}
                      onChange={(val) => {
                        this.onDateTimeChange(val);
                      }}
                      disabledDate={(current) => {
                        return current && current > dayjs();
                      }}
                      showTime={{
                        format: "h:mmA",
                        defaultValue: [dayjs().startOf("day"), dayjs()],
                      }}
                      defaultValue={[dayjs().startOf("day"), dayjs()]}
                    />
                  </>
                )}
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                    this.setState({ pageNo: 1 });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={2}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.driverScoreReportStore?.total}
            columns={this.state.column}
            dataSource={this.driverScoreReportStore?.driverScoreReport}
            loading={this.driverScoreReportStore?.loading}
            onChange={this.handleTableChange}
          />

          <Modal
            open={this.state.showEmail}
            // title="Send Email"
            title={this.context.translate("_SEND_EMAIL_")}
            onOk={() => this.setState({ showEmail: false })}
            onCancel={() => this.setState({ showEmail: false })}
            footer={false}
            width={400}
          >
            <Form
              name="Email"
              layout="vertical"
              onFinish={this.onEmailSend}
              style={{ maxWidth: "100%" }}
            >
              <Row>
                <Col span={12}></Col>
                <Col
                  span={12}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                ></Col>
              </Row>
              <Form.Item
                name="Email"
                // label="Email"
                title={this.context.translate("_FLEET_LOGIN_EMAILLBL_")}
                rules={[{ required: true }]}
              >
                <Select
                  onSearch={this.handleSearch}
                  fieldNames={{ label: "emailIds", value: "emailIds" }}
                  // placeholder="Email Ids"
                  placeholder={this.context.translate("_EMAIL_IDS_")}
                  optionFilterProp="children"
                  options={this.machineryReportStore?.emailIdsList}
                  style={{ width: 355 }}
                />
              </Form.Item>
              <div className="formBtnGroup">
                {/* css class */}

                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  // loading={this.state.loading}
                >
                  {/* css class */}
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
              {/* <Row justify={"end"}>
                <Space style={{}}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Row> */}
            </Form>
          </Modal>
        </div>
      </>
    );
  }
}
DriverScoreReport.contextType = StoreContext;
export default observer(withRouter(DriverScoreReport));
