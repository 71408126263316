import React from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  TimePicker,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import {
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";
import config from "../config";
import { format } from "date-fns";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
import MDVRAlarmReportStore from "../store/MDVRAlarmReportStore";
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);
const { Search } = Input;
const dateFormat = "DD-MM-YYYY hh:mmA";
const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);
const { Option } = Select;
class MDVRAlarmReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.mdvrlalarmReportStore = new MDVRAlarmReportStore();

    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      selectedVehicles: [],
      selectAllVehicles: false,
      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,
      Notasset: false,

      selectedColumns: [
        {
          // title: 'Vehicle No ',
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },
        {
          // title: 'Device Serial Id',
          title: context.translate("_DEVICE_ID_"),
          dataIndex: "deviceSerialId",
          key: "deviceSerialId",
          width: "15%",
        },

        // {
        //   title: 'Driver Id',
        //   dataIndex: 'driverId',
        //   key: 'driverId',
        //   width: '15%'
        // },
        {
          // title: 'Date Time',
          title: context.translate("_DATE_TIME_"),
          dataIndex: "dateTime",
          key: "dateTime",
          width: "15%",
        },
        {
          // title: 'Latitude',
          title: context.translate("_FLEET_DASHBORD_SETTING_LATITUDE_"),
          dataIndex: "latitude",
          key: "latitude",
          width: "15%",
        },
        {
          // title: 'Longitude',
          title: context.translate("_FLEET_DASHBORD_SETTING_LONGITUDE_"),
          dataIndex: "longitude",
          key: "longitude",
          width: "15%",
        },
        {
          // title: 'Speed',
          title: context.translate("_FLEET_DASHBORD_SETTING_SPEED_"),
          dataIndex: "speed",
          key: "speed",
          width: "15%",
        },
        {
          // title: 'Alarm Type',
          title: context.translate("_ALARM_TYPE_"),
          dataIndex: "alarmType",
          key: "alarmType",
          width: "15%",
        },
        {
          // title: 'Content',
          title: context.translate("_CONTENT_"),
          dataIndex: "content",
          key: "content",
          width: "15%",
        },
        {
          // title: 'Command Type',
          title: context.translate("_COMMAND_TYPE_"),
          dataIndex: "commandType",
          key: "commandType",
          width: "15%",
        },
        {
          // title: 'View On Map  ',
          title: context.translate("_VIEW_ON_MAP_"),
          dataIndex: "viewonmap",
          key: "viewonmap",
          width: "15%",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "1",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "2",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "3",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "4",
          icon: <PlusOutlined />,
        },
      ],
      onClick: () => {},
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });

    await this.setState({ column: this.state.selectedColumns });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });

    this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ Notasset: false });
    await this.mdvrlalarmReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      await this.mdvrlalarmReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate:this.state.toDate,
      });
      // console.log(this.state.fromDate,this.state.toDate,)
    }
  };
  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">MDVR Alarm Report</div> */}
              <div className="heading">
                {this.context.translate("_MDVR_ALARM_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
             

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.mdvrlalarmReportStore?.mdvrlalarmReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button
                  //  style={{ backgroundColor: config.activeColor }}
                   className="exportDropdown"
                   >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>

              <Checkbox
                checked={this.state.selectAllVehicles}
                onChange={async (e) => {
                  await this.setState({ selectAllVehicles: e.target.checked });
                  if (this.state.selectAllVehicles) {
                    await this.setState({
                      Notasset: false,
                    });
                  }
                  console.log(e.target.checked);
                  await this.handleAllSelectVehicles();
                }}
              />
              {/* All */}
              {this.context.translate("_ALL_")}
              {"  "}

              {/* <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => {this.setState({ selectedVehicles: val,Notasset:false });
                  if(this.vehicleStore?.getVehiclesUsingCompanyId.length>val.length){
                    this.setState({selectAllVehicles:false})
                  }
                  else{
                    this.setState({selectAllVehicles:true})
                  }
                }}
                  maxTagCount="responsive"
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle }
                  status={this.state.Notasset ? 'error' : false}
                /> */}
              <Select
                maxTagCount="responsive"
                value={this.state.selectedVehicles}
                onChange={(val) => {
                  this.setState({ selectedVehicles: val });
                  if (
                    this.vehicleStore?.getVehiclesUsingCompanyId.length >
                    val.length
                  ) {
                    this.setState({ selectAllVehicles: false });
                  } else {
                    this.setState({ selectAllVehicles: true });
                  }

                  if (val?.length > 1) {
                    console.log(val?.length > 1);
                    this.setState({ showAlertPopup: true });
                  }
                }}
                style={{ width: 200 }}
                showSearch
                status={this.state.Notasset ? "error" : false}
                mode="multiple"
                placeholder={this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                )}
                filterOption={(inputValue, option) => {
                  if (option.children) {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                  return <Option value={val.vehicleId}>{val.vehicleNo}</Option>;
                })}
              </Select>

              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>

              <Col>
                <Button type="primary" onClick={() => this.showData()}>
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                  {/* Show */}
                </Button>
              </Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={250}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.mdvrlalarmReportStore?.total}
            columns={this.state.column}
            dataSource={this.mdvrlalarmReportStore?.mdvrlalarmReport}
            loading={this.mdvrlalarmReportStore?.loading}
            onChange={this.handleTableChange}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
        </div>
      </>
    );
  }
}
MDVRAlarmReport.contextType = StoreContext;
export default observer(withRouter(MDVRAlarmReport));
