import React from "react";
import {
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, DownOutlined, CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";

import ListTable from "./ListTable";

import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";

import VehiclePerformanceStore from "../store/VehiclePerformanceStore";

import dayjs from "dayjs";

import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;
// dayjs.extend(customParseFormat);
// const { Search } = Input;
// const dateFormat = "DD-MM-YYYY hh:mmA";
// const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);

let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

class VehiclePerformanceList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.vehiclePerformanceStore = new VehiclePerformanceStore();
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      // fromDate: "",
      // toDate: "",
      selectedVehicles: [],
      selectAllVehicles: false,
      modalVisible: false,
      column: "",
      filter: "",
      showMonthPopup: false,

      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany: false,
      Notasset: false,
      showTable: true,
      showtotaltable: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",
          width: "15%",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "driverName",
          key: "driverName",
          width: "15%",
        },

        {
          // title: "KM",
          title: context.translate("_KM_"),
          dataIndex: "distanceTravel",
          key: "distanceTravel",
          width: "15%",
        },
        {
          // title: "Stoppage",
          title: context.translate("_STOPPAGE_"),
          dataIndex: "stopduration",
          key: "stopduration",
          width: "15%",
        },
        {
          // title: "Travel ",
          title: context.translate("_TRAVEL_"),
          dataIndex: "travelduration",
          key: "travelduration",
          width: "15%",
        },
        {
          // title: "Idle ",
          title: context.translate("_IDLE_"),
          dataIndex: "idleduration",
          key: "idleduration",
          width: "15%",
        },
        {
          // title: "Exc Idle ",
          title: context.translate("_EXC_IDLE_"),
          dataIndex: "exidleduration",
          key: "exidleduration",
          width: "15%",
        },
        {
          // title: "Harsh Break ",
          title: context.translate("_HARSH_BREAK_"),
          dataIndex: "harshBreak",
          key: "harshBreak",
          width: "15%",
        },
        {
          // title: "Harsh Acceleration ",
          title: context.translate("_HARSH_ACCELERATION_"),
          dataIndex: "Harshaccelcounter",
          key: "Harshaccelcounter",
          width: "15%",
        },
        {
          // title: "Over Speed ",
          title: context.translate("_OVER_SPEED_"),
          dataIndex: "overspeedCount",
          key: "overspeedCount",
          width: "15%",
        },
        {
          // title: "Max speed as per the limit set",
          title: context.translate("_MAXIMUM_SPEED_AS_PER_THE_LIMIT_SET_"),
          dataIndex: "maxSpeed",
          key: "maxSpeed",
          width: "15%",
        },
        {
          // title: "Over speed as per the road speed ",
          title: context.translate("_OVER_SPEED_AS_PER_THE_ROAD_SPEED_"),
          dataIndex: "exceedRoadSpeed",
          key: "exceedRoadSpeed",
          width: "15%",
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.vehiclePerformanceStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.vehiclePerformanceStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.vehiclePerformanceStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.vehiclePerformanceStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    await this.setState({ column: this.state.selectedColumns });

    this.columnsTotalVPerfo = widthAdderFunc([
      {
        // title: "Vehicle No",
        title: this.context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
        dataIndex: "vehicleNo",
        width: "15%",
      },
      {
        // title: "Total Driver",
        title: this.context.translate("_TOTAL_DRIVER_"),
        dataIndex: "totalDriver",
        width: "15%",
      },
      {
        // title: "Total KM",
        title: this.context.translate("_TOTAL_KM_"),
        dataIndex: "totalKm",
        width: "15%",
      },
      {
        // title: "Total Stoppage",
        title: this.context.translate("_TOTAL_STOPPAGE_"),
        dataIndex: "totalStop",
        width: "15%",
      },
      {
        // title: "Total Travel",
        title: this.context.translate("_TOTAL_TRAVEL_"),
        dataIndex: "totalTravel",
        width: "15%",
      },
      {
        // title: "Total Idle",
        title: this.context.translate("_TOTAL_IDLE_"),
        dataIndex: "totalIdle",
        width: "15%",
      },
      {
        // title: "Total Exc Idle",
        title: this.context.translate("_TOTAL_EXC_IDLE_"),
        dataIndex: "totalExidle",
        width: "15%",
      },
      {
        // title: "Total Over Speed",
        title: this.context.translate("_TOTAL_OVER_SPEED_"),
        dataIndex: "totalOverspeed",
        width: "15%",
      },
      {
        // title: "Total Over Speed as per the road speed",
        title: this.context.translate("_TOTAL_OVER_SPEED_AS_ROAD_SPEED_"),
        dataIndex: "totalRoadspeed",
        width: "15%",
      },
    ]);

    // await this.setState({ listUpdated: 1 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    this.setState({ update: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.setState({ currentPage: 1, companyId: comp?.value });

    // this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ Notasset: false });
    await this.vehiclePerformanceStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.vehiclePerformanceStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          // toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
          toDate: this.state.toDate,
        });
        // console.log(this.state.fromDate,this.state.toDate,)
        await this.setState({ listUpdated: 1 });
      }
    }
  };

  showTotal = async () => {
    await this.vehiclePerformanceStore?.VehicleTotalPerformance({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      vehicleId: this.state.selectedVehicles,
      fromDate: this.state.fromDate,
      // toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
      toDate: this.state.toDate,
    });
    // console.log(this.state.fromDate,this.state.toDate,)
    await this.setState({ loadData: 1 });
  };
  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Vehicle Performance Report</div> */}
              <div className="heading">
                {" "}
                {this.context.translate("_VEHICLE_PERFORMANCE_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async () => {
                    this.showTotal();
                    await this.setState({
                      showTable: false,
                      showtotaltable: true,
                    });
                  }}
                >
                  {/* Total */}
                  {this.context.translate("_TOTAL_")}
                </Button>
              

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  disabled={
                    this.vehiclePerformanceStore?.vehiclePerformance?.length > 0
                      ? false
                      : true
                  }
                  menu={this.menuProps}
                >
                  <Button 
                  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={(value) => this.handleChange(value)}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
              </Col>
              {this.props.getUserData().isadmin === 1 && (
                <DeviceGroupsForVehicles
                  vehicleStore={this.vehicleStore}
                  setLoadDta={() => this.setState({ loadData: 1 })}
                  resetVehicles={() =>
                    this.setState({
                      selectedVehicles: [],
                      selectAllVehicles:false
                    })
                  }
                />
              )}

              <Checkbox
                checked={this.state.selectAllVehicles}
                onChange={async (e) => {
                  await this.setState({
                    selectAllVehicles: e.target.checked,
                  });
                  if (this.state.selectAllVehicles) {
                    await this.setState({
                      Notasset: false,
                    });
                  }
                  console.log(e.target.checked);
                  await this.handleAllSelectVehicles();
                }}
              />
              {/* All */}
              {this.context.translate("_ALL_")}
              {"  "}

              <Select
                maxTagCount="responsive"
                value={this.state.selectedVehicles}
                onChange={(val) => {
                  this.setState({ selectedVehicles: val });
                  if (
                    this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                    val.length
                  ) {
                    this.setState({ selectAllVehicles: false });
                  } else {
                    this.setState({ selectAllVehicles: true });
                  }

                  if (val?.length > 1) {
                    console.log(val?.length > 1);
                    this.setState({ showAlertPopup: true });
                  }
                }}
                style={{ width: 200 }}
                showSearch
                status={this.state.Notasset ? "error" : false}
                mode="multiple"
                placeholder={this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                )}
                filterOption={(inputValue, option) => {
                  if (option.children) {
                    return (
                      option.children
                        .toLowerCase()
                        .indexOf(inputValue.toLowerCase()) >= 0
                    );
                  }
                }}
              >
                {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                  return <Option value={val.vehicleId}>{val.vehicleNo}</Option>;
                })}
              </Select>

              {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

              <RangePicker
                placeholder={[
                  this.context.translate("_FROM_DATE_TIME_"),
                  this.context.translate("_TO_DATETIME_"),
                ]}
                format="DD-MM-YYYY hh:mmA"
                style={{ width: 400 }}
                onChange={(val) => {
                  this.onDateTimeChange(val);
                }}
                disabledDate={(current) => {
                  return current && current > dayjs();
                }}
                showTime={{
                  format: "h:mmA",
                  defaultValue: [dayjs().startOf("day"), dayjs()],
                }}
                defaultValue={[dayjs().startOf("day"), dayjs()]}
              />

              <Col>
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.showData();
                    await this.setState({ currentPage: 1 });
                    await this.setState({
                      showTable: true,
                      showtotaltable: false,
                    });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Col>
              <Col></Col>
            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={350}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>
          <div
            className="accordion-list"
            onClick={() =>
              this.setState({
                showTable: !this.state.showTable,
                showtotaltable: !this.state.showtotaltable,
              })
            }
          >{this.context.translate("_VEHICLE_PERFORMANCE_REPORT_")}
            <Button>
              {this.state.showTable ? <CaretDownOutlined /> :<CaretUpOutlined />}
            </Button>
          </div>
          {this.state.showTable && (
            <ListTable
              currentPage={this.state.currentPage}
              pageSize={this.state.pageSize}
              total={this.vehiclePerformanceStore?.total}
              columns={this.state.column}
              dataSource={this.vehiclePerformanceStore?.vehiclePerformance}
              // pagination={{
              //   pageSize: this.state.pageSize,
              //   //  current:this.state.pageNo
              //   total:this.vehiclePerformanceStore?.total || 0

              // }}
              loading={this.vehiclePerformanceStore?.loading}
              onChange={this.handleTableChange}

              // scroll={{
              //   x: "calc(700px + 50%)",
              //   y: 280,
              // }}
              // scroll={{
              //   // y: this.state.clientHeight-240,
              //   x: 100,
              // }}
            />
          )}
          {/* <div
            style={{
              backgroundColor: "Transparent ",
              color: "#0090e7",
              fontSize: "16px",
              padding: "8px 8px",
              fontWeight: "600",
              marginTop: "8px",
              marginBottom: "5px",
            }}
          >
            {this.context.translate("_VEHICLE_PERFORMANCE_TOTAL_")}
          </div> */}
          <div
           className="accordion-list"
            onClick={() =>
              this.setState({
                showtotaltable: !this.state.showtotaltable,
                showTable: !this.state.showTable,
              })
            }
          >
            {" "}
            {this.context.translate("_VEHICLE_PERFORMANCE_TOTAL_")}
            <Button >
              {this.state.showtotaltable ? <CaretDownOutlined /> :<CaretUpOutlined />}
            </Button>{" "}
          </div>
          {this.state.showtotaltable && (
            <ListTable
              // current={this.state.currentPage}
              // pageSize={this.state.pageSize}
              total={this.vehiclePerformanceStore?.totalReport}
              columns={this.columnsTotalVPerfo}
              dataSource={
                this.vehiclePerformanceStore?.vehicleTotalPerformanceArray
              }
              pagination={false}
              // loading={this.vehiclePerformanceStore?.loading}
              // onChange={this.handleTableChange}
              summary={(records) => {
                var sum = 0;
                var suma = 0;
                var totalSum = 0;
                var totalIdleSum = 0;
                var totalExidleSum = 0;
                var sumofDriver = 0;
                var sumofRoadSpeed = 0;
                var sumofKm = 0;

                records.map((record) => {
                  if (record.totalDriver) {
                    sumofDriver =
                      parseInt(sumofDriver) + parseInt(record.totalDriver);
                  }

                  if (record.totalKm) {
                    sumofKm = parseInt(sumofKm) + parseInt(record.totalKm);
                  }

                  if (record.totalOverspeed) {
                    sum = parseInt(sum) + parseInt(record.totalOverspeed);
                  }

                  if (record.totalRoadspeed) {
                    sumofRoadSpeed =
                      parseInt(sumofRoadSpeed) +
                      parseInt(record.totalRoadspeed);
                  }

                  if (record.totalStop) {
                    var dt = record.totalStop.split(":");
                    var ts =
                      parseInt(dt[0] * 60 * 60) +
                      parseInt(dt[1] * 60) +
                      parseInt(dt[2]);
                    suma = parseInt(parseInt(suma) + parseInt(ts));
                  }

                  if (record.totalTravel) {
                    var dt1 = record.totalTravel.split(":");
                    var ts1 =
                      parseInt(dt1[0] * 60 * 60) +
                      parseInt(dt1[1] * 60) +
                      parseInt(dt1[2]);
                    totalSum = parseInt(parseInt(totalSum) + parseInt(ts1));
                  }

                  if (record.totalIdle) {
                    var dt2 = record.totalIdle.split(":");
                    var ts2 =
                      parseInt(dt2[0] * 60 * 60) +
                      parseInt(dt2[1] * 60) +
                      parseInt(dt2[2]);
                    totalIdleSum = parseInt(
                      parseInt(totalIdleSum) + parseInt(ts2)
                    );
                  }

                  if (record.totalExidle) {
                    var dt3 = record.totalExidle.split(":");
                    var ts3 =
                      parseInt(dt3[0] * 60 * 60) +
                      parseInt(dt3[1] * 60) +
                      parseInt(dt3[2]);
                    totalExidleSum = parseInt(
                      parseInt(totalExidleSum) + parseInt(ts3)
                    );
                  }
                });
                var d, h, m, s;

                s = suma;
                m = Math.floor(s / 60);
                s = s % 60;
                h = Math.floor(m / 60);
                m = m % 60;
                d = Math.floor(h / 24);
                h = h % 24;

                var result = (d < 10 ? "0" + d : d) + " days,";
                result += h < 10 ? "0" + h : h;
                result += ":" + (m < 10 ? "0" + m : m);
                result += ":" + (s < 10 ? "0" + s : s);

                var d1, h1, m1, s1;

                s1 = totalSum;
                m1 = Math.floor(s1 / 60);
                s1 = s1 % 60;
                h1 = Math.floor(m1 / 60);
                m1 = m1 % 60;
                d1 = Math.floor(h1 / 24);
                h1 = h1 % 24;

                var totalTrips = (d1 < 10 ? "0" + d1 : d1) + " days,";
                totalTrips += h1 < 10 ? "0" + h1 : h1;
                totalTrips += ":" + (m1 < 10 ? "0" + m1 : m1);
                totalTrips += ":" + (s1 < 10 ? "0" + s1 : s1);

                var d2, h2, m2, s2;

                s2 = totalIdleSum;
                m2 = Math.floor(s2 / 60);
                s2 = s2 % 60;
                h2 = Math.floor(m2 / 60);
                m2 = m2 % 60;
                d2 = Math.floor(h2 / 24);
                h2 = h2 % 24;

                var totalIdle = (d2 < 10 ? "0" + d2 : d2) + " days,";
                totalIdle += h2 < 10 ? "0" + h2 : h2;
                totalIdle += ":" + (m2 < 10 ? "0" + m2 : m2);
                totalIdle += ":" + (s2 < 10 ? "0" + s2 : s2);

                var d3, h3, m3, s3;

                s3 = totalExidleSum;
                m3 = Math.floor(s3 / 60);
                s3 = s3 % 60;
                h3 = Math.floor(m3 / 60);
                m3 = m3 % 60;
                d3 = Math.floor(h3 / 24);
                h3 = h3 % 24;

                var totalExidle = (d3 < 10 ? "0" + d3 : d3) + " days,";
                totalExidle += h3 < 10 ? "0" + h3 : h3;
                totalExidle += ":" + (m3 < 10 ? "0" + m3 : m3);
                totalExidle += ":" + (s3 < 10 ? "0" + s3 : s3);

                return (
                  <>
                    {records.length != 0 && (
                      <Table.Summary.Row>
                        <Table.Summary.Cell>
                          {/* <Text style={{ fontWeight: "bold" }}>Total</Text> */}
                          <Text style={{ fontWeight: "bold" }}>
                            {this.context.translate("_TOTAL_")}
                          </Text>
                        </Table.Summary.Cell>

                        {/* <Table.Summary.Cell />
                      <Table.Summary.Cell /> */}

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>
                            {sumofDriver}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>{sumofKm}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>{result}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>
                            {totalTrips}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>
                            {totalIdle}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>
                            {totalExidle}
                          </Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>{sum}</Text>
                        </Table.Summary.Cell>

                        <Table.Summary.Cell>
                          <Text style={{ fontWeight: "bold" }}>
                            {sumofRoadSpeed}
                          </Text>
                        </Table.Summary.Cell>
                      </Table.Summary.Row>
                    )}
                  </>
                );
              }}
            />
          )}
        </div>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
VehiclePerformanceList.contextType = StoreContext;
export default observer(withRouter(VehiclePerformanceList));
