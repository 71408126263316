import React, { Component } from "react";

import {
  Form,
  Space,
  Button,
  Table,
  Input,
  message,
  Checkbox,
  Upload,
  Row,
  Col,
  Tooltip,
  Spin,
  Select
} from "antd";
import { DeleteFilled, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import config from "../config";
import withRouter from "../withRouter";
import { Link } from "react-router-dom";
import VehicleFuelCalibrationStore from "../store/VehicleFuelCalibrationStore";
import DeleteConfirm from "./DeleteConfirm";
const { Column } = Table;

class VehicleFuelCalibrationAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      editFormShow: true,
      Id: -1,
      tableData: [],
      fuelCalibrationId: "",
      listTblCount:1,
      loadingSpin: false,
      volumePerUnit:0,
      minVoltage:0,
      maxVoltage:0,
      fuelTankCapacity:0,
      showVolumePerUnit:false
    };

    this.vehicleFuelStore = new VehicleFuelCalibrationStore();
    this.onDeleteGridData = this.onDeleteGridData.bind(this);
  }

  async componentDidMount() {
    
    await this.setState({ loadList: 1 });
    const Id = this.props.fuelCalibrationId;

    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }
  }

  async filldata(Id) {
    await this.setState({ loadingSpin: true })
    const getFuelDetails = await this.vehicleFuelStore.getFuelDetails(Id);
    console.log("getFuelDetails", getFuelDetails);

    const getGridData = await this.vehicleFuelStore.getGridDetails(Id);
    console.log("getGridData", getGridData);
    this.setState({ tableData: getGridData });

    this.formRef.current.setFieldsValue({
      Id: getFuelDetails.Id,
      fuelCalibrationName: getFuelDetails.fuelCalibrationName,
      fuelTankCapacity: getFuelDetails.fuelTankCapacity,
      maxVoltage: getFuelDetails.maxVoltage,
      minVoltage: getFuelDetails.minVoltage,
      sensorType: getFuelDetails.sensorType,
      volumePerUnit: getFuelDetails.volumePerUnit
    });

    this.sensorTypeChange(getFuelDetails.sensorType)
    await this.setState({ loadingSpin: false })
    await this.setState({ minVoltage: getFuelDetails.minVoltage })
    await this.setState({ maxVoltage: getFuelDetails.maxVoltage })
    await this.setState({ fuelTankCapacity: getFuelDetails.fuelTankCapacity })
    this.setState({listTblCount:Number(getGridData[getGridData.length-1].rId)+1})
    
    // this.formRef.current.setFieldsValue({
    //   Id:getGridData.Id,
    //   fuel:getGridData.fuel,
    //   voltage:getGridData.voltage,
    //   fuelCalibrationId:getGridData.fuelCalibrationId,
    //   rId:getGridData.rId
    //    });
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onFuelCalibrationDetailsSubmit = async (values) => {
    console.log(values, "value");
    this.setState({ loading: true });
    console.log(this.state.tableData[0]);
    const GridDetails = this.state.tableData;

    const data = {
      ...values,
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      Id: this.state.Id,
      GridDetails: GridDetails,
     // sensorType : values.sensorType.value
    };
    const resp = await this.vehicleFuelStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehiclefuelcalibrationlist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
   
    this.setState({ loading: false });
    // console.log(resp,"data")
  };

  handleAddRecord = () => {
    const { tableData } = this.state;

    // fuel: "",
    // voltage: "",
    console.log(this.state.tableData, "data");
   this.setState({ listTblCount:this.state.listTblCount+1})
    this.setState({
      tableData: [
        ...tableData,
        {
          rId: this.state.listTblCount,
          fuel: "",
          voltage: "",
          Id: 0,
          fuelCalibrationName: "",
          fuelTankCapacity: "",
          maxVoltage: "",
          minVoltage: "",
        },
      ],
    });
  };

  validateFieldsValue = (value, field) => {
    const value1 = value.replace(/[^0-9.,]+/g, "");
    console.log(value1);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
    if(field=='minVoltage'){
      this.setState({minVoltage : value1})
    }
    if(field=='maxVoltage'){
      this.setState({maxVoltage : value1})
    }
    if(field=='fuelTankCapacity'){
      this.setState({fuelTankCapacity : value1})
    }

    
   
  };

   calculateVolumePerUnit = async() =>{
      if(this.state.minVoltage!='' && this.state.maxVoltage!='' && this.state.fuelTankCapacity!=''){
        const minVoltage = parseFloat(this.state.minVoltage);
        const maxVoltage = parseFloat(this.state.maxVoltage);
        const fuelTankCapacity = parseFloat(this.state.fuelTankCapacity);
          console.log(minVoltage)
          console.log(maxVoltage)
          console.log(fuelTankCapacity)
       

        const volumePerUnit = ((fuelTankCapacity) / (maxVoltage - minVoltage)).toFixed(2);
        await this.setState({volumePerUnit:volumePerUnit})
        this.formRef.current.setFieldsValue({volumePerUnit:volumePerUnit})
        console.log(this.statevolumePerUnit)
      }
  }
  handleInputChange = (e, index, fieldName) => {
    console.log(e, index, fieldName);
    const newData = [...this.state.tableData];
    console.log(newData);
    newData[index][fieldName] = e.target.value;

    this.setState({ tableData: newData });
  };

  onSelectSchool = (value) => {
    if (value === "SchoolBus") {
      this.setState({ school: true });
    } else {
      this.setState({ school: false });
    }
  };
  
  sensorTypeChange=(value)=>{
    if(value==='BLE'){
      this.setState({showVolumePerUnit:true});
    }else{
      this.setState({showVolumePerUnit:false});
    }
    //console.log(value)
  }

  async onDeleteGridData(record) {
    console.log(record.Id, record.fuelCalibrationId, "record");

    const response = await this.vehicleFuelStore.deleteGridData({
      Id: record.Id,
      fuelCalibrationId: record.fuelCalibrationId,
    });
    //  console.log(response.data.success)
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      // this.handleSearch();
    }
    // else {
    //   message.error(response.data.message);
    // }
  }

  onDeleteRow = (index) => {
    console.log(index);
    const filterData = this.state.tableData.filter((val, ind) => ind !== index);
    console.log(filterData);
    this.setState({ tableData: filterData });
  };

  closeModal=()=>{
    this.props.closeModal()
  }

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
        <Spin spinning={this.state.loadingSpin} fullscreen >
          <Form
            style={{ maxWidth: "100%", padding: 0 }}
            ref={this.formRef}
            layout="vertical"
            onFinish={this.onFuelCalibrationDetailsSubmit}
            // {...this.layout}
            name="nest-message"
            className="formClass" //change
          >
            {/* <h1>Fuel Calibration</h1> */}

            {/* <div className="headingTitle">Fuel Calibration</div> */}
            <Row className="overflowFormInputs">
              {/* css class */}
              <Col span={12} className="formColumnClass">

                <Form.Item
                          name="sensorType"
                          label={this.context.translate("_SENSOR_TYPE_")}
                          rules={[{ required: true }]}
                        >
                          <Select
                             onChange={(value) =>
                               this.sensorTypeChange(value)
                             }
                            //onSelect={this.handleVTypeChange}
                            
                            options={[
                              {
                                value: "BLE",
                                // label: "Truck",
                                label: this.context.translate("_BLE_"),
                               
                              },
                              {
                                value: "Analog",
                                // label: "Car",
                                label: this.context.translate("_analog_"),
                               
                              }
                            ]}
                          />
                        </Form.Item>  
                
                <Form.Item
                  name="fuelCalibrationName"
                  label={this.context.translate("_FUEL_CALIBRATION_NAME_")}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
                             
                <Form.Item
                  name="fuelTankCapacity"
                  label={this.context.translate("_FUEL_TANK_CAPACITY_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>

                      this.validateFieldsValue(
                        e.target.value,
                        "fuelTankCapacity"
                      )
                    }
                    onKeyUp={this.calculateVolumePerUnit}
                  />
                </Form.Item>
               
              </Col>

              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="maxVoltage"
                  label={this.context.translate("_MAX_VOLTAGE_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.validateFieldsValue(e.target.value, "maxVoltage")
                    }
                    onKeyUp={this.calculateVolumePerUnit}
                  />
                </Form.Item>

                <Form.Item
                  name="minVoltage"
                  label={this.context.translate("_MIN_VOLTAGE_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      this.validateFieldsValue(e.target.value, "minVoltage")
                    }
                    onKeyUp={this.calculateVolumePerUnit}
            
                  />
                </Form.Item>
                {this.state.showVolumePerUnit && (   
                <Form.Item
                  name="volumePerUnit"
                  label={this.context.translate("_VOLUME_PER_UNIT_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    onChange={(e) =>
                      
                      this.validateFieldsValue(
                        e.target.value,
                        "volumePerUnit"
                      )
                    }
                    value={this.state.volumePerUnit}
                  />
                </Form.Item>
                )}
              </Col>
              <Col span={24}>
                <Row>
                  <Button
                    type="primary"
                    onClick={this.handleAddRecord}
                    style={{ margin: "20px 0px 20px 0px" }}
                  >
                    {/* Add Record */}
                    {this.context.translate("_ADD_RECORD_")}
                  </Button>
                </Row>

                <Table dataSource={this.state.tableData} bordered>
                  <Column
                    // title="Id"
                    title={this.context.translate("_Id_")}
                    dataIndex="rId"
                    key="Id"
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) =>
                          this.handleInputChange(e, index, "rId")
                        }
                      />
                    )}
                  />
                  <Column
                    // title="Fuel"
                    title={this.context.translate("_FUEL_")}
                    dataIndex="fuel"
                    key="fuel"
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) =>
                          this.handleInputChange(e, index, "fuel")
                        }
                      />
                    )}
                  />

                  <Column
                    // title="Voltage"
                    title={this.context.translate("_VOLTAGE_")}
                    dataIndex="voltage"
                    key="voltage"
                    render={(text, record, index) => (
                      <Input
                        value={text}
                        onChange={(e) =>
                          this.handleInputChange(e, index, "voltage")
                        }
                      />
                    )}
                  />

                  <Column
                    // title="Action"
                    title={this.context.translate("_ACTION_")}
                    dataIndex=""
                    key=""
                    render={(text, record, index) => (
                      <div>
                        <Space>
                          <Link
                            onClick={async () => {
                              await this.onDeleteRow(index);

                              // console.log(record, text);
                              await this.onDeleteGridData(record, "record");
                            }}
                          >
                            <Tooltip
                              // title="Delete"
                              title={this.context.translate("_FLEET_DASHBORD_RULETRIGGER_DELETE_")}
                              color={config.tooltipColorDelete}
                            >
                              <DeleteFilled
                                style={{ color: config.deleteColor }}
                              />
                            </Tooltip>
                          </Link>
                        </Space>
                      </div>
                    )}
                  />
                </Table>
              </Col>
            </Row>
            <div className="formBtnGroup">
              {/* <Link to={"/vehiclefuelcalibrationlist"}>
                  <Button ghost type="primary">
                    Back
                  </Button>
                </Link> */}
              <Button
                className="formCancelButton"
                type="primary"
                onClick={(e) => {
                  this.closeModal()
                  // this.formRef.current?.resetFields();
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLOSE_BTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

VehicleFuelCalibrationAdd.contextType = StoreContext;
export default observer(withRouter(VehicleFuelCalibrationAdd));
