import {Tooltip,Space, Dropdown,Form,Input, Select,Row,Col,DatePicker,Switch,Checkbox,Button} from 'antd';
import {
  DownOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useContext } from 'react';
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
/*"displayDomains" : ["awtltrack"],*/

  const JobManagementListFields = (props) => {
        console.log('props',props);
       
    // const shouldShow = baseConfig.displayDomains.includes(config.project);
    const shouldShow = shouldShowComponent(config.project, "JobManagementListFields");

     const storeContext = useContext(StoreContext); // Use the context here


    if (!shouldShow) {
      return null; // Do not render if the condition is not met
    }

    return (
    <>
           <Link to="/deliveryDashboard">
                  <Button
                    onClick={async () => props.handleDashboardClick()}
                  >
                    {storeContext.translate("_DASHBOARD_")}
                  </Button>
                </Link> 

        </>
      
    );
  };


  JobManagementListFields.contextType = StoreContext;
export default observer(withRouter(JobManagementListFields));
