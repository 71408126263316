import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import VehicleStore from "../../../store/VehicleStore";

const VehicleDetailsTabFormFields = (props) => {
  console.log("props", props.isadmin);
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleDetailsTabFormFields"
  );
  //   console.log("shouldShow**", shouldShow);
  const storeContext = useContext(StoreContext); // Use the context here

  const vehicleStore = new VehicleStore();
  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      {props.isadmin.isadmin !== 1 && (
        <>
          {" "}
          <Form.Item
            name="DigInpt4"
            // label=" DigiInput2:"
            label={storeContext.translate(
              "_FLEET_VEHICLE_VEHICLEDETAIL_DIGITAL4_"
            )}
          >
            <Select
              options={[
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_DOOR_"
                  ),
                  value: "Door",
                },
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_SEATBELT_"
                  ),
                  value: "Seatbelt",
                },
                {
                  // label: "ACC",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_ACC_"),
                  value: "ACC",
                },
                {
                  // label: "Panic",
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_PANIC_"
                  ),
                  value: "Panic",
                },
                {
                  // label: "AC ",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_AC_"),
                  value: "AC",
                },
                {
                  // label: "Passenger Seat Belt",
                  label: storeContext.translate("_SEAT_SENSORS_"),
                  value: "Seatsensor",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="AngInpt1"
            // label=" DigiInput1:"
            label={storeContext.translate(
              "_FLEET_DASHBORD_SETTING_FUELVOLUME1_"
            )}
          >
            <Select
              options={[
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_DOOR_"
                  ),
                  value: "Door",
                },
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_SEATBELT_"
                  ),
                  value: "Seatbelt",
                },
                {
                  // label: "ACC",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_ACC_"),
                  value: "ACC",
                },
                {
                  // label: "Panic",
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_PANIC_"
                  ),
                  value: "Panic",
                },
                {
                  // label: "AC ",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_AC_"),
                  value: "AC",
                },
                {
                  // label: "Passenger Seat Belt",
                  label: storeContext.translate("_SEAT_SENSORS_"),
                  value: "Seatsensor",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="AngInpt2"
            // label=" DigiInput2:"
            label={storeContext.translate(
              "_FLEET_DASHBORD_SETTING_FUELVOLUME2_"
            )}
          >
            <Select
              options={[
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_DOOR_"
                  ),
                  value: "Door",
                },
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_SEATBELT_"
                  ),
                  value: "Seatbelt",
                },
                {
                  // label: "ACC",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_ACC_"),
                  value: "ACC",
                },
                {
                  // label: "Panic",
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_PANIC_"
                  ),
                  value: "Panic",
                },
                {
                  // label: "AC ",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_AC_"),
                  value: "AC",
                },
                {
                  // label: "Passenger Seat Belt",
                  label: storeContext.translate("_SEAT_SENSORS_"),
                  value: "Seatsensor",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="AngInpt3"
            // label=" DigiInput2:"
            label={storeContext.translate(
              "_FLEET_DASHBORD_SETTING_FUELVOLUM3_"
            )}
          >
            <Select
              options={[
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_DOOR_"
                  ),
                  value: "Door",
                },
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_SEATBELT_"
                  ),
                  value: "Seatbelt",
                },
                {
                  // label: "ACC",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_ACC_"),
                  value: "ACC",
                },
                {
                  // label: "Panic",
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_PANIC_"
                  ),
                  value: "Panic",
                },
                {
                  // label: "AC ",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_AC_"),
                  value: "AC",
                },
                {
                  // label: "Passenger Seat Belt",
                  label: storeContext.translate("_SEAT_SENSORS_"),
                  value: "Seatsensor",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="AngInpt4"
            // label=" DigiInput2:"
            label={storeContext.translate(
              "_FLEET_DASHBORD_SETTING_FUELVOLUME4_"
            )}
          >
            <Select
              options={[
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_DOOR_"
                  ),
                  value: "Door",
                },
                {
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_SEATBELT_"
                  ),
                  value: "Seatbelt",
                },
                {
                  // label: "ACC",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_ACC_"),
                  value: "ACC",
                },
                {
                  // label: "Panic",
                  label: storeContext.translate(
                    "_FLEET_DASHBORD_SETTING_PANIC_"
                  ),
                  value: "Panic",
                },
                {
                  // label: "AC ",
                  label: storeContext.translate("_FLEET_DASHBORD_SETTING_AC_"),
                  value: "AC",
                },
                {
                  // label: "Passenger Seat Belt",
                  label: storeContext.translate("_SEAT_SENSORS_"),
                  value: "Seatsensor",
                },
              ]}
            />
          </Form.Item>
        </>
      )}

      <Form.Item
        name="permitExpiryDate"
        label={storeContext.translate("_PERMIT_EXPIRY_DATE_")}
      >
        <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        name="nationalPermitExpiryDate"
        label={storeContext.translate("_NATIONAL_PERMIT_EXPIRY_DATE_")}
        rules={[{ type: Date }]}
      >
        <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
      </Form.Item>

      <Form.Item
        name="pollutionExpiryDate"
        label={storeContext.translate("_POLLUTION_EXPIRY_DATE_")}
        rules={[{ type: Date }]}
      >
        <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
      </Form.Item>
      {!props.vehicleIdForEdit && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Form.Item
            name="rule"
            label={storeContext.translate("_RULE_")}
            style={{ width: "80%" }}
          >
            <Select
              mode="multiple"
              className="search-field"
              maxTagCount={"responsive"}
              options={vehicleStore?.vehicleRules}
              fieldNames={{ label: "name", value: "ruleId" }}
            />
          </Form.Item>

          <Form.Item label=" ">
            <Checkbox
              checked={props.selectRules}
              onChange={(e) => {
                props.handleSelectAllRules(e);
              }}
            >
              {storeContext.translate("_ALL_")}{" "}
            </Checkbox>
          </Form.Item>
        </div>
      )}

      <Form.Item
        name="speedmileage"
        label={storeContext.translate("_SPEEDMILEAGE_")}
        initialValue={"GPS"}
        rules={[{ required: true }]}
      >
        <Select
          options={[
            { label: "GPS", value: "GPS" },
            { label: "Pulse", value: "Pulse" },
          ]}
          onSelect={(val) => {
            // console.log(val)
            props.showPulseFields(val);
          }}
        />
      </Form.Item>
    </>
  );
};

VehicleDetailsTabFormFields.contextType = StoreContext;
export default observer(withRouter(VehicleDetailsTabFormFields));
