import {
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  Switch,
  Checkbox,
  Button,
} from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";

const AccountFormFields = (props) => {
  console.log("props", props);
  const shouldShow = shouldShowComponent(config.project, "AccountFormFields");

  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Form.Item
        name="registrationNoArabic"
        // label="Commercial Record Number"
        label={storeContext.translate("_COMMERCIAL_RECORD_NUMBER_ARABIC_")}

        // rules={[{ required: true }]}
      >
        <Input disabled />
      </Form.Item>

      <Form.Item
        name="identityNo"
        //label="Identity Number"
        label={storeContext.translate("_IDENTITY_NUMBER_")}
        // rules={[{ required: true }]}
        rules={[
          {
            pattern: /^(\d{10})$/,
            required: true,
            message: storeContext.translate(
              "_Identity_Number_should_be_10_Digit_Number_"
            ),
          },
        ]}
      >
        <Input
          onChange={(e) => {
            props.validateNumber(e.target.value, "identityNo");
          }}
        />
      </Form.Item>

      <Row className="mapcheck0">
        <Col span={11}>
          <Form.Item
            name="commercialRecordIssueDate"
            // label="Commercial Record Issue Date"
            label={storeContext.translate("_COMMERCIAL_RECORD_ISSUE_DATE2_")}
            rules={[{ type: Date }]}
          >
            <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
          </Form.Item>
        </Col>

        <Col span={11}>
          <Form.Item
            className=""
            name="offlineAlerts"
            // label="Offline Alerts"
            label={storeContext.translate("_OFFLINE_ALERT_")}
            rules={[{ type: Checkbox }]}
          >
            <Switch
              className="form-offlineAlerts"
              checked={props.isSwitchOn}
              // checkedChildren="Yes"
              // unCheckedChildren="No"
              checkedChildren={storeContext.translate(
                "_FLEET_RULEDETAIL_ACTIVEDDL_YES_"
              )}
              unCheckedChildren={storeContext.translate(
                "_FLEET_RULEDETAIL_ACTIVEDDL_NO_"
              )}
              onChange={async (val) => {
                props.setSwitch(val);
                console.log(val ? 1 : 0);
                props.formRef.current.setFieldsValue({
                  offlineAlerts: val ? 1 : 0,
                });
                console.log(val);
              }}
              // defaultChecked
            />
            {/* <Radio.Group>
                      <Radio value={1}>Yes</Radio>
                      <Radio value={2}>No</Radio>
                    </Radio.Group> */}
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};

AccountFormFields.contextType = StoreContext;
export default observer(withRouter(AccountFormFields));
