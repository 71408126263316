import React, { Component } from "react";
import { decode as base64_decode } from "base-64";
import { Button, Form, Input, Row, Select, message, Modal, Col } from "antd";

import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";

import GPRSCommandStore from "../store/GPRSCommandStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import withRouter from "../withRouter";
import GprsFormFields from "./Plugins/AddGPRS/GprsFormFields";
import config from "../config.js";
class GprsCommand extends Component {
  formRef = React.createRef();
  constructor(prop) {
    super(prop);
    this.state = {
      commandType: 1,

      vehicleId: "",
      selectAllVehicles: false,
      showModal: false,
      smsInput: "",
      disable: false,
      APNCommand: false,
      otherCommand: false,
      ipportcmd: false,
      intervalcmd: false,
      // commandType: "",
      apnCmdValue: "",
      ip: "",
      port: "",
      interval: "",
    };
    this.gprsStore = new GPRSCommandStore();
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });

    const data = await this.gprsStore.getVehicles({ companyId: comp.value });

    await this.handleSearch();
  }
  async componentDidUpdate() {
    if (Number(this.props.getUserData().isadmin) === 1) {
      this.props.navigate("/login");
    }
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setUserAddStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      const data = await this.gprsStore.getVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });
      // await this.setState({userId:this.props.getUserData().userId})
      await this.setState({ isadmin: this.props.getUserData().isadmin });
    }
  }

  async handleSearch() {
    await this.gprsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  grpsCommandSubmit = async (fieldsValue) => {
    this.setState({ loading: true });
    console.log(fieldsValue);

    var cmdText = fieldsValue.cmdText;
    var vehicleId = fieldsValue.vehicleId;
    var full_imei = this.gprsStore?.getVehiclesUsingCompanyId[0].full_imei;
    console.log(full_imei);
    const data = {
      //   companyId:this.state.companyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      vehicleId,
      cmdText,
      full_imei,
    };
    console.log(data, "rwerwertweter");
    const response = await this.gprsStore.addData(data);
    if (response?.success === "S") {
      await message.success(response.message);
    } else {
      message.error(response.message);
    }
    this.setState({ loading: false });
  };

  // changeCommandFields = (val) => {
  //   if (val == 1) {
  //     this.setState({ APNCommand: true });
  //     this.setState({ otherCommand: false });
  //     this.setState({ ipportcmd: false });
  //     this.setState({ intervalcmd: false });
  //   } else if (val == 5) {
  //     this.setState({ otherCommand: true });
  //     this.setState({ ipportcmd: false });
  //     this.setState({ intervalcmd: false });
  //     this.setState({ APNCommand: false });
  //   } else if (val == 2) {
  //     this.setState({ ipportcmd: true });
  //     this.setState({ APNCommand: false });
  //     this.setState({ otherCommand: false });
  //     this.setState({ intervalcmd: false });
  //   } else if (val == 3 || val == 4) {
  //     this.setState({ intervalcmd: true });
  //     this.setState({ APNCommand: false });
  //     this.setState({ otherCommand: false });
  //     this.setState({ ipportcmd: false });
  //   } else {
  //     this.setState({ APNCommand: false });
  //     this.setState({ otherCommand: false });
  //     this.setState({ ipportcmd: false });
  //     this.setState({ intervalcmd: false });
  //   }
  // };

  changeCommandFields = (val) => {
    console.log(val);
    this.setState({ commandType: val });
    switch (val) {
      case 1:
        this.setState({
          APNCommand: true,
          otherCommand: false,
          ipportcmd: false,
          intervalcmd: false,
        });
        break;
      case 5:
        this.setState({
          APNCommand: false,
          otherCommand: true,
          ipportcmd: false,
          intervalcmd: false,
        });
        break;
      case 2:
        this.setState({
          APNCommand: false,
          otherCommand: false,
          ipportcmd: true,
          intervalcmd: false,
        });
        break;
      case 3:
      case 4:
        this.setState({
          APNCommand: false,
          otherCommand: false,
          ipportcmd: false,
          intervalcmd: true,
        });
        break;
      default:
        this.setState({
          APNCommand: false,
          otherCommand: false,
          ipportcmd: false,
          intervalcmd: false,
        });
        break;
    }
  };

  generateCmd = async () => {
    const Imei = await this.gprsStore?.getVehiclesUsingCompanyId.filter(
      (val) => val.vehicleId === this.state.vehicleId
    );
    // console.log(Imei)
    // console.log(Imei,Imei[0].full_imei)
    var imei = Imei[0]?.full_imei;
    var command = "";

    if (this.state.commandType === 1) {
      command += "(0" + imei + "AP04" + this.state.apnCmdValue + ")";

      this.formRef.current.setFieldsValue({ cmdText: command });
    }

    if (this.state.commandType === 2) {
      if (this.state.ip && this.state.port) {
        var IPAddress = this.state.ip.split(".");

        var formatedIP = "";

        for (let i = 0; i < 4; i++) {
          var tempvar;

          if (IPAddress[i]?.length == 1) {
            tempvar = "00" + IPAddress[i];
          } else if (IPAddress[i]?.length == 2) {
            tempvar = "0" + IPAddress[i];
          } else {
            tempvar = IPAddress[i];
          }
          formatedIP += tempvar;
        }

        formatedIP = "(0" + imei + "AP03" + formatedIP + this.state.port + ")";
        // formatedIP = `(0${imei}AP03${formatedIP}${this.state.port})`;
        this.formRef.current.setFieldsValue({ cmdText: formatedIP });
      }
    }

    if (this.state.commandType === 3 || this.state.commandType === 4) {
      var interval = parseInt(this.state.interval);
      var IntervalHEX = interval.toString(16);

      let intervalLength = IntervalHEX.length;
      if (intervalLength == 1) {
        IntervalHEX = "000" + IntervalHEX;
      } else if (intervalLength == 2) {
        IntervalHEX = "00" + IntervalHEX;
      } else if (intervalLength == 3) {
        IntervalHEX = "0" + IntervalHEX;
      }
      if (this.state.commandType === 3) {
        command += "(0" + imei + "AR05" + IntervalHEX + ")";
      } else {
        command += "(0" + imei + "AR06" + IntervalHEX + ")";
      }
      this.formRef.current.setFieldsValue({ cmdText: command });
    }

    if (this.state.commandType === 5) {
      const deviceName = await this.gprsStore?.getVehiclesUsingCompanyId.filter(
        (val) => val.vehicleId === this.state.vehicleId
      );

      const data = await this.gprsStore?.getGPRSCommand({
        smsInput: this.state.smsInput,

        deviceName: deviceName[0].deviceName,
      });

      // if (data === "") {
      //   Modal.warning({
      //     // title: "Alert",
      //     title: this.context.translate("_FLEET_ALERT_"),
      //     // content: "Command Not Defined",
      //     content: this.context.translate("_COMMAND_NOT_DEFINED_"),
      //     // okText: "OK",
      //     okText: this.context.translate(
      //       "_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_"
      //     ),

      //   });
      // }
      this.formRef.current.setFieldsValue({ cmdText: data });
    }
  };

  handleInputChange = (val, field) => {
    this.setState({ [field]: val });
  };

  selectVehicles = (val) => {
    this.setState({ vehicleId: val });
  };

  getDeviceName = async (vehicleId) => {
    const deviceName = await this.gprsStore?.getVehiclesUsingCompanyId.filter(
      (val) => val.vehicleId === vehicleId
    );
    // var full_imei = val[0].full_imei;

    this.setState({ deviceName: deviceName[0].deviceName });
    const data = await this.gprsStore?.getGPRSCommand({
      commandType: this.state.commandType,
      smsInput: this.state.smsInput,
      // deviceName: this.state.deviceName,
      deviceName: deviceName[0].deviceName,
      //   full_imei,
    });

    if (data === "") {
      Modal.warning({
        // title: "Alert",
        title: this.context.translate("_FLEET_ALERT_"),
        // content: "Command Not Defined",
        content: this.context.translate("_COMMAND_NOT_DEFINED_"),
        // okText: "OK",
        okText: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_COMMAND_OKBTN_"
        ),
        onOk: () => {
          this.setState({ showModal: false });
          // Handle the Ok button click event if needed
          console.log("Ok button clicked");
        },
      });
    }

    this.formRef.current.setFieldsValue({ cmdText: data });
  };

  handleCommandType = async (value) => {
    this.setState({ disable: false });
    console.log(this.state.deviceName);
    this.setState({ commandType: value });
    const data = await this.gprsStore?.getGPRSCommand({
      commandType: value,
      smsInput: this.state.smsInput,
      deviceName: this.state.deviceName,
      //   full_imei,
    });
    this.formRef.current.setFieldsValue({ cmdText: data });
  };

  commandchange = async () => {
    this.setState({ disable: true });
    console.log(this.state.commandType);
    const data = await this.gprsStore?.getGPRSCommand({
      commandType: this.state.commandType,
      smsInput: this.state.smsInput,
      deviceName: this.state.deviceName,
      //   full_imei,
    });
    this.formRef.current.setFieldsValue({ cmdText: data });
  };

  selectAllVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.gprsStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      this.formRef.current.setFieldsValue({ vehicleId: allVehicles });
      this.setState({ loadList: 1 });
    } else {
      this.formRef.current.setFieldsValue({ vehicleId: [] });
    }
    this.setState({ loadList: 1 });
  };

  handleCheckBoxChange = (checked) => {
    this.setState({ selectAllVehicles: checked });
  };

  render() {
    return (
      <>
        {/* <div className="changePassHeadline">GPRS Command</div> */}
        <div className="changePassHeadline">
          {this.context.translate("_GPRS_COMMAND_")}
        </div>
        <div className="changePassLayout">
          <Form
            ref={this.formRef}
            layout="vertical"
            name="nest-messages"
            onFinish={this.grpsCommandSubmit}
            style={{ maxWidth: "100%", padding: 0 }}
            className="changePassForm"
            // onValuesChange={this.handleFormValuesChange}
          >
            {/* <div className="headingTitle">GPRS Command</div> */}
            <Row style={{ padding: "0px 20px" }}>
              {/* <Col span={11}> */}
              {(this.props.getUserData().isadmin === -1 ||
                this.props.getUserData().isadmin === 3) && (
                <Form.Item
                  name="companyId"
                  label={this.context.translate("_ACCOUNT_")}
                  rules={[{ required: true }]}
                  style={{ width: "100%" }}
                >
                  <CompanyAutocomplete
                    style={{ background: "transparent" }}
                    //  placeholder="Select Account"

                    onChange={(value) => {
                      this.handleChange(value);
                      this.formRef.current.setFieldsValue({
                        vehicleId: undefined,
                      });
                    }}

                    // style={{ width: "50%" }}
                  />
                </Form.Item>
              )}
            </Row>

            {config.project === "drive7" && (
              <>
                <Row
                  style={{
                    padding: "0px 20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Item
                    className="search-field"
                    name="vehicleId"
                    // label="Asset Name"
                    label={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Select
                      // style={{ width: "130px" }}
                      // placeholder="Select Asset"
                      placeholder={this.context.translate(
                        "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                      )}
                      value={this.state.vehicleId}
                      onChange={(val) => {
                        // setVehicleId(val);
                        this.selectVehicles(val);
                        this.formRef.current.setFieldsValue({
                          smsInput: undefined,
                          cmdText: undefined,
                        });
                      }}
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.gprsStore?.getVehiclesUsingCompanyId}
                    />
                  </Form.Item>
                </Row>

                <Row
                  style={{
                    padding: "0px 20px",
                    justifyContent: "space-between",
                  }}
                >
                  <Form.Item
                    className="search-field"
                    name="vehicleId"
                    // label="Asset Name"
                    label={this.context.translate(
                      "_COMMAND_"
                    )}
                    rules={[{ required: true }]}
                    style={{ width: "100%" }}
                  >
                    <Input/>
                    </Form.Item>
                </Row>
              </>
            )}

            <GprsFormFields
              changeCommandFields={this.changeCommandFields}
              generateCmd={this.generateCmd}
              handleInputChange={this.handleInputChange}
              selectVehicles={this.selectVehicles}
              getDeviceName={this.getDeviceName}
              handleCommandType={this.handleCommandType}
              commandchange={this.commandchange}
              selectAllVehicles={this.selectAllVehicles}
              handleCheckBoxChange={this.handleCheckBoxChange}
              vehicleId={this.state.vehicleId}
              formRef={this.formRef}
              gprsStore={this.gprsStore?.getVehiclesUsingCompanyId}
              This={this.state}
            />

            <div className="formBtnGroup">
              <Link to="/commandsentreportlist">
                <Button
                  type="primary"
                  className="formSaveButton"
                  style={{ marginBottom: "10px" }}
                >
                  {/* Report */}
                  {this.context.translate("_REPORT_")}
                </Button>
              </Link>

              <Button
                className="formCancelButton"
                style={{ marginBottom: "10px" }}
                type="primary"
                onClick={(e) => {
                  this.formRef.current?.resetFields();
                  this.setState({ selectAllVehicles: false });
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_DASHBORD_SHOWPOI_CANCELBTN_")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="formSaveButton"
                style={{ marginBottom: "10px" }}
              >
                {/* Send */}
                {this.context.translate(
                  "_FLEET_DASHBORD_VEHICLE_COMMAND_SENDBTN_"
                )}
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
GprsCommand.contextType = StoreContext;
export default observer(withRouter(GprsCommand));
