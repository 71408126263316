import React, { Component } from "react";
import { Button, Checkbox, Form, message, Select } from "antd";

import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";
import AssignGeofenceToComapnyStore from "../store/AssignGeofenceToCompanyStore";

class AssignGeofenceToCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      allCompany: false,
      loadList: 0,
      companyId: [],
      selectAllGeofence:false
    };

    this.assignGeofenceToCompanyStore = new AssignGeofenceToComapnyStore(this);
    this.formRef = React.createRef();
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
    }
    await this.assignGeofenceToCompanyStore.getGeofenceData();

    await this.setState({ loadData: 1 });
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
  }

  onAssignGeofenceToCompany = async (fieldsValue) => {
    const data = { ...fieldsValue, companyId: this.state.companyId };

    const response =
      await this.assignGeofenceToCompanyStore.onAssignGeofenceToCompany(data);

    if (response?.data.success === "S") {
      message.success("Geofence Save Sucessfully");
    } else {
      message.error(response.data.error);
    }
  };

  handleSelectAllGeofence = async (e) => {
    if (this.state.selectAllGeofence) {
      const allGeofence = this.assignGeofenceToCompanyStore?.geofencesList?.map(
        (val) => {
          return val.zoneId;
        }
      );
      this.formRef.current.setFieldsValue({ zoneId: allGeofence });
      this.setState({ loadList: 1 });
    } else {
      this.formRef.current.setFieldsValue({ zoneId: [] });
    }
    this.setState({ loadList: 1 });
  };

  render() {
    return (
      <>
        <div className="changePassHeadline">
          {this.context.translate("_ASSIGN_GEOFENCE_TO_COMPANY_")}
        </div>
        <div className="changePassLayout">
          <Form
            ref={this.formRef}
            layout="vertical"
            name="nest-messages"
            onFinish={this.onAssignGeofenceToCompany}
            className="changePassForm"
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              {(this.props.getUserData().isadmin === -1 ||
                this.props.getUserData().isadmin === 3) && (
                <>
                  <Form.Item
                    name="companyId"
                    rules={[{ required: true }]}
                    label={this.context.translate("_ACCOUNT_")}
                    style={{ width: "100%" }}
                  >
                    <CompanyAutocomplete
                      onChange={(value) => {
                        this.handleChange(value);
                      }}
                    />
                  </Form.Item>
                </>
              )}
            </div>

            <div style={{ display: "flex", justifyContent: "space-between",alignItems:'center'}}>
              <Form.Item
                className="search-field"
                name="zoneId"
                rules={[{ required: true }]}
                label={this.context.translate("_GEOFENCE_NAME_")}
                style={{ width: "80%" }}
              >
                <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  fieldNames={{ label: "address", value: "zoneId" }}
                  optionFilterProp="children"
                  options={this.assignGeofenceToCompanyStore?.geofencesList}
                />
              </Form.Item>
              <Form.Item label='' style={{ marginBottom: 0 }}>
              <Checkbox
                  checked={this.state.selectAllGeofence}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllGeofence: e.target.checked,
                    });
                    // console.log(e.target.checked);
                    await this.handleSelectAllGeofence();
                  }}
                >
                {this.context.translate("_ALL_")}
                </Checkbox>
              </Form.Item>
             

            </div>

            <div className="formBtnGroup">
              <Button
                className="formCancelButton"
                onClick={(e) => {
                  this.formRef.current?.resetFields();
                  this.setState({ allCompany: false });
                }}
              >
                {this.context.translate("_FLEET_CLEAR_BTN_")}
              </Button>
              <Button
                className="formSaveButton"
                type="primary"
                htmlType="submit"
              >
                {this.context.translate("_FLEET_DASHBORD_SETTING_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
AssignGeofenceToCompany.contextType = StoreContext;
export default observer(withRouter(AssignGeofenceToCompany));
