import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Button, Tooltip } from "antd";
import { UploadOutlined } from "@ant-design/icons";

const MaintenanceListFields = (props) => {
  const shouldShow = shouldShowComponent(
    config.project,
    "MaintenanceListFields"
  );

  // console.log(shouldShow,config.project)
  const storeContext = useContext(StoreContext);

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <Tooltip
        // title="Upload"
        title={storeContext.translate("_UPLOAD_")}
        color={config.tooltipColorEdit}
      >
        <Button
          type={config.uploadBoxType}
          onClick={() => props.handleUploadClick()}
        >
          <UploadOutlined />
        </Button>
      </Tooltip>
    </>
  );
};

MaintenanceListFields.contextType = StoreContext;
export default observer(withRouter(MaintenanceListFields));

// export default observer(withRouter(MaintenanceTableFields));
