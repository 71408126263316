import React, { Component } from "react";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Select,
  Upload,
  message,
  Tabs,
  Modal,
  Tooltip,
  Spin,
} from "antd";
import { UploadOutlined, CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import CompanyStore from "../store/CompanyStore";
import withRouter from "../withRouter";
import CountryStore from "../store/CountryStore";
import Autocomplete from "react-google-autocomplete";
import config from "../config";
import { autorun } from "mobx";
import DisplayLocationMap from "./DisplayLocationMap";
import "./AddAccount.css";
import { addMonths, format } from "date-fns";
import AccountFormFields from "./Plugins/AddAccount/AccountFormFields";
import UserDetailsFields from "./Plugins/AddAccount/UserDetailsFields";
import AccountFormPaymentFields from "./Plugins/AddAccount/AccountFormPaymentFields";

const { Option } = Select;

const { TabPane } = Tabs;
/* eslint-enable no-template-curly-in-string */

class AddAccount extends Component {
  formRef = React.createRef();
  constructor(props, context) {
    super(props);

    this.state = {
      activeTab: "1",
      tabErrors: {},
      formError: false,

      isSwitchOn: false,

      loading: false,
      loadData: 0,
      update: 0,
      editFormShow: true,
      companyId: "",
      errorShow: false,
      data: [],
      fileList: "",
      isadmin: this.props.getUserData().isadmin,
      cuserId: this.props.getUserData().userId,
      // Payment Modes
      Yearly: true,
      Monthly: false,
      HaifYearyly: false,
      Quaterly: false,
      timezoneId: parseInt(config.defaultTimezoneId),
      fuelSensor: false,
      driverId: false,
      loadSensor: false,
      temperatureSensor: false,
      rfid: false,
      canbus: false,

      monthlyRental: 0,

      // Charges
      deviceCharge: 0,
      simCharge: 0,
      applicationCharge: 0,
      totalCharge: 0,

      driverIdCharge: 0,
      temperatureSensorCharge: 0,
      rfidCharge: 0,
      canbusCharge: 0,
      fuelSensorCharge: 0,
      paymentModeDisable: false,

      logo: "",
      logoFromDb: [],
      addressMap: false,
      addressInfo: [],
      secondaryContact: false,
      loadingData: false,
      ContactNoClass: "bi bi-plus-circle",
      phoneNoClass: "bi bi-plus-circle",
      EmailIconClass: "bi bi-plus-circle",
      setDisable: false,
      disabledField: false,
      IdentityNoComponent: null,
      UserDetailsFieldComponent: null,
    };
    this.companyStore = new CompanyStore(this);
    this.countryStore = new CountryStore(this);

    // this.isArabic = context.isArabic
    // console.log(this.isArabic)
  }
  normFile = (e) => {
    // console.log('Upload event:', e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.addressMap &&
      prevState.addressMap !== this.state.addressMap
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }
  async componentDidMount() {
    autorun(() => console.log("hello"));

    await this.setState({ loadingData: true });
    const companyId = this.props.companyIdForEdit;

    // this.setFieldsValue({applicationCharge : "value"})
    await this.countryStore.getCountry();
    await this.countryStore.getTimeZone();

    // this.formRef.current.setFieldsValue({
    //   timezoneId:"2"
    // })
    // await this.setState({ loadData: 1 });
    // await this.setState({companyId:companyId})
    //       console.log("props", companyId)
    if (companyId) {
      await this.setState({ editFormShow: false });
      const getCompanyData = await this.companyStore.getSingleCompanyData(
        companyId
      );
      //console.log(this.companyStore.logoFromDb[0])
      console.log(getCompanyData, "check");

      if (getCompanyData.logo) {
        await this.setState({ logo: getCompanyData.logo });
      }

      // if (getCompanyData.logo) {
      //   console.log(getCompanyData.logo);
      //   this.setState({
      //     logoFromDb: [
      //       {
      //         name: "1687166980firm375.jpg",
      //         thumbUrl:
      //           config.fleetURL + "/images/companylogo/1687166980firm375.jpg",
      //       },
      //     ],
      //   });

      //   await this.setState({
      //     thumbUrl:
      //       config.fleetURL + "/images/companylogo/" + getCompanyData.logo,
      //   });

      //   await console.log(this.state.thumbUrl);
      // }
      await this.formRef.current.setFieldsValue({
        username: getCompanyData.username,
        companyname: getCompanyData.name,
        companyNameArabic: getCompanyData.companyNameArabic,
        phoneno: getCompanyData.contact1No,
        contact1Email: getCompanyData.contact1Email,
        contact1No: getCompanyData.contact1No,
        city: getCompanyData.city,
        state: getCompanyData.state,
        // AccountexpiryDate: moment(getCompanyData.AccountexpiryDate  ),
        // AccountexpiryDate: moment(getCompanyData.AccountexpiryDate && getCompanyData.AccountexpiryDate ),
        // updateDate: moment(getCompanyData.addedByDateTime ),
        // updateDate: moment(getCompanyData.addedByDateTime && getCompanyData.addedByDateTime),
        companyaddress: getCompanyData.address,
        // commercialRecordIssueDate: moment(getCompanyData.commercialRecordIssueDate && getCompanyData.commercialRecordIssueDate ),
        // commercialRecordIssueDate: moment(getCompanyData.commercialRecordIssueDate  ),
        commercialRecordNo: getCompanyData.commercialRecordNo,
        registrationNoArabic: getCompanyData.registrationNoArabic,
        contact1: getCompanyData.contact1,
        contact2: getCompanyData.contact2,
        contact2Email: getCompanyData.contact2Email,
        contact2No: getCompanyData.contact2No,
        contact3: getCompanyData.contact3,
        contact3Email: getCompanyData.contact3Email,
        contact3No: getCompanyData.contact3No,
        country: getCompanyData.country ? parseInt(getCompanyData.country) : "",
        // dob: moment(getCompanyData.dob ),
        // dob: moment(getCompanyData.dob && getCompanyData.dob),
        dobFormat: getCompanyData.dobFormat,
        identityNo: getCompanyData.identityNo,
        industry: getCompanyData.industry,
        attachInvoice: getCompanyData.invoice,
        companylocation: getCompanyData.location,
        logo: getCompanyData.logo,
        mapSetting: getCompanyData.mapSetting,
        mapSetting2: getCompanyData.mapSetting2,
        offlineAlerts:
          getCompanyData.offlineAlert === 1
            ? this.setState({ isSwitchOn: true })
            : this.setState({ isSwitchOn: false }),
        paymentMode: getCompanyData.paymentMode,
        // taxExpiryDate: getCompanyData.taxExpiryDate ? moment(getCompanyData.taxExpiryDate):null,
        // taxExpiryDate: moment(getCompanyData.taxExpiryDate && getCompanyData.taxExpiryDate ),
        website: getCompanyData.website,
        timezoneId: parseInt(getCompanyData.timezoneId),
        companystatus: parseInt(getCompanyData.status),
        monthlyRental: getCompanyData.monthlyRental
          ? getCompanyData.monthlyRental
          : 0,
        rfidCharge: getCompanyData.rfidCharge ? getCompanyData.rfidCharge : 0,
        fuelSensorCharge: getCompanyData.fuelSensorCharge
          ? getCompanyData.fuelSensorCharge
          : 0,
        temperatureSensorCharge: getCompanyData.temperatureSensorCharge
          ? getCompanyData.temperatureSensorCharge
          : 0,
        canbus: getCompanyData.canbus ? getCompanyData.canbus : 0,
        driverIdCharge: getCompanyData.driverIdCharge
          ? getCompanyData.driverIdCharge
          : 0,
        simCharge: getCompanyData.simCharge ? getCompanyData.simCharge : 0,
        applicationCharge: getCompanyData.applicationCharge
          ? getCompanyData.applicationCharge
          : 0,
        totalCharge: getCompanyData.totalCharge
          ? getCompanyData.totalCharge
          : 0,
        deviceCharge: getCompanyData.deviceCharge
          ? getCompanyData.deviceCharge
          : 0,

        // Accountexpirydate: getCompanyData.AccountExpiryDate
        //   ? dayjs(getCompanyData.AccountExpiryDate)
        //   : null,
        Accountexpirydate: getCompanyData.AccountExpiryDate,
        commercialRecordIssueDate: getCompanyData.commercialRecordIssueDate
          ? dayjs(getCompanyData.commercialRecordIssueDate)
          : null,
        // taxExpiryDate: getCompanyData.taxExpiryDate
        //   ? dayjs(getCompanyData.taxExpiryDate)
        //   : null,
        dob: getCompanyData.dob ? dayjs(getCompanyData.dob) : null,
        voltageDrop: getCompanyData.voltageDrop,
        landline: getCompanyData.Landline,
        certificateCheck: getCompanyData.certificate ? true : false,
        remark: getCompanyData.remark,
      });
      console.log(getCompanyData.deviceCharge);
      await this.setState({
        deviceCharge: getCompanyData.deviceCharge
          ? getCompanyData.deviceCharge
          : 0,
      });
      await this.setState({
        totalCharge: getCompanyData.totalCharge
          ? getCompanyData.totalCharge
          : 0,
      });

      // this.setState({deviceCharge:getCompanyData.deviceCharge})
      await this.setState({
        applicationCharge: getCompanyData.applicationCharge
          ? getCompanyData.applicationCharge
          : 0,
      });
      await this.setState({
        simCharge: getCompanyData.simCharge ? getCompanyData.simCharge : 0,
        monthlyRental: getCompanyData.monthlyRental,
      });

      await this.setState({
        rfidCharge: getCompanyData.rfidCharge ? getCompanyData.rfidCharge : 0,
        rfid: getCompanyData.rfidCharge ? true : false,
      });
      await this.setState({
        fuelSensorCharge: getCompanyData.fuelSensorCharge
          ? getCompanyData.fuelSensorCharge
          : 0,
        fuelSensor: getCompanyData.fuelSensorCharge ? true : false,
      });
      await this.setState({
        temperatureSensorCharge: getCompanyData.temperatureSensorCharge
          ? getCompanyData.temperatureSensorCharge
          : 0,
        temperatureSensor: getCompanyData.temperatureSensorCharge
          ? true
          : false,
      });
      console.log(getCompanyData.canbus);
      await this.setState({
        canbusCharge: getCompanyData.canbus ? getCompanyData.canbus : 0,
        canbus: getCompanyData.canbus ? true : false,
      });
      await this.setState({
        driverIdCharge: getCompanyData.driverIdCharge
          ? getCompanyData.driverIdCharge
          : 0,
        driverId: getCompanyData.driverIdCharge ? true : false,

        countryCode1: Number(getCompanyData.countryCode1),
        countryCode2: Number(getCompanyData.countryCode2),
      });

      if (getCompanyData.paymentMode == "HaifYearyly") {
        this.setState({ Yearly: true, paymentModeDisable: true });
      } else if (getCompanyData.paymentMode == "Quaterly") {
        this.setState({ Yearly: true, paymentModeDisable: true });
      } else if (getCompanyData.paymentMode == "Monthly") {
        this.setState({
          Monthly: true,
          paymentModeDisable: true,
          Yearly: false,
        });
      } else if (getCompanyData.paymentMode == "Yearly") {
        this.setState({ Yearly: true, paymentModeDisable: true });
      }

      console.log(this.state.simCharge, "sim");
      console.log(this.state.deviceCharge, "devie");
      console.log(getCompanyData.offlineAlert, "getCompanyData");
      await this.setState({ update: 1 });

      if (this.props.companyIdForEdit) {
        this.setState({ paymentModeDisable: true });
        this.setState({ setDisable: true });
      }
    }

    if (this.props.getUserData().isadmin === 1 && this.props.companyIdForEdit) {
      this.setState({ disabledField: true });
    }

    this.setState({ loadData: 1, loadingData: false });

    //this.importComponent();
  }

  onAccountDetailSubmit = async (fieldsValue) => {
    if (this.state.fileList?.size > config.photoUploadSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return;
    }
    this.setState({ loading: true });

    // console.log((fieldsValue.Accountexpirydate?.length>0) && format(addMonths(new Date(),Number(fieldsValue.Accountexpirydate)), 'yyyy-MM-dd'))
    let companyIdForEdit = this.props.companyIdForEdit;
    console.log(fieldsValue);
    const data = {
      ...fieldsValue,
      mapSetting: fieldsValue.mapSetting ? 1 : 0,
      mapSetting2: fieldsValue.mapSetting2 ? 1 : 0,
      // Accountexpirydate: fieldsValue.Accountexpirydate
      //   ? fieldsValue.Accountexpirydate.format("YYYY-MM-DD")
      //   : null,
      Accountexpirydate:
        !companyIdForEdit && fieldsValue?.Accountexpirydate?.length > 0
          ? format(
              addMonths(new Date(), Number(fieldsValue?.Accountexpirydate)),
              "yyyy-MM-dd"
            )
          : fieldsValue?.Accountexpirydate,
      commercialRecordIssueDate: fieldsValue.commercialRecordIssueDate
        ? fieldsValue.commercialRecordIssueDate.format("YYYY-MM-DD")
        : null,
      // taxExpiryDate: fieldsValue.taxExpiryDate
      //   ? fieldsValue.taxExpiryDate.format("YYYY-MM-DD")
      //   : null,
      dob: fieldsValue.dob ? fieldsValue.dob.format("YYYY-MM-DD") : null,
      cuserId: this.state.cuserId,
      user: {
        name: fieldsValue.name,
        username: fieldsValue.username,
        password: fieldsValue.password,
        roleId: fieldsValue.roleId,
        isadmin: this.state.isadmin,
      },
      rfidCharge: this.state.rfid ? this.state.rfidCharge : null,
      fuelSensorCharge: this.state.fuelSensor
        ? this.state.fuelSensorCharge
        : null,
      temperatureSensorCharge: this.state.temperatureSensor
        ? this.state.temperatureSensorCharge
        : null,
      canbus: this.state.canbus ? this.state.canbusCharge : null,
      driverIdCharge: this.state.driverId ? this.state.driverIdCharge : null,
      logo: this.state.logo,
      loggeduserId: this.props.getUserData().userId,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      offlineAlerts: fieldsValue.offlineAlerts
        ? fieldsValue.offlineAlerts
        : this.state.isSwitchOn
        ? 1
        : 0,
      companystatus: 1,
      customerDistributor:
        this.props.getUserData().isadmin !== -1
          ? "Customer"
          : fieldsValue.customerDistributor,
    };

    console.log(data);
    let response;
    if (this.props.companyIdForEdit) {
      console.log("called");
      data.companyId = await this.props.companyIdForEdit;
      response = await this.companyStore.updateData(data);
    } else {
      data.companyId = -1;
      response = await this.companyStore.addData(data);
    }
    await this.props.response(response);
    console.log(data);

    console.log(response);
    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/companylist");
    // } else {
    //   if (response.errors[0].companyname) {
    //     message.error(this.context.translate(response.errors[0]?.companyname));
    //   }
    //   if (response.errors[0]?.username) {
    //     message.error(this.context.translate(response.errors[0]?.username));
    //   }
    //   this.setState({ loading: false });
    // }
  };

  imageDelete = async () => {
    this.setState({ logo: "" });
    this.companyStore.setLogoFromDb([]);
  };
  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)
      // console.log("innnnnnnn");
      console.log(fileinfo);
      if (this.state.logo) {
      }
      fileUp.fileList[0].url = config.fleetURL + fileinfo?.folderName;
      await this.setState({ logo: fileinfo.data });
    }

    this.companyStore.setLogoFromDb(fileUp.fileList);
    this.setState({ update: 1 });
  };

  removeImage = async (fileUp) => {
    this.remove();
  };

  handleSearch = (newValue) => {};
  // <Select.Option value="Yearly">Yearly </Select.Option>
  // <Select.Option value="HaifYearyly">HaifYearyly</Select.Option>
  // <Select.Option value="Quaterly">Quaterly</Select.Option>
  // <Select.Option value="Monthly">Mo
  onSelectPaymentMode = (val) => {
    if (val === "Yearly" || val === "HaifYearyly" || val === "Quaterly") {
      this.setState({ Yearly: true });
      this.setState({ Monthly: false });
      this.state.Monthly &&
        this.formRef.current.setFieldsValue({ totalCharge: 0 });
      // this.formRef.current.setFieldsValue({
      //   totalCharge:
      //     parseFloat(this.state.deviceCharge) +
      //     parseFloat(this.state.simCharge) +
      //     parseFloat(this.state.applicationCharge),
      // });
    } else if (val === "Monthly") {
      this.formRef.current.setFieldsValue({
        totalCharge: parseFloat(this.state.monthlyRental),
      });
      this.setState({ Monthly: true });
      this.setState({ Yearly: false });
    }
  };

  closeAccountForm = () => {
    this.props.closeModal();
  };

  // this.setState({
  //   rfidCharge: getCompanyData.rfidCharge ? getCompanyData.rfidCharge : 0,
  //   rfid: getCompanyData.rfidCharge ? true : false,
  // });
  // this.setState({
  //   fuelSensorCharge: getCompanyData.fuelSensorCharge
  //     ? getCompanyData.fuelSensorCharge
  //     : 0,
  //   fuelSensor: getCompanyData.fuelSensorCharge ? true : false,
  // });
  // this.setState({
  //   temperatureSensorCharge: getCompanyData.temperatureSensorCharge
  //     ? getCompanyData.temperatureSensorCharge
  //     : 0,
  //   temperatureSensor: getCompanyData.temperatureSensorCharge
  //     ? true
  //     : false,
  // });
  // this.setState({
  //   canbusCharge: getCompanyData.canbus ? getCompanyData.canbus : 0,
  //   canbus: getCompanyData.canbus ? true : false,
  // });
  // this.setState({
  //   driverIdCharge: getCompanyData.driverIdCharge
  //     ? getCompanyData.driverIdCharge
  //     : 0,
  //   driverId: getCompanyData.driverIdCharge ? true : false,
  // });

  fillCharges = async () => {
    console.log(this.state.driverIdCharge);
    const totalCharge =
      parseFloat(
        this.state.deviceCharge && this.state.Yearly
          ? this.state.deviceCharge
          : 0
      ) +
      parseFloat(
        this.state.applicationCharge && this.state.Yearly
          ? this.state.applicationCharge
          : parseFloat(0)
      ) +
      parseFloat(
        this.state.simCharge && this.state.Yearly ? this.state.simCharge : 0
      ) +
      parseFloat(
        this.state.driverId && this.state.driverIdCharge && this.state.Yearly
          ? this.state.driverIdCharge
          : 0
      ) +
      parseFloat(
        this.state.temperatureSensor &&
          this.state.temperatureSensorCharge &&
          this.state.Yearly
          ? this.state.temperatureSensorCharge
          : 0
      ) +
      parseFloat(
        this.state.rfid && this.state.rfidCharge && this.state.Yearly
          ? this.state.rfidCharge
          : 0
      ) +
      parseFloat(
        this.state.canbus && this.state.canbusCharge && this.state.Yearly
          ? this.state.canbusCharge
          : 0
      ) +
      parseFloat(
        this.state.fuelSensor &&
          this.state.fuelSensorCharge &&
          this.state.Yearly
          ? this.state.fuelSensorCharge
          : 0
      ) +
      parseFloat(
        this.state.monthlyRental && this.state.Monthly
          ? this.state.monthlyRental
          : 0
      );
    console.log(totalCharge);
    await this.formRef.current.setFieldsValue({
      totalCharge: totalCharge.toFixed(2), // Format to three decimal places
    });
  };
  handlePlaceSelected = async (place) => {
    await this.setState({ addressMap: true });
    await this.setState({
      addressInfo: [
        place.geometry.location.lat(),
        place.geometry.location.lng(),
      ],
    });
    console.log(place);
    const { address_components, geometry } = place;

    let city = "";
    let state = "";
    let country = "";
    if (geometry?.location?.lat()) {
      this.setState({ latitude: geometry?.location?.lat() });
      this.setState({ longitude: geometry?.location?.lng() });
    }
    //console.log(geometry.location.lat());
    // Extract city, state, and country from the address components
    for (let i = 0; i < address_components.length; i++) {
      const component = address_components[i];
      const { types } = component;

      if (types.includes("locality")) {
        city = component.long_name;
      } else if (types.includes("administrative_area_level_1")) {
        state = component.long_name;
      } else if (types.includes("country")) {
        country = component.long_name;
      }
    }

    //const result = this.countryStore?.country?.find(e => e.country === country);
    //console.log(result,"INNNNN");

    if (country) {
      this.countryStore?.country?.map((name) => {
        //console.log(name.country.toLowerCase,country.toLowerCase)
        if (name.country.toLowerCase() === country.toLowerCase()) {
          this.formRef.current.setFieldsValue({
            country: Number(name.countryId),
          });
        }
      });
    }

    this.formRef.current.setFieldsValue({
      city: city,
      state: state,
      // country: country,
      companylocation: place.formatted_address,
    });
  };

  //  validatePhoneNumber = (_, value) => {
  //   // Check if value contains only numbers
  //   const isValid = /^[0-9]+$/.test(value);
  //   if (!isValid) {
  //     if (value) {
  //       return Promise.reject('Please enter only numbers');
  //     }
  //   } else if (value.length < 10) {
  //     return Promise.reject('Enter a 10-digit number');
  //   }
  //   return Promise.resolve();
  // };

  // validatePhoneNumber = (_, value) => {
  //   // Check if value contains only numbers
  //   const isValid = /^[0-9]+$/.test(value);

  //   if (!isValid) {
  //     if (value) {
  //       return Promise.reject("Please enter only numbers");
  //     }
  //   } else if (value.length < 10) {
  //     return Promise.reject("Enter a 10-digit number");
  //   }
  //   return Promise.resolve();
  // };

  validatePhoneNumber = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };
  handleTabChange = (key) => {
    this.setState({
      activeTab: key,
      formError: false, // Reset formError when switching tabs
    });
  };

  validateFile = (file) => {
    this.setState({ fileList: file });
    console.log(file);
    const allowedExtensions = [".jpg", ".png"];
    const fileName = file.name;
    const fileExtension = fileName.slice(
      ((fileName.lastIndexOf(".") - 1) >>> 0) + 2
    );
    if (!allowedExtensions.includes("." + fileExtension.toLowerCase())) {
      message.error("Only JPEG and PNG files are allowed");
      return false;
    }
    const maxSize = config.photoUploadSize;
    if (file.size > maxSize) {
      message.error(this.context.translate("_FILE_SIZE_UPTO_10_MB"));
      return false;
    }
    return true;
  };

  importComponent = async () => {
    console.log("importComponent");
    try {
      const component = await import(
        `./Plugins/${config.project}_AddAccount/AccountFormFields`
      );
      this.setState({ IdentityNoComponent: component.default });
    } catch (error) {
      console.error("Error loading AccountFormFields component:", error);
    }

    try {
      const component = await import(
        `./Plugins/${config.project}_AddAccount/UserDetailsFields`
      );
      this.setState({ UserDetailsFieldComponent: component.default });
    } catch (error) {
      console.error("Error loading UserDetailsFields component:", error);
    }
  };
  // handleNextClick = async () => {
  //   const form = this.formRef.current;

  //   if (form) {
  //     try {
  //       await form.validateFields(); // Validate all fields without triggering onFinishFailed

  //       const { activeTab } = this.state;
  //       const nextTab = String(Number(activeTab) + 1);
  //       this.setState({ activeTab: nextTab });
  //       // this.setState({ formError: false });
  //     } catch (err) {
  //       console.error("Form Validation Error:", err);
  //       this.setState({ formError: true });
  //     }
  //   }
  // };
  handleNextClick = async () => {
    const form = this.formRef.current;

    if (form) {
      try {
        if (this.state.activeTab === "1") {
          // Validate only the fields in the 'User Information' tab
          await form.validateFields([
            "companyname",
            "customerDistributor",
            "city",
            "state",
            "country",
            "timezoneId",
            "companystatus",
            "contact1",
            "contact1Email",
            "contact1No",
            "identityNo",
          ]);
        } else {
          // Validate all fields in other tabs
          await form.validateFields();
        }

        const { activeTab } = this.state;
        const nextTab = String(Number(activeTab) + 1);
        this.setState({ activeTab: nextTab });
      } catch (err) {
        console.error("Form Validation Error:", err);
        message.error(
          this.context.translate("_FLEET_VEHICLE_VEHICLEDETAIL_ERRORMSG_")
          // "Please fill correct data in the fields that are marked red."
        );
        this.setState({ formError: true });
      }
    }
  };

  handleFormError = (err) => {
    console.log(err);
    const { errorFields } = err;

    const tabErrors = {};

    errorFields.forEach(({ name }) => {
      const [fieldName] = name;
      const tabFieldMappings = {
        companyname: 1,
        customerDistributor: 1,
        city: 1,
        state: 1,
        country: 1,
        timezoneId: 1,
        companystatus: 1,
        contact1: 1,
        contact1Email: 1,
        contact1No: 1,
        identityNo: 1,
        // second tab

        username: 2,
        password: 2,

        // third tab
      };
      const tab = tabFieldMappings[fieldName];
      console.warn(tab);
      if (tab) {
        tabErrors[tab] = true;
      }
    });

    this.setState({ tabErrors }); // Update the state with tabErrors
  };

  handleBackClick = () => {
    const { activeTab } = this.state;
    const previousTab = String(Number(activeTab) - 1);
    this.setState({ activeTab: previousTab });
  };

  chargesValidator = async ({ e, charge }) => {
    console.log(e, charge);
    await this.setState({
      [charge]: e.target.value,
    });

    let value = e.target.value.replace(/[^0-9.]*/g, "");
    if (value === "") {
      await this.setState({ [charge]: 0 });
      await this.formRef.current.setFieldsValue({
        [charge]: undefined,
      });
      // this.fillCharges();
      // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
    } else {
      await this.formRef.current.setFieldsValue({
        [charge]: value,
      });
    }
    await this.fillCharges();
  };

  validateNumber = (value, field1) => {
    // Check if value contains only numbers
    const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9+]*/g, "");
    console.log(value1.length);
    if (value1.length > 0) {
      this.formRef.current.setFieldsValue({ [field1]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field1]: "" });
    }
  };

  // convertNumberToArabic = (value, field) => {
  //   if (/^\d{10}$/.test(value)) {
  //     const arabicNumber = NumberToArabic.toArabic(value);
  //     this.setState({ [field]: value, arabicNumber });
  //   } else {
  //     this.setState({ [field]: '', arabicNumber: '' });
  //   }
  // };

  errorMessage = () => {
    message.open({
      type: "error",
      content: "This is an error message",
    });
  };

  setSwitch = (val) => {
    this.setState({ isSwitchOn: val });
  };

  render() {
    console.log("UserDetailsFields**", UserDetailsFields);
    return (
      <div style={{ padding: 0 }}>
        <div>
          <Spin spinning={this.state.loadingData} fullscreen>
            <Form
              // {...layout}
              ref={this.formRef}
              labelCol={{
                span: 20,
              }}
              layout="vertical"
              name="companyFrm"
              onFinish={this.onAccountDetailSubmit}
              // onFinishFailed={(e)=>this.setState({errorShow:true})}
              initialValues={{
                mapSetting: false,
                mapSetting2: false,
                countryCode1: config.countryCode,
                countryCode2: config.countryCode,
                customerDistributor: "Customer",
              }}
              onFinishFailed={(value) => {
                console.log(value);
                this.handleFormError(value);
              }}
              style={{
                maxWidth: "100%",
                padding: 0,
                fontFamily: "inter, sans-serif",
              }}
              className="formClass" //change
            >
              <Tabs
                activeKey={this.state.activeTab}
                onChange={this.handleTabChange}
              >
                <TabPane
                  tab={
                    <span
                      className={
                        this.state.tabErrors["1"] && "validation-failed"
                      }
                    >
                      {/* <Button type="text">Account Details</Button> */}
                      <Button type="text">
                        {this.context.translate(
                          "_FLEET_COMPANY_COMPANYDETAIL_TITLE_"
                        )}
                      </Button>
                    </span>
                  }
                  key="1"
                >
                  <Row style={{ overflowY: "scroll", height: "480px" }}>
                    {/* {this.state.errorShow && { message.error("Please fill correct data in the fields that are marked red.")  }} */}
                    {}
                    {/* <Col span={12} className={ this.isArabic ? "formColumnClassArabic" :"formColumnClass"}> */}
                    <Col span={12} className="formColumnClass">
                      {this.state.editFormShow &&
                        this.props.getUserData().isadmin !== 3 && (
                          <Form.Item
                            name="customerDistributor"
                            // label="Customer/Distributor"
                            label={this.context.translate(
                              "_CUSTOMER_DISTRIBUTOR_"
                            )}
                            rules={[{ required: true }]}
                            initialValue={"Customer"}
                          >
                            <Select
                              placeholder="Select Customer"
                              onChange={(value) => {
                                if (value === "Distributor") {
                                  this.formRef.current.setFieldsValue({
                                    selectRole: "Distributor",
                                  });
                                } else {
                                  this.formRef.current.setFieldsValue({
                                    selectRole: "norole",
                                  });
                                }
                              }}
                            >
                              <Select.Option value="Customer">
                                Customer
                                {/* {this.context.translate("_FLEET_CUSTOMER_")} */}
                              </Select.Option>
                              <Select.Option value="Distributor">
                                Distributor
                                {/* {this.context.translate("_DISTRIBUTOR_")} */}
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        )}
                      <Form.Item
                        name="companyname"
                        // label="Account Name"
                        label={this.context.translate(
                          "_FLEET_COMPANY_COMPANYLIST_COMPANYNAME_"
                        )}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="companyNameArabic"
                        // label="Account Name"
                        label={this.context.translate("_COMPANY_NAME_ARABIC_")}
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="companyaddress"
                        // label="Account Address"
                        label={this.context.translate(
                          "_FLEET_COMPANY_COMPANYDETAIL_ADDRESS_"
                        )}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="companylocation"
                        // label="Mark Location"
                        label={this.context.translate("_MARK_LOCATION_")}
                      >
                        <Autocomplete
                          style={{
                            width: "100%",
                            height: "44px",
                            boxShadow: "none",
                            padding: "4px 11px",
                            borderWidth: 1,
                            borderStyle: "solid",
                            borderColor: "#d9d9d9",
                            borderRadius: "6px",
                          }}
                          apiKey={config.googleMapKey}
                          onPlaceSelected={this.handlePlaceSelected}
                          componentRestrictions={{ country: "us" }}
                          options={{
                            types: ["geocode", "establishment"],
                          }}
                          placeholder={this.context.translate(
                            "_PLEASE_ENTER_LOCATION_"
                          )}
                        />
                      </Form.Item>

                      {/* <Form.Item name={['account', 'identityNumber']} label="Identity Number" rules={[{ type: 'number' }]}>
                                <Input />
                            </Form.Item>

                            <Form.Item name={['account', 'commercialRegNumber']} label="Commercial Registration Number" rules={[{ type: 'number' }]}>
                                <Input />
                            </Form.Item>

                            

                            <Form.Item name={['account', 'markLocation']} label="Enter a location" >
                                <Input />
                            </Form.Item> */}
                      <Row>
                        <Col span={11}>
                          <Form.Item
                            name="city"
                            // label="City"
                            label={this.context.translate(
                              "_FLEET_COMPANY_COMPANYDETAIL_CITY_"
                            )}
                            rules={[
                              { required: true },
                              // {
                              //   pattern: /^[A-Za-z]+$/,
                              //   // pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
                              //   message: "Please enter only alphabets",
                              // },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={2} />
                        <Col span={11}>
                          <Form.Item
                            name="state"
                            // label="State"
                            label={this.context.translate("_STATE_")}
                            rules={[
                              { required: true },
                              // {
                              //   pattern: /^[A-Za-z]+$/,
                              //   message: "Please enter only alphabets",
                              // },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Form.Item
                        name="country"
                        // label="Country"
                        label={this.context.translate(
                          "_FLEET_COMPANY_COMPANYDETAIL_COUNTRY_"
                        )}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          // placeholder="Select Country"
                          placeholder={this.context.translate(
                            "_SELECT_COUNTRY_"
                          )}
                          filterOption={(inputValue, option) => {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }}
                        >
                          {this.countryStore?.country?.map((country) => {
                            return (
                              <Option value={parseInt(country.countryId)}>
                                {country.country}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        name="timezoneId"
                        // label="Time Zone"
                        label={this.context.translate(
                          "_FLEET_COMPANY_COMPANYDETAIL_TIME_"
                        )}
                        rules={[{ required: true }]}
                        initialValue={this.state.timezoneId}
                      >
                        {/* <Select
                      placeholder="Select TimeZone"
                      onSearch={this.handleSearch}
                      showSearch
                      fieldNames={{ label: "name", value: "timezoneId" }}
                      optionFilterProp="children"
                      options={this.countryStore?.timeZones}
                    /> */}

                        <Select
                          disabled={
                            this.props.getUserData().isadmin === 1
                              ? true
                              : false
                          }
                          showSearch
                          placeholder="Select TimeZone"
                          filterOption={(inputValue, option) => {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }}
                          // defaultValue={"AMST - GMT+03:00"}
                        >
                          {this.countryStore?.timeZones.map((country) => {
                            return (
                              <Option value={parseInt(country.timezoneId)}>
                                {country.abbr}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>

                      {/* <Form.Item
                      name="companystatus"
                      // label="Status"
                      label={this.context.translate(
                        "_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"
                      )}
                      rules={[{ required: true }]}
                    >
                      <Select
                        // placeholder="Select Status"
                        placeholder={this.context.translate(
                          "_FLEET_USER_USERDETAIL_STATUSDDL_SELECT_"
                        )}
                        autoClearSearchValue
                      >
                        <Select.Option value={1}>
                          {this.context.translate(
                            "_FLEET_USER_USERLIST_STATUS_ACTIVE_"
                          )}
                        </Select.Option>
                        <Select.Option value={0}>
                          {this.context.translate(
                            "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                          )}
                        </Select.Option>
                      </Select>
                    </Form.Item> */}

                      <Form.Item
                        name="industry"
                        // label="Industry"
                        label={this.context.translate("_INDUSTRY_")}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="website"
                        label={this.context.translate("_WEBSITE_")}
                        // label="Website"
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name="landline"
                        label={this.context.translate("_LANDLINE_")}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    {/* <Col span={12} className={ this.isArabic ? "formColumnClassArabic" :"formColumnClass"}> */}
                    <Col span={12} className="formColumnClass">
                      <Form.Item
                        name="Accountexpirydate"
                        // label="Acc. Expiry Date"
                        label={
                          this.props.companyIdForEdit
                            ? this.context.translate(
                                "_FLEET_COMPANY_COMPANYDETAIL_EXPIREDATE_"
                              )
                            : this.context.translate(
                                "_FLEET_COMPANY_COMPANYDETAIL_EXPIREMONTHS_"
                              )
                        }
                        rules={[{}]}
                      >
                        <Input
                          onChange={(e) => {
                            this.validateNumber(
                              e.target.value,
                              "Accountexpirydate"
                            );
                          }}
                          disabled={this.props.companyIdForEdit ? true : false}
                          // placeholder="Enter Months"
                          placeholder={this.context.translate("_ENTER_MONTHS_")}
                        />
                      </Form.Item>

                      {/* <Form.Item
                      name="taxExpiryDate"
                      // label="Tax Expiry Date"
                      label={this.context.translate(
                        "_FLEET_COMPANY_COMPANYDETAIL_TAXEXPIREDATE_"
                      )}
                      rules={[{ type: Date }]}
                    >
                      <DatePicker
                        format="YYYY-MM-DD"
                        style={{ width: "100%" }}
                      />
                    </Form.Item> */}
                      {/*config.formConfig.showIdentityNo && (
                       <Form.Item
                        name="identityNo"
                        // label="Identity Number"
                        label={this.context.translate("_IDENTITY_NUMBER_")}
                        // rules={[{ required: true }]}
                        rules={[
                          {
                            pattern: /^(\d{10})$/,
                            required: true,
                            message: this.context.translate(
                              "_Identity_Number_should_be_10_Digit_Number_"
                            ),
                          },
                        ]}
                      >
                        <Input
                          onChange={(e) => {
                            this.validateNumber(e.target.value, "identityNo");
                          }}
                        />
                      </Form.Item>
                     )*/}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          style={{ width: "85%" }}
                          name="contact1"
                          // label="Contact 1"
                          label={this.context.translate("_CONTACT1_")}
                          // rules={[{ required: true }]}
                          rules={[{ required: true }]}
                        >
                          <Input disabled={this.state.disabledField} />
                        </Form.Item>
                        <Form.Item label=" ">
                          <Tooltip
                            // title="Add new Department"
                            title={this.context.translate("_CONTACT2_")}
                            placement="bottom"
                            color={config.tooltipColorEdit}
                          >
                            <Button
                              style={{ borderRadius: "50%" }}
                              icon={
                                <i
                                  class={this.state.ContactNoClass} //"bi bi-plus-circle"
                                  style={{ fontSize: "26px" }}
                                ></i>
                              }
                              type="primary"
                              // style={{ height: "48px"}}
                              onClick={async () => {
                                await this.setState({
                                  secondaryContact:
                                    !this.state.secondaryContact,
                                });

                                if (this.state.secondaryContact) {
                                  this.setState({
                                    ContactNoClass: "bi bi-dash-circle",
                                  });
                                } else {
                                  this.setState({
                                    ContactNoClass: "bi bi-plus-circle",
                                  });
                                }
                              }}
                            ></Button>
                          </Tooltip>
                        </Form.Item>
                      </div>

                      {this.state.secondaryContact && (
                        <>
                          <Form.Item
                            style={{ width: "85%" }}
                            name="contact2"
                            // label="Contact 2"
                            label={this.context.translate("_CONTACT2_")}
                          >
                            <Input disabled={this.state.disabledField} />
                          </Form.Item>
                        </>
                      )}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          style={{ width: "30%" }}
                          name="countryCode1"
                          // label="Contact 1"
                          label={" "}
                          // rules={[{ required: true }]}
                          rules={[{ required: true }]}
                        >
                          <Select disabled={this.state.disabledField}>
                            <Select.Option value={91}>+91</Select.Option>
                            <Select.Option value={966}>+966</Select.Option>
                            {/* 966 = saudi arabia */}
                            <Select.Option value={971}>+971</Select.Option>
                            {/* 971 = uae */}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          style={{ width: "50%" }}
                          name="contact1No"
                          // label="Phone Number 1"
                          label={this.context.translate("_PHONE_NUMBER_1_")}
                          rules={[
                            {
                              pattern: /^\d{8,10}$/,
                              required: true,
                              message: this.context.translate(
                                "_Enter_8_or_10_Digit_"
                              ),
                            },
                          ]}
                          // rules={[{ required: true}]}
                        >
                          <Input
                            disabled={this.state.disabledField}
                            type="tel"
                            // maxLength={13}
                            onChange={(e) => {
                              this.validatePhoneNumber(
                                e.target.value,
                                "contact1No"
                              );
                            }}
                          />
                        </Form.Item>

                        <Form.Item label=" ">
                          <Tooltip
                            // title={"Secondary Contact No"}
                            title={this.context.translate(
                              "_SECONDARY_CONTACT_NO_"
                            )}
                            placement="bottom"
                            color={config.tooltipColorEdit}
                          >
                            <Button
                              style={{ borderRadius: "50%" }}
                              icon={
                                <i
                                  class={this.state.phoneNoClass}
                                  style={{ fontSize: "26px" }}
                                ></i>
                              }
                              type="primary"
                              // style={{ height: "48px"}}
                              onClick={async () => {
                                await this.setState({
                                  secondaryContactNo:
                                    !this.state.secondaryContactNo,
                                });

                                if (this.state.secondaryContactNo) {
                                  this.setState({
                                    phoneNoClass: "bi bi-dash-circle",
                                  });
                                } else {
                                  this.setState({
                                    phoneNoClass: "bi bi-plus-circle",
                                  });
                                }
                              }}
                            ></Button>
                          </Tooltip>
                        </Form.Item>
                      </div>
                      {this.state.secondaryContactNo && (
                        <div
                          style={{ display: "flex", justifyContent: "start" }}
                        >
                          <Form.Item
                            style={{ width: "30%" }}
                            className="extField"
                            name="countryCode2"
                            // label="Contact 1"
                            label={" "}
                            // rules={[{ required: true }]}
                          >
                            <Select
                              defaultValue={966}
                              disabled={this.state.disabledField}
                            >
                              <Select.Option value={91}>+91</Select.Option>
                              <Select.Option value={966}>+966</Select.Option>
                              {/* 966 = saudi arabia */}
                              <Select.Option value={971}>+971</Select.Option>
                              {/* 971 = uae */}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            style={{ width: "50%" }}
                            name="contact2No"
                            // label="Phone Number 2"
                            label={this.context.translate("_PHONE_NUMBER_2_")}
                          >
                            <Input
                              disabled={this.state.disabledField}
                              type="tel"
                              maxLength={13}
                              onChange={(e) => {
                                this.validatePhoneNumber(
                                  e.target.value,
                                  "contact2No"
                                );
                              }}
                            />
                          </Form.Item>
                          <Form.Item label=""></Form.Item>
                        </div>
                      )}

                      {/* </Col>
                <Col span={1}></Col>
                <Col span={7}> */}

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          style={{ width: "85%" }}
                          name="contact1Email"
                          // label="Email 1"
                          label={this.context.translate("_EMAIL_1_")}
                          // rules={[{ type: "email", required: true }]}
                          rules={[
                            {
                              type: "email",
                              // message: "Please enter a valid email address", // Custom message for incorrect format
                              required: true,
                            },
                          ]}
                        >
                          <Input disabled={this.state.disabledField} />
                        </Form.Item>

                        <Form.Item label=" ">
                          <Tooltip
                            // title="Add new Department"
                            title={this.context.translate("_EMAIL_2_")}
                            placement="bottom"
                            color={config.tooltipColorEdit}
                          >
                            <Button
                              style={{ borderRadius: "50%" }}
                              icon={
                                <i
                                  class={this.state.EmailIconClass}
                                  style={{ fontSize: "26px" }}
                                ></i>
                              }
                              type="primary"
                              // style={{ height: "48px"}}
                              onClick={async () => {
                                await this.setState({
                                  secondaryEmail: !this.state.secondaryEmail,
                                });

                                if (this.state.secondaryEmail) {
                                  this.setState({
                                    EmailIconClass: "bi bi-dash-circle",
                                  });
                                } else {
                                  this.setState({
                                    EmailIconClass: "bi bi-plus-circle",
                                  });
                                }
                              }}
                            ></Button>
                          </Tooltip>
                        </Form.Item>
                      </div>

                      {this.state.secondaryEmail && (
                        <>
                          <Form.Item
                            style={{ width: "85%" }}
                            name="contact2Email"
                            // label="Email 2"
                            label={this.context.translate("_EMAIL_2_")}
                            rules={[{ type: "email" }]}
                          >
                            <Input disabled={this.state.disabledField} />
                          </Form.Item>
                        </>
                      )}

                      <Form.Item
                        name="commercialRecordNo"
                        // label="Commercial Record Number"
                        label={this.context.translate(
                          "_COMMERCIAL_RECORD_NUMBER_"
                        )}
                        rules={[
                          {
                            pattern: /^(\d{10})$/,
                            message: "The maximum length for this field is 10",
                          },
                        ]}
                        // rules={[{ required: true }]}
                      >
                        <Input
                          type="tel"
                          onChange={(e) => {
                            this.validateNumber(
                              e.target.value,
                              "commercialRecordNo"
                            );
                            const arabic = Number(
                              e.target.value
                            ).toLocaleString("ar-EG", { useGrouping: false });
                            // console.log(arabic)
                            this.formRef.current.setFieldsValue({
                              registrationNoArabic: arabic,
                            });
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        name="voltageDrop"
                        rules={[{ required: true }]}
                        label={this.context.translate("_VOLTAGE_DROP_")}
                        initialValue={2}
                      >
                        <Input />
                      </Form.Item>

                      {/*config.formConfig.showCommRecNumArabic && (
                       <Form.Item
                        name="registrationNoArabic"
                        // label="Commercial Record Number"
                        label={this.context.translate(
                          "_COMMERCIAL_RECORD_NUMBER_ARABIC_"
                        )}
                       
                        // rules={[{ required: true }]}
                      >
                        <Input disabled/>
                      </Form.Item>
                       )*/}
                      {/*config.formConfig.showRecordIssueDate && (
                       <Row className="mapcheck0">
                        <Col span={11}>
                          <Form.Item
                            name="commercialRecordIssueDate"
                            // label="Commercial Record Issue Date"
                            label={this.context.translate(
                              "_COMMERCIAL_RECORD_ISSUE_DATE2_"
                            )}
                            rules={[{ type: Date }]}
                          >
                            <DatePicker
                              format="YYYY-MM-DD"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        
                        <Col span={11}>
                          <Form.Item
                            className=""
                            name="offlineAlerts"
                            // label="Offline Alerts"
                            label={this.context.translate("_OFFLINE_ALERT_")}
                            rules={[{ type: Checkbox }]}
                          >
                            <Switch
                              className="form-offlineAlerts"
                              checked={this.state.isSwitchOn}
                              // checkedChildren="Yes"
                              // unCheckedChildren="No"
                              checkedChildren={this.context.translate(
                                "_FLEET_RULEDETAIL_ACTIVEDDL_YES_"
                              )}
                              unCheckedChildren={this.context.translate(
                                "_FLEET_RULEDETAIL_ACTIVEDDL_NO_"
                              )}
                              onChange={async (val) => {
                                this.setState({ isSwitchOn: val });
                                console.log(val ? 1 : 0);
                                this.formRef.current.setFieldsValue({
                                  offlineAlerts: val ? 1 : 0,
                                });
                                console.log(val);
                              }}
                              // defaultChecked
                            />
                          
                          </Form.Item>
                        </Col>
                      </Row> 
                     )*/}
                      <AccountFormFields
                        validateNumber={this.validateNumber}
                        isSwitchOn={this.state.isSwitchOn}
                        setSwitch={this.setSwitch}
                        formRef={this.formRef}
                      />

                      <Row className="mapcheck">
                        <Col span={11}>
                          <Form.Item
                            className="formCheckboxCss"
                            name="mapSetting"
                            // label="Map Setting"
                            label={this.context.translate("_MAP_SETTING_")}
                            valuePropName="checked"
                          >
                            {/* <Checkbox>Cluster</Checkbox> */}
                            <Checkbox>
                              {this.context.translate("_CLUSTER_")}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        {/* <Col span={1}></Col> */}
                        <Col span={11}>
                          <Form.Item
                            className="formCheckboxCss"
                            name="mapSetting2"
                            label=" "
                            valuePropName="checked"
                          >
                            {/* <Checkbox>Plot Default</Checkbox> */}
                            <Checkbox>
                              {this.context.translate("_PLOT_DEFAULT_")}
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <div className="formBtnGroup" style={{ textAlign: "center" }}>
                    {/* <Link to="/companyList">
                      <Button
                        className="backbtn"
                        type="secondary"
                        style={{ border: "1px solid" }}
                      >
                        Back
                      </Button>
                    </Link> */}
                    <Button
                      className="formCancelButton"
                      onClick={(e) => {
                        // this.formRef.current?.resetFields();
                        this.closeAccountForm();
                      }}
                    >
                      {/* css class */}
                      {/* Cancel */}
                      {this.context.translate("_FLEET_CLOSE_BTN_")}
                    </Button>
                    <Button
                      className="formSaveButton"
                      type="primary"
                      onClick={(e) => {
                        this.handleNextClick();
                      }}
                    >
                      {/* Next */}
                      {this.context.translate("_NEXT_")}
                    </Button>
                  </div>
                </TabPane>
                <TabPane
                  tab={
                    <span
                      className={
                        this.state.tabErrors["2"] && "validation-failed"
                      }
                    >
                      {/* <Button type="">User Details</Button> */}
                      <Button type="">
                        {this.context.translate(
                          "_FLEET_USER_USERDETAIL_TITLE_"
                        )}
                      </Button>
                    </span>
                  }
                  key="2"
                  // style={{ marginLeft: "30px" }}
                >
                  <Row style={{ overflowY: "scroll", height: "480px" }}>
                    <Col span={12} className="formColumnClass">
                      {/* <Form.Item name="contact3No" label="Phone Number 3">
                      <Input
                        type="tel"
                        maxLength={13}
                        onChange={(e) => {
                          this.validatePhoneNumber(
                            e.target.value,
                            "contact3No"
                          );
                        }}
                      />
                    </Form.Item> */}

                      {/* <Form.Item
                        name="dobFormat"
                        // label="DOB Format"
                        label={this.context.translate("_DOB_FORMATE_")}
                      >
                        <Select
                          // placeholder="Select DOB Format"
                          placeholder={this.context.translate(
                            "_SELECT_DOB_FORMAT_"
                          )}
                        >
                          <Select.Option value="Hijri">
                            Hijri
                          
                          </Select.Option>
                          <Select.Option value="Gregorian">
                            Gregorian
                            
                          </Select.Option>
                        </Select>
                      </Form.Item> */}

                      {/* <Form.Item
                        name="dob"
                        // label="Date Of Birth"
                        label={this.context.translate("_DOB_LABEL_")}
                        rules={[{ type: Date }]}
                      >
                        <DatePicker
                          format="YYYY-MM-DD"
                          style={{ width: "100%" }}
                        />
                      </Form.Item> */}

                      {this.state.editFormShow && (
                        <div>
                          <UserDetailsFields />

                          <Form.Item
                            name="username"
                            // label="User Name"
                            label={this.context.translate(
                              "_FLEET_COMPANY_USERDETAIL_USERNAME_"
                            )}
                            rules={[{ required: true }]}
                          >
                            <Input autoComplete="off" />
                          </Form.Item>

                          <Form.Item
                            // label="Password"
                            label={this.context.translate(
                              "_FLEET_COMPANY_USERDETAIL_PASSWORD_"
                            )}
                            name="password"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input.Password
                              autoComplete="new-password"
                              id="form-password"
                            />
                          </Form.Item>
                        </div>
                      )}
                    </Col>
                    <Col span={12} className="formColumnClass">
                      {this.state.editFormShow && (
                        <Form.Item
                          name="selectRole"
                          // label="Select Role"
                          label={this.context.translate(
                            "_FLEET_USER_USERDETAIL_ROLE_"
                          )}
                          initialValue="Default Role"
                          rules={[{ message: "Please Select Role!" }]}
                        >
                          <Select
                            // placeholder="Select Role"
                            placeholder={this.context.translate(
                              "_FLEET_USER_USERDETAIL_ROLE_"
                            )}
                          >
                            <Select.Option value="norole">
                              No Role
                              {/* {this.context.translate("_DEFAULT_ROLE_")} */}
                            </Select.Option>
                            <Select.Option value="basic">
                              {/* {this.context.translate("_BASIC_")} */}
                              Basic
                            </Select.Option>
                            <Select.Option value="Distributor">
                              Distributor
                              {/* {this.context.translate("_DISTRIBUTOR_")} */}
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      )}

                      <Form.Item
                        name="logo"
                        className="formCheckboxCss"
                        label=" "
                        style={{ marginTop: "16px" }}
                        //  valuePropName="fileList"

                        // getValueFromEvent={this.normFile}
                      >
                        <Upload
                          style={{ backgroundColor: "black" }}
                          name="logo"
                          action={
                            config.baseURL + "/index.php?c=company&a=fileupload"
                          }
                          listType="picture"
                          accept=".jpg,.png"
                          beforeUpload={(file) => this.validateFile(file)}
                          data={{ fileType: "companylogo", fieldName: "logo" }}
                          onChange={this.getUploadData}
                          onRemove={this.imageDelete}
                          maxCount={1}
                          // fileList={this.companyStore.logoFromDb}
                          // onRemove={this.removeImage}
                          // defaultFileList={[...this.companyStore.logoFromDb]}
                          {...this.companyStore.logoFromDb}
                        >
                          <Button
                            className="imgupload"
                            icon={<UploadOutlined />}
                          >
                            {/* Upload Photo */}
                            {this.context.translate(
                              "_FLEET_DASHBORD_NEWPOI_PHOTO_"
                            )}
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="formBtnGroup" style={{ textAlign: "center" }}>
                    <Button
                      className="formCancelButton"
                      onClick={this.handleBackClick}
                    >
                      {/* Back */}
                      {this.context.translate(
                        "_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_"
                      )}
                    </Button>
                    <Button
                      className="formSaveButton"
                      type="primary"
                      onClick={this.handleNextClick}
                    >
                      {/* Next */}
                      {this.context.translate("_NEXT_")}
                    </Button>
                  </div>
                </TabPane>

                <TabPane
                  tab={
                    <span
                      className={
                        this.state.tabErrors["3"] && "validation-failed"
                      }
                    >
                      <Button type="">
                        {this.context.translate("_PAYMENT_")}
                      </Button>
                      {/* <Button type="">
                      {this.context.translate("Payment")}
                    </Button> */}
                    </span>
                  }
                  key="3"
                  // style={{ marginLeft: "30px" }}
                >
                  <Row style={{ overflowY: "scroll", height: "480px" }}>
                    <Col span={12} className="formColumnClass">
                      <Form.Item
                        name="paymentMode"
                        // label="Payment Mode"
                        label={this.context.translate("_PAYMENT_MODE_")}
                        rules={[{ message: "Please select Payment Mode!" }]}
                        initialValue={"Yearly"}
                      >
                        <Select
                          placeholder="Select Payment Mode"
                          disabled={this.state.paymentModeDisable}
                          onSelect={(val) => {
                            this.onSelectPaymentMode(val);
                          }}
                        >
                          <Select.Option value="Yearly">
                            {" "}
                            Yearly
                            {/* {this.context.translate("_YEARLY_")}{" "} */}
                          </Select.Option>
                          <Select.Option value="HaifYearyly">
                            Half Yearly
                            {/* {this.context.translate("_HALF_YEARLY_")} */}
                          </Select.Option>
                          <Select.Option value="Quaterly">
                            Quaterly
                            {/* {this.context.translate("_QUARTERLY_")} */}
                          </Select.Option>
                          <Select.Option value="Monthly">
                            Monthly
                            {/* {this.context.translate("_MONTHLY_")} */}
                          </Select.Option>
                        </Select>
                      </Form.Item>

                      {this.state.Yearly && (
                        <div>
                          <Form.Item
                            name="deviceCharge"
                            // label="Device Charges"
                            label={this.context.translate("_DEVICE_CHARGES_")}
                            rules={[{ type: Number }]}
                          >
                            <Input
                              disabled={this.state.setDisable}
                              value={this.state.deviceCharge}
                              onChange={async (e) => {
                                this.chargesValidator({
                                  e: e,
                                  charge: "deviceCharge",
                                });
                                //   await this.setState({
                                //     deviceCharge: e.target.value,
                                //   });
                                //   let value = e.target.value.replace(/[^0-9.]*/g, "");
                                //   if (value === "") {
                                //     await this.setState({ deviceCharge: 0 });
                                //     await this.formRef.current.setFieldsValue({
                                //       deviceCharge: undefined,
                                //     });
                                //     // this.fillCharges();
                                //     // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                                //   } else {
                                //     await this.formRef.current.setFieldsValue({
                                //       deviceCharge: value,
                                //     });
                                //   }
                                // await this.fillCharges();
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name="simCharge"
                            // label="Sim Charges"
                            label={this.context.translate("_SIM_CHARGES_")}
                            rules={[{ type: Number }]}
                          >
                            <Input
                              disabled={this.state.setDisable}
                              value={this.state.simCharge}
                              onChange={async (e) => {
                                this.chargesValidator({
                                  e: e,
                                  charge: "simCharge",
                                });
                                // await this.setState({
                                //   simCharge: e.target.value,
                                // });
                                // // let value = e.target.value.replace(/\D/g, "");
                                // let value = e.target.value.replace(/[^0-9.]*/g, "");
                                // if (value === "") {
                                //   await this.setState({ simCharge: 0 });
                                //   await this.formRef.current.setFieldsValue({
                                //     simCharge: undefined,
                                //   });
                                //   // this.fillCharges();
                                //   // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                                // } else {
                                //   const numericValue = parseFloat(value);
                                //   await this.formRef.current.setFieldsValue({
                                //     simCharge: value,
                                //   });
                                //   // this.fillCharges();
                                // }
                                // await this.fillCharges();
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name="applicationCharge"
                            // label="Application Charges"
                            label={this.context.translate(
                              "_APPLICATON_CHARGES_"
                            )}
                            rules={[{ type: Number }]}
                          >
                            <Input
                              disabled={this.state.setDisable}
                              value={this.state.applicationCharge}
                              onChange={async (e) => {
                                this.chargesValidator({
                                  e: e,
                                  charge: "applicationCharge",
                                });
                                // await this.setState({
                                //   applicationCharge: e.target.value,
                                // });
                                // let value = e.target.value.replace(/[^0-9.]*/g, "");

                                // if (value === "") {
                                //   await this.setState({ applicationCharge: 0 });
                                //   await this.formRef.current.setFieldsValue({
                                //     applicationCharge: undefined,
                                //   });
                                //   // this.fillCharges();
                                //   // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                                // } else {
                                //   const numericValue = parseFloat(value);
                                //   await this.formRef.current.setFieldsValue({
                                //     applicationCharge: value,
                                //   });
                                //   // this.fillCharges();
                                // }
                                // await this.fillCharges();
                              }}
                            />
                          </Form.Item>
                        </div>
                      )}

                      {this.state.Monthly && (
                        <Form.Item
                          name="monthlyRental"
                          // label="Monthly Rental"
                          label={this.context.translate("_MONTHLY_RENTAL_")}
                          rules={[{ type: Number }]}
                        >
                          <Input
                            disabled={this.state.setDisable}
                            value={this.state.monthlyRental}
                            onChange={async (e) => {
                              this.chargesValidator({
                                e: e,
                                charge: "monthlyRental",
                              });

                              // const value = e.target.value.replace(/[^0-9.]*/g, "");
                              // if (value === "") {
                              //   // Handle empty input case
                              //   await this.setState({ monthlyRental: 0 });
                              //   await this.formRef.current.setFieldsValue({
                              //     monthlyRental: undefined,
                              //   });
                              //   // this.fillCharges();
                              //   // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                              // } else {
                              //   const numericValue = parseFloat(value);

                              //   await this.setState({
                              //     monthlyRental: numericValue,
                              //   });
                              //   await this.formRef.current.setFieldsValue({
                              //     monthlyRental: value,
                              //   });
                              //   await this.formRef.current.setFieldsValue({
                              //     totalCharge: numericValue,
                              //   });
                              //   // this.fillCharges();
                              // }
                              // await this.fillCharges();
                            }}
                          />
                        </Form.Item>
                      )}

                      <AccountFormPaymentFields />
                    </Col>

                    {/* <Col span={7}> */}
                    {/* <div className="addon"fillChargestem style={{ display: this.state.Yearly ? "" : "none" }}>
                    <Form.Item
                      name="addOn"
                      label="Add On"
                      rules={[{ type: Number }]}
                    >
                      <Checkbox
                        checked={this.state.rfid}
                        onChange={async (e) => {
                          await this.setState({ rfid: e.target.checked });
                          this.fillCharges();
                        }}
                      >
                        RFID Charges{" "}
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={this.state.fuelSensor}
                        onChange={async (e) => {
                          await this.setState({
                            fuelSensor: e.target.checked,
                          });
                          this.fillCharges();
                        }}
                      >
                        Fuel Sensor Charges{" "}
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={this.state.temperatureSensor}
                        onChange={async (e) => {
                          await this.setState({
                            temperatureSensor: e.target.checked,
                          });
                          this.fillCharges();
                        }}
                      >
                        Temprature Sensor Charges{" "}
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={this.state.canbus}
                        onChange={async (e) => {
                          await this.setState({ canbus: e.target.checked });
                          this.fillCharges();
                        }}
                      >
                        Canbus{" "}
                      </Checkbox>
                      <br />
                      <Checkbox
                        checked={this.state.driverId}
                        onChange={async (e) => {
                          await this.setState({
                            driverId: e.target.checked,
                          });
                          this.fillCharges();
                        }}
                      >
                        DriverId Charges{" "}
                      </Checkbox>
                      <br />
                    </Form.Item>
                  </div> */}
                    {/* </Col>
                <Col span={1}></Col> */}
                    <Col span={12} className="formColumnClass">
                      <div
                        style={{ display: this.state.Monthly ? "none" : "" }}
                      >
                        {/* <div style={{ display: this.state.rfid ? "" : "none" }}> */}
                        <Form.Item
                          name="rfidCharge"
                          className={!this.state.rfid && "disabledLabel"}
                          label={
                            <label>
                              {" "}
                              <Checkbox
                                checked={this.state.rfid}
                                onChange={async (e) => {
                                  await this.setState({
                                    rfid: e.target.checked,
                                  });
                                  await this.fillCharges();
                                }}
                              ></Checkbox>{" "}
                              {/* RFID Charges */}
                              {this.context.translate("_RFID_CHARGES_")}
                            </label>
                          }
                          rules={[{ type: Number }]}
                        >
                          <Input
                            disabled={
                              this.state.rfid
                                ? false
                                : true || this.state.setDisable
                            }
                            value={this.state.rfidCharge}
                            onChange={async (e) => {
                              this.chargesValidator({
                                e: e,
                                charge: "rfidCharge",
                              });

                              // await this.setState({ rfidCharge: e.target.value });
                              // let value = e.target.value.replace(/[^0-9.]*/g, "");
                              // if (value === "") {
                              //   await this.setState({ rfidCharge: 0 });
                              //   await this.formRef.current.setFieldsValue({
                              //     rfidCharge: undefined,
                              //   });
                              //   // this.fillCharges();
                              //   // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                              // } else {
                              //   const numericValue = parseFloat(value);
                              //   await this.formRef.current.setFieldsValue({
                              //     rfidCharge: value,
                              //   });
                              //   // this.fillCharges();
                              // }
                              // await this.fillCharges();
                            }}
                          />
                        </Form.Item>
                        {/* </div> */}

                        {/* <div style={{ display: this.state.fuelSensor ? "" : "none" }}> */}
                        <Form.Item
                          name="fuelSensorCharge"
                          className={!this.state.fuelSensor && "disabledLabel"}
                          label={
                            <label>
                              {" "}
                              <Checkbox
                                checked={this.state.fuelSensor}
                                onChange={async (e) => {
                                  await this.setState({
                                    fuelSensor: e.target.checked,
                                  });
                                  this.fillCharges();
                                }}
                              >
                                {/* Fuel Sensor Charges{" "} */}
                                {this.context.translate(
                                  "_FUEL_SENSOR_CHARGES_"
                                )}
                              </Checkbox>
                            </label>
                          }
                          rules={[{ type: Number }]}
                        >
                          <Input
                            disabled={
                              this.state.fuelSensor
                                ? false
                                : true || this.state.setDisable
                            }
                            value={this.state.fuelSensorCharge}
                            onChange={async (e) => {
                              this.chargesValidator({
                                e: e,
                                charge: "fuelSensorCharge",
                              });

                              // await this.setState({
                              //   fuelSensorCharge: e.target.value,
                              // });
                              // let value = e.target.value.replace(/[^0-9.]*/g, "");
                              // if (value === "") {
                              //   await this.setState({ fuelSensorCharge: 0 });
                              //   await this.formRef.current.setFieldsValue({
                              //     fuelSensorCharge: undefined,
                              //   });
                              //   // await this.fillCharges();

                              //   // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                              // } else {
                              //   const numericValue = parseFloat(value);
                              //   await this.formRef.current.setFieldsValue({
                              //     fuelSensorCharge: value,
                              //   });
                              //   // this.fillCharges();
                              // }
                              // await this.fillCharges();
                            }}
                          />
                        </Form.Item>
                        {/* </div> */}

                        {/* <div
                    style={{
                      display: this.state.temperatureSensor ? "" : "none",
                    }}
                  > */}
                        <Form.Item
                          name="temperatureSensorCharge"
                          className={
                            !this.state.temperatureSensor && "disabledLabel"
                          }
                          label={
                            <label>
                              {" "}
                              <Checkbox
                                checked={this.state.temperatureSensor}
                                onChange={async (e) => {
                                  await this.setState({
                                    temperatureSensor: e.target.checked,
                                  });
                                  await this.fillCharges();
                                }}
                              >
                                {/* Temperature Sensor Charges{" "} */}
                                {this.context.translate(
                                  "_TEMPERATURE_SENSOR_CHARGE_"
                                )}
                              </Checkbox>
                            </label>
                          }
                          rules={[{ type: Number }]}
                        >
                          <Input
                            disabled={
                              this.state.temperatureSensor
                                ? false
                                : true || this.state.setDisable
                            }
                            value={this.state.temperatureSensorCharge}
                            onChange={async (e) => {
                              this.chargesValidator({
                                e: e,
                                charge: "temperatureSensorCharge",
                              });

                              // await this.setState({
                              //   temperatureSensorCharge: e.target.value,
                              // });
                              // let value = e.target.value.replace(/[^0-9.]*/g, "");
                              // if (value === "") {
                              //   await this.setState({
                              //     temperatureSensorCharge: 0,
                              //   });
                              //   await this.formRef.current.setFieldsValue({
                              //     temperatureSensorCharge: undefined,
                              //   });
                              // //  await this.fillCharges();
                              //   // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                              // } else {
                              //   const numericValue = parseFloat(value);
                              //   await this.formRef.current.setFieldsValue({
                              //     temperatureSensorCharge: value,
                              //   });
                              //   console.log(value)
                              // //  await this.fillCharges();
                              // }
                              // await this.fillCharges();
                            }}
                          />
                        </Form.Item>
                        {/* </div> */}

                        {/* <div style={{ display: this.state.canbus ? "" : "none" }}> */}
                        <Form.Item
                          name="canbus"
                          className={!this.state.canbus && "disabledLabel"}
                          label={
                            <label>
                              {" "}
                              <Checkbox
                                checked={this.state.canbus}
                                onChange={async (e) => {
                                  await this.setState({
                                    canbus: e.target.checked,
                                  });
                                  await this.fillCharges();
                                }}
                              >
                                {/* Canbus{" "} */}
                                {this.context.translate("_CANBUS_")}
                              </Checkbox>
                            </label>
                          }
                          rules={[{ type: Number }]}
                        >
                          <Input
                            disabled={
                              this.state.canbus
                                ? false
                                : true || this.state.setDisable
                            }
                            value={this.state.canbusCharge}
                            onChange={async (e) => {
                              await this.setState({
                                canbusCharge: e.target.value,
                              });
                              let value = e.target.value.replace(
                                /[^0-9.]*/g,
                                ""
                              );
                              if (value === "") {
                                await this.setState({ canbusCharge: 0 });
                                await this.formRef.current.setFieldsValue({
                                  canbus: undefined,
                                });
                                // this.fillCharges();
                                // await this.formRef.current.setFieldsValue({ totalCharge: "" });
                              } else {
                                const numericValue = parseFloat(value);
                                await this.formRef.current.setFieldsValue({
                                  canbus: value,
                                });
                                // this.fillCharges();
                              }
                              await this.fillCharges();
                            }}
                          />
                        </Form.Item>
                        {/* </div> */}

                        {/* <div style={{ display: this.state.driverId ? "" : "none" }}> */}
                        <Form.Item
                          name="driverIdCharge"
                          className={!this.state.driverId && "disabledLabel"}
                          label={
                            <label>
                              {" "}
                              <Checkbox
                                checked={this.state.driverId}
                                onChange={async (e) => {
                                  await this.setState({
                                    driverId: e.target.checked,
                                  });
                                  await this.fillCharges();
                                }}
                              >
                                {/* DriverId Charges{" "} */}
                                {this.context.translate("_DRIVERID_CHARGES_")}
                              </Checkbox>
                            </label>
                          }
                          rules={[{ type: Number }]}
                        >
                          <Input
                            disabled={
                              this.state.driverId
                                ? false
                                : true || this.state.setDisable
                            }
                            value={this.state.driverIdCharge}
                            onChange={async (e) => {
                              this.chargesValidator({
                                e: e,
                                charge: "driverIdCharge",
                              });
                              // await this.setState({
                              //   driverIdCharge: e.target.value,
                              // });
                              // let value = e.target.value.replace(/[^0-9.]*/g, "");
                              // console.log( typeof value.length,"val")
                              // if (value.length === 0 || value===null) {
                              //   await this.setState({ driverIdCharge: 0 });
                              //   await this.formRef.current.setFieldsValue({
                              //     driverIdCharge: undefined,
                              //   });
                              //   // this.fillCharges();
                              //   // await this.formRef.current.setFieldsValue({ totalCharge: undefined });
                              // } else {
                              //   const numericValue = parseFloat(value);
                              //   await this.formRef.current.setFieldsValue({
                              //     driverIdCharge: value,
                              //   });
                              //   await this.setState({driverIdCharge:value})
                              //   // this.fillCharges();
                              // }
                              // await this.fillCharges();
                            }}
                          />
                        </Form.Item>
                        {/* </div> */}
                      </div>

                      <Form.Item
                        name="totalCharge"
                        // label="Total Charges"
                        label={this.context.translate("_TOTAL_CHARGES_")}
                        rules={[{ type: Number }]}
                      >
                        <Input
                          disabled={this.state.setDisable}
                          value={this.state.totalCharge}
                          // value={parseFloat(this.state.totalCharge).toFixed(2)}
                          onChange={(e) => {
                            let value = e.target.value.replace(/\D/g, "");
                            this.setState({ totalCharge: value });
                            this.fillCharges();
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <div className="formBtnGroup">
                    <Button
                      className="formCancelButton"
                      onClick={this.handleBackClick}
                    >
                      {/* Back */}
                      {this.context.translate(
                        "_FLEET_USER_USERLIST_ASSIGNVEHICLE_BACKBTN_"
                      )}
                    </Button>
                    <Button
                      type="primary"
                      className="formSaveButton"
                      htmlType="submit"
                    >
                      {/* Submit */}
                      {this.context.translate("_SUBMIT_")}
                    </Button>
                  </div>
                </TabPane>
              </Tabs>
            </Form>
          </Spin>
        </div>

        {this.state.addressMap && (
          <Modal
            className="multiTracking-popup"
            style={{ padding: "0px !important" }}
            // title=<div>
            //   Location On Map
            //   <CloseOutlined
            //     onClick={() => this.setState({ addressMap: false })}
            //   />
            // </div>
            title=<div>
              Location On Map
              <CloseOutlined
                onClick={() => this.setState({ addressMap: false })}
              />
            </div>
            // title={this.context.translate("_LOCATION_ON_MAP_")}
            maskClosable={false}
            centered
            bodyStyle={{ height: "60vh", padding: "0px" }} // Adjust the dimensions as needed
            width={1000}
            visible={this.state.addressMap}
            onCancel={() => this.setState({ addressMap: false })}
            footer={null}
          >
            <DisplayLocationMap
              addressInfo={
                this.state.addressInfo?.length > 0 && this.state.addressInfo
              }
              formRef={this.formRef}
              address={(address) =>
                this.formRef.current.setFieldsValue({
                  companylocation: address,
                  city: "",
                  state: "",
                })
              }
            />
          </Modal>
        )}
      </div>
    );
  }
}
AddAccount.contextType = StoreContext;
export default observer(withRouter(AddAccount));
