import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Typography,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import config from "../config";
import MaintenanceFleetReportStore from "../store/MaintenanceFleetReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import withRouter from "../withRouter";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};
const { Option } = Select;
class MaintenanceFleetReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.maintenanceFleetReportStore = new MaintenanceFleetReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],
      selectAllVehicles: false,
      selectAllServices: false,
      selectedServices: [],
      companyId: "",
      loadData: 0,
      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,
      Notasset: false,
      selectedColumns: widthAdderFunc([
        {
          // title: "Vehicle No ",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          // fixed: "left",
          // width:'15%'
        },
        {
          // title: "Vehicle Type",
          title: context.translate("_VEHICLE_TYPE_"),
          dataIndex: "type",
          key: "Vehicle Type",

          // fixed: "left",
          // width:'15%'
        },

        {
          // title: "Driver Name",
          title: context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
          ),
          dataIndex: "driverName",
          key: "driverName",
        },

        {
          // title: "Current Odometer Value",
          title: context.translate(
            "_FLEET_REPORTS_MAINTENANCEREPORT_CURRENTODOMETER_"
          ),
          dataIndex: "distance",
          key: "CurrentOdometerValue",
        },
        {
          // title: "Service Type",
          title: context.translate("_SERVICE_TYPE_"),
          dataIndex: "serviceName",
          key: "serviceName",
        },
        {
          // title: "Service Date",
          title: context.translate("_SERVICE_DATE_"),
          dataIndex: "serviceDate",
          key: "serviceDate",
        },
        {
          // title: "Odometer",
          title: context.translate("_FLEET_DASHBORD_SETTING_ODOMETER_"),
          dataIndex: "currentOdometer",
          key: "currentOdometer",
        },
        {
          // title: "  Garage Name",
          title: context.translate("_GARAGE_NAME_"),
          dataIndex: "garageName",
          key: "garageName",
        },

        {
          // title: "Garage Address  ",
          title: context.translate("_GARAGE_ADDRESS_"),
          dataIndex: "garageAddress",
          key: "garageAddress",
        },
        {
          // title: " Extra Works Done",
          title: context.translate("_EXTRA_WORK_DONE_"),
          dataIndex: "extraWorksDone",
          key: "extraWorksDone",
        },
        {
          // title: " Amount ",
          title: context.translate("_AMOUNT_"),
          dataIndex: "totalAmmount",
          key: "totalAmmount",

          // fixed: 'right',
        },
        {
          // title: "Last Oil Change(Date/Time and Odometer)  ",
          title: context.translate(
            "_FLEET_REPORTS_MAINTENANCEREPORT_LASTOILCHANGE_"
          ),
          dataIndex: "lastOilDate",
          key: "lastOilDate",

          // fixed: 'right',
        },
        {
          // title: "Last Tyre Change(Date/Time and Odometer)  ",
          title: context.translate("_LAST_TYRE_CHANGE_"),
          dataIndex: "lastTireDate",
          key: "lastTireDate",

          //fixed: 'right',
        },
        {
          // title: "Last Major Service(Date/Time and Odometer)  ",
          title: context.translate(
            "_FLEET_REPORTS_MAINTENANCEREPORT_LASTMAJORSERVICE_"
          ),
          dataIndex: "lastServiceDate",
          key: "lastServiceDate",

          // fixed: 'right',
        },
        {
          // title: "Next Oil Change (Km) ",
          title: context.translate(
            "_FLEET_REPORTS_MAINTENANCEREPORT_NEXTOILCHANGE_"
          ),
          dataIndex: "nextOil",
          key: "Next Oil Change",

          // fixed: 'right',
        },
        {
          // title: "Next Tyre Change (Km) ",
          title: context.translate("_NEXT_TYRE_CHANGE_KM_"),
          dataIndex: "nextTire",
          key: "Next Tire Change",

          // fixed: 'right',
        },
        {
          // title: "Next Major Service Change(Km)  ",
          title: context.translate(
            "_FLEET_REPORTS_MAINTENANCEREPORT_NEXTMAJORSERVICE_"
          ),
          dataIndex: "nextService",
          key: "Next Major Service",

          // fixed: 'right',
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        // },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            sid: this.state.selectedServices,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.maintenanceFleetReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.maintenanceFleetReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.maintenanceFleetReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.maintenanceFleetReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    await this.maintenanceFleetReportStore.loadData({ offset: 0, limit: 50 });

    await this.maintenanceFleetReportStore.getServiceData();

    this.setState({ pageSize: 50 });

    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({
      selectAllVehicles: false,
    });

    await this.handleAllSelectVehicles();
    await this.setState({ companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    console.log(this.vehicleStore.getVehiclesUsingCompanyId);
    await this.setState({ loadData: 1 });
    // await this.handleSearch();
  }

  async handleSearch() {
    await this.maintenanceFleetReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      await this.maintenanceFleetReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        serviceId: this.state.selectedServices,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleSelectAllServices = async (e) => {
    if (this.state.selectAllServices) {
      const allServices =
        this.maintenanceFleetReportStore?.maintenanceService.map((val) => {
          return val.serviceId;
        });
      await this.setState({ selectedServices: allServices });
    } else {
      await this.setState({ selectedServices: [] });
    }
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Maintenance Fleet Report</div> */}
              <div className="heading">
                {this.context.translate(
                  "_FLEET_REPORTS_MAINTENANCEREPORT_TITLE_"
                )}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.maintenanceFleetReportStore?.maintenanceReportStore
                      ?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button
                    className="exportDropdown"
                    //  style={{backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {this.setState({ selectedVehicles: val });
                  if(this.vehicleStore?.getVehiclesUsingCompanyId.length>val.length){
                    this.setState({selectAllVehicles:false})
                  }
                  else{
                    this.setState({selectAllVehicles:true})
                  }
                }}
                  onSearch={this.handleSearch}
                  // onChange={(val) => this.vehicleSelect(val)}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 160 }}
                /> */}
                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <Checkbox
                  checked={this.state.selectAllServices}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllServices: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleSelectAllServices();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  value={this.state.selectedServices}
                  onChange={(val) => {
                    this.setState({ selectedServices: val });
                    if (
                      this.maintenanceFleetReportStore?.maintenanceService
                        .length > val.length
                    ) {
                      this.setState({ selectAllServices: false });
                    } else {
                      this.setState({ selectAllServices: true });
                    }
                  }}
                  onSearch={this.handleSearch}
                  // onChange={(val) => this.vehicleSelect(val)}
                  fieldNames={{ label: "serviceName", value: "serviceId" }}
                  // placeholder="Service Type"
                  placeholder={this.context.translate("_SERVICE_TYPE_")}
                  optionFilterProp="children"
                  options={this.maintenanceFleetReportStore?.maintenanceService}
                  style={{ width: 160 }}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={400}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.maintenanceFleetReportStore?.total}
            columns={this.state.column}
            dataSource={
              this.maintenanceFleetReportStore?.maintenanceReportStore
            }
            loading={this.maintenanceFleetReportStore?.loading}
            onChange={this.handleTableChange}
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: 280,
            }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
        </div>
      </>
    );
  }
}
MaintenanceFleetReportList.contextType = StoreContext;
export default observer(withRouter(MaintenanceFleetReportList));
