import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Input,
  Modal,
  Checkbox,
  Row,
  Col,
  Table,
  Space,
  Tooltip,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";
import AlertStore from "../store/AlertStore";
import VehicleStore from "../store/VehicleStore";

import { format, addMinutes } from "date-fns";
import CompanyStore from "../store/CompanyStore";
import config from "../config";
import { DeleteFilled } from "@ant-design/icons";
import EmailReportStore from "../store/EmailReportStore";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import AlertSelectorFields from "./Plugins/AddAlerts/AlertSelectorFields";
class AlertAdd extends Component {
  formRef = React.createRef();
  assignedformRef = React.createRef();
  constructor(props, context) {
    super(props);

    this.state = {
      closeWarningModal: false,
      loading: false,
      alertSettingShow: false,
      SetDropSpeedLimit: false,
      companyId: "",
      getVehiclesNo: "",
      vehiclesNo: "",
      RuleSelector: [],
      loadData: 0,
      selectAllVehicles: false,
      emailSubCheckBx: [],
      sendpushnotification: false,
      webpushnotification: false,

      ruleSelectorId17: false,
      SetAccelrSpeedLimit: false,
      Rule3SetVehicleSpeed: false,
      Rule34SetVehicleSpeed: false,
      Rule4FromTime: false,
      Rule5SetTimeLimit: false,
      Rule7SetSpeedLimit: false,
      Rule10SetMinTemp: false,
      Rule15geofencename: false,
      Rule16Assigndist: false,
      ServiceReminder: false,
      SendLastPositionButton: false,
      ruleSelectorId20: false,
      ruleSelectorId21: false,
      ruleSelectorId23: false,
      Rule18SelInterval: false,
      ruleSelectorId24: false,
      RoadOverspeed: false,
      Rule26degreecentigrade: false,
      ruleSelectorId27: false,
      ruleSelectorId28: false,
      ruleSelectorId29: false,
      ruleSelectorId30: false,
      ruleSelectorId31: false,
      Rule32TimeLimit: false,
      Rule33geofencename: false,
      ruleSelectorId35: false,
      ruleSelectorId37: false,
      BLEFromTime: false,
      nonStoppageMin: false,
      ruleSelectorId25: false,

      ruleSelectorId34: false,
      ruleSelectorId36: false,
      ruleSelectorId37: false,
      ruleSelectorId38: false,
      ruleSelectorId39: false,

      bletemp1: false,
      bletemp2: false,
      bletemp3: false,
      bletemp4: false,

      blehumidity1: false,
      blehumidity2: false,
      blehumidity3: false,
      blehumidity4: false,

      RuleSelector: null,
      Id: "",
      ruleSelectorId39: false,
      ruleSelectorId40: false,
      Rule41geofencename: false,

      stoppedOutOfGeofence: false,
      ruleId: 0,
      saveSettingChanges: false,
      loadingSpin: false,

      openAssignVehicleModal: false,

      assignVehiclesArray: [],
      searchVehicleArray: [],
      selectedAssignedVehicleId: [],
      selectedSearchVehicleId: [],
    };
    this.alertStore = new AlertStore();
    this.vehicleStore = new VehicleStore();
    this.companyStore = new CompanyStore(this);
    this.alertSettingPopupFormRef = React.createRef();
    this.emailReportStore = new EmailReportStore(this);

    this.columns = [
      {
        title: context.translate("_VEHICLE_"),
        dataIndex: "vehicleNo",
      },
    ];

    this.assignedColumns = [
      {
        title: context.translate("_ASSIGNED_VEHICLES_"),
        dataIndex: "vehicleNo",
      },
      {
        title: context.translate("_REMOVE_"),
        dataIndex: "action",
        render: (text, record, index) => {
          // console.log(text, record, index);
          return (
            <>
              <Space>
                <Tooltip
                  // title="Remove"
                  title={this.context.translate("_REMOVE_")}
                  color={config.tooltipColorDelete}
                >
                  <DeleteFilled
                    style={{ color: config.deleteColor }}
                    onClick={async () => {
                      await this.onDeleteRow(index, Number(record.vehicleId));
                    }}
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }

  onDeleteRow = (index) => {
    const filterData = this.state.assignVehiclesArray.filter(
      (val, indexx) => indexx !== index
    );

    const selectedAssignedVehicleId = filterData.map((item) => item.vehicleId);
    const selectedSearchVehicleId = this.state.selectedSearchVehicleId.filter(
      (id) => selectedAssignedVehicleId.includes(id)
    );

    this.setState({
      assignVehiclesArray: filterData,
      selectedAssignedVehicleId: selectedAssignedVehicleId,
      selectedSearchVehicleId: selectedSearchVehicleId,
    });
  };

  async componentDidMount() {
    await this.setState({ loadList: 1 });
    console.log(this.props.getUserData().userCompanyId);

    if (Number(this.props.getUserData().isadmin) !== -1) {
      await this.context.viewStateStore.setAlertStates({
        companyId: this.props.getUserData().userCompanyId,
      });

      this.setState({ companyId: this.props.getUserData().userCompanyId });
      // const emailData = await this.companyStore.getCompanyData({
      //   companyId: this.props.getUserData().userCompanyId,
      // });
      // console.log(emailData);
      // this.formRef.current.setFieldsValue({
      //   notificationEmail: emailData[0].contact1Email,
      //   notifyMobile: emailData[0].contact1No,
      // });
      const allVehicles = await this.alertStore.getAllVehicles(
        this.props.getUserData().userCompanyId
      );

      await this.setState({
        searchVehicleArray: this.alertStore.vehicleListArr,
      });

      await this.alertStore.getVehicles(this.props.getUserData().userCompanyId);
    }
    await this.setState({ loadList: 1 });
    const Id = this.props.ruleId;
    console.log(Id);

    if (Id) {
      this.fillData(Id);
      this.setState({ Id: Id });
    }
    await this.setState({ loadList: 1 });
  }

  async fillData(Id) {
    // await this.setState({ loadingSpin: true });

    const getAlertDetails = await this.alertStore.getAlertData(Id);

    const allVehicles = await this.alertStore.getAllVehicles(
      getAlertDetails.companyId
    );

    await this.setState({ searchVehicleArray: this.alertStore.vehicleListArr });

    const vehicleData = getAlertDetails.vehicleId?.map((val) => val) || [];

    const assignedVehiclesArray = allVehicles.filter((vehicle) =>
      vehicleData.includes(vehicle.vehicleId)
    );

    // this.setState({assignVehiclesArray:assignedVehiclesArray})

    await this.setState({
      assignVehiclesArray: assignedVehiclesArray,

      selectedAssignedVehicleId: vehicleData,
    });

    await this.alertStore.getVehicles(getAlertDetails.companyId);
    const singleData = await this.companyStore.getCompanyDataById(
      getAlertDetails.companyId
    );
    await this.setState({
      companyId: singleData[0].value,
      ruleId: getAlertDetails.ruleId,
    });

    // console.log(JSON.parse(getAlertDetails.ruleselector[3].params))

    await this.formRef.current.setFieldsValue({
      companyId: singleData,
      ruleName: getAlertDetails.name,
      minimumInterval: getAlertDetails.minimumInterval,
      notificationEmail: getAlertDetails.notificationEmail,
      notifyMobile: getAlertDetails.notifyMobile,
      systemRule: getAlertDetails.systemRule,
      active: getAlertDetails.active,
      cronRule: getAlertDetails.cronRule,
      sendpushnotification: getAlertDetails.pushnotification,
      triggerAction: getAlertDetails.triggerAction,
      webpushnotification: getAlertDetails.webpushnotification,
      description: getAlertDetails.description,
      prefedinedActions: getAlertDetails.actions,
      eMailSubject: getAlertDetails.emailSubject,
      emailSubCheckBx: getAlertDetails.emailSubCheckBx,
      eMailMessage: getAlertDetails.emailMessage,
      smsMessage: getAlertDetails.smsMessage,
      vehicle: vehicleData,
    });
    // await this.setState({ loadingSpin: false });

    this.setState({ loadList: 1 });
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onAlertDetailSubmit = async (values) => {
    // this.setState({ loading: true });

    const selectedData = this.state.assignVehiclesArray.map((val) => {
      return val.vehicleId;
    });

    const data = {
      ...values,
      sendpushnotification: values.sendpushnotification ? 1 : 0,
      webpushnotification: values.webpushnotification ? 1 : 0,
      ruleSelector: { RuleSelector: this.state.RuleSelector },
      companyId: this.state.companyId,
      ruleId: this.state.ruleId,
      vehicle: selectedData,
    };
    console.log(this.state.RuleSelector);
    console.log(data);
    const response = await this.alertStore.addData(data);
    console.log(response);
    await this.props.response(response);

    this.setState({ loading: false });

    // if (response?.success === "S") {
    //   message.success(response.message);
    //   this.props.navigate("/alertlist");
    // } else {
    //   message.error(response.message);
    // }
  };
  onAlertSettingSubmit = (value) => {
    console.log(value);

    this.setState({ saveSettingChanges: true });
    const RuleSelector = [
      {
        RuleSelectorId: 1,
        Ruleparams: JSON.stringify({
          SetDropSpeedLimit: this.state.SetDropSpeedLimit
            ? value.SetDropSpeedLimit
            : "",
        }),
        enable: this.state.SetDropSpeedLimit ? true : false,
      },
      {
        RuleSelectorId: 2,
        Ruleparams: JSON.stringify({
          SetAccelrSpeedLimit: value.SetAccelrSpeedLimit,
        }),
        enable: this.state.SetAccelrSpeedLimit ? true : false,
      },
      {
        RuleSelectorId: 3,
        Ruleparams: JSON.stringify({
          Rule3SetVehicleSpeed: value.Rule3SetVehicleSpeed,
        }),
        enable: this.state.Rule3SetVehicleSpeed ? true : false,
      },
      {
        RuleSelectorId: 4,
        Ruleparams: JSON.stringify({
          Rule4FromTime: value.Rule4FromTime,
          Rule4ToTime: value.Rule4ToTime,
          SetSpeedLimit: value.SetSpeedLimit,
          Days: value.Days,
        }),
        enable: this.state.Rule4FromTime ? true : false,
      },
      {
        RuleSelectorId: 5,
        Ruleparams: JSON.stringify({
          Rule5SetTimeLimit: value.Rule5SetTimeLimit,
        }),
        enable: this.state.Rule5SetTimeLimit ? true : false,
      },

      // {
      //   RuleSelectorId: 6,
      //   Ruleparams: JSON.stringify({"Rule6SetTimeLimit":
      //     value.stoppedOutOfGeofence ? value.stoppedOutOfGeofence : ""
      //   }),
      //   enable: this.state.stoppedOutOfGeofence,
      // },

      {
        RuleSelectorId: 7,
        Ruleparams: JSON.stringify({
          Rule7SetSpeedLimit: value.Rule7SetSpeedLimit,
        }),
        enable: this.state.Rule7SetSpeedLimit ? true : false,
      },
      // {
      //   RuleSelectorId: 9,
      //   Ruleparams: JSON.stringify({"Rule9InputValue":null,"Rule9EventValue":null}),
      //   // enable: this.state.TemperatureMonitoringButton,
      // },
      {
        RuleSelectorId: 10,
        Ruleparams: JSON.stringify({
          Rule10SetMinTemp: value.Rule10SetMinTemp,
          Rule10SetMaxTemp: value.Rule10SetMaxTemp,
        }),
        enable: this.state.Rule10SetMinTemp ? true : false,
      },

      // extra fields

      {
        RuleSelectorId: 11,
        Ruleparams: JSON.stringify({ Rule11SetFuelincreaseLevel: null }),
        // enable: this.state.DriverSeatbeltButton,
      },
      {
        RuleSelectorId: 12,
        Ruleparams: JSON.stringify({ Rule12SetFuelDropLevel: null }),
        // enable: this.state.DriverSeatbeltButton,
      },
      {
        RuleSelectorId: 13,
        Ruleparams: JSON.stringify({ Rule13SelTimePeriod: "" }),
        // enable: this.state.DriverSeatbeltButton,
      },
      {
        RuleSelectorId: 14,
        Ruleparams: JSON.stringify({ Rule14SelTimePeriod: "" }),
        // enable: this.state.DriverSeatbeltButton,
      },

      // // rule15Obj.Ruleparams = Ext.encode;
      {
        RuleSelectorId: 15,
        Ruleparams: JSON.stringify({
          Rule15geofencename: value.Rule15geofencename,
          Rule15EventName: value.Rule15EventName,
          // GeofenceFromTime: value.GeofenceFromTime,
          // GeofenceToTime: value.GeofenceToTime,
        }),
        // "Rule15EventName": value.Rule15EventName ? value.Rule15EventName : "" ,
        enable: this.state.Rule15geofencename ? true : false ? true : false,
      },
      {
        RuleSelectorId: 16,
        Ruleparams: JSON.stringify({
          Rule16Assigndist: value.Rule16Assigndist,
        }),
        enable: this.state.Rule16Assigndist ? true : false,
      },
      {
        RuleSelectorId: 17,
        enable: this.state.ruleSelectorId17,
      },
      {
        RuleSelectorId: 18,
        Ruleparams: JSON.stringify({
          Rule18SelInterval: value.Rule18SelInterval,
        }),
        enable: this.state.Rule18SelInterval ? true : false,
      },

      // pass only in obj
      {
        RuleSelectorId: 19,
        Ruleparams: JSON.stringify({ Rule19ObdFault: false }),
        // enable: this.state.DriverSeatbeltButton,
      },
      {
        RuleSelectorId: 20,
        enable: this.state.ruleSelectorId20,
      },
      {
        RuleSelectorId: 21,
        enable: this.state.ruleSelectorId21,
      },
      {
        RuleSelectorId: 23,
        enable: this.state.ruleSelectorId23,
      },
      {
        RuleSelectorId: 24,
        enable: this.state.ruleSelectorId24,
      },
      {
        RuleSelectorId: 25,
        enable: this.state.ruleSelectorId25,
      },
      {
        RuleSelectorId: 26,
        Ruleparams: JSON.stringify({
          Rule26degreecentigrade: value.Rule26degreecentigrade,
        }),
        enable: this.state.Rule26degreecentigrade ? true : false,
      },
      {
        RuleSelectorId: 27,
        enable: this.state.ruleSelectorId27,
      },
      {
        RuleSelectorId: 28,
        enable: this.state.ruleSelectorId28,
      },
      {
        RuleSelectorId: 29,
        enable: this.state.ruleSelectorId29,
      },
      {
        RuleSelectorId: 30,
        enable: this.state.ruleSelectorId30,
      },
      {
        RuleSelectorId: 31,
        enable: this.state.ruleSelectorId31,
      },
      {
        RuleSelectorId: 32,
        Ruleparams: JSON.stringify({ Rule32TimeLimit: value.Rule32TimeLimit }),
        enable: this.state.Rule32TimeLimit ? true : false,
      },
      {
        RuleSelectorId: 33,
        Ruleparams: JSON.stringify({
          Rule33geofencename: value.Rule33geofencename
            ? value.Rule33geofencename
            : "",
          Rule33TimeLimit: value.Rule33TimeLimit ? value.Rule33TimeLimit : "",
        }),
        enable: this.state.Rule33geofencename ? true : false,
      },
      {
        RuleSelectorId: 34,
        enable: this.state.ruleSelectorId34,
      },
      {
        RuleSelectorId: 35,
        enable: this.state.ruleSelectorId35,
      },
      {
        RuleSelectorId: 36,
        enable: this.state.ruleSelectorId36,
      },
      {
        RuleSelectorId: 37,
        enable: this.state.ruleSelectorId37,
      },
      {
        RuleSelectorId: 38,
        enable: this.state.ruleSelectorId38,
      },
      {
        RuleSelectorId: 39,
        enable: this.state.ruleSelectorId39,
      },
      // {
      //   RuleSelectorId: 35,
      //   Ruleparams: JSON.stringify({
      //     Rule34SetVehicleSpeed: value.Rule34SetVehicleSpeed
      //       ? value.Rule34SetVehicleSpeed
      //       : "",
      //   }),
      //   enable: this.state.Rule34SetVehicleSpeed ? true : false,
      // },
      {
        RuleSelectorId: 40,
        Ruleparams: JSON.stringify({
          Rule41geofencename: value.Rule41geofencename,
        }),
        enable: this.state.Rule41geofencename ? true : false,
      },
      {
        RuleSelectorId: 41,
        Ruleparams: JSON.stringify({
          BLEFromTime: value.BLEFromTime,
          BLEToTime: value.BLEToTime,
          BLEMinTemp1: this.state.bletemp1 ? value.BLEMinTemp1 : "",
          BLEMaxTemp1: this.state.bletemp1 ? value.BLEMaxTemp1 : "",
          BLEMinTemp2: this.state.bletemp2 ? value.BLEMinTemp2 : "",
          BLEMaxTemp2: this.state.bletemp2 ? value.BLEMaxTemp2 : "",
          BLEMinTemp3: this.state.bletemp3 ? value.BLEMinTemp3 : "",
          BLEMaxTemp3: this.state.bletemp3 ? value.BLEMaxTemp3 : "",
          BLEMinTemp4: this.state.bletemp4 ? value.BLEMinTemp4 : "",
          BLEMaxTemp4: this.state.bletemp4 ? value.BLEMaxTemp4 : "",
          BLEMinHumidity1: this.state.blehumidity1 ? value.BLEMinHumidity1 : "",
          BLEMaxHumidity1: this.state.blehumidity1 ? value.BLEMaxHumidity1 : "",
          BLEMinHumidity2: this.state.blehumidity2 ? value.BLEMinHumidity2 : "",
          BLEMaxHumidity2: this.state.blehumidity2 ? value.BLEMaxHumidity2 : "",
          BLEMinHumidity3: this.state.blehumidity3 ? value.BLEMinHumidity3 : "",
          BLEMaxHumidity3: this.state.blehumidity3 ? value.BLEMaxHumidity3 : "",
          BLEMinHumidity4: this.state.blehumidity4 ? value.BLEMinHumidity4 : "",
          BLEMaxHumidity4: this.state.blehumidity4 ? value.BLEMaxHumidity4 : "",
        }),
        enable: this.state.BLEFromTime ? true : false,
      },
    ];

    console.log(RuleSelector);
    // let encodeRules=  base64_encode(JSON.stringify(postData));
    this.setState({ RuleSelector: RuleSelector });
    this.setState({ alertSettingShow: false });
  };

  handleChange = async (comp) => {
    await this.setState({ companyId: comp.value });
    if (!this.props.params.id) {
      this.context.viewStateStore.setAlertStates({ companyId: comp.value });
    }
    await this.setState({ companyId: comp.value });
    this.formRef.current.setFieldsValue({
      companyId: comp.value,
    });
    const data = await this.alertStore.getVehicles(comp.value);

    await this.alertStore.getAllVehicles(comp.value);

    this.setState({ searchVehicleArray: this.alertStore.vehicleListArr });
    await this.handleSearch();
    await this.setState({ loadData: 1 });
  };

  async handleSearch() {
    await this.alertStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  handleSelectAllVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.alertStore?.vehicleList.map((val) => {
        return val.vehicleId;
      });

      console.log(allVehicles);
      this.formRef.current.setFieldsValue({ vehicle: allVehicles });
    } else {
      this.formRef.current.setFieldsValue({ vehicle: [] });
    }
  };

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
    console.log();
    let endTime = new Date(2000, 0, 1, 24, 0);
    for (let time = startTime; time < endTime; time = addMinutes(time, 30)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm:ss");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }
    // console.log(timeArray);
    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "h:mm aa")}
        </Select.Option>
      );
    });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  onSearchVehicleNo = async (value) => {
    let inputData = value.toLowerCase();

    const data = this.alertStore.vehicleListArr.filter((vehicleNo) => {
      return vehicleNo.vehicleNo.toLowerCase().includes(inputData);
    });

    await this.setState({ searchVehicleArray: data });
  };

  onVehicleAssigned = async (values) => {
    console.log(
      this.state.assignVehiclesArray.map((val) => {
        return val.vehicleId;
      })
    );
  };

  getSubGroup = async (val) => {
    const subgroupData = await this.emailReportStore.getVehicleSubGroup({
      deviceGroupId: val,
      deviceSubGroupId: this.state.deviceSubGroupId,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      subgroup: val,
    });
    this.setState({ loadData: 1 });
  };

  getSubGroupVehicles = async (val) => {
    const subgroupData = await this.emailReportStore.getVehicleSubGroup({
      deviceGroupId: this.state.deviceGroupId,
      deviceSubGroupId: val,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });
    this.setState({ loadData: 1 });
  };

  handleEnableButton=(val,field)=>{
    // console.log(val)
    if(val == "ruleSelectorId34"){
      this.setState({
        ruleSelectorId34: !this.state.ruleSelectorId34
      })
    }
    if(val == "ruleSelectorId38"){
      this.setState({
        ruleSelectorId38: !this.state.ruleSelectorId38
      })
    }
    if(val == "ruleSelectorId39"){
      this.setState({
        ruleSelectorId39: !this.state.ruleSelectorId39
      })
    }
    if(val == "ruleSelectorId37"){
      this.setState({
        ruleSelectorId37: !this.state.ruleSelectorId37
      })
    }
    if(val == "ruleSelectorId36"){
      this.setState({
        ruleSelectorId36: !this.state.ruleSelectorId36
      })
    }
    if(val == "Rule34SetVehicleSpeed"){
      this.setState({
        Rule34SetVehicleSpeed: !this.state.Rule34SetVehicleSpeed
      })
    }
    if(val == "ruleSelectorId40"){
      this.setState({
        ruleSelectorId40: !this.state.ruleSelectorId40
      })
    }
    if(val == "nonStoppageMin"){
      this.setState({
        nonStoppageMin: !this.state.nonStoppageMin
      })
    }
    
  }
  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          {/* <Spin spinning={this.state.loadingSpin} fullscreen> */}
          <Form
            style={{ maxWidth: "100%", padding: 0 }}
            layout="vertical"
            ref={this.formRef}
            onFinish={this.onAlertDetailSubmit}
            // {...this.layout}
            name="nest-message"
            className="formClass"
          >
            {/* <h1>Rule Details</h1> */}

            {/* <div className="formBorder"> */}
            {/* <div className="headingTitle"> Rule Details</div> */}
            <Row className="overflowFormInputs">
              <Col span={12} className="formColumnClass">
                {(this.props.getUserData().isadmin === -1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={async (value, e) => {
                        await this.handleChange(value);
                        console.log(e);
                        this.formRef.current.setFieldsValue({
                          notificationEmail: e.contact1Email,
                          notifyMobile: e.contact1No,
                        });
                      }}
                      companyId={this.state.companyId}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                )}

                <Form.Item label=" " className="alertSelector">
                  {this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_RULESELECTOR_"
                  )}{" "}
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.setState({ alertSettingShow: true });
                      if (!this.state.saveSettingChanges) {
                        await this.alertStore?.alertRecordArray?.ruleselector?.forEach(
                          (val) => {
                            if (val.params) {
                              this.alertSettingPopupFormRef.current.setFieldsValue(
                                JSON.parse(val?.params)
                              );
                              this.setState(JSON.parse(val.params));
                              console.log(JSON.parse(val.params));
                              if (val.ruleSelectorId === 36) {
                                let x = JSON.parse(val.params);
                                if (
                                  x.BLEMinTemp1?.length > 0 ||
                                  x.BLEMaxTemp1?.length > 0
                                )
                                  this.setState({ bletemp1: true });
                                if (
                                  x.BLEMinTemp2?.length > 0 ||
                                  x.BLEMaxTemp2?.length > 0
                                )
                                  this.setState({ bletemp2: true });
                                if (
                                  x.BLEMinTemp3?.length > 0 ||
                                  x.BLEMaxTemp3?.length > 0
                                )
                                  this.setState({ bletemp3: true });
                                if (
                                  x.BLEMinTemp4?.length > 0 ||
                                  x.BLEMaxTemp4?.length > 0
                                )
                                  this.setState({ bletemp4: true });

                                if (
                                  x.BLEMinHumidity1?.length > 0 ||
                                  x.BLEMaxHumidity1?.length > 0
                                )
                                  this.setState({ blehumidity1: true });
                                if (
                                  x.BLEMinHumidity2?.length > 0 ||
                                  x.BLEMaxHumidity2?.length > 0
                                )
                                  this.setState({ blehumidity2: true });
                                if (
                                  x.BLEMinHumidity3?.length > 0 ||
                                  x.BLEMaxHumidity3?.length > 0
                                )
                                  this.setState({ blehumidity3: true });
                                if (
                                  x.BLEMinHumidity4?.length > 0 ||
                                  x.BLEMaxHumidity4?.length > 0
                                )
                                  this.setState({ blehumidity4: true });
                              }
                            } else {
                              let updatedRuleSelectedId =
                                "ruleSelectorId" + val.ruleSelectorId;
                              this.setState({
                                [updatedRuleSelectedId]: true,
                              });
                            }
                            console.log(val.ruleSelectorId);
                          }
                        );
                        await this.alertSettingPopupFormRef.current.setFieldsValue(
                          {}
                        );
                      }
                    }}
                  >
                    {this.context.translate("_ALERT_SETTING_")}
                  </Button>
                </Form.Item>

                {/* <Row>
                  <Col span={11}> */}
                <Form.Item
                  name="notificationEmail"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_NOTIFICATIONEMAIL_"
                  )}
                  rules={[
                    {
                      required: true,
                      type: "email",
                      // message: "Please enter a valid email address",
                      message: this.context.translate(
                        "_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS_"
                      ),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>

                {/* <Row>
                  <Col span={11}> */}
                <Form.Item
                  name="systemRule"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_SYSTEMRULE_"
                  )}
                >
                  <Select>
                    {/* <Select.Option value="AB Group">AB Group</Select.Option>
                            <Select.Option value="A S TWO ELECTRONICS TRADING">A S TWO ELECTRONICS TRADING</Select.Option>
                            <Select.Option value="3SC TECHNOLOGIES LLC">3SC TECHNOLOGIES LLC</Select.Option> */}
                  </Select>
                </Form.Item>

                <Form.Item
                  name="cronRule"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULELIST_CRONRULE_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Select>
                    {/* <Select.Option value="No">No</Select.Option>
                    <Select.Option value="5">5 Minute</Select.Option>
                    <Select.Option value="15">15 Minute</Select.Option>
                    <Select.Option value="30">30 Minute</Select.Option>
                    <Select.Option value="60">Hourly</Select.Option>
                    <Select.Option value="1440">Daily</Select.Option>
                    <Select.Option value="10080">Weekly</Select.Option> */}
                    <Select.Option value="No">
                      {this.context.translate("_FLEET_RULELIST_CRONRULE_NO_")}
                    </Select.Option>
                    <Select.Option value="5">
                      {this.context.translate("_FLEET_RULELIST_CRONRULE_5MIN_")}
                    </Select.Option>
                    <Select.Option value="15">
                      {this.context.translate(
                        "_FLEET_RULELIST_CRONRULE_15MIN_"
                      )}
                    </Select.Option>
                    <Select.Option value="30">
                      {this.context.translate(
                        "_FLEET_RULELIST_CRONRULE_30MIN_"
                      )}
                    </Select.Option>
                    <Select.Option value="60">
                      {this.context.translate(
                        "_FLEET_RULELIST_CRONRULE_HOURLY_"
                      )}
                    </Select.Option>
                    <Select.Option value="1440">
                      {this.context.translate(
                        "_FLEET_RULELIST_CRONRULE_DAILY_"
                      )}
                    </Select.Option>
                    <Select.Option value="10080">
                      {this.context.translate(
                        "_FLEET_RULELIST_CRONRULE_WEEKLY_"
                      )}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="description"
                  // label="Description"
                  label={this.context.translate(
                    "_FLEET_DASHBORD_ROUTELIST_DISCRIPTION_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="eMailSubject"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_EMAILSUBJECT_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                {/* <br/> */}

                <Form.Item
                  name="eMailMessage"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_EMAILMESSAGE_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="smsMessage"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_SMSMESSAGE_"
                  )}
                >
                  <Input />
                </Form.Item>

                <Form.Item label=" " className="alertSelector">
                  {this.context.translate("_VEHICLE_")}
                  <Button
                    type="primary"
                    onClick={async () => {
                      this.setState({ openAssignVehicleModal: true });
                      if (this.props.getUserData().isadmin === 1) {
                        await this.emailReportStore.getVehicleGroup({
                          companyId: JSON.parse(
                            base64_decode(Cookies.get("data"))
                          ).userCompanyId,
                          userId: JSON.parse(base64_decode(Cookies.get("data")))
                            .userId,
                          isadmin: JSON.parse(
                            base64_decode(Cookies.get("data"))
                          ).isadmin,
                        });
                        this.setState({loadData:1})
                      }
                    }}
                  >
                    {this.context.translate("_ASSIGN_VEHICLE_")}
                  </Button>
                </Form.Item>
              </Col>
              {/* <Col span={1}></Col> */}
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="ruleName"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_RULENAME_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="minimumInterval"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_MININTERVAL_"
                  )}
                  rules={[{ required: true }]}
                >
                  <Select>
                    <Select.Option value="10">10</Select.Option>
                    <Select.Option value="20">20</Select.Option>
                    <Select.Option value="30">30</Select.Option>
                    <Select.Option value="60">60</Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="notifyMobile"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_NOTIFICATIONMOBILE_"
                  )}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  name="active"
                  label={this.context.translate("_ACTIVE_")}
                  rules={[{ required: true }]}
                >
                  <Select>
                    {/* <Select.Option value="Yes">Yes</Select.Option>
                        <Select.Option value="No">No</Select.Option> */}
                    <Select.Option value="Yes">
                      {this.context.translate(
                        "_FLEET_RULEDETAIL_ACTIVEDDL_YES_"
                      )}
                    </Select.Option>
                    <Select.Option value="No">
                      {this.context.translate(
                        "_FLEET_RULEDETAIL_ACTIVEDDL_NO_"
                      )}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  // label="Notification"
                  label={this.context.translate("_NOTIFICATION_")}
                  valuePropName="checked"
                  className="label-seprator"
                  name="webpushnotification"
                  style={{ marginTop: "10px" }}
                  // className="formCheckboxCss"
                  // style={{marginTop:"30px",}}
                >
                  <Checkbox
                  // checked={this.state.webpushnotification}
                  // onChange={async (e) => {
                  //   await this.setState({
                  //     webpushnotification: e.target.checked,
                  //   });
                  //   console.log(e.target.checked);
                  // }}
                  >
                    {this.context.translate("_SEND_WEB_PUSH_NOTIFICATION_")}
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  // style={{ marginTop: "10px" }}
                  className="label-seprator"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_TRIGGERACTION_"
                  )}
                  // className="formCheckboxCss"
                  //  style={{height:"30px"}}
                  name="triggerAction"
                  rules={[{ required: true }]}
                >
                  <Checkbox.Group
                    options={[
                      {
                        // label: "Email",
                        label: this.context.translate("_FLEET_LOGIN_EMAILLBL_"),
                        value: "1",
                      },
                      {
                        // label: "Save/Alert",
                        label: this.context.translate(
                          "_FLEET_RULEDETAIL_TRIGGERACTION_SAVE_"
                        ),
                        value: "2",
                      },
                      {
                        // label: "Queue",
                        label: this.context.translate(
                          "_FLEET_RULEDETAIL_TRIGGERACTION_QUEUE_"
                        ),
                        value: "3",
                      },
                    ]}
                  />
                </Form.Item>

                <Form.Item
                  // label="Notification"
                  label={this.context.translate("_NOTIFICATION_")}
                  name="sendpushnotification"
                  className="label-seprator"
                  rules={[
                    {
                      validator: (rule, value) => {
                        if (!value) {
                          return Promise.reject(
                            this.context.translate(
                              "PLEASE_CHECK_SEND_PUSH_NOTIFICATION_"
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  // style={{ marginTop: "10px" }}
                  valuePropName="checked"
                >
                  <Checkbox
                  // checked={this.state.sendpushnotification}
                  // onChange={(e) => {
                  //   this.setState({ sendpushnotification: e.target.checked });
                  //   this.formRef.current.setFieldsValue({
                  //     sendpushnotification: e.target.checked,
                  //   });
                  // }}
                  >
                    {this.context.translate("_SEND_PUSH_NOTIFICATION_")}
                  </Checkbox>
                </Form.Item>

                <Form.Item
                  name="prefedinedActions"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_PREDEFINEACTION_"
                  )}
                  style={{ margin: "-8px 0 0 0" }}
                >
                  <Select>
                    <Select.Option value="Select">
                      {this.context.translate(
                        "_FLEET_COMPANY_COMPANYDETAIL_SELECT_"
                      )}
                    </Select.Option>
                    <Select.Option value="Send command Immobilization ON">
                      {/* Send command Immobilization ON */}
                      {this.context.translate(
                        "_FLEET_RULEDETAIL_PREDEFINEACTIONDDL_ON_"
                      )}
                    </Select.Option>
                    <Select.Option value="Send command Immobilization OFF">
                      {/* Send command Immobilization OFF */}
                      {this.context.translate(
                        "_FLEET_RULEDETAIL_PREDEFINEACTIONDDL_OFF_"
                      )}
                    </Select.Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="emailSubCheckBx"
                  className="select-parameter-label"
                  label={this.context.translate(
                    "_FLEET_RULEADMIN_RULEDETAIL_SELECTPARAMETER_"
                  )}
                  // className="formCheckboxCss"
                  // style={{marginTop:"12x",}}
                >
                  <Checkbox.Group value={this.state.emailSubCheckBx}>
                    <Checkbox
                      value="1"
                      onChange={(val) => {
                        console.log(val);
                      }}
                    >
                      {this.context.translate(
                        "_FLEET_RULEDETAIL_SELECTPARAMETER_VEHICLE_"
                      )}
                    </Checkbox>
                    <Checkbox value="2">
                      {this.context.translate("_SPEED_")}
                    </Checkbox>
                    <Checkbox value="3">
                      {this.context.translate("_DISTANCE_")}
                    </Checkbox>
                    <Checkbox value="4">
                      {this.context.translate("_LOCATION_")}
                    </Checkbox>
                    <Checkbox value="5">
                      {this.context.translate("_TEMPERATURE_")}
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>

                {/* <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Form.Item
                    name="vehicle"
                    // label="Vehicle No"
                    label={this.context.translate("_VEHICLE_NO_")}
                    rules={[{ required: true }]}
                    className="search-field"
                    style={{ width: "80%" }}
                    // style={{ width: "210px" }}
                  >
                    <Select
                      maxTagCount={"responsive"}
                      // placeholder="No Vehicles(Select By Groups)"
                      placeholder={this.context.translate(
                        "_FLEET_RULEADMIN_RULEDETAIL_VEHICLE_EMPTY_"
                      )}
                      mode="multiple"
                      showSearch
                      fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                      optionFilterProp="children"
                      options={this.alertStore?.vehicleList}
                    />
                  </Form.Item>
                  <Form.Item label=" ">
                    <Checkbox
                      checked={this.state.selectAllVehicles}
                      onChange={async (e) => {
                        await this.setState({
                          selectAllVehicles: e.target.checked,
                        });
                        console.log(e.target.checked);
                        await this.handleSelectAllVehicles();
                      }}
                    >
                     
                      {this.context.translate("_ALL_")}
                    </Checkbox>
                  </Form.Item>
                </div> */}
              </Col>
            </Row>
            <div className="formBtnGroup">
              {/* <Link to={"/alertlist"}>
                  {" "}
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}

              <Button
                // ghost
                // danger
                // type="primary"
                className="formCancelButton"
                onClick={(e) => {
                  this.closeModal();
                  // this.formRef.current?.resetFields();
                  this.setState({
                    sendpushnotification: false,
                    webpushnotification: false,
                    selectAllVehicles: false,
                  });
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLOSE_BTN_")}
              </Button>
              <Button
                className="formSaveButton"
                type="primary"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>

          <Modal
            maskClosable={false}
            // title="Alert Selector Details"
            title={this.context.translate("_FLEET_RULESELECTORDETAIL_TITLE_")}
            open={this.state.alertSettingShow}
            onCancel={() => this.setState({ closeWarningModal: true })}
            footer={null}
            width={500}
            style={{ maxHeight: "800px" }}
            // style={{
            //   top: 20,
            // }}
            // onOk={}
          >
            <Form
              style={{ maxWidth: "100%" }}
              layout="vertical"
              onFinish={this.onAlertSettingSubmit}
              onFinishFailed={(err) => console.log(err)}
              name="nest-message"
              ref={this.alertSettingPopupFormRef}
            >
              <div className="alert-form">
                {/* <h6 className="header">Harsh Braking</h6> */}
                <h6 className="header">
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE1_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      SetDropSpeedLimit: !this.state.SetDropSpeedLimit,
                    });
                  }}
                >
                  {/* {this.state.SetDropSpeedLimit ? "DISABLE" : "ENABLE"} */}
                  {this.state.SetDropSpeedLimit
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.SetDropSpeedLimit ? "" : "none",
                  }}
                >
                  {" "}
                  <Form.Item
                    name="SetDropSpeedLimit"
                    // label="Harsh Braking"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE1_TITLE_"
                    )}
                    rules={[{ required: this.state.SetDropSpeedLimit }]}
                  >
                    <Input
                      // placeholder="Set Drop Speed Limit(Km/Hr)"
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE1__SETSPEEDLIMIT_"
                      )}
                      style={{ width: 250 }}
                      HarshBraking
                    ></Input>
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered when sudden brake is applied by
                    driver. User can set the drop speed limit in km/hr. Rule
                    will be triggered when speed is dropped suddenly to the set
                    limit and user will be notified for same. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE1_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header">Harsh Acceleration</h6> */}
                <h6 className="header">
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE2_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      SetAccelrSpeedLimit: !this.state.SetAccelrSpeedLimit,
                    });
                  }}
                >
                  {/* {this.state.SetAccelrSpeedLimit ? "DISABLE" : "ENABLE"} */}
                  {this.state.SetAccelrSpeedLimit
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.SetAccelrSpeedLimit ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="SetAccelrSpeedLimit"
                    // label="Harsh Acceleration"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE2_TITLE_"
                    )}
                    rules={[{ required: this.state.SetAccelrSpeedLimit }]}
                  >
                    <Input
                      // placeholder="Set Accelerated Speed Limit(Km/Hr)"
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE2_SETACCERSPEEDLIMIT_"
                      )}
                      style={{ width: 250 }}
                    ></Input>
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered when sudden acceleration is applied
                    by driver. User can set the accelerated speed limit in
                    km/hr. Rule will be triggered when speed is accelerated
                    suddenly to the defined limit and user will be notified for
                    same. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE2_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header"> Driver Seatbelt</h6> */}
                <h6 className="header">
                  {this.context.translate(
                    "_FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT1_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule3SetVehicleSpeed: !this.state.Rule3SetVehicleSpeed,
                    });
                  }}
                >
                  {/* {this.state.Rule3SetVehicleSpeed ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule3SetVehicleSpeed
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule3SetVehicleSpeed ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule3SetVehicleSpeed"
                    // label="Driver Seatbelt"
                    label={this.context.translate(
                      "_FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT1_"
                    )}
                    rules={[{ required: this.state.Rule3SetVehicleSpeed }]}
                  >
                    <Input
                      // placeholder="Set Asset Speed Limit(Km/Hr)"
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE3_SETVEHICLESPEED_"
                      )}
                      style={{ width: 250 }}
                    ></Input>
                  </Form.Item>
                  <p className="paragraph">
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>

                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE3_DESCRIPTION_"
                    )}
                  </p>
                </div>

                
                {/* <h6 className="header">Working out of Hours</h6> */}
                <h6 className="header">
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE4_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule4FromTime: !this.state.Rule4FromTime,
                    });
                  }}
                >
                  {/* {this.state.Rule4FromTime ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule4FromTime
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <Col
                  span={22}
                  style={{
                    display: this.state.Rule4FromTime ? "" : "none",
                  }}
                >
                  <div style={{ width: "250px" }}>
                    <Form.Item
                      name="Rule4FromTime"
                      // label="From Time"
                      label={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                      )}
                      rules={[{ required: this.state.Rule4FromTime }]}
                    >
                      <Select>{this.timeOptions()}</Select>
                    </Form.Item>

                    <Form.Item
                      name="Rule4ToTime"
                      // label="To Time"
                      label={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE4_TOTIME_"
                      )}
                      rules={[{ required: this.state.Rule4FromTime }]}
                    >
                      <Select>{this.timeOptions()}</Select>
                    </Form.Item>

                    <Form.Item
                      name="SetSpeedLimit"
                      // label="Set Asset Speed Limit(Km/Hr)"
                      label={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE4_SPEEDLIMIT_"
                      )}
                      rules={[{ required: this.state.Rule4FromTime }]}
                    >
                      <Input></Input>
                    </Form.Item>

                    <Form.Item
                      name="Days"
                      // label="Days"
                      label={this.context.translate("_DAYS_")}
                      className="search-field"
                      rules={[{ required: this.state.Rule4FromTime }]}
                    >
                      <Select mode="multiple" maxTagCount={"responsive"}>
                        <Select.Option value="0">
                          {this.context.translate("_SUNDAY_")}
                        </Select.Option>
                        <Select.Option value="1">
                          {this.context.translate("_MONDAY_")}
                        </Select.Option>
                        <Select.Option value="2">
                          {this.context.translate("_TUESDAY_")}
                        </Select.Option>
                        <Select.Option value="3">
                          {this.context.translate("_WEDNESDAY_")}
                        </Select.Option>
                        <Select.Option value="4">
                          {this.context.translate("_THURSDAY_")}
                        </Select.Option>
                        <Select.Option value="5">
                          {this.context.translate("_FRIDAY_")}
                        </Select.Option>
                        <Select.Option value="6">
                          {this.context.translate("_SATURDAY_")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This rule is triggered when vehicle is driven out of working
                    hours with speed greater than defined speed limit. If speed
                    limit is not required user can set it as 0 at speed limit. */}

                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE4_DESCRIPTION_"
                    )}
                  </p>
                </Col>

                {/* <h6 className="header"> Asset Stopped Reporting</h6> */}
                <h6 className="header">
                  {" "}
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE5_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule5SetTimeLimit: !this.state.Rule5SetTimeLimit,
                    });
                  }}
                >
                  {/* {this.state.Rule5SetTimeLimit ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule5SetTimeLimit
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule5SetTimeLimit ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule5SetTimeLimit"
                    // label="Set Time Limit(Min)"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE5_SETTIMELIMIT_"
                    )}
                    rules={[
                      {
                        required: this.state.Rule5SetTimeLimit,
                        message: this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE5_SETTIMELIMIT_"
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE5_SETTIMELIMIT_"
                      )}
                      style={{ width: 250 }}
                    ></Input>
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc"> Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>

                    {/* This Rule is triggered when vehicle has stopped reporting
                    for defined time interval. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE5_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Stopped out of Geofence</h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      stoppedOutOfGeofence: !this.state.stoppedOutOfGeofence,
                    });
                  }}
                >
                  {this.state.stoppedOutOfGeofence ? "DISABLE" : "ENABLE"}
                </Button>

                <div
                  style={{
                    display: this.state.stoppedOutOfGeofence ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule6SetTimeLimit"
                    label="Rule6 SetTime Limit"
                    rules={[
                      {
                        required: this.state.stoppedOutOfGeofence,
                        message: "Set Time Limit(min)",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Set Time Limit(min)"
                      style={{ width: 250 }}
                    ></Input>
                  </Form.Item>
                  <p className="paragraph">
                    <div className="disc"> Description </div>
                    This Rule is triggered when vehicle has stopped out side geofence for defined time interval
                  </p>
                </div> */}

                {/* <h6 className="header">Overspeeding</h6> */}
                <h6 className="header">
                  {" "}
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE7_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule7SetSpeedLimit: !this.state.Rule7SetSpeedLimit,
                    });
                  }}
                >
                  {/* {this.state.Rule7SetSpeedLimit ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule7SetSpeedLimit
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule7SetSpeedLimit ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule7SetSpeedLimit"
                    // label="Set Speed Limit(Km/hr)"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE7_SETSPEEDLIMIT_"
                    )}
                    rules={[
                      {
                        required: this.state.Rule7SetSpeedLimit,
                        message: this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE7_SETSPEEDLIMIT_"
                        ),
                      },
                    ]}
                  >
                    <Input
                      // placeholder="Set Speed Limit(Km/hr)"
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE7_SETSPEEDLIMIT_"
                      )}
                      style={{ width: 250 }}
                    ></Input>
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc"> Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This rule is triggered when vehicle crosses defined speed
                    limit. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE7_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header">Temperature Monitoring</h6> */}
                <h6 className="header">
                  {" "}
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE9_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule10SetMinTemp: !this.state.Rule10SetMinTemp,
                    });
                  }}
                >
                  {/* {this.state.Rule10SetMinTemp ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule10SetMinTemp
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule10SetMinTemp ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule10SetMinTemp"
                    // label="Set Temperature Level(Min Temp)"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE9_SETTEMPERATURE_"
                    )}
                    rules={[{ required: this.state.Rule10SetMinTemp }]}
                  >
                    <Input style={{ width: 250 }}></Input>
                  </Form.Item>

                  <Form.Item
                    name="Rule10SetMaxTemp"
                    // label="Max Temp"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE9_MAXTEMP_"
                    )}
                    rules={[{ required: this.state.Rule10SetMinTemp }]}
                  >
                    <Input style={{ width: 250 }}></Input>
                  </Form.Item>

                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered when temperature goes beyond the
                    defined limit. User will set minimum and maximum
                    temperature. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE9_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Geofence Arrival/Departure</h6> */}
                <h6 className="header">
                  {" "}
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE12_TITLE_"
                  )}
                </h6>
                <h6 className="header"></h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule15geofencename: !this.state.Rule15geofencename,
                    });
                  }}
                >
                  {/* {this.state.Rule15geofencename ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule15geofencename
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule15geofencename ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule15geofencename"
                    // label="Select Geofences"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                    )}
                    className="search-field"
                    rules={[{ required: this.state.Rule15geofencename }]}
                  >
                    <Select
                      maxTagCount={"responsive"}
                      // placeholder="Select Geofences"
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                      )}
                      style={{ width: 250 }}
                      mode="multiple"
                      onChange={(val) => {
                        this.alertSettingPopupFormRef.current.setFieldsValue({
                          Rule15geofencename: val,
                        });
                        console.log(val);
                      }}
                      showSearch
                      fieldNames={{ value: "zoneId", label: "name" }}
                      optionFilterProp="children"
                      options={this.alertStore?.geofencesList}
                    />
                  </Form.Item>

                  <Form.Item
                    name="Rule15EventName"
                    // label="Select Event"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE8_EVENT_"
                    )}
                    rules={[{ required: this.state.Rule15geofencename }]}
                  >
                    <Select
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE8_EVENT_"
                      )}
                      // placeholder="Select Event"
                      style={{ width: 250 }}
                    >
                      <Select.Option value="Inside/Outside">
                        {/* Inside/Outside */}
                        {this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_INOUT_"
                        )}
                      </Select.Option>
                      {/* <Select.Option value="Inside">Inside</Select.Option>
                      <Select.Option value="Outside">Outside</Select.Option> */}
                      <Select.Option value="Inside">
                        {this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_IN_"
                        )}
                      </Select.Option>
                      <Select.Option value="Outside">
                        {this.context.translate(
                          "_FLEET_RULESELECTORDETAIL_RULE12_EVENTDDL_OUT_"
                        )}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  {/* <Form.Item
                    name="GeofenceFromTime"
                    // label="From Time"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                    )}
                    rules={[{ required: this.state.Rule15geofencename }]}
                  >
                    <Select style={{ width: 250 }}>{this.timeOptions()}</Select>
                  </Form.Item>

                  <Form.Item
                    name="GeofenceToTime"
                    // label="To Time"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE4_TOTIME_"
                    )}
                    rules={[{ required: this.state.Rule15geofencename }]}
                  >
                    <Select style={{ width: 250 }}>{this.timeOptions()}</Select>
                  </Form.Item> */}

                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered when Asset enters or exits assigned
                    geofence. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE12_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Distance travelled</h6> */}
                <h6 className="header">
                  {" "}
                  {this.context.translate("_TRIP_DISTANCE_TRAVELLED_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule16Assigndist: !this.state.Rule16Assigndist,
                    });
                  }}
                >
                  {/* {this.state.Rule16Assigndist ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule16Assigndist
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule16Assigndist ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule16Assigndist"
                    // label="Assign Distance (KM)"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE13_ASSIGNDISTANCE_"
                    )}
                    rules={[{ required: this.state.Rule16Assigndist }]}
                  >
                    <Input style={{ width: 250 }}></Input>
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered when vehicle has travelled given
                    distance. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE13_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Service Reminder</h6> */}
                <h6 className="header">
                  {" "}
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE14_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId17: !this.state.ruleSelectorId17,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId17 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId17
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId17 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="ServiceReminder"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered with Service has been expired. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE14_DESCRIPTION_"
                    )}
                  </p>
                </div>

                <h6 className="header">
                  {" "}
                  {/* Send Last Position at Fixed Interval */}
                  {this.context.translate(
                    "_FLEET_RULESELECTORDETAIL_RULE15_TITLE_"
                  )}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule18SelInterval: !this.state.Rule18SelInterval,
                    });
                  }}
                >
                  {/* {this.state.Rule18SelInterval ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule18SelInterval
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule18SelInterval ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule18SelInterval"
                    // label="Select Interval(Hour)"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE15_SELECTINTERVAL_"
                    )}
                    rules={[{ required: this.state.Rule18SelInterval }]}
                  >
                    <Input style={{ width: 250 }} />
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule will send latest position by email at fixed time
                    interval. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE15_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Insurance Renewal</h6> */}
                <h6 className="header">
                  {this.context.translate("_INSURANCE_RENEWAL_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId20: !this.state.ruleSelectorId20,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId20 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId20
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId20 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="InsuranceRenewal"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered with Tire has been expired. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_INSURANCE_RENEWAL_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header">Mulkiya Renewal</h6> */}
                <h6 className="header">
                  {this.context.translate("_MULKIYA_RENEWAL_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId21: !this.state.ruleSelectorId21,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId21 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId21
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId21 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="MulkiyaRenewal"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule is triggered with Oil has been expired. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_MULKIYA_RENEWAL_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header">Excessive Idle</h6> */}
                <h6 className="header">
                  {this.context.translate("_EXCESSIVE_IDLE_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId23: !this.state.ruleSelectorId23,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId23 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId23
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId23 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="ExcessiveIdle"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This rule will be triggered when vehicle idles beyond the
                    set limit defined in Vehicle page. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_EXCESSIVE_IDLE_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header"> Ignition</h6> */}
                <h6 className="header">
                  {this.context.translate("_IGNITION_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId24: !this.state.ruleSelectorId24,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId24 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId24
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{ display: this.state.ruleSelectorId24 ? "" : "none" }}
                >
                  {/* <Form.Item name="Ignition"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule will be triggered when vehicle's ignition is
                    switched On and Off. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_EXCESSIVE_IGINITION_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Road Overspeed</h6> */}
                <h6 className="header">
                  {this.context.translate("_ROAD_OVERSPEED_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId25: !this.state.ruleSelectorId25,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId25 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId25
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId25 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="RoadOverspeed"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Rule will be triggered when vehicle's speed is greater than road speed. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_ROAD_OVERSPEED_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Engine Temp</h6> */}
                <h6 className="header">
                  {this.context.translate("_ENGINE_TEMP_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule26degreecentigrade:
                        !this.state.Rule26degreecentigrade,
                    });
                  }}
                >
                  {/* {this.state.Rule26degreecentigrade ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule26degreecentigrade
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule26degreecentigrade ? "" : "none",
                  }}
                >
                  <Form.Item
                    label="Degree Centigrade"
                    // label={this.context.translate("")}
                    name="Rule26degreecentigrade"
                    rules={[
                      {
                        required: this.state.Rule26degreecentigrade,
                        message: this.context.translate(
                          "_PLEASE_ENTER_TEMPERATURE_"
                        ),
                      },
                    ]}
                  >
                    <Input placeholder="°C" style={{ width: 200 }} />
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This Alert will be triggered when Engine Temp exceeds
                    defined limit. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_ENGINE_TEMP_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header"> Vehicle Harsh Braking</h6> */}
                <h6 className="header">
                  {this.context.translate("_VEHICLE_HARSH_BRACKING_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId27: !this.state.ruleSelectorId27,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId27 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId27
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId27 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="VehicleHarshBraking"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alerts will be triggered based on event received from
                    Gps devices. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_VEHICLE_HARSH_BRAKING_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header">Vehicle Harsh Acceleration</h6> */}
                <h6 className="header">
                  {this.context.translate("_VEHICLE_HARSH_ACCEL_")}
                </h6>

                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId28: !this.state.ruleSelectorId28,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId28 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId28
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId28 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="VehicleHarshAcceleration"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc">Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alerts will be triggered based on event received from
                    Gps devices. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_VEHICLE_HARSH_BRAKING_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header">Vehicle Harsh Cornering</h6> */}
                <h6 className="header">
                  {this.context.translate("_VEHICLE_HARSH_CONNECTING_")}
                </h6>

                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId29: !this.state.ruleSelectorId29,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId29 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId29
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId29 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="VehicleHarshCornering"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alerts will be triggered based on event received from
                    Gps devices. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_VEHICLE_HARSH_BRAKING_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header">Panic</h6> */}
                <h6 className="header">
                  {this.context.translate("_FLEET_DASHBORD_SETTING_PANIC_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId30: !this.state.ruleSelectorId30,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId30 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId30
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{ display: this.state.ruleSelectorId30 ? "" : "none" }}
                >
                  {/* <Form.Item name="Panic"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alert will be triggered when panic button is pressed. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_PANIC_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header">Power Cut</h6> */}
                <h6 className="header">
                  {this.context.translate("_POWER_CUT_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId31: !this.state.ruleSelectorId31,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId31 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId31
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{ display: this.state.ruleSelectorId31 ? "" : "none" }}
                >
                  {/* <Form.Item name="PowerCut"></Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alert will be triggered when device main power gets
                    disconnected. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_POWER_CUT_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header">Parking Alert</h6> */}
                <h6 className="header">
                  {this.context.translate("_PARKING_ALERT_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule32TimeLimit: !this.state.Rule32TimeLimit,
                    });
                  }}
                >
                  {/* {this.state.Rule32TimeLimit ? "DISABLE" : "ENABLE"} */}

                  {this.state.Rule32TimeLimit
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule32TimeLimit ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule32TimeLimit"
                    // label="Time Limit"
                    label={this.context.translate("_TIME_LIMIT_")}
                    rules={[
                      {
                        required: this.state.Rule32TimeLimit,
                        message: this.context.translate(
                          "_PLEASE_ENTER_TIME_LIMIT_"
                        ),
                      },
                    ]}
                  >
                    <Input
                      // placeholder="Set Parking Time Limit (Min)"
                      placeholder={this.context.translate(
                        "_SET_PARKING_TIME_LIMIT_"
                      )}
                      style={{ width: 200 }}
                    />
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc">Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alert is generated if vehicle is parked for more than
                    defined time limit. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_PARKING_ALERT_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header">Stay Alert</h6> */}
                <h6 className="header">
                  {this.context.translate("_STAY_ALERT_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule33geofencename: !this.state.Rule33geofencename,
                    });
                  }}
                >
                  {/* {this.state.Rule33geofencename ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule33geofencename
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule33geofencename ? "" : "none",
                  }}
                >
                  <Form.Item
                    className="search-field"
                    name="Rule33geofencename"
                    label={this.context.translate("_GEOFENCE_NAME_")}
                    // label="Geofence Name"
                    rules={[
                      {
                        required: this.state.Rule33geofencename,
                        // message: "Please select geofence",
                        message: this.context.translate(
                          "_PLEASE_SELECT_GEOFENCE_"
                        ),
                      },
                    ]}
                  >
                    <Select
                      maxTagCount={"responsive"}
                      // placeholder="Select Geofences"
                      style={{ width: 200 }}
                      mode="multiple"
                      showSearch
                      fieldNames={{ value: "zoneId", label: "name" }}
                      optionFilterProp="children"
                      options={this.alertStore?.geofencesList}
                    />
                  </Form.Item>
                  <Form.Item
                    name="Rule33TimeLimit"
                    // label="Time Limit"
                    label={this.context.translate("_TIME_LIMIT_")}
                    className="search-field"
                    rules={[
                      {
                        required: this.state.Rule33geofencename,
                        // message: "Please enter time limit",
                        message: this.context.translate(
                          "_PLEASE_ENTER_TIME_LIMIT_"
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE5_SETTIMELIMIT_"
                      )}
                      // placeholder="Set Time Limit(Min)"
                      style={{ width: 200 }}
                    />
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc"> Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alert will be triggered if vehicle stays inside
                    selected geofence for more than defined time limit. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_STAY_ALERT_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header">Door Open/Close Alert</h6> */}
             <AlertSelectorFields This={this.state} handleEnableButton={this.handleEnableButton}/>

                {/* <h6 className="header"> Towing Alert</h6> */}
                <h6 className="header">
                  {this.context.translate("_TOWING_ALERT_")}
                </h6>
                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      ruleSelectorId35: !this.state.ruleSelectorId35,
                    });
                  }}
                >
                  {/* {this.state.ruleSelectorId34 ? "DISABLE" : "ENABLE"} */}
                  {this.state.ruleSelectorId35
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.ruleSelectorId35 ? "" : "none",
                  }}
                >
                  {/* <Form.Item name="TowingAlert"></Form.Item> */}
                  <p className="pragraph">
                    {/* <div className="disc"> Description </div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This alert will be triggered when vehicle is moving and its
                    ignition is off. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_TOWING_ALERT_DESCRIPTION_"
                    )}
                  </p>
                </div>
                {/* <h6 className="header"> Mulkiya Renewal Alert
</h6> */}
             

                {/* <h6 className="header"> Insurance Renewal Alert
</h6> */}
             

                {/* <h6 className="header"> Battery Change Alert
</h6> */}
           

                {/* <h6 className="header"> Service/Oil/Tyre Change Alert.

</h6> */}
              

                {/* <h6 className="header">BLE Temp/Humidity</h6> */}
                <h6 className="header">
                  {this.context.translate("_BLE_TEMP_HUMIDITY_")}
                </h6>

                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({ BLEFromTime: !this.state.BLEFromTime });
                  }}
                >
                  {/* {this.state.BLEFromTime ? "DISABLE" : "ENABLE"} */}
                  {this.state.BLEFromTime
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div style={{ display: this.state.BLEFromTime ? "" : "none" }}>
                  <Form.Item
                    name="BLEFromTime"
                    // label="From Time"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE4_FROMTIME_"
                    )}
                    rules={[{ required: this.state.BLEFromTime }]}
                  >
                    <Select style={{ width: 250 }}>{this.timeOptions()}</Select>
                  </Form.Item>

                  <Form.Item
                    name="BLEToTime"
                    // label="To Time"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE4_TOTIME_"
                    )}
                    rules={[{ required: this.state.BLEFromTime }]}
                  >
                    <Select style={{ width: 250 }}>{this.timeOptions()}</Select>
                  </Form.Item>

                  {/* <Form.Item name="BLETemp" label="BLE Temp"> */}
                  {/* <h6>BLETemp</h6> */}
                  <h6 className="header">
                    {this.context.translate("_BLE_TEMP_")}
                  </h6>

                  <Checkbox
                    checked={this.state.bletemp1}
                    onChange={async (e) => {
                      await this.setState({
                        bletemp1: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Temp1 */}
                    {this.context.translate("_BLE_TEMP1_")}
                  </Checkbox>

                  <div style={{ display: this.state.bletemp1 ? "" : "none" }}>
                    <Form.Item
                      name="BLEMinTemp1"
                      // label="Min Temp1"
                      label={this.context.translate("_MIN_TEMP1_")}
                      rules={[{ required: this.state.bletemp1 }]}
                    >
                      <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item
                      name="BLEMaxTemp1"
                      // label="Max Temp1"
                      label={this.context.translate("_MAX_TEMP1_")}
                      rules={[{ required: this.state.bletemp1 }]}
                    >
                      <Input style={{ width: 250 }} />
                    </Form.Item>
                  </div>

                  <br />
                  <Checkbox
                    checked={this.state.bletemp2}
                    onChange={async (e) => {
                      await this.setState({
                        bletemp2: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Temp2 */}
                    {this.context.translate("_BLE_TEMP2_")}
                  </Checkbox>
                  {this.state.bletemp2 && (
                    <>
                      <Form.Item
                        name="BLEMinTemp2"
                        // label="Min Temp2"
                        label={this.context.translate("_MIN_TEMP2_")}
                        rules={[{ required: this.state.bletemp2 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                      <Form.Item
                        name="BLEMaxTemp2"
                        // label="Max Temp2"
                        label={this.context.translate("_MAX_TEMP2_")}
                        rules={[{ required: this.state.bletemp2 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                    </>
                  )}
                  <br />
                  <Checkbox
                    checked={this.state.bletemp3}
                    onChange={async (e) => {
                      await this.setState({
                        bletemp3: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Temp3 */}
                    {this.context.translate("_BLE_TEMP3_")}
                  </Checkbox>
                  {this.state.bletemp3 && (
                    <>
                      <Form.Item
                        name="BLEMinTemp3"
                        // label="Min Temp3"
                        label={this.context.translate("_MIN_TEMP3_")}
                        rules={[{ required: this.state.bletemp3 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                      <Form.Item
                        name="BLEMaxTemp3"
                        // label="Max Temp3"
                        label={this.context.translate("_MAX_TEMP3_")}
                        rules={[{ required: this.state.bletemp3 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                    </>
                  )}
                  <br />
                  <Checkbox
                    checked={this.state.bletemp4}
                    onChange={async (e) => {
                      await this.setState({
                        bletemp4: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Temp4 */}
                    {this.context.translate("_BLE_TEMP4_")}
                  </Checkbox>
                  {this.state.bletemp4 && (
                    <>
                      <Form.Item
                        name="BLEMinTemp4"
                        // label="Min Temp4"
                        label={this.context.translate("_MIN_TEMP4_")}
                        rules={[{ required: this.state.bletemp4 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                      <Form.Item
                        name="BLEMaxTemp4"
                        // label="Max Temp4"
                        label={this.context.translate("_MAX_TEMP4_")}
                        rules={[{ required: this.state.bletemp4 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                    </>
                  )}
                  {/* </Form.Item> */}

                  {/* <h6>BLE Humidity</h6> */}
                  <h6 className="header">
                    {this.context.translate("_BLE_HUMIDITY_")}
                  </h6>
                  {/* <Form.Item name="BLEHumidity" label="BLE Humidity"> */}
                  <Checkbox
                    checked={this.state.blehumidity1}
                    onChange={async (e) => {
                      await this.setState({
                        blehumidity1: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Humidity1 */}
                    {this.context.translate("_BLE_HUMIDITY1_")}
                  </Checkbox>
                  {this.state.blehumidity1 && (
                    <>
                      <Form.Item
                        name="BLEMinHumidity1"
                        // label="Min Humidity1"
                        label={this.context.translate("_MIN_HUMIDITY1_")}
                        rules={[{ required: this.state.blehumidity1 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                      <Form.Item
                        name="BLEMaxHumidity1"
                        // label="Max Humidity1"
                        label={this.context.translate("_MAX_HUMIDITY1_")}
                        rules={[{ required: this.state.blehumidity1 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                    </>
                  )}
                  <br />
                  <Checkbox
                    checked={this.state.blehumidity2}
                    onChange={async (e) => {
                      await this.setState({
                        blehumidity2: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Humidity2 */}
                    {this.context.translate("_BLE_HUMIDITY2_")}
                  </Checkbox>
                  {this.state.blehumidity2 && (
                    <>
                      <Form.Item
                        name="BLEMinHumidity2"
                        // label="Min Humidity2"
                        label={this.context.translate("_MIN_HUMIDITY2_")}
                        rules={[{ required: this.state.blehumidity2 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                      <Form.Item
                        name="BLEMaxHumidity2"
                        // label="Max Humidity2"
                        label={this.context.translate("_MAX_HUMIDITY2_")}
                        rules={[{ required: this.state.blehumidity2 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                    </>
                  )}
                  <br />
                  <Checkbox
                    checked={this.state.blehumidity3}
                    onChange={async (e) => {
                      await this.setState({
                        blehumidity3: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Humidity3 */}
                    {this.context.translate("_BLE_HUMIDITY3_")}
                  </Checkbox>
                  {this.state.blehumidity3 && (
                    <>
                      <Form.Item
                        name="BLEMinHumidity3"
                        // label="Min Humidity3"
                        label={this.context.translate("_MIN_HUMIDITY3_")}
                        rules={[{ required: this.state.blehumidity3 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                      <Form.Item
                        name="BLEMaxHumidity3"
                        // label="Max Humidity3"
                        label={this.context.translate("_MAX_HUMIDITY3_")}
                        rules={[{ required: this.state.blehumidity3 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                    </>
                  )}
                  <br />
                  <Checkbox
                    checked={this.state.blehumidity4}
                    onChange={async (e) => {
                      await this.setState({
                        blehumidity4: e.target.checked,
                      });
                      console.log(e.target.checked);
                    }}
                  >
                    {/* BLE Humidity4 */}
                    {this.context.translate("_BLE_HUMIDITY4_")}
                  </Checkbox>

                  {this.state.blehumidity4 && (
                    <>
                      <Form.Item
                        name="BLEMinHumidity4"
                        // label="Min Humidity4"
                        label={this.context.translate("_MIN_HUMIDITY4_")}
                        rules={[{ required: this.state.blehumidity4 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                      <Form.Item
                        name="BLEMaxHumidity4"
                        // label="Max Humidity4"
                        label={this.context.translate("_MAX_HUMIDITY4_")}
                        rules={[{ required: this.state.blehumidity4 }]}
                      >
                        <Input style={{ width: 250 }} />
                      </Form.Item>
                    </>
                  )}
                  {/* </Form.Item> */}
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This rule will be triggered when temperature/humidity goes
                    beyond the defined limit. User can set minimum and maximum
                    temperature/humidity. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_BLE_TEMP_DESCRIPTION_"
                    )}
                  </p>
                </div>

                {/* <h6 className="header">Area Wise Speed Violation</h6> */}
                <h6 className="header">
                  {this.context.translate("_AREAWISE_SPEED_VIOLATION_")}
                </h6>

                <Button
                  className="altbtn"
                  type="primary"
                  onClick={() => {
                    this.setState({
                      Rule41geofencename: !this.state.Rule41geofencename,
                    });
                  }}
                >
                  {/* {this.state.Rule41geofencename ? "DISABLE" : "ENABLE"} */}
                  {this.state.Rule41geofencename
                    ? this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DISABLEBTN_"
                      )
                    : this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_ENABLEBTN_"
                      )}
                </Button>

                <div
                  style={{
                    display: this.state.Rule41geofencename ? "" : "none",
                  }}
                >
                  <Form.Item
                    name="Rule41geofencename"
                    // label="Select Geofences"
                    label={this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                    )}
                    className="search-field"
                    rules={[
                      {
                        required: this.state.Rule41geofencename,
                        message: "Please select geofences",
                      },
                    ]}
                  >
                    <Select
                      maxTagCount={"responsive"}
                      // placeholder="Select Geofences"
                      placeholder={this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_RULE6_GEOFENCE_"
                      )}
                      style={{ width: 250 }}
                      mode="multiple"
                      onChange={(val) => {
                        this.alertSettingPopupFormRef.current.setFieldsValue({
                          Rule41geofencename: val,
                        });
                        console.log(val);
                      }}
                      showSearch
                      fieldNames={{ value: "zoneId", label: "name" }}
                      optionFilterProp="children"
                      options={this.alertStore?.geofencesList}
                    />
                  </Form.Item>
                  <p className="paragraph">
                    {/* <div className="disc"> Description</div> */}
                    <div className="disc">
                      {this.context.translate(
                        "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                      )}
                    </div>
                    {/* This rule is triggered when Vehicle exceeds the defined
                    speed for selected geofences. */}
                    {this.context.translate(
                      "_FLEET_RULESELECTORDETAIL_RULE_SPEED_VIOLATION_DESCRIPTION_"
                    )}
                  </p>
                </div>
              </div>
              <div className="formBtnGroup">
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
                {/* <Button
                  className="formCancelButton"
                  onClick={() => this.setState({ alertSettingShow: false })}
                >
                  Cancel
                </Button> */}
              </div>
            </Form>
          </Modal>

          <Modal
            // title="Close Confirmation"
            title={this.context.translate(
              "_FLEET_RULEDETAIL_RULESELECTORWIN_ALERTTITLE_"
            )}
            open={this.state.closeWarningModal}
            width={500}
            onCancel={() => this.setState({ closeWarningModal: false })}
            footer={[
              <Button
                onClick={() => {
                  this.setState({
                    closeWarningModal: false,
                    alertSettingShow: false,
                    saveSettingChanges: true,
                  });
                  this.alertSettingPopupFormRef.current.resetFields();
                }}
              >
                {/* Yes */}
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_YES_")}
              </Button>,
              <Button
                // key="no"
                onClick={() => this.setState({ closeWarningModal: false })}
              >
                {/* No */}
                {this.context.translate("_FLEET_RULEDETAIL_ACTIVEDDL_NO_")}
              </Button>,
            ]}
          >
            {this.context.translate(
              "_FLEET_RULEADMIN_RULESELECTORWIN_ALERTMSG_"
            )}
          </Modal>

          <Modal
            maskClosable={false}
            title={this.context.translate("_ASSIGN_VEHICLE_")}
            open={this.state.openAssignVehicleModal}
            onCancel={() => this.setState({ openAssignVehicleModal: false })}
            footer={null}
            width={650}
            style={{ maxHeight: "1000px" }}
          >
            <Form
              layout="vertical"
              ref={this.assignedformRef}
              onFinish={this.onVehicleAssigned}
            >
              <Row>
                <Col span={11}>
                  {Number(this.props.getUserData().isadmin) !== -1 && (
                    <>
                      {" "}
                      <Select
                        showSearch
                        style={{ marginBottom: "10px", width: "100%" }}
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                        placeholder="Select Group"
                        onSelect={async (val) => {
                          await this.getSubGroup(val);
                          await this.setState({ deviceGroupId: val });
                        }}
                      >
                        {this.emailReportStore.groupList?.map((val) => {
                          return (
                            <Select.Option
                              value={val.deviceGroupId}
                              key={val.deviceGroupId}
                            >
                              {val.text}
                            </Select.Option>
                          );
                        })}
                      </Select>
                      <Select
                        style={{ marginBottom: "10px", width: "100%" }}
                        showSearch
                        placeholder="Select SubGroup"
                        filterOption={(inputValue, option) => {
                          if (option.children) {
                            return (
                              option.children
                                .toLowerCase()
                                .indexOf(inputValue.toLowerCase()) >= 0
                            );
                          }
                        }}
                        onSelect={async (val) => {
                          await this.setState({ deviceSubGroupId: val });
                          await this.getSubGroupVehicles(val);
                        }}
                      >
                        {this.emailReportStore.subGroupList?.map((val) => {
                          return (
                            <Select.Option
                              value={val.deviceSubGroupId}
                              key={val.deviceSubGroupId}
                            >
                              {val.subgroupname}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </>
                  )}
                  <Input
                    placeholder={this.context.translate("_SEARCH_VEHICLE_")}
                    style={{ marginBottom: "10px" }}
                    onChange={(e) => this.onSearchVehicleNo(e.target.value)}
                  />
                  <Table
                    bordered
                    columns={this.columns}
                    dataSource={this.state.searchVehicleArray}
                    pagination={false}
                    rowKey={"vehicleId"}
                    scroll={{
                      x: 100,
                      y: 350,
                    }}
                    rowSelection={{
                      selectedRowKeys: this.state.selectedAssignedVehicleId,
                      onChange: async (selectedRowKeys, selectedRows) => {
                        await this.setState({
                          assignVehiclesArray: selectedRows,

                          selectedAssignedVehicleId: selectedRowKeys,
                        });
                      },
                    }}
                  />
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <Table
                    bordered
                    columns={this.assignedColumns}
                    dataSource={this.state.assignVehiclesArray}
                    pagination={false}
                    rowKey={"vehicleId"}
                    scroll={{
                      x: 100,
                      y: 350,
                    }}
                    rowSelection={{
                      selectedRowKeys: this.state.selectedSearchVehicleId,
                      onChange: async (selectedRowKeys, selectedRows) => {
                        console.log(selectedRowKeys, selectedRows);
                        // await this.setState({})
                        await this.setState({
                          selectedSearchVehicleId: selectedRowKeys,
                        });
                      },
                    }}
                  />
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  loading={this.state.loading}
                  type="primary"
                  htmlType="submit"
                  className="formSaveButton"
                  onClick={() => {
                    this.setState({ openAssignVehicleModal: false });
                    //  this.formRef.current.setFieldsValue({})
                  }}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Modal>
        </div>
      </div>
    );
  }
}

AlertAdd.contextType = StoreContext;
export default observer(withRouter(AlertAdd));
