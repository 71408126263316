
import React from "react";
import {
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  DatePicker,
  TimePicker,
  Tag,
  Avatar,
  Row,
  Col,
  Dropdown,
  Checkbox,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import {
  DeleteFilled,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  UserOutlined,
  DownOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import moment from "moment";

import AttendenceReportStore from '../store/AttendenceReportStore';

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VehicleStore from "../store/VehicleStore";
const { RangePicker } = DatePicker;

dayjs.extend(customParseFormat);
const { Search } = Input;
const dateFormat = "DD-MM-YYYY hh:mmA";
const defaultValue = moment().startOf("day").hour(0).minute(0).second(0);

class AttendanceReportList extends React.Component {
  constructor(props,context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.attendenceReportStore = new AttendenceReportStore()
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId: "",
      fromDate: "",
      toDate: "",
      SelectedSalespersonId: [],
      selectAllSalesPerson: false,

      modalVisible: false,
      column: "",
      filter: "",
      Notcompany: false,
      Notperson: false,

      selectedColumns: [
        {
          // title: 'Sales Person Name ',
          title:context.translate("_SALES_PERSON_NAME_"),
          dataIndex: 'salesPersonName',
          key: 'salesPersonName',
          width: '15%'
        },
        {
          // title: 'Punch In Time',
          title:context.translate("_PUNCH_IN_TIME_"),
          dataIndex: 'punchInTime',
          key: 'punchInTime',
          width: '15%'
        },

        {
          // title: 'Punch In Location',
          title:context.translate("_PUNCH_IN_LOCATION_"),
          dataIndex: 'punchInLocation',
          key: 'punchInLocation',
          width: '15%'
        },
        {
          // title: 'Punch Out Time',
          title:context.translate("_PUNCH_OUT_TIME_"),
          dataIndex: 'punchOutTime',
          key: 'punchOutTime',
          width: '15%'
        },
        {
          // title: 'Punch Out Location',
          title:context.translate("_PUNCH_OUT_LOCATION_"),
          dataIndex: 'punchOutLocation',
          key: 'punchOutLocation',
          width: '15%'
        },

      ]

    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label:context.translate("_EXCEL_"),
          key: "1",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label:context.translate("_CSV_"), 
          key: "2",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label:context.translate("_XML_"),
          key: "3",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label:context.translate("_PDF_"), 
          key: "4",
          icon: <PlusOutlined />,
        },
      ],
      onClick: () => { },
    };
    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    if(Number(this.props.getUserData().isadmin)!==-1){
    
      let companyId = this.props.getUserData().userCompanyId;
   
        this.setState({companyId:companyId})
        this.handleChange({value:companyId});
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false })
    await this.setState({ currentPage: 1, companyId: comp?.value });
    await this.setState({ SelectedSalespersonId: [], selectAllSalesPerson: false });
    await this.attendenceReportStore.getSalesPersonExecutive({ companyId: comp?.value })
    console.log(this.attendenceReportStore.salesPersonArray)
    // this.handleSearch();
  }

  // async onSearch(value) {
  //     console.log(value)
  //     await this.setState({ emailId: value });
  //     this.handleSearch();
  // };

  async handleSearch() {
    this.setState({ Notperson: false })
    await this.attendenceReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
  }

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true })
    }
    else {
      this.setState({ Notcompany: false })
    }
    if (this.state.SelectedSalespersonId.length === 0) {
      this.setState({ Notperson: true })
    }
    else {
      this.setState({ Notperson: false })
    }
    if (this.state.companyId && this.state.SelectedSalespersonId.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notperson: false });
      await this.attendenceReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        salespersonId: this.state.SelectedSalespersonId,
        fromDate: this.state.fromDate,
        toDate: defaultValue.format("YYYY-MM-DD HH:mm:ss"),
      });
      await this.setState({ loadData: 1 });

      // console.log(this.state.fromDate,this.state.toDate,)
    }
  };
  handleAllSelectSalesPerson = async (e) => {
    if (this.state.selectAllSalesPerson) {
      const allVehicles = this.attendenceReportStore?.salesPersonArray.map((val) => { return val.salesPersonId })
      await this.setState({ SelectedSalespersonId: allVehicles })
    } else {
      await this.setState({ SelectedSalespersonId: [] })
    }

  }
  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  render() {
    //console.log('in render1')
    const companyInputStyle = this.state.Notcompany ? { border: '1px solid red', width: '150px', borderRadius: '8px' } : { width: '150px' };
    const personInputStyle = this.state.Notperson ? { border: '1px solid red', width: '150px', borderRadius: '8px' } : { width: '150px' };


    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Attendance Report</div> */}
              <div className="heading">{this.context.translate("_ATTENDANCE_REPORT_")}</div>


            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
              
                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown menu={this.menuProps}
                     disabled={
                      this.attendenceReportStore?.attendenceReport?.length > 0 ? false : true
                    }
                >
                  <Button 
                   className="exportDropdown"
                  // style={{backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <Row style={{ padding: "10px 0px" }}> */}
          <Row className="listInputRow">
            <Space>
              <Col span={16}>
                <CompanyAutocomplete
                  onChange={(value) => this.handleChange(value)}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? 'error' : false}
                />
              </Col>
           
                <Checkbox checked={this.state.selectAllSalesPerson}
                  onChange={async (e) => {
                    await this.setState({ selectAllSalesPerson: e.target.checked });
                    console.log(e.target.checked);
                    await this.handleAllSelectSalesPerson()
                  }} />
                {/* All */}
                {this.context.translate("_ALL_")}
                {"  "}
          
            
                <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  value={this.state.SelectedSalespersonId}
                  onChange={(val) => {
                    this.setState({ SelectedSalespersonId: val, Notperson: false });
                    if (this.attendenceReportStore?.salesPersonArray.length > val.length) {
                      this.setState({ selectAllSalesPerson: false })
                    }
                    else {
                      this.setState({ selectAllSalesPerson: true })
                    }
                  }}
                  maxTagCount="responsive"
                  fieldNames={{ label: "salesPersonName", value: "salesPersonId" }}
                  // placeholder="Sales Person"
                  placeholder={this.context.translate("_SALES_PERSON_")}
                  optionFilterProp="children"
                  options={this.attendenceReportStore?.salesPersonArray}
                  style={{ width: 150 }}
                  // style={personInputStyle}
                  status={this.state.Notperson ? 'error' : false}
                />
                {/* {this.vehicleStore?.getVehiclesUsingCompanyId.map((val)=>{
                    return (<Select.Option value={val.vehicleId}>{val.vehicleNo}</Select.Option>)
                })}
              </Select> */}
           
              <Col>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(dates) => {
                    // console.log(val)
                    // this.onDateTimeChange(val)
                    const format = "YYYY-MM-DD HH:mm:ss";
                    this.setState({
                      fromDate: dates[0].format(format),
                      toDate: dates[1].format(format),
                    });
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}

                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
              </Col>
              {/* 
              <Col>
                <Select placeholder="Pickup">
                  <Select.Option value="All">All</Select.Option>
                  <Select.Option value="Pickup">Pickup</Select.Option>
                  <Select.Option value="Drop">Dop</Select.Option>
                </Select>
              </Col> */}
              <Col>

                <Button type="primary" onClick={() => this.showData()}>
                {this.context.translate("_FLEET_SHOW_BTN_")}
                  {/* Show */}
                </Button>
              </Col>


            </Space>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.attendenceReportStore?.total}
            columns={this.state.column}
            dataSource={this.attendenceReportStore?.attendenceReport}
            loading={this.attendenceReportStore?.loading}
            onChange={this.handleTableChange}
            scroll={{
              // y: this.state.clientHeight-240,
              x: 100,
            }}
          />


        </div>
      </>
    );
  }
}
AttendanceReportList.contextType = StoreContext;
export default observer(withRouter(AttendanceReportList));
