import React, { useState } from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";

import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  Checkbox,
  Dropdown,
  Modal,
  Image,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import withRouter from "../withRouter";
import { Link } from "react-router-dom";
import MapForVehiclePosition from "./MapForVehiclePosition";
import config from "../config";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import StationaryReportStore from "../store/StationaryReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import duration from "dayjs/plugin/duration";

import L from "leaflet";
import dayjs from "dayjs";
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});

dayjs.extend(duration);

class StationaryReportList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.stationaryReportStore = new StationaryReportStore();

    this.state = {
      loading: false,
      loadData: 0,
      pageSize: 50,
      offset: 0,
      limit: 50,
      pageNo: 1,
      companyId: "",
      modalVisible: false,
      filter: "",
      column: "",
      hours: "",
      showMap: false,

      vehicleObj: false,
      selectedColumns: [
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "vehicleNo",
        },

        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "name",
          width: "15%",
          key: "name",
        },

        {
          // title: "Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
          width: "15%",
          key: "location",
        },
        {
          // title: "Region",
          title: context.translate("_REGION_"),
          dataIndex: "region",
          width: "15%",
          key: "region",
        },
        {
          // title: "Stationary From",
          title: context.translate("_STATIONARY_FROM_"),
          dataIndex: "stopFrom",
          width: "15%",
          key: "stopFrom",
        },
        {
          // title: "Stop Since",
          title: context.translate("_STOP_SINCE_"),
          dataIndex: "stopSince",
          width: "15%",
          key: "stopSince",
          render: (c) => {
            console.log(c);

            var g, f, b, e;
            e = c;
            b = Math.floor(e / 60);
            e = e % 60;
            f = Math.floor(b / 60);
            b = b % 60;
            g = Math.floor(f / 24);
            f = f % 24;
            var a = (g < 10 ? "0" + g : g) + " days,";
            a += f < 10 ? "0" + f : f;
            a += ":" + (b < 10 ? "0" + b : b);
            a += ":" + (e < 10 ? "0" + e : e);
            return a;

            // const formattedTime = this.formatSecondsToTime(value);

            // return <div>{formattedTime}</div>;
          },
        },

        {
          // title: "View On Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "viewonmap",
          width: "15%",
          key: "viewonmap",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: { ...obj, icon: "map-car-icon-green.png" },
                      // heading: "Track Data Map",
                      heading: this.context.translate("_TRACK_DATA_REPORT_"),
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />

                {/* <Link
                  // onClick={(e) =>
                  //   this.setState({ showMap: true, modalInfoObj: obj })
                  // }
                  onClick={async (e) => {
                    // await this.setState({ showMap: true, modalInfoObj: obj });
                    await this.setState({ vehicleObj:{...obj,icon:"logo_icon.png"},heading:"Track Data Map" });
                    await this.setState({loadData:1})
                  }}
                >
                  {" "}
                  <img
                    src={require("../assets/images/mapIcon/gps_icon.png")}
                    width={25}
                    height={25}
                  />
                </Link> */}
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        //   icon: <PlusOutlined />,
        // },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          console.log(result.toString());

          var getData = {
            companyId: this.state.companyId,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            hours: this.state.hours,
            colList: result.toString(),
          };

          console.log(
            "asfsdgfhdfghfg",
            parseInt(JSON.parse(base64_decode(Cookies.get("data"))).userId)
          );
          if (val.key == "excel") {
            // console.log("afd")
            this.stationaryReportStore.exportExcel(getData);
          }
          if (val.key == "csv") {
            this.stationaryReportStore.exportcsv(getData);
          }
          if (val.key == "pdf") {
            this.stationaryReportStore.exportpdf(getData);
          }

          if (val.key == "xml") {
            this.stationaryReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }

  // formatSecondsToTime = (val) => {
  //   const duration = dayjs.duration(val, "seconds");
  //   const days = duration.days().toString().padStart(2, "0");
  //   const hours = duration.hours().toString().padStart(2, "0");
  //   const minutes = duration.minutes().toString().padStart(2, "0");
  //   const seconds = duration.seconds().toString().padStart(2, "0");

  //   // console.log({days} , {hours} ,{minutes},{seconds})

  //   return `${days} days, ${hours}:${minutes}:${seconds}`;
  // };
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.stationaryReportStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;
      console.log(companyId);
      await this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  // async componentDidUpdate() {
  //   if (this.state.showMap) {
  //     setTimeout(() => {
  //       window.dispatchEvent(new Event("resize"));
  //     }, 10);
  //   }
  // }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pagesize: 50,
    });
    // await this.vehicleStore.getVehiclesCompanyId({ companyId: comp.value });
    await this.handleSearch();

    // console.log("data", data);
  }

  async handleSearch() {
    await this.stationaryReportStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      // companyId: this.state.companyId,
      pageNo: this.state.pageNo,
      // hours: this.state.hours,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }
  showData = async () => {
    console.log("company", this.state.companyId);
    await this.stationaryReportStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      hours: this.state.hours,
      pageNo: this.state.pageNo,
    });
    await this.setState({ loadData: 1 });
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Stationary Report</div> */}
              <div className="heading">
                {this.context.translate("_STATIONARY_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
              

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.stationaryReportStore?.stationaryReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button
                    className="exportDropdown"
                    //  style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                />

                <Input
                  style={{ width: 100 }}
                  // placeholder="Hours"
                  placeholder={this.context.translate("_hour_")}
                  value={this.state.hours}
                  onChange={(e) => this.setState({ hours: e.target.value })}
                />

                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ pageSize: 50, pageNo: 1 });
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.stationaryReportStore?.total}
            columns={this.state.column}
            dataSource={this.stationaryReportStore?.stationaryReport}
            loading={this.stationaryReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.stationaryReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          {/* <Modal
            title="Trip Report Map"
            open={this.state.showMap}
            centered
            // bodyStyle={{ height: 1000 }}
            width={500}
            onOk={() => this.setState({ showMap: false })}
            onCancel={() => this.setState({ showMap: false })}
            footer={false}
          >
            <MapContainer
              ref={this.mapRef}
              zoom={14}
              center={[
                this.state.modalInfoObj.latitude,
                this.state.modalInfoObj.longitude,
              ]}
              style={{ height: "300px", width: "450px" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // attribution="Map data © OpenStreetMap contributors"
              />

              <Marker
                icon={dotIcon}
                position={[
                  this.state.modalInfoObj.latitude,
                  this.state.modalInfoObj.longitude,
                ]}
              >
               
                <Popup>{this.state.modalInfoObj.vehicleNo}</Popup>
              </Marker>
            </MapContainer>
          </Modal> */}

          {this.state.vehicleObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.vehicleObj}
              heading={this.state.heading}
              closeMapModal={(resp) => {
                this.setState({ vehicleObj: resp });
              }}
            />
          )}
        </div>
      </>
    );
  }
}
StationaryReportList.contextType = StoreContext;
export default observer(withRouter(StationaryReportList));
