import React, { Component } from "react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Table,
  message,
} from "antd";
import { Link } from "react-router-dom";
import { FullscreenOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import DeliveryDashboardStore from "../store/DeliveryDashboardStore";
import JobManagementAdd from "./JobManagementAdd";
import { Column } from "@ant-design/plots";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";

class DeliveryDashboard extends Component {
  constructor(props, context) {
    super(props);
    const currentDate = dayjs();
    const oneWeekAgoDate = dayjs().subtract(7, "days");

    this.state = {
      loading: false,
      showDashboardModal: false,
      datefilter: dayjs().format("YYYY-MM-DD"),
      fromDate: oneWeekAgoDate.format("YYYY-MM-DD"),
      toDate: currentDate.format("YYYY-MM-DD"),
      filter: "all",
      statusFilter: null,
      openJobmanagementForm: false,
      Id: "",
      openLogTbl: false,
      jobNo: "",
    };

    this.deliveryDashboardStore = new DeliveryDashboardStore(this);

    this.columns = [
      {
        title: context.translate("_JOB_NO_"),
        dataIndex: "jobNo",
        width: "8%",
      },
      {
        // title: "Driver Name",
        title: context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
        ),
        dataIndex: "driverName",
        width: "10%",
      },
      {
        // title: "Vehicle Number",
        title: context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "10%",
      },
      {
        // title: "Customer Name ",
        title: context.translate("_CUSTOMER_NAME_"),
        dataIndex: "customerName",
        width: "15%",
      },
      {
        // title: "Job Type",
        title: context.translate("_JOB_TYPE_"),
        dataIndex: "jobType",
        width: "10%",
      },
      {
        title: context.translate("_INVOICE_NO_"),
        dataIndex: "invoiceNo",
        width: "10%",
      },
      {
        title: context.translate("_INVOICE_DATE_"),
        dataIndex: "invoiceDate",
        width: "10%",
      },
      {
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        width: "8%",
        render: (val) => {
          if (val === 4) {
            return <>Cancelled</>;
          } else if (val === 3) {
            return <>Completed</>;
          } else if (val === 6) {
            return <>Started</>;
          } else {
            return <>Pending</>;
          }
        },
      },
      {
        title: context.translate("_DELIVERY_DATE_"),
        dataIndex: "deliveryDate",
        width: "12%",
      },
      {
        title: context.translate("_DELIVERED_AT_"),
        dataIndex: "deliveredAt",
        width: "13%",
      },
      {
        title: context.translate("_ORDER_STATUS1_"),
        dataIndex: "orderStatus",
        width: "12%",
      },
      // {
      //   title: context.translate("_LOG_"),
      //   dataIndex: "deliverylog",
      //   width: "10%",
      //   render: (val, record) => {
      //     return (
      //       <Link
      //         onClick={async () => {
      //           await this.setState({ openLogTbl: true, jobNo: record.jobNo });
      //           await this.showDeliveryLogCol();
      //           await this.showDeliveryLogData();
      //         }}
      //       >
      //         Delivery Log
      //       </Link>
      //     );
      //   },
      // },
    ];

    this.dashboardColumns = [
      {
        title: context.translate("_JOB_NO_"),
        dataIndex: "jobNo",
        width: "8%",
      },
      {
        // title: "Driver Name",
        title: context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
        ),
        dataIndex: "driverName",
        width: "10%",
      },
      {
        // title: "Vehicle Number",
        title: context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "10%",
      },
      {
        // title: "Customer Name ",
        title: context.translate("_CUSTOMER_NAME_"),
        dataIndex: "customerName",
        width: "15%",
      },
      {
        // title: "Job Type",
        title: context.translate("_JOB_TYPE_"),
        dataIndex: "jobType",
        width: "10%",
      },
      {
        title: context.translate("_INVOICE_NO_"),
        dataIndex: "invoiceNo",
        width: "10%",
      },
      {
        title: context.translate("_INVOICE_DATE_"),
        dataIndex: "invoiceDate",
        width: "10%",
      },
      {
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        width: "8%",
        render: (val) => {
          if (val === 4) {
            return <>Cancelled</>;
          } else if (val === 3) {
            return <>Completed</>;
          } else if (val === 6) {
            return <>Started</>;
          } else {
            return <>Pending</>;
          }
        },
      },
      {
        title: context.translate("_DELIVERY_DATE_"),
        dataIndex: "deliveryDate",
        width: "12%",
      },
      {
        title: context.translate("_DELIVERED_AT_"),
        dataIndex: "deliveredAt",
        width: "13%",
      },
      {
        title: context.translate("_ORDER_STATUS1_"),
        dataIndex: "orderStatus",
        width: "12%",
      },
      {
        title: context.translate("_LOG_"),
        dataIndex: "deliverylog",
        width: "10%",
        render: (val, record) => {
          return (
            <Link
              onClick={async () => {
                await this.setState({ openLogTbl: true, jobNo: record.jobNo });
                await this.showDeliveryLogCol();
                await this.showDeliveryLogData();
              }}
            >
              Delivery Log
            </Link>
          );
        },
      },
    ];

    this.deliverylogcols = [
      {
        title: context.translate("_CREATED_AT_"),
        dataIndex: "registeredAt",
        width: "10%",
      },
      {
        // title: "Driver Name",
        title: context.translate("_CREATED_BY_"),
        dataIndex: "registeredBy",
        width: "10%",
      },
      {
        // title: "Vehicle Number",
        title: context.translate("_UPDATED_AT_"),
        dataIndex: "updatedAt",
        width: "10%",
      },
      {
        // title: "Customer Name ",
        title: context.translate("_UPDATED_BY_"),
        dataIndex: "updatedBy",
        width: "10%",
      },
      {
        // title: "Job Type",
        title: context.translate("_UPDATED_FIELD_"),
        dataIndex: "updatedField",
        width: "10%",
      },
      {
        title: context.translate("_CANCELLED_AT_"),
        dataIndex: "cancelledAt",
        width: "10%",
      },
      {
        title: context.translate("_CANCELLED_BY_"),
        dataIndex: "cancelledBy",
        width: "10%",
      },
    ];
  }

  async componentDidMount() {
    await this.showDeliveryDashbordData();

    this.intervalId = setInterval(async () => {
      await this.showDeliveryDashbordData();
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalId); // Clear the interval when the component is unmounted
  }

  responseMsg = async (resp) => {
    if (resp.success == "S") {
      message.success(resp.message);
      // await this.setState({ listDataUpdate: true });
      await this.setState({ openJobmanagementForm: false });

      // await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else {
      message.error(resp.message);
    }
  };

  showDeliveryDashbordData = async () => {
    this.setState({ loading: true });
    await this.deliveryDashboardStore.loadData({
      companyId: this.props.getUserData().userCompanyId,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      filter: this.state.filter,
      datefilter: this.state.fromDate,
      statusFilter: this.state.statusFilter ? this.state.statusFilter : null,
      invoiceDate: this.state.invoiceDate ? this.state.invoiceDate : null,
    });
    await this.setState({ loadData: 1, loading: false });
  };

  showDeliveryLogData = async () => {
    await this.deliveryDashboardStore.loadDeliveryLogData({
      jobNo: this.state.jobNo,
    });
    await this.setState({ loadData: 1 });
  };

  showDeliveryDashboardCol = () => {
    return (
      <Table
        columns={this.columns}
        bordered
        dataSource={this.deliveryDashboardStore?.dashboardTableData}
        pagination={false}
        scroll={{ y: 320 }}
      />
    );
  };

  showDeliveryLogCol = () => {
    return (
      <Table
        columns={this.deliverylogcols}
        bordered
        dataSource={this.deliveryDashboardStore?.dashboardDeliveryTblData}
        pagination={false}
        scroll={{ y: 250 }}
      />
    );
  };

  deliveryDashboardHeader = () => {
    return (
      <Space
        style={{
          float: this.context.locale.direction == "ltr" ? "right" : "left",
        }}
      >
        <Input
          placeholder={this.context.translate("_INVOICE_NO_")}
          style={{ width: 150, height: 33 }}
        />

        <DatePicker
          placeholder={this.context.translate("_INVOICE_DATE_")}
          // defaultValue={dayjs()}
          style={{ width: 130, height: 33 }}
          format="DD-MM-YYYY"
          onChange={(date) => {
            if (date === null) {
              this.setState({ invoiceDate: "" });
            } else {
              const format = "YYYY-MM-DD";
              this.setState({
                invoiceDate: date.format(format),
              });
            }
          }}
        />
        <Select
          placeholder="Status"
          className="statusDropdown"
          style={{ width: 130 }}
          onSelect={async (val) => {
            await this.setState({ statusFilter: val });
            await this.showDeliveryDashbordData();
          }}
          options={[
            {
              label: "All",
              value: "All",
            },
            {
              label: "Pending",
              value: "Pending",
            },
            {
              label: "Completed",
              value: "Completed",
            },
            {
              label: "Cancelled",
              value: "Cancelled",
            },
          ]}
        />

        <DatePicker
          defaultValue={dayjs(this.state.fromDate)}
          style={{ width: 130, height: 33 }}
          format="DD-MM-YYYY"
          onChange={(date) => {
            if (date === null) {
              this.setState({ fromDate: "" });
            } else {
              const format = "YYYY-MM-DD";
              this.setState({
                fromDate: date.format(format),
              });
            }
          }}
        />

        <DatePicker
          defaultValue={dayjs(this.state.toDate)}
          style={{ width: 130, height: 33 }}
          format="DD-MM-YYYY"
          onChange={(date) => {
            if (date === null) {
              this.setState({ toDate: "" });
            } else {
              const format = "YYYY-MM-DD";
              this.setState({
                toDate: date.format(format),
              });
            }
          }}
        />

        <Button
          icon={<SearchOutlined />}
          type="primary"
          onClick={() => {
            this.showDeliveryDashbordData();
          }}
        />

        <Button
          onClick={() => {
            let result = [];

            if (true) {
              this.columns.map((obj) => result.push(obj.dataIndex));

              var getData = {
                companyId: this.props.getUserData().userCompanyId,

                fromDate: this.state.fromDate,
                toDate: this.state.toDate,
                userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,

                datefilter: this.state.datefilter,
                statusFilter: this.state.statusFilter,
                filter: this.state.filter,

                colList: result.toString(),
              };
            }
            this.deliveryDashboardStore.exportExcel(getData);
          }}
        >
          {this.context.translate("_EXPORT_")}
        </Button>
      </Space>
    );
  };

  closeModal = () => {
    this.setState({ openJobmanagementForm: false, Id: "" });
  };
  render() {
    const dashboardCounts = this.deliveryDashboardStore?.dashboardDataCounts;

    const dashboardLabels = {
      TotalJobs: "Jobs",
      TotalStarted: "Started",
      TotalPickUp: "Pick Up",
      TotalDeliveries: "Deliveries",
      TotalDeliverd: "Job Completed",
      TotalPending: "Pending",
      TotalCancelled: "Cancelled",
    };

    const graphData = Object.entries(
      this.deliveryDashboardStore?.dashboardDataCounts || {}
    ).map(([key, value]) => ({
      type: dashboardLabels[key],
      value: value,
    }));

    return (
      <Spin spinning={this.state.loading} fullscreen>
        <div>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                <div className="heading">
                  {this.context.translate("_DELIVERY_DASHABOARD_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                >
                  {/* <Image
                    style={{
                      borderRadius: "50px",
                      borderColor: "white",
                      border: "2px solid",
                    }}
                    width={30}
                    src={
                      this.props.getUserData().photo
                        ? config.fleetURL + "/images/users/" + this.state.photo
                        : logoIcon
                    }
                    alt="logo"
                  />
                  Welcome {this.props.getUserData().userName} */}
                  <Button
                    onClick={() => {
                      this.setState({ openJobmanagementForm: true });
                    }}
                  >
                    Add
                  </Button>
                  <Link to="/deliverymanagementreport">
                    <Button>Report</Button>
                  </Link>
                  <Link to="/jobmanagementlist">
                    {" "}
                    <Button>Back</Button>
                  </Link>
                  <Link to="/login">
                    {" "}
                    <Button>Logout</Button>
                  </Link>
                </Space>
              </Col>
            </Row>
          </div>
          <div className="delivery-dashboard-card-row">
            <Card
              className="delivery-dashboard-card"
              onClick={async (val) => {
                await this.setState({
                  showDashboardModal: true,
                  filter: "all",
                });
                await this.showDeliveryDashboardCol();
                await this.showDeliveryDashbordData();
              }}
              style={{ backgroundColor: "#ffa800" }}
            >
              <div className="dash-countBlock">
                <i class="las la-file-alt"></i>

                <span>
                  <b>
                    {" "}
                    {dashboardCounts.TotalJobs
                      ? dashboardCounts.TotalJobs
                      : 0}{" "}
                    {this.context.translate("_TOTAL_JOB_")}
                  </b>
                </span>
              </div>
            </Card>

            <Card
              className="delivery-dashboard-card"
              onClick={async (val) => {
                await this.setState({
                  showDashboardModal: true,
                  filter: "started",
                });
                await this.showDeliveryDashboardCol();
                await this.showDeliveryDashbordData();
              }}
              style={{ backgroundColor: "#34a399" }}
            >
              <div className="dash-countBlock">
                <i class="las la-briefcase"></i>

                <span>
                  {" "}
                  <b>
                    {" "}
                    {dashboardCounts.TotalStarted
                      ? dashboardCounts.TotalStarted
                      : 0}{" "}
                    &nbsp;
                    {this.context.translate("_STARTED_")}
                  </b>
                </span>
              </div>
            </Card>

            <Card
              className="delivery-dashboard-card"
              onClick={async (val) => {
                await this.setState({
                  showDashboardModal: true,
                  filter: "pickup",
                });
                await this.showDeliveryDashboardCol();
                await this.showDeliveryDashbordData();
              }}
              style={{ backgroundColor: "#92576b" }}
            >
              {/* <div className="dash-countBlock">
                <i class="las la-truck-pickup"></i>

                <span>
                  <b>
                    {dashboardCounts.TotalPickUp
                      ? dashboardCounts.TotalPickUp
                      : 0}{" "}
                  </b>

                  <p>{this.context.translate("_PICKUP_")}</p>
                </span>
              </div> */}

              <div className="dash-countBlock">
                <i class="las la-truck-pickup"></i>

                <span>
                  <b>
                    {dashboardCounts.TotalPickUp
                      ? dashboardCounts.TotalPickUp
                      : 0}{" "}
                    &nbsp; {this.context.translate("_PICKUP_")}
                  </b>
                </span>
              </div>
            </Card>

            <Card
              className="delivery-dashboard-card"
              onClick={async (val) => {
                await this.setState({
                  showDashboardModal: true,
                  filter: "deliveries",
                });
                await this.showDeliveryDashboardCol();
                await this.showDeliveryDashbordData();
              }}
              style={{ backgroundColor: "#2082d7" }}
            >
              <div className="dash-countBlock">
                <i class="las la-box"></i>

                <span>
                  <b>
                    {dashboardCounts.TotalDeliveries
                      ? dashboardCounts.TotalDeliveries
                      : 0}{" "}
                    &nbsp;
                    {this.context.translate("_DELIVERIES_")}{" "}
                  </b>
                </span>
              </div>
            </Card>
            <Card
              className="delivery-dashboard-card"
              onClick={async (val) => {
                await this.setState({
                  showDashboardModal: true,
                  filter: "completed",
                });
                await this.showDeliveryDashboardCol();
                await this.showDeliveryDashbordData();
              }}
              style={{ backgroundColor: "#8e52c0" }}
            >
              <div className="dash-countBlock">
                <i class="las la-check-circle"></i>

                <span>
                  <b>
                    {dashboardCounts.TotalDeliverd
                      ? dashboardCounts.TotalDeliverd
                      : 0}{" "}
                    &nbsp; {this.context.translate("_JOB_COMPLETED_")}{" "}
                  </b>
                </span>
              </div>
            </Card>

            <Card
              className="delivery-dashboard-card"
              onClick={async (val) => {
                await this.setState({
                  showDashboardModal: true,
                  filter: "pending",
                });
                await this.showDeliveryDashboardCol();
                await this.showDeliveryDashbordData();
              }}
              style={{ backgroundColor: "#1acaa1" }}
            >
              <div className="dash-countBlock">
                <i class="las la-business-time"></i>

                <span>
                  <b>
                    {dashboardCounts.TotalPending
                      ? dashboardCounts.TotalPending
                      : 0}{" "}
                    &nbsp;
                    {this.context.translate("_PENDING_JOBS_")}
                  </b>
                </span>
              </div>
            </Card>

            <Card
              className="delivery-dashboard-card"
              onClick={async (val) => {
                await this.setState({
                  showDashboardModal: true,
                  filter: "cancelled",
                });
                await this.showDeliveryDashboardCol();
                await this.showDeliveryDashbordData();
              }}
              style={{ backgroundColor: "#f44336" }}
            >
              <div className="dash-countBlock">
                <i class="las la-times-circle"></i>

                <span>
                  <b>
                    {dashboardCounts.TotalCancelled
                      ? dashboardCounts.TotalCancelled
                      : 0}
                    &nbsp;
                    {this.context.translate("_CANCELLED_JOBS_")}{" "}
                  </b>
                </span>
              </div>
            </Card>
          </div>
        </div>
        <Card
          className="delivery-dashboard-panel window-padding"
          title={
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="panel-head">
                <h1>{this.context.translate("_JOB_DETAILS_")} </h1>
              </div>
              <div style={{ flex: 1, paddingLeft: 16, marginRight: "10px" }}>
                {this.deliveryDashboardHeader()}
              </div>

              <FullscreenOutlined
                style={{ marginLeft: "auto", color: "#ffffff" }}
                onClick={async (val) => {
                  await this.setState({ showDashboardModal: true });
                  await this.showDeliveryDashboardCol();
                  await this.showDeliveryDashbordData();
                }}
              />
            </div>
          }
          style={{ marginInlineEnd: "20px", marginInlineStart: "20px" }}
        >
          {/* {this.showDeliveryDashboardCol()} */}
          <Table
            columns={this.dashboardColumns}
            bordered
            dataSource={this.deliveryDashboardStore?.dashboardTableData}
            pagination={false}
            scroll={{ y: 250 }}
          />
        </Card>

        <Card
          style={{
            marginInlineEnd: "20px",
            marginInlineStart: "20px",
          }}
          className="delivery-dashboard-panel window-padding"
          title={
            <div className="panel-head">
              <h1>{this.context.translate("_GRAPH_")}</h1>
            </div>
          }
        >
          <Column
            {...{
              data: graphData,
              yField: "value",
              xField: "type",
              color: ({ type }) => {
                switch (type) {
                  case "Jobs":
                    return "#ffa800";
                  case "Started":
                    return "#34a399";
                  case "Pick Up":
                    return "#92576b";
                  case "Deliveries":
                    return "#2082d7";
                  case "Job Completed":
                    return "#8e52c0";
                  case "Pending":
                    return "#1acaa1";
                  case "Cancelled":
                    return "#f44336";
                  default:
                    return "#2989FF";
                }
              },
              legend: false,
              animation: false,
              yAxis: {
                label: {
                  style: {
                    fontWeight: "bold",
                    fontSize: 13,
                  },
                },
                // title: {
                //   text: this.context.translate("_DISTANCE_TRAVELED_KMS_"),
                //   style: {
                //     fontWeight: "bold",
                //     fontSize: 17,
                //   },
                // },
              },

              xAxis: {
                label: {
                  style: {
                    fontWeight: "bold",
                    fontSize: 13,
                  },
                },
                // title: {
                //   // text: "Asset Name",
                //   text:
                //     this.state.xlabel === "vehicleNo"
                //       ? this.context.translate(
                //           "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                //         )
                //       : "Date",

                //   style: {
                //     fontWeight: "bold",
                //     fontSize: 17,
                //   },
                // },
              },

              tooltip: {
                customContent: (title, items) => {
                  const data = items[0]?.data || {};

                  const dashboardTooltip = (
                    <div
                      style={{
                        background: "white",
                        padding: "10px",
                      }}
                      class="custom-tooltip-title"
                    >
                      {data.type}:{data.value}
                    </div>
                  );

                  return (
                    <div
                      style={{
                        background: "white",
                        padding: "10px",
                      }}
                    >
                      <div>{dashboardTooltip}</div>
                    </div>
                  );
                },
              },
            }}
          />
        </Card>
        <Modal
          open={this.state.showDashboardModal}
          footer={null}
          centered
          width="95%"
          style={{ height: "450px" }}
          onCancel={() => this.setState({ showDashboardModal: false })}
        >
          <div className="heading">
            {this.context.translate("_JOB_DETAILS_")}
          </div>
          <div style={{ height: "450px" }}>
            <Row style={{ padding: "10px 0px" }}>
              <Col span={8}></Col>
              <Col span={16}>{this.deliveryDashboardHeader()}</Col>
            </Row>

            {this.showDeliveryDashboardCol()}
          </div>
        </Modal>

        <Modal
          open={this.state.openLogTbl}
          footer={null}
          centered
          width="60%"
          style={{ height: "250px" }}
          onCancel={() => this.setState({ openLogTbl: false })}
        >
          <div className="heading">
            {this.context.translate("_DELIVERY_LOG_")}
          </div>
          <div style={{ height: "250px" }}>{this.showDeliveryLogCol()}</div>
        </Modal>
        {this.state.openJobmanagementForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Add Job Details"
            title={this.context.translate("_ADD_JOB_DETAILS")}
            centered
            open={this.state.openJobmanagementForm}
            footer={null}
            onCancel={() => {
              this.setState({ openJobmanagementForm: false, Id: "" });
            }}
            width={665}
          >
            <div>
              <JobManagementAdd
                Id={this.state.Id}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </Spin>
    );
  }
}
DeliveryDashboard.contextType = StoreContext;
export default observer(withRouter(DeliveryDashboard));
