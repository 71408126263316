import { Select } from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";

const AccountListCertificateDropdown = (props) => {
  console.log("props", props);
  const shouldShow = shouldShowComponent(
    config.project,
    "AccountListCertificateDropdown"
  );

  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Select
        style={{
          width: 180,
        }}
        placeholder="Search By Certificate"
        // placeholder={this.context.translate("_FILTER_TYPE_")}
        optionFilterProp="children"
        onSelect={async (value) => {}}
        // defaultValue={"All"}
        options={[
          {
            value: "All",
            label: "All",
          },
          {
            value: "With Certificate",
            label: "With Certificate",
            // label:this.context.translate("_FLEET_CUSTOMER_")
          },
          {
            value: "Without Certificate",
            label: "Without Certificate",
            // label:this.context.translate("_DISTRIBUTOR_")
          },
        ]}
      />
    </>
  );
};

AccountListCertificateDropdown.contextType = StoreContext;
export default observer(withRouter(AccountListCertificateDropdown));
