import React, { useContext, useState } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Button, Col, Form, Input, Row, Select, Tooltip } from "antd";

const AlertSelectorFields = (props) => {
  const shouldShow = shouldShowComponent(config.project, "AlertSelectorFields");

  console.log(shouldShow, config.project, "AlertSelectorFields");
  const storeContext = useContext(StoreContext);

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      {config.project === "starlingeye" && (
        <>
          {" "}
          {/* Door Open Close Alert */}
          <h6 className="header">
            {storeContext.translate("_DOOR_OPEN_CLOSE_ALERT_")}
          </h6>
          <Button
            className="altbtn"
            type="primary"
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId34");
            }}
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId34: !props.This.ruleSelectorId34,
            //   });
            // }}
          >
            {props.This.ruleSelectorId34
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>
          <div
            style={{
              display: props.This.ruleSelectorId34 ? "" : "none",
            }}
          >
            <p className="paragraph">
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/* This alert will be triggered when Vehicles Door is opened or Closed. */}
              {storeContext.translate("_DOOR_OPEN_CLOSE_ALERT_STATEMENT_")}
            </p>
          </div>
          {/* Battery Change Alert */}
          <h6 className="header">
            {storeContext.translate("_BATTERY_CHANGE_ALERT_")}
          </h6>
          <Button
            className="altbtn"
            type="primary"
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId38: !this.state.ruleSelectorId38,
            //   });
            // }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId38");
            }}
          >
            {props.This.ruleSelectorId38
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>
          <div
            style={{
              display: props.This.ruleSelectorId38 ? "" : "none",
            }}
          >
            <p className="pragraph">
              {/* <div className="disc"> Description </div> */}
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/*This alert is triggered 30 days,15 days 7 days prior to battery change date.
               */}
              {storeContext.translate("_BATTERY_CHANGE_ALERT_STATEMENT_")}
            </p>
          </div>
          {/* Service/Oil/Tyre Change Alert. */}
          <h6 className="header">
            {storeContext.translate("_SERVICE_OIL_TYRE_CHANGE_ALERT_")}
          </h6>
          <Button
            className="altbtn"
            type="primary"
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId39: !this.state.ruleSelectorId39,
            //   });
            // }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId39");
            }}
          >
            {props.This.ruleSelectorId39
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>
          <div
            style={{
              display: props.This.ruleSelectorId39 ? "" : "none",
            }}
          >
            <p className="pragraph">
              {/* <div className="disc"> Description </div> */}
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/*This alert is triggered when servicing, oil or tyre change is due for selected vehicle.
               */}
              {storeContext.translate(
                "_SERVICE_OIL_TYRE_CHANGE_ALERT_STATEMENT_"
              )}
            </p>
          </div>
          {/* Insurance Renewal Alert */}
          <h6 className="header">
            {storeContext.translate("_INSURANCE_RENEWAL_ALERT_")}
          </h6>
          <Button
            className="altbtn"
            type="primary"
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId37: !this.state.ruleSelectorId37,
            //   });
            // }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId37");
            }}
          >
            {props.This.ruleSelectorId37
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>
          <div
            style={{
              display: props.This.ruleSelectorId37 ? "" : "none",
            }}
          >
            <p className="pragraph">
              {/* <div className="disc"> Description </div> */}
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/*This alert is triggered 30 days,15 days 7 days prior to Insurance expiry date.
               */}
              {storeContext.translate("_INSURANCE_RENEWAL_ALERT_STATEMENT_")}
            </p>
          </div>
          {/* Mulkiya Renewal Alert */}
          <h6 className="header">
            {storeContext.translate("_MULKIYA_RENEWAL_ALERT_")}
          </h6>
          <Button
            className="altbtn"
            type="primary"
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId36: !this.state.ruleSelectorId36,
            //   });
            // }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId36");
            }}
          >
            {props.This.ruleSelectorId36
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>
          <div
            style={{
              display: props.This.ruleSelectorId36 ? "" : "none",
            }}
          >
            <p className="pragraph">
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/*This alert is triggered 30 days,15 days 7 days prior to Mulkiya expiry date.
               */}
              {storeContext.translate("_MULKIYA_RENEWAL_ALERT_STATEMENT_")}
            </p>
          </div>
        </>
      )}

      {config.project === "awtltrack" && (
        <>
          {/*Passenger Seatbelt */}
          <h6 className="header">
            {storeContext.translate(
              "_FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT2_"
            )}
          </h6>
          <Button
            className="altbtn"
            type="primary"
            // onClick={() => {
            //   this.setState({
            //     Rule34SetVehicleSpeed: !this.state.Rule34SetVehicleSpeed,
            //   });
            // }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("Rule34SetVehicleSpeed");
            }}
          >
            {props.This.Rule34SetVehicleSpeed
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>

          <div
            style={{
              display: props.This.Rule34SetVehicleSpeed ? "" : "none",
            }}
          >
            <Form.Item
              name="Rule34SetVehicleSpeed"
              // label="Passenger Seatbelt"
              label={storeContext.translate(
                "_FLEET_VEHICLEDETAIL_DIGITALIPDDL_SEATBELT2_"
              )}
              rules={[{ required: props.This.Rule34SetVehicleSpeed }]}
            >
              <Input
                // placeholder="Set Asset Speed Limit(Km/Hr)"
                placeholder={storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_RULE3_SETVEHICLESPEED_"
                )}
                style={{ width: 250 }}
              ></Input>
            </Form.Item>
            <p className="paragraph">
              {/* <div className="disc"> Description </div> */}
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/* This rule is triggered when vehicle is moving with speed:10 kmph and passenger is not wearing the seatbelt. */}
              {storeContext.translate(
                "_FLEET_RULESELECTORDETAIL_RULE_PASSENGER_DESCRIPTION_"
              )}
            </p>
          </div>

          {/* Accident Alert */}
          <h6 className="header">
            {storeContext.translate("_ACCIDENT_ALERT_")}
          </h6>
          <Button
            className="altbtn"
            type="primary"
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId37");
            }}
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId37: !this.state.ruleSelectorId37,
            //   });
            // }}
          >
            {props.This.ruleSelectorId37
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>

          <div
            style={{
              display: props.This.ruleSelectorId37 ? "" : "none",
            }}
          >
            {/* <Form.Item name="AccidentAlert"></Form.Item> */}
            <p className="pragraph">
              {/* <div className="disc"> Description </div> */}
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/* This alert will be triggered when vehicle had an accident. */}
              {storeContext.translate(
                "_FLEET_RULESELECTORDETAIL_RULE_ACCIDENT_ALERT_DESCRIPTION_"
              )}
            </p>
          </div>

          {/* GPS Battery Disconnection*/}

          <h6 className="header">
            {storeContext.translate("_GPS_BATTERY_DISCONNECTION_")}
          </h6>

          <Button
            className="altbtn"
            type="primary"
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId39: !this.state.ruleSelectorId39,
            //   });
            // }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId39");
            }}
          >
            {/* {this.state.ruleSelectorId39 ? "DISABLE" : "ENABLE"} */}
            {props.This.ruleSelectorId39
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>

          <div
            style={{
              display: props.This.ruleSelectorId39 ? "" : "none",
            }}
          >
            <p className="paragraph">
              {/* <div className="disc"> Description </div> */}
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/* This alert is triggered when internal battery of gps is
                    about to drain. */}
              {storeContext.translate(
                "_FLEET_RULESELECTORDETAIL_RULE_GPS_BATTERY_DESCRIPTION_"
              )}
            </p>
          </div>

          {/* <h6 className="header">Unauthorized Driving</h6> */}
          <h6 className="header">
            {storeContext.translate("_UNAUTHO_DRIVING_")}
          </h6>

          <Button
            className="altbtn"
            type="primary"
            // onClick={() => {
            //   this.setState({
            //     ruleSelectorId40: !this.state.ruleSelectorId40,
            //   });
            // }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("ruleSelectorId40");
            }}
          >
            {props.This.ruleSelectorId40
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>

          <div
            style={{
              display: props.This.ruleSelectorId40 ? "" : "none",
            }}
          >
            <p className="paragraph">
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>

              {storeContext.translate(
                "_FLEET_RULESELECTORDETAIL_RULE_UNAUTHORIZED_DRIVER_DESCRIPTION_"
              )}
            </p>
          </div>

          {/* Non Stoppage*/}
          <h6 className="header">{storeContext.translate("_NON_STOPPAGE_")}</h6>

          <Button
            className="altbtn"
            type="primary"
            //   onClick={() => {
            //     this.setState({
            //       nonStoppageMin: !this.state.nonStoppageMin,
            //     });
            //   }}
            onClick={() => {
              // console.log(e.target.value)
              props.handleEnableButton("nonStoppageMin");
            }}
          >
            {props.This.nonStoppageMin
              ? storeContext.translate("_FLEET_RULESELECTORDETAIL_DISABLEBTN_")
              : storeContext.translate("_FLEET_RULESELECTORDETAIL_ENABLEBTN_")}
          </Button>

          <div
            style={{
              display: props.This.nonStoppageMin ? "" : "none",
            }}
          >
            <Form.Item
              name="nonStoppageMin"
              // label="Non Stoppage Min"
              label={storeContext.translate("_NON_STOPPAGE_MIN")}
              rules={[{ required: props.This.nonStoppageMin }]}
            >
              <Input
                style={{ width: 250 }}
                // placeholder="Moving Since(Min) for Non Stoppage"
                placeholder={storeContext.translate("_NON_STOPPAGE_FOR_")}
              />
            </Form.Item>
            <p className="paragraph">
              {/* <div className="disc"> Description</div> */}
              <div className="disc">
                {storeContext.translate(
                  "_FLEET_RULESELECTORDETAIL_DESCRIPTION_"
                )}
              </div>
              {/* This Alert will trigger when vehicle is moving for more than
                    defined time period. */}
              {storeContext.translate(
                "_FLEET_RULESELECTORDETAIL_RULE_NON_STOPPAGE_DESCRIPTION_"
              )}
            </p>
          </div>
        </>
      )}
    </>
  );
};

AlertSelectorFields.contextType = StoreContext;
export default observer(withRouter(AlertSelectorFields));
