import {
  Tooltip,
  Button,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
/*"displayDomains" : ["awtltrack"],*/

const VehicleOdometerListFields = (props) => {
  console.log("props", props);

  // const shouldShow = baseConfig.displayDomains.includes(config.project);
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleOdometerListFields"
  );

  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Tooltip
        // title="Upload"
        title={storeContext.translate("_UPLOAD_")}
        color={config.tooltipColorEdit}
      >
        <Button
          style={{ backgroundColor: "white" }}
          // disabled={!this.state.companyId}
          type={config.uploadBoxType}
          onClick={() => props.handleUploadClick()}
        >
          <UploadOutlined />
        </Button>
      </Tooltip>
      <Link to="/vehicleodometerrecords">
        {" "}
        <Button>{storeContext.translate("_RECORDS_")}</Button>
      </Link>
    </>
  );
};

VehicleOdometerListFields.contextType = StoreContext;
export default observer(withRouter(VehicleOdometerListFields));
