import React from "react";
import {  Button, Space, Row, Col, Select } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { DownloadOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";

import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
import CertificateReportStore from "../store/CertificateReportStore";
import VehicleStore from "../store/VehicleStore";

const { Option } = Select;
class CertificateReport extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyStore = new CompanyStore(this);
    this.certificateStore = new CertificateReportStore(this);
    this.vehicleStore = new VehicleStore(this);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      vehicleId: "",
    };
  }

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
    //   console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.vehicleStore.getVehiclesCompanyId( this.props.getUserData().userCompanyId);
      //  console.log(this.state.companyId)
      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_S_NO_"),
        dataIndex: "sno",
        width: "10%",
      },
      {
        title: this.context.translate("_ISSUING_DATE_"),
        dataIndex: "activateDate",
        width: "15%",
      },
      {
        title: this.context.translate("_SERVICE_"),
        dataIndex: "serviceDate",
        width: "15%",
      },
      {
        title: this.context.translate("_TRACKER_ID_"),
        dataIndex: "trackerId",
        width: "10%",
      },
      {
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_PLATENO_"
        ),
        dataIndex: "vehicleNo",
        width: "10%",
      },
      {
        title: this.context.translate("_CHASSIS_NUMBER_"),
        dataIndex: "chassisNo",
        width: "10%",
      },
      {
        title: this.context.translate("_VEHICLE_MODEL_"),
        dataIndex: "model",
        width: "10%",
      },
      {
        title: this.context.translate("_COMAPNY_NAME_"),
        dataIndex: "companyname",
        width: "15%",
      },
      {
        title: this.context.translate("_CONTACT_NO_"),
        dataIndex: "contact1No",
        width: "10%",
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ currentPage: 1, companyId: comp.value });

    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.certificateStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      vehicleId: this.state.vehicleId,
    });
    await this.setState({ loadData: 1 }); //need to reload data
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_CERTIFICATE_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space>
                <Button
                  disabled={
                    this.certificateStore?.certificateData?.length > 0 ? false : true
                  }
                  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    console.log(val);
                    const result = [];
                    this.columns.map((obj) => result.push(obj.dataIndex));
                    //  console.log(result.toString())
                    var getData = {
                      cid: this.state.companyId,
                      colList: result,
                    };
                    console.log(getData);
                    if (val) {
                      this.certificateStore.exportExcel(getData);
                    }
                  }}
                >
                  <DownloadOutlined />
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={(value) => {this.handleChange(value);
                    this.setState({vehicleId:''})
                  }}
                  //   value={
                  //     this.state.company_value_label
                  //       ? this.state.company_value_label
                  //       : null
                  //   }
                  // allowClear={true}
                  style={{ width: 200 }}
                />

                <Select
                  value={this.state.vehicleId ? this.state.vehicleId : null}
                  style={{ width: 180 }}
                  showSearch
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  onChange={async (val) => {
                    await this.setState({ vehicleId: val });
                    await this.handleSearch();
                  }}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.certificateStore?.total}
            columns={this.columns}
            dataSource={this.certificateStore?.certificateData}
            loading={this.certificateStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>
      </>
    );
  }
}
CertificateReport.contextType = StoreContext;
export default observer(withRouter(CertificateReport));
