import React, { Component } from "react";

import {
  Form,
  Select,
  Button,
  Input,
  Space,
  Checkbox,
  Row,
  Col,
  Spin,
  TimePicker,
} from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";

import EmailReportStore from "../store/EmailReportStore";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";

import { format, addMinutes } from "date-fns";
import CompanyStore from "../store/CompanyStore";
import dayjs from "dayjs";
const options = [];
for (let i = 1; i < 31; i++) {
  options.push({
    label: i,
    value: i,
  });
}

class EmailReportAdd extends Component {
  formRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = { show: false };

    this.state = {
      loading: false,
      companyId: "",
      getVehiclesNo: "",
      daily: false,
      weekly: false,
      monthly: false,
      loadList: 0,
      selectAllVehicles: false,
      selectedVehicle: [],
      er_Id: -1,
      loadingSpin: false,
      deviceSubGroupId: "",
      deviceGroupId: "",
    };
    // this.jobManagementStore = new this.JobManagementStore()

    this.emailReportStore = new EmailReportStore();
    this.companyStore = new CompanyStore();
  }

  async componentDidMount() {
    const data = await this.emailReportStore.getReportsName();
    // console.log(this.props.getUserData().userCompanyId);

    if (Number(this.props.getUserData().isadmin) !== -1) {
      this.context.viewStateStore.setEmailReportStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      this.setState({ companyId: this.props.getUserData().userCompanyId });

      const emailData = await this.companyStore.getCompanyData({
        companyId: this.props.getUserData().userCompanyId,
      });

      this.formRef.current.setFieldsValue({
        emailId: emailData[0].contact1Email,
      });
      await this.emailReportStore.getVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.emailReportStore.getVehicleGroup({
        companyId: this.props.getUserData().userCompanyId,
        userId: this.props.getUserData().userId,
        isadmin: this.props.getUserData().isadmin,
      });
    }
    // await this.setState({ loadList: 1 });
    const Id = this.props.Id;

    if (Id) {
      this.filldata(Id);
      this.setState({ Id: Id });
    }

    this.setState({ loadData: 1 });
  }

  async filldata(Id) {
    await this.setState({ loadingSpin: true });
    console.log(Id);
    const getEmailDetails = await this.emailReportStore.getEmailReportData(
      Id,
      this.props.companyId
    );
    console.log("getEmailDetails", getEmailDetails);

    await this.emailReportStore.getVehicles({
      companyId: this.props.companyId,
    });

    const singleData = await this.companyStore.getCompanyDataById(
      this.props.companyId
    );
    console.log(singleData);

    await this.setState({
      companyId: getEmailDetails.companyId,
      er_Id: getEmailDetails.er_Id,
    });

    this.formRef.current.setFieldsValue({
      er_Id: getEmailDetails.er_Id,
      companyId: singleData,
      reportFormat: getEmailDetails.reportFormat,
      reportIds: getEmailDetails.reportIds,
      frequency: getEmailDetails.frequency,
      daily: getEmailDetails.daily?.split(","),

      fromTime: getEmailDetails.fromTime,
      toTime: getEmailDetails.toTime,
      monthly: getEmailDetails.monthly,
      emailId: getEmailDetails.emailId,
      // daily:getEmailDetails.daily,
      cc: getEmailDetails.cc,
      weekly: getEmailDetails.weekly,
      vehicleIds: getEmailDetails.vehicleIds,
      active: getEmailDetails.active ? 1 : 0,
    });

    await this.setState({ loadingSpin: false });

    if (getEmailDetails.daily?.split(",")?.length > 0) {
      this.setState({ daily: true });
    }

    if (getEmailDetails.frequency === "Daily") {
      this.setState({ daily: true });
    }
    if (getEmailDetails.frequency === "Weekly") {
      this.setState({ weekly: true });
    }
    if (getEmailDetails.frequency === "Monthly") {
      this.setState({ monthly: true });
    }
    // console.log(getEmailDetails.frequency, "aaaaaaaaaaaa");
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  handleChange = async (comp) => {
    await this.setState({ companyId: comp.value });
    const data = await this.emailReportStore.getVehicles({
      companyId: comp.value,
    });

    const groupData = await this.emailReportStore.getVehicleGroup({
      companyId: comp.value,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });

    await this.setState({ companyId: comp.value });
    if (!this.props.Id) {
      this.context.viewStateStore.setEmailReportStates({
        companyId: comp.value,
      });
    }
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  };

  async handleSearch() {
    await this.emailReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  onEmailDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    console.log(values);

    const emailReport = {
      ...values,
      companyId: this.state.companyId,
      er_Id: this.state.er_Id,
    };
    console.log(emailReport);
    const data = {
      tokenId: "80bovn8u800tg1aq9bvr73kbhh",
      emailReport: emailReport,
    };

    const response = await this.emailReportStore.addData(data);
    // if (response?.success === "S") {
    //   await message.success(response.message);
    //   this.props.navigate("/emailreportlist");
    // } else {
    //   message.error(response.message);
    // }
    await this.props.response(response);
    await this.setState({ loading: false });
  };

  getFrequency = (value) => {
    // console.log(value);
    if (value === "Weekly") {
      this.setState({ weekly: true });
      this.setState({ daily: false });
      this.setState({ monthly: false });
    } else if (value === "Monthly") {
      this.setState({ monthly: true });
      this.setState({ weekly: false });
      this.setState({ daily: false });
    }
    else if (value === "Daily") {
      this.setState({ daily: true });
      this.setState({ monthly: false });
      this.setState({ weekly: false });
    }
  };

  handleSelectAllVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.emailReportStore?.vehicles.map((val) => {
        return val.vehicleId;
      });

      this.formRef.current.setFieldsValue({ vehicleIds: allVehicles });
    } else {
      this.formRef.current.setFieldsValue({ vehicleIds: [] });
    }
  };

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM
    console.log();
    let endTime = new Date(2000, 0, 1, 24, 0);
    for (let time = startTime; time < endTime; time = addMinutes(time, 15)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }
    // console.log(timeArray);
    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "HH:mm")}
        </Select.Option>
      );
    });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  getSubGroup = async (val) => {
    const subgroupData = await this.emailReportStore.getVehicleSubGroup({
      deviceGroupId: val,
      deviceSubGroupId: this.state.deviceSubGroupId,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      subgroup: val,
    });
    this.setState({ loadData: 1 });
  };

  getSubGroupVehicles = async (val) => {
    const subgroupData = await this.emailReportStore.getVehicleSubGroup({
      deviceGroupId: this.state.deviceGroupId,
      deviceSubGroupId: val,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });
    this.setState({ loadData: 1 });
  };
  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              ref={this.formRef}
              layout="vertical"
              onFinish={this.onEmailDetailsSubmit}
              //   {...this.layout}
              name="nest-message"
              className="formClass"
            >
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_SELECT_COMPANY_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={async (value, e) => {
                          await this.handleChange(value, e);
                          console.log(e.contact1Email);
                          //  await this.setState({emailId:e.contact1Email})
                          this.formRef.current.setFieldsValue({
                            emailId: e.contact1Email,
                          });
                        }}
                        //   style={{ width: 440 }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="groupId"
                    className="search-field"
                    label={this.context.translate("_GROUP_")}
                    // rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                      onSelect={async (val) => {
                        await this.getSubGroup(val);
                        await this.setState({ deviceGroupId: val });
                      }}
                    >
                      {this.emailReportStore.groupList?.map((val) => {
                        return (
                          <Select.Option
                            value={val.deviceGroupId}
                            key={val.deviceGroupId}
                          >
                            {val.text}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="subGroupId"
                    className="search-field"
                    label={this.context.translate("_SUB_GROUP_")}
                    // rules={[{ required: true }]}
                  >
                    <Select
                      showSearch
                      filterOption={(inputValue, option) => {
                        if (option.children) {
                          return (
                            option.children
                              .toLowerCase()
                              .indexOf(inputValue.toLowerCase()) >= 0
                          );
                        }
                      }}
                      onSelect={async (val) => {
                        await this.setState({ deviceSubGroupId: val });
                        await this.getSubGroupVehicles(val);
                      }}
                    >
                      {this.emailReportStore.subGroupList?.map((val) => {
                        return (
                          <Select.Option
                            value={val.deviceSubGroupId}
                            key={val.deviceSubGroupId}
                          >
                            {val.subgroupname}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                  <Space>
                    <Form.Item
                      name="vehicleIds"
                      // label="Vehicle No"
                      label={this.context.translate("_VEHICLE_NO_")}
                      rules={[{ required: true }]}
                      className="search-field"
                    >
                      <Select
                        maxTagCount={"responsive"}
                        // placeholder="Select Asset"
                        placeholder={this.context.translate(
                          "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                        )}
                        style={{ width: "239px" }}
                        mode="multiple"
                        showSearch
                        value={this.state.selectedVehicle}
                        onChange={(val) => {
                          console.log(val);
                          this.setState({ selectedVehicle: val });
                        }}
                        fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                        optionFilterProp="children"
                        options={this.emailReportStore?.vehicles}
                      />
                    </Form.Item>
                    <Form.Item label=" ">
                      <Checkbox
                        style={{ marginRight: 5 }}
                        checked={this.state.selectAllVehicles}
                        onChange={async (e) => {
                          await this.setState({
                            selectAllVehicles: e.target.checked,
                          });
                          console.log(e.target.checked);
                          await this.handleSelectAllVehicles();
                        }}
                      />
                      {/* All */}
                      {this.context.translate("_ALL_")}
                    </Form.Item>
                  </Space>

                  <Form.Item
                    name="reportIds"
                    className="search-field"
                    label={this.context.translate("_REPORT_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      maxTagCount={"responsive"}
                      mode="multiple"
                      showSearch
                    >
                      {this.emailReportStore.reportsList?.map((val) => {
                        return (
                          <Select.Option
                            value={Number(val.reportId)}
                            key={val.reportId}
                          >
                            {val.reportName}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="scheduledAt"
                    label={this.context.translate("_SHEDULED_AT_")}
                    initialValue={dayjs("23:55", "HH:mm")}
                  >
                    <TimePicker
                      format="HH:mm"
                      style={{ width: "100%" }}
                    ></TimePicker>
                  </Form.Item>

                  <Form.Item
                    name="frequency"
                    label={this.context.translate("_FREQUENCY_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onSelect={(value) => {
                        this.getFrequency(value);
                        // console.log(value);
                      }}
                    >
                      <Select.Option value="Daily">
                        {this.context.translate(
                          "_FLEET_RULELIST_CRONRULE_DAILY_"
                        )}
                      </Select.Option>
                      <Select.Option value="Weekly">
                        {this.context.translate(
                          "_FLEET_RULELIST_CRONRULE_WEEKLY_"
                        )}
                      </Select.Option>
                      <Select.Option value="Monthly">
                        {this.context.translate("_MONTHLY_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={2}></Col> */}

                <Col span={12} className="formColumnClass">
                  {/* <div style={{ display: this.state.weekly ? "" : "none" }}> */}
                  {this.state.weekly && (
                    <>
                      <Form.Item
                        name="weekly"
                        label={this.context.translate(
                          "_FLEET_RULEDETAIL_CRONRULE_WEEKLY_"
                        )}
                      >
                        <Select>
                          <Select.Option value="1">
                            {this.context.translate("_SUNDAY_")}
                          </Select.Option>
                          <Select.Option value="2">
                            {this.context.translate("_MONDAY_")}
                          </Select.Option>
                          <Select.Option value="3">
                            {this.context.translate("_TUESDAY_")}
                          </Select.Option>
                          <Select.Option value="4">
                            {this.context.translate("_WEDNESDAY_")}
                          </Select.Option>
                          <Select.Option value="5">
                            {this.context.translate("_THURSDAY_")}
                          </Select.Option>
                          <Select.Option value="6">
                            {this.context.translate("_FRIDAY_")}
                          </Select.Option>
                          <Select.Option value="7">
                            {this.context.translate("_SATURDAY_")}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </>
                  )}

                  {this.state.monthly && (
                    <>
                      <Form.Item
                        name="monthly"
                        // label="Monthly"
                        label={this.context.translate("_MONTHLY_")}
                      >
                        <Select
                          // mode="multiple"
                          allowClear
                          options={options}
                        />
                      </Form.Item>
                    </>
                  )}

                  {this.state.daily && (
                    <>
                      <Form.Item
                        name="fromTime"
                        // label="From Time"
                        label={this.context.translate("_FROM_TIME_")}
                      >
                        <Select
                          allowClear
                          // placeholder="Select Time"
                          placeholder={this.context.translate("_SELECT_TIME_")}
                        >
                          {this.timeOptions()}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="toTime"
                        // label="To Time"
                        label={this.context.translate("_TO_TIME_")}
                      >
                        <Select
                          allowClear
                          // placeholder="Select Time"
                          placeholder={this.context.translate("_SELECT_TIME_")}
                        >
                          {this.timeOptions()}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="daily"
                        // label="Select Days"
                        label={this.context.translate("_SELECT_DAYS_")}
                        className="search-field"
                      >
                        <Select
                          maxTagCount={"responsive"}
                          mode="multiple"
                          allowClear
                          // placeholder="Select days"
                          placeholder={this.context.translate("_SELECT_DAYS_")}
                        >
                          <Select.Option value="1">
                            {this.context.translate("_SUNDAY_")}
                          </Select.Option>
                          <Select.Option value="2">
                            {this.context.translate("_MONDAY_")}
                          </Select.Option>
                          <Select.Option value="3">
                            {this.context.translate("_TUESDAY_")}
                          </Select.Option>
                          <Select.Option value="4">
                            {this.context.translate("_WEDNESDAY_")}
                          </Select.Option>
                          <Select.Option value="5">
                            {this.context.translate("_THURSDAY_")}
                          </Select.Option>
                          <Select.Option value="6">
                            {this.context.translate("_FRIDAY_")}
                          </Select.Option>
                          <Select.Option value="7">
                            {this.context.translate("_SATURDAY_")}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </>
                  )}
                

                  <Form.Item
                    name="reportFormat"
                    label={this.context.translate("_REPORT_FORMAT_")}
                    rules={[{ required: true }]}
                  >
                    <Select>
                      <Select.Option value="PDF">
                        {this.context.translate("_PDF_")}
                      </Select.Option>
                      <Select.Option value="Excel">
                        {this.context.translate("_EXCEL_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="emailId"
                    label={this.context.translate("_EMAIL_ID_")}
                    rules={[
                      {
                        type: "email",
                        // message: "Please enter a valid email address",
                        message: this.context.translate(
                          "_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS_"
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="cc"
                    label={this.context.translate("_CC_")}
                    rules={[
                      {
                        type: "email",
                        // message: "Please enter a valid email address",
                        message: this.context.translate(
                          "_PLEASE_ENTER_A_VALID_EMAIL_ADDRESS_"
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="active"
                    label=""
                    valuePropName="checked"
                    style={{ marginTop: "30px" }}
                  >
                    <Checkbox>{this.context.translate("_ACTIVE_")}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <div className="formBtnGroup">
                <Button
                  className="formCancelButton"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                    this.setState({ selectAllVehicles: false });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

EmailReportAdd.contextType = StoreContext;
export default observer(withRouter(EmailReportAdd));
