import React from "react";
import {
  Col,
  Row,
  Select,
  Button,
  Space,
  Modal,
  message,
  Tooltip,
  Form,
  Upload,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { PlusOutlined, UploadOutlined } from "@ant-design/icons";

import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import VehicleOdometerStore from "../store/VehicleOdometerStore";
import withRouter from "../withRouter";

import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import dayjs from "dayjs";
import CompanyStore from "../store/CompanyStore";
import VehicleOdometerAdd from "./VehicleOdometerAdd";
import config from "../config";
import { decode as base64_decode } from "base-64";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import VehicleOdometerListFields from "./Plugins/AddVehicle/VehicleOdometerListFields";

const { Option } = Select;
class VehicleOdometerList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.vehicleOdometerStore = new VehicleOdometerStore();
    this.workingHoursStore = new VehicleWorkingHoursStore();
    this.companyStore = new CompanyStore();
    this.cookies = new Cookies();
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      openConfirm: false,
      confirmLoading: false,
      companyId: "",
      loadData: 0,
      offset: 0,
      limit: 50,
      selectedVehicles: "",
      companyVal_Lab: "",
      openOdometerId: "",
      openOdometerAddForm: false,
      listDataUpdate: false,
      showUpload: false,
      companyFieldHighlight: false,
    };
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setOdometer = this.context.viewStateStore.vehicleOdometerObject;

      await this.setState({ companyId: setOdometer.companyId });
      // await this.workingHoursStore.getAssignAsset({
      //   companyId: setOdometer.companyId,
      // });

      if (setOdometer.companyId !== "") {
        await this.vehicleOdometerStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: setOdometer.companyId,
          vehicleId: this.state.selectedVehicles,
        });
        const singleData = await this.companyStore.getCompanyDataById(
          setOdometer.companyId
        );
        await this.workingHoursStore.getAssignVehicles({
          companyId: setOdometer.companyId,
        });
        await this.setState({ loadList: 1 });

        await this.setState({ companyVal_Lab: singleData });
      }
      this.setState({ listDataUpdate: false });
      // await this.vehicleOdometerStore.loadData({ offset: 0, limit: 50 });
    }
  }
  async componentDidMount() {
    // console.log(this.viewStateStore.)
    if (Number(this.props.getUserData().isadmin) !== -1 && Number(this.props.getUserData().isadmin) !== 3) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.setState({
        excelCompanyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      const data = await this.workingHoursStore.getAssignVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        // title: "Account",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_CUSTOMER_"),
        dataIndex: "company",
        width: "20%",
      },
      {
        // title: " Asset Name",
        title: this.context.translate("_FLEET_REPORTS_VEHICLENO_"),
        dataIndex: "vehicleNo",
        width: "20%",
      },
      {
        // title: "GPS Odometer",
        title: this.context.translate("_GPS_ODOMETER_"),
        dataIndex: "gpsOdo",
        width: "20%",
      },
      {
        // title: "Change Odometer",
        title: this.context.translate("_CHANGE_ODOMETER_"),
        dataIndex: "odometer",
        width: "20%",
      },
      {
        // title: "Date Time  ",
        title: this.context.translate("_DATE_TIME_"),
        dataIndex: "datetime",
        width: "20%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
      {
        // title: " Odometer Travelled",
        title: this.context.translate("_ODOMETER_TRAVELLED_"),
        dataIndex: "travelledOdo",
        width: "20%",
      },
      {
        // title: "Total Odometer",
        title: this.context.translate("_TOTAL_ODOMETER_"),
        dataIndex: "totalOdo",
        width: "20%",
      },
      {
        // title: "Diff Odometer",
        title: this.context.translate("_DIFF_ODOMETER_"),
        dataIndex: "diffOdo",
        width: "20%",
      },
      {
        // title: "Diff Odometer",
        title: this.context.translate("_CREATED_DATE_"),
        dataIndex: "createDate",
        width: "20%",
        render: (value) => {
          if (value) {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          } else {
            return <></>;
          }
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);

    await this.setState({ companyVal_Lab: singleData });
    // await this.setState({ companyId: comp.value });
    await this.setState({ selectedVehicles: "" });
    const data = await this.workingHoursStore.getAssignVehicles({
      companyId: comp.value,
    });

    await this.setState({ loadData: 1 });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleOdometerStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      vehicleId: this.state.selectedVehicles,
    });

    await this.setState({ loadData: 1 });
  }

  responseMsg = async (resp) => {
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      this.setState({ openOdometerAddForm: false });
      this.setState({ listDataUpdate: true });
      // this.props.navigate("/vehicleodometerlist");
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["username"]);
      });
    }
  };

  getUploadData = async (fileUp) => {
    let fileinfo = fileUp.file.response;

    console.log(fileinfo);

    if (fileinfo?.success == "S") {
      // console.log(fileinfo.data)

      await this.setState({ excelFile: fileinfo.data });
    }

    this.setState({ update: 1 });
  };

  onExcelSubmit = async (fieldsValue) => {
    console.log(fieldsValue);
    const data = {
      ...fieldsValue,
      companyId: this.state.excelCompanyId,
      excelFile: this.state.excelFile,
    };
    const resp = await this.vehicleOdometerStore.addOdometerByExcel(data);

    console.log(resp);
    if (resp?.success == "S") {
      message.success(resp.message);

      this.setState({ showUpload: false });
      this.setState({ excelFile: "" });
      this.handleSearch();
      // this.formRef.current?.resetFields();
    } else {
      message.error(resp.errors[0].error);
    }
    if (resp.message) {
      this.setState({ showUpload: false });
      this.formRef.current?.resetFields();
    }
  };

  closeModal = () => {
    this.setState({ openOdometerAddForm: false, openOdometerId: "" });
  };

  handleUploadClick =() =>{
    if (!this.state.companyId) {
      this.setState({ companyFieldHighlight: true });
      this.setState({ showUpload: false });
    } else {
      this.setState({ companyFieldHighlight: false });
      this.setState({ showUpload: true });
    }
  }
  render() {
    const companyInputBox = this.state.companyFieldHighlight
      ? { border: "2px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_ODOMETER_ADJUSTMENT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  // title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => this.setState({ openOdometerAddForm: true })}
                  ></Button>
                </Tooltip>

                
                <VehicleOdometerListFields handleUploadClick={this.handleUploadClick}/>


                {/* <AddButton
                  title={this.context.translate("_ADD_")}
                  link="/vehicleodometeradd"
                /> */}
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  // allowClear={true}
                  onChange={(value) => {
                    this.handleChange(value);
                  }}
                  value={
                    this.state.companyVal_Lab ? this.state.companyVal_Lab : null
                  }
                  // style={{ width: 200 }}
                  style={companyInputBox}
                />

                <Select
                  // placeholder="Select Asset"
                  placeholder={this.context.translate(
                    "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                  )}
                  style={{ width: 200 }}
                  defaultValue={"-1"}
                  // maxTagCount={"responsive"}
                  // mode="multiple"
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onChange={async (val) => {
                    await this.setState({ selectedVehicles: val });
                    await this.handleSearch();
                    await this.setState({ loadData: 1 });
                  }}
                  showSearch
                  // fieldNames={{
                  //   value: "vehicleId",
                  //   label: "vehicleNo",
                  // }}
                  optionFilterProp="children"
                  // options={this.vehicleWorkingHoursStore?.assignAssetList}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.workingHoursStore?.assignAssetList?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.vehicleOdometerStore?.total}
            columns={this.columns}
            dataSource={this.vehicleOdometerStore?.vehicleOdometerVehicles}
            loading={this.vehicleOdometerStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.showUpload && (
          <>
            {" "}
            <Modal
              open={this.state.showUpload}
              // title="File Upload"
              title={this.context.translate("_FILE_UPLOAD_")}
              maskClosable={false}
              onOk={() => this.setState({ showUpload: false })}
              onCancel={() =>
                this.setState({
                  showUpload: false,
                  excelFile: "",
                  companyId: "",
                })
              }
              footer={false}
              width={400}
              // style={{width:100}}
            >
              <Form
                name="excelimport"
                layout="vertical"
                onFinish={this.onExcelSubmit}
                style={{ padding: "0px 10px" }}
              >
                {(this.props.getUserData().isadmin === -1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={(companyId) => {
                        console.log(companyId.value);
                        this.setState({ excelCompanyId: companyId.value });
                        // this.formRef.current.setFieldsValues({excelFile:undefined})
                      }}
                      style={{ width: 300 }}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="excelFile"
                  // className="label-seprator"

                  className="uploadFile"
                  // label="Excel File"
                  label={this.context.translate("_EXCEL_FILE_")}
                  rules={[{ required: true }]}
                >
                  <Upload
                    // beforeUpload={(file) => this.validateFile(file)}
                    onRemove={() =>
                      this.setState({ excelFile: "", fileList: [] })
                    }
                    name="excelFile"
                    action={
                      config.baseURL + "/index.php?c=company&a=fileupload"
                    }
                    listType="text"
                    data={{
                      fileType: "accountimport",
                      fieldName: "excelFile",
                    }}
                    onChange={this.getUploadData}
                    // beforeUpload={(file) => this.validateFile(file)}
                    maxCount={1}
                  >
                    <Button icon={<UploadOutlined />}>
                      {this.context.translate("_CLICKTOUPLOAD_EXCELFILE_")}
                    </Button>
                  </Upload>
                </Form.Item>

                <div className="formBtnGroup">
                  <Button
                    className="formCancelButton"
                    onClick={(val) => {
                      var colList = "vehicleNo,distance,date,time";
                      if (true) {
                        var getData = {
                          companyId: this.state.companyId,
                          isadmin: JSON.parse(
                            base64_decode(this.cookies.get("data"))
                          ).isadmin,
                          userId: JSON.parse(
                            base64_decode(this.cookies.get("data"))
                          ).userId,

                          colList: colList,
                        };

                        if (val) {
                          // console.log("afd")
                          this.vehicleOdometerStore.exportExcel(getData);
                        }
                      }
                    }}
                  >
                    {this.context.translate("_SAMPLE_")}
                  </Button>
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                    // loading={this.state.loading}
                  >
                    {this.context.translate("_UPLOAD_")}
                  </Button>
                </div>
              </Form>
            </Modal>{" "}
          </>
        )}

        {this.state.openOdometerAddForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Adjust Odometer"
            title={this.context.translate("_ADJUST_ODOMETER_")}
            centered
            visible={this.state.openOdometerAddForm}
            footer={null}
            onCancel={() => {
              this.setState({ openOdometerAddForm: false, openOdometerId: "" });
            }}
            width={665}
          >
            <div>
              <VehicleOdometerAdd
                openOdometerId={this.state.openOdometerId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
VehicleOdometerList.contextType = StoreContext;
export default observer(withRouter(VehicleOdometerList));
