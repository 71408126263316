import React, { Component } from "react";
import { Button, Form, Input, message, Select } from "antd";

import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../withRouter";
import AddSocialMediaStore from "../store/AddSocialMediaStore";

class AddSocialMedia extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      allCompany: false,
      loadList: 0,
      companyId: [],
    };

    this.addSocialMediaStore = new AddSocialMediaStore(this);
    this.formRef = React.createRef();
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
    }
    const data = await this.addSocialMediaStore.loadSocialMediaData(
      this.props.getUserData().userCompanyId
    );
    console.log(data);
    this.formRef.current.setFieldsValue({
      facebookLink: data.facebookLink,
      linkedinLink: data.linkedinLink,
      instagramLink: data.instagramLink,
      emailLink: data.emailLink,
      tiktokLink: data.tiktokLink,
      whatsappNo: data.whatsappNo,
    });

    await this.setState({ loadData: 1 });
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
  }

  onAddData = async (fieldsValue) => {
    const data = {
      ...fieldsValue,
      Id: 1,
      companyId: this.props.getUserData().userCompanyId,
    };
    // console.log(fieldsValue);
    const response = await this.addSocialMediaStore.addData(data);

    if (response?.data.success === "S") {
      message.success(response?.data.message);
      // this.formRef.current?.resetFields();
    } else {
      message.error(response?.data.error);
    }
  };

  render() {
    return (
      <>
        <div className="changePassHeadline">
          {this.context.translate("_ADD_SOCIAL_MEDIA_")}
        </div>
        <div className="changePassLayout">
          <Form
            ref={this.formRef}
            layout="vertical"
            name="nest-messages"
            onFinish={this.onAddData}
            className="changePassForm"
          >
            <div style={{ height: "400px", overflowY: "auto",padding:"10px",overflowX:'hidden' }}>
              <Form.Item
                name="facebookLink"
                rules={[{ required: true }]}
                label={this.context.translate("_FACEBOOK_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="instagramLink"
                rules={[{ required: true }]}
                label={this.context.translate("_INSTAGRAM_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="linkedinLink"
                rules={[{ required: true }]}
                label={this.context.translate("_LINKEDIN_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="emailLink"
                rules={[{ required: true }]}
                label={this.context.translate("_EMAIL_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="tiktokLink"
                rules={[{ required: true }]}
                label={this.context.translate("_TIKTOK_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="whatsappNo"
                rules={[{ required: true }]}
                label={this.context.translate("_WHATSAPP_")}
                style={{ width: "100%" }}
              >
                <Input />
              </Form.Item>
            </div>
            <div className="formBtnGroup">
              <Button
                className="formCancelButton"
                onClick={(e) => {
                  this.formRef.current?.resetFields();
                  this.setState({ allCompany: false });
                }}
              >
                {this.context.translate("_FLEET_CLEAR_BTN_")}
              </Button>
              <Button
                className="formSaveButton"
                type="primary"
                htmlType="submit"
              >
                {this.context.translate("_FLEET_DASHBORD_SETTING_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </div>
      </>
    );
  }
}
AddSocialMedia.contextType = StoreContext;
export default observer(withRouter(AddSocialMedia));
