import { Button, Tooltip } from "antd";
import { FileTextOutlined } from "@ant-design/icons";
import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Link } from "react-router-dom";
/*"displayDomains" : ["awtltrack"],*/

const GlobalList = (props) => {
  console.log("props", props);

  // const shouldShow = baseConfig.displayDomains.includes(config.project);
  const shouldShow = shouldShowComponent(config.project, "GlobalList");

  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      {/* <Tooltip title="Global" color={config.tooltipColorEdit}> */}
        <Link to={"/globalSearchList"}>
          {" "}
          <Button>
          <i class="bi bi-file-earmark-font" style={{ marginRight: "5px" }}></i>Global
            {/* <FileTextOutlined style={{ fontSize: "20px" }} /> Global */}
          </Button>
        </Link>
      {/* </Tooltip> */}
    </>
  );
};

GlobalList.contextType = StoreContext;
export default observer(withRouter(GlobalList));
