import React from "react";

import { Input, message, Button, Space, Row, Col, Tooltip, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import { DeleteFilled, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";

import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import StaffStore from "../store/StaffStore";
import CompanyStore from "../store/CompanyStore";
import StaffAdd from "./StaffAdd";
const { Search } = Input;

class CompanyBlackListPersonList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);

    this.onDelete = this.onDelete.bind(this);

    this.staffStore = new StaffStore(this);
    this.companyStore = new CompanyStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_name: "",
      listUpdated: 0,
      companyId_value_label: "",
      salesPersonName: "",
      openStaffDetailsForm: false,
      staffId: "",
      listDataUpdate: false,
    };
  }
  onEdit = async (record) => {
    console.log(record.id, "re");
    await this.context.viewStateStore.setStaffStates({
      companyId: this.state.companyId,
      searchText: this.state.salesPersonName,
    });
    await this.context.viewStateStore.setStaffStates({
      companyId: this.state.companyId,
    });
    await this.setState({ staffId: record.id });
    await this.setState({ openStaffDetailsForm: true });
    // await this.props.navigate("/staffadd/" + record.id);
  };
  async onDelete(record) {
    console.log(record.id);
    const response = await this.staffStore.deleteRecord({
      id: record.id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }
  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const staffStates = this.context.viewStateStore.staffStateObject;
      const singleData = await this.companyStore.getCompanyDataById(
        staffStates.companyId
      );
      await this.setState({
        companyId_value_label: singleData,
        salesPersonName: staffStates.searchText,
        companyId: staffStates.companyId,
      });

      console.log(staffStates);
      await this.staffStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: staffStates.companyId,
        salesPersonName: staffStates.searchText,
        isadmin: this.props.getUserData().isadmin,
      });
      await this.setState({ listDataUpdate: false });
      // await this.setState({salesPersonName:staffStates.searchText,})
    }
  }
  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      //  console.log(this.state.companyId)
      // await this.setState({userId:this.props.getUserData().userId})
      await this.handleSearch();
    }

    // await this.staffStore.loadData({ offset: 0, limit: 50 });
    this.setState({ pageSize: 50 });
    // await this.handleSearch();

    // await this.setState({ listUpdated: 1 });

    this.columns = [
      {
        // title: "Sales Person Id",
        title: this.context.translate("_Id_"),
        dataIndex: "id",
        width: "5%",
      },
      {
        // title: "Name",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_NAME_"),
        dataIndex: "name",
        width: "5%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value}
            </Link>
          );
        },
      },
      {
        // title: "Mobile No",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_MOBILE_"
        ),
        dataIndex: "mobileno",
        width: "5%",
      },
      {
        // title: "Address",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
        dataIndex: "address",
        width: "5%",
      },
      {
        // title: "Company",
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_COMPANY_"),
        dataIndex: "companyName",
        width: "5%",
      },
      {
        // title: "Employee Type",
        title: this.context.translate("_EMPLOYEE_TYPE_"),
        dataIndex: "salesPersonTypeId",
        width: "5%",
        render: (mainValue) => {
          let value = Number(mainValue);
          if (value === 1) {
            return "Sales Executive";
          } else if (value === 2) {
            return "Sales Co-ordinator";
          } else if (value === 3) {
            return "Transport Co-ordinator";
          } else if (value === 7) {
            return "Bus Attendant";
          }else if (value === 8) {
            return "Teacher";
          }else if (value === 9) {
            return "Operations";
          }
        },
      },
      {
        // title: "Department",
        title: this.context.translate("_DEPARTMENT_"),
        dataIndex: "departmentName",
        width: "5%",
      },
      {
        // title: "Employee Id",
        title: this.context.translate("_EMPLOYEE_ID_"),
        dataIndex: "employeeId",
        width: "5%",
      },
      {
        // title: "Employee Status",
        title: this.context.translate("_EMPLOYEE_STATUS_"),
        dataIndex: "Employementstatus",
        width: "5%",
      },
      {
        // title: "Working Hours",
        title: this.context.translate("_WORKING_HOUR_"),
        dataIndex: "workingHours",
        width: "5%",
      },
      {
        // title: "Overtime Charges",
        title: this.context.translate("_OVERTIME_CHARGE_"),
        dataIndex: "overtimeCharges",
        width: "5%",
      },
      {
        // title: "Other Permit",
        title: this.context.translate("_OTHER_PERMIT_"),
        dataIndex: "otherPermit",
        width: "5%",
      },
      {
        // title: "Permit Number",
        title: this.context.translate("_PERMIT_NO_"),
        dataIndex: "permitNo",
        width: "5%",
      },
      {
        // title: "Action",
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                  placement="bottom"
                    // title="Edit Staff Details"
                    title={this.context.translate("_EDIT_STAFF_DETAILS_")}
                    color={config.tooltipColorEdit}
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}
                <DeleteConfirm
                  onConfirm={(e) => {
                    this.onDelete(record);
                  }}
                >
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <Tooltip
                      placement="bottom"
                      // title="Delete Staff Details"
                      title={this.context.translate("_DELETE_STAFF_DETAILS_")}
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    await this.setState({ currentPage: 1, companyId: comp.value });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    await this.setState({ companyId_value_label: singleData });
    await this.handleSearch();
  }

  async searchInput(e) {
    const searchField = e.target.value;
    this.setState({ salesPersonName: searchField });
    if (searchField.length > 2 || searchField.length === 0) {
      await this.staffStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId_value_label
          ? this.state.companyId_value_label[0].value
          : this.state.companyId,
        salesPersonName: searchField,
      });
      await this.setState({ listUpdated: 1 });
    }
  }
  async handleSearch(e) {
    this.setState({ loading: true });
    await this.staffStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      salesPersonName: this.state.salesPersonName,
      isadmin: this.props.getUserData().isadmin,
    });
    await this.setState({ listUpdated: 1 });

    //need to reload data
  }
  responseMsg = async (response) => {
    if (response?.success === "S") {
      message.success(response.message);
      // this.props.navigate("/companystafflist");
      await this.setState({ listDataUpdate: true });
      await this.setState({ openStaffDetailsForm: false });
    } else {
      message.error(response.errors[0].username);
    }
  };
  closeModal = () => {
    this.setState({ openStaffDetailsForm: false, driverId: "" });
  };

  render() {
    //console.log('in render1')
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Staff List</div> */}
              <div className="heading">
                {this.context.translate("_EMPLOYEE_LIST_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space>
                <Tooltip
                  //  title="Add"
                  title={this.context.translate(
                    "_FLEET_DRIVER_DRIVERLIST_ADDBTN_"
                  )}
                  placement="left"
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      this.setState({
                        openStaffDetailsForm: true,
                        staffId: "",
                      });
                    }}
                  ></Button>
                </Tooltip>

                {/* <Button>Export</Button> */}
                {/* <Button>{this.context.translate("_EXPORT_")}</Button> */}
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  value={
                    this.state.companyId_value_label !== ""
                      ? this.state.companyId_value_label
                      : null
                  }
                  onChange={this.handleChange}
                  // allowClear={true}
                  style={{ width: 200 }}
                />

                <Input
                  // placeholder="Sales Person Name"
                  placeholder={this.context.translate("_SALES_PERSON_NAME_")}
                  style={{ width: 200 }}
                  value={
                    this.state.salesPersonName !== ""
                      ? this.state.salesPersonName
                      : ""
                  }
                  onChange={async (e) => {
                    await this.searchInput(e);
                  }}
                ></Input>
                {/* <Button
                  icon={<SearchOutlined />}
                  type="primary"
                  onClick={() => {
                    this.handleSearch();
                  }}
                /> */}
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/*<Button  ><PlusOutlined />WASL</Button>
                <Button  ><PlusOutlined />WASL Station</Button>*/}
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.staffStore?.total}
            columns={this.columns}
            dataSource={this.staffStore?.staff}
            loading={this.staffStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openStaffDetailsForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal" //  {/* css class */}
            // title="Staff Details"
            title={this.context.translate("_EMPLOYEE_DETAILS_")}
            centered
            visible={this.state.openStaffDetailsForm}
            footer={null}
            onCancel={() => {
              this.setState({ openStaffDetailsForm: false, driverId: "" });
            }}
            width={665}
          >
            <div>
              <StaffAdd
                staffId={this.state.staffId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
CompanyBlackListPersonList.contextType = StoreContext;
export default observer(withRouter(CompanyBlackListPersonList));
