import { encode as base64_encode } from "base-64";
import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";
import config from "../config.js";
class RtaOfficialsStore {
  rtaOfficialsData = [];
  total = 0;
  loading = false;
  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      rtaOfficialsData: observable,
      total: observable,
      loading: observable,
      loadData: action,
      setPhotoFromDb: action,
      addData: action,
      deleteRecord: action,
      getRtaData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ offset, limit, companyId }) {
    this.loading = true;

    var postData = { companyId: companyId, offset: offset, limit: limit };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rtoOffice&a=getRtaDetail", "postData=" + base64Input);

    this.rtaOfficialsData = response?.data?.data?.rtadetails;

    this.total = parseInt(response?.data?.data?.TotalCount);
    this.loading = false;
  }

  async addData(rtaData) {
    var postData = { tokenId: "", user: rtaData };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rtoOffice&a=update", "postData=" + base64Input);
    console.log(response, "post");
    return response;
  }

  async deleteRecord({ userId }) {
    var postData = { user: { userId: userId } };
    console.log(postData);
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=user&a=delete", "postData=" + base64Input);
    return response;
  }

  async getRtaData({ companyId, userId }) {
    //console.log("users",userId,)
    var postData = {
      companyId: companyId,
      userId: userId,
      limit: 1,
      offset: 0,
    };
    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post("index.php?c=rtoOffice&a=getRtaDetail", "postData=" + base64Input);
    //  console.log(response)
    var singleRow = response.data.data.rtadetails[0];

    if (singleRow.photo) {
      const fileList = [
        {
          name: singleRow.photo,
          status: "success",
          url: config.fleetURL + "/images/users/" + singleRow.photo,
        },
      ];

      this.setPhotoFromDb(fileList);
    }

    return singleRow;
  }

  async setPhotoFromDb(photo) {
    console.log(photo);
    this.photoFromDb = { fileList: photo };
  }
}

export default RtaOfficialsStore;
