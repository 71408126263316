import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Space, Tooltip } from "antd";
import { DownloadOutlined, ShareAltOutlined } from "@ant-design/icons";

const VehicleActionColumnIcons = (props) => {
  console.log("props",props.vehicleId);
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleActionColumnIcons"
  );
  //   console.log("shouldShow**", shouldShow);
  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <Space>
      <Tooltip title="Download Certificate" color={config.activeColor}>
        <DownloadOutlined
          onClick={() => {
            props.vehicleStore.downloadCertificate(props.vehicleId);
          }}
        />
      </Tooltip>

      <Tooltip title="Share Certificate" color={config.activeColor}>
        <ShareAltOutlined
          onClick={() => {
           props.openSharemodal(props.vehicleId)
          }}
        />
      </Tooltip>
    </Space>
  );
};

VehicleActionColumnIcons.contextType = StoreContext;
export default observer(withRouter(VehicleActionColumnIcons));
