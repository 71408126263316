import React from "react";
import { Row, Col, Space, Tooltip, message, Button, Modal } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import ListTable from "./ListTable";
import config from "../config";
import withRouter from "../withRouter";
import DeleteConfirm from "./DeleteConfirm";
import { DeleteFilled, DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import CompanyStore from "../store/CompanyStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleAuditSore from "../store/VehicleAuditStore";
import VehicleAuditAdd from "./VehicleAuditAdd";

class VehicleAudit extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.companyStore = new CompanyStore(this);
    this.vehicleAuditStore = new VehicleAuditSore(this);
    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      loadData: 1,
      companyId: "",
      comp_value: "",
      listDataUpdate: false,
      openVehAuditForm: false,
    };
  }
  async componentDidMount() {

    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.handleSearch();
    }
    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_VEHICLE_NO_"),
        dataIndex: "vehicleNo",
        width: "10%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_DRIVERID_"),
        dataIndex: "driverName",
        // width: "25%",
      },
      {
        title: this.context.translate("_DATE_"),
        dataIndex: "date",
        // width: "25%",
      },
      {
        title: this.context.translate("_VEHICLE_AUDIT_SCORE_"),
        dataIndex: "vehicleAuditScore",
        // width: "25%",
      },
      {
        title: this.context.translate("_ACCIDENT_REASON_"),
        dataIndex: "comments",
        // width: "25%",
      },
      {
        title: this.context.translate("_OUTSIDE_PARTY_"),
        dataIndex: "outsidersn",
        // width: "25%",
        render: (val) => {
          if (val === 1) {
            return <>Yes</>;
          } else if(val === 0) {
            return <>No</>;
          }
        },
      },
      {
        title: this.context.translate("_ACCIDENT_TYPE_"),
        dataIndex: "accidentType",
        // width: "25%",
        render: (val) => {
          if (val === 1) {
            return <>Major</>;
          } else if (val === 2) {
            return <>Minor</>;
          }
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "action",
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Tooltip
                    placement="bottom"
                    // title="Delete Record"
                    title={this.context.translate("_DELETE_RECORD_")}
                    color={config.tooltipColorDelete}
                  >
                    <DeleteFilled style={{ color: config.deleteColor }} />
                  </Tooltip>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const setVehAuditData = this.context.viewStateStore.vehAuditObject;

      if (setVehAuditData !== "") {
        await this.vehicleAuditStore.loadData({
          companyId: this.state.companyId,
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          page: this.state.currentPage,
        });

        const singleData = await this.companyStore.getCompanyDataById(
          setVehAuditData
        );

        await this.setState({ loadList: 1 });

        await this.setState({
          comp_value: singleData,
          companyId: setVehAuditData,
        });
      }
      await this.setState({ listDataUpdate: false });
    }
  }

  onEdit = async (record) => {
    await this.context.viewStateStore.setVehAuditStates({
      companyId: record.companyId,
    });
    await this.setState({ Id: record.Id });
    await this.setState({ openVehAuditForm: true });
  };

  async onDelete(record) {
    const response = await this.vehicleAuditStore.deleteRecord({
      Id: record.Id,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.vehicleAuditStore.loadData({
      companyId: this.state.companyId,
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      pagee: this.state.currentPage,
    });
    await this.setState({ loadData: 1, loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  closeModal = () => {
    this.setState({ openVehAuditForm: false });
  };

  responseMsg = async (resp) => {
    if (resp.success === "S") {
      message.success(resp.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openVehAuditForm: false });
      await this.handleSearch();
    }
  };

  async handleChange(comp) {
    await this.setState({
      companyId: comp.value,
      currentPage: 1,
      pageSize: 50,
    });
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    console.log(singleData);

    await this.setState({ comp_value: singleData });

    await this.handleSearch();
    await this.setState({ loadList: 1 });
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_VEHICLE_AUDIT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Tooltip
                  placement="left"
                  //  title="Add"
                  title={this.context.translate("_FLEET_ROLE_ROLELIST_ADDBTN_")}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() =>
                      this.setState({ openVehAuditForm: true, Id: "" })
                    }
                  ></Button>
                </Tooltip>

                <Button
                  disabled={
                    this.vehicleAuditStore?.auditList?.length > 0 ? false : true
                  }
                  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  onClick={(val) => {
                    let result = [];
                    if (true) {
                      this.columns.map((obj) => result.push(obj.dataIndex));

                      var getData = {
                        companyId: this.state.companyId,
                        colList: result,
                      };

                      if (val) {
                        this.vehicleAuditStore.exportExcel(getData);
                      }
                    }
                  }}
                >
                   <DownloadOutlined/>
                  {this.context.translate("_EXPORT_")}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  value={this.state.comp_value ? this.state.comp_value : null}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>
          <ListTable
            currentPage={this.state.currentPage}
            total={this.vehicleAuditStore?.total}
            columns={this.columns}
            pageSize={this.state.pageSize}
            dataSource={this.vehicleAuditStore?.auditList}
            loading={this.vehicleAuditStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openVehAuditForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            title={this.context.translate("_ACCIDENT_ANALYSIS_")}
            centered
            open={this.state.openVehAuditForm}
            footer={null}
            onCancel={() => {
              this.setState({ openVehAuditForm: false, Id: "" });
            }}
            width={665}
          >
            <div>
              <VehicleAuditAdd
                Id={this.state.Id}
                companyId={this.state.companyId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
VehicleAudit.contextType = StoreContext;
export default observer(withRouter(VehicleAudit));
