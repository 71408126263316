import {Space, Dropdown,Form,Input, Select,Row,Col,DatePicker,Switch,Checkbox,Button} from 'antd';
import {
  DownOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useContext } from 'react';
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
/*"displayDomains" : ["awtltrack"],*/

  const VehicleListFields = (props) => {
        console.log('props',props);
       
    // const shouldShow = baseConfig.displayDomains.includes(config.project);
    const shouldShow = shouldShowComponent(config.project, "VehicleListFields");

     const storeContext = useContext(StoreContext); // Use the context here


    if (!shouldShow) {
      return null; // Do not render if the condition is not met
    }

    return (
    <>
           <Dropdown menu={props.menu}>
                    <Button>
                      <Space>
                        {/* WASL  */}
                        {storeContext.translate("_WASL_REPLY_")}
                        <DownOutlined />
                      </Space>
                    </Button>
                  </Dropdown>
        </>
      
    );
  };


  VehicleListFields.contextType = StoreContext;
export default observer(withRouter(VehicleListFields));
