import { Checkbox, Select, Space } from "antd";
import React, { Component } from "react";
import Cookies from "js-cookie";
import { decode as base64_decode } from "base-64";
import withRouter from "../withRouter";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import conf from "../config";
const { Option } = Select;
class DeviceGroupsForVehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAllGroups: false,
      deviceGroupIds: [],
      deviceGroupId: [],
      deviceSubGroupId: null,
    };

    // console.log(conf.project);
  }

  handleSelectAllDeviceGroups = async (e) => {
    if (this.state.selectAllGroups) {
      const allGroups = this.props.vehicleStore?.deviceGroups.map((val) => {
        return val.deviceGroupId;
      });
      // console.log(allGroups);
      await this.setState({ deviceGroupIds: allGroups });
    } else {
      await this.setState({ deviceGroupIds: [] });
    }
  };

  async componentDidMount() {
    // console.log(JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId);
    // if(Number(this.props.getUserData().isadmin)==1){
    const data = await this.props.vehicleStore.getDeviceGroups(
      JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId
    );

    this.setState({ loadData: 1 });
    // }
  }

  getSubGroup = async (val) => {
    const subGroups = await this.props.vehicleStore.getVehicleSubGroup({
      deviceGroupId: val,
      deviceSubGroupId: null,
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: 1,
      subgroup: val,
    });
    // console.log(subGroups);

    await this.setState({ loadData: 1 });

    if (subGroups) {
      await this.props.setLoadDta();
      await this.props.resetVehicles();
    }
  };

  getSubGroupVehicles = async (val) => {
    const subGroupsVehicles = await this.props.vehicleStore.getVehicleSubGroup({
      deviceGroupId: this.state.deviceGroupIds,
      deviceSubGroupId: val,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });
    // console.log(subGroupsVehicles)

    this.setState({ loadData: 1 });
    if (subGroupsVehicles) {
      await this.props.setLoadDta();
      await this.props.resetVehicles();
    }
  };

  render() {
    return (
      <Space>
        {/* {conf.project === "awtltrack" && (
          <>
            <Checkbox
              checked={this.state.selectAllGroups}
              onChange={async (e) => {
                await this.setState({
                  selectAllGroups: e.target.checked,
                });
                if (this.state.selectAllGroups) {
                  await this.props.vehicleStore.getDeviceGroupsofVehicles({
                    deviceGroupId: 0,
                    companyId: JSON.parse(base64_decode(Cookies.get("data")))
                      .userCompanyId,
                    userId: JSON.parse(base64_decode(Cookies.get("data")))
                      .userId,
                  });
                  await this.props.setLoadDta();
                }

                await this.handleSelectAllDeviceGroups();
                await this.props.resetVehicles();
              }}
            />{" "}
            {this.context.translate("_ALL_")} &nbsp;
            <Select
              placeholder={this.context.translate("_GROUPS_")}
              style={{ width: 130 }}
              value={this.state.deviceGroupId ? this.state.deviceGroupId : null}
              mode="multiple"
              maxTagCount="responsive"
              onChange={async (val) => {
                if (this.props.vehicleStore?.deviceGroups.length > val.length) {
                  this.setState({ selectAllGroups: false });
                } else {
                  this.setState({ selectAllGroups: true });
                }

                await this.setState({ deviceGroupId: val });
                await this.props.vehicleStore.getDeviceGroupsofVehicles({
                  deviceGroupId: this.state.deviceGroupId,
                  companyId: JSON.parse(base64_decode(Cookies.get("data")))
                    .userCompanyId,
                  userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
                });
                await this.props.resetVehicles();
                await this.props.setLoadDta();
                await this.setState({ loadData: 1 });
              }}
            >
              {this.props.vehicleStore?.deviceGroups?.map((val) => {
                return (
                  <Option key={val.deviceGroupId} value={val.deviceGroupId}>
                    {val.groupname}
                  </Option>
                );
              })}
            </Select>
          </>
        )} */}
        {/* <Checkbox
          checked={this.state.selectAllGroups}
          onChange={async (e) => {
            await this.setState({
              selectAllGroups: e.target.checked,
            });
           
            // if (this.state.selectAllGroups) {
            //   await this.props.vehicleStore.getDeviceGroupsofVehicles({
            //     deviceGroupId: 0,
            //     companyId: JSON.parse(base64_decode(Cookies.get("data")))
            //       .userCompanyId,
            //     userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            //   });
            //   await this.props.setLoadDta();
            // }

            await this.handleSelectAllDeviceGroups();
            // await this.props.resetVehicles();
          }}
        />
        {this.context.translate("_ALL_")}  */}
        <Select
          placeholder={this.context.translate("_GROUPS_")}
          style={{ width: 130 }}
          mode="multiple"
          maxTagCount="responsive"
          value={this.state.deviceGroupIds ? this.state.deviceGroupIds : null}
          onChange={async (val) => {
            // console.log(val)
            await this.getSubGroup(val);

            await this.setState({
              deviceGroupIds: val,
              deviceSubGroupId: undefined,
            });

            if (this.props.vehicleStore?.deviceGroups?.length > val.length) {
              // console.log(this.props.vehicleStore?.deviceGroups?.length,"aaaaaaaa",val.length)
              this.setState({ selectAllGroups: false });
            } else {
              this.setState({ selectAllGroups: true });
            }
          }}
          filterOption={(inputValue, option) => {
            if (option.children) {
              return (
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              );
            }
          }}
        >
          {this.props.vehicleStore?.deviceGroups?.map((val) => {
            return (
              <Option key={val.deviceGroupId} value={val.deviceGroupId}>
                {val.groupname}
              </Option>
            );
          })}
        </Select>
        <Select
          placeholder={this.context.translate("_SUB_GROUP_")}
          style={{ width: 130 }}
          value={
            this.state.deviceSubGroupId ? this.state.deviceSubGroupId : null
          }
          onSelect={(val) => {
            this.setState({ deviceSubGroupId: val });
          }}
          onChange={async (val) => {
            await this.getSubGroupVehicles(val);
          }}
          filterOption={(inputValue, option) => {
            if (option.children) {
              return (
                option.children
                  .toLowerCase()
                  .indexOf(inputValue.toLowerCase()) >= 0
              );
            }
          }}
        >
          {this.props.vehicleStore?.subGroupList?.map((val) => {
            return (
              <Option key={val.deviceSubGroupId} value={val.deviceSubGroupId}>
                {val.subgroupname}
              </Option>
            );
          })}
        </Select>
      </Space>
    );
  }
}
DeviceGroupsForVehicles.contextType = StoreContext;
export default observer(withRouter(DeviceGroupsForVehicles));
// export default DeviceGroupsForVehicles;
