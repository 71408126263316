import React from "react";
import {
  Form,
  Input,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  DatePicker,
  Dropdown,
  Checkbox,
  Modal,
  FloatButton,
  Popover,
  Tooltip,
  Collapse,
} from "antd";
import { Icon } from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";
import config from "../config";
import { CaretDownOutlined, CaretUpOutlined, CloseOutlined, DashOutlined, DownOutlined, MinusCircleOutlined, MinusOutlined, PlusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import TravelReportStore from "../store/TravelReportStore";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";

import L from "leaflet";
import dayjs from "dayjs";
import { MapContainer } from "react-leaflet";

import HistoryTrackingStore from "../store/HistoryTrackingStore";
// import LiveRoutePath from "./LiveRoutePath";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import AdditionalContentInMap from "./AdditionalContentInMap";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { Option } = Select;
const { Text } = Typography;
const { Panel } = Collapse;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};

const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
const { RangePicker } = DatePicker;
// const currentDate =
class TravelReportList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.vehicleStore = new VehicleStore();
    this.travelReportStore = new TravelReportStore();
    this.historyTrackingStore = new HistoryTrackingStore();
    this.mapRef = React.createRef();
    // this.liveRoutePath = new LiveRoutePath();
    this.state = {
      loading: false,
      pageSize: 50,
      offset: 0,
      limit: 50,
      loadData: 0,
      pageNo: 1,
      companyId: "",
      vehicleId: "",
      selectedVehicles: [],
      selectAllVehicles: false,
      modalVisible: false,
      showEmail: false,
      column: "",
      filter: "",
      interval: 0,
      vehicleObj: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      Notcompany: false,
      Notasset: false,
      isPlaying: true,
      showMonthPopup: false,
      showTable: true,
      showtotaltable: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "vehicleNo",
          render: (val) => {
            const doc = new DOMParser().parseFromString(val, "text/html");
            const strongElement = doc.querySelector("strong");
            return strongElement ? strongElement.textContent || "" : val;
          },
        },
        {
          // title: "Driver Name",
          title: context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
          ),
          dataIndex: "name",
          width: "15%",
          key: "name",
        },
        {
          // title: "Start Date/Time",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STARTDATETIME_"
          ),
          dataIndex: "startTime",
          width: "15%",
          key: "startTime",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Start Address",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STARTADDRESS_"
          ),
          dataIndex: "startLocation",
          width: "15%",
          key: "startLocation",
        },
        {
          // title: "Stop Date/Time",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STOPDATETIME_"
          ),
          dataIndex: "stopTime",
          width: "15%",
          key: "stopTime",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Stop Address",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_STOPADDRESS_"
          ),
          dataIndex: "stopLocation",
          width: "15%",
          key: "stopLocation",
        },
        {
          // title: "Distance Travelled",
          title: context.translate("_FLEET_RULESELECTORDETAIL_RULE13_TITLE_"),
          dataIndex: "distanceTravelled",
          width: "15%",
          key: "distanceTravelled",
          render: (val) => {
            const doc = new DOMParser().parseFromString(val, "text/html");
            const strongElement = doc.querySelector("strong");
            return strongElement ? strongElement.textContent || "" : val;
          },
        },
        {
          // title: "Duration",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_ELAPSEDTIME_"
          ),
          dataIndex: "elapsedTime",
          width: "15%",
          key: "elapsedTime",
          render: (val) => {
            const doc = new DOMParser().parseFromString(val, "text/html");
            const strongElement = doc.querySelector("strong");
            return strongElement ? strongElement.textContent || "" : val;
          },
        },
        {
          // title: "Start Odometer",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STARTOD_"),
          dataIndex: "startDist",
          width: "15%",
          key: "startDist",
        },
        {
          // title: "End Odometer",
          title: context.translate("_END_ODOMETER_"),
          dataIndex: "stopDist",
          width: "15%",
          key: "stopDist",
        },
        {
          // title: "Tag Id",
          title: context.translate("_TAG_ID_"),
          dataIndex: "tagId",
          width: "15%",
          key: "tagId",
        },
        {
          // title: "Fuel Consumed",
          title: context.translate("_FUEL_CONSUMED_"),
          dataIndex: "fuelConsumed",
          width: "15%",
          key: "fuelconsumed",
        },
        {
          // title: "View on Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          // dataIndex: "viewonmap",
          width: "15%",
          key: "view",
          render: (value, obj) => {
            return (
              <>
                <Link
                  onClick={async (e) => {
                    await this.setState({ viewMap: true });
                    await this.getOfflineData(value);
                  }}
                >
                  {" "}
                  <img
                    src={require("../assets/images/mapIcon/gps_icon.png")}
                    width={25}
                    height={25}
                  />
                </Link>
              </>
            );
          },
        },
      ]),
    };

    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
        {
          // label: "Total Excel",
          label: context.translate("_TOTAL_EXCEL_"),
          key: "totalexcel",
        },
        {
          // label: "Total PDF",
          label: context.translate("_TOTAL_PDF_"),
          key: "totalpdf",
        },
        {
          label: (
            <Link to onClick={(e) => this.setState({ showEmail: true })}>
              {/* Email */}
              {context.translate("_FLEET_LOGIN_EMAILLBL_")}
            </Link>
          ),
          key: "email",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            // userId: 1,
            // userId: this.state.userId,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.travelReportStore.exportExcel(getData);
          }
          if (val.key == "csv") {
            this.travelReportStore.exportcsv(getData);
          }
          if (val.key == "pdf") {
            this.travelReportStore.exportpdf(getData);
          }

          if (val.key == "xml") {
            this.travelReportStore.exportxml(getData);
          }
          if (val.key == "totalexcel") {
            this.travelReportStore.exporttotalexcel(getData);
          }
          if (val.key == "totalpdf") {
            this.travelReportStore.exporttotalpdf(getData);
          }
        }
      },
    };

    this.newColumns = widthAdderFunc([
      ...this.state.selectedColumns,
      {
        // title: "Start Load Voltage",
        title: context.translate("_START_LOAD_VOLTAGE_"),
        dataIndex: "startloadvoltage",
        width: 15,
        key: "startLoadVoltage",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "End Load Voltage",
        title: context.translate("_END_LOAD_VOLTAGE_"),
        dataIndex: "endloadvoltage",
        width: 15,
        key: "endLoadVoltage",
        render: (value) => {
          if (!value || value == "") {
            return "NA";
          }
          return value;
        },
      },
      {
        // title: "Start Luggage Load",
        title: context.translate("_START_LUGGAGE_LOAD_"),
        dataIndex: "weightFactor",
        width: 15,
        key: "startLuggageeLoad",
        render: (value, r) => {
          var loadVoltage = r.startloadvoltage;
          console.log(loadVoltage);
          var weightFactor = r.weightFactor;
          console.log(weightFactor);
          var emptyWeight = r.emptyWeight;
          console.log(emptyWeight);
          if (
            loadVoltage != null &&
            weightFactor != null &&
            emptyWeight != null
          ) {
            if (loadVoltage >= r.minVolt && loadVoltage <= r.maxVolt) {
              return 0;
            }
            var totalWeightKg = loadVoltage * weightFactor;
            if (totalWeightKg < emptyWeight) {
              totalWeightKg = emptyWeight;
            }

            var loadWeight = totalWeightKg - emptyWeight;
            return Math.round(loadWeight);
          } else {
            if (r.vehicleNo == "Total") {
              return 0;
            } else {
              return "NA";
            }
          }
        },
      },
      {
        // title: "End Luggage Load",
        title: context.translate("_END_LUGGAGE_LOAD_"),
        dataIndex: "weightFactor",
        width: 15,
        key: "endLuggageLoad",
        render: (value, r) => {
          var loadVoltage = r.startloadvoltage;
          console.log(loadVoltage);
          var weightFactor = r.weightFactor;
          console.log(weightFactor);
          var emptyWeight = r.emptyWeight;
          console.log(emptyWeight);
          if (
            loadVoltage != null &&
            weightFactor != null &&
            emptyWeight != null
          ) {
            if (loadVoltage >= r.minVolt && loadVoltage <= r.maxVolt) {
              return 0;
            }
            var totalWeightKg = loadVoltage * weightFactor;
            if (totalWeightKg < emptyWeight) {
              totalWeightKg = emptyWeight;
            }

            var loadWeight = totalWeightKg - emptyWeight;
            return Math.round(loadWeight);
          } else {
            if (r.vehicleNo == "Total") {
              return 0;
            } else {
              return "NA";
            }
          }
        },
      },
      {
        // title: "Start Empty Weight",
        title: context.translate("_START_EMPTY_WEIGHT_"),
        dataIndex: "emptyWeight",
        width: 15,
        key: "startEmptyWeight",
        render: (value) => {
          if (value) {
            return Math.round(value);
          } else {
            return "NA";
          }
        },
      },
      {
        // title: "End Empty Weight",
        title: context.translate("_END_EMPTY_WEIGHT_"),
        dataIndex: "emptyWeight",
        width: 15,
        key: "endEmptyWeight",
        render: (value) => {
          if (value) {
            return Math.round(value);
          } else {
            return "NA";
          }
        },
      },
      {
        // title: "Start Total Weight",
        title: context.translate("_START_TOTAL_WEIGHT_"),
        dataIndex: "weightFactor",
        width: 15,
        key: "starttotalWeight",
        render: (value, r) => {
          console.log(r, "rrr");
          var loadVoltage = r.startloadvoltage;
          // console.log(loadVoltage,"load")
          var weightFactor = r.weightFactor;

          if (loadVoltage != null && weightFactor != null) {
            var loadKg = loadVoltage * weightFactor;
            // console.log(loadKg,"loadkg")

            if (loadKg > r.emptyWeight) {
              return Math.round(loadKg);
            } else {
              return Math.round(r.emptyWeight);
            }
          } else {
            if (r.vehicleNo == "Total") {
              return 0;
            } else {
              return "NA";
            }
          }
        },
      },
      {
        // title: "End Total Weight",
        title: context.translate("_END_TOTAL_WEIGHT_"),
        dataIndex: "weightFactor",
        width: 15,
        key: "endtotalWeight",
        render: function (value, r) {
          var loadVoltage = r.endloadvoltage;
          var weightFactor = r.weightFactor;

          if (loadVoltage != null && weightFactor != null) {
            var loadKg = loadVoltage * weightFactor;

            if (loadKg > r.emptyWeight) {
              return Math.round(loadKg);
            } else {
              return Math.round(r.emptyWeight);
            }
          } else {
            if (r.vehicleNo == "Total") {
              return 0;
            } else {
              return "NA";
            }
          }
        },
      },
    ]);
  }

  getColorMarker = (v) => {
    var acc = v.acc;
    var idle = v.idleStatus;
    var Direction;
    if (v.direction != null) {
      Direction = v.direction;
    } else {
      Direction = 60;
    }
    var colorMarkr;
    if (idle == 1 && acc == 1) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Yellow-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Yellow-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Yellow-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Yellow-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Yellow-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Yellow-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Yellow-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Yellow-north-west.png";
      } else {
        colorMarkr = "Yellow-north.png";
      }
    } else if (acc == 1 && v.speed == 0) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Yellow-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Yellow-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Yellow-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Yellow-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Yellow-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Yellow-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Yellow-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Yellow-north-west.png";
      } else {
        colorMarkr = "Yellow-north.png";
      }
    } else if (acc == 1 && v.speed > 0) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Green-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Green-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Green-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Green-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Green-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Green-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Green-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Green-north-west.png";
      } else {
        colorMarkr = "Green-north.png";
      }
    } else if (acc == 0) {
      if (Direction >= 337 && Direction < 23) {
        colorMarkr = "Red-north.png";
      } else if (Direction >= 23 && Direction < 68) {
        colorMarkr = "Red-north-east.png";
      } else if (Direction >= 68 && Direction < 113) {
        colorMarkr = "Red-east.png";
      } else if (Direction >= 113 && Direction < 158) {
        colorMarkr = "Red-south-east.png";
      } else if (Direction >= 158 && Direction < 203) {
        colorMarkr = "Red-south.png";
      } else if (Direction >= 203 && Direction < 248) {
        colorMarkr = "Red-south-west.png";
      } else if (Direction >= 248 && Direction < 293) {
        colorMarkr = "Red-west.png";
      } else if (Direction >= 293 && Direction < 337) {
        colorMarkr = "Red-north-west.png";
      } else {
        colorMarkr = "Red-north.png";
      }
    }
    return colorMarkr;
  };
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.travelReportStore.loadData({ offset: 0, limit: 50 });
    await this.setState({ column: this.state.selectedColumns });

    this.columnsTotalTReport = [
      {
        // title: "Asset Name",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
        ),
        dataIndex: "vehicleNo",
        width: "15%",
        key: "vehicleNo",
        render: (value) => {
          // console.log(value);
          if (value === "") {
            return "Total"; // Return "Total" as a string
          } else {
            return value; // Return the actual value
          }
        },
      },
      {
        // title: "From Date",
        title: this.context.translate("_FROM_DATE_"),
        dataIndex: "fromDate",
        width: "15%",
        key: "fromDate",
      },
      {
        // title: "To Date",
        title: this.context.translate("_TO_DATE_"),
        dataIndex: "toDate",
        width: "15%",
        key: "toDate",
      },
      {
        // title: "Total Trips",
        title: this.context.translate("_TOTAL_TRIPS_"),
        dataIndex: "totaltrips",
        width: "15%",
        key: "totalTrips",
      },
      {
        // title: "Total Travel Duration",
        title: this.context.translate("_TOTAL_TRAVEL_DURATION_"),
        dataIndex: "totalElapsedTime",
        width: "15%",
        key: "totalTravelDuration",
      },
      {
        // title: "Total Distance",
        title: this.context.translate(
          "_FLEET_CREATEROUTE_ROUTEADD_TOTALDISTANCE_"
        ),
        dataIndex: "distanceTravelled",
        width: "15%",
        key: "totalDistance",
      },
    ];
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;
      // console.log(companyId)
      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
    await this.setState({ listUpdated: 1 });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.viewMap !== prevState.viewMap) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }
  getOfflineData = async (obj) => {
    this.historyTrackingStore.checked = true;
    this.historyTrackingStore.pgcount = 1;
    this.historyTrackingStore.offset = 0;
    this.historyTrackingStore.offlineDataNewObj = [];
    this.historyTrackingStore.newPolyLineArray = [];
    this.historyTrackingStore.totalCount = null;
    let data = {
      companyId: obj.companyId,
      vehicleId: obj.vehicleId,
      fromDate: obj.startTime,
      toDate: obj.stopTime,
      SelRoutOption: "1",
      TripEventdata: 1,
      tableName: "mt90",
    };
    const resp = await this.historyTrackingStore.setOfflineData(data);
    await this.drawingPolyline();
  };

  drawingPolyline = async () => {
    if (
      this.state.viewMap === false &&
      this.historyTrackingStore.checked === true
    ) {
      return;
    }
    if (
      this.historyTrackingStore?.offlineDataNewObj?.length > 0 &&
      this.historyTrackingStore.count !==
        this.historyTrackingStore?.offlineDataNewObj?.length
    ) {
      let vehicleObj = await this.historyTrackingStore.offlineDataNewObj[
        this.historyTrackingStore.count
      ];
      let polyLineArray = await this.historyTrackingStore.newPolyLineArray.push(
        [vehicleObj?.latitude, vehicleObj?.longitude]
      );

      if (this.state.isPlaying === false) {
        return;
      }
      setTimeout(() => {
        // console.log(this.liveRootPathStore.offlineShowingLatLong,"sammekpont")
        if (this.state.viewMap) {
          L.polyline(this.historyTrackingStore.newPolyLineArray, 13).addTo(
            this.mapRef.current
          );

          let imgName = this.getColorMarker(vehicleObj);
          // console.log(imgName)
          const marker = L.marker(
            [vehicleObj?.latitude, vehicleObj?.longitude],
            {
              icon: new Icon({
                iconUrl: require("../assets/images/BaloonIcons/" + imgName),
                iconSize: [26, 26], // size of the icon
              }),
            }
          ).addTo(this.mapRef.current);
          this.mapRef.current.setView(
            [vehicleObj?.latitude, vehicleObj?.longitude],
            18
          );
          // if (this.historyTrackingStore.newPolyLineArray.length > 2) {
          //   const bounds = [
          //     [
          //       this.historyTrackingStore.newPolyLineArray[
          //         this.historyTrackingStore.newPolyLineArray.length - 1
          //       ][0],
          //       this.historyTrackingStore.newPolyLineArray[
          //         this.historyTrackingStore.newPolyLineArray.length - 1
          //       ][1],
          //     ],
          //     [
          //       this.historyTrackingStore.newPolyLineArray[0][0],
          //       this.historyTrackingStore.newPolyLineArray[1][1],
          //     ],
          //   ];
          //   this.mapRef.current.fitBounds(bounds, { maxZoom: 15 });
          // }

          this.historyTrackingStore.count++;
          this.drawingPolyline();
        } else {
          return;
        }
      }, 700);
    }
  };
  playFunc = async () => {
    await this.setState({ isPlaying: true });
    await this.drawingPolyline();
  };

  closeMap = () => {
    this.historyTrackingStore.checked = false;
    this.historyTrackingStore.pgcount = 1;
    this.historyTrackingStore.offset = 0;
    this.historyTrackingStore.count = 1;
    this.historyTrackingStore.offlineDataNewObj = [];
    this.historyTrackingStore.newPolyLineArray = [];
    this.historyTrackingStore.totalCount = null;
    //

    this.mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
        this.mapRef.current.removeLayer(layer);
      }
    });
    this.mapRef.current.eachLayer((layer) => {
      if (layer instanceof L.Polyline) {
        this.mapRef.current.removeLayer(layer);
      }
    });
  };

  async handleChange(comp) {
    await this.setState({ Notcompany: false, pageNo: 1 });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    // await this.vehicleStore.getVehiclesCompanyId({ companyId: comp.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    this.setState({ Notasset: false });
    // await this.travelReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   pageNo: this.state.pageNo,
    //   vehicleId: this.state.selectedVehicles,
    // });
    await this.setState({ loadData: 1 });
    // this.setState({ loading: false });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination, "pagination");
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }

    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.travelReportStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          interval: this.state.interval,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.pageNo,
        });

        await this.setState({ loadData: 1 });
      }
    }
  };

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  showTotal = async () => {
    await this.travelReportStore?.totalStartStopReport({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      vehicleId: this.state.selectedVehicles,
      interval: this.state.interval,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
    });
    // console.log(this.state.fromDate,this.state.toDate,)
    await this.setState({ loadData: 1 });
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handlePanelChange = (key) => {
    console.log(key);
    if (this.state.activePanelKey.includes(key)) {
      // If the clicked panel is already open, close it
      this.setState({ activePanelKey: [] });
    } else {
      // If the clicked panel is closed, open it and close other panels
      this.setState({ activePanelKey: [key] });
    }
  };

  // handlePanelChange = (key) => {
  //   if (this.state.activePanelKey.includes(key)) {
  //     // If the clicked panel is already open, close it
  //     this.setState({ activePanelKey: [] });
  //   } else {
  //     // If the clicked panel is closed, open it and close other panels
  //     this.setState({ activePanelKey: [key] });
  //   }
  // };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  //  totalTripsSum = dataSourceWithTotal.reduce((acc, currentRow) => {
  //   // Parse the totaltrips value as an integer and add it to the accumulator
  //   return acc + parseInt(currentRow.totaltrips, 10) || 0;
  // }, 0);

  // console.log("Sum of Total Trips:", totalTripsSum);
  close;
  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    // const existingDataSource = this.travelReportStore?.totalStartStopArray;

    // const totalRow = { column1: "Totaltrip" };

    // const dataSourceWithTotal = [...existingDataSource, totalRow];

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Travel Report</div> */}
              <div className="heading">
                {this.context.translate(
                  "_FLEET_REPORTS_STARTSTOPREPORT_TITLE_"
                )}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async () => {
                    await this.showTotal();
                    // this.calculateTotalTripsSum()
                    console.log("asdasdssdf");
                    await this.setState({
                      showTable: false,
                      showtotaltable: true,
                    });
                    // this.setState({ showTable: !this.state.showTable,
                    //   showtotaltable: this.state.showtotaltable,})
                  }}
                >
                  {/* Total */}
                  {this.context.translate("_TOTAL_")}
                </Button>

              
                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.travelReportStore?.travelReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />

                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles:false
                      })
                    }
                    //  This={this}
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    // console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>

                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Input
                  value={this.state.interval}
                  style={{ width: 100 }}
                  // placeholder="Interval"
                  placeholder={this.context.translate("_INTERVAL_")}
                  onChange={(e) => {
                    this.setState({ interval: e.target.value });
                    console.log(e);
                  }}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.showData();
                    await this.setState({
                      showTable: true,
                      showtotaltable: false,
                    });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>
          <div>
            <div
             className="accordion-list"
              onClick={() =>
                this.setState({
                  showTable: !this.state.showTable,
                  showtotaltable: !this.state.showtotaltable,
                })
              }
            >
              {this.context.translate("_FLEET_REPORTS_STARTSTOPREPORT_TITLE_")}
              <Button>
                {this.state.showTable ? <CaretDownOutlined /> :<CaretUpOutlined />}
              </Button>
            </div>
            {this.state.showTable && (
              <ListTable
                currentPage={this.state.pageNo}
                pageSize={this.travelReportStore.pageSize}
                total={this.travelReportStore?.total}
                columns={this.state.column}
                dataSource={
                  this.travelReportStore?.travelReport.length > 0 && [
                    ...this.travelReportStore?.travelReport,
                  ]
                }
                loading={this.travelReportStore?.loading}
                onChange={this.handleTableChange}
                style={{ borderWidth: 0 }}
                // size="middle"
                // scroll={{
                //   x: "calc(700px + 50%)",
                //   y: 280,
                // }}
              />
            )}
          </div>
          <div>
            <div
              className="accordion-list"
              onClick={() =>
                this.setState({
                  showtotaltable: !this.state.showtotaltable,
                  showTable: !this.state.showTable,
                })
              }
            >
              {" "}
              {this.context.translate("_TOTAL_TRAVEL_REPORT_")}
              <Button >
                {this.state.showtotaltable ? <CaretDownOutlined /> :<CaretUpOutlined /> }
              </Button>{" "}
            </div>

            {this.state.showtotaltable && (
              <>
                <ListTable
                  style={{ borderWidth: 0 }}
                  // currentPage={this.state.pageNo}
                  pageSize="50"
                  // total={this.travelReportStore?.total}

                  columns={this.columnsTotalTReport}
                  dataSource={this.travelReportStore?.totalStartStopArray}
                  // dataSource={this.travelReportStore?.totalStartStopArray}
                  loading={this.travelReportStore?.loadingData}
                  // onChange={this.handleTableChange}
                  summary={(records) => {
                    var sum = 0;
                    var suma = 0;
                    var totalDistanceTravelled = 0;

                    records.map((record) => {
                      if (record.totaltrips) {
                        sum = parseInt(sum) + parseInt(record.totaltrips);
                      }

                      if (record.distanceTravelled) {
                        totalDistanceTravelled =
                          parseInt(totalDistanceTravelled) +
                          parseInt(record.distanceTravelled);
                      }

                      if (record.totalElapsedTime) {
                        var dt = record.totalElapsedTime.split(":");
                        var ts =
                          parseInt(dt[0] * 60 * 60) +
                          parseInt(dt[1] * 60) +
                          parseInt(dt[2]);
                        suma = parseInt(parseInt(suma) + parseInt(ts));
                      }
                    });

                    var d, h, m, s;

                    s = suma;
                    m = Math.floor(s / 60);
                    s = s % 60;
                    h = Math.floor(m / 60);
                    m = m % 60;
                    d = Math.floor(h / 24);
                    h = h % 24;

                    var result = (d < 10 ? "0" + d : d) + " days,";
                    result += h < 10 ? "0" + h : h;
                    result += ":" + (m < 10 ? "0" + m : m);
                    result += ":" + (s < 10 ? "0" + s : s);

                    return (
                      <>
                        {records.length != 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>Total</Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell />
                            <Table.Summary.Cell />

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>{sum}</Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {result}
                              </Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {totalDistanceTravelled}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                />
              </>
            )}
          </div>
        </div>

        <Modal
          className="multiTracking-popup"
          maskClosable={false}
          title={
            <div>
              {/* Vehicle History Details */}
              {this.context.translate("_VEHICLE_HISTORY_DETAILS_")}
              <CloseOutlined
                onClick={async () => {
                  await this.setState({ viewMap: false });
                  await this.closeMap();
                }}
              />
            </div>
          }
          open={this.state.viewMap}
          centered
          bodyStyle={{
            height: "55vh",
            //width: "900px",
          }} // Adjust the dimensions as needed
          width={1000}
          onOk={() => this.setState({ viewMap: false })}
          onCancel={async () => {
            await this.setState({ viewMap: false });
            await this.closeMap();
          }}
          footer={false}
        >
          <div
            dir={this.context.locale.language === "arabic" ? "ltr" : "ltr"}
            className={
              this.context.locale.language === "arabic"
                ? "arabic-map-container"
                : ""
            }
          >
            <MapContainer
              ref={this.mapRef}
              zoom={13}
              minZoom={3}
              center={[18, 42]}
              style={{
                position: "relative",
                height: "55vh",

                // borderRadius: "0px 0px 5px 5px",
              }}
            >
              <ReactLeafletGoogleLayer />
              <AdditionalContentInMap This={this} size="small" />
            </MapContainer>
          </div>

          <div style={{ textAlign: "right" }}>
            {/* <Button icon={<i class="bi bi-pause-circle"></i>} className="formCancelButton" onClick={() => this.setState({ isPlaying: false })}>
        
            </Button> */}
            {/* <FloatButton icon={<FloatButton tooltip={<div>Documents</div>} />} tooltip={<div>Documents</div>} />; */}

            <FloatButton.Group
              shape="square"
              style={{
                top: "72%",
                width: "0px",
                margin: "2px",
                insetInlineEnd: "6%",
                zIndex: 999,
                height: "13%",
                position: "absolute",
                transition: "top 0.5s",
              }}
            >
              <Popover className="locationShowingWindowBtns">
                <Tooltip title="Pause" align={"left"}>
                  <Button
                    style={{ height: "40px", width: "40px" }}
                    onClick={() => this.setState({ isPlaying: false })}
                    icon={
                      <i
                        class="bi bi-pause-circle"
                        style={{ fontSize: "34px" }}
                      ></i>
                    }
                  ></Button>
                </Tooltip>
              </Popover>

              <Popover style={{ padding: "0px" }}>
                <Tooltip title="Play" align={"left"}>
                  <Button
                    onClick={() => this.playFunc()}
                    style={{ height: "40px", width: "40px" }}
                    icon={
                      <i
                        class="bi bi-play-circle"
                        style={{ fontSize: "34px" }}
                      ></i>
                    }
                  ></Button>
                </Tooltip>
              </Popover>

              {/* <FloatButton />
      <FloatButton icon={<SyncOutlined />} />
      <FloatButton.BackTop visibilityHeight={0} /> */}
            </FloatButton.Group>
            {/* <Button icon={<i class="bi bi-play-circle"></i>}  className="formSaveButton"  type="primary" onClick={() => this.playFunc()}></Button>

            <Button className="formCancelButton"  onClick={async()=>{ await this.setState({ viewMap: false });
              await this.closeMap()}}>Close</Button> */}
          </div>
        </Modal>

        <Modal
          open={this.state.showEmail}
          title="Export"
          onOk={() => this.setState({ showEmail: false })}
          onCancel={() => this.setState({ showEmail: false })}
          footer={false}
          width={400}
        >
          <Form
            name="Email"
            layout="vertical"
            onFinish={this.onEmailSend}
            style={{ maxWidth: "100%" }}
          >
            <Row>
              <Col span={12}></Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              ></Col>
            </Row>
            <Form.Item
              name="Email"
              // label="Email"
              label={this.context.translate("_FLEET_LOGIN_EMAILLBL_")}
              rules={[{ required: true }]}
            >
              <Input style={{ width: 300 }} />
            </Form.Item>
            <div className="formBtnGroup">
              {/* css class */}

              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                // loading={this.state.loading}
              >
                {/* css class */}
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
            {/* <Row justify={"end"}>
                <Space style={{}}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Space>
              </Row> */}
          </Form>
        </Modal>
        <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          placeholder={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {console.log(this.newColumns, "Df")}
            {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
TravelReportList.contextType = StoreContext;
export default observer(withRouter(TravelReportList));
