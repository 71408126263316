import React from "react";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import {
  Select,
  Button,
  Space,
  Row,
  Col,
  Checkbox,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";

import config from "../config";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import CanBustReportStore from "../store/CanBustReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { RangePicker } = DatePicker;
const { Option } = Select;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};
class CanBusReportList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.canBusReportStore = new CanBustReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      offset: 0,
      limit: 50,
      loadData: 0,
      selectedVehicles: [],
      modalVisible: false,
      column: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),

      selectAllVehicles: false,
      showMonthPopup: false,

      selectedColumns: widthAdderFunc([
        {
          // title: "Vehicle No",
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "Engine Temp",
          title: context.translate("_ENGINE_TEMP_"),
          dataIndex: "EngineTemp",
          width: "15%",
          key: "EngineTemp",
        },

        {
          // title: "Fuel Consumed",
          title: context.translate("_FUEL_CONSUMED_"),
          dataIndex: "FuelConsumed",
          width: "15%",
          key: "FuelConsumed",
        },
        {
          // title: "Engine Working Time",
          title: context.translate("_ENGINE_WORKING_TIME_"),
          dataIndex: "EngineWorkingTime",
          width: "15%",
          key: "EngineWorkingTime",
        },
        {
          // title: "Latitude",
          title: context.translate("_FLEET_DASHBORD_SETTING_LATITUDE_"),
          dataIndex: "latitude",
          width: "15%",
          key: "latitude",
        },
        {
          // title: "Longitude",
          title: context.translate("_FLEET_DASHBORD_SETTING_LONGITUDE_"),
          dataIndex: "longitude",
          width: "15%",
          key: "longitude",
        },
        {
          // title: "Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
          width: "15%",
          key: "location",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "driverId",
          width: "15%",
          key: "driverId",
        },
        {
          // title: "Date Time",
          title: context.translate("_DATE_TIME_"),
          dataIndex: "dateTime",
          width: "15%",
          key: "dateTime",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "Engine Current Load",
          title: context.translate("_ENGINEE_CURRENT_LOAD_"),
          dataIndex: "enggCurrentLoad",
          width: "15%",
          key: "enggCurrentLoad",
        },
        {
          // title: "Engine Total Fuel Used",
          title: context.translate("_ENGINE_TOTAOL_FUEL_USE_"),
          dataIndex: "enggTotalFuelUse",
          width: "15%",
          key: "engTotaltFuelUsed",
        },
        {
          // title: "Fuel Level",
          title: context.translate("_FUEL_LEVEL_"),
          dataIndex: "fuelLevel",
          width: "15%",
          key: "fuelLevel",
        },
        {
          // title: "Engine Total Hours Operation",
          title: context.translate("_ENGINE_TOTAL_HOURSE_OF_OPERATION_"),
          // dataIndex: "engine_total_hours_of_operation",
          dataIndex: "EngTotalHrsOperation",
          width: "15%",
          // key: "engine_total_hours_of_operation",
          key: "EngTotalHrsOperation",
        },
        {
          // title: "Engine Coolant Temperature",
          title: context.translate("_ENGINE_COOLENT_TEMETATURE_"),
          // dataIndex: "engine_coolant_temperature",
          dataIndex: "EngcoolentTemp",
          width: "15%",
          // key: "engine_coolant_temperature",
          key: "EngcoolentTemp",
        },
        {
          // title: "Instantaneous Fuel Economy",
          title: context.translate("_INSTANTANIOUSE_FUEL_ECONOMY_"),
          dataIndex: "instFuelEconomy",
          width: "15%",
          key: "instantaneous_fuel_economy",
        },
      ]),
    };

    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "CSV",
        //   label: context.translate("_CSV_"),
        //   key: "csv",
        //   icon: <PlusOutlined />,
        // },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          console.log(result.toString());

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            colList: result.toString(),
          };
          // console.log(this.state.travelInt)
          // console.log("asfsdgfhdfghfg",parseInt(JSON.parse(base64_decode(Cookies.get("data"))).userId))
          if (val.key == "excel") {
            // console.log("afd")
            this.canBusReportStore.exportExcel(getData);
          }
          if (val.key == "csv") {
            this.canBusReportStore.exportcsv(getData);
          }
          if (val.key == "pdf") {
            this.canBusReportStore.exportpdf(getData);
          }

          if (val.key == "xml") {
            this.canBusReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = widthAdderFunc([
      ...this.state.selectedColumns,
      {
        // title: "Movement Sensor",
        title: context.translate("_MOVEMENT_SENSOR_"),
        dataIndex: "movementSensor",
        width: 15,
        key: "movementSensor",
      },
      {
        // title: "Ivcan Vehicle Speed",
        title: context.translate("_IVCAN_VEHICLE_SPEED_"),
        dataIndex: "IvcanVehicleSpeed",
        width: 15,
        key: "IvcanVehicleSpeed",
      },
      {
        // title: "Ivcan Pedal Position",
        title: context.translate("_IVCAN_PEDAL_POSITION_"),
        dataIndex: "IvcanPedalPosition",
        width: 15,
        key: "IvcanPedalPosition",
      },
      {
        // title: "Fuel Percent",
        title: context.translate("_FUEL_PERCENT_"),
        dataIndex: "FuelPercent",
        width: 15,
        key: "fuelPercent",
      },
      {
        // title: "RPM",
        title: context.translate("_RPM_"),
        dataIndex: "RPM",
        width: 15,
        key: "RPM",
      },
      {
        // title: "Fuel Rate2",
        title: context.translate("_FUEL_RATE_"),
        dataIndex: "FuelRate",
        width: 15,
        key: "FuelRate",
      },
      {
        // title: "Fuel",
        title: context.translate("_FUEL_"),
        dataIndex: "Fuel",
        width: 15,
        key: "Fuel",
      },
      {
        // title: "Fuel Consumed Counted",
        title: context.translate("_FUEL_CONSUMED_COUNTED_"),
        dataIndex: "FuelCounted",
        width: 15,
        key: "FuelConsumedCounted",
      },
      {
        // title: "Vehicle Mileage",
        title: context.translate("_VEHICLE_MILAGE_"),
        dataIndex: "VehicleMileage",
        width: 15,
        key: "VehicleMileage",
      },
      {
        // title: "Total Mileage Counted",
        title: context.translate("_TOTAL_MILEAGE_COUNTED_"),
        dataIndex: "TotalMileageCounted",
        width: 15,
        key: "TotalMileageCounted",
      },
      {
        // title: "Ivcan Program Number",
        title: context.translate("_IVCAN_PROGRAM_NUMBER_"),
        dataIndex: "IvcanProgramNumber",
        width: 15,
        key: "IvcanProgramNumber",
      },
      {
        // title: "Brake Switch",
        title: context.translate("_BREAKE_SWITCH_"),
        dataIndex: "breakSwitch",
        width: 15,
        key: "BrakeSwitch",
      },
      {
        // title: "Wheel Based Speed",
        title: context.translate("_WHEEL_BASE_SPEED_"),
        dataIndex: "wheelBaseSpeed",
        width: 15,
        key: "WheelBaseSpeed",
      },
      {
        // title: "Cruise Control Active",
        title: context.translate("_CRUISE_CONTROL_ACTIVE_"),
        dataIndex: "crouseControlActive",
        width: 15,
        key: "CruiseControlActive",
      },
      {
        title: "Clutch Switch",

        dataIndex: "clutchSwitch",
        // title:context.translate("_CRUISE_CONTROL_ACTIVE_"),
        width: 15,
        key: "ClutchSwitch",
      },
      {
        // title: "Pto State",
        title: context.translate("_PTO_STATE_"),
        dataIndex: "ptoState",
        width: 15,
        key: "PtoState",
      },
      {
        // title: "Acceleration Pedal Position",
        title: context.translate("_ACCELERATION_PEDAL_POSITION_"),
        dataIndex: "accPedalPossition",
        width: 15,
        key: "AccelerationPedalPosition",
      },
      {
        // title: "Engine Speed",
        title: context.translate("_ENGINE_SPEED_"),
        dataIndex: "EngineSpeed",
        width: 15,
        key: "EngineSpeed",
      },
      {
        // title: "Axle_Weight1",
        title: context.translate("_AXEL_WIDTH1_"),
        dataIndex: "axle_weight1",
        width: 15,
        key: "Axle_Weight1",
      },
      {
        // title: "Axle_Weight2",
        title: context.translate("_AXEL_WIDTH2_"),
        dataIndex: "axle_weight2",
        width: 15,
        key: "Axle_Weight2",
      },
      {
        // title: "Axle_Weight3",
        title: context.translate("_AXEL_WIDTH3_"),
        dataIndex: "axle_weight3",
        width: 15,
        key: "Axle_Weight3",
      },
      {
        // title: "Axle_Weight4",
        title: context.translate("_AXEL_WIDTH4_"),
        dataIndex: "axle_weight4",
        width: 15,
        key: "Axle_Weight4",
      },
      {
        // title: "Axle_Weight5",
        title: context.translate("_AXEL_WIDTH5_"),
        dataIndex: "axle_weight5",
        width: 15,
        key: "Axle_Weight5",
      },
      {
        // title: "Axle_Weight6",
        title: context.translate("_AXEL_WIDTH6_"),
        dataIndex: "axle_weight6",
        width: 15,
        key: "Axle_Weight6",
      },
      {
        // title: "Axle_Weight7",
        title: context.translate("_AXEL_WIDTH7_"),
        dataIndex: "axle_weight7",
        width: 15,
        key: "Axle_Weight7",
      },
      {
        // title: "Axle_Weight8",
        title: context.translate("_AXEL_WIDTH8_"),
        dataIndex: "axle_weight8",
        width: 15,
        key: "Axle_Weigh8",
      },
      {
        // title: "Axle_Weight9",
        title: context.translate("_AXEL_WIDTH9_"),
        dataIndex: "axle_weight9",
        width: 15,
        key: "Axle_Weight9",
      },
      {
        // title: "Axle_Weight10",
        title: context.translate("_AXEL_WIDTH10_"),
        dataIndex: "axle_weight10",
        width: 15,
        key: "Axle_Weight10",
      },
      {
        // title: "Axle_Weight11",
        title: context.translate("_AXEL_WIDTH11_"),
        dataIndex: "axle_weight11",
        width: 15,
        key: "Axle_Weight11",
      },
      {
        // title: "Axle_Weight12",
        title: context.translate("_AXEL_WIDTH12_"),
        dataIndex: "axle_weight12",
        width: 15,
        key: "Axle_Weight12",
      },
      {
        // title: "Axle_Weight13",
        title: context.translate("_AXEL_WIDTH13_"),
        dataIndex: "axle_weight13",
        width: 15,
        key: "Axle_Weight13",
      },
      {
        // title: "Axle_Weight14",
        title: context.translate("_AXEL_WIDTH14_"),
        dataIndex: "axle_weight14",
        width: 15,
        key: "Axle_Weight14",
      },
      {
        // title: "Axle_Weight15",
        title: context.translate("_AXEL_WIDTH15_"),
        dataIndex: "axle_weight15",
        width: 15,
        key: "Axle_Weight15",
      },

      {
        // title: "Software Version Supported",
        title: context.translate("_SOPTWARE_VERSION_SUPPORTED_"),
        dataIndex: "soptwareVersionSupported",
        width: 15,
        key: "SoftwareVersionSuppoorted",
      },
      {
        // title: "Diagnostics Supported",
        title: context.translate("_DIGNOSTIC_SUPPORTED_"),
        dataIndex: "diagnostics_supported",
        width: 15,
        key: "DiagnosticsSupported",
      },
      {
        // title: "Requests Supported",
        title: context.translate("_REQEST_SUPPORTED_"),
        dataIndex: "requests_supported",
        width: 15,
        key: "RequestSupported",
      },
      {
        // title: "Service Distance",
        title: context.translate("_SERVICE_DISTANCE_"),
        dataIndex: "service_distance",
        width: 15,
        key: "ServiceDistance",
      },
      {
        // title: "Direction Indication",
        title: context.translate("_DIRECTION_INDICATION_"),
        dataIndex: "direction_indication",
        width: 15,
        key: "DirectionIndication",
      },
      {
        // title: "Tachograph Performance",
        title: context.translate("_TACHOGRAPH_PERFORMANCE_"),
        dataIndex: "tachograph_performance",
        width: 15,
        key: "TachographPerformance",
      },
      {
        // title: "Handling Info",
        title: context.translate("_HANDLING_INFO_"),
        dataIndex: "handling_info",
        width: 15,
        key: "HandlingInfo",
      },
      {
        // title: "System Event",
        title: context.translate("_SYSTEM_EVENT_"),
        dataIndex: "system_event",
        width: 15,
        key: "SystemEvent",
      },
      {
        // title: "Ambient Air Temperature",
        title: context.translate("_AMBIENT_AIR_TEMPERATURE_"),
        dataIndex: "ambientAirTemp",
        width: 15,
        key: "AmbientAirTemperature",
      },
      {
        // title: "Fuel Rate2",
        title: context.translate("_FUEL_RATE_"),
        dataIndex: "fuel_rate2",
        width: 15,
        key: "FuelRate2",
      },
      {
        // title: "Pto Drive Engagement",
        title: context.translate("_PTO_DRIVE_ENGAGEMENT_"),
        dataIndex: "ptoDriveEng",
        width: 15,
        key: "PtoDriveEngagement",
      },
      {
        // title: "High Resolution Engine Total Fuel Used",
        title: context.translate("_HIGH_RESOLUTION_ENGINE_TOTAL_FUEL_USED_"),
        dataIndex: "highResolutionEngTotalFuel",
        width: 15,
        key: "HighResolutionTotalFuelUsed",
      },
      {
        // title: "Gross Combinaton Vehicle Weight",
        title: context.translate("_GROSS_COMBINATION_VEHICLE_WEIGHT_"),
        dataIndex: "grossCombVehicleWeight",
        width: 15,
        key: "GrossCombinatonVehicleWeight",
      },
      {
        // title: "AdBlue Status",
        title: context.translate("_ADBLUE_STATUS_"),
        dataIndex: "adBlue_status",
        width: 15,
        key: "AdBlueStatus",
      },
      {
        // title: "Brake Pedal Position",
        title: context.translate("_BREAKE_PEDAL_POSITION_"),
        dataIndex: "breakrPedalPosition",
        width: 15,
        key: "BrakePedalPosition",
      },
      {
        // title: "Fuel Level2",
        title: context.translate("_FUEL_LEVEL2_"),
        dataIndex: "fuel_level2",
        width: 15,
        key: "FuelLevel2",
      },
      {
        // title: "Mil Indicator",
        title: context.translate("_MIL_INDICATOR_"),
        dataIndex: "mil_indicator",
        width: 15,
        key: "MilIndicator",
      },
      {
        // title: "Tell_Tale0",
        title: context.translate("_TELL_TALE0_"),
        dataIndex: "tell_tale0",
        width: 15,
        key: "Tell_Tale0",
      },
      {
        // title: "Tell_Tale1",
        title: context.translate("_TELL_TALE1_"),
        dataIndex: "tell_tale1",
        width: 15,
        key: "Tell_Tale1",
      },
      {
        // title: "Tell_Tale2",
        title: context.translate("_TELL_TALE2_"),
        dataIndex: "tell_tale2",
        width: 15,
        key: "Tell_Tale2",
      },
      {
        // title: "Tell_Tale3",
        title: context.translate("_TELL_TALE3_"),
        dataIndex: "tell_tale3",
        width: 15,
        key: "Tell_Tale3",
      },
      {
        // title: "AdBlue Level",
        title: context.translate("_ADBLUE_LEVEL1_"),
        dataIndex: "adBlue_level",
        width: 15,
        key: "AdBlue Level",
      },
    ]);
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.canBusReportStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp.value);
    await this.handleSearch();

    // console.log("data", data);
  }

  async handleSearch() {
    // await this.canBusReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   pageNo:this.state.pageNo
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log("pagination", pagination);

    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }
  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  showData = async () => {
    if (this.checkFromDate(this.state.fromdateStdTime)) {
      await this.canBusReportStore.loadData({
        offset: (this.state.pageNo - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        pageNo: this.state.pageNo,
      });
      await this.setState({ loadData: 1 });
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Canbus Report</div> */}
              <div className="heading">
                {this.context.translate("_CANBUS_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
            

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.canBusReportStore?.canBusReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button 
                  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <Row style={{ padding: "10px 0px" }}> */}
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 200 }}
                />

                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) =>{ this.setState({ selectedVehicles: val });
                  if(this.vehicleStore?.getVehiclesUsingCompanyId.length>val.length){
                    this.setState({selectAllVehicles:false})
                  }
                  else{
                    this.setState({selectAllVehicles:true})
                  }
                  }
                }
                  onSearch={this.handleSearch}
                  // onChange={(val) => this.vehicleSelect(val)}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 200 }}
                /> */}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button type="primary" onClick={() => this.showData()}>
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={350}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.canBusReportStore?.total}
            columns={this.state.column}
            dataSource={this.canBusReportStore?.canBusReport}
            loading={this.canBusReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   total: this.canBusReportStore.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
        </div>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
CanBusReportList.contextType = StoreContext;
export default observer(withRouter(CanBusReportList));
