import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Checkbox, Col, DatePicker, Form, Input, Select } from "antd";

const VehicleInfoDrive7Fields = (props) => {
  console.log("props", props.This);
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleInfoDrive7Fields"
  );
  //   console.log("shouldShow**", shouldShow);
  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Col span={12} className="formColumnClass">
        {props.isadmin.isadmin !== 1 && (
          <>
            <Form.Item
              name="installer"
              label={storeContext.translate("_INSTALLER_")}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="installedLocation"
              label={storeContext.translate("_INSATALLED_LOCATION_")}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="chassisNo"
              label={storeContext.translate("_CHASSIS_NUMBER_")}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="permitExpiryDate"
              label={storeContext.translate("_PERMIT_EXPIRY_DATE_")}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item
              name="nationalPermitExpiryDate"
              label={storeContext.translate("_NATIONAL_PERMIT_EXPIRY_DATE_")}
              rules={[{ type: Date }]}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              name="pollutionExpiryDate"
              label={storeContext.translate("_POLLUTION_EXPIRY_DATE_")}
              rules={[{ type: Date }]}
            >
              <DatePicker format="YYYY-MM-DD" style={{ width: "100%" }} />
            </Form.Item>
          </>
        )}

        <div className="vehicleFormCheckbox">
          {props.isadmin.isadmin !== 1 && (
            <>
              <Form.Item
                name="addOn"
                // label="Add On"
                label={storeContext.translate("_ADDON_")}
                style={{ marginTop: "14px" }}
              >
                <Checkbox
                  // value={this.state.DriverId}
                  checked={props.This.DriverId}
                  //   onChange={(e) => {
                  onChange={async (e) =>
                    props.handleCheckboxChange(e.target.checked, "DriverId")
                  }
                  // props.This.this.setState({
                  //   DriverId: e.target.checked,
                  // });
                  //   }}
                >
                  {storeContext.translate("_FLEET_REPORTS_DRIVER_ID_")}
                </Checkbox>

                <Checkbox
                  value={4}
                  checked={props.This.rfid}
                  //   onChange={(e) => {
                  //     this.setState({ rfid: e.target.checked });
                  //   }}
                  onChange={async (e) =>
                    props.handleCheckboxChange(e.target.checked, "rfid")
                  }
                >
                  {/* RFID */}
                  {storeContext.translate("_FLEET_DASHBORD_SETTING_RFID_")}
                </Checkbox>
              </Form.Item>
            </>
          )}
        </div>

        <Form.Item
          name="technicianName"
          label={storeContext.translate("_TECHNICIAN_NAME_")}
        >
          <Input />
        </Form.Item>

        <Form.Item name="remark" label={storeContext.translate("_REMARK_")}>
          <Input />
        </Form.Item>
      </Col>
      <Col span={12} className="formColumnClass">
        {(Number(props.isadmin.isadmin) === -1 ||
          Number(props.isadmin.isadmin) === 3) && (
          <>
            <Checkbox
              style={{ marginTop: "14px" }}
              // value={this.state.fuelSensor}
              checked={props.This.fuelSensor}
              onChange={async (e) =>
                props.handleCheckboxChange(e.target.checked, "fuelSensor")
              }

              //   onChange={(e) => {
              //     this.setState({ fuelSensor: e.target.checked });
              //   }}
            >
              <span style={{ fontWeight: "bold" }}>
                {storeContext.translate("_FUEL_SENSOR_")}
              </span>
            </Checkbox>

            <Form.Item
              name="fuelId1"
              // label="Fuel Id1"
              label={storeContext.translate("_FUEL_ID_1_")}
              className={!props.This.fuelSensor && "disabledLabel"}
            >
              <Input disabled={props.This.fuelSensor ? false : true} />
            </Form.Item>

            <Form.Item
              name="fuelId2"
              // label="Fuel Id2"
              label={storeContext.translate("_FUEL_ID_2_")}
              className={!props.This.fuelSensor && "disabledLabel"}
            >
              <Input disabled={props.This.fuelSensor ? false : true} />
            </Form.Item>

            <Checkbox
              style={{ marginTop: "14px" }}
              // value={4}
              checked={props.This.temperatureSensor}
              //   onChange={(e) => {
              //     this.setState({
              //       temperatureSensor: e.target.checked,
              //     });
              //   }}
              onChange={async (e) =>
                props.handleCheckboxChange(
                  e.target.checked,
                  "temperatureSensor"
                )
              }
            >
              <span style={{ fontWeight: "bold" }}>
                {/* Temperature Sensor */}
                {storeContext.translate("_TEMPERATURE_SENSOR_")}
              </span>
            </Checkbox>

            <>
              <Form.Item
                name="tempId1"
                // label="Temp Id1"
                label={storeContext.translate("_TEMP_ID1_")}
                className={!props.This.temperatureSensor && "disabledLabel"}
              >
                <Select
                  disabled={props.This.temperatureSensor ? false : true}
                />
              </Form.Item>
              <Form.Item
                name="tempId2"
                // label="Temp Id2"
                label={storeContext.translate("_TEMP_ID2_")}
                className={!props.This.temperatureSensor && "disabledLabel"}
              >
                <Select
                  disabled={props.This.temperatureSensor ? false : true}
                />
              </Form.Item>
              <Form.Item
                name="tempId3"
                // label="Temp Id3"
                label={storeContext.translate("_TEMP_ID3_")}
                className={!props.This.temperatureSensor && "disabledLabel"}
              >
                <Select
                  disabled={props.This.temperatureSensor ? false : true}
                />
              </Form.Item>
            </>
          </>
        )}
      </Col>
    </>
  );
};

VehicleInfoDrive7Fields.contextType = StoreContext;
export default observer(withRouter(VehicleInfoDrive7Fields));
