import {Space, Dropdown, Tooltip,Button} from 'antd';
import {
  DownOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import React, { useContext } from 'react';
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";

  const AccountListFields = (props) => {
        console.log('props',props);
        const shouldShow = shouldShowComponent(config.project, "AccountListFields");

         const storeContext = useContext(StoreContext); // Use the context here

    if (!shouldShow) {
      return null; // Do not render if the condition is not met
    }

    return (
    <>
       <Space>
          <Dropdown menu={props.menu}>
            <Button>
              <Space>
                {/* WASL  */}
                {storeContext.translate("_WASL_")}
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>


          <Tooltip
            //  title="Upload"
            title={storeContext.translate("_UPLOAD_")}
            color={config.tooltipColorEdit}
          >
            <Button
              type={config.uploadBoxType}
              onClick={() => props.setshowUpload(true)}
            >
              <UploadOutlined />
            </Button>
          </Tooltip>

          </Space>

       
      </>
    );
  };


  AccountListFields.contextType = StoreContext;
export default observer(withRouter(AccountListFields));
