import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class EventStore {
  loading = false;
  total = 0;
  contentList = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      contentList: observable,

      loadData: action,
      deleteRecord: action,

      addData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, page }) {
    this.loading = true;
    var postData = {
      companyId: 1,
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=getContentDetails",
        "postData=" + base64Input
      );

    this.contentList = response?.data?.data?.contentData;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  // async addData(eventDetails) {
  //   var postData = eventDetails;
  //   // var postData = {
  //   //   id: -1,
  //   //   title: "newtitllee",
  //   //   content:
  //   //     "<p><strong>newtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitlleenewtitllee</strong></p>\n",
  //   //   expiryDate: "2024-08-02",
  //   //   type: 1,
  //   //   userId: 3920,
  //   // };
  //   const base64Input = await base64_encode(JSON.stringify(postData));
  //   console.log(base64Input);
  //   var response = await postApi
  //     .getClient()
  //     .post(
  //       "index.php?c=traxi_content&a=updateContent",
  //       "postData=" + base64Input
  //     );

  //   return response.data;
  // }

  async addData(eventDetails) {
    var postData = eventDetails;

    const base64Input = btoa(unescape(encodeURIComponent(JSON.stringify(postData))));
    const encodedInput = encodeURIComponent(base64Input);

    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=updateContent",
        "postData=" + encodedInput
      );

      // console.log(response)
    return response.data;
  }
  async getEventData(contentId) {
    console.log("getdriverdata", contentId);
    var postData = { contentId: contentId, limit: 1, offset: 0 };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=getContentDetails",
        "postData=" + base64Input
      );
    console.log(response);

    return response?.data?.data?.contentData[0];
  }

  async deleteRecord({ id }) {
    const postData = {
      id: id,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=deleteEvent",
        "postData=" + base64Input
      );

    return response;
  }
}

export default EventStore;
