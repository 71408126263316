import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

const AddVersionModal = (props) => {
  const shouldShow = shouldShowComponent(config.project, "AddVersionModal");

  // console.log(shouldShow,config.project)
  const storeContext = useContext(StoreContext);

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <Button
      disabled={props.companyId == "" ? true :false}
        onClick={() => {
          props.handleAddVersionModal();
          
        }}
        type="primary"
        icon=<DownloadOutlined style={{ fontSize: "21px" }} />
      ></Button>
    </>
  );
};

AddVersionModal.contextType = StoreContext;
export default observer(withRouter(AddVersionModal));
