import React from "react";
import {
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import config from "../config";
import withRouter from "../withRouter";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";

import FuelFilledReportStore from "../store/FuelFilledReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { RangePicker } = DatePicker;
const { Option } = Select;
class FuelFilledReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fuelFilledReportStore = new FuelFilledReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: "",
      selectedDriver: [],
      Notdriver: false,
      selectAllDrivers: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      showMonthPopup: false,
      Notcompany: false,
      Notasset: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      selectedColumns: [
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          // fixed: 'left',
        },
        {
          // title: "Asset Type",
          title: context.translate("_FLEET_REPORTS_VEHICLETYPE_"),
          dataIndex: "vehicleTypeNew",
          key: "vehicleTypeNew",
        },
        {
          // title: "Driver Name",
          title: context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
          ),
          dataIndex: "driverName",
          key: "driverName",
        },
        {
          // title: "Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "day",
          key: "day",
        },
        {
          // title: "Start Odometer",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STARTOD_"),
          dataIndex: "startDistance",
          key: "startDistance",
        },
        {
          // title: "Stop Odometer",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STOPOD_"),
          dataIndex: "stopDistance",
          key: "stopDistance",
        },
        {
          // title: "Entered Odometer",
          title: context.translate("_ENERED_ODOMETER_"),
          dataIndex: "odometer",
          key: "odometer",
        },

        {
          // title: "Amount",
          title: context.translate("_AMOUNT_"),
          dataIndex: "amount",
          key: "amount",
        },
        {
          // title: "Fuel",
          title: context.translate("_FUEL_"),
          dataIndex: "fuel",
          key: "fuel",
        },
        {
          // title: "Fuel Filled",
          title: context.translate("_FUEL_FILLED_"),
          dataIndex: "fuelFilled",
          key: "fuelFilled",
        },

        {
          // title: "Total Run Km",
          title: context.translate("_TOTAL_RUN_KM_"),
          dataIndex: "distanceTravelled",
          key: "distanceTravelled",
          render: (value) => {
            return Math.floor(value);
          },
        },

        {
          // title: " Fuel Consumed ",
          title: context.translate("_FUEL_CONSUMED_"),
          dataIndex: "fuelconsumed",
          key: "fuelconsumed",

          // fixed:'right'
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            DriverId: this.state.selectedDriver,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.fuelFilledReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.fuelFilledReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.fuelFilledReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.fuelFilledReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.fuelFilledReportStore.loadData({ offset: 0, limit: 20 });

    this.setState({ pageSize: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    console.log(this.state.selectedVehicles);
    this.setState({ selectedVehicles: "" });
    this.setState({ selectedDriver: [], selectAllDrivers: false });

    await this.setState({ companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.fuelFilledReportStore.getDriversByCompanyId({
      companyId: comp?.value,
    });
    await this.handleSearch();
    //  console.log(data,"sdcd")
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    // await this.fuelFilledReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   pageNo: this.state.currentPage,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val });
  };

  driverSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedDriver: val });
  };
  showData = async () => {
    this.setState({ loading: true });
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.selectedDriver?.length === 0) {
      this.setState({ Notdriver: true });
    } else {
      this.setState({ Notdriver: false });
    }

    if (
      this.state.companyId &&
      this.state.selectedVehicles &&
      this.state.selectedDriver?.length > 0
    ) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false, Notdriver: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.fuelFilledReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          driverId: this.state.selectedDriver,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.currentPage,
        });
        this.setState({ loading: true });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleSelectAllDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.fuelFilledReportStore?.getDrivers.map((val) => {
        return val.driverId;
      });
      await this.setState({ selectedDriver: allDrivers });
    } else {
      await this.setState({ selectedDriver: [] });
    }
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Fuel Filled Report</div> */}
              <div className="heading">
                {this.context.translate("_FUEL_FILLED_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <Button>Total</Button> */}

            

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.fuelFilledReportStore?.fuelFilledReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {/* <Select
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => { this.vehicleSelect(val); this.setState({ Notasset: false }) }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle}
                  status={this.state.Notasset ? 'error' : false}
                /> */}
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: "",
                      })
                    }
                  />
                )}
                <Select
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <Checkbox
                  checked={this.state.selectAllDrivers}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllDrivers: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleSelectAllDrivers();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                <Select
                  onSearch={this.handleSearch}
                  value={this.state.selectedDriver}
                  mode="multiple"
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.setState({ selectedDriver: val });
                    if (
                      this.fuelFilledReportStore?.getDrivers.length > val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  // fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  optionFilterProp="children"
                  // options={this.fuelFilledReportStore?.getDrivers}
                  style={{ width: 160 }}
                  status={this.state.Notdriver ? "error" : false}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.fuelFilledReportStore?.getDrivers?.map((val) => {
                    return <Option value={val.driverId}>{val.name}</Option>;
                  })}
                </Select>
                {/* <RangePicker
                  style={{ width: 300 }}
                  onChange={(val) => {
                    console.log(val);
                    this.onDateTimeChange(val);
                  }}
                  // renderExtraFooter={() => "extra footer"}
                  showTime={{ format: "h:mm A" }}
                /> */}
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={async() => {
                  await  this.setState({ currentPage: 1,pageSize:50});
                   await this.showData();
                   
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.fuelFilledReportStore?.total}
            columns={this.state.column}
            dataSource={this.fuelFilledReportStore?.fuelFilledReport}
            loading={this.fuelFilledReportStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total:this.fuelFilledReportStore?.total || 0

            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {/* Alert */}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
      </>
    );
  }
}
FuelFilledReportList.contextType = StoreContext;
export default observer(withRouter(FuelFilledReportList));
