import React, { useState } from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Typography,
  Space,
  DatePicker,
  Dropdown,
  Modal,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import config from "../config";
import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import TempReportStore from "../store/TempReportStore";
import withRouter from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MapForVehiclePosition from "./MapForVehiclePosition";
import L from "leaflet";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
const { RangePicker } = DatePicker;
const { Option } = Select;
class TempReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.tempStore = new TempReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      offset: 0,
      limit: 50,
      currentPage: 1,
      selectedVehicles: "",
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      // showMap: false,
      showMonthPopup: false,
      Notcompany: false,
      Notasset: false,
      page: "",
      vehicleObj: false,
      heading: "",

      selectedColumns: [
        {
          // title: "  Asset Name ",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
          // fixed: 'left',
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "  Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "updatedate",
          // fixed: 'left',
          key: "updatedate",
          width: "15%",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "  Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
          key: "location",

          width: "15%",
        },
        {
          // title: "BLE Temp ",
          title: context.translate("_BLE_TEMP_"),
          dataIndex: "BLETemp",
          key: "BLETemp",

          //fixed: 'left',
          width: "15%",
          render: (value) => {
            if (!value || value === "") {
              return "NA";
            }
            var humArr = value.split(", ");
            var formattedHumidity = [];

            for (var i = 0; i < humArr.length; i++) {
              if (humArr[i] !== "NA") {
                var j = i + 1;
                formattedHumidity.push(
                  <div key={j}>
                    <b>{`Sensor${j}: `}</b>
                    {humArr[i]}
                  </div>
                );
              }
            }
            return formattedHumidity;
          },
          //           render: (text,value) => {
          // console.log(value,text)
          //             if (typeof text === 'number') {
          //               return <div>{text.toFixed(1)}Sensor:{text}</div>;
          //             }
          //             return <div>Sensor1:{text}</div>;
          //           },
          // render: (text,value) => {
          //   console.log(text)
          //   return {value}
          // }

          // <div dangerouslySetInnerHTML={{ __html: text }} />

          //   render: (value) => {
          //     if (!value || value == "") {
          //         return "NA";
          //     }
          //     var tmpArr = value.split(", ");
          //     var formattedtemp = "";
          //    for(var i=0;i<tmpArr.length;i++){
          //           if(tmpArr[i] != "NA")  {
          //               var j=i+1;
          //             formattedtemp += "<b> Sensor"+j+": </b>"+tmpArr[i]+ "<br>";
          //             // formattedtemp += <b> Sensor {j}: </b> +tmpArr[i]+ <br/>;
          //           }

          //     }
          //     return formattedtemp;

          // }
        },
        {
          // title: "BLE  Humidity",
          title: context.translate("_BLE_HUMIDITY_"),
          dataIndex: "BLEHumidity",
          //fixed: 'left',
          key: "BLEHumidity",

          width: "15%",
          render: (value) => {
            if (!value || value === "") {
              return "NA";
            }
            var humArr = value.split(", ");
            var formattedHumidity = [];

            for (var i = 0; i < humArr.length; i++) {
              if (humArr[i] !== "NA") {
                var j = i + 1;
                formattedHumidity.push(
                  <div key={j}>
                    <b>{`Sensor${j}: `}</b>
                    {humArr[i]}
                  </div>
                );
              }
            }

            return formattedHumidity;
          },
          // render: (value) => {
          //   console.log(value);
          //   if (!value || value == "") {
          //     return "NA";
          //   }
          //   var humArr = value.split(", ");
          //   var formattedtemp1 = "";

          //   for (var i = 0; i < humArr.length; i++) {
          //     if (humArr[i] != "NA") {
          //       var j = i + 1;
          //       formattedtemp1 += `<b> Sensor"+j+": </b>` + humArr[i] + <br />;
          //     }
          //   }

          //   return formattedtemp1;
          // },
        },
        {
          // title: " Latitude  ",
          title: context.translate("_FLEET_DASHBORD_SETTING_LATITUDE_"),
          dataIndex: "latitude",
          key: "latitude",

          //fixed: 'left',
          width: "15%",
        },
        {
          // title: "Longitude ",
          title: context.translate("_FLEET_DASHBORD_SETTING_LONGITUDE_"),
          dataIndex: "longitude",
          key: "longitude",

          width: "15%",
        },

        {
          // title: "View On Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "GPS",
          key: "GPS",

          // fixed: 'right',
          width: "15%",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: { ...obj, icon: "map-car-icon-green.png" },
                      heading: "Temp Report Map",
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            intervel: this.state.intervel,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            userId: 1,
            filter: this.state.filter,

            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.tempStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.tempStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.tempStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.tempStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.tempStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    await this.setState({ column: this.state.selectedColumns });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: "" });

    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    // await this.handleSearch();
    await this.setState({ loadData: 1 });
    // console.log("data", data);
  }

  async handleSearch() {
    // this.setState({ loading: true });
    this.setState({ Notasset: false });
    await this.tempStore.loadData({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      pageNo: this.state.pageNo,
    });

    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    // await this.setState({ page: pagination.current });
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  vehicleSelect = async (val) => {
    console.log(val);
    await this.setState({ selectedVehicles: val, Notasset: false });
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.tempStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.pageNo,
        });
      }
    }
    this.setState({loadData:1})
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const assetInputStyle = this.state.Notasset
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed">
            <Col span={12}>
              {/* <div className="heading">Temp Report</div> */}
              <div className="heading">
                {this.context.translate("_TEMP_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
             

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.tempStore?.tempReportStore?.length > 0 ? false : true
                  }
                >
                  <Button className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  status={this.state.Notcompany ? "error" : false}
                  // style={companyInputStyle}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles:'',
                      })
                    }
                  />
                )}
                {/* <Select

                  maxTagCount="responsive"
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) =>{ this.vehicleSelect(val);  this.setState({Notasset:false})}}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle}
                  status={this.state.Notasset ? 'error' :false}
                /> */}
                <Select
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ pageNo: 1,pageSize:50});
                    await this.showData();
                   
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.tempStore?.total}
            columns={this.state.column}
            dataSource={this.tempStore?.tempReportStore}
            loading={this.tempStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total: this.tempStore?.total || 0,
            // }}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />

          {/* <Modal
            title="Temp Report Map"
            open={this.state.showMap}
            centered
            // bodyStyle={{ height: 1000 }}
            width={850}
            height={400}
            onOk={() => this.setState({ showMap: false })}
            onCancel={() => this.setState({ showMap: false })}
            footer={false}
          >
            <MapContainer
              ref={this.mapRef}
              zoom={16}
              center={[
                this.state.modalInfoObj?.latitude,
                this.state.modalInfoObj?.longitude,
              ]}
              style={{ height: "400px", width: "800px" }}
              bounds={
                this.state.modalInfoObj
                  ? [
                      [
                        this.state.modalInfoObj.latitude,
                        this.state.modalInfoObj.longitude,
                      ],
                      [
                        this.state.modalInfoObj.latitude,
                        this.state.modalInfoObj.longitude,
                      ],
                    ]
                  : []
              }
            >
              <ReactLeafletGoogleLayer />
             

              <Marker
                icon={dotIcon}
                position={[
                  this.state.modalInfoObj?.latitude,
                  this.state.modalInfoObj?.longitude,
                ]}
              >
              
                <Popup>{this.state.modalInfoObj?.vehicleNo}</Popup>
              </Marker>
            </MapContainer>
          </Modal> */}

          {this.state.vehicleObj && (
            <MapForVehiclePosition
              vehicleObj={this.state.vehicleObj}
              heading={this.state.heading}
              closeMapModal={(resp) => {
                this.setState({ vehicleObj: resp });
              }}
            />
          )}
          <Modal
            width={350}
            className="alert-model"
            title={
              <div>
                {" "}
                <i
                  style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                  class="bi bi-exclamation-triangle"
                ></i>{" "}
                {/* Alert */}
                {this.context.translate("_FLEET_ALERT_")}
              </div>
            }
            open={this.state.showMonthPopup}
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For Reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
          </Modal>
        </div>
      </>
    );
  }
}
TempReportList.contextType = StoreContext;
export default observer(withRouter(TempReportList));
