import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Empty,
  Input,
  Row,
  Select,
  Space,
  Spin,
} from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";
import { Bar, Column } from "@ant-design/plots";
import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";

const { Option } = Select;
class DistanceGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: [],
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD  00:00:01"),
      toDate: dayjs().format("YYYY-MM-DD  23:59:59"),
      loadData: 0,
      horizontal: false,
      vertical: false,
      Notcompany: false,
      Notasset: false,
      singlevehiclegraph: false,
      loadingSpin: false,
      xlabel: "",
    };
  }

  async handleChange(comp) {
    this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp.value);

    // console.log("data", data);

    this.setState({ update: 1 });
  }

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      if (this.state.selectedVehicles.length > 1) {
        this.setState({ xlabel: "vehicleNo" });
      } else {
        this.setState({ xlabel: "startTime" });
      }
      // this.setState({mandatory:false})
      await this.setState({ loadingSpin: true });

      this.setState({ Notcompany: false, Notasset: false });
      const data = await this.distanceGraphStore.loadDistanceGraphData({
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
        distance: this.state.distance,
        // pageNo: this.state.pageNo,
      });
      console.log(data);
      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }

  render() {
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Distance Graph</div> */}
                <div className="heading">
                  {this.context.translate("_DISTANCE_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={22}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />

                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: [],
                          selectAllVehicles: false,
                        })
                      }
                    />
                  )}
                  <Checkbox
                    checked={this.state.selectAllVehicles}
                    onChange={async (e) => {
                      await this.setState({
                        selectAllVehicles: e.target.checked,
                        vertical: false,
                        horizontal: false,
                      });
                     
                      await this.handleAllSelectVehicles();
                    }}
                  />
                  {/* All */}
                  {this.context.translate("_ALL_")}
                  <Select
                    mode="multiple"
                    maxTagCount="responsive"
                    value={this.state.selectedVehicles}
                    onChange={async (val) => {
                      this.setState({
                        selectedVehicles: val,
                        Notasset: false,
                        vertical: false,
                        horizontal: false,
                      });
                      if (
                        this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                        val.length
                      ) {
                        this.setState({ selectAllVehicles: false });
                      } else {
                        this.setState({ selectAllVehicles: true });
                      }
                    }}
                    // fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                    // placeholder="Asset Name"
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    optionFilterProp="children"
                    // options={this.distanceGraphStore?.vehiclesArray}
                    style={{ width: 175 }}
                    status={this.state.Notasset ? "error" : false}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                      (val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      }
                    )}
                  </Select>

                  <Input
                    style={{ width: 150 }}
                    // placeholder="Distance"
                    placeholder={this.context.translate(
                      "_FLEET_RULEDETAIL_SELECTPARAMETER_DISTANCE_"
                    )}
                    value={this.state.distance}
                    onChange={(e) =>
                      this.setState({ distance: e.target.value })
                    }
                  />
                  <DatePicker
                    defaultValue={dayjs()}
                    format={"DD-MM-YYYY"}
                    // placeholder="From Date"
                    placeholder={this.context.translate("_FROM_DATE_")}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                    onChange={(date) => {
                      console.log(date);
                      if (date === null) {
                        this.setState({ fromDate: "" });
                      } else {
                        const formattedDate = date.startOf("day");
                        const format = "YYYY-MM-DD HH:mm:01";
                        this.setState({
                          fromDate: formattedDate.format(format),
                        });
                        console.log(
                          formattedDate.format("YYYY-MM-DD HH:mm:01")
                        );
                      }
                    }}
                  />
                  <DatePicker
                    format={"DD-MM-YYYY"}
                    defaultValue={dayjs()}
                    placeholder={this.context.translate("_TO_DATE_")}
                    onChange={(date) => {
                      console.log(date);
                      if (date === null) {
                        this.setState({ toDate: "" });
                      } else {
                        const formattedDate = date.endOf("day");
                        const format = "YYYY-MM-DD HH:mm:ss";
                        this.setState({ toDate: formattedDate.format(format) });
                        console.log(
                          formattedDate.format("YYYY-MM-DD HH:mm:ss")
                        );
                      }
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                  />
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.setState({
                        vertical: true,
                        horizontal: false,
                      });
                      await this.showData();
                    }}
                  >
                    {/* Vertical */}
                    {this.context.translate("_VERTICAL_")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.setState({
                        horizontal: true,
                        vertical: false,
                      });
                      await this.showData();
                    }}
                  >
                    {/* Horizontal */}
                    {this.context.translate("_HORIZONTAL_")}
                  </Button>
                </Space>
              </Col>
              <Col span={2}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
            <div style={{ marginTop: "100px" }}>
              {this.state.vertical || this.state.horizontal ? (
                <>
                  {this.state.vertical &&
                    !this.state.Notasset &&
                    !this.state.Notcompany && (
                      <Column
                        {...{
                          data:
                            this.distanceGraphStore?.distanceGraph?.length > 0
                              ? this.distanceGraphStore?.distanceGraph
                              : [],

                          yField: "distanceTravelled",

                          // xField: (this.state.selectedVehicles.length > 1) ? 'vehicleNo' : 'startTime',
                          xField: this.state.xlabel,

                          // "vehicleNo",
                          legend: false,
                          seriesField: "distanceTravelled",
                          animation: false,
                          yAxis: {
                            label: {
                              style: {
                                fontWeight: "bold",
                                fontSize: 13,
                              },
                            },
                            title: {
                              // text: "Distance Travelled(Kms)",
                              text: this.context.translate(
                                "_DISTANCE_TRAVELED_KMS_"
                              ),
                              style: {
                                fontWeight: "bold",
                                fontSize: 17,
                              },
                            },
                          },
                          // label: {
                          //   rotate: -Math.PI / 2,
                          // },
                          xAxis: {
                            label: {
                              // rotate: -Math.PI / 2,

                              style: {
                                fontWeight: "bold",
                                fontSize: 13,
                              },
                            },
                            title: {
                              // text: "Asset Name",
                              text:
                                this.state.xlabel === "vehicleNo"
                                  ? this.context.translate(
                                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                                    )
                                  : "Date",
                              // text: this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_")
                              // text: (this.state.selectedVehicles.length > 1) ? this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_") : "Date",
                              style: {
                                fontWeight: "bold",
                                fontSize: 17,
                              },
                            },
                          },

                          tooltip: {
                            // showMarkers: false,
                            // enterable: true,

                            customContent: (title, items) => {
                              const data = items[0]?.data || {};

                              const Distance = (
                                <div
                                  style={{
                                    // border: "1px solid",
                                    background: "white",
                                    padding: "10px",
                                  }}
                                  class="custom-tooltip-title"
                                >
                                  {/* Distance:{data.distanceTravelled} */}
                                  {this.context.translate("_DISTANCE_")}:
                                  {data.distanceTravelled
                                    ? data.distanceTravelled.toFixed(2)
                                    : "-"}
                                </div>
                              );

                              const date = (
                                <div
                                  style={{
                                    // border: "1px solid",
                                    background: "white",
                                    padding: "8px",
                                  }}
                                  class="custom-tooltip-title"
                                >
                                  {this.context.translate("_DATE_")}:
                                  {data.startTime ? data.startTime : "-"}
                                </div>
                              );

                              const vehicleNo = (
                                <div
                                  style={{
                                    // border: "1px solid",
                                    background: "white",
                                    padding: "8px",
                                  }}
                                  class="custom-tooltip-title"
                                >
                                  {/* Asset Name:{data.vehicleNo} */}
                                  {this.context.translate("_ASSET_NAME_")}:
                                  {data.vehicleNo ? data.vehicleNo : "-"}
                                </div>
                              );

                              const name = (
                                <div
                                  style={{
                                    // border: "1px solid",
                                    background: "white",
                                    padding: "8px",
                                  }}
                                  class="custom-tooltip-title"
                                >
                                  {this.context.translate("_DRIVER_NAME_")}:
                                  {data.name ? data.name : "-"}
                                </div>
                              );
                              const driverPhone = (
                                <div
                                  style={{
                                    // border: "1px solid",
                                    background: "white",
                                    padding: "10px",
                                  }}
                                  class="custom-tooltip-title"
                                >
                                  {this.context.translate("_DRIVER_MOBILE_NO_")}
                                  :{data.driverphone ? data.driverphone : "-"}
                                </div>
                              );

                              return (
                                <div
                                  style={{
                                    // border: "1px solid",
                                    background: "white",
                                    padding: "10px",
                                  }}
                                >
                                  <div>{Distance}</div>
                                  {date}
                                  {vehicleNo}

                                  {name}
                                  {driverPhone}
                                </div>
                              );
                            },
                          },
                        }}
                      />
                    )}

                  {this.state.horizontal &&
                    !this.state.Notasset &&
                    !this.state.Notcompany && (
                      <Bar
                        {...{
                          data:
                            this.distanceGraphStore?.distanceGraph?.length > 0
                              ? this.distanceGraphStore?.distanceGraph
                              : [],

                          xField: "distanceTravelled",

                          // yField: "vehicleNo",
                          // yField: (this.state.selectedVehicles.length > 1) ? 'vehicleNo' : 'startTime',
                          yField: this.state.xlabel,
                          legend: false,
                          animation: false,
                          seriesField: "distanceTravelled",
                          yAxis: {
                            label: {
                              style: {
                                fontWeight: "bold",
                                fontSize: 13,
                              },
                            },
                            title: {
                              // text: "Asset Name",
                              text:
                                this.state.xlabel === "vehicleNo"
                                  ? this.context.translate(
                                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                                    )
                                  : "Date",
                              // text: (this.state.selectedVehicles.length > 1) ? this.context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_") : "Date",
                              style: {
                                fontWeight: "bold",
                                fontSize: 17,
                              },
                            },
                          },
                          xAxis: {
                            label: {
                              style: {
                                fontWeight: "bold",
                                fontSize: 13,
                              },
                            },
                            title: {
                              // text:"Distance Travelled(Kms)",
                              text: this.context.translate(
                                "_DISTANCE_TRAVELED_KMS_"
                              ),
                              style: {
                                fontWeight: "bold",
                                fontSize: 17,
                              },
                            },
                          },

                          style: {
                            direction:
                              this.context.locale.language === "arabic"
                                ? "ltr"
                                : "ltr",
                          },

                          tooltip: {
                            // showMarkers: false,
                            // enterable: true,

                            customContent: (title, items) => {
                              console.log(items);
                              const data = items[0]?.data || {};
                              console.log(data);

                              const Distance = (
                                <div class="custom-tooltip-title">
                                  {/* Distance:{data.distanceTravelled} */}
                                  {this.context.translate("_DISTANCE_")}:
                                  {data.distanceTravelled
                                    ? data.distanceTravelled.toFixed(2)
                                    : "-"}
                                </div>
                              );

                              const date = (
                                <div class="custom-tooltip-title">
                                  {this.context.translate("_DATE_")}:
                                  {data.startTime}
                                </div>
                              );

                              const vehicleNo = (
                                <div class="custom-tooltip-title">
                                  {/* Asset Name:{data.vehicleNo} */}
                                  {this.context.translate("_ASSET_NAME_")}:
                                  {data.vehicleNo}
                                </div>
                              );

                              const name = (
                                <div class="custom-tooltip-title">
                                  {this.context.translate("_DRIVER_NAME_")}:
                                  {data.name}
                                </div>
                              );
                              const driverPhone = (
                                <div class="custom-tooltip-title">
                                  {this.context.translate("_DRIVER_MOBILE_NO_")}
                                  :{data.driverphone}
                                </div>
                              );

                              return (
                                <div
                                  style={{
                                    background: "white",
                                    padding: "10px",
                                  }}
                                >
                                  {Distance}
                                  {date}
                                  {vehicleNo}

                                  {name}
                                  {driverPhone}
                                </div>
                              );
                            },
                          },
                        }}
                      />
                    )}
                </>
              ) : (
                <Empty></Empty>
              )}
            </div>
          </div>
        </Spin>
      </>
    );
  }
}
DistanceGraph.contextType = StoreContext;
export default observer(withRouter(DistanceGraph));
