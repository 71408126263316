import React, { useState } from "react";
import {
  Select,
  Checkbox,
  Table,
  Row,
  Col,
  Button,
  Typography,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";

import {
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import config from "../config";
import withRouter from "../withRouter";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import DriverComplainReportStore from "../store/DriverComplainReportStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import ListTable from "./ListTable";
import DriverLoginReportStore from "../store/DriverLoginReportStore";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
const { Option } = Select;
class DriverPerformanceReportList extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.driverComplainReportStore = new DriverComplainReportStore();
    this.driverLoginReportStore = new DriverLoginReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedDriver: [],
      selectAllDrivers: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      showMonthPopup: false,
      Notcompany: false,
      notdriver: false,

      selectedColumns: [
        {
          // title: "  Driver ",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "name",
          key: "name",
          ind: 0,

          width: "15%",
        },
        {
          // title: "Complain ",
          title: context.translate("_COMPLAIN_"),
          dataIndex: "complain",
          key: "complain",
          ind: 1,
          width: "15%",
        },

        {
          // title: "  Complain Date Time",
          title: context.translate("_COMPLAIN_DATETIME_"),
          dataIndex: "complainDate",
          key: "complainDate",
          ind: 3,
          //fixed: 'left',
          width: "15%",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          var getData = {
            companyId: this.state.companyId,
            DriverId: this.state.selectedDriver,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.driverComplainReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.driverComplainReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.driverComplainReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.driverComplainReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.driverComplainReportStore.loadData({ offset: 0, limit: 50 });

    this.setState({ pageSize: 50 });

    if (Number(this.props.getUserData().isadmin) !== -1) {

      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId })
      this.handleChange({ value: companyId });
    }


    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });

    await this.setState({ selectedDriver: [], selectAllDrivers: false });
    await this.driverLoginReportStore.getDriversByCompanyId({
      companyId: comp.value,
    });
    console.log(this.driverLoginReportStore.getDriversByCompanyId);
    await this.handleSearch();
   
  }

  async handleSearch() {
    // await this.driverComplainReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.showData();
  }

  
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedDriver.length === 0) {
      this.setState({ notdriver: true });
    } else {
      this.setState({ notdriver: false });
    }
    if (this.state.companyId && this.state.selectedDriver.length > 0) {
      this.setState({ Notcompany: false, notdriver: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.driverComplainReportStore.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,

          driverId: this.state.selectedDriver,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
        });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      
      const newData = this.state.selectedColumns.slice(0)
      newData.splice(column.ind, 0, column);
      await this.setState({
        selectedColumns: newData,
        column: newData
      });
      console.log(this.state.selectedColumns);

    }
    await this.setState({ loadData: 1 })
  };
  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + (-6)
    // return date.format("YYYY-MM-DD HH:mm:ss"); 
    return dayjs().month(indx)
  }

  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      
      this.setState({ showMonthPopup: true })
      return false;
    } else {
      return true;
    }
  }
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleSelectAllDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.driverLoginReportStore?.getDrivers.map((val) => {
        return val.driverId;
      });
      await this.setState({ selectedDriver: allDrivers });
    } else {
      await this.setState({ selectedDriver: [] });
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Driver Complain Report</div>{" "} */}
              <div className="heading">{this.context.translate("_DRIVER_COMPLAIN_REPORT_")}</div>{" "}
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
              

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown menu={this.menuProps}
                  disabled={
                    this.driverComplainReportStore?.driverComplainStore?.length > 0 ? false : true
                  }
                >
                  <Button 
                   className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 200 }}
                  status={this.state.Notcompany ? 'error' : false}
                />
                <Checkbox
                  checked={this.state.selectAllDrivers}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllDrivers: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleSelectAllDrivers();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  onSearch={this.handleSearch}
                  mode="multiple"
                  value={this.state.selectedDriver}
                 maxTagCount="responsive"
                //  onChange={(val) => this.setState({ selectedDriver: val,selectAllDrivers : false })}
                onChange={(val) => {this.setState({ selectedDriver: val }, this.setState({notdriver:false}));
                  if(this.driverLoginReportStore?.getDrivers.length>val.length){
                    this.setState({selectAllDrivers:false})
                  }
                  else{
                    this.setState({selectAllDrivers:true})
                  }
                }}
                  fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate("_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_")}
                  optionFilterProp="children"
                  options={this.driverLoginReportStore?.getDrivers}
                  style={{ width: 160 }}
                  status={ this.state.notdriver? 'error' : false}
                /> */}
                <Select
                  // onSearch={this.handleSearch}
                  mode="multiple"
                  value={this.state.selectedDriver}
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.setState({
                      selectedDriver: val,
                      Notdriver: false,
                    });
                    if (
                      this.driverLoginReportStore?.getDrivers.length >
                      val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  // fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  optionFilterProp="children"
                  // options={this.driverLoginReportStore?.getDrivers}
                  // options={this.state.filterby=='Driver' ? this.driverLoginReportStore?.getDrivers :this.driverScoreReportStore?.getVehicles }
                  style={{ width: 150 }}
                  // style={DriverInputStyle}
                  status={this.state.notdriver ? 'error' : false}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.driverLoginReportStore?.getDrivers?.map((val) => {
                    return <Option value={val.driverId}>{val.name}</Option>;
                  })}
                </Select>
                <RangePicker
                  placeholder={["From Datetime", "To Datetime"]}

                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={350}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.driverComplainReportStore?.total}
            columns={this.state.column}
            dataSource={this.driverComplainReportStore?.driverComplainStore}
            loading={this.driverComplainReportStore?.loading}
            onChange={this.handleTableChange}
          // scroll={{
          //   // y: this.state.clientHeight-240,
          //   x: 100,
          // }}
          />

          <Modal
            open={this.state.showMonthPopup}
            title="Alert"
            onOk={() => this.setState({ showMonthPopup: false })}
            onCancel={() => this.setState({ showMonthPopup: false })}
            footer={false}
          >
            {/* For Reports prior to 6 months please contact administrator */}
            {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}

          </Modal>

        </div>
      </>
    );
  }
}
DriverPerformanceReportList.contextType = StoreContext;
export default observer(withRouter(DriverPerformanceReportList));
