import { observer } from "mobx-react";
import React, { Component } from "react";
import withRouter from "../withRouter";
import { Button, Col, DatePicker, Empty, Row, Select, Space, Spin } from "antd";
import CompanyAutocomplete from "./CompanyAutocomplete";
import { StoreContext } from "../store/RootStore";
import DistanceGraphStore from "../store/DistanceGraphStore";
import dayjs from "dayjs";
// import { Bar, Column, tooltip } from "@ant-design/plots";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line,
  LineChart,
} from "recharts";

import FuelGraphStore from "../store/FuelGraphStore";
import VehicleStore from "../store/VehicleStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
// import { Column } from "@ant-design/plots";
const barColors = ["#FF5733", "#33FF57", "#5733FF", "#FFFF33", "#33FFFF"];
const { Option } = Select;
const { RangePicker } = DatePicker;
class FuelGraph extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.distanceGraphStore = new DistanceGraphStore(this);
    this.fuelGraphStore = new FuelGraphStore(this);
    this.vehicleStore = new VehicleStore(this);

    this.state = {
      selectAllVehicles: false,
      selectedVehicles: "",
      update: 0,
      companyId: "",
      distance: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      loadData: 0,

      displayVehicleNo: "",
      fromNewDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toNewDate: dayjs().format("YYYY-MM-DD hh:mm:ss"),
      showFuelGraph: false,
      Notcompany: false,
      Notasset: false,
      loadingSpin: false,
    };
  }

  async handleChange(comp) {
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: "" });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    this.setState({ loadData: 1 });
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles) {
      // this.setState({mandatory:false})
      await this.setState({ loadingSpin: true });

      this.setState({ Notcompany: false, Notasset: false });
      const data = await this.fuelGraphStore.loadFuelGraphData({
        companyId: this.state.companyId,
        vehicleId: this.state.selectedVehicles,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,

        // pageNo: this.state.pageNo,
      });
      console.log(data);
      this.setState({ loadData: 1 });
      await this.setState({ loadingSpin: false });
    }
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });
      await this.vehicleStore.getVehiclesCompanyId(
        this.props.getUserData().userCompanyId
      );
      await this.setState({ update: 1 });
    }
  }
  onDateTimeChange = (dates) => {
    console.log(dates);
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
      const dateFormat = "YYYY-MM-DD hh:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromNewDate: dates[0].format(dateFormat),
        toNewDate: dates[1].format(dateFormat),
      });
    }
  };

  customTickFunction = (props) => {
    const { x, y, payload, visibleTicksCount, tickFormatter } = props;
    // console.log(x,y,)

    // Customize the tick appearance or position here
    return (
      <text
        x={x - 20}
        y={this.context.locale.language === "english" ? y + 5 : y}
        textAnchor="end"
        fontSize={12}
        dy={-4}
        style={{ lineHeight: "1.2" }}
      >
        {tickFormatter ? tickFormatter(payload.value) : payload.value}
      </text>
    );
  };

  CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;

      return (
        <div
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
          <div>{`${this.context.translate("_FUEL_")}: ${
            data.fuel ? data.fuel : "-"
          }`}</div>
        </div>
      );
    }
    return null;
  };

  render() {
    return (
      <>
        <Spin spinning={this.state.loadingSpin} fullscreen>
          <div style={{ padding: 0 }}>
            <Row className="headerFixed" style={{ height: "50px" }}>
              <Col span={12}>
                {/* <div className="heading">Fuel Graph</div> */}
                <div className="heading">
                  {this.context.translate("_FUEL_GRAPH_")}
                </div>
              </Col>
              <Col
                span={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>
          </div>
          <div
            style={{
              paddingTop: "10px",
              paddingRight: "24px",
              paddingLeft: "24px",
            }}
          >
            <Row className="listInputRow">
              <Col span={20}>
                <Space>
                  <CompanyAutocomplete
                    onChange={this.handleChange}
                    allowClear={true}
                    style={{ width: 175 }}
                    status={this.state.Notcompany ? "error" : false}
                  />

                  {this.props.getUserData().isadmin === 1 && (
                    <DeviceGroupsForVehicles
                      vehicleStore={this.vehicleStore}
                      setLoadDta={() => this.setState({ loadData: 1 })}
                      resetVehicles={() =>
                        this.setState({
                          selectedVehicles: "",
                          selectAllVehicles: false,
                        })
                      }
                    />
                  )}

                  <Select
                    value={
                      this.state.selectedVehicles
                        ? this.state.selectedVehicles
                        : null
                    }
                    // onChange={(val) => {
                    //   this.setState({ selectedVehicles: val });
                    //   this.setState({ Notasset: false });
                    // }}
                    onChange={(val, x) => {
                      this.setState({
                        selectedVehicles: val,
                        displayVehicleNo: x.children,
                      });
                    }}
                    style={{ width: 200 }}
                    showSearch
                    status={this.state.Notasset ? "error" : false}
                    placeholder={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                    )}
                    filterOption={(inputValue, option) => {
                      if (option.children) {
                        return (
                          option.children
                            .toLowerCase()
                            .indexOf(inputValue.toLowerCase()) >= 0
                        );
                      }
                    }}
                  >
                    {this.vehicleStore?.getVehiclesUsingCompanyId?.map(
                      (val) => {
                        return (
                          <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                        );
                      }
                    )}
                  </Select>

                  <RangePicker
                    // placeholder={["From Datetime", "To Datetime"]}
                    placeholder={[
                      this.context.translate("_FROM_DATETIME_"),
                      this.context.translate("_TO_DATETIME_"),
                    ]}
                    format="DD-MM-YYYY hh:mmA"
                    style={{ width: 400 }}
                    onChange={(val) => {
                      this.onDateTimeChange(val);
                    }}
                    disabledDate={(current) => {
                      return current && current > dayjs();
                    }}
                    showTime={{
                      format: "h:mmA",
                      defaultValue: [dayjs().startOf("day"), dayjs()],
                    }}
                    defaultValue={[dayjs().startOf("day"), dayjs()]}
                  />

                  <Button
                    type="primary"
                    onClick={async () => {
                      await this.showData();
                      await this.setState({ showFuelGraph: true });
                      // console.log(this.distanceGraphStore?.distanceGraph);
                    }}
                  >
                    {/* Show */}
                    {this.context.translate("_FLEET_SHOW_BTN_")}
                  </Button>
                </Space>
              </Col>
              <Col span={4}>
                <Space
                  style={{
                    float:
                      this.context.locale.direction == "ltr" ? "right" : "left",
                  }}
                ></Space>
              </Col>
            </Row>

            <div
              style={{
                paddingTop: "10px",
                paddingRight: "24px",
                paddingLeft: "24px",
              }}
            >
              {this.fuelGraphStore?.fuelGraph?.length > 0 && (
                <Row className="listInputRow">
                  <Col span={18}>
                    {/* <div className="heading">Fuel Graph</div> */}
                    <div className="heading">
                      {this.context.translate("_FUEL_GRAPH_")}
                    </div>
                    <b>
                      {this.context.translate(
                        "_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"
                      )}
                      : {this.state.displayVehicleNo}
                    </b>
                    <br />
                    <b>
                      {this.context.translate("_FROM_DATETIME_")} :{" "}
                      {this.state.fromNewDate}
                    </b>
                    <br />
                    <b>
                      {this.context.translate("_TO_DATETIME_")} :{" "}
                      {this.state.toNewDate}
                    </b>
                    <br />
                  </Col>
                  <Col
                    span={6}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                    }}
                  >
                    <Space
                      style={{
                        float:
                          this.context.locale.direction == "ltr"
                            ? "right"
                            : "left",
                      }}
                    ></Space>
                  </Col>
                </Row>
              )}
            </div>
            {this.state.showFuelGraph &&
            this.fuelGraphStore?.fuelGraph?.length > 0 ? (
              // <ResponsiveContainer height={400}>
              //   <BarChart
              //     layout="vertical"
              //     width={500}
              //     data={this.fuelGraphStore?.fuelGraph}
              //     // margin={{ bottom: 50 }}
              //   >
              //     <YAxis type="number" fontSize={"10px"} label={{ value: 'Fuel', angle: -90, position: 'insideLeft' }}/>
              //     <XAxis type="category" dataKey="timestamp" height={50} label={{ value: 'Time', position: 'insideBottom' }}/>

              //     <Bar dataKey="fuel" stackId="a" fill="orange"></Bar>
              //   </BarChart>
              // </ResponsiveContainer>
              <ResponsiveContainer height={500}>
                <LineChart
                  width={600}
                  //   height={300}
                  data={this.fuelGraphStore?.fuelGraph}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 10,
                  }}
                >
                  <XAxis
                    dataKey="timestamp"
                    angle={
                      this.context.locale.language === "english" ? -90 : 90
                    }
                    // interval={Math.ceil(
                    //   this.fuelGraphStore?.fuelGraph?.length / 35
                    // )}
                    textAnchor="end"
                    height={170}
                    label={{
                      value: this.context.translate("_TIME_"),
                      position: "insideBottom",
                    }}
                  />
                  {/* <XAxis
                dataKey="timestamp"
                height={60}
                label={{ value: "Time", position: "insideBottom" }}
              /> */}
                  <YAxis
                    label={{
                      value: this.context.translate("_FUEL_"),
                      angle: -90,
                      position: "insideLeft",
                    }}
                    // tick={this.customTickFunction}
                    style={{
                      direction:
                        this.context.locale.language === "arabic"
                          ? "ltr"
                          : "ltr",
                    }}
                  />
                  <Tooltip content={<this.CustomTooltip />} />
                  <Line
                    type="monotone"
                    dataKey="fuel"
                    stroke="orange"
                    strokeWidth={2}
                    dot={{ fill: "orange" }}
                  />
                </LineChart>
              </ResponsiveContainer>
            ) : (
              <Empty></Empty>
            )}
          </div>
        </Spin>
      </>
    );
  }
}
FuelGraph.contextType = StoreContext;
export default observer(withRouter(FuelGraph));
