import { Checkbox, Image, Modal, message } from "antd";
import React, { Component } from "react";
import SendCommandPopupStore from "../store/SendCommandPopupStore";
import Cookies from "js-cookie";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import withRouter from "../withRouter";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

class SendCommandPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.sendCommandPopupStore = new SendCommandPopupStore(this);

  // console.log(this.props)

  }
  // {"tokenId":"","vehicleId":324373,"IMEI":"865468051345810","cmdId":1,"otherCmdText":null,"userId":1,"isadmin":"-1"}
  sendCommandFunc = async (commandId) => {
    let vehicleInfoObj = this.props?.vehicleInfoObj;
    console.log(vehicleInfoObj,"vehicleInfoObj")
    let data = {
      tokenId: "",
      vehicleId: vehicleInfoObj?.vehicleId,
      IMEI: vehicleInfoObj?.full_imei,
      cmdId: commandId,
      otherCmdText: null,
      userId: 1,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    };
    // console.log(data)
    const response = await this.sendCommandPopupStore.sendVehicleCmd(data);
    // console.log(response);
    if (response.data.success === "S") {
      message.success("Command Sent to Device");
    } else {
      await message.error("Error");
    }
  };
  render() {
    return (
      <div>
        {this.props?.sendCommandPopUpBoolean && (
          <Modal
            title={this.context.translate(
              "_FLEET_DASHBORD_VEHICLE_COMMAND_TITLE_"
            )}
            visible={this.props?.sendCommandPopUpBoolean}
            onCancel={() => this.props?.sendCommandPopUpFunc(false)}
            footer={null}
            maskClosable={false}
            width={"20%"}
          >
            <div style={{ marginTop: "20px",marginLeft:'20px' }}>
              <div style={{ display: "flex", gap: "5%" }}>
                <Image
                  src={require("../assets/images/sendCmd/engine-on-icon.png")}
                  style={{ width: "60%" }}
                  onClick={() => this.sendCommandFunc("1")}
                />
                <Image
                  src={require("../assets/images/sendCmd/engine-off-icon.png")}
                  style={{ width: "60%" }}
                  onClick={() => this.sendCommandFunc("2")}
                />
                <Image
                  src={require("../assets/images/sendCmd/get-position-icon.png")}
                  style={{ width: "60%" }}
                  onClick={() => this.sendCommandFunc("&&865585040450136,Y01")}
                />
              </div>
              <div style={{ display: "flex", gap: "17%", marginTop: "20px" }}>
                <Image
                  src={require("../assets/images/sendCmd/lock-icon.png")}
                  style={{ width: "60%" }}
                  onClick={() => this.sendCommandFunc("&&865585040450136,XA1")}
                />
                <Image
                  src={require("../assets/images/sendCmd/unlock-icon.png")}
                  style={{ width: "60%" }}
                  onClick={() => this.sendCommandFunc("&&865585040450136,XA2")}
                />
                {/* <Checkbox>Others</Checkbox> */}
              </div>
            </div>
          </Modal>
        )}
      </div>
    );
  }
}
SendCommandPopup.contextType = StoreContext;
export default observer(withRouter(SendCommandPopup));
