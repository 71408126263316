import React from "react";
import {
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table,
  Button,
  Typography,
  Space,
  Row,
  Col,
  Checkbox,
  Dropdown,
  DatePicker,
  Modal,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext, StoreProvider } from "../store/RootStore";
import { Link } from "react-router-dom";
import withRouter from "../withRouter";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExportOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import ExIdleReportStore from "../store/ExIdleReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import { Icon, divIcon, point } from "leaflet";
import L from "leaflet";
import MapForVehiclePosition from "./MapForVehiclePosition";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import config from "../config";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { Text } = Typography;
const { Option } = Select;
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
const { RangePicker } = DatePicker;

class ExidleReport extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.exIdleReportStore = new ExIdleReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      offset: 0,
      limit: 50,
      selectedVehicles: [],
      selectAllVehicles: false,
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      interval: 0,
      loadData: 0,
      // modalInfoObj: {},
      // showMap: false,
      vehicleObj: false,
      heading: "",
      Notcompany: false,
      Notasset: false,
      showMonthPopup: false,
      showTable: true,
      showtotaltable: false,
      selectedColumns: [
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "name",
          width: "15%",
          key: "name",
        },

        {
          // title: "Model",
          title: context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_MODEL_"
          ),
          dataIndex: "vehicleTypeNew",
          width: "15%",
          key: "vehicleTypeNew",
        },
        {
          // title: "Location",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_LOCATION_"),
          dataIndex: "location",
          width: "15%",
          key: "location",
        },
        {
          // title: "Idle From",
          title: context.translate("_IDLE_FROM_"),
          dataIndex: "idleFrom",
          width: "15%",
          key: "idleFrom",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "Idle Till",
          title: context.translate("_IDLE_TILL_"),
          dataIndex: "idleTo",
          width: "15%",
          key: "idleTo",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },
        {
          // title: "Duration",
          title: context.translate(
            "_FLEET_REPORTS_STARTSTOPREPORT_ELAPSEDTIME_"
          ),
          dataIndex: "duration",
          width: "15%",
          key: "duration",
        },
        {
          // title: "View On Map",
          title: context.translate("_FLEET_SERVICE_POILIST_VIEWMAP_"),
          dataIndex: "viewOnMap",
          width: "15%",
          key: "viewOnMap",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: { ...obj, icon: "map-car-icon-green.png" },
                      heading: this.context.translate("_EXCESSIVE_IDLE_MAP_"),
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          console.log(result.toString());

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            colList: result.toString(),
          };
          // console.log(this.state.travelInt)
          // console.log("asfsdgfhdfghfg",parseInt(JSON.parse(base64_decode(Cookies.get("data"))).userId))
          if (val.key == "excel") {
            // console.log("afd")
            this.exIdleReportStore.exportExcel(getData);
          }
          if (val.key == "csv") {
            this.exIdleReportStore.exportcsv(getData);
          }
          if (val.key == "pdf") {
            this.exIdleReportStore.exportpdf(getData);
          }

          if (val.key == "xml") {
            this.exIdleReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }

  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.exIdleReportStore.loadData({ offset: 0, limit: 50 });
    await this.setState({ column: this.state.selectedColumns });

    this.columns1 = [
      {
        // title: "Asset Name",
        title: this.context.translate(
          "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
        ),
        dataIndex: "vehicleNo",
        width: "15%",
        //   summary:  (records) =>{
        //     return '<b>Total</b>';
        // }
      },
      {
        // title: "From Date",
        title: this.context.translate("_FROM_DATE_"),
        dataIndex: "fromDate",
        width: "15%",
      },

      {
        // title: "To Date",
        title: this.context.translate("_TO_DATE_"),
        dataIndex: "toDate",
        width: "15%",
      },
      {
        // title: "Total ExIdling Count",
        title: this.context.translate("_TOTAL_EXIDLING_COUNT_"),
        dataIndex: "totalExIdilng",
        width: "15%",
        //   summary:  (records) =>  {
        //     var suma = 0;
        //     records.map((record)=>{
        //         if (record.totalExIdilng) {
        //             suma = parseInt(suma) + parseInt(record.totalExIdilng);
        //         }
        //     });
        //     return '<b>' + suma + '</b>';
        // }
      },
      {
        // title: "Total Duration",
        title: this.context.translate("_TOTAL_DURATION_"),
        dataIndex: "totalDuration",
        width: "15%",

        //   summary:  (records) =>  {
        //     var suma = 0;
        //     records.map((record)=>{
        //         if (record.totalDuration) {
        //             var dt = record.totalDuration.split(":");
        //             var ts = parseInt(dt[0] * 60 * 60) + parseInt(dt[1] * 60) + parseInt(dt[2]);
        //             suma = parseInt(parseInt(suma) + parseInt(ts));

        //         }
        //     });
        //     var d, h, m, s;

        //     s = suma;
        //     m = Math.floor(s / 60);
        //     s = s % 60;
        //     h = Math.floor(m / 60);
        //     m = m % 60;
        //     d = Math.floor(h / 24);
        //     h = h % 24;

        //     var result = (d < 10 ? "0" + d : d) + " days,";
        //     result += (h < 10 ? "0" + h : h);
        //     result += ":" + (m < 10 ? "0" + m : m);
        //     result += ":" + (s < 10 ? "0" + s : s);

        //     //console.log({d: d, h: h, m: m, s: s });
        //     //return result;
        //     return '<b>' + result + '</b>';
        // }
      },
    ];
    if(Number(this.props.getUserData().isadmin)!==-1){
    
      let companyId = this.props.getUserData().userCompanyId;
   
        this.setState({companyId:companyId})
        this.handleChange({value:companyId});
    }

    this.setState({ update: 1 });
  }

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: [], selectAllVehicles: false });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.handleSearch();

    // console.log("data", data);
  }

  async handleSearch() {
    // await this.exIdleReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   vehicleId: this.state.selectedVehicles,
    //   pageNo: this.state.pageNo,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  // vehicleSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedVehicles: val });
  // };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromdateStdTime)) {
        await this.exIdleReportStore.loadData({
          offset: (this.state.pageNo - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,
          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          interval: this.state.interval,
          pageNo: this.state.pageNo,
        });
      }
    }
    // console.log( this.state.fromDate,this.state.toDate,"qwqwrwertryerytuyi")
  };
  showTotal = async () => {
    await this.exIdleReportStore.loadDataTotal({
      offset: (this.state.pageNo - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      vehicleId: this.state.selectedVehicles,
      fromDate: this.state.fromDate,
      toDate: this.state.toDate,
      interval: this.state.interval,
      // pageNo: this.state.pageNo,
    });
  };

  onDateTimeChange = (dates) => {
    console.log(dates);
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Ex-Idle Report</div> */}
              <div className="heading">
                {this.context.translate("_EX_IDLE_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                <Button
                  onClick={async () => {
                    await this.showTotal();
                    await this.setState({
                      showTable: false,
                      showtotaltable: true,
                    });
                  }}
                >
                  {this.context.translate("_TOTAL_")}
                </Button>

            
                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.exIdleReportStore?.exIdleReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button className="exportDropdown"
                   style={{ backgroundColor: config.activeColor }}
                   >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>

              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          {/* <Row style={{ padding: "10px 0px" }}> */}
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 200 }}
                  status={this.state.Notcompany ? "error" : false}
                />
                  {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles:false
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  maxTagCount="responsive"
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicless}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                  }}
                  // onChange={(val) => this.vehicleSelect(val)}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 200 }}
                  status={this.state.Notasset ? "error" : false}
                /> */}

                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 150 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />
                <Input
                  style={{ width: 100 }}
                  placeholder={this.context.translate("_INTERVAL_")}
                  value={this.state.interval}
                  onChange={(e) => this.setState({ interval: e.target.value })}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.showData();
                    await this.setState({
                      showTable: true,
                      showtotaltable: false,
                    });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <div
           className="accordion-list"
            onClick={() =>
              this.setState({
                showTable: !this.state.showTable,
                showtotaltable: !this.state.showtotaltable,
              })
            }
          >{this.context.translate("_EX_IDLE_REPORT_")}
            <Button >
              {this.state.showTable ? <CaretDownOutlined /> :<CaretUpOutlined />}
            </Button>
          </div>

          {this.state.showTable && (
            <ListTable
              currentPage={this.state.currentPage}
              pageSize={this.state.pageSize}
              total={this.exIdleReportStore?.total}
              columns={this.state.column}
              dataSource={this.exIdleReportStore?.exIdleReport}
              loading={this.exIdleReportStore?.loading}
              onChange={this.handleTableChange}
              // pagination={{
              //   pageSize: this.state.pageSize,
              //   total: this.exIdleReportStore.total || 0,
              // }}
              // scroll={{
              //   // y: this.state.clientHeight-240,
              //   x: 100,
              // }}
            />
          )}

          {/* <div style={{backgroundColor: "Transparent " ,color :"#0090e7" , fontSize:"16px" ,padding: "8px 8px" ,fontWeight : "600", marginTop:"8px" ,marginBottom:"5px"}}>Total Ex Idle Report</div> */}
          {/* <div
            style={{
              backgroundColor: "Transparent ",
              color: "#0090e7",
              fontSize: "16px",
              padding: "8px 8px",
              fontWeight: "600",
              marginTop: "8px",
              marginBottom: "5px",
            }}
          >
            {this.context.translate("_TOTAL_EX_IDLE_REPORT_")}
          </div> */}
          <>
            <div
               className="accordion-list"
              onClick={() =>
                this.setState({
                  showtotaltable: !this.state.showtotaltable,
                  showTable: !this.state.showTable,
                })
              }
            >
              {" "}
              {this.context.translate("_TOTAL_EX_IDLE_REPORT_")}
              <Button >
                {this.state.showtotaltable ? <CaretDownOutlined/> :<CaretUpOutlined/>}
              </Button>{" "}
            </div>

            {this.state.showtotaltable && (
              <>
                {" "}
                <ListTable
                  // current={this.state.currentPage}
                  // total={this.exIdleReportStore?.total}
                  pageSize="50"
                  columns={this.columns1}
                  dataSource={this.exIdleReportStore?.totalReport}
                  loading={this.exIdleReportStore?.loading}
                  // onChange={this.handleTableChange}
                  summary={(records) => {
                    var sum = 0;
                    var suma = 0;

                    records.map((record) => {
                      if (record.totalExIdilng) {
                        sum = parseInt(sum) + parseInt(record.totalExIdilng);
                      }

                      if (record.totalDuration) {
                        var dt = record.totalDuration.split(":");
                        var ts =
                          parseInt(dt[0] * 60 * 60) +
                          parseInt(dt[1] * 60) +
                          parseInt(dt[2]);
                        suma = parseInt(parseInt(suma) + parseInt(ts));
                      }
                    });

                    var d, h, m, s;

                    s = suma;
                    m = Math.floor(s / 60);
                    s = s % 60;
                    h = Math.floor(m / 60);
                    m = m % 60;
                    d = Math.floor(h / 24);
                    h = h % 24;

                    var result = (d < 10 ? "0" + d : d) + " days,";
                    result += h < 10 ? "0" + h : h;
                    result += ":" + (m < 10 ? "0" + m : m);
                    result += ":" + (s < 10 ? "0" + s : s);

                    return (
                      <>
                        {records.length != 0 && (
                          <Table.Summary.Row>
                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>Total</Text>
                              {/* <Text style={{fontWeight: 'bold'}}>{this.context.translate("_TOTAL_")}</Text> */}
                            </Table.Summary.Cell>

                            <Table.Summary.Cell />
                            <Table.Summary.Cell />

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>{sum}</Text>
                            </Table.Summary.Cell>

                            <Table.Summary.Cell>
                              <Text style={{ fontWeight: "bold" }}>
                                {result}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        )}
                      </>
                    );
                  }}
                />
              </>
            )}
          </>
          {/* <Modal
            title="Excessive Idle Map"
            open={this.state.showMap}
            centered
          
            width={500}
            onOk={() => this.setState({ showMap: false })}
            onCancel={() => this.setState({ showMap: false })}
            footer={false}
          >
            <MapContainer
              ref={this.mapRef}
              zoom={14}
              center={[
                this.state.modalInfoObj.latitude,
                this.state.modalInfoObj.longitude,
              ]}
              style={{ height: "300px", width: "450px" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // attribution="Map data © OpenStreetMap contributors"
              />

              <Marker
                icon={dotIcon}
                position={[
                  this.state.modalInfoObj.latitude,
                  this.state.modalInfoObj.longitude,
                ]}
              >
              
                <Popup>{this.state.modalInfoObj.vehicleNo}</Popup>
              </Marker>
            </MapContainer>
          </Modal> */}
        </div>
        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
ExidleReport.contextType = StoreContext;
export default observer(withRouter(ExidleReport));
