import {Menu,Form,Input, Select,Row,Col,DatePicker,Switch,Checkbox,Button} from 'antd';
import React, { useContext } from 'react';
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
/*"displayDomains" : ["awtltrack"],*/

  const AccountMenus = (props) => {
        console.log('props',props);
        
    const shouldShow = shouldShowComponent(config.project, "AccountMenus");

     const storeContext = useContext(StoreContext); // Use the context here
     
     const items = props.menuItems.map(item =>
      props.getItem(item.label, item.key)
    );


    console.log('items**',items);


    if (!shouldShow) {
      return null; // Do not render if the condition is not met
    }




    return (
      <>
      {/* <div>
        {items.map(item => (
        
        <div key={item.key}>
        <div>{item.label}</div>
        <div>{item.icon}</div>
        <div>{item.children}</div>
        </div>
        ))}
        </div> */}
      </>

    );
  };


  AccountMenus.contextType = StoreContext;
export default observer(withRouter(AccountMenus));
