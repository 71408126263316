import React, { useState, useEffect } from "react";
import { TreeSelect } from "antd";

import { useCookies } from "react-cookie";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { StoreContext } from "../store/RootStore";
import withRouter from "../withRouter";
import { observer } from "mobx-react";

const { SHOW_PARENT } = TreeSelect;
const { TreeNode } = TreeSelect;

const UserGroups = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies();
  let isadmin = Number(JSON.parse(base64_decode(cookies.data)).isadmin);
  let companyId = Number(JSON.parse(base64_decode(cookies.data)).userCompanyId);
  let userId = Number(JSON.parse(base64_decode(cookies.data)).userId);
  const [value, setValue] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const onChange = async (newSelectedIds) => {
    setValue(newSelectedIds);
    await props.liveTrackingStore.loadData({
      value: newSelectedIds,
    });
    props.updateSelectedVehicles(newSelectedIds);
  };

  useEffect(() => {
    getTreeData(companyId, userId, isadmin);
  }, []);

  const getTreeData = async (companyId, userId, isadmin) => {
    const groups = await props.vehicleStore.getVehicleGroup({
      companyId,
      userId,
      isadmin,
    });
    setTreeData(groups.data.data);
  };

  const renderTreeNodes = (data) => {
    return data.map((item) => {
      if (item.children) {
        return (
          <TreeNode
            key={item.deviceGroupId}
            value={item.deviceGroupId}
            title={item.text}
          >
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return (
        <TreeNode
          key={item.vehicleId}
          value={item.vehicleId}
          title={item.text}
        />
      );
    });
  };

  const tProps = {
    ...props,
    value,
    onChange,
    treeCheckable: true,
    placeholder: "Vehicle Groups",
  };

  return (
    <TreeSelect maxTagCount={"responsive"} {...tProps}>
      {renderTreeNodes(treeData)}
    </TreeSelect>
  );
};

UserGroups.contextType = StoreContext;
export default observer(withRouter(UserGroups));
