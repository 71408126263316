import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Checkbox, DatePicker, Form, Input, Select } from "antd";
import VehicleStore from "../../../store/VehicleStore";

const VehicleAccountTabFormFields = (props) => {
  console.log("props", props.isadmin);
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleAccountTabFormFields"
  );
  //   console.log("shouldShow**", shouldShow);
  const storeContext = useContext(StoreContext); // Use the context here

  const vehicleStore = new VehicleStore();
  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Form.Item
        name="ownership"
        label={storeContext.translate("_VEHICLE_OWNERSHIP_")}
        // rules={[{ required: true }]}
      >
        <Select
          options={[
            {
              // label: "Vertical Cylinder",
              label: storeContext.translate("_OWN_"),
              value: "Own",
            },
            {
              // label: "Horizontal Rectangle",
              label: storeContext.translate("_RENTED_"),
              value: "Rented",
            },
          ]}
        />
      </Form.Item>

      <Form.Item
        name="mdvrfull_imei"
        label={storeContext.translate("_MDVR_IMEI_")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="minimumFineAmount"
        label={storeContext.translate("_MIN_FINE_AMOUNT_")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="finePerMinute"
        label={storeContext.translate("_FINE_PER_MINUTE_")}
      >
        <Input />
      </Form.Item>
    </>
  );
};

VehicleAccountTabFormFields.contextType = StoreContext;
export default observer(withRouter(VehicleAccountTabFormFields));
