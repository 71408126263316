import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Checkbox, DatePicker, Form, Input, Select } from "antd";

const VehicleTankTabFormFields = (props) => {
  console.log("props", props);
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleTankTabFormFields"
  );
  //   console.log("shouldShow**", shouldShow);
  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Form.Item
        name="region"
        //  label="Region"
        label={storeContext.translate("_REGION_")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="branch"
        // label="Branch"
        label={storeContext.translate("_BRANCH_")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="department"
        //  label="Department"
        label={storeContext.translate("_DEPARTMENT_")}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="ownership"
        // label="Ownership"
        label={storeContext.translate("_OWNERSHIP_")}
      >
        <Input />
      </Form.Item>
    </>
  );
};

VehicleTankTabFormFields.contextType = StoreContext;
export default observer(withRouter(VehicleTankTabFormFields));
