import React, { Component } from "react";

import { Form, Select, Button, Input, Checkbox, Row, Col, Spin } from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import withRouter from "../withRouter";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";

import { format, addMinutes } from "date-fns";
import CompanyStore from "../store/CompanyStore";
import dayjs from "dayjs";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";

class VehicleWorkingHoursAdd extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      Id: "",
      vehiclesAssetArray: [],
      workingHourId: -1,
      companyId: "",
      Vehicle: false,
      selectedDriver: false,
      selectedVehicle: false,
      linkError: "",
      loadingSpin: false,
    };
    this.workingHoursStore = new VehicleWorkingHoursStore();
    this.vehicleStore = new VehicleStore();
    this.companyStore = new CompanyStore();
  }

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 ||
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      this.context.viewStateStore.setWorkingHoursStates({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      console.log(this.state.companyId);
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      const data = await this.workingHoursStore.getAssignVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });

      // console.log("data", data);
      const drivelData = await this.workingHoursStore.getAssignDriver(
        this.props.getUserData().userCompanyId
      );
    }
    if (Number(this.props.getUserData().isadmin) === 1) {
      await this.workingHoursStore.getVehicleGroup({
        companyId: this.props.getUserData().userCompanyId,
        userId: this.props.getUserData().userId,
        isadmin: this.props.getUserData().isadmin,
      });
      this.setState({ loadData: 1 });
    }

    const wid = this.props.vehicleWorkingHoursId;
    console.log(wid);

    if (wid) {
      await this.filldata(wid);
      this.setState({ wid: wid });
    }
  }

  filldata = async (wid) => {
    await this.setState({ loadingSpin: true });
    const getVehicleWorkingHours =
      await this.workingHoursStore.getWokingHoursData(
        wid,
        this.props.companyId
      );
    console.log("getVehicleWorkingHours", getVehicleWorkingHours);

    await this.setState({
      companyId: getVehicleWorkingHours.companyId,
      workingHourId: getVehicleWorkingHours.workingHourId,
    });
    const singleData = await this.companyStore.getCompanyDataById(
      getVehicleWorkingHours.companyId
    );

    const drivelData = await this.workingHoursStore.getAssignDriver(
      getVehicleWorkingHours.companyId
    );
    console.log(drivelData);

    const data = await this.workingHoursStore.getAssignVehicles({
      companyId: getVehicleWorkingHours.companyId,
    });

    this.formRef.current.setFieldsValue({
      companyId: singleData,
      shiftname: getVehicleWorkingHours.shiftName,
      workingDays: getVehicleWorkingHours.workingDays,
      isAssign:
        Number(getVehicleWorkingHours.isAssign) === 1 ? "Vehicle" : "Driver",
      vehicleIds: getVehicleWorkingHours.vehicleIds,
      driverIds: getVehicleWorkingHours.driverIds,
      fromTime: dayjs(
        getVehicleWorkingHours.workingHourFrom,
        "hh:mm A"
      )?.format("HH:mm"),
      toTime: dayjs(getVehicleWorkingHours.workingHourTo, "hh:mm A")?.format(
        "HH:mm"
      ),
      // vehicleIds: getVehicleWorkingHours.vehicleIds,
    });
    this.setState({ fromTime: getVehicleWorkingHours.workingHourFrom });
    this.setState({ toTime: getVehicleWorkingHours.workingHourTo });
    console.log(
      dayjs(getVehicleWorkingHours.workingHourTo, "hh:mm A")?.format("HH:mm"),
      "hel"
    );
    if (getVehicleWorkingHours.isAssign == 1 || getVehicleWorkingHours.isAssign=="Vehicle") {
      this.setState({ Vehicle: true });
      this.setState({ Driver: false });
    } else if (getVehicleWorkingHours.isAssign == 2 || getVehicleWorkingHours.isAssign=="Driver") {
      this.setState({ Driver: true });
      this.setState({ Vehicle: false });
    }
    // this.setState({selectedDriver:true,selectedVehicle:true})
    if (
      this.workingHoursStore?.assignAssetList?.length ===
      getVehicleWorkingHours.vehicleIds?.length
    ) {
      this.setState({ selectedVehicle: true });
    }
    console.log(
      this.workingHoursStore?.assignAssetList?.length,
      getVehicleWorkingHours.vehicleIds
    );
    if (
      this.workingHoursStore?.assignDriverList?.length ===
      getVehicleWorkingHours.driverIds?.length
    ) {
      this.setState({ selectedDriver: true });
    }
    await this.setState({ loadingSpin: false });
  };

  onWorkingHourDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    // console.log(values);
    const data = {
      ...values,
      companyId: this.state.companyId,
      workingHourId: this.state.workingHourId,
      // isAssign: "Vehicle",
      // workingDays:[values.workingDays]
      // toTime:"HH:mm",
      // fromTime:"HH:mm"
    };
    // console.log(data);
    const resp = await this.workingHoursStore.addData(data);
    await this.props.response(resp);
    if (
      resp.data.errors[0]?.fromTime === "This time slot is already assigned!"
    ) {
      this.setState({
        linkError: (
          <div style={{ color: "red", fontSize: 12, marginTop: 5 }}>
            This time slot is already assigned!
          </div>
        ),
      });
      // this.formRef.current.setFieldsValue({vehicleId:""})
    }

    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehicleworkinghourslist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["fromTime"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });
    await this.workingHoursStore.getVehicleGroup({
      companyId: this.state.companyId,
      userId: this.props.getUserData().userId,
      isadmin: this.props.getUserData().isadmin,
    });

    this.setState({ loadData: 1 });
    // const vehicleAsset = await this.vehicleStore.getVehicles(comp.value)
    // await this.setState({ vehiclesAssetArray: vehicleAsset })
    // console.log(comp.value)
    if (!this.props.vehicleWorkingHoursId) {
      this.context.viewStateStore.setWorkingHoursStates({
        companyId: comp.value,
      });
    }
    const data = await this.workingHoursStore.getAssignVehicles({
      companyId: comp.value,
    });

    const drivelData = await this.workingHoursStore.getAssignDriver(comp.value);

    // if (Number(this.props.getUserData().isadmin) === 3) {

    // }
    await this.handleSearch();
  }

  async handleSearch() {
    await this.workingHoursStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  timeOptions = () => {
    const timeArray = [];

    let startTime = new Date(2000, 0, 1, 0, 0); // 12:00 AM

    let endTime = new Date(2000, 0, 1, 23, 30);
    for (let time = startTime; time <= endTime; time = addMinutes(time, 30)) {
      // const formattedTime = format(time, 'h:mm aa');
      const formattedTime = format(time, "HH:mm");
      var ob = {
        formattedTime: formattedTime,
        time: time,
      };
      timeArray.push(ob);
    }

    return timeArray.map((data) => {
      return (
        <Select.Option key={data.formattedTime} value={data.formattedTime}>
          {format(data.time, "h:mm aa")}
        </Select.Option>
      );
    });
  };

  handleSelectAll = async (e) => {
    var arr = [];
    this.setState({ selectedVehicle: e.target.checked });
    console.log(e.target.checked);
    if (e.target.checked) {
      const data = await this.workingHoursStore?.assignAssetList.map((option) =>
        arr.push(option.vehicleId)
      );

      this.formRef.current.setFieldsValue({ vehicleIds: arr });

      await this.setState({ selectedValues: arr });
    } else {
      arr = [];
      this.formRef.current.setFieldsValue({ vehicleIds: arr });
    }
  };

  handleSelectAllDrivers = async (e) => {
    console.log(e);
    var arr = [];
    this.setState({ selectedDriver: e.target.checked });

    if (e.target.checked) {
      const data = await this.workingHoursStore?.assignDriverList.map(
        (option) => arr.push(option.driverId)
      );

      this.formRef.current.setFieldsValue({ driverIds: arr });

      await this.setState({ selectedValues: arr });
    } else {
      arr = [];
      this.formRef.current.setFieldsValue({ driverIds: arr });
    }
  };

  assignTo = (value) => {
    // console.log(value);
    if (value == "Vehicle") {
      this.setState({ Vehicle: true });
      this.setState({ Driver: false });
    } else if (value == "Driver") {
      this.setState({ Driver: true });
      this.setState({ Vehicle: false });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  getSubGroup = async (val) => {
    const subgroupData = await this.workingHoursStore.getVehicleSubGroup({
      deviceGroupId: val,
      deviceSubGroupId: null,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      subgroup: val,
    });

    this.setState({ loadData: 1 });
  };

  getSubGroupVehicles = async (val) => {
    const subgroupData = await this.workingHoursStore.getVehicleSubGroup({
      deviceGroupId: this.state.deviceGroupId,
      deviceSubGroupId: val,
      companyId: this.state.companyId,

      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });
    this.setState({ loadData: 1 });
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onWorkingHourDetailsSubmit}
              {...this.layout}
              name="nest-message"
              className="formClass" //change
            >
              <Row className="overflowFormInputs">
                {/* css class */}
                <Col span={12} className="formColumnClass">
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="shiftname"
                    label={this.context.translate("_SHIFT_NAME_")}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="workingDays"
                    className="search-field"
                    label={this.context.translate("_WORKING_DAYS_")}
                    rules={[{ required: true }]}
                  >
                    <Select mode="multiple" maxTagCount="responsive">
                      <Select.Option value="Sunday">
                        {this.context.translate("_SUNDAY_")}
                      </Select.Option>
                      <Select.Option value="Monday">
                        {this.context.translate("_MONDAY_")}
                      </Select.Option>
                      <Select.Option value="Tuesday">
                        {this.context.translate("_TUESDAY_")}
                      </Select.Option>
                      <Select.Option value="Wednesday">
                        {this.context.translate("_WEDNESDAY_")}
                      </Select.Option>
                      <Select.Option value="Thursday">
                        {this.context.translate("_THURSDAY_")}
                      </Select.Option>
                      <Select.Option value="Friday">
                        {this.context.translate("_FRIDAY_")}
                      </Select.Option>
                      <Select.Option value="Saturday">
                        {this.context.translate("_SATURDAY_")}
                      </Select.Option>
                    </Select>
                  </Form.Item>

                  {Number(this.props.getUserData().isadmin) !== -1 && (
                    <>
                      <Form.Item
                        name="group"
                        className="search-field"
                        label={this.context.translate("Group")}
                        // rules={[{ required: true }]}
                      >
                        <Select
                          // style={{ width: 120 }}
                          showSearch
                          mode="multiple"
                          maxTagCount="responsive"
                          filterOption={(inputValue, option) => {
                            if (option.children) {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(inputValue.toLowerCase()) >= 0
                              );
                            }
                          }}
                          onChange={async (val) => {
                            await this.getSubGroup(val);
                            await this.setState({ deviceGroupId: val });
                            await this.formRef.current.setFieldsValue({
                              vehicleIds: undefined,
                              subgrp: undefined,
                            });
                          }}
                          // placeholder="Group"
                          // onSelect={async (val) => {
                          //   await this.getSubGroup(val);
                          //   await this.setState({ deviceGroupId: val });
                          // }}
                        >
                          {this.workingHoursStore.groupList?.map((val) => {
                            return (
                              <Select.Option
                                value={val.deviceGroupId}
                                key={val.deviceGroupId}
                              >
                                {this.props.getUserData().isadmin == 3
                                  ? val.groupname
                                  : val.text}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="subgrp"
                        className="search-field"
                        label={this.context.translate("Sub Group")}
                        // rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          // style={{ width: 120 }}
                          placeholder="Sub Group"
                          filterOption={(inputValue, option) => {
                            if (option.children) {
                              return (
                                option.children
                                  .toLowerCase()
                                  .indexOf(inputValue.toLowerCase()) >= 0
                              );
                            }
                          }}
                          onChange={async () => {
                            await this.formRef.current.setFieldsValue({
                              vehicleIds: undefined,
                            });
                          }}
                          onSelect={async (val) => {
                            await this.setState({ deviceSubGroupId: val });
                            await this.getSubGroupVehicles(val);
                          }}
                        >
                          {this.workingHoursStore.subGroupList?.map((val) => {
                            return (
                              <Select.Option
                                value={val.deviceSubGroupId}
                                key={val.deviceSubGroupId}
                              >
                                {val.subgroupname}
                              </Select.Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </>
                  )}
                </Col>
                <Col span={12} className="formColumnClass">
                  <Form.Item
                    name="fromTime"
                    label={this.context.translate("_TIME_FROM_")}
                    rules={[{ required: true }]}
                    help={this.state.linkError ? this.state.linkError : null}
                    // validateStatus={this.state.linkError?"error": ""}
                  >
                    <Select
                      // onChange={this.setFromTime}
                      status={this.state.linkError ? "error" : null}
                      onSelect={(val) => this.setState({ fromTime: val })}
                      // value={this.state.fromTime}
                      allowClear={true}
                    >
                      {this.timeOptions()}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="toTime"
                    label={this.context.translate("_TIME_TO_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onSelect={(val) => this.setState({ toTime: val })}
                      onChange={(e) => console.log(e)}
                      // value={this.state.fromTime}
                      allowClear={true}
                    >
                      {this.timeOptions()}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    name="isAssign"
                    // label="Assign To"
                    label={this.context.translate("_ASSIGN_TO_")}
                    rules={[{ required: true }]}
                  >
                    <Select
                      onSelect={this.assignTo}
                      options={[
                        {
                          // label: "Vehicle",
                          label: this.context.translate("_VEHICLES_"),
                          value: "Vehicle",
                        },
                        {
                          // label: "Driver",
                          label: this.context.translate(
                            "_FLEET_MANAGEVEHICLE_DRIVER_TAB_"
                          ),
                          value: "Driver",
                        },
                      ]}
                    />
                  </Form.Item>
                  {this.state.Vehicle && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          name="vehicleIds"
                          label={this.context.translate("_ASSIGN_ASSET_")}
                          // label="Assign Asset"
                          style={{ width: "80%" }}
                          className="search-field"
                          rules={[{ required: true }]}
                        >
                          <Select
                            placeholder={this.context.translate(
                              "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                            )}
                            // placeholder="Select Asset"
                            maxTagCount={"responsive"}
                            mode="multiple"
                            showSearch
                            fieldNames={{
                              value: "vehicleId",
                              label: "vehicleNo",
                            }}
                            optionFilterProp="children"
                            options={this.workingHoursStore?.assignAssetList}
                          />
                        </Form.Item>

                        <Form.Item label=" ">
                          <Checkbox
                            checked={this.state.selectedVehicle}
                            onChange={(e) => {
                              console.log(e);
                              this.handleSelectAll(e);
                            }}
                          >
                            {/* All */}
                            {this.context.translate("_ALL_")}{" "}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </>
                  )}
                  {this.state.Driver && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Form.Item
                          name="driverIds"
                          // label="Assign Driver"
                          label={this.context.translate("_ASSIGN_DRIVER_")}
                          className="search-field"
                          style={{ width: "80%" }}
                          rules={[{ required: true }]}
                        >
                          <Select
                            // placeholder="Select Driver"
                            placeholder={this.context.translate(
                              "_SELECT_DRIVER_"
                            )}
                            maxTagCount={"responsive"}
                            mode="multiple"
                            showSearch
                            fieldNames={{
                              value: "driverId",
                              label: "driverName",
                            }}
                            optionFilterProp="children"
                            options={this.workingHoursStore?.assignDriverList}
                          />
                        </Form.Item>

                        <Form.Item label=" ">
                          <Checkbox
                            checked={this.state.selectedDriver}
                            onChange={(e) => {
                              console.log(e);
                              this.handleSelectAllDrivers(e);
                            }}
                          >
                            {/* All */}
                            {this.context.translate("_ALL_")}{" "}
                          </Checkbox>
                        </Form.Item>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/vehicleworkinghourslist"}>
                  {" "}
                  <Button ghost type="primary">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}

                <Button
                  className="formCancelButton"
                  type="primary"
                  onClick={(e) => {
                    this.closeModal();
                    // this.formRef.current?.resetFields();
                    this.setState({
                      selectedDriver: false,
                      selectedVehicle: false,
                    });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

VehicleWorkingHoursAdd.contextType = StoreContext;
export default observer(withRouter(VehicleWorkingHoursAdd));
