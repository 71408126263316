import React from "react";
import {
  Select,
  Button,
  Space,
  Row,
  Col,
  Checkbox,
  Dropdown,
  DatePicker,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { DownOutlined } from "@ant-design/icons";
import OverTimeReportStore from "../store/OverTimeReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import DriverLoginReportStore from "../store/DriverLoginReportStore";
import withRouter from "../withRouter";

import dayjs from "dayjs";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import Cookies from "js-cookie";
import config from "../config";
const { RangePicker } = DatePicker;
const { Option } = Select;
class OverTimeReport extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.overTimeReportStore = new OverTimeReportStore();
    this.driverLoginReportStore = new DriverLoginReportStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedDriver: [],
      selectAllDrivers: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      // selectedRange: [dayjs().startOf("day"), dayjs()],
      // datePickerRange: [dayjs().startOf("day"), dayjs()],

      Notcompany: false,
      Notdriver: false,

      selectedColumns: [
        {
          // title: "Driver Name",
          title: context.translate(
            "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
          ),
          dataIndex: "driverName",
          key: "driverName",

          width: "10%",
        },
        {
          // title: "Vehicle No",
          title: context.translate("_FLEET_DASHBORD_RULETRIGGER_VEHICLE_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          width: "10%",
        },

        {
          // title: "Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "date",
          key: "date",

          width: "10%",
        },
        {
          // title: "Working Hours (Travel Time)",
          title: context.translate("_WORKING_HR_"),
          dataIndex: "elapsedTime",
          key: "elapsedTime",

          width: "20%",
        },
        {
          // title: "Allocate Working Hour",
          title: context.translate("_ALLOCATE_WORKING_HR_"),
          dataIndex: "allocateWorkingHour",
          key: "allocateWorkingHour",

          width: "15%",
        },
        {
          // title: "Overtime Hours",
          title: context.translate("_OVERTIME_HOURS_"),
          dataIndex: "overTimeHour",
          key: "overTimeHour",

          width: "15%",
        },
        {
          // title: "Overtime Charges",
          title: context.translate("_OVERTIME_CHARGE_"),
          dataIndex: "overtimeCharges",
          width: "15%",
          key: "overtimeCharges",
        },
        {
          // title: "Cost",
          title: context.translate("_COST_"),
          dataIndex: "cost",
          key: "cost",

          width: "15%",
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedDriver,
            interval: this.state.interval,
            fromDate: this.state.fromDate,
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
            toDate: this.state.toDate,
            colList: result.toString(),
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.overTimeReportStore.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.overTimeReportStore.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.overTimeReportStore.exportcsv(getData);
          }
          if (val.key == "xml") {
            this.overTimeReportStore.exportxml(getData);
          }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.overTimeReportStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false, currentPage: 1 });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedDriver: [], selectAllDrivers: false });
    if (this.state.companyId) {
      await this.driverLoginReportStore.getDriversByCompanyId({
        companyId: comp?.value,
      });
    }

    await this.setState({ loadData: 1 });
  }

  async handleSearch() {
    this.setState({ Notdriver: false, currentPage: 1, pageSize: 0 });
    await this.overTimeReportStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  // driverSelect = async (val) => {
  //   console.log(val);
  //   await this.setState({ selectedDriver: val });
  // };
  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedDriver.length === 0) {
      this.setState({ Notdriver: true });
    } else {
      this.setState({ Notdriver: false });
    }

    if (this.state.companyId && this.state.selectedDriver.length > 0) {
      // this.setState({mandatory:false})
      console.log((this.state.currentPage - 1) * this.state.pageSize);
      this.setState({ Notcompany: false, Notdriver: false });
      await this.overTimeReportStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,

        driverId: this.state.selectedDriver,
        fromDate: this.state.fromDate,
        toDate: this.state.toDate,
      });
    }
    this.setState({ update: 1 });
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss,";
      this.setState({
        fromDate: dates[0]?.format(format),
        toDate: dates[1]?.format(format),
      });
    }
  };

  handleSelectAllDrivers = async (e) => {
    if (this.state.selectAllDrivers) {
      const allDrivers = this.driverLoginReportStore?.getDrivers.map((val) => {
        return val.driverId;
      });
      await this.setState({ selectedDriver: allDrivers });
    } else {
      await this.setState({ selectedDriver: [] });
    }
  };

  // handleTodayButtonClick = () => {
  //   const now = dayjs();
  //   this.setState({
  //     fromDate: now.startOf("day"),
  //     selectedRange: [now.startOf("day"), now],
  //   });
  // };

  render() {
    const companyInputStyle = this.state.Notcompany
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };
    const driverInputStyle = this.state.Notdriver
      ? { border: "1px solid red", width: "150px", borderRadius: "8px" }
      : { width: "150px" };

    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Over Time Report</div> */}
              <div className="heading">
                {this.context.translate("_OVER_TIME_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
               

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.overTimeReportStore?.overTimeReport?.length > 0
                      ? false
                      : true
                  }
                >
                  <Button className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                <Checkbox
                  checked={this.state.selectAllDrivers}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllDrivers: e.target.checked,
                    });
                    if (this.state.selectAllDrivers) {
                      await this.setState({
                        Notdriver: false,
                      });
                    }
                    console.log(e.target.checked);
                    await this.handleSelectAllDrivers();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  onSearch={this.handleSearch}
                  mode="multiple"
                  value={this.state.selectedDriver}
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.setState({ selectedDriver: val, Notdriver: false });
                    if (
                      this.driverLoginReportStore?.getDrivers.length >
                      val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  optionFilterProp="children"
                  options={this.driverLoginReportStore?.getDrivers}
                  style={{ width: 150 }}
                  // style={driverInputStyle}
                  status={this.state.Notdriver ? "error" : false}
                /> */}
                <Select
                  // onSearch={this.handleSearch}
                  mode="multiple"
                  value={this.state.selectedDriver}
                  maxTagCount="responsive"
                  onChange={(val) => {
                    this.setState({
                      selectedDriver: val,
                      Notdriver: false,
                    });
                    if (
                      this.driverLoginReportStore?.getDrivers.length >
                      val.length
                    ) {
                      this.setState({ selectAllDrivers: false });
                    } else {
                      this.setState({ selectAllDrivers: true });
                    }
                  }}
                  // fieldNames={{ label: "name", value: "driverId" }}
                  // placeholder="Driver name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_INFORMATION_DRIVERNAME_"
                  )}
                  optionFilterProp="children"
                  // options={this.driverLoginReportStore?.getDrivers}
                  // options={this.state.filterby=='Driver' ? this.driverLoginReportStore?.getDrivers :this.driverScoreReportStore?.getVehicles }
                  style={{ width: 150 }}
                  // style={DriverInputStyle}
                  status={this.state.Notdriver ? "error" : false}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.driverLoginReportStore?.getDrivers?.map((val) => {
                    return <Option value={val.driverId}>{val.name}</Option>;
                  })}
                </Select>
                <RangePicker
                  showNow={false}
                  placeholder={["From Datetime", "To Datetime"]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                  // value={this.state.selectedRange}
                  // renderExtraFooter={() => (
                  //   <Button onClick={this.handleTodayButtonClick}>Today</Button>
                  // )}
                />
                <Button
                  type="primary"
                  onClick={() => {
                    this.showData();
                    this.setState({ currentPage: 1 });
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {console.log(this.newColumns, "Df")}
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.overTimeReportStore?.total}
            columns={this.state.column}
            dataSource={this.overTimeReportStore?.overTimeReport}
            size="middle"
            scroll={{
              x: "calc(700px + 50%)",
              y: 280,
            }}
            // pagination={{
            //   pageSize: this.state.pageSize,
            //   //  current:this.state.pageNo
            //   total: this.overTimeReportStore?.total || 0,
            // }}
            loading={this.overTimeReportStore?.loading}
            onChange={this.handleTableChange}
            // scroll={{
            //   // y: this.state.clientHeight-240,
            //   x: 100,
            // }}
          />
        </div>
      </>
    );
  }
}
OverTimeReport.contextType = StoreContext;
export default observer(withRouter(OverTimeReport));
