import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Checkbox, Col, DatePicker, Form, Input, Select } from "antd";
import VehicleStore from "../../../store/VehicleStore";

const VehicleInfoAwtlFormFields = (props) => {
  console.log("props", props.This);
  const shouldShow = shouldShowComponent(
    config.project,
    "VehicleInfoAwtlFormFields"
  );
  //   console.log("shouldShow**", shouldShow);
  const storeContext = useContext(StoreContext); // Use the context here
  const vehicleStore = new VehicleStore();
  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Col span={12} className="formColumnClass">
        {props.isadmin.isadmin !== 1 && (
          <>
            <Form.Item
              name="installer"
              // label=" Installer"
              label={storeContext.translate("_INSTALLER_")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="installedLocation"
              // label=" Installed Location"
              label={storeContext.translate("_INSATALLED_LOCATION_")}
              rules={[{ required: true }]}
            >
              <Select
                options={[
                  { label: "Riyadh", value: "Riyadh" },
                  { label: "Jeddah", value: "Jeddah" },
                  { label: "Gizan", value: "Gizan" },
                  { label: "Dammam", value: "Dammam" },
                  { label: "Abu Arish", value: "Abu Arish" },
                  { label: "Arar", value: "Arar" },
                  { label: "Taif", value: "Taif" },
                  { label: "Abha", value: "Abha" },
                  { label: "Al-Baha", value: "Al-Baha" },
                  { label: "Madinah", value: "Madinah" },
                  { label: "Tabuk", value: "Tabuk" },
                  { label: "Al Qassim", value: "Al Qassim" },
                  {
                    label: "Khamis Mushayt",
                    value: "Khamis Mushayt",
                  },
                  {
                    label: "Muhail-Aseer",
                    value: "Muhail-Aseer",
                  },
                  { label: "Yanbu", value: "Yanbu" },
                  { label: "Najran", value: "Najran" },
                  { label: "Hail", value: "Hail" },
                  { label: "Skaka", value: "Skaka" },
                  { label: "Turaif", value: "Turaif" },
                  {
                    label: "Hafar Al Batin",
                    value: "Hafar Al Batin",
                  },
                  { label: "Jubail", value: "Jubail" },
                  { label: "Hofuf", value: "Hofuf" },
                  { label: "Khafji", value: "Khafji" },
                  { label: "Umlujj", value: "Umlujj" },
                  { label: "Neom", value: "Neom" },
                  { label: "Makkah", value: "Makkah" },
                ]}
              />
            </Form.Item>

            <Form.Item
              name="InstallationType"
              label={storeContext.translate("_INSTALLATION_TYPE_")}
              rules={[{ required: true }]}
            >
              <Select
                disabled={props.This.disabledField}
                onChange={(value) => {
                  props.installationType(value);
                  //   console.log(value);
                }}
                options={[
                  {
                    // label: "Basic",
                    label: storeContext.translate("_BASIC_"),
                    value: "Basic",
                  },
                  {
                    // label: "PTA",
                    label: storeContext.translate("_PTA_"),
                    value: "PTA",
                  },
                  {
                    // label: "SFDA",
                    label: storeContext.translate("_SFDA_"),
                    value: "SFDA",
                  },
                ]}
              />
            </Form.Item>
          </>
        )}
        <Form.Item
          name="sequenceNo"
          // label="Sequence Number"
          label={storeContext.translate("_SEQUENCE_NUMBER_")}
          rules={[{ required: props.This.loadSensor }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="plateType"
          // label="Plate Type"
          label={storeContext.translate("_PLATE_TYPE_")}
          rules={[{ required: props.This.loadSensor }]}
        >
          <Select
            options={[
              {
                // label: "Private Car",
                label: storeContext.translate("__PRIVATE_CAR_"),
                value: 1,
              },
              {
                // label: "Public Transport",
                label: storeContext.translate("_PUBLIC_TRANSPORT_"),
                value: 2,
              },
              {
                // label: "Private Transport",
                label: storeContext.translate("_PRIVATE_TRANSPORT_"),
                value: 3,
              },
              {
                // label: "Public Mini Bus",
                label: storeContext.translate("_PUBLIC_MINIBUS_"),
                value: 4,
              },
              {
                // label: "Private Mini Bus",
                label: storeContext.translate("_PRIVATE_MINIBUS_"),
                value: 5,
              },
              {
                // label: "Taxi",
                label: storeContext.translate("_TAXI_"),
                value: 6,
              },
              {
                // label: "Heavy Equipment",
                label: storeContext.translate("_HEAVY_EQUIPMENT_"),
                value: 7,
              },
              {
                // label: "Export",
                label: storeContext.translate("_EXPORT_"),
                value: 8,
              },
              {
                // label: "Diplomatic",
                label: storeContext.translate("_DIPLOMATIC_"),
                value: 9,
              },
              {
                // label: "MotorCycle",
                label: storeContext.translate("_MOTORCYCLE_"),
                value: 10,
              },
              {
                // label: "Temporary",
                label: storeContext.translate("_TEMPORARY_"),
                value: 11,
              },
            ]}
          />
        </Form.Item>

        {props.This.sfda && (
          <>
            <Form.Item
              name="storingCategoryId"
              label={storeContext.translate("_STORING_CATEGORY_")}
              rules={[{ required: true }]}
            >
              <Select
                name="storingCategoryId"
                onSelect={(e) => {
                  props.getTypeByCategory(e);
                  //   console.log(e);
                }}
                // onSearch={this.handleSearch}
                showSearch
                fieldNames={{ value: "id", label: "category" }}
                optionFilterProp="children"
                options={vehicleStore?.getSCategories}
              />
            </Form.Item>

            <Form.Item
              name="storingTypeCode"
              label={storeContext.translate("_STORING_TYPE_")}
              rules={[{ required: true }]}
            >
              <Select
                name="storingTypeCode"
                showSearch
                fieldNames={{
                  value: "code",
                  label: "categorytype",
                }}
                optionFilterProp="children"
                options={vehicleStore?.getTypeofCategory}
              />
            </Form.Item>
          </>
        )}

        <div className="formCheckboxCss">
          {props.isadmin.isadmin !== 1 && (
            <>
              <Form.Item
                name="addOn"
                // label="Add On"
                label={storeContext.translate("_ADDON_")}
                style={{ marginTop: "14px" }}
              >
                <Checkbox
                  // value={this.state.DriverId}
                  checked={props.This.DriverId}
                  onChange={async (e) =>
                    props.handleCheckboxChange(e.target.checked, "DriverId")
                  }
                  // onChange={(e) => {
                  //   props.This.setState({
                  //     DriverId: e.target.checked,
                  //   });
                  //   console.log(e.target.checked);
                  // }}
                >
                  {storeContext.translate("_FLEET_REPORTS_DRIVER_ID_")}
                </Checkbox>

                <Checkbox
                  value={5}
                  checked={props.This.rfid}
                  onChange={async (e) =>
                    props.handleCheckboxChange(e.target.checked, "rfid")
                  }
                  //   onChange={(e) => {
                  //     props.This.setState({ rfid: e.target.checked });
                  //     console.log(e.target.checked);
                  //   }}
                >
                  {storeContext.translate("_FLEET_DASHBORD_SETTING_RFID_")}
                </Checkbox>
              </Form.Item>
            </>
          )}
        </div>
        <Form.Item
          name="hourMeterReading"
          // label="Hour Meter Reading"
          label={storeContext.translate("_HOUR_METER_READING_")}
        >
          <Input
            onChange={(e) =>
              props.validateFieldsValue(e.target.value, "hourMeterReading")
            }
          />
        </Form.Item>

        <Form.Item
          name="regularRunningHours"
          // label="Regular Running Hours"
          label={storeContext.translate("_REGULAR_RUNNING_HOUR_")}
        >
          <Input
            onChange={(e) =>
              props.validateFieldsValue(e.target.value, "regularRunningHours")
            }
          />
        </Form.Item>

        <Form.Item
          name="maxHoursPerDay"
          // label="Max Hour Per Day"
          label={storeContext.translate("_MAX_HOUR_PER_DAY_")}
        >
          <Input
            onChange={(e) =>
              props.validateFieldsValue(e.target.value, "maxHoursPerDay")
            }
          />
        </Form.Item>

        <Form.Item
          name="rpm"
          // label="Max Hour Per Day"
          label={storeContext.translate("_STOPPED_RPM_")}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="movingRPM"
          // label="Max Hour Per Day"
          label={storeContext.translate("_MOVING_RPM_")}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="idelRPM"
          // label="Max Hour Per Day"
          label={storeContext.translate("_IDLE_RPM_")}
        >
          <Input />
        </Form.Item>
      </Col>
      <Col span={12} className="formColumnClass">
        <Checkbox
          style={{ marginTop: "14px" }}
          // value={this.state.fuelSensor}
          checked={props.This.fuelSensor}
          onChange={async (e) =>
            props.handleCheckboxChange(e.target.checked, "fuelSensor")
          }
        >
          <span style={{ fontWeight: "bold" }}>
            {storeContext.translate("_FUEL_SENSOR_")}
          </span>
        </Checkbox>

        <Form.Item
          name="fuelId1"
          // label="Fuel Id1"
          label={storeContext.translate("_FUEL_ID_1_")}
          className={!props.This.fuelSensor && "disabledLabel"}
        >
          <Input disabled={props.This.fuelSensor ? false : true} />
        </Form.Item>

        <Form.Item
          name="fuelId2"
          // label="Fuel Id2"
          label={storeContext.translate("_FUEL_ID_2_")}
          className={!props.This.fuelSensor && "disabledLabel"}
        >
          <Input disabled={props.This.fuelSensor ? false : true} />
        </Form.Item>
        <Checkbox
          style={{ marginTop: "14px" }}
          // value={3}
          checked={
            props.This.sfda || props.This.pta
              ? props.This.sfda || props.This.pta
              : props.This.loadSensor
          }
          onChange={async (e) => {
            props.This.pta && props.handleLoadSensorCheckbox({ pta: false });
            props.This.sfda
              ? props.handleLoadSensorCheckbox({ loadSensor: true })
              : props.handleLoadSensorCheckbox({
                  loadSensor: e.target.checked,
                });

            props.This.basic &&
              (await props.handleLoadSensorCheckbox({ loadSensor: false }));

            (await props.This.sfda) && props.warning();
            props.This.basic && e.target.checked && props.warning();
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            {storeContext.translate("_LOAD_SENSOR_")}
          </span>
        </Checkbox>
        <>
          <Form.Item
            name="weightFactor"
            // label="Weight/Volt"
            label={storeContext.translate("_WEIGHT_VOLT_")}
            className={!props.This.loadSensor && "disabledLabel"}
            rules={[{ required: props.This.loadSensor }]}
          >
            <Input
              disabled={props.This.loadSensor ? false : true}
              value={props.This.weightFactor}
              onChange={async (e) => {
                await props.validateFieldsValue(e.target.value, "weightFactor");
                await props.current.formRef.setFieldsValue({
                  emptyWeight: e.target.value,
                });
              }}
            />
          </Form.Item>

          <Form.Item
            name="tId"
            // label="Attach Trailer"
            label={storeContext.translate("_ATTACH_TAILER_")}
            className={!props.This.loadSensor && "disabledLabel"}
          >
            <Select
              onSelect={(e) => {
                props.getWeightsUsingTid(e);
                console.log(e);
              }}
              disabled={props.This.loadSensor ? false : true}
              name="tId"
              // style={{ width: 285 }}
              showSearch
              fieldNames={{ value: "tId", label: "trailerId" }}
              optionFilterProp="children"
              options={vehicleStore?.getTrailerUsingCompanyId}
            />
          </Form.Item>

          <Form.Item
            name="headWeight"
            // label="Head Weight"
            label={storeContext.translate("_HEAD_WEIGHT_")}
            className={!props.This.loadSensor && "disabledLabel"}
            rules={[{ required: props.This.loadSensor }]}
          >
            <Input
              disabled={props.This.loadSensor ? false : true}
              onChange={async (e) => {
                await props.validateFieldsValue(e.target.value, "headWeight");
                //  await this.getWeightsUsingTid()
              }}
            />
          </Form.Item>

          <Form.Item
            name="emptyWeight"
            // label="Empty Truck Weight (kg)"
            label={storeContext.translate("_EMPTY_TRUCK_WEIGHT_KG_")}
            className={!props.This.loadSensor && "disabledLabel"}
          >
            <Input
              disabled={props.This.loadSensor ? false : true}
              onChange={(e) =>
                props.validateFieldsValue(e.target.value, "emptyWeight")
              }
            />
          </Form.Item>

          <Form.Item
            name="minVolt"
            // label="Minimum Volt"
            label={storeContext.translate("_MINIMUM_VOLT_")}
            className={!props.This.loadSensor && "disabledLabel"}
          >
            <Input
              disabled={props.This.loadSensor ? false : true}
              onChange={(e) =>
                props.validateFieldsValue(e.target.value, "minVolt")
              }
            />
          </Form.Item>

          <Form.Item
            name="maxVolt"
            // label="Maximum Volt"
            label={storeContext.translate("_MAXIMUM_VOLT_")}
            className={!props.This.loadSensor && "disabledLabel"}
          >
            <Input
              disabled={props.This.loadSensor ? false : true}
              onChange={(e) =>
                props.validateFieldsValue(e.target.value, "maxVolt")
              }
            />
          </Form.Item>
        </>

        <Checkbox
          style={{ marginTop: "14px" }}
          // value={4}
          checked={props.This.temperatureSensor}
          onChange={async (e) =>
            props.handleCheckboxChange(e.target.checked, "temperatureSensor")
          }
        >
          <span style={{ fontWeight: "bold" }}>
            {storeContext.translate("_TEMPERATURE_SENSOR_")}
          </span>
        </Checkbox>

        <>
          <Form.Item
            name="tempId1"
            // label="Temp Id1"
            label={storeContext.translate("_TEMP_ID1_")}
            className={!props.This.temperatureSensor && "disabledLabel"}
          >
            <Select disabled={props.This.temperatureSensor ? false : true} />
          </Form.Item>
          <Form.Item
            name="tempId2"
            // label="Temp Id2"
            label={storeContext.translate("_TEMP_ID2_")}
            className={!props.This.temperatureSensor && "disabledLabel"}
          >
            <Select disabled={props.This.temperatureSensor ? false : true} />
          </Form.Item>
          <Form.Item
            name="tempId3"
            // label="Temp Id3"
            label={storeContext.translate("_TEMP_ID3_")}
            className={!props.This.temperatureSensor && "disabledLabel"}
          >
            <Select disabled={props.This.temperatureSensor ? false : true} />
          </Form.Item>
        </>
      </Col>
    </>
  );
};

VehicleInfoAwtlFormFields.contextType = StoreContext;
export default observer(withRouter(VehicleInfoAwtlFormFields));
