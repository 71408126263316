import React from "react";
import {
  Select,
  Checkbox,
  Row,
  Col,
  Button,
  Space,
  DatePicker,
  Dropdown,
  Modal,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import VehicleStore from "../store/VehicleStore";
import dayjs from "dayjs";
import { decode as base64_decode } from "base-64";
import Cookies from "js-cookie";
import FleetDaySummaryReportStore from "../store/FleetDaySummaryReportStore";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";

const { Option } = Select;
let widthAdderFunc = (arr) => {
  let array = arr;
  array.map((val) => {
    if (val.title?.length > 24) {
      val.width = 250;
    } else if (val.title?.length > 15) {
      val.width = 200;
    } else if (val.title?.length > 12) {
      val.width = 150;
    } else if (val.title?.length > 8) {
      val.width = 125;
    } else {
      val.width = 110;
    }
    // console.log(val.title.length)
  });

  return arr;
};
class FleetDaySummaryReport extends React.Component {
  constructor(props, context) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fleetDaySummaryReport = new FleetDaySummaryReportStore(this);
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      selectedVehicles: [],

      selectAllVehicles: false,
      companyId: "",
      modalVisible: false,
      column: "",
      filter: "",
      showMonthPopup: false,
      Notcompany: false,
      Notasset: false,
      fromDate: dayjs().format("YYYY-MM-DD"),
      toDate: dayjs().format("YYYY-MM-DD"),
      selectedColumns: widthAdderFunc([
        {
          // title: "Asset Name",
          title: context.translate("_VEHICLE_NO_"),
          dataIndex: "vehicleNo",
          key: "vehicleNo",

          // fixed: 'left',
        },
        {
          // title: "Asset Type",
          title: context.translate("_FLEET_START_TIME_"),
          dataIndex: "startTime",
          key: "startTime",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Driver Name",
          title: context.translate("_START_LOCATION_"),
          dataIndex: "startLocation",
          key: "startLocation",
        },
        {
          // title: "Date",
          title: context.translate("_FLEET_STOP_TIME_"),
          dataIndex: "stopTime",
          key: "stopTime",
          render: (value) => {
            if (value) {
              const formattedDateTime = dayjs(value).format(
                "DD-MM-YYYY hh:mm:ss A"
              );
              return <>{formattedDateTime}</>;
            } else {
              return <></>;
            }
          },
        },
        {
          // title: "Start Odometer",
          title: context.translate("_STOP_LOCATION_"),
          dataIndex: "stopLocation",
          key: "stopLocation",
        },
        {
          // title: "Stop Odometer",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STARTOD_"),
          dataIndex: "startDistance",
          key: "startDistance",
        },
        {
          // title: "Stop Odometer",
          title: context.translate("_FLEET_REPORTS_DISTANCEREPORT_STOPOD_"),
          dataIndex: "stopDistance",
          key: "stopDistance",
        },
        {
          // title: "Entered Odometer",
          title: context.translate("_DISTANCE_TRAVELED_"),
          dataIndex: "distanceTravel",
          key: "distanceTravel",
        },

        {
          // title: "Amount",
          title: context.translate("_DRIVER_NAME_"),
          dataIndex: "driverName",
          key: "driverName",
        },
        {
          // title: "Fuel",
          title: context.translate("_DRIVER_MOBILE_NO_"),
          dataIndex: "mobileno",
          key: "mobileno",
        },
        {
          // title: "Fuel Filled",
          title: context.translate("_MAX_SPEED_1"),
          dataIndex: "maxSpeed",
          key: "maxSpeed",
        },

        {
          // title: "Total Run Km",
          title: context.translate("_TRAVEL_TIME_"),
          dataIndex: "travelduration",
          key: "travelduration",
        },

        {
          // title: " Fuel Consumed ",
          title: context.translate("_IDLE_TIME_"),
          dataIndex: "idleduration",
          key: "idleduration",

          // fixed:'right'
        },
        {
          // title: " Fuel Consumed ",
          title: context.translate("_EXIDLE_TIME_"),
          dataIndex: "exidleduration",
          key: "exidleduration",

          // fixed:'right'
        },
        {
          // title: " Fuel Consumed ",
          title: context.translate("_TOTAL_STOP_"),
          dataIndex: "stopduration",
          key: "stopduration",

          // fixed:'right'
        },
        {
          // title: " Fuel Consumed ",
          title: context.translate("_IDLE_COUNT_"),
          dataIndex: "idleCount",
          key: "idleCount",

          // fixed:'right'
        },
        {
          // title: " Fuel Consumed ",
          title: context.translate("_OVER_SPEED_"),
          dataIndex: "overspeedCount",
          key: "overspeedCount",

          // fixed:'right'
        },
      ]),
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        // {
        //   // label: "XML",
        //   label: context.translate("_XML_"),
        //   key: "xml",
        //   icon: <PlusOutlined />,
        // },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        console.log(val);
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));

          //  console.log(result.toString())

          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,

            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            colList: result.toString(),
            userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
          };
          if (val.key == "excel") {
            // console.log("afd")
            this.fleetDaySummaryReport.exportExcel(getData);
          }
          if (val.key == "pdf") {
            this.fleetDaySummaryReport.exportpdf(getData);
          }
          if (val.key == "csv") {
            this.fleetDaySummaryReport.exportcsv(getData);
          }
          // if (val.key == "xml") {
          //   this.fleetDaySummaryReport.exportxml(getData);
          // }
        }
      },
    };
    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    // await this.fuelFilledReportStore.loadData({ offset: 0, limit: 20 });

    this.setState({ pageSize: 50 });

    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;

      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    console.log(this.state.selectedVehicles);

    await this.setState({ selectedVehicles: [], selectAllVehicles: false });

    await this.setState({ companyId: comp?.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);

    await this.handleSearch();
    //  console.log(data,"sdcd")
  }

  async handleSearch() {
    this.setState({ Notasset: false });
    // await this.fuelFilledReportStore.loadData({
    //   offset: (this.state.currentPage - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   pageNo: this.state.currentPage,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    // this.handleSearch();
    await this.showData();
  }

  showData = async () => {
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (this.state.selectedVehicles.length === 0) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }

    if (this.state.companyId && this.state.selectedVehicles.length > 0) {
      // this.setState({mandatory:false})
      this.setState({ Notcompany: false, Notasset: false });
      if (this.checkFromDate(this.state.fromDate)) {
        await this.fleetDaySummaryReport.loadData({
          offset: (this.state.currentPage - 1) * this.state.pageSize,
          limit: this.state.pageSize,
          companyId: this.state.companyId,
          vehicleId: this.state.selectedVehicles,

          fromDate: this.state.fromDate,
          toDate: this.state.toDate,
          pageNo: this.state.currentPage,
        });
        await this.setState({ listData: 1 });
      }
    }
  };

  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };
  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };

  checkFromDate(dates) {
    console.log(dates);
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  handleAllSelectVehicles = async (e) => {
    if (this.state.selectAllVehicles) {
      const allVehicles = this.vehicleStore?.getVehiclesUsingCompanyId.map(
        (val) => {
          return val.vehicleId;
        }
      );
      await this.setState({ selectedVehicles: allVehicles });
    } else {
      await this.setState({ selectedVehicles: [] });
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Fuel Filled Report</div> */}
              <div className="heading">
                {this.context.translate(
                  "_FLEET_DAY_SUMMRY_REPORTS_FLEETREPORT_TITLE_"
                )}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/* <Button>Total</Button> */}

               

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.fleetDaySummaryReport?.fleetDaySummaryReport?.length >
                    0
                      ? false
                      : true
                  }
                >
                  <Button 
                  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={20}>
              <Space>
                <CompanyAutocomplete
                  allowClear={true}
                  onChange={this.handleChange}
                  style={{ width: 150 }}
                  // style={companyInputStyle}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: [],
                        selectAllVehicles: false,
                      })
                    }
                  />
                )}
                <Checkbox
                  checked={this.state.selectAllVehicles}
                  onChange={async (e) => {
                    await this.setState({
                      selectAllVehicles: e.target.checked,
                    });
                    // console.log(e.target.checked);
                    await this.handleAllSelectVehicles();
                  }}
                />
                {/* All */}
                {this.context.translate("_ALL_")}
                {/* <Select
                  mode="multiple"
                  onSearch={this.handleSearch}
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    this.setState({ Notasset: false });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }
                  }}
                  fieldNames={{ label: "vehicleNo", value: "vehicleId" }}
                  // placeholder="Asset Name"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  maxTagCount="responsive"
                  optionFilterProp="children"
                  options={this.vehicleStore?.getVehiclesUsingCompanyId}
                  style={{ width: 150 }}
                  // style={assetInputStyle}
                  status={this.state.Notasset ? "error" : false}
                /> */}
                <Select
                  maxTagCount="responsive"
                  value={this.state.selectedVehicles}
                  onChange={(val) => {
                    this.setState({ selectedVehicles: val });
                    if (
                      this.vehicleStore?.getVehiclesUsingCompanyId?.length >
                      val.length
                    ) {
                      this.setState({ selectAllVehicles: false });
                    } else {
                      this.setState({ selectAllVehicles: true });
                    }

                    if (val?.length > 1) {
                      console.log(val?.length > 1);
                      this.setState({ showAlertPopup: true });
                    }
                  }}
                  style={{ width: 200 }}
                  showSearch
                  status={this.state.Notasset ? "error" : false}
                  mode="multiple"
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <DatePicker
                  defaultValue={dayjs()}
                  placeholder="From Date"
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  onChange={(date) => {
                    console.log(date);
                    if (date === null) {
                      this.setState({ fromDate: "" });
                    } else {
                      this.setState({ fromDate: date.format("YYYY-MM-DD") });
                    }
                  }}
                />

                <DatePicker
                  defaultValue={dayjs()}
                  placeholder="To Date"
                  onChange={(date) => {
                    console.log(date);
                    if (date === null) {
                      this.setState({ toDate: "" });
                    } else {
                      this.setState({ toDate: date.format("YYYY-MM-DD") });
                    }
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                />
                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ currentPage: 1, pageSize: 50 });
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={4}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.fleetDaySummaryReport?.total}
            columns={this.state.column}
            dataSource={this.fleetDaySummaryReport?.fleetDaySummaryReport}
            loading={this.fleetDaySummaryReport?.loading}
            onChange={this.handleTableChange}
          />
        </div>
        <Modal
          open={this.state.modalVisible}
          width={300}
          // title="Select Columns"
          title={this.context.translate("_SELECT_COLUMNS_")}
          onCancel={() => this.setState({ modalVisible: false })}
          onOk={() => {
            this.setState({ modalVisible: false });
          }}
          footer={null}
        >
          <div style={{ maxHeight: "400px", overflowY: "auto" }}>
            {console.log(this.newColumns, "Df")}
            {this.newColumns.map((column, v) => {
              return (
                <Row key={v}>
                  <Col>
                    <Checkbox
                      onChange={() => this.handleCheckboxChange(column)}
                      checked={this.state.selectedColumns.some(
                        (c) => c.key === column.key
                      )}
                    >
                      {column.title}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        </Modal>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {/* Alert */}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>
      </>
    );
  }
}
FleetDaySummaryReport.contextType = StoreContext;
export default observer(withRouter(FleetDaySummaryReport));
