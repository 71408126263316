import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
import { shouldShowComponent } from "../../../utils/componentVisibility";
import { Form, Input } from "antd";


const AddGeofenceFields = (props) => {
  const shouldShow = shouldShowComponent(config.project, "AddGeofenceFields");

  // console.log(shouldShow,config.project)
  const storeContext = useContext(StoreContext);

  if (!shouldShow) {
    return null;
  }

  return (
    <>
      <Form.Item
        label={storeContext.translate("_SPEED_DURATION_")}
        name="speedDuration"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={storeContext.translate("_VENDOR_ID_")}
        name="vendorId"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={storeContext.translate("_SPECIAL_")}
        name="special"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={storeContext.translate("_PRICE_")}
        name="price"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={storeContext.translate("_NEIGHT_PRICE_")}
        name="neightPrice"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={storeContext.translate("_BIG_PRICE_")}
        name="bigPrice"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={storeContext.translate("_BIG_NEIGHT_PRICE_")}
        name="bigNeightPrice"
      >
        <Input />
      </Form.Item>
    </>
  );
};

AddGeofenceFields.contextType = StoreContext;
export default observer(withRouter(AddGeofenceFields));

// export default observer(withRouter(MaintenanceTableFields));
