import React from "react";
import { Space, Row, Col, Select, Tooltip, Button, Modal, message } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import { DeleteFilled, PlusOutlined } from "@ant-design/icons";
import withRouter from "../withRouter";
import ListTable from "./ListTable";
import config from "../config";

import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
import { Link } from "react-router-dom";
import DeleteConfirm from "./DeleteConfirm";
import RtaOfficialsStore from "../store/RtaOfficialsStore";

import RtaOfficialsAdd from "./RtaOfficialsAdd";

const { Option } = Select;
class RtaOfficials extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.companyStore = new CompanyStore(this);
    this.rtaOfficialsStore = new RtaOfficialsStore(this);

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      company_value_label: "",
      listUpdated: 0,
      vehicleId: "",
      openRtaForm: false,
      userId: "",
      listDataUpdate: false,
      companyId: "",
    };
  }

  async componentDidMount() {
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.setState({ userId: this.props.getUserData().userId });
      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });

    this.columns = [
      {
        title: this.context.translate("_NAME_"),
        dataIndex: "name",
        width: "10%",
        render: (val, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {val}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
        dataIndex: "address",
        width: "15%",
      },
      {
        title: this.context.translate("_MOBILE_NUMBER_"),
        dataIndex: "mobileno",
        width: "15%",
      },
      {
        title: this.context.translate("_EMAIL_ID_"),
        dataIndex: "emailId",
        width: "10%",
      },
      {
        title: this.context.translate("_USER_TYPE_"),
        dataIndex: "userType",
        width: "10%",
        render: (value) => {
          if (value == "1") {
            return "Admin";
          }
          if (value == "2") {
            return "Driver";
          }
          if (value == "3") {
            return "Distributor";
          }
          if (value == "5") {
            return "Sales Person";
          }
          if (value == "6") {
            return "Transport Coordinator";
          } else {
            return "User";
          }
        },
      },
      {
        title: this.context.translate("_DEPARTMENT_"),
        dataIndex: "departmentName",
        width: "10%",
      },
      {
        title: this.context.translate("_FLEET_COMPANY_USERDETAIL_USERNAME_"),
        dataIndex: "username",
        width: "10%",
      },
      {
        title: this.context.translate("_STATUS_"),
        dataIndex: "status",
        width: "10%",
        render: (value) => {
          if (value == 1) {
            return (
              // <Tag
              //   style={{ width: 55, textAlign: "center" }}
              //   color={config.activeColor}
              // >
              "Active"
              // </Tag>
            );
          } else {
            return (
              // <Tag
              //   style={{
              //     color: "white",
              //     backgroundColor: config.inActiveColor,
              //     width: 55,
              //     textAlign: "center",
              //   }}
              // >
              "Inactive"
              // </Tag>
            );
          }
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        key: "Action",
        // fixed : 'right',
        width: "5%",
        render: (_, record) => {
          return (
            <>
              <Space>
                <DeleteConfirm
                  onConfirm={(e) => {
                    e.preventDefault();
                    this.onDelete(record);
                  }}
                >
                  <Link>
                    <Tooltip
                      // title="Delete"
                      title={this.context.translate(
                        "_FLEET_COMPANY_COMPANYLIST_DELETE_"
                      )}
                      placement="bottom"
                      color={config.tooltipColorDelete}
                    >
                      <DeleteFilled style={{ color: config.deleteColor }} />
                    </Tooltip>
                  </Link>
                </DeleteConfirm>
              </Space>
            </>
          );
        },
      },
    ];
  }

  onEdit = async (record) => {
    const userId = record.userId;
    if (userId) {
      await this.setState({ userId: userId });
      await this.setState({ openRtaForm: true });
    }
  };

  async onDelete(record) {
    const response = await this.rtaOfficialsStore.deleteRecord({
      userId: record.userId,
    });
    console.log(response.data.success);
    if (response?.data?.success === "S") {
      message.success(response.data.message);
      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    this.handleSearch();
  }

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ currentPage: 1, companyId: comp.value });
    console.log(comp.value);
    const singleData = await this.companyStore.getCompanyDataById(comp.value);
    console.log(singleData);
    await this.setState({
      company_value_label: singleData,
      companyId: comp.value,
    });
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    await this.rtaOfficialsStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
    });
    await this.setState({ loadData: 1 }); //need to reload data
  }

  closeModal = () => {
    this.setState({ openRtaForm: false, userId: "" });
  };

  responseMsg = async (resp) => {
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openRtaForm: false });
    } else {
      message.error(resp.data.message);
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    // if (
    //   this.state.openRtaForm &&
    //   prevState.openRtaForm !== this.state.openRtaForm
    // ) {
    //   console.log("ilocasd");
    //   setTimeout(() => {
    //     window.dispatchEvent(new Event("resize"));
    //   }, 10);
    // }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.rtaOfficialsObject.companyId;
      // console.log(beforeEditCompanyId);
      await this.rtaOfficialsStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: this.state.companyId,
      });

      const singleData = await this.companyStore.getCompanyDataById(
        this.context.viewStateStore.rtaOfficialsObject.companyId
      );
      // this.setState({companyId:this.state.})
      await this.setState({ listUpdated: 1 }); //need to reload data
      await this.setState({
        company_value_label: singleData,
        companyId: beforeEditCompanyId,
      });
      await this.setState({ listDataUpdate: false });
    }
  }
  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_RTA_OFFICIALS_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Tooltip
                placement="left"
                // title="Add"
                title={this.context.translate(
                  "_FLEET_DRIVER_DRIVERLIST_ADDBTN_"
                )}
                color={config.primaryColor}
              >
                <Button
                  className="addButton"
                  icon={<PlusOutlined />}
                  type="primary"
                  onClick={() => {
                    this.setState({ openRtaForm: true, userId: "" });
                  }}
                ></Button>
              </Tooltip>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={(comp) => this.handleChange(comp)}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
              </Space>
            </Col>
          </Row>

          <ListTable
            current={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.rtaOfficialsStore?.total}
            columns={this.columns}
            dataSource={this.rtaOfficialsStore?.rtaOfficialsData}
            loading={this.rtaOfficialsStore?.loading}
            onChange={this.handleTableChange}
          />
        </div>

        {this.state.openRtaForm && (
          <Modal
            bodyStyle={{ height: "645px" }}
            className="formShowModal"
            // title="User Details"
            title={this.context.translate("_RTA_OFFICIALS_")}
            centered
            open={this.state.openRtaForm}
            footer={null}
            onCancel={() => {
              this.setState({ openRtaForm: false, userId: "" });
            }}
            width={665}
          >
            <div>
              <RtaOfficialsAdd
                companyId={this.state.companyId}
                userId={this.state.userId}
                response={this.responseMsg}
                closeModal={this.closeModal}
              />
            </div>
          </Modal>
        )}
      </>
    );
  }
}
RtaOfficials.contextType = StoreContext;
export default observer(withRouter(RtaOfficials));
