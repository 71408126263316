import { Form, Input, Checkbox } from "antd";
import React, { useContext } from "react";
import { StoreContext } from "../../../store/RootStore";
import { observer } from "mobx-react";
import withRouter from "../../../withRouter";
import config from "../../../config";
//import baseConfig from "../../../baseconfig.json";
import { shouldShowComponent } from "../../../utils/componentVisibility";

const AccountFormPaymentFields = (props) => {
  console.log("props", props);
  const shouldShow = shouldShowComponent(
    config.project,
    "AccountFormPaymentFields"
  );

  const storeContext = useContext(StoreContext); // Use the context here

  if (!shouldShow) {
    return null; // Do not render if the condition is not met
  }

  return (
    <>
      <Form.Item name="remark" label={storeContext.translate("_REMARK_")}>
        <Input />
      </Form.Item>

      <Form.Item
        name="certificateCheck"
        label=""
        valuePropName="checked"
        style={{ marginTop: "20px" }}
      >
        <Checkbox>{storeContext.translate("_CERTIFICATE_")}</Checkbox>
      </Form.Item>
    </>
  );
};

AccountFormPaymentFields.contextType = StoreContext;
export default observer(withRouter(AccountFormPaymentFields));
