import { encode as base64_encode } from "base-64";

import { postApi } from "../components/Utility";
import { action, makeObservable, observable } from "mobx";

class SpecialOfferStore {
  loading = false;
  total = 0;
  offerList = [];

  rootStore;

  constructor(rootStore) {
    makeObservable(this, {
      loading: observable,
      total: observable,
      offerList: observable,

      loadData: action,
      deleteRecord: action,
      getofferData: action,
      addData: action,
    });
    this.rootStore = rootStore;
  }

  async loadData({ companyId, offset, limit, page }) {
    this.loading = true;
    var postData = {
      companyId: 1,
      offset: offset,
      limit: limit,
      page: page,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=getOfferDetails",
        "postData=" + base64Input
      );

    this.offerList = response?.data?.data?.offerData;
    this.total = parseInt(response?.data?.data?.TotalCount);

    this.loading = false;
  }

  async addData(discountDetails) {
    var postData = discountDetails;
    // const base64Input = await base64_encode(JSON.stringify(postData));
    // const encodedInput = encodeURIComponent(base64Input);
    const base64Input = btoa(unescape(encodeURIComponent(JSON.stringify(postData))));
    const encodedInput = encodeURIComponent(base64Input);
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=updateContent",
        "postData=" + encodedInput
      );

    return response.data;
  }

  async getofferData(contentId) {
    console.log("getdriverdata", contentId);
    var postData = { contentId: contentId, limit: 1, offset: 0 };
    // console.log(postData)

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=getOfferDetails",
        "postData=" + base64Input
      );
    console.log(response);

    return response?.data?.data?.offerData[0];
  }

  async deleteRecord({ id }) {
    const postData = {
      id: id,
    };

    const base64Input = await base64_encode(JSON.stringify(postData));
    var response = await postApi
      .getClient()
      .post(
        "index.php?c=traxi_content&a=deleteEvent",
        "postData=" + base64Input
      );

    return response;
  }
}

export default SpecialOfferStore;
