import React, { Component } from "react";

import { Form, Select, Button, Input, Row, Col, DatePicker, Modal } from "antd";

import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import VehicleOdometerStore from "../store/VehicleOdometerStore";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";

import VehicleWorkingHoursStore from "../store/VehicleWorkingHoursStore";
import withRouter from "../withRouter";
import dayjs from "dayjs";

// const currentDateTime = dayjs();
class VehicleOdometerAdd extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      companyId: "",
      loadList: 0,
      vehiclesAssetArray: [],
      vehicleId: "",
      Id: "",
      selectedDate: dayjs(),
      currentOdometer: "",
      changeOdometer: "",
      diffOdometer: "",
      vehicleIdSelected: false,
      odometerWarning: false,
      vehicleCondition: false,
      // vehicleId:false
      // console.log(parseInt(this.state.changeOdometer)-parseInt(this.state.currentOdometer))
      //  diffOdo: "changeOdometer"
    };
    this.vehicleOdometerStore = new VehicleOdometerStore();
    this.vehicleStore = new VehicleStore();
    this.workingHoursStore = new VehicleWorkingHoursStore();
    this.setCurrentOdometer = this.setCurrentOdometer.bind(this);
  }

  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1 && Number(this.props.getUserData().isadmin) !== 3) {
      // this.context.viewStateStore.setUserAddStates({ companyId: this.props.getUserData().userCompanyId});
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });

      await this.setState({ isadmin: this.props.getUserData().isadmin });
      const data = await this.workingHoursStore.getAssignVehicles({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ update: 1 });
    }
  }

  onOdometerDetailSubmit = async (values) => {
    this.setState({ loading: true });
    const data = {
      ...values,
      datetime: values?.datetime?.format("YYYY-MM-DD HH:mm:00"),
      companyId: this.state.companyId,
    };

    const resp = await this.vehicleOdometerStore.addData(data);
    this.context.viewStateStore.setOdometerStates({
      companyId: this.state.companyId,
    });
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehicleodometerlist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }

    this.setState({ loading: false });
  };

  async handleChange(comp) {
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    const data = await this.workingHoursStore.getAssignVehicles({
      companyId: comp.value,
    });

    // const vehicleAsset = await this.vehicleStore.getVehicles(comp.value)
    // await this.setState({ vehiclesAssetArray: vehicleAsset })
    // console.log(comp.value)

    await this.handleSearch();
  }

  async handleSearch() {
    await this.vehicleOdometerStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  setFieldValue = () => {
    this.formRef.current.setFieldsValue({
      diffOdo:
        parseFloat(this.state.changeOdometer) -
        parseFloat(this.state.currentOdometer),
      totalOdo: parseFloat(this.state.changeOdometer),
    });
  };

  async setCurrentOdometer(vehicleId) {
    const data = await this.vehicleStore.getCurrentGps(vehicleId);

    // await this.setState({ vehicleCondition: data.acc });
    await this.setState({ currentOdometer: data.distance });
    await this.formRef.current.setFieldsValue({ gpsOdo: data.distance });

    if (data.acc === 1) {
      await this.setState({ odometerWarning: true });
    } else if (data.acc === 0) {
      await this.setState({ odometerWarning: false });
    }
    this.setState({ loadData: 1 });
  }

  async setOdometerByTime({ vehicleId, datetime }) {
    const data = await this.vehicleStore.getOdometerByTime({
      vehicleId,
      datetime,
    });

    await this.formRef.current.setFieldsValue({ travelledOdo: data });
  }

  validateFieldsValue = (value, field) => {
    // Check if value contains only numbers
    // const isValid = /^[0-9]+$/.test(value);
    const value1 = value.replace(/[^0-9.]*/g, "");

    if (value1) {
      this.formRef.current.setFieldsValue({ [field]: value1 });
    } else {
      this.formRef.current.setFieldsValue({ [field]: "" });
    }
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div style={{ paddingBottom: "10px" }}>
          <Form
            style={{ maxWidth: "100%", padding: 0 }}
            layout="vertical"
            ref={this.formRef}
            onFinish={this.onOdometerDetailSubmit}
            // {...this.layout}
            initialValues={{ datetime: dayjs() }}
            name="nest-message"
          >
            {/* <h1>Adjust Odometer</h1> */}

            {/* <div className="headingTitle">Adjust Odometer</div> */}
            <Row className="overflowFormInputs">
              {/* css class */}
              <Col span={12} className="formColumnClass">
                {(this.props.getUserData().isadmin === -1 ||
                  this.props.getUserData().isadmin === 3) && (
                  <Form.Item
                    name="companyId"
                    label={this.context.translate("_ACCOUNT_")}
                    rules={[{ required: true }]}
                  >
                    <CompanyAutocomplete
                      onChange={(value) => {
                        this.handleChange(value);
                        this.formRef.current.setFieldsValue({
                          vehicleId: undefined,
                          gpsOdo: undefined,
                        });
                        this.setState({ odometerWarning: false });
                      }}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                )}

                <Form.Item
                  name="vehicleId"
                  label={this.context.translate("_ASSIGN_ASSET_")}
                  rules={[{ required: true }]}
                >
                  <Select
                    value={this.state.vehicleId}
                    // placeholder="Select Asset"
                    placeholder={this.context.translate(
                      "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                    )}
                    onSelect={(e, val) => {
                      this.setCurrentOdometer(e);
                      // this.validateFields(e.target.value);

                      this.setState({ vehicleIdSelected: true });

                      // if (this.state.vehicleCondition === false) {
                      //   this.setState({ odometerWarning: true });
                      // } else {
                      //   this.setState({ odometerWarning: false });
                      // }
                    }}
                    onChange={() => {
                      // Reset the value of the second field when the first field changes
                      this.formRef.current.setFieldsValue({
                        odometer: undefined,
                        totalOdo: undefined,
                        diffOdo: undefined,
                      });
                      this.setState({ vehicleIdSelected: false });
                      this.setState({ odometerWarning: false });
                    }}
                    style={{ width: "100%" }}
                    // mode="multiple"
                    showSearch
                    fieldNames={{ value: "vehicleId", label: "vehicleNo" }}
                    optionFilterProp="children"
                    options={this.workingHoursStore?.assignAssetList}
                    // onChange={(e) => {

                    // }}
                  />

                  {/* <Form.Item name='vehicleId' label={this.context.translate("_ASSET_NAME_")} rules={[{ required: true }]}   >
                                <Select placeholder="Select Asset" onChange={(e) => this.setCurrentOdometer(e)} >
                                    {this.fillAssets()}
                                </Select> */}
                </Form.Item>

                <Form.Item
                  name="gpsOdo"
                  label={this.context.translate("_CURRENT_GPS_ODOMETER_")}
                  rules={[{ required: true }]}
                  className="disabledLabel"
                >
                  <Input
                    disabled
                    onChange={(e) => {
                      this.validateFieldsValue(e.target.value, "gpsOdo");
                      // this.validateFields(e.target.value, "gpsOdo");
                    }}
                  />
                </Form.Item>

                <Form.Item
                  name="odometer"
                  label={this.context.translate("_CHANGE_ODOMETER_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    value={this.state.changeOdometer}
                    onChange={async (e) => {
                      await this.setState({ changeOdometer: e.target.value });
                      await this.setFieldValue();
                      await this.validateFieldsValue(
                        e.target.value,
                        "odometer"
                      );

                      if (
                        this.state.vehicleId === "" &&
                        !this.state.vehicleIdSelected
                      ) {
                        Modal.warning({
                          // title: "Alert",
                          title: this.context.translate("_FLEET_ALERT_"),
                          content: this.context.translate(
                            "_PLEASE_SELECT_A_VEHICLE_TO_SET_GPS_ODOMETER_"
                          ),
                          // content:
                          //   "Please select a vehicle to set GPS odometer",
                        });
                      }
                    }}
                    onClick={() => {}}
                  />
                </Form.Item>
              </Col>
              <Col span={12} className="formColumnClass">
                <Form.Item
                  name="datetime"
                  label={this.context.translate("_DATE_TIME_")}
                  rules={[{ required: true }]}
                >
                  <DatePicker
                    showTime
                    style={{ width: "100%" }}
                    format="DD-MM-YYYY hh:mmA"
                    onChange={(date) => {
                      // Check if the date is valid
                      if (date && date.isValid()) {
                        // Update the form field value
                        this.formRef.current.setFieldsValue({ datetime: date });
                      }
                    }}
                  />

                  {/* <DatePicker
                   
                    style={{ width: "100%" }}
                    format={"DD-MM-YYYY hh:mmA"}
                    onChange={(date) => {
                      // Check if the date is valid
                      if (date && date.isValid()) {
                        // Set the time to the beginning of the day (00:00:00)
                        const startOfDay = date.startOf('day');
                        
                        // Update the form field value
                        this.formRef.current.setFieldsValue({ datetime: startOfDay });
                      }
                    }}
                  /> */}
                </Form.Item>

                <Form.Item
                  name="travelledOdo"
                  className="disabledLabel"
                  label={this.context.translate("_ODOMETER_TRAVELLED_")}
                  initialValue={0}
                >
                  <Input
                    disabled
                    onChange={(e) =>
                      this.validateFieldsValue(e.target.value, "travelledOdo")
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="totalOdo"
                  label={this.context.translate("_TOTAL_ODOMETER_")}
                  rules={[{ required: true }]}
                  className="disabledLabel"
                >
                  <Input
                    disabled
                    onChange={(e) =>
                      this.validateFieldsValue(e.target.value, "totalOdo")
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="diffOdo"
                  className="disabledLabel"
                  label={this.context.translate("_DIFF_ODOMETER_")}
                  rules={[{ required: true }]}
                >
                  <Input
                    disabled
                    onChange={(e) =>
                      this.validateFieldsValue(e.target.value, "diffOdo")
                    }
                  />
                </Form.Item>
              </Col>
            </Row>

            <div className="formBtnGroup">
              {/* <Link to={"/vehicleodometerlist"}>
                  <Button ghost type="primary" htmlType="">
                    {" "}
                    Back{" "}
                  </Button>
                </Link> */}
              <Button
                className="formCancelButton"
                type="primary"
                onClick={(e) => {
                  this.closeModal();
                  // this.formRef.current?.resetFields();
                }}
              >
                {/* Cancel */}
                {this.context.translate("_FLEET_CLOSE_BTN_")}
              </Button>
              <Button
                type="primary"
                className="formSaveButton"
                htmlType="submit"
                loading={this.state.loading}
              >
                {/* Save */}
                {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
              </Button>
            </div>
          </Form>
        </div>

        <Modal
          width={350}
          className="alert-model"
          title="Message"
          open={this.state.odometerWarning}
          onCancel={() => this.setState({ odometerWarning: false })}
          footer={[
            <Button
              // key="no"

              onClick={() => {
                this.setState({ odometerWarning: false });
                this.formRef.current.setFieldsValue({
                  vehicleId: undefined,
                  gpsOdo: undefined,
                });
              }}
            >
              OK
            </Button>,
          ]}
        >
          <p>Vehicle is on Trip, Cannot adjust odometer.</p>
        </Modal>

        {/* {!this.state.vehicleId && (
          <Modal.warning
            title="Alert"
            content="Please select a vehicle to set GPS odometer"
          />
        )} */}
      </div>
    );
  }
}

VehicleOdometerAdd.contextType = StoreContext;
export default observer(withRouter(VehicleOdometerAdd));
