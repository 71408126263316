import React, { Component } from "react";

import { Form, Button, Input, Checkbox, Row, Col, Spin } from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import VehicleGroupStore from "../store/VehicleGroupStore";
import withRouter from "../withRouter";

import CompanyAutocomplete from "./CompanyAutocomplete";

import CompanyStore from "../store/CompanyStore";

const CheckboxGroup = Checkbox.Group;

class VehicleGroupAdd extends Component {
  formRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      showAsset: false,
      selectedChecked: false,
      selectedValues: [],
      deviceGroupId: "",
      companyId: "",
      loadingSpin: false,
    };
    this.groupStore = new VehicleGroupStore();
    this.companyStore = new CompanyStore();
  }
  async componentDidMount() {
    if (Number(this.props.getUserData().isadmin) !== -1) {
      // this.context.viewStateStore.setUserAddStates({ companyId: this.props.getUserData().userCompanyId});
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ isadmin: this.props.getUserData().isadmin });
      const data = await this.groupStore.getAssetData(
        this.props.getUserData().userCompanyId
      );
      console.log(data);
      await this.setState({ update: 1 });
    }
    const deviceGroupId = this.props.vehicleGroupId;

    console.log(deviceGroupId);
    // const companyId=this.props.companyId
    // console.log(companyId)

    if (deviceGroupId) {
      await this.filldata(deviceGroupId);
      await this.setState({ deviceGroupId: deviceGroupId });
    }
  }

  filldata = async (deviceGroupId) => {
    await this.setState({ loadingSpin: true });
    const getVehicleGroup = await this.groupStore.getVehicleGroupData(
      deviceGroupId,
      this.props.companyId
    );

    const allVehicles = await this.groupStore.getAssetData(
      this.props.companyId
    );

    const singleData = await this.companyStore.getCompanyDataById(
      this.props.companyId
    );

    await this.setState({
      companyId: getVehicleGroup.companyId,
      deviceGroupId: getVehicleGroup.deviceGroupId,
    });

    // const vehicles =await this.groupStore.vehicleArray
    // console.log(vehicles)
    let vehicleData = [];
    getVehicleGroup.vehicles?.forEach((val) => {
      vehicleData.push(val.vehicleId);
    });
    if (vehicleData.length === allVehicles.length) {
      this.setState({ selectedChecked: true });
    }

    await this.setState({ selectedValues: vehicleData });

    await this.formRef.current.setFieldsValue({
      deviceGroupId: getVehicleGroup.deviceGroupId,
      companyId: singleData,
      groupname: getVehicleGroup.groupname,
      inchargeName: getVehicleGroup.inchargeName,
      email: getVehicleGroup.email,
      // vehicle: this.state.selectedValues,
    });
    await this.setState({ loadingSpin: false });
  };
  layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  onDeviceDetailsSubmit = async (values) => {
    this.setState({ loading: true });
    const data = {
      ...values,
      companyId: this.state.companyId,
      devicegroupId: this.state.deviceGroupId,
      deviceGroupparentId: 0,
      // devicegroupId: "",
      tokenId: "",
      vehicle: this.state.selectedValues,
    };
    console.log(data);
    const resp = await this.groupStore.addData(data);
    await this.props.response(resp);
    // if (resp.data.success == "S") {
    //   message.success(resp.data.message);
    //   this.props.navigate("/vehiclegrouplist");
    // } else if (resp.data.success == "F0") {
    //   resp.data.errors.map((value) => {
    //     message.error(value["username"]);
    //   });
    // }
    this.setState({ loading: false });
  };

  async handleChange(comp) {
    console.log(comp.value);
    await this.setState({ companyId: comp.value });
    await this.formRef.current.setFieldsValue({
      companyId: this.state.companyId,
    });

    const data = await this.groupStore.getAssetData(this.state.companyId);
    console.log("data", data);
    await this.handleSearch();
  }

  async handleSearch() {
    await this.groupStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      searchField: this.state.searchField,
      companyId: this.state.companyId,
    });
    await this.setState({ loadList: 1 });
  }

  handleSelectAllChange = async (e) => {
    this.setState({ selectedChecked: e.target.checked });

    if (e.target.checked) {
      const allValues = this.groupStore?.assetList.map(
        (option) => option.vehicleId
      );
      this.formRef.current.setFieldsValue({ vehicle: allValues.vehicleNo });

      await this.setState({ selectedValues: allValues });

      console.log(allValues);
    } else {
      await this.setState({ selectedChecked: e.target.checked });
      await this.setState({ selectedValues: [] });
    }
  };

  handleCheckboxChange = async (checkedValues) => {
    if (checkedValues?.length === this.groupStore.assetList.length) {
      console.log("hoo");
      await this.setState({ selectedChecked: true });
    } else {
      await this.setState({ selectedChecked: false });
    }
    await this.setState({ selectedValues: checkedValues });

    console.log(this.state.selectedValues);
  };

  closeModal = () => {
    this.props.closeModal();
  };

  render() {
    return (
      <div style={{ padding: 0 }}>
        <div>
          <Spin spinning={this.state.loadingSpin} fullscreen>
            <Form
              style={{ maxWidth: "100%", padding: 0 }}
              layout="vertical"
              ref={this.formRef}
              onFinish={this.onDeviceDetailsSubmit}
              // {...this.layout}
              name="nest-message"
              className="formClass" //change
            >
              {/*                  
                        <Col span={12}>
                            <h1> Device Details</h1>
                        </Col> */}

              {/* <div className="headingTitle">Device Details</div> */}
              <Row className="overflowFormInputs">
                <Col span={12} className="formColumnClass">
                  {/* css class */}
                  {(this.props.getUserData().isadmin === -1 ||
                    this.props.getUserData().isadmin === 3) && (
                    <Form.Item
                      name="companyId"
                      label={this.context.translate("_ACCOUNT_")}
                      rules={[{ required: true }]}
                    >
                      <CompanyAutocomplete
                        onChange={(value) => this.handleChange(value)}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  )}

                  <Form.Item
                    name="groupname"
                    label={this.context.translate(
                      "_FLEET_GROUP_GROUPDETAIL_DEVICEGROUP_"
                    )}
                    rules={[{ required: true }]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="inchargeName"
                    label={this.context.translate("_INCHARGE_NAME_")}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12} className="formColumnClass">
                  {/* css class */}
                  <Form.Item
                    name="email"
                    label={this.context.translate("_EMAIL_")}
                    rules={[{ type: "email" }]}
                  >
                    <Input />
                  </Form.Item>

                  {/* <Form.Item label={this.context.translate("_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_")}  >
                                <Checkbox checked={this.state.selectedChecked} onChange={(e) => { this.handleSelectAllChange(e) }} />
                            </Form.Item> */}
                  <Form.Item
                    // label={this.context.translate(
                    //   "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                    // )}

                    label=" "
                  >
                    {/* Select Asset{" "} */}
                    {this.context.translate(
                      "_FLEET_GROUP_GROUPDETAIL_SELECTVEHICLE_"
                    )}
                    &nbsp;&nbsp;
                    <Checkbox
                      checked={this.state.selectedChecked}
                      onChange={(e) => {
                        this.handleSelectAllChange(e);
                      }}
                    >
                      {/* All */}
                      {this.context.translate("_ALL_")}
                    </Checkbox>
                  </Form.Item>
                  <CheckboxGroup
                    name="vehicle"
                    value={this.state.selectedValues}
                    onChange={this.handleCheckboxChange}
                  >
                    <Row>
                      {this.groupStore?.assetList?.map((value) => {
                        return (
                          <Col span={10}>
                            <Checkbox value={value.vehicleId}>
                              {value.vehicleNo}
                            </Checkbox>
                          </Col>
                        );
                      })}
                    </Row>
                  </CheckboxGroup>
                </Col>
              </Row>
              <div className="formBtnGroup">
                {/* <Link to={"/vehiclegrouplist"}>
                  {" "}
                  <Button ghost type="primary">
                    Back{" "}
                  </Button>
                </Link> */}
                <Button
                  type="primary"
                  className="formCancelButton"
                  onClick={(e) => {
                    // this.formRef.current?.resetFields();
                    this.closeModal();
                    this.setState({
                      selectedChecked: false,
                      selectedValues: [],
                    });
                  }}
                >
                  {/* Cancel */}
                  {this.context.translate("_FLEET_CLOSE_BTN_")}
                </Button>
                <Button
                  type="primary"
                  className="formSaveButton"
                  htmlType="submit"
                  loading={this.state.loading}
                >
                  {/* Save */}
                  {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                </Button>
              </div>
            </Form>
          </Spin>
        </div>
      </div>
    );
  }
}

VehicleGroupAdd.contextType = StoreContext;
export default observer(withRouter(VehicleGroupAdd));
