import React from "react";
import {
  Input,
  message,
  Select,
  Button,
  Space,
  Tag,
  Row,
  Col,
  Modal,
  Tooltip,
  Switch,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";
import { Link } from "react-router-dom";

import UserAdd from "./UserAdd";
import {
  DeleteFilled,
  PlusOutlined,
  SearchOutlined,
  CarOutlined,
} from "@ant-design/icons";
import UsersStore from "../store/UsersStore";
import DepartmentStore from "../store/DepartmentStore";
import withRouter from "../withRouter";
import ListTable from "./ListTable";

import DeleteConfirm from "./DeleteConfirm";
import config from "../config";
import CompanyAutocomplete from "./CompanyAutocomplete";
import CompanyStore from "../store/CompanyStore";
import AssignVehicleToUser from "./AssignVehicleToUser";
import Cookies from "universal-cookie";
import UserListFields from "./Plugins/AddUser/UserListFields";

const { Search } = Input;

class CompanyUserList extends React.Component {
  constructor(props) {
    super(props);
    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.onSearch = this.onSearch.bind(this);

    this.selectDistributor = this.selectDistributor.bind(this);

    this.UsersStore = new UsersStore();
    this.DepartmentStore = new DepartmentStore();
    this.companyStore = new CompanyStore(this);
    this.cookies = new Cookies();
    this.isadmin = this.props.getUserData().isadmin;

    this.state = {
      loading: false,
      pageSize: 50,
      currentPage: 1,
      distributor: "",
      distributorType: "",
      status: "",
      company_name: "",
      emailId: "",
      listUpdated: 0,
      loadList: 0,
      company_value_label: "",
      userId: "",
      openUserForm: false,

      listDataUpdate: false,
      openAssignForm: false,
      assignUserId: "",
      vehicleSelection: 0,
      showStatusConfirm: false,
      isSwitchOn: false,
      userType: "",
      userStatus: "",
      statusUserId: "",
    };
  }
  onEdit = async (record) => {
    // console.log(record.companyId)
    console.log(record);
    // await this.context.setBeforeEditState(record.companyId);
    await this.context.viewStateStore.setUserAddStates({
      companyId: record.companyId,
    });
    // await this.props.navigate("/useradd/" + record.userId);
    await this.setState({ openUserForm: true });
    await this.setState({ userId: record.userId });
  };
  async onDelete(record) {
    const response = await this.UsersStore.deleteRecord({
      userId: record.userId,
    });

    if (response?.data?.success === "S") {
      message.success(response.data.message);

      this.handleSearch();
    } else {
      message.error(response.data.message);
    }
  }

  // sendEmail = async (record) => {
  //   this.setState({ loading: true });
  //   console.log(record, "sfsdfdgdfgdfgfhgh");
  //   const resp = await this.UsersStore.sendEmail({
  //     emailId: record.emailId,
  //     username: record.username,
  //     password: record.password,
  //   });
  //   // console.log(response);

  //   if (resp.data.success === "S") {
  //     message.success(resp.data.message);
  //   } else if (resp.data.success === "F0") {
  //     // resp.data.errors.map((value) => {
  //     message.error(resp.data.message);
  //     // });
  //   }

  //   this.setState({ loading: false });
  // };
  async componentDidMount() {
    // await this.companyStore.loadData({ offset: 0, limit: 50 });
    // console.log(this.UsersStore.beforeEditCompanyNo,"era")
    console.log("hello");

    console.log(this.props.getUserData());
    // const data=JSON.parse(base64_decode(this.cookies.get("data")))
    if (
      Number(this.props.getUserData().isadmin) !== -1 &&
      Number(this.props.getUserData().isadmin) !== 3
    ) {
      console.log(this.props.getUserData().userCompanyId);
      await this.setState({
        companyId: this.props.getUserData().userCompanyId,
      });
      await this.setState({ userId: this.props.getUserData().userId });

      await this.handleSearch();
    }

    this.setState({ pageSize: 50 });
    // await this.handleSearch();
    this.columns = [
      {
        title: this.context.translate("_USER_ID_"),
        dataIndex: "userId",
        width: "8%",
      },
      {
        title: this.context.translate("_NAME_"),
        dataIndex: "name",
        width: "15%",
        render: (value, record) => {
          return (
            <Link
              onClick={(e) => {
                e.preventDefault();
                this.onEdit(record);
              }}
            >
              {value === null ? "Admin" : value}
            </Link>
          );
        },
      },
      {
        title: this.context.translate("_FLEET_DASHBORD_NEWPOI_ADDRESS_"),
        dataIndex: "address",
        width: "15%",
      },
      {
        title: this.context.translate("_MOBILE_NUMBER_"),
        dataIndex: "mobileno",
        width: "10%",
      },
      {
        title: this.context.translate("_EMAIL_"),
        dataIndex: "emailId",
        width: "13%",
      },

      {
        title: this.context.translate("_USER_TYPE_"),
        dataIndex: "isadmin",

        width: "14%",
        render: (mainValue, obj) => {
          let value = Number(mainValue);
          if (value === 1 || value === 3) {
            return (
              <Tag
                style={{ width: 80, textAlign: "center" }}
                color={config.adminColor}
              >
                Admin
              </Tag>
            );
          } else if (value === 5) {
            if (Number(obj.userType) === 5) {
              return (
                <Tag
                  style={{ width: 80, textAlign: "center" }}
                  color={config.salesPersonColor}
                >
                  Sales Person
                </Tag>
              );
            }
            if (Number(obj.userType) === 6) {
              return (
                <Tag
                  style={{ textAlign: "center" }}
                  color={config.salesPersonColor}
                >
                  Transport Co-ordinator
                </Tag>
              );
            } else {
              return (
                <Tag
                  style={{ width: 80, textAlign: "center" }}
                  color={config.salesPersonColor}
                >
                  Sales Person
                </Tag>
              );
            }
          } else if (value === 2) {
            return (
              <Tag
                style={{ width: 80, textAlign: "center" }}
                color={config.driverColor}
              >
                Driver
              </Tag>
            );
          } else {
            return (
              <Tag
                style={{ width: 80, textAlign: "center" }}
                color={config.userColor}
              >
                User
              </Tag>
            );
          }
        },
      },
      {
        title: this.context.translate("_DEPARTMENT_"),
        dataIndex: "departmentName",
        width: "10%",
      },
      {
        title: this.context.translate("_FLEET_COMPANY_USERDETAIL_USERNAME_"),
        dataIndex: "username",
        width: "10%",
      },
      {
        title: this.context.translate("_STATUS_"),
        dataIndex: "status",
        width: "6%",
        render: (value) => {
          if (value == 1) {
            return (
              <Tag
                style={{ width: 55, textAlign: "center" }}
                color={config.activeColor}
              >
                Active
              </Tag>
            );
          } else if (value == 0) {
            return (
              <Tag
                style={{
                  color: "white",
                  backgroundColor: config.inActiveColor,
                  width: 55,
                  textAlign: "center",
                }}
              >
                Inactive
              </Tag>
            );
          }
        },
      },
      {
        title: this.context.translate("_ACTION_"),
        dataIndex: "Action",
        width: "9%",

        render: (_, record) => {
          // console.log(record.isadmin,"sdfdgdfghdfhg")
          return (
            <>
              <Space>
                {/* <Link
                  onClick={(e) => {
                    e.preventDefault();
                    this.onEdit(record);
                  }}
                >
                  <Tooltip
                    // title="Edit User Details"
                    title={ this.context.translate("_FLEET_USER_USERLIST_EDIT_")}
                    color={config.tooltipColorEdit}
                    placement="bottom"
                  >
                    <EditFilled />
                  </Tooltip>
                </Link> */}

                {(this.props.getUserData().isadmin === -1 ||
                  (this.props.getUserData().isadmin !== -1 &&
                    record.isadmin !== 1 &&
                    record.isadmin !== 3)) && (
                  <DeleteConfirm
                    onConfirm={(e) => {
                      this.onDelete(record);
                    }}
                  >
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <Tooltip
                        // title="Delete User"
                        title={this.context.translate(
                          "_FLEET_USER_USERLIST_DELETE_"
                        )}
                        placement="bottom"
                        color={config.tooltipColorDelete}
                      >
                        <DeleteFilled style={{ color: config.deleteColor }} />
                      </Tooltip>
                    </Link>
                  </DeleteConfirm>
                )}

                {/* <Link
                  onClick={(e) => {
                    // e.preventDefault();
                    console.log(e);
                    this.sendEmail(record);
                  }}
                >
                  <Tooltip
                    // title="Send"
                    title={this.context.translate(
                      "_FLEET_DASHBORD_VEHICLE_COMMAND_SENDBTN_"
                    )}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <MailOutlined />
                  </Tooltip>
                </Link> */}

                <Link
                  // to={`/assignvehicletouser/${this.state.companyId}/${record.userId}`}
                  // to={`/assignvehicletouser/?id=65`}
                  onClick={() => {
                    this.setState({
                      openAssignForm: true,
                      assignUserId: record.userId,
                      vehicleSelection: record.vehicleSelection,
                    });
                  }}
                >
                  {" "}
                  <Tooltip
                    // title="Assign"
                    title={this.context.translate("_ASSIGN_")}
                    placement="bottom"
                    color={config.tooltipColorEdit}
                  >
                    <CarOutlined />
                  </Tooltip>
                </Link>

                <Tooltip
                  title={
                    record.status == "1"
                      ? this.context.translate("_ACTIVE_")
                      : this.context.translate(
                          "_FLEET_USER_USERLIST_STATUS_INACTIVE_"
                        )
                  }
                  placement="top"
                  color={
                    record.status == "1"
                      ? config.activeColor
                      : config.inActiveColor
                  }
                >
                  <Switch
                    size="small"
                    className="form-offlineAlerts"
                    checked={Number(record.status)}
                    // checkedChildren="Yes"
                    // unCheckedChildren="No"
                    // checkedChildren={this.context.translate(
                    //   "_ACTIVE_"
                    // )}
                    // unCheckedChildren={this.context.translate(
                    //   "_IN_ACTIVE_"
                    // )}
                    onChange={async (val) => {
                      this.setState({ statusUserId: record.userId });
                      this.setState({ isActive: val });
                      this.setState({ showStatusConfirm: true }); // open modal
                    }}
                    // defaultChecked
                  />
                </Tooltip>
              </Space>
            </>
          );
        },
      },
    ];
  }
  async componentDidUpdate(prevProps, prevState) {
    if (
      this.state.openUserForm &&
      prevState.openUserForm !== this.state.openUserForm
    ) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 10);
    }

    if (prevState.listDataUpdate !== this.state.listDataUpdate) {
      const beforeEditCompanyId =
        this.context.viewStateStore.userAddStatesObj.companyId;
      console.log(beforeEditCompanyId);
      await this.UsersStore.loadData({
        offset: (this.state.currentPage - 1) * this.state.pageSize,
        limit: this.state.pageSize,
        companyId: beforeEditCompanyId,
        isadmin: this.props.getUserData().isadmin,
        userId:
          this.props.getUserData().isadmin == 0
            ? this.props.getUserData().userId
            : 0,
      });

      const singleData = await this.companyStore.getCompanyDataById(
        this.context.viewStateStore.userAddStatesObj.companyId
      );
      // this.setState({companyId:this.state.})
      await this.setState({ listUpdated: 1 }); //need to reload data
      await this.setState({
        company_value_label: singleData,
        companyId: beforeEditCompanyId,
      });
      await this.setState({ listDataUpdate: false });
    }
  }
  async handleTableChange(pagination, filters, sorter) {
    //   console.log(pagination);
    await this.setState({
      currentPage: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.handleSearch();
  }

  async selectDistributor(value) {
    //  console.log("value",value)
    await this.setState({ distributor: value });
    await this.handleSearch();
  }

  async handleChange({ comp, userId }) {
    console.log(comp);
    if (comp) {
      await this.setState({
        currentPage: 1,
        companyId: comp.value,
        pageSize: 50,
        // isadmin:this.isadmin
      });
      const singleData = await this.companyStore.getCompanyDataById(
        comp.value,
        this.isadmin
      );
      console.log(singleData);
      await this.setState({ company_value_label: singleData });
      console.log(this.state.company_value_label);

      // await this.UsersStore.beforeEditCompanyId(comp.value)
      await this.handleSearch();
      await this.setState({ loadList: 1 });
    }
    // if (userId) {
    //   const data = await this.UsersStore.getDeviceGroups({
    //     companyId: this.state.companyId,
    //   });
    //   console.log(data);
    //   const data1 = await this.UsersStore.getVehiclesData({
    //     companyId: this.state.companyId,
    //     userId: userId,
    //   });
    //   console.log(data1);

    //   // console.log(this.UsersStore?.users);
    // }
    await this.setState({ loadList: 1, pageSize: 50 });
  }

  // async onSearch(value) {
  //   console.log(value);
  //   await this.setState({ emailId: value });
  //   this.handleSearch();
  // }

  async handleSearch() {
    this.setState({ loading: true });
    await this.UsersStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      searchField: this.state.searchField,
      userStatus: this.state.userStatus,
      userType: this.state.userType,
      isadmin: this.props.getUserData().isadmin,
      userId:
        this.props.getUserData().isadmin == 0
          ? this.props.getUserData().userId
          : 0,
    });
    // console.log(this.props.getUserData().isadmin);
    await this.setState({ listUpdated: 1 }); //need to reload data
    await this.setState({ loading: false });
  }

  async onSearch() {
    this.setState({ loading: true });
    await this.UsersStore.loadData({
      offset: (this.state.currentPage - 1) * this.state.pageSize,
      limit: this.state.pageSize,
      companyId: this.state.companyId,
      searchField: this.state.searchField,
      userStatus: this.state.userStatus,
      userType: this.state.userType,
    });

    await this.setState({ listUpdated: 1 }); //need to reload data
    await this.setState({ loading: false });
  }

  searchInput = async (e) => {
    const value = e.target.value;

    // await this.setState({searchField:value})
    if (value.length > 3) {
      await this.UsersStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        searchField: value,
        companyId: this.state.companyId,
      });

      await this.setState({ loadList: 1 });
    } else if (value.length === 0) {
      await this.UsersStore.loadData({
        offset: 0,
        limit: this.state.pageSize,
        // searchField: value,
        companyId: this.state.companyId,
      });
      await this.setState({ currentPage: 1 });
    }
  };

  responseMsg = async (resp) => {
    console.log(resp);
    if (resp.data.success == "S") {
      message.success(resp.data.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openUserForm: false });

      //  await this.handleSearch();
      // this.props.navigate("/companyuserlist");
    } else if (resp.data.success == "F0") {
      resp.data.errors.map((value) => {
        message.error(value["username"]);
      });
    }
  };

  assignresponseMsg = async (response) => {
    if (response.data.success === "S") {
      message.success(response.data.message);
      await this.setState({ listDataUpdate: true });
      await this.setState({ openAssignForm: false });
      await this.handleSearch();
      //  await this.props.navigate("/companyuserlist");
      //   await this.setState({ loading: false });
      //   console.log(response);
    } else {
      await message.error(response.data.message);
      await this.setState({ loading: false });
    }
  };

  closeModal = () => {
    this.setState({ openUserForm: false, userId: "" });
  };
  // confirmStatus = async (status) => {
  //   // alert("Work In Progress");

  //   //  await this.companyStore.updateStatus({
  //   //   companyId: this.state.companyId,
  //   //   activeStatus: "-1"
  //   // });

  //   this.setState({showStatusConfirm :false});
  //   await this.setState({isSwitchOn: !this.state.isSwitchOn  });

  // }

  confirmStatus = async (status) => {
    const data = {};

    data.userId = this.state.statusUserId;
    data.activeStatus = this.state.isActive === true ? 1 : 0;

    await this.UsersStore.updateStatus(data);
    await this.setState({ showStatusConfirm: false });

    await this.handleSearch();
  };
  cancelConfirm = async (response) => {
    this.setState({ isSwitchOn: false });
    this.setState({ showStatusConfirm: false });
  };

  handleExportClick = (val) => {
    let result = [];
    if (true) {
      this.columns.map((obj) => result.push(obj.dataIndex));
      //  console.log(result.toString())

      var getData = {
        companyId: this.state.companyId,

        searchField: "",
        colList: result,
      };
      console.log(getData);
      if (val) {
        // console.log("afd")
        this.UsersStore.exportExcel(getData);
      }
    }
  };

  render() {
    //console.log('in render1')
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              <div className="heading">
                {this.context.translate("_FLEET_USER_USERLIST_TITLE_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space style={{}}>
                <Tooltip
                  placement="left"
                  // title="Add"
                  title={this.context.translate(
                    "_FLEET_DRIVER_DRIVERLIST_ADDBTN_"
                  )}
                  color={config.primaryColor}
                >
                  <Button
                    className="addButton"
                    icon={<PlusOutlined />}
                    type="primary"
                    onClick={() => {
                      this.setState({ openUserForm: true, userId: "" });
                    }}
                  ></Button>
                </Tooltip>

                <UserListFields
                  handleExportClick={this.handleExportClick}
                  UsersStore={this.UsersStore?.users}
                />
              </Space>
            </Col>
          </Row>
        </div>

        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={16}>
              <Space>
                <CompanyAutocomplete
                  onChange={(comp) => this.handleChange({ comp })}
                  value={
                    this.state.company_value_label
                      ? this.state.company_value_label
                      : null
                  }
                  // allowClear={true}
                  style={{ width: 200 }}
                />
                {this.props.getUserData().isadmin != 0 && (
                  <Select
                    style={{
                      width: 150,
                    }}
                    onSelect={async (val) => {
                      console.log(val);
                      await this.setState({ userStatus: val });
                      await this.onSearch();
                    }}
                    placeholder={this.context.translate(
                      "_FLEET_USER_USERLIST_STATUS_"
                    )}
                    options={[
                      {
                        value: -1,
                        label: "All",
                        // label:this.context.translate("_ALL_")
                      },
                      {
                        value: 1,
                        label: "Active",
                        // label:this.context.translate("_ALL_")
                      },
                      {
                        value: 0,
                        label: "Inactive",
                        // label:this.context.translate("_EXPIRED_")
                      },
                    ]}
                  />
                )}
                {this.props.getUserData().isadmin != 0 && (
                  <Select
                    style={{
                      width: 150,
                    }}
                    onSelect={async (val) => {
                      console.log(val);
                      await this.setState({ userType: val });
                      await this.onSearch();
                    }}
                    // placeholder={this.context.translate(
                    //   "_FLEET_USER_USERLIST_STATUS_"
                    // )}
                    placeholder={this.context.translate("_USER_TYPE_")}
                    options={[
                      {
                        value: 1,
                        label: "Admin",
                      },

                      {
                        value: 2,
                        label: "Driver",
                      },
                      {
                        value: 5,
                        label: "Sales Person",
                      },
                      {
                        value: 6,
                        label: "Transport Co-ordinator",
                      },
                      {
                        value: 7,
                        label: "User",
                      },
                    ]}
                  />
                )}
                {this.props.getUserData().isadmin != 0 && (
                  <Input
                    // placeholder="Search By Name,Mobile No,Email"
                    placeholder={this.context.translate(
                      "_SEARCH_BY_NAME_MOBILE_EMAIL_"
                    )}
                    style={{ width: 400 }}
                    onChange={async (e) => {
                      await this.setState({ searchField: e.target.value });
                      await this.searchInput(e);
                    }}
                  />
                )}
                {this.props.getUserData().isadmin != 0 && (
                  <Button
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={async () => {
                      // this.handleSearch();
                      await this.UsersStore.loadData({
                        offset: 0,
                        limit: this.state.pageSize,
                        searchField: this.state.searchField,
                        companyId: this.state.companyId,
                      });
                      // await this.onSearch()
                      await this.setState({ loadData: 1 });
                    }}
                  />
                )}
              </Space>
            </Col>
            <Col span={8}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
                {/*<Button  ><PlusOutlined />WASL</Button>
                <Button  ><PlusOutlined />WASL Station</Button>*/}
              </Space>
            </Col>
          </Row>

          {this.state.openUserForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="User Details"
              title={this.context.translate("_FLEET_COMPANY_USERDETAIL_TITLE_")}
              centered
              visible={this.state.openUserForm}
              footer={null}
              onCancel={() => {
                this.setState({ openUserForm: false, userId: "" });
              }}
              width={665}
            >
              <div>
                <UserAdd
                  userId={this.state.userId}
                  response={this.responseMsg}
                  closeModal={this.closeModal}
                />
              </div>
            </Modal>
          )}

          {this.state.openAssignForm && (
            <Modal
              bodyStyle={{ height: "645px" }}
              className="formShowModal" //  {/* css class */}
              // title="Assign Vehicles"
              title={this.context.translate("_FLEET_USER_USERLIST_VEHICLE_")}
              centered
              visible={this.state.openAssignForm}
              footer={null}
              onCancel={() => {
                this.setState({ openAssignForm: false });
              }}
              width={665}
            >
              <div>
                <AssignVehicleToUser
                  companyId={this.state.companyId}
                  userId={this.state.assignUserId}
                  response={this.assignresponseMsg}
                  vehicleSelection={Number(this.state.vehicleSelection)}
                  // vehicleSelection={Number(2)}
                />
              </div>
            </Modal>
          )}

          {this.state.showStatusConfirm && (
            <Modal
              maskClosable={false}
              title={this.context.translate("_CONFIRM_")}
              description="Are you sure to change this status?"
              centered
              open={this.state.showStatusConfirm}
              //footer={null}
              onOk={this.confirmStatus}
              onCancel={() => {
                this.setState({ showStatusConfirm: false });
              }}
              width={400}
            >
              {this.state.isActive === true ? (
                <p>{this.context.translate("_STATUS_ACTIVE_")}</p>
              ) : (
                <p>{this.context.translate("_STATUS_INACTIVE_")}</p>
              )}
            </Modal>
          )}
        </div>

        <div style={{ margin: "0px 20px 20px" }}>
          <ListTable
            currentPage={this.state.currentPage}
            pageSize={this.state.pageSize}
            total={this.UsersStore?.total}
            columns={this.columns}
            dataSource={this.UsersStore?.users}
            loading={this.UsersStore?.loading}
            onChange={this.handleTableChange}
            // pagination={{ currentPage:this.state.currentPage,}}
          />
        </div>
      </>
    );
  }
}
CompanyUserList.contextType = StoreContext;
export default observer(withRouter(CompanyUserList));
