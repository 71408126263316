import {
  Card,
  Space,
  Row,
  Col,
  Modal,
  Input,
  Button,
  Form,
  DatePicker,
  message,
  Table,
  Select,
} from "antd";
import React from "react";
// import { Bar, Column } from "@ant-design/plots";
import MaintenanceDashboardStore from "../store/MaintenanceDashboardStore";
import { StoreContext } from "../store/RootStore";
import { observer } from "mobx-react";
import { decode as base64_decode } from "base-64";

import {
  CartesianGrid,
  Cell,
  BarChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import conf from "../config";

class MaintenanceDashboard extends React.Component {
  constructor(props, context) {
    super(props);
    this.state = {
      serviceReminder: false,
      tyerChangeReminder: false,
      oilChangeReminder: false,
      istimaraInsurance: false,
      insuranceReminder: false,
      batteryChangeReminder: false,
      driverOrVehicleSearchArray: [],
      driverOrVehicleSearchArrayfortire: [],
      driverOrVehicleSearchArrayforoil: [],
      driverOrVehicleSearchArrayforistimara: [],
      driverOrVehicleSearchArrayforinsurance: [],
      driverOrVehicleSearchArrayforbattery: [],
      openactionmodal: false,
      openactiofortyre: false,
      openactionforoil: false,
      vehicleid: "",
      status: "",
      dueLimitTyre: 1,
      dueLimit: 1,
    };
    this.maintenanceDashboardStore = new MaintenanceDashboardStore();
    this.dashBoardCardCss = { height: 225, width: "15.5%", padding: "14px" };
    this.COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    this.data = [
      { name: "Group A", value: 400 },
      { name: "Group B", value: 300 },
      { name: "Group C", value: 300 },
      { name: "Group D", value: 200 },
    ];
    this.RADIAN = Math.PI / 180;
    this.columns1 = [
      {
        // title: "Vehicle Id",
        title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleId",
        width: 10,
      },
      {
        // title: "Asset Name",
        title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
        dataIndex: "vehicleNo",
        width: 10,
      },
      {
        // title: "Driver",
        title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        dataIndex: "driverName",
        width: 10,
      },
      {
        // title: "Service Interval (km)",
        title: context.translate("_SERVICE_INTERVAL_"),
        dataIndex: "serviceIntervalKm",
        width: 15,
      },
      {
        // title: "Next Major Service Change(Km)",
        title: context.translate(
          "_FLEET_REPORTS_MAINTENANCEREPORT_NEXTMAJORSERVICE_"
        ),
        dataIndex: "nextService",
        width: 20,
      },
      {
        // title: "Current Odometer Value ",
        title: context.translate(
          "_FLEET_REPORTS_MAINTENANCEREPORT_CURRENTODOMETER_"
        ),
        dataIndex: "distance",
        width: 17,
      },
      {
        // title: "Status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        render: (value, record) => {
          // return <>Service-{record.serviceStatus}:{record.distance}km</>
          var oilDiff1 = Number(record.nextService) - Number(record.distance);
          console.log(typeof oilDiff1, oilDiff1);

          var oilStatus;
          if (oilDiff1 >= 0) {
            // return<> { "Oil Change - " + "OverDue:" + oilDiff1 + "km"}</>;

            return (
              <p>
                Service <span style={{ color: "green" }}> Due:</span>{" "}
                {Math.abs(oilDiff1)} km
              </p>
            );
          } else {
            // return<> { "Oil Change- " + "Due.: " + oilDiff1 + "km"}</>;
            return (
              <p>
                Service <span style={{ color: "red" }}> OverDue:</span>{" "}
                {oilDiff1 ? Math.abs(oilDiff1) : Math.abs(record.distance)}
                km
              </p>
            );
          }
        },
        width: 7,
      },
      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "action",
        render: (value, record) => {
          return (
            <img
              src={require("../assets/images/action-icon.png")}
              onClick={() => {
                this.setState({
                  openactionmodal: true,
                  vehicleid: record.vehicleId,
                });
              }}
            ></img>
          );
        },
        width: 7,
      },
    ];
    this.columns2 = [
      {
        // title: "Vehicle Id",
        title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleId",
        // width:''
      },
      {
        // title: "Asset Name",
        title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
        dataIndex: "vehicleNo",
      },
      {
        // title: "Driver",
        title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        dataIndex: "driverName",
      },
      {
        // title: "Tyre Change Interval(km)",
        title: context.translate("_TIRE_CHANGE_KM_"),
        dataIndex: "tireChangeInterval",
      },
      {
        // title: "Next Tyre Change(Km)",
        title: context.translate("_NEXT_TYRE_CHANGE_KM_"),
        dataIndex: "nextTire",
      },
      {
        // title: "Current Odometer Value ",
        title: context.translate(
          "_FLEET_REPORTS_MAINTENANCEREPORT_CURRENTODOMETER_"
        ),
        dataIndex: "distance",
      },
      {
        title: context.translate("_FLEET_DASHBORD_VEHICLE_INFORMATION_TYRE_"),
        dataIndex: "lastDistanceTire",
      },
      {
        title: context.translate(
          "_FLEET_DASHBORD_VEHICLE_INFORMATION_TYRE_DATE_"
        ),
        dataIndex: "lastTireDate",
      },
      {
        // title: "Status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        render: (value, record) => {
          // return <>Service-{record.serviceStatus}:{record.distance}km</>
          //   var oilDiff1 = record.nextOil - record.distance;

          //   var oilStatus;
          //   if (oilDiff1 < 0) {
          //     // return<> { "Oil Change - " + "OverDue:" + oilDiff1 + "km"}</>;
          //     return <p>Tire Change -<span style={{ color: 'red' }}> OverDue:</span> {oilDiff1}+ "km"</p>;
          //   } else {
          //     // return<> { "Oil Change- " + "Due.: " + oilDiff1 + "km"}</>;
          //     return <p>Tire Change -<span style={{ color: 'green' }}> Due:</span> {oilDiff1}+ "km"</p>;
          //   }
          // },
          var oilDiff1 = Number(record.nextTire) - Number(record.distance);
          console.log(typeof oilDiff1, oilDiff1);

          var oilStatus;
          if (oilDiff1 >= 0) {
            return (
              <p>
                Tire Change <span style={{ color: "green" }}> Due:</span>{" "}
                {Math.abs(oilDiff1)} km
              </p>
            );
          } else {
            return (
              <p>
                Tire Change -<span style={{ color: "red" }}> OverDue:</span>{" "}
                {oilDiff1 ? Math.abs(oilDiff1) : Math.abs(record.distance)}
                km
              </p>
            );
          }
        },
      },
      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "action",
        render: (value, record) => {
          return (
            <img
              src={require("../assets/images/action-icon.png")}
              onClick={() => {
                this.setState({
                  openactiofortyre: true,
                  vehicleid: record.vehicleId,
                });
              }}
            ></img>
          );
        },
      },
    ];
    this.columns3 = [
      {
        // title: "Vehicle Id",
        title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleId",
      },
      {
        // title: "Asset Name",
        title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
        dataIndex: "vehicleNo",
      },
      {
        // title: "Driver",
        title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        dataIndex: "driverName",
      },
      {
        // title: "Oil Change Interval(km)",
        title: context.translate("_OIL_CHANGE_INTERVAL_"),
        dataIndex: "oilChangeInterval",
      },
      {
        // title: "Next Oil Change(Km)",
        title: context.translate(
          "_FLEET_REPORTS_MAINTENANCEREPORT_NEXTOILCHANGE_"
        ),
        dataIndex: "nextOil",
      },
      {
        // title: "Current Odometer Value ",
        title: context.translate(
          "_FLEET_REPORTS_MAINTENANCEREPORT_CURRENTODOMETER_"
        ),
        dataIndex: "distance",
      },
      {
        // title: "Next Oil Change(Km)",
        title: context.translate("_FLEET_DASHBORD_VEHICLE_INFORMATION_OIL_KM_"),
        dataIndex: "lastDistanceOil",
      },
      {
        // title: "Next Oil Change(Km)",
        title: context.translate(
          "_FLEET_VEHICLE_VEHICLEDETAIL_LASTOILCHANGE_DATE_"
        ),
        dataIndex: "lastOilDate",
      },
      {
        // title: "Status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "serviceStatus",
        render: (value, record) => {
          // var serviceDiff1=record.get('nextService') - record.get('distance');
          //            var serviceDiff = serviceDiff1.toString();
          //            var serviceStatus;

          //            var oilDiff1=record.get('nextOil') - record.get('distance');
          //             var oilDiff = oilDiff1.toString();
          //            var oilStatus;
          //            if(oilDiff< 0){
          //                oilStatus="Oil Change - "+"OverDue: ".fontcolor("red")+oilDiff.substring(1)+"km";
          //            }else{
          //                oilStatus="Oil Change- "+"Upcoming: ".fontcolor("green")+oilDiff+"km";
          //            }
          var oilDiff1 = Number(record.nextOil) - Number(record.distance);

          var oilStatus;
          if (oilDiff1 < 0) {
            return (
              <p>
                Oil Change <span style={{ color: "red" }}> OverDue:</span>{" "}
                {oilDiff1 ? Math.abs(oilDiff1) : 0} km
              </p>
            );
          } else {
            return (
              <p>
                Oil Change -<span style={{ color: "green" }}> Upcoming:</span>{" "}
                {/* {oilDiff1 ? Math.abs(oilDiff1) : Math.abs(record.distance)} */}
                {oilDiff1 ? Math.abs(oilDiff1): 0}Km
              </p>
            );
          }
        },
      },
      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "action",
        render: (value, record) => {
          return (
            <img
              src={require("../assets/images/action-icon.png")}
              onClick={() => {
                this.setState({
                  openactioforoil: true,
                  vehicleid: record.vehicleId,
                });
              }}
            ></img>
          );
        },
      },
    ];

    this.columns4 = [
      {
        // title: "Vehicle Id",
        title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleId",
      },
      {
        // title: "Asset Name",
        title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
        dataIndex: "vehicleNo",
      },
      {
        // title: "Driver",
        title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        dataIndex: "driverName",
      },
      {
        // title: "Company Name",
        title: context.translate("_COMPANY_NAME_"),
        dataIndex: "name",
      },
      {
        // title: "Istimara Renewal",
        title: context.translate("_MULKIYA_RENEWAL_"),

        // title: context.translate("_IASTIMARA_RENEWAL_"),
        dataIndex: "mulkiyaRenewal",
      },
      {
        // title: "Status",
        // dataIndex: "status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        // render: (value, record) => {
        //   return <>Service-{record.serviceStatus}:{record.distance}km</>
        // },
      },
      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "action",
        render: (value, record) => {
          return <img src={require("../assets/images/action-icon.png")}></img>;
        },
      },
    ];

    this.columns5 = [
      {
        // title: "Vehicle Id",
        title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleId",
      },
      {
        // title: "Asset Name",
        title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
        dataIndex: "vehicleNo",
      },
      {
        // title: "Driver",
        title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        dataIndex: "driverName",
      },
      {
        // title: "Company Name",
        title: context.translate("_COMPANY_NAME_"),
        dataIndex: "name",
      },
      {
        // title: "Insurance Renewal",
        title: context.translate("_INSURANCE_RENEWAL_"),
        dataIndex: "insuranceRenewal",
      },
      {
        // title: "Status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
      },
      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "action",
        render: (value, record) => {
          return <img src={require("../assets/images/action-icon.png")}></img>;
        },
      },
    ];
    this.columns6 = [
      {
        // title: "Vehicle Id",
        title: context.translate("_FLEET_REPORTS_VEHICLEID_"),
        dataIndex: "vehicleId",
        width: 10,
      },
      {
        // title: "Asset Name",
        title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
        dataIndex: "vehicleNo",
        width: 10,
      },
      {
        // title: "Driver",
        title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
        dataIndex: "driverName",
        width: 10,
      },
      {
        // title: "Company Name",
        title: context.translate("_COMPANY_NAME_"),
        dataIndex: "name",
        width: 10,
      },
      {
        // title: "Battery Next Change Date",
        title: context.translate("_Battery_Next_Change_Date_"),
        dataIndex: "nextBatteryChange",
        width: 20,
      },
      {
        // title: "Status",
        title: context.translate("_FLEET_DASHBORD_DROWGEO_ALERTSTATUS_"),
        dataIndex: "status",
        width: 10,
      },
      {
        // title: "Action",
        title: context.translate("_ACTION_"),
        dataIndex: "action",
        width: 10,
        render: (value, record) => {
          return <img src={require("../assets/images/action-icon.png")}></img>;
        },
      },
    ];
  }
  async handleChange() {
    await this.maintenanceDashboardStore.getData({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      status: this.state.status,
    });

    await this.setState({
      driverOrVehicleSearchArray:
        this.maintenanceDashboardStore.serviceReminderData,
    });
    await this.setState({
      driverOrVehicleSearchArrayfortire:
        this.maintenanceDashboardStore.tyreReminderData,
    });
    await this.setState({
      driverOrVehicleSearchArrayforoil:
        this.maintenanceDashboardStore.oilReminderData,
    });
    await this.setState({
      driverOrVehicleSearchArrayforistimara:
        this.maintenanceDashboardStore.mulkiyaReminderData,
    });
    await this.setState({
      driverOrVehicleSearchArrayforinsurance:
        this.maintenanceDashboardStore.insuranceExpiryData,
    });
    await this.setState({
      driverOrVehicleSearchArrayforbattery:
        this.maintenanceDashboardStore.batteryExpiryData,
    });
    console.log(this.maintenanceDashboardStore.tyreReminderData);
    await this.setState({ loadData: 1 });
  }
  CustomTooltip = ({ active, payload }) => {
    console.log(active, payload);
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      console.log(data);
      return (
        <div
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
          <div>{`Vehicle No: ${data.vehicleNo}`}</div>
          <div>{`Amount: ${data.sumAmount}`}</div>
        </div>
      );
    }
    return null;
  };
  piechart_tooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const label = payload[0].name;
      console.log(label); // Assuming 'name' is the property in your data
      return (
        <div
          className="custom-tooltip"
          style={{ border: "1px solid", background: "white", padding: "10px" }}
        >
          <small className="label">{`${label}`}</small>
          <br />
        </div>
      );
    }
    return null;
  };

  handleSearchChange(query) {
    return this.maintenanceDashboardStore?.serviceReminderData?.filter(
      (vehicle) => {
        const normalizedQuery = query.toLowerCase();
        return (
          (vehicle.driverName &&
            vehicle.driverName.toLowerCase().includes(normalizedQuery)) ||
          (vehicle.vehicleNo &&
            vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
        );
      }
    );
  }
  handleSearchtyreremainder(query) {
    return this.maintenanceDashboardStore?.tyreReminderData?.filter(
      (vehicle) => {
        const normalizedQuery = query.toLowerCase();
        return (
          (vehicle.driverName &&
            vehicle.driverName.toLowerCase().includes(normalizedQuery)) ||
          (vehicle.vehicleNo &&
            vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
        );
      }
    );
  }
  handleSearchoilremainder(query) {
    return this.maintenanceDashboardStore?.oilReminderData?.filter(
      (vehicle) => {
        const normalizedQuery = query.toLowerCase();
        return (
          (vehicle.driverName &&
            vehicle.driverName.toLowerCase().includes(normalizedQuery)) ||
          (vehicle.vehicleNo &&
            vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
        );
      }
    );
  }
  handleSearchistimararemainder(query) {
    return this.maintenanceDashboardStore?.mulkiyaReminderData?.filter(
      (vehicle) => {
        const normalizedQuery = query.toLowerCase();
        return (
          (vehicle.driverName &&
            vehicle.driverName.toLowerCase().includes(normalizedQuery)) ||
          (vehicle.vehicleNo &&
            vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
        );
      }
    );
  }
  handleSearchinsuaranceremainder(query) {
    return this.maintenanceDashboardStore?.insuranceExpiryData?.filter(
      (vehicle) => {
        const normalizedQuery = query.toLowerCase();
        return (
          (vehicle.driverName &&
            vehicle.driverName.toLowerCase().includes(normalizedQuery)) ||
          (vehicle.vehicleNo &&
            vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
        );
      }
    );
  }
  handleSearchbatteryremainder(query) {
    return this.maintenanceDashboardStore?.batteryExpiryData?.filter(
      (vehicle) => {
        const normalizedQuery = query.toLowerCase();
        return (
          (vehicle.driverName &&
            vehicle.driverName.toLowerCase().includes(normalizedQuery)) ||
          (vehicle.vehicleNo &&
            vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
        );
      }
    );
  }

  handleSearchinsuranceremainder(query) {
    return this.maintenanceDashboardStore.insuranceExpiryData?.filter(
      (vehicle) => {
        const normalizedQuery = query.toLowerCase();
        return (
          (vehicle.driverName &&
            vehicle.driverName.toLowerCase().includes(normalizedQuery)) ||
          (vehicle.vehicleNo &&
            vehicle.vehicleNo.toLowerCase().includes(normalizedQuery))
        );
      }
    );
  }
  // closeModal() {
  //   this.setState({ openactionmodal: false })
  // }
  async componentDidMount() {
    const maintenanceDashboard =
      await this.maintenanceDashboardStore.getDashboardCount({
        companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
        userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
        isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      });
    // this.getDashBoardCount();

    const weeklyMaintenanceData =
      await this.maintenanceDashboardStore.getVehicleWeeklyGraph({
        companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
        userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
        isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      });

    const monthlyMaintenanceData =
      await this.maintenanceDashboardStore.getVehicleMonthlyGraph({
        companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
        userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
        isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      });

    await this.maintenanceDashboardStore.getMaintenancePieChart({
      companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
      userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
      isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
    });

    this.setState({ loadData: 1 });
  }

  getDashBoardCount() {
    setTimeout(() => {
      this.maintenanceDashboardStore.getDashboardCount({
        companyId: JSON.parse(base64_decode(Cookies.get("data"))).userCompanyId,
        userId: JSON.parse(base64_decode(Cookies.get("data"))).userId,
        isadmin: JSON.parse(base64_decode(Cookies.get("data"))).isadmin,
      });
      this.getDashBoardCount();
    }, 30000);
  }

  renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    name,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * this.RADIAN);
    const y = cy + radius * Math.sin(-midAngle * this.RADIAN);

    return (
      <text
        style={{ margin: "30px" }}
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  getColorByName = (name) => {
    // Add your conditions here
    if (name === "Oil") {
      return "#ff7976";
    } else if (name === "Tire") {
      return "#57caeb";
    } else if (name === "Service") {
      return "#157fcc";
    } else if (name === "Mulkiya") {
      return "orange";
    } else if (name === "Insurance") {
      return "rgb(255, 219, 88)";
    }
  };

  onActionSubmit = async (value) => {
    console.log(value);
    const data = {
      changeValue: value?.changeValue,
      changeValueServiceDate:
        value?.changeValueServiceDate.format("YYYY-MM-DD"),
      vehicleId: this.state.vehicleid,
      actionDateTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    console.log(data);
    const response = await this.maintenanceDashboardStore.addaction(data);
    console.log(response);
    if (response.data.success === "S") {
      message.success(response.data.message);
      this.setState({ loadList: 1 });
      this.handleChange();
      await this.setState({ openactionmodal: false });
    } else {
      await message.error(response.data.message);
    }
  };

  onActiontyreSubmit = async (value) => {
    console.log(value);
    const data = {
      changeValue: value?.changeValue,
      changeValueServiceDate:
        value?.changeValueServiceDate.format("YYYY-MM-DD"),
      vehicleId: this.state.vehicleid,
      actionDateTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    console.log(data);
    const response = await this.maintenanceDashboardStore.addactionfortyre(
      data
    );
    console.log(response);
    if (response.data.success === "S") {
      message.success(response.data.message);
      this.setState({ loadList: 1 });
      this.handleChange();
      await this.setState({ openactiofortyre: false });
    } else {
      await message.error(response.data.message);
    }
  };

  onActionOilSubmit = async (value) => {
    console.log(value);
    const data = {
      changeValue: value?.changeValue,
      changeValueServiceDate:
        value?.changeValueServiceDate.format("YYYY-MM-DD"),
      vehicleId: this.state.vehicleid,
      actionDateTime: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    console.log(data);
    const response = await this.maintenanceDashboardStore.addactionforoil(data);
    console.log(response);
    if (response.data.success === "S") {
      message.success(response.data.message);
      this.setState({ loadList: 1 });
      this.handleChange();
      await this.setState({ openactioforoil: false });
    } else {
      await message.error(response.data.message);
    }
  };
  updatedistanceAll = async ({ state }) => {
    const data = { vehicleIds: [], type: "tyreReminderAll" };
    const resp = await this.maintenanceDashboardStore.updatedistanceAll(data);
    if (resp.data.success === "S") {
      Modal.success({
        title: "Success",
        content: "Details Updated Successfully",
      });
      await this.setState({ [state]: false });
    }
  };
  render() {
    const data = [
      // {
      //   distanceTravel: 84.6,
      //   startTime: "2023-05-29 00:45:46",
      //   vehicleNo: "JSB-3158",
      //   vehicleId: "18749",
      //   date: "2023-05-29",
      // },
      // {
      //   distanceTravel: 80.3,
      //   startTime: "2023-05-29 06:07:50",
      //   vehicleNo: "ASB-1350",
      //   vehicleId: "18591",
      //   date: "2023-05-29",
      // },
      // {
      //   distanceTravel: 78.3,
      //   startTime: "2023-05-29 00:29:39",
      //   vehicleNo: "ASB-1302",
      //   vehicleId: "18615",
      //   date: "2023-05-29",
      // },
      // {
      //   distanceTravel: 75.53,
      //   startTime: "2023-05-29 05:46:34",
      //   vehicleNo: "ASB-1329",
      //   vehicleId: "18627",
      //   date: "2023-05-29",
      // },
      // {
      //   distanceTravel: 74.3,
      //   startTime: "2023-05-29 06:20:31",
      //   vehicleNo: "ASB-1330",
      //   vehicleId: "18611",
      //   date: "2023-05-29",
      // },
      // {
      //   distanceTravel: 72.78,
      //   startTime: "2023-05-29 04:51:50",
      //   vehicleNo: "ASB-1289",
      //   vehicleId: "18641",
      //   date: "2023-05-29",
      // },
    ];
    const config = {
      // data,
      data,
      xField: "number",
      yField: "distanceTravel",
    };
    const series = [
      {
        name: "Series 1",
        data: [{ category: "", value: 0.7 }],
      },
    ];

    return (
      <div
        className="dash"
        style={{ height: "100vh", overflowY: "scroll", padding: 30 }}
      >
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Card
            style={this.dashBoardCardCss}
            onClick={async () => {
              await this.setState({ serviceReminder: true, status: "Running" });
              await this.handleChange();
            }}
            title={
              <Row>
                <Col span={6}>
                  {" "}
                  <i
                    class="las la-tools"
                    style={{
                      margin: "5px",
                      fontSize: "40px",
                      color: "white",
                      background: "#157fcc",
                    }}
                  ></i>
                  {/* <p>Service Reminder</p> */}
                  <p>{this.context.translate("_SERVICE_REMINDER_")}</p>
                </Col>
                <Col offset={11}>
                  <p style={{ fontSize: 18 }}>
                    {
                      this.maintenanceDashboardStore?.maintenanceData
                        ?.totalService
                    }
                  </p>
                </Col>
              </Row>
            }
          >
            <Row style={{ height: "200px" }}>
              <Col span={19} style={{ height: "50%", marginLeft: 5 }}>
                {/* <p>{this.context.translate("_SERVICE_REMINDER_")}</p> */}
                {/* <p>Upcoming</p> */}
                <p>{this.context.translate("_UPCOMING_")}</p>
                {/* <p>Due</p> */}
                <p>{this.context.translate("_DUE_")}</p>
                <p>{this.context.translate("_OVERDUE_")}</p>
                {/* <p>OverDue</p> */}
              </Col>
              <Col span={4} style={{ height: "50%" }}>
                <p>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.serviceUpcoming
                  }
                </p>
                <p>
                  {this.maintenanceDashboardStore?.maintenanceData?.serviceDue}
                </p>
                <p>
                  {" "}
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.serviceOverDue
                  }
                </p>
              </Col>
            </Row>
          </Card>

          <Modal
            open={this.state.serviceReminder}
            centered
            width={1300}
            onCancel={() => this.setState({ serviceReminder: false })}
            footer={null}
          >
            <div style={{ height: "600px" }}>
              <div className="heading" style={{ marginBottom: "10px" }}>
                {this.context.translate("_SERVICE_REMINDER_")}
              </div>

              <Row style={{ marginBottom: "10px", gap: "10px" }}>
                <Space>
                  <Input
                    // placeholder="Search by Vehicle"
                    placeholder={this.context.translate(
                      "_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_"
                    )}
                    style={{ width: 300, height: "36px" }}
                    onChange={async (e) => {
                      let searchedArray = await this.handleSearchChange(
                        e.target.value
                      );
                      console.log(searchedArray);
                      await this.setState({
                        driverOrVehicleSearchArray:
                          searchedArray?.length === 0 ? [] : searchedArray,
                      });
                    }}
                  />
                  <Button
                    disabled={
                      this.maintenanceDashboardStore?.serviceReminderData
                        ?.length > 0
                        ? false
                        : true
                    }
                    style={{
                      backgroundColor: conf.activeColor,
                      height: "36px",
                    }}
                    onClick={(val) => {
                      let result = [];
                      if (true) {
                        this.columns1.map((obj) => result.push(obj.dataIndex));

                        //  console.log(result.toString())

                        var getData = {
                          companyId: JSON.parse(
                            base64_decode(Cookies.get("data"))
                          ).userCompanyId,
                          userCompany: undefined,
                          vehicleStatus: this.state.status,
                          userId: JSON.parse(base64_decode(Cookies.get("data")))
                            .userId,
                          isadmin: JSON.parse(
                            base64_decode(Cookies.get("data"))
                          ).isadmin,
                          colList: result,
                        };
                        console.log(getData);
                        if (val) {
                          // console.log("afd")
                          this.maintenanceDashboardStore.exportSerRemExcel(
                            getData
                          );
                        }
                      }
                    }}
                  >
                    {/* Export */}
                    {this.context.translate("_EXPORT_")}
                  </Button>
                  {/* <Input
                  // placeholder="Search by Driver"
                  placeholder={this.context.translate("_SEARCH_DERIVER_")}
                  style={{ width: 200, height: "36px" }}
                  // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                  onChange={async (e) => {
                    let searchedArray = await this.handleSearchChange(
                      e.target.value
                    );
                    await this.setState({
                      driverOrVehicleSearchArray:
                        searchedArray.length === 0 ? [] : searchedArray,
                    });
                  }}
                /> */}

                  <Button
                    type="primary"
                    style={{ height: "36px" }}
                    onClick={() =>
                      this.updatedistanceAll({ state: "serviceReminder" })
                    }
                  >
                    <i class="bi bi-arrow-clockwise"></i>
                  </Button>
                </Space>
              </Row>

              <Table
                bordered
                pagination={false}
                current={this.state.currentPage}
                total={this.maintenanceDashboardStore?.total}
                columns={this.columns1}
                dataSource={this.state.driverOrVehicleSearchArray}
                // dataSource={this.maintenanceDashboardStore?.serviceReminderData}
                loading={this.maintenanceDashboardStore?.loading}
                onChange={this.handleTableChange}
                scroll={{ y: 450 }}
              />
            </div>
          </Modal>

          <Card
            style={this.dashBoardCardCss}
            title={
              <Row>
                <Col span={6}>
                  {" "}
                  <i
                    class="las la-cog"
                    style={{
                      fontSize: "40px",
                      color: "white",
                      background: "#57caeb",
                      margin: "5px",
                    }}
                  ></i>
                  {/* <p>Tyre Change Reminder</p> */}
                  <p>{this.context.translate("_TYRE_CHANGE_REAMINDER_")}</p>
                </Col>
                <Col offset={11}>
                  <p style={{ fontSize: 18 }}>
                    {this.maintenanceDashboardStore?.maintenanceData?.totalTire}
                  </p>
                </Col>
              </Row>
            }
            onClick={async () => {
              await this.setState({ tyerChangeReminder: true, status: "Idle" });
              await this.handleChange();
            }}
          >
            <Row style={{ height: "200px" }}>
              <Col span={19} style={{ height: "50%", marginLeft: 5 }}>
                {/* <p>{this.context.translate("_TYRE_CHANGE_REAMINDER_")}</p> */}
                <p>{this.context.translate("_UPCOMING_")}</p>
                <p>{this.context.translate("_DUE_")}</p>
                <p>{this.context.translate("_OVERDUE_")}</p>
              </Col>
              <Col span={4} style={{ height: "50%" }}>
                <p>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.tireUpcoming
                  }
                </p>
                <p>
                  {this.maintenanceDashboardStore?.maintenanceData?.tireDue}
                </p>
                <p>
                  {" "}
                  {this.maintenanceDashboardStore?.maintenanceData?.tireOverDue}
                </p>
              </Col>
            </Row>
          </Card>

          <Modal
            open={this.state.tyerChangeReminder}
            centered
            width={1300}
            onCancel={() => this.setState({ tyerChangeReminder: false })}
            footer={null}
          >
            <div style={{ height: "600px" }}>
              <div className="heading" style={{ marginBottom: "10px" }}>
                Tyre Change Reminder
              </div>
              <Row style={{ marginBottom: "10px", gap: "10px" }}>
                <Input
                  // placeholder="Search by Vehicle"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_"
                  )}
                  style={{ width: 300, height: "36px" }}
                  onChange={async (e) => {
                    let searchedArray = await this.handleSearchtyreremainder(
                      e.target.value
                    );
                    console.log(searchedArray);
                    await this.setState({
                      driverOrVehicleSearchArrayfortire:
                        searchedArray?.length === 0 ? [] : searchedArray,
                    });
                  }}
                  // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                />

                <Select
                  onSelect={async (val) => {
                    this.setState({ dueLimitTyre: val });
                    await this.maintenanceDashboardStore.getData({
                      companyId: JSON.parse(base64_decode(Cookies.get("data")))
                        .userCompanyId,
                      userId: JSON.parse(base64_decode(Cookies.get("data")))
                        .userId,
                      isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                        .isadmin,
                      status: "Idle",
                      dueLimitTyre: this.state.dueLimitTyre,
                    });
                  }}
                  onChange={(val) => {}}
                  style={{ width: 150 }}
                  defaultValue={1}
                  className="dashboard-select"
                >
                  <Select.Option value={1}>All</Select.Option>
                  <Select.Option value={2}>500 Km</Select.Option>
                  <Select.Option value={3}>1000 Km</Select.Option>
                  <Select.Option value={4}>2000 Km</Select.Option>
                  <Select.Option value={5}>5000 Km</Select.Option>
                </Select>

                <Button
                  disabled={
                    this.maintenanceDashboardStore?.tyreReminderData?.length > 0
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor: conf.activeColor,
                    height: "36px",
                  }}
                  onClick={(val) => {
                    let result = [];
                    if (true) {
                      this.columns2.map((obj) => result.push(obj.dataIndex));

                      //  console.log(result.toString())

                      var getData = {
                        companyId: JSON.parse(
                          base64_decode(Cookies.get("data"))
                        ).userCompanyId,
                        userCompany: undefined,
                        vehicleStatus: this.state.status,
                        userId: JSON.parse(base64_decode(Cookies.get("data")))
                          .userId,
                        isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                          .isadmin,
                        dueLimitTyre: this.state.dueLimitTyre,
                        colList: result,
                      };
                      console.log(getData);
                      if (val) {
                        // console.log("afd")
                        this.maintenanceDashboardStore.exportTyreRemExcel(
                          getData
                        );
                      }
                    }
                  }}
                >
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>

                {/* <Input
                  // placeholder="Search by Driver"
                  placeholder={this.context.translate("")}
                  style={{ width: 200, height: "36px" }}
                  // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                  onChange={async (e) => {
                    let searchedArray = await this.handleSearchtyreremainder(
                      e.target.value
                    );
                    await this.setState({
                      driverOrVehicleSearchArrayfortire:
                        searchedArray?.length === 0 ? [] : searchedArray,
                    });
                  }}
                /> */}
                <Button
                  type="primary"
                  style={{ height: "36px" }}
                  onClick={() =>
                    this.updatedistanceAll({ state: "tyerChangeReminder" })
                  }
                >
                  <i class="bi bi-arrow-clockwise"></i>
                </Button>
              </Row>

              <Table
                bordered
                pagination={false}
                current={this.state.currentPage}
                total={this.maintenanceDashboardStore?.total}
                columns={this.columns2}
                // dataSource={this.maintenanceDashboardStore?.tyreReminderData}
                dataSource={this.state.driverOrVehicleSearchArrayfortire}
                loading={this.maintenanceDashboardStore?.loading}
                onChange={this.handleTableChange}
                scroll={{ y: 450 }}
              />
            </div>
          </Modal>

          <Card
            style={this.dashBoardCardCss}
            title={
              <Row>
                <Col span={6}>
                  {" "}
                  <i
                    class="las la-oil-can"
                    style={{
                      fontSize: "40px",
                      color: "white",
                      background: "#ff7976",
                      margin: "5px",
                    }}
                  ></i>
                  {/* <p>Oil Change Reminder</p> */}
                  <p>{this.context.translate("_OIL_CHANGE_REMINDER_")}</p>
                </Col>
                <Col offset={11}>
                  <p style={{ fontSize: 18 }}>
                    {this.maintenanceDashboardStore?.maintenanceData?.totalOil}
                  </p>
                </Col>
              </Row>
            }
            onClick={async () => {
              await this.setState({ oilChangeReminder: true, status: "Stop" });
              await this.handleChange();
            }}
          >
            <Row style={{ height: "200px" }}>
              <Col span={19} style={{ height: "50%", marginLeft: 5 }}>
                {/* <p>{this.context.translate("_OIL_CHANGE_REMINDER_")}</p> */}
                <p>{this.context.translate("_UPCOMING_")}</p>
                <p>{this.context.translate("_DUE_")}</p>
                <p>{this.context.translate("_OVERDUE_")}</p>
              </Col>
              <Col span={4} style={{ height: "50%" }}>
                <p>
                  {this.maintenanceDashboardStore?.maintenanceData?.oilUpcoming}
                </p>
                <p>{this.maintenanceDashboardStore?.maintenanceData?.oilDue}</p>
                <p>
                  {" "}
                  {this.maintenanceDashboardStore?.maintenanceData?.oilOverDue}
                </p>
              </Col>
            </Row>
          </Card>

          <Modal
            open={this.state.oilChangeReminder}
            centered
            width={1300}
            onCancel={() => this.setState({ oilChangeReminder: false })}
            footer={null}
          >
            <div style={{ height: "600px" }}>
              <div className="heading" style={{ marginBottom: "10px" }}>
                {this.context.translate("_OIL_CHANGE_REMINDER_")}
              </div>
              <Row style={{ marginBottom: "10px", gap: "10px" }}>
                <Input
                  // placeholder="Search by Vehicle"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_"
                  )}
                  style={{ width: 300, height: "36px" }}
                  // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                  onChange={async (e) => {
                    let searchedArray = await this.handleSearchoilremainder(
                      e.target.value
                    );
                    await this.setState({
                      driverOrVehicleSearchArrayforoil:
                        searchedArray.length === 0 ? [] : searchedArray,
                    });
                  }}
                />

                <Select
                  onSelect={async (val) => {
                    this.setState({ dueLimit: val });
                    await this.maintenanceDashboardStore.getData({
                      companyId: JSON.parse(base64_decode(Cookies.get("data")))
                        .userCompanyId,
                      userId: JSON.parse(base64_decode(Cookies.get("data")))
                        .userId,
                      isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                        .isadmin,
                      status: "Stop",
                      dueLimit: this.state.dueLimit,
                    });
                  }}
                  onChange={(val) => {}}
                  style={{ width: 150 }}
                  defaultValue={1}
                  className="dashboard-select"
                >
                  <Select.Option value={1}>All</Select.Option>
                  <Select.Option value={2}>500 Km</Select.Option>
                  <Select.Option value={3}>1000 Km</Select.Option>
                  <Select.Option value={4}>2000 Km</Select.Option>
                  <Select.Option value={5}>5000 Km</Select.Option>
                </Select>
                <Button
                  disabled={
                    this.maintenanceDashboardStore?.oilReminderData?.length > 0
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor: conf.activeColor,
                    height: "36px",
                  }}
                  onClick={(val) => {
                    let result = [];
                    if (true) {
                      this.columns3.map((obj) => result.push(obj.dataIndex));

                      //  console.log(result.toString())

                      var getData = {
                        companyId: JSON.parse(
                          base64_decode(Cookies.get("data"))
                        ).userCompanyId,
                        userCompany: undefined,
                        vehicleStatus: this.state.status,
                        userId: JSON.parse(base64_decode(Cookies.get("data")))
                          .userId,
                        isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                          .isadmin,
                        colList: result,
                      };
                      console.log(getData);
                      if (val) {
                        // console.log("afd")
                        this.maintenanceDashboardStore.exportOilExcel(getData);
                      }
                    }
                  }}
                >
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>

                {/* <Input
                  // placeholder="Search by Driver"
                  placeholder={this.context.translate("_SEARCH_DERIVER_")}
                  style={{ width: 200, height: "36px" }}
                  onChange={async (e) => {
                    let searchedArray = await this.handleSearchoilremainder(
                      e.target.value
                    );
                    await this.setState({
                      driverOrVehicleSearchArrayforoil:
                        searchedArray.length === 0 ? [] : searchedArray,
                    });
                  }}
                // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                /> */}
                <Button
                  type="primary"
                  style={{ height: "36px" }}
                  onClick={() =>
                    this.updatedistanceAll({ state: "oilChangeReminder" })
                  }
                >
                  <i class="bi bi-arrow-clockwise"></i>
                </Button>
              </Row>
              <div>
                <Table
                  bordered
                  pagination={false}
                  current={this.state.currentPage}
                  total={this.maintenanceDashboardStore?.total}
                  columns={this.columns3}
                  // dataSource={this.maintenanceDashboardStore?.oilReminderData}
                  dataSource={this.state.driverOrVehicleSearchArrayforoil}
                  loading={this.maintenanceDashboardStore?.loading}
                  // onChange={this.handleTableChange}
                  scroll={{ y: 450 }}
                />
              </div>
            </div>
          </Modal>

          <Card
            style={this.dashBoardCardCss}
            title={
              <Row>
                <Col span={6}>
                  {" "}
                  <i
                    class="las la-car-side"
                    style={{
                      fontSize: "40px",
                      color: "white",
                      background: "#5ddab4",
                      margin: "5px",
                    }}
                  ></i>
                   <p>Mulkiya Reminder</p>
                  {/* <p>IstimaraInsurance</p> */}
                  {/* <p>{this.context.translate("_ISTIMARA_REMINDER_")}</p> */}
                </Col>
                <Col offset={11}>
                  <p style={{ fontSize: 18 }}>
                    {
                      this.maintenanceDashboardStore?.maintenanceData
                        ?.totalMulkiya
                    }{" "}
                  </p>
                </Col>
              </Row>
            }
            onClick={async () => {
              await this.setState({
                istimaraInsurance: true,
                status: "exIdle",
              });
              await this.handleChange();
            }}
          >
            <Row style={{ height: "200px" }}>
              <Col span={19} style={{ height: "50%", marginLeft: 5 }}>
                {/* <p>{this.context.translate("_ISTIMARA_REMINDER_")}</p> */}
                <p>{this.context.translate("_UPCOMING_")}</p>
                <p>{this.context.translate("_DUE_")}</p>
                <p>{this.context.translate("_OVERDUE_")}</p>
              </Col>
              <Col span={4} style={{ height: "50%" }}>
                <p>
                  {" "}
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.mulkiyaUpcoming
                  }
                </p>

                <p>
                  {this.maintenanceDashboardStore?.maintenanceData?.mulkiyaDue}
                </p>
                <p>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.mulkiyaOverDue
                  }
                </p>
              </Col>
            </Row>
          </Card>

          <Modal
            open={this.state.istimaraInsurance}
            centered
            width={1300}
            onCancel={() => this.setState({ istimaraInsurance: false })}
            footer={null}
          >
            <div style={{ height: "600px" }}>
              <div className="heading" style={{ marginBottom: "10px" }}>
                {this.context.translate("Mulkiya Reminder")}
              </div>
              <Row style={{ marginBottom: "10px", gap: "10px" }}>
                <Input
                  // placeholder="Search by Vehicle"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_"
                  )}
                  style={{ width: 300, height: "36px" }}
                  onChange={async (e) => {
                    let searchedArray =
                      await this.handleSearchistimararemainder(e.target.value);
                    console.log(searchedArray);
                    await this.setState({
                      driverOrVehicleSearchArrayforistimara:
                        searchedArray?.length === 0 ? [] : searchedArray,
                    });
                  }}
                  // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                />

                <Button
                  disabled={
                    this.maintenanceDashboardStore?.mulkiyaReminderData
                      ?.length > 0
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor: conf.activeColor,
                    height: "36px",
                  }}
                  onClick={(val) => {
                    let result = [];
                    if (true) {
                      this.columns4.map((obj) => result.push(obj.dataIndex));

                      //  console.log(result.toString())

                      var getData = {
                        companyId: JSON.parse(
                          base64_decode(Cookies.get("data"))
                        ).userCompanyId,
                        userCompany: undefined,
                        vehicleStatus: this.state.status,
                        userId: JSON.parse(base64_decode(Cookies.get("data")))
                          .userId,
                        isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                          .isadmin,
                        colList: result,
                      };
                      console.log(getData);
                      if (val) {
                        // console.log("afd")
                        this.maintenanceDashboardStore.exportMulkiyaExcel(
                          getData
                        );
                      }
                    }
                  }}
                >
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>

                {/* <Input
                  // placeholder="Search by Driver"
                  placeholder={this.context.translate("_SEARCH_DERIVER_")}
                  style={{ width: 200, height: "36px" }}
                // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                /> */}
                <Button
                  type="primary"
                  style={{ height: "36px" }}
                  onClick={() =>
                    this.updatedistanceAll({ state: "istimaraInsurance" })
                  }
                >
                  <i class="bi bi-arrow-clockwise"></i>
                </Button>
              </Row>

              <Table
                bordered
                pagination={false}
                current={this.state.currentPage}
                total={this.maintenanceDashboardStore?.total}
                columns={this.columns4}
                // dataSource={this.maintenanceDashboardStore?.mulkiyaReminderData}
                dataSource={this.state.driverOrVehicleSearchArrayforistimara}
                loading={this.maintenanceDashboardStore?.loading}
                scroll={{ y: 450 }}
              />
            </div>
          </Modal>

          <Card
            style={this.dashBoardCardCss}
            title={
              <Row>
                <Col span={8}>
                  {" "}
                  <i
                    class="las la-clipboard-list"
                    style={{
                      fontSize: "40px",
                      color: "white",
                      background: "#fda54f",
                      margin: "5px",
                    }}
                  ></i>
                  {/* <p>Insurance Reminder</p> */}
                  <p>{this.context.translate("_INSURANCE_REMINDER_")}</p>
                </Col>
                <Col offset={11}>
                  <p style={{ fontSize: 18 }}>
                    {
                      this.maintenanceDashboardStore?.maintenanceData
                        ?.totalInsurance
                    }
                  </p>
                </Col>
              </Row>
            }
            onClick={async () => {
              await this.setState({
                insuranceReminder: true,
                status: "NoData",
              });
              await this.handleChange();
            }}
          >
            <Row style={{ height: "200px" }}>
              <Col span={19} style={{ height: "50%", marginLeft: 5 }}>
                {/* <p>{this.context.translate("_INSURANCE_REMINDER_")}</p> */}
                <p>{this.context.translate("_UPCOMING_")}</p>
                <p>{this.context.translate("_DUE_")}</p>
                <p>{this.context.translate("_OVERDUE_")}</p>
              </Col>
              <Col span={4} style={{ height: "50%" }}>
                <p>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.insuranceUpcoming
                  }
                </p>
                <p>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.insuranceDue
                  }
                </p>
                <p>
                  {" "}
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.insuranceOverDue
                  }
                </p>
              </Col>
            </Row>
          </Card>

          <Modal
            open={this.state.insuranceReminder}
            centered
            width={1300}
            onCancel={() => this.setState({ insuranceReminder: false })}
            footer={null}
          >
            <div style={{ height: "600px" }}>
              <div className="heading" style={{ marginBottom: "10px" }}>
                {this.context.translate("_INSURANCE_REMINDER_")}
              </div>
              <Row style={{ marginBottom: "10px", gap: "10px" }}>
                <Input
                  // placeholder="Search by Vehicle"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_"
                  )}
                  style={{ width: 300, height: "36px" }}
                  onChange={async (e) => {
                    let searchedArray =
                      await this.handleSearchinsuranceremainder(e.target.value);
                    console.log(searchedArray);
                    await this.setState({
                      driverOrVehicleSearchArrayforinsurance:
                        searchedArray?.length === 0 ? [] : searchedArray,
                    });
                  }}
                  // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                />

                <Button
                  disabled={
                    this.maintenanceDashboardStore?.insuranceExpiryData
                      ?.length > 0
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor: conf.activeColor,
                    height: "36px",
                  }}
                  onClick={(val) => {
                    let result = [];
                    if (true) {
                      this.columns5.map((obj) => result.push(obj.dataIndex));

                      //  console.log(result.toString())

                      var getData = {
                        companyId: JSON.parse(
                          base64_decode(Cookies.get("data"))
                        ).userCompanyId,
                        userCompany: undefined,
                        vehicleStatus: this.state.status,
                        userId: JSON.parse(base64_decode(Cookies.get("data")))
                          .userId,
                        isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                          .isadmin,
                        colList: result,
                      };
                      console.log(getData);
                      if (val) {
                        // console.log("afd")
                        this.maintenanceDashboardStore.exportInsuranceExcel(
                          getData
                        );
                      }
                    }
                  }}
                >
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>

                {/* <Input
                  // placeholder="Search by Driver"
                  placeholder={this.context.translate("_SEARCH_DERIVER_")}
                  style={{ width: 200, height: "36px" }}
                // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                /> */}
                <Button
                  type="primary"
                  style={{ height: "36px" }}
                  onClick={() =>
                    this.updatedistanceAll({ state: "insuranceReminder" })
                  }
                >
                  <i class="bi bi-arrow-clockwise"></i>
                </Button>
              </Row>

              <Table
                bordered
                current={this.state.currentPage}
                total={this.maintenanceDashboardStore?.total}
                columns={this.columns5}
                // dataSource={this.maintenanceDashboardStore?.insuranceExpiryData}
                dataSource={this.state.driverOrVehicleSearchArrayforinsurance}
                loading={this.maintenanceDashboardStore?.loading}
                // onChange={this.handleTableChange}
              />
            </div>
          </Modal>

          <Card
            style={this.dashBoardCardCss}
            title={
              <Row>
                <Col span={8}>
                  {" "}
                  <i
                    class="las la-battery-three-quarters"
                    style={{
                      fontSize: "40px",
                      color: "white",
                      background: "#eb5bbb",
                      margin: "5px",
                    }}
                  ></i>
                  {/* <p>Battery Change Reminder</p> */}
                  <p>{this.context.translate("_BATTERY_CHANGE_REMINDER_")}</p>
                </Col>
                <Col offset={11}>
                  <p style={{ fontSize: 20 }}>
                    {
                      this.maintenanceDashboardStore?.maintenanceData
                        ?.totalbattery
                    }
                  </p>
                </Col>
              </Row>
            }
            onClick={async () => {
              await this.setState({
                batteryChangeReminder: true,
                status: "batteryData",
              });
              await this.handleChange();
            }}
          >
            <Row style={{ height: "200px" }}>
              <Col span={19} style={{ height: "50%", marginLeft: 5 }}>
                {/* <p>{this.context.translate("_BATTERY_CHANGE_REMINDER_")}</p> */}

                <p>{this.context.translate("_UPCOMING_")}</p>
                <p>{this.context.translate("_DUE_")}</p>
                <p>{this.context.translate("_OVERDUE_")}</p>
              </Col>
              <Col span={4} style={{ height: "50%" }}>
                <p>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.batteryUpcoming
                  }
                </p>
                <p>
                  {this.maintenanceDashboardStore?.maintenanceData?.batteryDue}
                </p>
                <p>
                  {" "}
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.batteryOverDue
                  }
                </p>
              </Col>
            </Row>
          </Card>

          <Modal
            open={this.state.batteryChangeReminder}
            centered
            width={1300}
            onCancel={() => this.setState({ batteryChangeReminder: false })}
            footer={null}
          >
            <div style={{ height: "600px" }}>
              <div className="heading" style={{ marginBottom: "10px" }}>
                {this.context.translate("_BATTERY_CHANGE_REMINDER_")}
              </div>
              <Row style={{ marginBottom: "10px", gap: "10px" }}>
                <Input
                  // placeholder="Search by Vehicle"
                  placeholder={this.context.translate(
                    "_SEARCH_BY_VEHICLE_NO_DRIVER_NAME_"
                  )}
                  style={{ width: 300, height: "36px" }}
                  onChange={async (e) => {
                    let searchedArray = await this.handleSearchbatteryremainder(
                      e.target.value
                    );
                    console.log(searchedArray);
                    await this.setState({
                      driverOrVehicleSearchArrayforbattery:
                        searchedArray?.length === 0 ? [] : searchedArray,
                    });
                  }}
                  // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                />

                <Button
                  disabled={
                    this.maintenanceDashboardStore?.batteryExpiryData?.length >
                    0
                      ? false
                      : true
                  }
                  style={{
                    backgroundColor: conf.activeColor,
                    height: "36px",
                  }}
                  onClick={(val) => {
                    let result = [];
                    if (true) {
                      this.columns6.map((obj) => result.push(obj.dataIndex));

                      //  console.log(result.toString())

                      var getData = {
                        companyId: JSON.parse(
                          base64_decode(Cookies.get("data"))
                        ).userCompanyId,
                        userCompany: undefined,
                        vehicleStatus: this.state.status,
                        userId: JSON.parse(base64_decode(Cookies.get("data")))
                          .userId,
                        isadmin: JSON.parse(base64_decode(Cookies.get("data")))
                          .isadmin,
                        colList: result,
                      };
                      console.log(getData);
                      if (val) {
                        // console.log("afd")
                        this.maintenanceDashboardStore.exportBatteryExcel(
                          getData
                        );
                      }
                    }
                  }}
                >
                  {/* Export */}
                  {this.context.translate("_EXPORT_")}
                </Button>

                {/* <Input
                  // placeholder="Search by Driver"
                  placeholder={this.context.translate("_SEARCH_DERIVER_")}
                  style={{ width: 200, height: "36px" }}
                // onChange={(e) => { this.setState({ searchField: e.target.value }) }}
                /> */}
                <Button
                  type="primary"
                  style={{ height: "36px" }}
                  onClick={() =>
                    this.updatedistanceAll({ state: "batteryChangeReminder" })
                  }
                >
                  <i class="bi bi-arrow-clockwise"></i>
                </Button>
              </Row>

              <Table
                bordered
                pagination={false}
                current={this.state.currentPage}
                total={this.maintenanceDashboardStore?.total}
                columns={this.columns6}
                // dataSource={this.maintenanceDashboardStore?.batteryExpiryData}
                dataSource={this.state.driverOrVehicleSearchArrayforbattery}
                loading={this.maintenanceDashboardStore?.loading}
                // onChange={this.handleTableChange}
                scroll={{ y: 450 }}
              />
            </div>
          </Modal>
        </div>

        <br></br>
        <br></br>
        <Row style={{ justifyContent: "space-around" }}>
          <Card
            // title="Monthly Expenses"
            title={this.context.translate("_Monthly_Expenses_")}
            style={{ width: "48.5%", padding: "10px", marginInlineStart: "0" }}
          >
            {/* <p>Cost(In Thousand)</p> */}
            <p style={{ padding: "6px" }}>
              {this.context.translate("_Cost_InThousand_")}
            </p>

            {/* <ResponsiveContainer height={300}>
             {console.log(this.maintenanceDashboardStore?.monthlyMaintenanceData)}
              <BarChart width={500} data={this.maintenanceDashboardStore?.monthlyMaintenanceData}
                 layout="vertical"
              >
                   <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="vehicleNo" />
                <YAxis />
                <Bar dataKey="sumAmount" stackId="a" fill="orange"></Bar>
              </BarChart>
            </ResponsiveContainer>

            
           */}
            <ResponsiveContainer height={300}>
              {console.log(
                this.maintenanceDashboardStore?.monthlyMaintenanceData
              )}
              <BarChart
                width={500}
                data={this.maintenanceDashboardStore?.monthlyMaintenanceData}
                layout="horizontal"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="vehicleNo" type="category" />{" "}
                {/* Assuming 'vehicleNo' is a numeric value, use type="number" */}
                <YAxis type="number" />
                <Tooltip content={this.CustomTooltip}></Tooltip>
                <Bar dataKey="sumAmount" stackId="a" fill="orange" />
              </BarChart>
            </ResponsiveContainer>
          </Card>

          <Card
            // title="Weekly Expenses"
            title={this.context.translate("_Weekly_Expenses_")}
            style={{ width: "48.5%", padding: "10px" }}
          >
            {/* <p>Cost(In Thousand)</p> */}
            <p style={{ padding: "6px" }}>
              {this.context.translate("_Cost_InThousand_")}
            </p>
            {/* <Column {...config} /> */}
            <ResponsiveContainer height={300}>
              <BarChart
                width={500}
                data={this.maintenanceDashboardStore?.weeklyMaintenanceData}
                layout="horizontal"
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="vehicleNo" type="category" />{" "}
                {/* Assuming 'vehicleNo' is a numeric value, use type="number" */}
                <YAxis type="number" />
                <Tooltip content={this.CustomTooltip}></Tooltip>
                <Bar dataKey="sumAmount" stackId="a" fill="orange" />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Row>
        <br></br>

        <Card
          // title="Reminders"
          title={this.context.translate("_REMINDERS_")}
          style={{
            width: "98.3%",
            margin: "20px 12px",

            padding: "6px",
          }}
        >
          <Row>
            <Col span={12}>
              <ResponsiveContainer width="100%" height={250}>
                <PieChart width={500} height={400}>
                  <Pie
                    data={this.maintenanceDashboardStore.piChartDta}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={this.renderCustomizedLabel}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {this.maintenanceDashboardStore.piChartDta?.map(
                      (entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={this.getColorByName(entry.name)}
                        />
                      )
                    )}
                  </Pie>
                  <Tooltip content={<this.piechart_tooltip />} />
                </PieChart>
              </ResponsiveContainer>
            </Col>
            <Col span={12} style={{ marginTop: "80px" }}>
              <Space>
                <Button style={{ backgroundColor: "#157fcc" }}></Button>
                <b>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.totalService
                  }
                </b>
                {/* Service */}
                {this.context.translate("_SERVICE_")}
                <Button style={{ backgroundColor: "#57caeb" }}></Button>
                <b>
                  {this.maintenanceDashboardStore?.maintenanceData?.totalTire}
                </b>
                {/* Tire Change */}
                {this.context.translate("_TYRE_CHANGE_")}
                <Button style={{ backgroundColor: "#ff7976" }}></Button>
                <b>
                  {this.maintenanceDashboardStore?.maintenanceData?.totalOil}
                </b>
                {/* Oil Change */}
                {this.context.translate("_OIL_CHANGE_")}
              </Space>
              <br />
              <Space style={{ marginTop: "20px" }}>
                <Button style={{ backgroundColor: "#5ddab4" }}></Button>
                <b>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.totalMulkiya
                  }
                </b>
                Mulkiya

                {/* {this.context.translate("_ISTIMARA_")} */}
                &nbsp;&nbsp;
                <Button style={{ backgroundColor: "#fda54f" }}></Button>
                <b>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.totalInsurance
                  }
                </b>
                {/* Insurance  */}
                {this.context.translate("_INSURANCE_")}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button style={{ backgroundColor: "#eb5bbb" }}></Button>
                <b>
                  {
                    this.maintenanceDashboardStore?.maintenanceData
                      ?.totalbattery
                  }
                </b>
                {/* Battery Change Reminder */}
                {this.context.translate("_BATTERY_CHANGE_REMINDER_")}
              </Space>
            </Col>
          </Row>
        </Card>
        <Modal
          title="Action"
          open={this.state.openactionmodal}
          onCancel={() => this.setState({ openactionmodal: false })}
          footer={[null]}
          width={400}
        >
          <div>
            <Form layout="vertical" onFinish={this.onActionSubmit}>
              <Form.Item label="Km" name="changeValue">
                <Input></Input>
              </Form.Item>

              <Form.Item label="Date" name="changeValueServiceDate">
                <DatePicker></DatePicker>
              </Form.Item>

              <div className="formBtnGroup">
                <Space style={{ padding: 10 }}>
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                  >
                    {/* Save */}
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                  </Button>
                </Space>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Action"
          open={this.state.openactiofortyre}
          onCancel={() => this.setState({ openactiofortyre: false })}
          footer={[null]}
          width={400}
        >
          <div>
            <Form layout="vertical" onFinish={this.onActiontyreSubmit}>
              <Form.Item label="Km" name="changeValue">
                <Input></Input>
              </Form.Item>

              <Form.Item label="Date" name="changeValueServiceDate">
                <DatePicker></DatePicker>
              </Form.Item>

              <div className="formBtnGroup">
                <Space style={{ padding: 10 }}>
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                  >
                    {/* Save */}
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                  </Button>
                </Space>
              </div>
            </Form>
          </div>
        </Modal>

        <Modal
          title="Action"
          open={this.state.openactioforoil}
          onCancel={() => this.setState({ openactioforoil: false })}
          footer={[null]}
          width={400}
        >
          <div>
            <Form layout="vertical" onFinish={this.onActionOilSubmit}>
              <Form.Item label="Km" name="changeValue">
                <Input></Input>
              </Form.Item>

              <Form.Item label="Date" name="changeValueServiceDate">
                <DatePicker></DatePicker>
              </Form.Item>

              <div className="formBtnGroup">
                <Space style={{ padding: 10 }}>
                  <Button
                    type="primary"
                    className="formSaveButton"
                    htmlType="submit"
                  >
                    {/* Save */}
                    {this.context.translate("_FLEET_DASHBORD_NEWPOI_SAVEBTN_")}
                  </Button>
                </Space>
              </div>
            </Form>
          </div>
        </Modal>
      </div>
    );
  }
}
MaintenanceDashboard.contextType = StoreContext;
export default observer(MaintenanceDashboard);
