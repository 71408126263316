import React from "react";
import {
  Input,
  DatePicker,
  Select,
  Button,
  Space,
  Row,
  Col,
  Checkbox,
  Dropdown,
  Modal,
  Image,
} from "antd";
import { observer } from "mobx-react";
import { StoreContext } from "../store/RootStore";

import withRouter from "../withRouter";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { MapContainer, Marker, Popup } from "react-leaflet";

import TripReportStore from "../store/TripReportStore";
import ListTable from "./ListTable";
import CompanyAutocomplete from "./CompanyAutocomplete";
import VehicleStore from "../store/VehicleStore";
import ReactLeafletGoogleLayer from "react-leaflet-google-layer";

import dayjs from "dayjs";
import L from "leaflet";
import MapForVehiclePosition from "./MapForVehiclePosition";
import { decode as base64_decode } from "base-64";
import config from "../config";
import Cookies from "js-cookie";
import DeviceGroupsForVehicles from "./DeviceGroupsForVehicles";
const { RangePicker } = DatePicker;
const { Option } = Select;
const dotIcon = L.divIcon({
  className: "dot-icon",
  iconSize: [8, 8],
  html: ' <div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>',
});
class TripReportList extends React.Component {
  constructor(props, context) {
    super(props);

    this.handleTableChange = this.handleTableChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.tripReportStore = new TripReportStore();
    this.vehicleStore = new VehicleStore();

    this.state = {
      loading: false,
      pageSize: 50,
      pageNo: 1,
      offset: 0,
      limit: 50,
      loadData: 0,
      companyId: "",
      selectedVehicles: "",
      travelInt: 0,
      idleInt: 0,
      stopInt: 0,
      modalVisible: false,
      showMap: false,
      column: "",
      filter: "",
      fromDate: dayjs().format("YYYY-MM-DD 00:00:00"),
      toDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
      modalInfoObj: {},
      vehicleObj: "",
      heading: "",
      Notcompany: false,
      Notasset: false,
      showMonthPopup: false,
      selectedColumns: [
        {
          // title: "Asset Name",
          title: context.translate("_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"),
          dataIndex: "vehicleNo",
          width: "15%",
          key: "vehicleNo",
        },
        {
          // title: "Date",
          title: context.translate("_FLEET_REPORTS_DATE_"),
          dataIndex: "timestamp",
          width: "15%",
          key: "timestamp",
          render: (value) => {
            const formattedDateTime = dayjs(value).format(
              "DD-MM-YYYY hh:mm:ss A"
            );
            return <>{formattedDateTime}</>;
          },
        },

        {
          // title: "Activity",
          title: context.translate("_ACTIVITY_"),
          dataIndex: "activity",
          width: "15%",
          key: "activity",
        },
        {
          // title: "Description",
          title: context.translate("_FLEET_DASHBORD_ROUTELIST_DISCRIPTION_"),
          dataIndex: "location",
          width: "15%",
          key: "location",
        },
        {
          // title: "Duration(HH:MM:SS)",
          title: context.translate("_DURATION_HH_MM_SS_"),
          dataIndex: "duration",
          width: "15%",
          key: "duration",
        },
        {
          // title: "Distance (KM)",
          title: context.translate("_DISTANCE_KM_"),
          dataIndex: "distanceTravelled",
          width: "15%",
          key: "distance",
        },
        {
          // title: "Odometer",
          title: context.translate("_FLEET_DASHBORD_SETTING_ODOMETER_"),
          dataIndex: "odometer",
          width: "15%",
          key: "odometer",
        },
        {
          // title: "Max Speed",
          title: context.translate("_FLEET_REPORTS_FLEETREPORT_MAXSPEED_"),
          dataIndex: "speed",
          width: "15%",
          key: "maxspeed",
        },
        {
          // title: "Driver",
          title: context.translate("_FLEET_DASHBORD_HISTORY_TOOLTIP_DRIVER_"),
          dataIndex: "name",
          width: "15%",
          key: "driver",
        },
        {
          // title: "View on map",
          title: context.translate("_FLEET_REPORTS_GEOFENCELIST_VIEWMAP_"),
          dataIndex: "viewonmap",
          width: "15%",
          key: "viewonmap",
          render: (value, obj) => {
            return (
              <>
                <Image
                  src={require("../assets/images/mapIcon/gps_icon.png")}
                  width={25}
                  height={25}
                  onClick={async () => {
                    await this.setState({
                      vehicleObj: { ...obj, icon: "map-car-icon-green.png" },
                      heading: this.context.translate("_TRIP_REPORT_MAP_"),
                    });
                    await this.setState({ loadData: 1 });
                  }}
                />
              </>
            );
          },
        },
      ],
    };
    this.menuProps = {
      items: [
        {
          // label: "Excel",
          label: context.translate("_EXCEL_"),
          key: "excel",
          icon: <PlusOutlined />,
        },
        {
          // label: "CSV",
          label: context.translate("_CSV_"),
          key: "csv",
          icon: <PlusOutlined />,
        },
        {
          // label: "XML",
          label: context.translate("_XML_"),
          key: "xml",
          icon: <PlusOutlined />,
        },
        {
          // label: "PDF",
          label: context.translate("_PDF_"),
          key: "pdf",
          icon: <PlusOutlined />,
        },
      ],
      onClick: (val) => {
        let result = [];
        if (true) {
          this.state.selectedColumns.map((obj) => result.push(obj.dataIndex));
          var getData = {
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            tid: this.state.travelInt,
            iid: this.state.idleInt,
            sid: this.state.stopInt,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            userId: parseInt(
              JSON.parse(base64_decode(Cookies.get("data"))).userId
            ),

            colList: result.toString(),
          };
          if (val.key == "excel") {
            this.tripReportStore.exportExcel(getData);
          }
          if (val.key == "csv") {
            this.tripReportStore.exportcsv(getData);
          }
          if (val.key == "pdf") {
            this.tripReportStore.exportpdf(getData);
          }

          if (val.key == "xml") {
            this.tripReportStore.exportxml(getData);
          }
        }
      },
    };

    this.newColumns = [...this.state.selectedColumns];
  }
  async componentDidMount() {
    this.setState({ pageSize: 50 });
    // await this.tripReportStore.loadData({ offset: 0, limit: 50 });
    if (Number(this.props.getUserData().isadmin) !== -1) {
      let companyId = this.props.getUserData().userCompanyId;
      this.setState({ companyId: companyId });
      this.handleChange({ value: companyId });
    }

    await this.setState({ column: this.state.selectedColumns });
  }

  async handleChange(comp) {
    await this.setState({ Notcompany: false });
    await this.setState({ companyId: comp?.value });
    await this.setState({ selectedVehicles: "" });
    // await this.vehicleStore.getVehiclesCompanyId({ companyId: comp.value });
    await this.vehicleStore.getVehiclesCompanyId(comp?.value);
    await this.handleSearch();
  }

  async handleSearch() {
    this.setState({ loading: true });
    // await this.tripReportStore.loadData({
    //   offset: (this.state.pageNo - 1) * this.state.pageSize,
    //   limit: this.state.pageSize,
    //   companyId: this.state.companyId,
    //   pageNo: this.state.pageNo,
    // });
    await this.setState({ loadData: 1 });
  }

  async handleTableChange(pagination, filters, sorter) {
    await this.setState({
      pageNo: pagination.current,
      pageSize: pagination.pageSize,
    });
    await this.showData();
  }

  vehicleSelect = async (val) => {
    await this.setState({ selectedVehicles: val });
  };

  addMonths = (date, months) => {
    // date.setMonth(date.getMonth() + months);
    // date = date + months;
    var indx = dayjs().month() + -6;
    // return date.format("YYYY-MM-DD HH:mm:ss");
    return dayjs().month(indx);
  };
  checkFromDate(dates) {
    //  var fromDate = dates.format("YYYY-MM-DD HH:mm:ss");
    var fromDate = dates;
    var pdate = this.addMonths();
    if (pdate > fromDate) {
      //  Ext.Msg.show({
      //      title: fleet.Language.get('_FLEET_ALERT_'),
      //      msg: fleet.Language.get('_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_'),
      //      buttons: Ext.Msg.OK,
      //  });
      this.setState({ showMonthPopup: true });
      return false;
    } else {
      return true;
    }
  }

  showData = async () => {
    // console.log("ASDFGHGFHGH");
    if (!this.state.companyId) {
      this.setState({ Notcompany: true });
    } else {
      this.setState({ Notcompany: false });
    }
    if (!this.state.selectedVehicles) {
      this.setState({ Notasset: true });
    } else {
      this.setState({ Notasset: false });
    }
    console.log(this.state.selectedVehicles);
    if (this.state.companyId && this.state.selectedVehicles) {
      if (this.state.companyId) {
        this.setState({ Notcompany: false, Notasset: false });
        console.log("data");
        if (this.checkFromDate(this.state.fromdateStdTime)) {
          await this.tripReportStore.loadData({
            offset: (this.state.pageNo - 1) * this.state.pageSize,
            limit: this.state.pageSize,
            companyId: this.state.companyId,
            vehicleId: this.state.selectedVehicles,
            fromDate: this.state.fromDate,
            toDate: this.state.toDate,
            travelInt: this.state.travelInt,
            idleInt: this.state.idleInt,
            stopInt: this.state.stopInt,
            pageNo: this.state.pageNo,
          });
          await this.setState({ listData: 1 });
        }
      }
    }
  };

  onDateTimeChange = (dates) => {
    if (dates) {
      const format = "YYYY-MM-DD HH:mm:ss";
      this.setState({
        fromdateStdTime: dates[0],
        fromDate: dates[0].format(format),
        toDate: dates[1].format(format),
      });
    }
  };

  async componentDidUpdate(prevProps, prevState) {
    if (this.state.vehicleObj !== prevState.vehicleObj) {
      console.log("ilocasd");
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      });
    }
  }
  handleCheckboxChange = async (column) => {
    console.log(column);
    if (this.state.selectedColumns.some((c) => c.key === column.key)) {
      const filtered = this.state.selectedColumns.filter(
        (c) => c.key !== column.key
      );
      console.log(filtered);
      await this.setState({
        selectedColumns: filtered,
      });
      await this.setState({ column: filtered });
      // await  this.updateVisibleColumns();
    } else {
      console.log("adsf");
      await this.setState({
        selectedColumns: [...this.state.selectedColumns, column],
        column: [...this.state.selectedColumns, column],
      });
      // await  this.updateVisibleColumns();
    }
  };

  render() {
    return (
      <>
        <div style={{ padding: 0 }}>
          <Row className="headerFixed" style={{ height: "50px" }}>
            <Col span={12}>
              {/* <div className="heading">Trip Report</div> */}
              <div className="heading">
                {this.context.translate("_TRIP_REPORT_")}
              </div>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              >
              

                <Button onClick={() => this.setState({ modalVisible: true })}>
                  {/* Columns */}
                  {this.context.translate("_COLUMNS_")}
                </Button>

                <Dropdown
                  menu={this.menuProps}
                  disabled={
                    this.tripReportStore?.tripReport?.length > 0 ? false : true
                  }
                >
                  <Button  className="exportDropdown"
                  // style={{ backgroundColor: config.activeColor }}
                  >
                    <Space>
                      {/* Export */}
                      {this.context.translate("_EXPORT_")}
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              </Space>
            </Col>
          </Row>
        </div>
        <div
          style={{
            paddingTop: "10px",
            paddingRight: "24px",
            paddingLeft: "24px",
          }}
        >
          <Row className="listInputRow">
            <Col span={22}>
              <Space>
                <CompanyAutocomplete
                  onChange={this.handleChange}
                  allowClear={true}
                  style={{ width: 200 }}
                  status={this.state.Notcompany ? "error" : false}
                />
                {this.props.getUserData().isadmin === 1 && (
                  <DeviceGroupsForVehicles
                    vehicleStore={this.vehicleStore}
                    setLoadDta={() => this.setState({ loadData: 1 })}
                    resetVehicles={() =>
                      this.setState({
                        selectedVehicles: "",
                      })
                    }
                  />
                )}

                <Select
                  value={
                    this.state.selectedVehicles
                      ? this.state.selectedVehicles
                      : null
                  }
                  onChange={(val) => {
                    this.vehicleSelect(val);
                    this.setState({ Notasset: false });
                  }}
                  style={{ width: 150 }}
                  showSearch
                  optionFilterProp="children"
                  status={this.state.Notasset ? "error" : false}
                  placeholder={this.context.translate(
                    "_FLEET_DASHBORD_VEHICLE_VIEWICON_TITLE_"
                  )}
                  filterOption={(inputValue, option) => {
                    if (option.children) {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(inputValue.toLowerCase()) >= 0
                      );
                    }
                  }}
                >
                  {this.vehicleStore?.getVehiclesUsingCompanyId?.map((val) => {
                    return (
                      <Option value={val.vehicleId}>{val.vehicleNo}</Option>
                    );
                  })}
                </Select>
                <RangePicker
                  placeholder={[
                    this.context.translate("_FROM_DATE_TIME_"),
                    this.context.translate("_TO_DATETIME_"),
                  ]}
                  format="DD-MM-YYYY hh:mmA"
                  style={{ width: 400 }}
                  onChange={(val) => {
                    this.onDateTimeChange(val);
                  }}
                  disabledDate={(current) => {
                    return current && current > dayjs();
                  }}
                  showTime={{
                    format: "h:mmA",
                    defaultValue: [dayjs().startOf("day"), dayjs()],
                  }}
                  defaultValue={[dayjs().startOf("day"), dayjs()]}
                />

                <Input
                  style={{ width: 100 }}
                  // placeholder="Travel Int"
                  placeholder={this.context.translate("_TRAVEL_INT_")}
                  value={this.state.travelInt}
                  onChange={(e) => this.setState({ travelInt: e.target.value })}
                />
                <Input
                  style={{ width: 100 }}
                  // placeholder="Stop Int"
                  placeholder={this.context.translate("_STOP_INT_")}
                  value={this.state.stopInt}
                  onChange={(e) => this.setState({ stopInt: e.target.value })}
                />
                <Input
                  style={{ width: 100 }}
                  placeholder={this.context.translate("_IDLE_INT_")}
                  // placeholder="Idle Int"
                  value={this.state.idleInt}
                  onChange={(e) => this.setState({ idleInt: e.target.value })}
                />

                <Button
                  type="primary"
                  onClick={async () => {
                    await this.setState({ pageNo: 1, pageSize: 50 });
                    await this.showData();
                  }}
                >
                  {/* Show */}
                  {this.context.translate("_FLEET_SHOW_BTN_")}
                </Button>
              </Space>
            </Col>
            <Col span={2}>
              <Space
                style={{
                  float:
                    this.context.locale.direction == "ltr" ? "right" : "left",
                }}
              ></Space>
            </Col>
          </Row>

          <Modal
            open={this.state.modalVisible}
            width={300}
            // title="Select Columns"
            title={this.context.translate("_SELECT_COLUMNS_")}
            onCancel={() => this.setState({ modalVisible: false })}
            onOk={() => {
              this.setState({ modalVisible: false });
            }}
            footer={null}
          >
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              {this.newColumns.map((column, v) => {
                return (
                  <Row key={v}>
                    <Col>
                      <Checkbox
                        onChange={() => this.handleCheckboxChange(column)}
                        checked={this.state.selectedColumns.some(
                          (c) => c.key === column.key
                        )}
                      >
                        {column.title}
                      </Checkbox>
                    </Col>
                  </Row>
                );
              })}
            </div>
          </Modal>

          <ListTable
            currentPage={this.state.pageNo}
            pageSize={this.state.pageSize}
            total={this.tripReportStore?.total}
            columns={this.state.column}
            dataSource={this.tripReportStore?.tripReport}
            loading={this.tripReportStore?.loading}
            onChange={this.handleTableChange}
            pagination={{
              pageSize: this.state.pageSize,
              total: this.tripReportStore.total || 0,
            }}
            scroll={{
              y: this.state.clientHeight - 240,
              x: 100,
              // scrollToFirstRowOnChange:true
            }}
          />

          <Modal
            title={this.context.translate('_TRIP_REPORT_MAP_')}
            open={this.state.showMap}
            centered
            // bodyStyle={{ height: 1000 }}
            width={850}
            height={400}
            onOk={() => this.setState({ showMap: false })}
            onCancel={() => this.setState({ showMap: false })}
            footer={false}
          >
            <MapContainer
              ref={this.mapRef}
              zoom={16}
              center={[
                this.state.modalInfoObj.latitude,
                this.state.modalInfoObj.longitude,
              ]}
              style={{ height: "400px", width: "800px" }}
              bounds={
                this.state.modalInfoObj
                  ? [
                      [
                        this.state.modalInfoObj.latitude,
                        this.state.modalInfoObj.longitude,
                      ],
                      [
                        this.state.modalInfoObj.latitude,
                        this.state.modalInfoObj.longitude,
                      ],
                    ]
                  : []
              }
            >
              <ReactLeafletGoogleLayer />
              {/* <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // attribution="Map data © OpenStreetMap contributors"
              /> */}

              <Marker
                icon={dotIcon}
                position={[
                  this.state.modalInfoObj.latitude,
                  this.state.modalInfoObj.longitude,
                ]}
              >
                {/* <Popup>{this.state.modalInfoObj.formatted_address}</Popup> */}
                <Popup>{this.state.modalInfoObj.vehicleNo}</Popup>
              </Marker>
            </MapContainer>
          </Modal>
        </div>

        <Modal
          width={350}
          className="alert-model"
          title={
            <div>
              {" "}
              <i
                style={{ color: "red", fontSize: "18px", marginRight: "5px" }}
                class="bi bi-exclamation-triangle"
              ></i>{" "}
              {this.context.translate("_FLEET_ALERT_")}
            </div>
          }
          open={this.state.showMonthPopup}
          onOk={() => this.setState({ showMonthPopup: false })}
          onCancel={() => this.setState({ showMonthPopup: false })}
          footer={false}
        >
          {/* For reports prior to 6 months please contact administrator */}
          {this.context.translate("_REPORTS_PRIOR_CONTACT_ADMINISTRATOR_")}
        </Modal>

        {this.state.vehicleObj && (
          <MapForVehiclePosition
            vehicleObj={this.state.vehicleObj}
            heading={this.state.heading}
            closeMapModal={(resp) => {
              this.setState({ vehicleObj: resp });
            }}
          />
        )}
      </>
    );
  }
}
TripReportList.contextType = StoreContext;
export default observer(withRouter(TripReportList));
